import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation, Redirect } from 'react-router-dom';

import { selectSystemReady } from '../../redux/selectors/index.js';
import { root, navMenu, closePanelWrap, hidePanel, content } from '../../scss/SettingsLayout.module.scss';
import { LicoFab, LicoIcon, NavList } from "../common/index.js";
import VersionTooltip from '../common/VersionTooltip.jsx';
import { HOME, settingsRoutes, SETTINGS_INTEGRATION, SETTINGS_CLIENT_APPOINTMENTS }  from '../navigation/routes.js';

import OurStandardAuthLayout from './OurStandardAuthLayout.jsx';

const SettingsLayout = ({ children, user, integrated, systemReady }) => {
    const location = useLocation();

    // all routes have to be legal wrt admin flag; that's the only check for integrated systems.
    // Un-integrated systems only show integration page unless standalone has been chosen, in which 
    // case they show most of the other pages (client appointments is currently dependent on integration).
    const legalRoutes = settingsRoutes.filter( route => ( !route.adminOnly || ( user && user.admin ) ) && 
        ( integrated || ( systemReady 
            // system not integrated (otherwise shortcut stops it before here) but ready, so standalone, in 
            // which case we don't want integration or client scheduling (currently dependent on provider)
            ? route.to !== SETTINGS_INTEGRATION && route.to !== SETTINGS_CLIENT_APPOINTMENTS
            : route.to === SETTINGS_INTEGRATION ) ) );

    return <OurStandardAuthLayout>
        {legalRoutes.length === 0
            ? <Redirect to={HOME} />
            : location.pathname === "/settings" && <Redirect to={legalRoutes[ 0 ].to} />}

        <div className={root}>
            <span className={navMenu}>
                {systemReady && <Link to={HOME}>
                    <VersionTooltip versionArgs={{ files: true, modules: true, resources: true, nodeVersions: true, system: true, deployment: true }}>
                        <LicoFab
                            color="inherit"
                            licoVariant="large"
                            className={closePanelWrap}
                        >
                            <LicoIcon icon="angle-left" className={hidePanel} />
                        </LicoFab>
                    </VersionTooltip>

                </Link>}
                <NavList listItems={legalRoutes} />
            </span>
            <span className={content}>
                {children}
            </span>
        </div>
    </OurStandardAuthLayout>;
};

SettingsLayout.propTypes = {
    children:       PropTypes.node,
    user:           PropTypes.object.isRequired,
    integrated:     PropTypes.bool,
    systemReady:    PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    const { user, integration: { provider } } = state;

    return {
        user,
        integrated:     provider?.integrated,
        systemReady:    selectSystemReady( state )
    };
};

export default connect( mapStateToProps )( SettingsLayout );
export { SettingsLayout };
