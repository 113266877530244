import React from 'react';

import PropTypes from 'prop-types';

import { errorLine, flexColumn } from '../../scss/Settings.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { LicoSmallButton } from "../common/index.js";

import { BusinessHoursItem } from './BusinessHoursItem.jsx';


const BusinessHoursList = ({ businessHours, setValue, addItem, removeItem, error, allowEmptyList }) => <div className={flexColumn}>
    {businessHours.map( ( item, i ) => <BusinessHoursItem
        key={i}
        index={i}
        businessHours={item}
        setValue={setValue}
        removeItem={removeItem}
        allowEmptyList={allowEmptyList}
    /> )}
    <LicoSmallButton label={__( "Add" )} onClick={() => addItem()} />
    {error && <div className={errorLine}>{error}</div>}
</div>;

BusinessHoursList.propTypes = {
    businessHours:  PropTypes.array,
    setValue:       PropTypes.func.isRequired,
    addItem:        PropTypes.func.isRequired,
    removeItem:     PropTypes.func.isRequired,
    error:          PropTypes.string,
    allowEmptyList: PropTypes.bool
};

export default BusinessHoursList;
export { BusinessHoursList };
