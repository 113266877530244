import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { errorMessage, separator } from '../../scss/TimeRangeSelector.module.scss';
import { __ } from '../../utils/i18n.jsx';

import { TimeSelector } from './TimeSelector.jsx';


const TimeRangeSelector = ({ startName = "startTime", startValue, endName = "endTime", endValue, onChange, ampm }) => {

    const [ error, setError ] = useState( "" );

    const validatedOnChange = change => {
        if ( ( change.name === startName && change.value >= endValue ) ||
                ( change.name === endName && change.value <= startValue ) )
            setError( __( "Start Time must come before End Time" ) );
        else {
            setError( "" );
            onChange( change );
        }
    };

    return <div>
        <TimeSelector name={startName} value={startValue} onChange={validatedOnChange} ampm={ampm} />
        <span className={separator}>{__( "to" )}</span>
        <TimeSelector name={endName} value={endValue} onChange={validatedOnChange} ampm={ampm} />
        <span className={errorMessage}>{error}</span>
    </div>;
};

TimeRangeSelector.propTypes = {
    startName:      PropTypes.string,
    startValue:     PropTypes.number.isRequired,
    endName:        PropTypes.string,
    endValue:       PropTypes.number.isRequired,
    onChange:       PropTypes.func.isRequired,
    ampm:           PropTypes.bool
};

export default TimeRangeSelector;
export { TimeRangeSelector };
