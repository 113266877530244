import React, { useState, useEffect } from 'react';

import { validEmail } from '@licoriceio/utils';
import { TextField, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ADD_ITEM_ID, CC_EMAIL_ID } from '../../constants.js';
import { setTypeAheadFields } from '../../redux/actions/index.js';
import { setTypeAheadFilter, getTypeAheadControlState } from '../../redux/reducers/typeAhead.js';
import { dispatchChangeAction, dispatchBlurThunk, installKeySaveActions } from '../../redux/reducerUtil.js';
import { optionAvatar, optionRender, roundInput, ccEmailItem } from '../../scss/DynamicSelect.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { SelectStyle } from '../../utils/misc.js';

import LicoAvatar from './LicoAvatar.jsx';

// noinspection JSUnusedLocalSymbols
const nullFn = ( ...a ) => null; // eslint-disable-line no-unused-vars

/**
 * @typedef {object} DynamicSelectParentProps
 * @property {string} name
 * @property {SelectStyle} [style]
 * @property {number} [minimumFilterLength]
 * @property {boolean} [autoFocus]
 * @property {React.ReactNode} [helperText]
 * @property {boolean} [error]
 * @property {object} [formPackage]
 * @property {function( React.ChangeEvent<any>, object ): void} [onChange]
 * @property {function( React.FocusEvent<any> ): void} [onBlur]
 * @property {function( React.ChangeEvent<any>, string, string ): void} [onInputChange]
 * @property {string} [initialText]
 * @property {string[]} [excludedIds]
 * @property {object} [extraData]
 * @property {string} [placeholder]
 */

/**
 * @typedef {object} DynamicSelectState
 * @property {string} inputValue
 */

const formPackageOnChange = ( formPackage, name, setInputValue ) => ( event, value ) => {
    // send thru the whole value (id + label) in case we need to update both
    // in state.
    formPackage.onChange({
        id:    formPackage.id,
        field: name,
        value: value || {
            id:    '',
            label: ''
        }
    });
    setInputValue( value ? value.label : '' );
    // this.setState({ inputValue: value ? value.label : '' });
};

const slicePackageOnChange = ( slicePackage, name, setInputValue ) => ( event, value ) => {
    dispatchChangeAction( slicePackage, { updates: { [ name ]: value } });
    setInputValue( value ? value.label : '' );
};

const normalOnChange = ( data, onChange, setInputValue ) => ( event, value ) => {

    // when we add a person to a job card list, we need the full record, not just the id and name.
    // This information is still present in the typeahead state for this control, we just need to look
    // it up.
    // TODO this code is not generic since it refers to userId...
    const fullRecord = data
        ? data.find( record => record.userId === value.id )
        : null;
    onChange( event, {
        ...value,
        fullRecord
    });
    setInputValue( '' );
    // this.setState({ inputValue: '' });
};

/** */
const DynamicSelect = props => {
    const {
        name,
        minimumFilterLength = 1,
        formPackage,
        slicePackage,
        onChange            = () => null,
        onInputChange,
        open,
        loading,
        loaded,
        options,
        data,
        setTypeAheadFilter,
        setTypeAheadFields,
        style               = SelectStyle.standard,
        excludedIds,
        extraData,
        placeholder         = __( "Enter filter..." ),
        autoFocus           = true,
        initialText         = '',
        addHandler,
        addLabel            = __( 'Add new item' ),
        addOnEmpty          = false,
        dataName,
        filterBy
    } = props;
    let {
        onBlur              = () => null,
        onKeyDown
    } = props;

    // I don't remember why this is called taYes...
    const taYes = style === SelectStyle.chipAdder || style === SelectStyle.round;

    const [ inputValue, setInputValue ] = useState( initialText || '' );
    const [ savedInitialText, setSavedInitialText ] = useState( initialText || '' );

    // if the seeding text is changed from above, we have to discard the current value
    // (including any changes) and put in the new one. Case in point; changing to a new or different job
    // without closing the job card.
    useEffect( () => {
        if ( initialText !== savedInitialText ) {
            setInputValue( initialText );
            setSavedInitialText( initialText );
        }
    }, [ initialText, savedInitialText ]);

    const autocompleteClasses = style === SelectStyle.chipAdder
        ? { inputRoot: 'slim' }
        : style === SelectStyle.round
            ? { inputRoot: roundInput }
            : undefined;

    const realOptions = [ ...options ];

    // we want to add a "CC: <email>" entry to the list when the filter looks like an email, and we can add items
    if ( addHandler && inputValue.length > 0 && validEmail( inputValue ) ) 
        realOptions.push({ id: CC_EMAIL_ID, label: `${__( "CC:" )} ${inputValue}` });

    if ( addHandler && loaded && ( !addOnEmpty || options.length === 0 ) )
        realOptions.push({ id: ADD_ITEM_ID, label: addLabel }); 

    const changeFunc = formPackage
        ? formPackageOnChange( formPackage, name, setInputValue )
        : slicePackage
            ? slicePackageOnChange( slicePackage, name, setInputValue )
            : taYes
                ? normalOnChange( data, onChange, setInputValue )
                : onChange || undefined;

    if ( slicePackage ) {
        onBlur = () => dispatchBlurThunk( slicePackage, { field: name });
        if ( slicePackage.keySaveAction )
            onKeyDown = installKeySaveActions( slicePackage, onBlur, props.onKeyDown );
    }

    return (
        <Autocomplete
            id={name}
            open={open}
            data-ux={dataName}
            openOnFocus
            disableClearable
            clearOnBlur={style === SelectStyle.chipAdder || style === SelectStyle.round}
            classes={autocompleteClasses}
            onOpen={() => {
                setTypeAheadFields({
                    name,
                    open: true
                });
                setTypeAheadFilter({ name, filter: '', extraData });
            }}
            onClose={() => {
                setTypeAheadFields({
                    name,
                    open: false
                });
            }}
            isOptionEqualToValue={( option, value ) => option.id === value.id}
            getOptionLabel={( option ) => option.label}

            // the "options => options" case is required to prevent the control filtering out entries which 
            // don't match the filter; since we're using the filter to get a new list of options, this is unneeded and wrong.
            filterOptions={ filterBy || ( excludedIds ? options => options.filter( option => !excludedIds.includes( option.id ) ) : options => options )}
            onChange={( event, value ) => value.id !== ADD_ITEM_ID ? changeFunc( event, value ) : addHandler({ value, inputValue })}
            inputValue={inputValue}

            onInputChange={( event, value, reason ) => {
                // an input change is fired at startup which wipes out any existing value
                if ( reason === 'reset' ) return;

                setTypeAheadFilter({ name, filter: value.length >= minimumFilterLength ? value : '', extraData });
                setInputValue( value );
                // this.setState({ inputValue: value });

                // edits to the text field only get recorded if they are blanking the field, otherwise we're just changing the
                // filter
                if ( formPackage ) {
                    if ( value === '' )
                        formPackage.onChange({ id: formPackage.id, field: name, value });
                }
                else if ( slicePackage ) {
                    if ( value === '' )
                        dispatchChangeAction( slicePackage, { updates: { [ name ]: '' } });
                }
                else if ( onInputChange )
                    onInputChange( event, value, reason );
            }}
            onBlur={event => {
                onBlur( event );
                if ( style === SelectStyle.chipAdder || style === SelectStyle.round )
                    setInputValue( '' );
                // this.setState({ inputValue: '' });
            }}
            onKeyDown={onKeyDown}

            options={realOptions}
            loading={loading}
            loadingText={__( "Loading..." )}
            renderOption={({ className, ...rest }, option ) => 
                option.id === ADD_ITEM_ID 
                    ? (
                        <div key={option.id} className={className} data-ux="option" {...rest}>
                            <LicoAvatar icon="add" className={`${optionAvatar} small`} />
                        &nbsp;&nbsp;{option.label}
                        </div>
                    ) 
                    : ( style === SelectStyle.chipAdder || style === SelectStyle.round ) && option.id !== CC_EMAIL_ID
                        ? (
                            <div key={option.id} className={`${className} ${option.avatar.icon ? ccEmailItem : ''}`} data-ux="option" {...rest}>
                                <LicoAvatar
                                    avatar={option.avatar}
                                    gravatarEmail={option.item && option.item.preferences?.gravatarEmail}
                                    size="small"
                                    className={`${optionAvatar} small`}
                                />
                                &nbsp;&nbsp;{option.label}
                            </div>
                        ) 
                        : (
                            <div key={option.id} className={`${className} ${option.id === CC_EMAIL_ID ? ccEmailItem : ''}`} data-ux="option" {...rest}>
                                {option.label}
                            </div>
                        )
            }
            renderInput={( params ) => (
                <TextField
                    {...params}
                    autoFocus={autoFocus}
                    variant="outlined"
                    placeholder={placeholder}
                />
            )}

            // without this, the control seems to remember the current selection
            // and then complains when the list changes if the current selection isn't included.
            value={null}
        />
    );
};

DynamicSelect.propTypes = {
    open:                       PropTypes.bool.isRequired,
    loading:                    PropTypes.bool.isRequired,
    loaded:                     PropTypes.bool.isRequired,
    options:                    PropTypes.array.isRequired,
    data:                       PropTypes.array,
    setTypeAheadFilter:         PropTypes.func.isRequired,
    setTypeAheadFields:         PropTypes.func.isRequired,
    name:                       PropTypes.string.isRequired,
    minimumFilterLength:        PropTypes.number,
    formPackage:                PropTypes.object,
    onChange:                   PropTypes.func,
    onInputChange:              PropTypes.func,
    onBlur:                     PropTypes.func,
    onKeyDown:                  PropTypes.func,
    style:                      PropTypes.string,
    excludedIds:                PropTypes.array,
    extraData:                  PropTypes.object,
    placeholder:                PropTypes.string,
    autoFocus:                  PropTypes.bool.isRequired,
    initialText:                PropTypes.string,
    addHandler:                 PropTypes.func,
    addLabel:                   PropTypes.string,
    addOnEmpty:                 PropTypes.bool,
    dataName:                   PropTypes.string,
    slicePackage:               PropTypes.object,
    filterBy:                   PropTypes.func
};


const mapStateToProps = ( state, parentProps ) => {
    const { open, loading, loaded, options, data } = getTypeAheadControlState( state, parentProps.name );

    return {
        open,
        loading,
        loaded,
        options,
        data
    };
};

const mapDispatchToProps = {
    setTypeAheadFilter,
    setTypeAheadFields
};

export default connect( mapStateToProps, mapDispatchToProps )( DynamicSelect );
export { DynamicSelect };
