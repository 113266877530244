/*********************************************************************************************************************
 * @file Meta reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 24-Dec-2020
 *********************************************************************************************************************/

import { has } from '@licoriceio/utils';
import dayjs from 'dayjs';

import { checkTimeEnteredDay } from '../../components/pegboard/reducer.js';
import { uri, GET } from '../../constants.js';
import { setMeta, setInvitationStatus, setUpdateTime, setAddToMonitor, setVersion } from '../../redux/actions/index.js';
import { abstractedCreateAuthRequest, abstractedCreateRequest } from '../../services/util/baseRequests.js';
import { saveToUserSession } from '../../utils/local-storage.js';
import { ezRedux, genericReducer, genericRequest } from '../reducerUtil.js';

const getBasicVersion = type => genericRequest({}, abstractedCreateRequest( GET, uri.VERSION_BASIC ), [ [ setVersion, { type } ] ]);
const getExtendedVersion = args => genericRequest({}, abstractedCreateAuthRequest( GET, uri.VERSION_EXTENDED ), [ [ setVersion, { type: args.type } ] ], [], args.params );

/**
 * @typedef {object} MetaState
 * @property {string} domain
 * @property {object} [invitationStatus]
 * @property {boolean} [inviteAccepted]
 * @property {number} [worklogTimerId]
 * @property {boolean} [searchPanelShown]
 * @property {boolean} [passwordResetEmailSent]
 * @property {object} [resetToken]
 * @property {boolean} [passwordReset]
 * @property {boolean} [inviteCreated]
 * @property {boolean} [clientDetailsSaved]
 * @property {boolean} [notificationPanelShown]
 */

/**
  * @type {MetaState}
  */
const initialState = Object.freeze({
    domain:             '',
    currentTime:        ( new Date() ).getTime(),
    monitorLog:         [],
    version:            {},
    clientDetailsSaved: false

});

const BASIC = 'basic';
const EXTENDED = 'extended';
const versionRequestType = {
    BASIC,
    EXTENDED
};

/**
 * Any actions which need to be triggered periodically can go here
 * @returns void
 */
const updateTimeThunk = () => dispatch => {
    dispatch( setUpdateTime() );
    dispatch( checkTimeEnteredDay() );
};

const getVersionInfoThunk = ( type, params ) => ( dispatch, getState ) => {
    const { meta: { version } } = getState();

    // if we change types, don't check the timestamp
    if ( version.nextCheckAfter && type === version.type ) 
    {
        if ( dayjs().isBefore( dayjs( version.nextCheckAfter ) ) ) 
            return;
    }

    // looks weird to check the type and then send it but it means we can echo it back to the payload, and
    // it keeps things a bit more flexible
    dispatch( type === versionRequestType.BASIC ? getBasicVersion( type ) : getExtendedVersion({ type, params }) );
};

// intercept the generic reducer to spot changes we want to save to the session
const _setMeta = ( draft, payload ) => {

    if ( has( payload, 'searchPanelShown' ) )
        saveToUserSession( payload );
    
    genericReducer( draft, payload );
};

/** all action creators are listed as keys here. Values are expressions which resolve to (state, args) => {} */
const reducers = {
    [ setMeta ]:                _setMeta,
    [ setInvitationStatus ]:    ( draft, payload ) => draft.invitationStatus = payload,
    [ setUpdateTime ]:          ( draft ) => draft.currentTime = ( new Date() ).getTime(),
    [ setAddToMonitor ]:        ( draft, payload ) => draft.monitorLog.unshift({ time: dayjs().format( 'HH:mm:ss' ), ...payload }),
    [ setVersion ]:             ( draft, payload ) => genericReducer( draft, 
        { version: { ...payload.payload, nextCheckAfter: dayjs().add( 10, 'seconds' ).toISOString(), type: payload.type } })
};

export {
    updateTimeThunk,
    getVersionInfoThunk,
    versionRequestType
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
