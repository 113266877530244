import { BAD_CREDS, BAD_MFA, MAXIMUM_ATTEMPTS } from '@licoriceio/errors';

import { uri } from '../constants.js';
import { INCORRECT_PASSWORD, INCORRECT_MFA, UNEXPECTED_ERROR, EXCEED_MAX_LOGIN_ATTEMPT } from "../utils/common-types.js";

import { createRequest }                 from "./util/baseRequests.js";

const loginError = {
    [ BAD_CREDS ]:          INCORRECT_PASSWORD,
    [ BAD_MFA ]:            INCORRECT_MFA,
    [ MAXIMUM_ATTEMPTS ]:   EXCEED_MAX_LOGIN_ATTEMPT
};

const errorResponse = response => {
    let errors = loginError[ response.data?.errorCode ] || UNEXPECTED_ERROR;

    return {
        type:        "singleLine",
        errors,
        status:     response.status,
        errorCode:  response.data?.errorCode
    };
};

export const login = createRequest( uri.LOGIN, errorResponse, "POST" );
