import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ redirect = "/", isAuthenticated, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: redirect, state: { from: props.location } }} />}
    />
);

PrivateRoute.propTypes = {
    redirect:        PropTypes.string,
    location:        PropTypes.string,
    component:       PropTypes.any,
    isAuthenticated: PropTypes.bool
};
