import React from 'react';

import { ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        borderBottom: "1px solid var(--lightgrey-1)",
        borderTop:    "1px solid var(--lightgrey-1)",
        marginTop:    -1,
        fontSize:     16,
        fontFamily:   "gotham-medium, sans-serif",
        color:        "var(--black-1)",
        borderLeft:   "6px solid transparent"
    },
    selected: {
        borderLeft:      "6px solid var(--teal)",
        backgroundColor: "var(--lightgrey-1)"
    }
});

export const NavListItem = ({ label, to, selected = false, testTag, ...rest }) => {
    const classes = useStyles( rest );

    return <ListItem className={`${classes.root} ${selected && classes.selected}`} button component={Link} to={to} data-ux={testTag}> {label} </ListItem>;
};

NavListItem.propTypes = {
    label:    PropTypes.string.isRequired,
    to:       PropTypes.string.isRequired,
    selected: PropTypes.bool,
    testTag:  PropTypes.string
};
