import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setEngineerId } from '../../redux/actions/index.js';
import { root, name } from '../../scss/SelectEngineerLine.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { LicoButton } from "../common/index.js";

const SelectEngineerLine = ({ engineer, engineerId, setEngineerId }) => (
    <div className={root}>
        <div className={name}>
            {engineer.name}
        </div>
        <LicoButton
            size="small"
            onClick={() => setEngineerId( engineer.id )}
            disabled={engineerId === engineer.id}
        >
            {engineerId === engineer.id ? __( "Booked" ) : __( "Book" )}
        </LicoButton>
    </div>
);

SelectEngineerLine.propTypes = {
    engineer:      PropTypes.object.isRequired,
    engineerId:    PropTypes.string.isRequired,
    setEngineerId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    engineerId: state.scheduling.engineerId
});

const mapDispatchToProps = { setEngineerId };

export default connect( mapStateToProps, mapDispatchToProps )( SelectEngineerLine );
export { SelectEngineerLine };
