/*********************************************************************************************************************
 * @file Jobcard Modal component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020 <- not true, didn't start til Feb 16
 *********************************************************************************************************************/
import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { jobState } from '../../constants.js';
import { getCalendarEventFromState, getJobFromState } from '../../redux/selectors/index.js';
import { jobCardModal } from '../../scss/JobCardModal.module.scss';
import { __ } from '../../utils/i18n.jsx';
import BusyCard from '../busycard/BusyCard.jsx';
import { setExistingJobThunk, setExistingBusyCardThunk } from '../calendar/sharedThunks.js';
import { MessageDialog } from '../common/index.js';
import TimedActionPanel from '../common/TimedActionPanel.jsx';
import Backdrop from '../layouts/Backdrop.jsx';

import JobCard from './JobCard.jsx';

const JobCardModal = ({
    job,
    calendarEvent,
    setExistingJobThunk,
    setExistingBusyCardThunk
}) => (
    <>
        {
            job?.state === jobState.DELETED
                ? <MessageDialog
                    title={__( "Job Deleted" )}
                    message={__( "This Job has been deleted" )}
                    isOpen={true}
                    onClose={() => {
                        setExistingJobThunk( null );
                        // setDeletedDialogOpen( false );
                        // onCloseClick();
                    }}
                />
                : <Backdrop onClose={() => {
                    setExistingJobThunk( null );
                    setExistingBusyCardThunk( null );  
                }}>
                    <div className={jobCardModal}>
                        {job && <JobCard job={job}/>}
                        {calendarEvent && <BusyCard calendarEvent={calendarEvent} />}
                    </div>
                    <TimedActionPanel />
                </Backdrop>
        }
    </>
);

JobCardModal.propTypes = {
    job:                        PropTypes.any,
    calendarEvent:              PropTypes.any,
    setExistingJobThunk:             PropTypes.func.isRequired,
    setExistingBusyCardThunk:        PropTypes.func.isRequired
};


const mapStateToProps    = ( state, props ) => ({
    job:            getJobFromState( state, props.jobId ),
    calendarEvent:  getCalendarEventFromState( state, props.calendarEventId )
});

const mapDispatchToProps = {
    setExistingJobThunk,
    setExistingBusyCardThunk
};

export default connect( mapStateToProps, mapDispatchToProps )( JobCardModal );
export { JobCardModal };
