/** ******************************************************************************************************************
 * @file Description of this file goes here.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 19-Apr-2021
 *********************************************************************************************************************/

import { avatars } from '@licoriceio/constants';
import { getFirstLast, randomElement } from '@licoriceio/utils';

import { __ } from './i18n.jsx';

const THIS_FIELD_IS_REQUIRED = __( 'This field is required.' );
const INCORRECT_PASSWORD = __( "Incorrect username or password." );
const INCORRECT_MFA = __( "Authentication failed." );
const UNEXPECTED_ERROR = __( "Unexpected error." );
const EXCEED_MAX_LOGIN_ATTEMPT = __( "Maximum login attempts exceeded." );
const INVALID_EMAIL = __( "Invalid email." );


function getAvatarFromUserOrCompany( item )
{
    // This isn't a great name splitting function
    const nameSplit = getFirstLast( item.name, true );

    return "profilePicture" in item && item.profilePicture
        ? item.profilePicture
        : item.name 
            ? {
                color:       getAvatarColors( item.preferences, nameSplit ),
                firstLetter: nameSplit[ 0 ],
                lastLetter:  nameSplit[ 1 ]
            }
            // users without names are CC emails
            : { 
                color:      getAvatarColors( item.preferences ),
                icon:       'envelope'
            };

}

const getAvatarItemFromName = name => {
    if ( !name ) return;

    const initials = getFirstLast( name, true );
    const color = randomElement( avatars, initials );

    return {
        color,
        firstLetter: initials[ 0 ],
        lastLetter:  initials[ 1 ]
    };
};

const getAvatarColors = ( source, initials ) => {
    const avatarClassTail = source.color || source.avatar || source.preferences?.avatar;
    if ( avatarClassTail ) return avatarClassTail;

    const fixed = initials || ( source.name || source.companyName ? getFirstLast( source.name || source.companyName, true ) : void 0 );
    return randomElement( avatars, fixed );
};

const convertToLicoSelectOptions = items => items.filter( v => v !== null ).map( convertToLicoSelectOption );

const convertToLicoSelectOption = item => !item ? null : {
    id:     '_id' in item ? item._id : 'userId' in item ? item.userId : 'no id?',
    label:  item.name || item.loginEmail,
    avatar: getAvatarFromUserOrCompany( item ),
    item
};


const convertToLicoSelectOptionFromMap = ( item, map ) => convertToLicoSelectOption( map[ item._id ]);

const convertToLicoSelectOptionsFromMap = ( items, map ) => items.map( v => convertToLicoSelectOptionFromMap( v, map ) ).filter( v => v !== null );

const nop = () => {};

export {
    nop,
    getAvatarItemFromName,
    getAvatarColors,
    THIS_FIELD_IS_REQUIRED,
    INCORRECT_PASSWORD,
    INCORRECT_MFA,
    UNEXPECTED_ERROR,
    EXCEED_MAX_LOGIN_ATTEMPT,
    INVALID_EMAIL,
    getAvatarFromUserOrCompany,
    convertToLicoSelectOptions,
    convertToLicoSelectOptionsFromMap
};
