import React from 'react';

import { numMinutesToString } from '@licoriceio/utils';
import PropTypes from 'prop-types';

import * as scss from '../../../scss/TimeMeter.module.scss';
import { P } from '../../common/index.js';

const { root, text, error, meter } = scss;

export const TimeMeter = ({ timeTaken, totalTime, currentTime, maxCurrentTime, priority, timerRunning }) => {

    const exceededMaxSeconds = maxCurrentTime && currentTime > maxCurrentTime;
    const displaySeconds = exceededMaxSeconds ? maxCurrentTime : currentTime;
    const currentTimeClass = exceededMaxSeconds && timerRunning ? error : '';

    return <div className={root}>
        <P className={text} gutterBottom = {false}>
            {numMinutesToString( timeTaken )} / {numMinutesToString( totalTime )} <span className={currentTimeClass}>({numMinutesToString( displaySeconds / 60 )})</span>
        </P>
        <progress className={`${meter} ${scss[ priority || 'normal' ]}`} max={totalTime} value={timeTaken > totalTime ? totalTime : timeTaken} />
    </div>;
};

TimeMeter.propTypes = {
    timeTaken:      PropTypes.number.isRequired,
    totalTime:      PropTypes.number.isRequired,
    priority:       PropTypes.string,
    currentTime:    PropTypes.number,
    maxCurrentTime: PropTypes.number,
    timerRunning:   PropTypes.bool
};
