import React from 'react';

import { Tooltip, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import { passwordScore, passwordTooltip, strengthImage } from '../../scss/PasswordField.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { LicoTextField, LicoIcon } from "../common/index.js";


const PasswordField = ({ password, strength, fieldTouched, fieldError, setFormValue, setTouched, onKeyPress, ...rest }) => <>
    <LicoTextField
        name="password"
        type="password"
        value={password}
        label={__( "Create a password" )}
        subLabel={__( "(Please enter a password which has a rating of Transcendent)" )}
        placeholder=" "
        noMargins

        helperText={fieldTouched.password ? fieldError.password : ''}
        error={!!fieldError.password && fieldTouched.password}

        onChange={e => setFormValue({ field: e.target.name, value: e.target.value })}
        onBlur={e => setTouched( e.target.name )}
        onKeyPress={onKeyPress}
        InputProps={ strength && {
            endAdornment: <InputAdornment position="start"><img className={strengthImage} src={strength.score[ 3 ]} alt={strength.score[ 2 ]} /></InputAdornment>
        }}
        {...rest}
    />
    {
        strength && password?.length > 0 &&
        <div className={passwordScore}>
            <Tooltip
                title={
                    <div className={passwordTooltip}>
                        <div><LicoIcon icon={Number( strength.score[ 0 ]) < 4 ? "times-circle" : "check-circle"} size="sm" /> {strength.score[ 1 ]}</div>
                        {strength.warning && <div><LicoIcon icon="warning" size="small" />  {strength.warning}</div>}
                        {strength.suggestions.map( ( suggestion, i ) => <div key={i}><LicoIcon icon="info" size="sm" /> {suggestion}</div> )}
                    </div>
                }
                classes={{ tooltip: passwordTooltip }}
                arrow
                placement="bottom-start"
            >
                <div>
                    <span style={{ display: "flex" }}>
                        { __( "Password strength:" )}
                        <span className="flexGrow" />
                        {strength.score[ 2 ]}
                        &nbsp;
                        <LicoIcon size="sm" icon='info' />
                    </span>

                </div>
            </Tooltip>
        </div>
    }
</>;

PasswordField.propTypes = {
    password:     PropTypes.string,
    strength:     PropTypes.object,
    fieldTouched: PropTypes.object.isRequired,
    fieldError:   PropTypes.object.isRequired,
    setTouched:   PropTypes.func.isRequired,
    setFormValue: PropTypes.func.isRequired,
    onKeyPress:   PropTypes.func
};

export default PasswordField;
export { PasswordField };
