import { TokenStatus } from '@licoriceio/constants';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { uri, GET, POST, PATCH } from '../../constants.js';
import { setToken, setScheduleError, setProgressAction, setAppointment, setEngineerId, setStartTime, setJobTitle, setJobDescription, setJobPriority,
    setWeekStart, setWeekData, setCurrentWeekData, setSendAppointment, setSettingsSchedule, setFoundEligibles
} from '../../redux/actions/index.js';
import { ezRedux, genericReducer, makeMapById } from '../../redux/reducerUtil.js';
import { abstractedCreateRequest } from '../../services/util/baseRequests.js';
import { __ } from '../../utils/i18n.jsx';
import { unwrapBusinessHours } from '../settings/utilities.jsx';
dayjs.extend( isBetween );


const progressStage = {
    LOADING:   0,
    CANCELLED: 1,
    LANDING:   2,
    CALENDAR:  3,
    CONFIRM:   4,
    SUCCESS:   5,
    UNKNOWN:   6
};

const initialState = Object.freeze({
    progress:           progressStage.LOADING,
    token:              '',
    error:              '',
    engineerId:         '',
    startTime:          '',
    weekStart:          '',
    sendAppointment:    true,
    weekData:           {},
    currentWeekData:    {},
    foundEligibles:     false
});

// these drive the calendar as well; be careful changing slotMinutes below 30 as it will affect the calendar height due to min slot height
const SLOT_MINUTES = 30;

/** services */

const _asyncFetchScheduleByRange = abstractedCreateRequest( GET, uri.SCHEDULES );
const fetchTokenById = abstractedCreateRequest( GET, uri.GET_TOKEN );
const _asyncSetTokenStatus = abstractedCreateRequest( PATCH, uri.SET_TOKEN_STATUS );
const _asyncAddScheduleAppointment = abstractedCreateRequest( POST, uri.SCHEDULE_APPOINTMENT );

/** requests */

const _findScheduleJob = async tokenId => fetchTokenById( null, void 0, [ tokenId ])
    .then( ({ payload, error }) => {
        if ( !error ) return payload;
        throw error;
    });

/** utility functions */

const endFromStart = ( startTime, duration ) => {
    // return new Date( new Date( startTime ).getTime() + duration * MINUTE );
    return dayjs( startTime ).add( duration, 'minutes' );
};

const eventInHours = ( startTime, endTime, hours, engineerHours ) => {
    const startMinutes = startTime.hour() * 60 + startTime.minute();
    const endMinutes = endTime.hour() * 60 + endTime.minute();
    const weekday = ( startTime.day() + 6 ) % 7;
    const eventFits = hourChunk => hourChunk.weekdays[ weekday ] && startMinutes >= hourChunk.startTime && endMinutes <= hourChunk.endTime;
    let hoursOk = hours.some( eventFits );
    if ( hoursOk && engineerHours )
        hoursOk = engineerHours.some( eventFits );
    return hoursOk;
};

const availableEngineers = ( engineersWithEvents, startTime, duration, prePadding, postPadding ) => {

    if ( !engineersWithEvents )
        return [];

    const realStartTime = dayjs( startTime ).subtract( prePadding, 'minutes' );

    const endTime = endFromStart( realStartTime, duration + prePadding + postPadding );
    return engineersWithEvents
        .filter( engineer => !engineer.events.some( event => event.start.isBefore( endTime ) && event.end.isAfter( realStartTime )  ) &&
            eventInHours( realStartTime, endTime, engineer.businessHours, engineer.engineerHours ) )
        .map( engineer => ({ id: engineer.id, name: engineer.name }) );
};

// reduce function to group events by user, where the user id can come in a specified field name
const eventReduce = personField => ( acc, cur ) => {
    const { startDate, endDate } = cur;
    const event = {
        start:  dayjs( startDate ),
        end:    dayjs( endDate )
    };
    if ( cur[ personField ] in acc )
        acc[ cur[ personField ] ].events.push( event );
    else {
        acc[ cur[ personField ] ] = {
            id:     cur[ personField ],
            name:   `Name of ${cur[ personField ]}`,
            events: [ event ]
        };
    }
    return acc;
};

// interpret token errors given a reason
const tokenError = {
    missing:        __( "No Appointment Found" ),
    obsolete:       __( "This appointment has already been scheduled" ),
    expired:        __( "This link has expired" ),
    purpose:        __( "This token is not an appointment token" )
};

/** thunk actions */

const setProgress = progress => ( dispatch, getState ) => {

    // if it's the final stage, we have to create the appt and cancel the token
    if ( progress === progressStage.SUCCESS ) {
        const { scheduling: { user, job, token, engineerId, startTime, sendAppointment, defaultDuration } } = getState();

        const appointment = {
            userId:             engineerId,
            creatorId:          user.userId,
            jobId:              job.jobId,
            startDate:          startTime,
            endDate:            dayjs( startTime ).add( defaultDuration, 'minutes' ).format()
        };

        // add the appointment, update the job, cancel the token, email the user...
        _asyncAddScheduleAppointment({ appointment, job, sendEmail: sendAppointment })
            .then( result => {
                if ( result.payload.ok ) {
                    _asyncSetTokenStatus({}, void 0, [ TokenStatus.used, token ])
                        .then( () => dispatch( setProgressAction( progress ) ) );
                }
            });
    }
    else
        dispatch( setProgressAction( progress ) );
};

const findScheduleJob = tokenId => async ( dispatch, getState ) => {
    const { scheduling: { job, token } } = getState();

    if ( tokenId && !job && token !== tokenId )
    {
        dispatch( setToken( tokenId ) );
        _findScheduleJob( tokenId )
            .then( result => {
                if ( result.status === "invalid" ) {
                    dispatch( setScheduleError( tokenError[ result.reason ] || __( "This link is invalid" ) ) );
                    dispatch( setProgress( progressStage.UNKNOWN ) );
                }
                else {
                    dispatch( setAppointment( result ) );
                    dispatch( setProgress( progressStage.LANDING ) );

                    // we've found the job and hence the integration client,
                    // so we can find the first week's engineers right away
                    // to prevent a delay on the calendar screen
                    findEngineerTimes( "" )( dispatch, getState );
                }
            })
            .catch( reason => {
                console.error( '_findScheduleJob failed', reason );

                dispatch( setProgress( progressStage.UNKNOWN ) );
            });
    }
    else if ( !tokenId )
        dispatch( setProgress( progressStage.UNKNOWN ) );
};

const findEngineerTimes = dateStr => async ( dispatch, getState ) => {

    // an empty dateStr means "start of today"; it's not "start of monday" because the calendar
    // is blocked from dates before today.
    if ( !dateStr )
        dateStr = dayjs().startOf( 'day' ).format();

    const startWeek = dayjs( dateStr );

    const schedulingState = getState().scheduling;

    // this catches the first load of the calendar, when the data was retrieved early.
    // The data may still be loading but we don't have to do anything.
    // Later; not sure this is still happening, haven't seen this log message at all.
    if ( schedulingState.weekStart === dateStr )
        return;

    dispatch( setWeekStart( dateStr ) );

    // changing week clears the appointment, since we now have exclusion data for a different week
    dispatch( setStartTime( '' ) );

    // if this week's data is already loaded, don't request it again
    if ( schedulingState.weekData[ dateStr ]) {
        dispatch( setCurrentWeekData({ currentWeekData: schedulingState.weekData[ dateStr ] }) );
        return;
    }

    // this will render the calendar (if on that page); we don't know what the new times will be (the engineer list
    // may have changed) so retain the times from the old week to prevent a pointless change.
    // Note that the first week will usually be loaded before the calendar renders since we request
    // that data on entry to the landing page.
    dispatch( setCurrentWeekData({ currentWeekData: {
        loading:        true,
        startMinute:    schedulingState?.currentWeekData?.startMinute || 540,
        endMinute:      schedulingState?.currentWeekData?.endMinute || 1020
    } }) );

    // get times from now til COB Friday
    _asyncFetchScheduleByRange( null, void 0, undefined, {
        from:    startWeek.format(),
        to:      startWeek.day( 5 ).hour( 20 ).minute( 0 ).second( 0 ).format()
    })
        // eslint-disable-next-line max-statements
        .then( result => {

            const { payload: { appointments, calendarEvents, engineers, settings } } = result;
            const { clientAppointmentSettings, clientAppointmentSettings: { openHours }, logoURL, timeEntrySettings: { businessHours } } = settings.data;

            // old orgs might not have open hours
            let organisationHours = openHours ?? businessHours;

            // this enables progression from the landing page or displays an error
            if ( engineers.length > 0 ) 
                dispatch( setFoundEligibles() );
            else {
                dispatch( setScheduleError( __( "No Engineers are eligible for Client Appointments. Please contact Licorice Support." ) ) );
                dispatch( setProgress( progressStage.UNKNOWN ) );
                return;
            }

            // if this is the first week we've retrieved, store the settings
            if ( !schedulingState.defaultDuration ) {
                if ( !clientAppointmentSettings.onlineAppointmentsEnabled ) {
                    dispatch( setScheduleError( __( "Client Appointments have been disabled" ) ) );
                    dispatch( setProgress( progressStage.UNKNOWN ) );
                    return;
                }

                // do we need a 7 day view, ie are Sat or Sun in the organisation hours?
                const showWeekends = organisationHours.some( bh => bh.weekdayMap & 0b1100000 );

                dispatch( setSettingsSchedule({ 
                    ...clientAppointmentSettings, 
                    logoURL,
                    openHours: organisationHours,
                    showWeekends
                }) );
            }

            const { defaultDuration, prePadding, postPadding, minimumLeadTimeHours, maximumLeadTimeDays } = clientAppointmentSettings;

            // first group appts by user, then add calendar events to those groups
            const engineerMap = engineers.reduce( makeMapById( 'userId' ), {});
            let events = engineers.reduce( ( acc, cur ) => (
                {
                    ...acc,
                    [ cur.userId ]: {
                        id:             cur.userId,
                        name:           cur.name,
                        events:         [ ],

                        // use engineer hours if custom times in use, otherwise organisation hours
                        businessHours:  unwrapBusinessHours( organisationHours ),
                        engineerHours:  cur.preferences.overrideOrgHours && unwrapBusinessHours( cur.preferences.businessHours )
                    }
                }
            ), {});
            events = appointments.filter( appt => engineerMap[ appt.userId ]).reduce( eventReduce( 'userId' ), events );
            events = calendarEvents.filter( ce => engineerMap[ ce.userId ]).reduce( eventReduce( 'userId' ), events );
            const engineersWithEvents = Object.values( events );

            const exclusions = [];
            const today = dayjs( dateStr );
            const startOfWeekDayNumber = ( today.day() + 6 ) % 7;

            // calculate calendar time range for this week based on the organisation's open hours, or the business hours if no open hours have been defined
            const startMinute = Math.min( ...organisationHours.map( bh => bh.workdayStartH * 60 + bh.workdayStartM ) );
            const endMinute = Math.max( ...organisationHours.map( bh => bh.workdayEndH * 60 + bh.workdayEndM ) );
            const numberSlots = ( endMinute - startMinute ) / SLOT_MINUTES + 1;

            // add an exclusion to block out the lead time today; we also need to end at the next half-hour.date
            const exclusionBg = '#d0d0d0';

            // can't combine these, not sure why not
            let now = dayjs();
            now = now.add( minimumLeadTimeHours, 'hours' ).add( 30 - ( now.minute() % 30 ), 'minutes' );
            exclusions.push({ start: today.format(), end: now.format(), background: exclusionBg });

            // we'll use the maximum lead time to prevent navigating to the week past the limit,
            // but we still need to block out the remainder (if any) of the final week.
            // For reference, maximum lead time of 1 (days) means you can schedule today and tomorrow.
            const lastValidDay = dayjs().add( maximumLeadTimeDays + 1, 'days' ).hour( 0 ).minute( 0 ).second( 0 );
            exclusions.push({
                start:      lastValidDay.format(),
                end:        dayjs( lastValidDay ).add( 5, 'days' ).hour( 23 ).minute( 59 ).second( 0 ).format(),
                background: exclusionBg
            });

            // turns out calculating absolute exclusions isn't enough; if all engineers
            // are adjacent to both sides of a specific time, then we can't allocate the time
            // half an hour prior to that time, since all engineers are busy in either the first
            // half or the second half of the appt. Yes, this depends on the default time for an appt.
            // Work out it crudely, ie use the function for available engineers at a given time to test
            // every timeslot in the week.
            let exclusionStart;

            // we must find a gap that fits the padding as well; the actual exclusion times
            // are adjusted for prePadding later, eg if we have prePadding of 30 and we find a gap, we
            // actually want to start the appt 30 mins later.
            const slotEngineerLists = {};

            // run a loop for every remaining day in the week, starting at 0 for the first day (not Monday)
            // so on Monday it goes 0 thru 6, Thursday 0 thru 3, Sunday 0 thru 0. We have the first day index
            // (0 = Monday) in startOfWeekDayNumber.
            for ( let d = 0; d <= 6 - startOfWeekDayNumber; d++ ) {
                let t = dayjs( dateStr ).add( d, 'days' ).add( startMinute, 'minutes' );
                exclusionStart = '';
                for ( let slot = 0; slot < numberSlots; slot++ ) {
                    const timeSlot = t.format();
                    const slotEngineers = availableEngineers( engineersWithEvents, t, defaultDuration, prePadding, postPadding );

                    if ( slotEngineers.length || slot === numberSlots - 1 ) {

                        // save list
                        if ( slotEngineers.length )
                            slotEngineerLists[ timeSlot ] = slotEngineers;

                        if ( exclusionStart ) {

                            // exclusions ending before "now" (actually next 30min mark) can be ignored, and exclusions starting before "now"
                            // should start "now" instead. This is so we don't have overlapping exclusions, which will display
                            // wrong due to use of opacity.
                            if ( now.isBefore( t ) ) {
                                exclusions.push({
                                    start: ( dayjs( exclusionStart ).isBefore( now ) ) ? now.format() : exclusionStart,
                                    end:   t.format()
                                }); }

                            exclusionStart = '';
                        }
                    }
                    else {
                        if ( !exclusionStart )
                            exclusionStart = t.format();

                    }
                    t = t.add( SLOT_MINUTES, 'minutes' );
                }
            }

            const weekData = {
                startWeek:      dateStr,
                startMinute,
                endMinute,
                engineers:      engineerMap,
                engineersWithEvents,
                exclusions,
                slotEngineerLists,
                lastValidDay: lastValidDay.format()
            };

            dispatch( setWeekData( weekData ) );

            if ( getState().scheduling.weekStart === dateStr )
                dispatch( setCurrentWeekData({ currentWeekData: weekData }) );

        });

};

/** reducers */

const weekDataReducer = ( draft, data ) => {
    draft.weekData[ data.startWeek ] = data;
};

const engineerIdReducer = ( draft, engineerId ) => {
    draft.engineerId = engineerId;
    const engineer = draft.currentWeekData.engineers[ engineerId ];
    draft.engineerName = engineer?.name ?? '';
};

/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setProgressAction ]:      ( draft, value ) => draft.progress = value,
    [ setAppointment ]:         genericReducer,
    [ setJobTitle ]:            ( draft, value ) => draft.job.title = value,
    [ setJobDescription ]:      ( draft, value ) => draft.job.description = value,
    [ setJobPriority ]:         ( draft, value ) => draft.job.priority = value,
    [ setWeekStart ]:           ( draft, value ) => draft.weekStart = value,
    [ setWeekData ]:            weekDataReducer,
    [ setCurrentWeekData ]:     genericReducer,
    [ setEngineerId ]:          engineerIdReducer,
    [ setStartTime ]:           ( draft, value ) => draft.startTime = value,
    [ setToken ]:               ( draft, token ) => draft.token = token,
    [ setScheduleError ]:       ( draft, error ) => draft.error = error,
    [ setSendAppointment ]:     ( draft, value ) => draft.sendAppointment = value,
    [ setSettingsSchedule ]:    genericReducer,
    [ setFoundEligibles ]:      ( draft ) => draft.foundEligibles = true
};

export {
    setProgress,
    progressStage,
    findScheduleJob,
    findEngineerTimes,
    endFromStart,
    SLOT_MINUTES as slotMinutes
};

export default ezRedux( reducers, initialState );
