/*********************************************************************************************************************
 * @file UserCalendar component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { PureComponent, createRef } from 'react';

import { AppointmentState, MINUTE } from '@licoriceio/constants';
import { numMinutesToString } from '@licoriceio/utils';
import { Popover } from '@mui/material';
import enGb from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';

import { DEFAULT_APPOINTMENT_DURATION } from '../../constants.js';
import { setExpandCalendarUp, setExpandCalendarDown, setUpdateCalendarEventStatics } from '../../redux/actions/index.js';
import { TypeAheadControl } from '../../redux/reducers/typeAhead.js';
import { selectUserCalendarEvents, selectSearchPanelShown, jobOnPegboard } from '../../redux/selectors/index.js';
import * as scss from '../../scss/UserCalendar.module.scss';
import { __, _$ } from '../../utils/i18n.jsx';
import { SelectStyle } from '../../utils/misc.js';
import { makeDefaultEventDates } from '../../utils/user-prefs.js';
import {
    UX_EVENT_POPUP_CANCEL, UX_EVENT_POPUP_COPY, UX_EVENT_POPUP_MOVE, UX_EVENT_POPUP_DONE, UX_EVENT_POPUP_COPY_TO, UX_EVENT_POPUP_MOVE_TO, UX_EVENT_POPUP_DELETE,
    UX_CALENDAR_STT_CELL
} from '../../ux-constants.js';
import { deleteCalendarEventRequest } from '../busycard/reducer.js';
import DynamicSelect from '../common/DynamicSelect.jsx';
import EventTooltip from '../common/EventTooltip.jsx';
import { LicoPopupMenu, LicoPopupMenuItem, LicoIcon, WarningConfirmDialog } from '../common/index.js';
import FullCalendar, { dayGridPlugin, timeGridPlugin, interactionPlugin } from '../FullCalendar.jsx';
import { sendPendingNote } from '../jobcard/reducer.js';
import { dragJobToPegboard } from '../pegboard/reducer.js';

import {
    createAppointmentThunk, getDateRangeThunk,
    selectUserCalendarState, OUT_OF_HOURS_GROUP, appointmentRemovedThunk,
    refreshCalendarApptsThunk
} from './reducer.js';
import { addCalendarEventRequest } from './requests.js';
import { setExistingJobThunk, setExistingBusyCardThunk } from './sharedThunks.js';
import TeammateCalendarHeader from './TeammateCalendarHeader.jsx';
import { addJobThunk, setDragInfoThunk, patchTimedEventThunk, rescheduleTimedEventThunk } from './thunks.js';

registerLocale( 'en-gb', enGb );

const { userCalendar, avatar, calendar_scroll_parent, greenMenuIcon, redMenuIcon } = scss;

const SLOT_LABEL_MINUTES = 30;

// events shorter than this don't display title, description or resize handle
const MIN_FULL_EVENT_DURATION_MSECS = 30 * MINUTE;

const renderEventContent = info => {

    const { timeText, event: { start, end, allDay, title, groupId, extendedProps: { calendarEventId, type, description, companyName, providerJobId } } } = info;
    const fullDisplay = start && end
        ? ( end.getTime() - start.getTime() ) > MIN_FULL_EVENT_DURATION_MSECS
        : true;

    // don't do anything for the pseudo-events used to display out-of-hours bg
    if ( groupId === OUT_OF_HOURS_GROUP )
        return;

    // FullCalendar won't let us put a tooltip around the whole fc-event block (well, it doesn't show up if you do)
    // so we're just putting tips around each content chunk. Use a local function to save repetition (but is this a performance hit?)
    const Tipped = ({ children }) => ( <EventTooltip
        heading={companyName || type}
        summary={title}
        description={description}
        providerJobId={providerJobId}>
        {children}
    </EventTooltip> );

    Tipped.propTypes = {
        children: PropTypes.node
    };

    // Using || in the expressions below because the potentially missing items are "", not null.

    return calendarEventId
        ? <>
            {!allDay && <Tipped >
                <div className="time-client">
                    <span className="fc-time">
                        {timeText}
                    </span>
                    <span className="fc-type">
                        {fullDisplay ? type : title || type }
                    </span>
                </div>
            </Tipped>}
            {fullDisplay &&
                <Tipped>
                    <span className="fc-title">
                        {title}
                    </span>
                </Tipped>
            }
        </>
        : <>
            {!allDay && <Tipped>
                <div className="time-client">
                    <span className="fc-time">
                        {timeText}
                    </span>
                    <span className={fullDisplay ? "client" : "description" }>
                        {fullDisplay ? companyName : title || companyName || description}
                    </span>
                </div></Tipped>}
            {fullDisplay && <>

                <Tipped>
                    <div className="fc-title">
                        {allDay ? title || companyName || description : title}
                    </div>
                </Tipped>

                {!allDay &&
                <Tipped>
                    <div className="description">
                        {description}
                    </div>
                </Tipped>}
            </>}
        </>;
};

const generateEventClassNames = info => {

    const { event: { start, end, groupId, extendedProps: { calendarEventId, priority, state, isPrivate, pending, highlight, newItemFlag } } } = info;

    // background groups just use the group name as the class
    if ( groupId )
        return [ groupId ];

    const classes = [ priority, state ];
    if ( start && end ) {

        // is this event too short to display sizer handle?
        const duration = end.getTime() - start.getTime();
        if ( duration < MIN_FULL_EVENT_DURATION_MSECS )
            classes.push( "no-resizer" );
    }

    // temporary import appts
    if ( newItemFlag )
        classes.push( 'converting' );

    if ( calendarEventId )
        classes.push( isPrivate ? 'personal-calendar-event' : 'internal-calendar-event' );

    if ( pending )
        classes.push( 'pending' );

    if ( highlight )
        classes.push( 'highlight' );

    return classes;
};

/**
 * @typedef {object} UserCalendarState
 * @property {boolean} showDatePicker
 * @property {object} [eventMenuInfo]
 * @property {boolean} selectCopyEngineerInProgress
 * @property {boolean} selectMoveEngineerInProgress
 */

class UserCalendar extends PureComponent
{
    constructor( props )
    {
        super( props );

        /** @type {UserCalendarState} */
        this.state = {
            showDatePicker:                 false,
            eventMenuInfo:                  null,
            selectCopyEngineerInProgress:   false,
            selectMoveEngineerInProgress:   false,
            deleteConfirmationInfo:         null
        };

        this._datePickerInputRef = createRef();
        this._calendarRef = createRef();
        this._scrollTimer = 0;
        this._dwellPeriodMsecs = 500;
    }

    componentDidMount() {
        if ( this._calendarRef.current )
            this.props.registerCalendarRef( this.props.user.userId, this._calendarRef );
    }
    // Event interactions
    //
    // Click on date (dateClick)
    //  open job card
    //
    // Extend existing appt (eventResize)
    //  patch appt end date
    //
    // Move existing appt (from this calendar (eventDrop) or other user (eventReceive) doesn't matter; the new appt is owned by this user either way)
    //  patch appt to rescheduled
    //  add new appt owned by me, same job
    //
    // Drag from PB (eventReceive)
    //  add new appt owned by me, same job
    //
    // Drag from search panel (eventReceive)
    //  add appt

    /**
     * Called when something is dragged into a calendar from an external source (pegboard, other calendar, etc)
     * @param info - details of event, as set by the external source
     * @this {{ state: UserCalendarState }}
     */
    eventReceived = info => {
        const event = info.event;
        const { extendedProps: { appointmentId, calendarEventId, isPrivate, userId: originalUserId } } = event;
        const { rescheduleTimedEventThunk, createAppointmentThunk, user: { userId }, setUpdateCalendarEventStatics } = this.props;

        if ( appointmentId || calendarEventId ) {
            // move an existing appointment or event
            if ( calendarEventId && isPrivate )
            {
                // can't move private events; it's easy enough to not make any change, but the original
                // event has been dragged from the source so it must be replaced/refreshed.
                // No data has changed in redux so just rebuild the statics.
                setUpdateCalendarEventStatics( originalUserId );
            }
            else
                rescheduleTimedEventThunk( userId, event );
        }
        else {
            // drag from pegboard, other calendar or search panel
            createAppointmentThunk( userId, event );
        }

        event.remove();

        this.props.setDragState( false );
    };

    handleRightClick = ( e, event ) => {
        e.preventDefault();
        e.stopPropagation();
        const { extendedProps: { newItemFlag } } = event;
        if ( newItemFlag )
            return;
        this.setState({ eventMenuInfo: { event, x: e.clientX, y: e.clientY } });
    };

    startDragOperation = info => {
        const handles = document.getElementsByClassName( "base-calendar-scroll-handle" );
        const { jsEvent: { altKey }, event: { extendedProps: { jobId, appointmentId, calendarEventId } } } = info;
        const { pendingNote, sendPendingNote } = this.props;

        // if an event is dragged to the PB, timelogs are changed, so we have to send any pending note
        // at drag start
        if ( jobId === pendingNote?.jobId )
            sendPendingNote();

        this.props.setDragInfoThunk({
            altPressed:     altKey,
            dragEventId:    appointmentId || calendarEventId
        });

        this.props.setDragState( true );

        Array.from( handles ).forEach( ( handle ) => {
            if ( handle instanceof HTMLElement )
                handle.style.opacity = "0.5";

        });
    };

    // called during drag ops to clear the highlight from both handles in case
    // we've left the active handle, and at the end of drag to hide the handles (and
    // clear the highlight)
    clearActiveHandles = ( hideHandles = true ) => {
        const handles = document.getElementsByClassName( "base-calendar-scroll-handle" );

        Array.from( handles ).forEach( ( handle ) => {
            handle.classList.remove( "active-calendar-scroll-handle" );
            if ( hideHandles && handle instanceof HTMLElement )
                handle.style.opacity = "0";

        });
    };

    setAppointmentState = newState => {
        const { patchTimedEventThunk } = this.props;

        const { eventMenuInfo } = this.state;
        if ( !eventMenuInfo ) return;

        const { event: { extendedProps: { userId, appointmentId, jobId } } } = eventMenuInfo;

        patchTimedEventThunk({
            userId,
            appointmentId,
            jobId,
            data:           {
                state: newState
            }
        });

        this.setState({ eventMenuInfo: undefined });
    };

    drawSlotLabels = info => {
        const { user, setExpandCalendarUp, setExpandCalendarDown, topExpanded, bottomExpanded, earlyEventsInView, lateEventsInView, minStartTime, maxEndTime } = this.props;
        const { date, text } = info;
        const slotTime = date.getHours() * 60 + date.getMinutes();
        return ( slotTime === minStartTime )
            ? <div className="slot-label-arrow up">
                {text}
                <LicoIcon
                    className={`slot-icon ${earlyEventsInView ? 'highlight' : ''}`}
                    icon={topExpanded ? "angle-down" : "angle-up"}
                    onClick={() => setExpandCalendarUp( user.userId )}
                />
            </div>
            : ( maxEndTime - slotTime < SLOT_LABEL_MINUTES && slotTime <= maxEndTime )
                ? <div className="slot-label-arrow down">
                    {text}
                    <LicoIcon
                        className={`slot-icon ${lateEventsInView ? 'highlight' : ''}`}
                        icon={bottomExpanded ? "angle-up" : "angle-down"}
                        onClick={() => setExpandCalendarDown( user.userId )}
                    />
                </div>
                : <div>{text}</div>;
    };

    //  jobOnPegboard return the timeLogId if on pegboard




    deleteCalendarEventFromMenu = doDelete => {
        const { deleteCalendarEventRequest } = this.props;

        const { eventMenuInfo } = this.state;
        if ( !eventMenuInfo ) return;

        if ( doDelete ) {
            const { event: { extendedProps: { userId, calendarEventId } } } = eventMenuInfo;

            deleteCalendarEventRequest({
                userId,
                calendarEventId
            });
        }

        this.setState({ eventMenuInfo: undefined, deleteConfirmationInfo: null });
    };

    DatePickerPopup = () => ( <Popover
        anchorEl={this._datePickerInputRef.current}
        anchorOrigin={{
            vertical:   'top',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical:   'top',
            horizontal: 'left'
        }}
        open={true}
        onClose={ ( event, reason ) => {
            if ( reason === 'backdropClick' || reason === 'escapeKeyDown' )
                this.setState({ showDatePicker: false });
        }}
    >
        <DatePicker
            locale="en-gb"
            onChange={date => {
                this.setState({ showDatePicker: false });
                this._calendarRef.current?.getApi().gotoDate( date );
            }}
            inline
        />
    </Popover> );

    DeleteConfirmation = ({ deleteConfirmationInfo }) => (
        <WarningConfirmDialog
            title={_$( "Delete this {name}?", deleteConfirmationInfo )}
            message={_$( "Are you sure you want to delete this {name}?", deleteConfirmationInfo )}
            isOpen={!!deleteConfirmationInfo}
            onCancel={deleteConfirmationInfo?.cancel}
            onContinue={deleteConfirmationInfo?.continue}
        />
    );

    EventPopupMenu = () => {
        const {
            user,
            rescheduleTimedEventThunk,
            createAppointmentThunk,
            addCalendarEventRequest
        } = this.props;
        const {
            eventMenuInfo,
            selectCopyEngineerInProgress,
            selectMoveEngineerInProgress
        } = this.state;

        return <LicoPopupMenu
            id="appointment-menu"
            anchorPosition={eventMenuInfo ? { left: eventMenuInfo.x, top: eventMenuInfo.y } : undefined}
            open={!!eventMenuInfo}
            onClose={() => this.setState({ eventMenuInfo: null })}
        >
            { ( eventMenuInfo && ( eventMenuInfo.event.extendedProps.appointmentId || !eventMenuInfo.event.extendedProps.isPrivate ) ) && [

                <LicoPopupMenuItem
                    onClick={() => {
                        this.setState({ selectCopyEngineerInProgress: true });
                    }}
                    icon="plus-circle"
                    data-ux={UX_EVENT_POPUP_COPY}
                    key="copy"
                >
                    {__( "Copy to other engineer" )}
                </LicoPopupMenuItem>,

                <LicoPopupMenuItem
                    onClick={() => {
                        this.setState({ selectMoveEngineerInProgress: true });
                    }}
                    icon="arrow-circle-right"
                    data-ux={UX_EVENT_POPUP_MOVE}
                    key="move"
                >
                    {__( "Move to other engineer" )}
                </LicoPopupMenuItem>
            ]
            }

            {( selectCopyEngineerInProgress || selectMoveEngineerInProgress ) && <div>
                <DynamicSelect
                    name={TypeAheadControl.engineers}
                    autoFocus={true}
                    initialText={""}
                    style={SelectStyle.chipAdder}
                    excludedIds={[ user.userId ]}
                    dataName={selectCopyEngineerInProgress ? UX_EVENT_POPUP_COPY_TO : UX_EVENT_POPUP_MOVE_TO}
                    onKeyDown={( e ) => { e.stopPropagation(); }}
                    onChange={( e, value ) => {
                        const event = eventMenuInfo.event;
                        if ( event ) {
                            const { fullRecord: { userId } } = value;
                            const { title, extendedProps: { appointmentId, typeId, description, isPrivate } } = event;

                            this.setState({ eventMenuInfo: null, selectCopyEngineerInProgress: false, selectMoveEngineerInProgress: false });

                            if ( selectMoveEngineerInProgress )
                                rescheduleTimedEventThunk( userId, event );
                            else {

                                if ( appointmentId )
                                    createAppointmentThunk( userId, event );
                                else {
                                    const eventDates = makeDefaultEventDates( event );

                                    addCalendarEventRequest({
                                        userId,
                                        title,
                                        licoriceNameId:     typeId,
                                        description,
                                        isPrivate,
                                        ...eventDates
                                    });
                                }
                            }
                        }
                    }}
                    onBlur={() => this.setState({ eventMenuInfo: null, selectCopyEngineerInProgress: false, selectMoveEngineerInProgress: false })}
                />
            </div>}

            {
                eventMenuInfo && eventMenuInfo.event.extendedProps.appointmentId
                    ? <LicoPopupMenuItem
                        iconClassName={eventMenuInfo && eventMenuInfo.event.extendedProps.state === AppointmentState.done ? greenMenuIcon : ''}
                        onClick={() => this.setAppointmentState( eventMenuInfo?.event.extendedProps.state === AppointmentState.done
                            ? AppointmentState.active
                            : AppointmentState.done )}
                        icon="check-circle"
                        data-ux={UX_EVENT_POPUP_DONE}
                    >
                        {__( "Done" )}
                    </LicoPopupMenuItem>
                    : null
            }

            {
                eventMenuInfo && eventMenuInfo.event.extendedProps.appointmentId && eventMenuInfo.event.extendedProps.state !== AppointmentState.done
                    ? <LicoPopupMenuItem
                        onClick={() => {
                            this.setAppointmentState( AppointmentState.cancelled );
                        }}
                        icon="minus-circle"
                        data-ux={UX_EVENT_POPUP_CANCEL}
                    >
                        { __( "Cancel" )  }
                    </LicoPopupMenuItem>
                    : null
            }

            {
                eventMenuInfo && eventMenuInfo.event.extendedProps.calendarEventId &&
                        <LicoPopupMenuItem
                            iconClassName={redMenuIcon}
                            onClick={() => this.setState({
                                deleteConfirmationInfo: {
                                    name:       __( 'Event' ),
                                    continue:   () => this.deleteCalendarEventFromMenu( true ),
                                    cancel:     () => this.deleteCalendarEventFromMenu( false )
                                }
                            })}
                            icon="times-circle"
                            data-ux={UX_EVENT_POPUP_DELETE}
                        >
                            {__( "Delete" )}
                        </LicoPopupMenuItem>
            }

            {/* Only used for test purposes
                    <LicoPopupMenuItem
                        onClick={() => {
                            appointmentRemovedThunk( eventMenuInfo.event.extendedProps.appointmentId );
                        }}
                        icon="warning"
                    >
                        {__( "Delete appointment" )}
                    </LicoPopupMenuItem>
                    */}

        </LicoPopupMenu>;

    };

    render() {
        const {
            user,
            appointments,
            calendarEvents,
            setExistingJobThunk,
            setExistingBusyCardThunk,
            addJobThunk,
            patchTimedEventThunk,
            rescheduleTimedEventThunk,
            dragJobToPegboard,
            getDateRangeThunk,
            setDragInfoThunk,
            topExpanded,
            bottomExpanded,
            outOfHourEvents,
            minStartTime,
            maxEndTime,
            className = "",
            defaultDurationMins,
            viewStart,
            // eslint-disable-next-line no-unused-vars
            searchPanelShown  // added this prop to force the calendar to render when the search panel is open
        } = this.props;
        const {
            eventMenuInfo,
            showDatePicker,
            deleteConfirmationInfo
        } = this.state;

        // noinspection JSVoidFunctionReturnValueUsed
        return <div className={`${userCalendar} ${className}`} id={user.userId}>

            {className === "otherUser"
                ?   <TeammateCalendarHeader user={user} />
                :   <div className={avatar}>
                    <input ref={this._datePickerInputRef} type="text" className="datepicker" />
                </div>
            }

            {
                showDatePicker && <this.DatePickerPopup />
            }

            <div className={calendar_scroll_parent}>
                <div id={"left-scroll-handle-" + user.userId} data-userid={user.userId} className='left-scroll-handle base-calendar-scroll-handle'>
                    <LicoIcon icon="chevron-left-solid" style={{ fontSize: 55 }} />
                </div>

                {
                    !!deleteConfirmationInfo && <this.DeleteConfirmation deleteConfirmationInfo={deleteConfirmationInfo} />
                }

                <FullCalendar
                    ref={this._calendarRef}
                    initialView="fiveDay"
                    initialDate={viewStart}
                    firstDay={1}
                    plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                    headerToolbar={{
                        left:   "today prev next datepicker",
                        center: "title",
                        right:  "threeDay fiveDay week"
                    }}

                    buttonText={{
                        today: __( "Today" )
                    }}

                    datesSet={info => getDateRangeThunk( user.userId, info ) }
                    customButtons={{
                        // Add custom datepicker
                        datepicker: {

                            // this doesn't refer to an icon, but the class triggers the display of an SVG.
                            // We can't display custom icons in here without changing to use the Bootstrap theme.
                            icon:   ' calendar-datepicker-icon',
                            click:  () => void this.setState({ showDatePicker: true })
                        }
                    }}

                    weekends={true}
                    eventSources={[
                        {
                            events:             appointments
                        },
                        {
                            events:             calendarEvents
                        },
                        {
                            events:             outOfHourEvents
                        }
                    ]}

                    selectable
                    select={info => {

                        // can't drag across 2 days; note that STTs are always midnight to midnight, ie 2 different days, so
                        // we check for 3 days in that case.
                        const startDate = new Date( info.start );
                        const endDate = new Date( info.end );

                        // cope with sunday being 0 but shown after saturday on our calendar
                        const days = ( endDate.getDay() - startDate.getDay() + 7 ) % 7;

                        if ( info.allDay ? days !== 1 : days !== 0 )
                            return;

                        addJobThunk({
                            date:       startDate,
                            userId:     user.userId,
                            allDay:     info.allDay,
                            duration:   info.allDay ? 0 : endDate.getTime() - startDate.getTime()
                        });
                    }}

                    allDayContent={( ) => {
                        return <div style={{ minHeight: 40 }} className="some-time-today">{__( "Some Time Today" )}</div>;
                    }}
                    dayCellClassNames={[ UX_CALENDAR_STT_CELL ]}

                    // FC doesn't mind if the minute component is >= 60
                    defaultTimedEventDuration={`0:${defaultDurationMins}`}

                    editable={true}
                    eventClick={info => info.event.extendedProps.calendarEventId
                        ? setExistingBusyCardThunk( info.event.extendedProps )
                        : setExistingJobThunk( info.event.extendedProps )}
                    eventDurationEditable={true}
                    eventResize={info => {
                        const { event: { allDay, start, end: newEnd }, oldEvent: { end: oldEnd, extendedProps: { appointmentId, calendarEventId } } } = info;

                        // events can't extend into another day; if this is requested, do a local update
                        // to set the event back to the old size.
                        const dayGap = newEnd.getDay() - start.getDay();
                        const midnightEnd = newEnd.getHours() === 0 && newEnd.getMinutes() === 0;
                        const [ end, localOnly ] = ( dayGap === 0 || ( dayGap === 1 && midnightEnd ) )
                            ? [ newEnd, false ]
                            : [ oldEnd, true ];

                        patchTimedEventThunk({
                            [ appointmentId ? 'userId' : 'userId' ]:   user.userId,
                            appointmentId,
                            calendarEventId,
                            localOnly,
                            data:                                       {
                                endDate:        allDay || end === null
                                    ? null
                                    : new Date( end.valueOf() ).toISOString()
                            }
                        });

                        // remove the temporary event otherwise we can get doubles
                        info.event.remove();
                    }}

                    nowIndicator={true}
                    slotMinTime={topExpanded ? "00:00" : numMinutesToString( minStartTime ?? 540 )}
                    slotMaxTime={bottomExpanded ? "23:59" : numMinutesToString( maxEndTime ? maxEndTime + 15 : 1080 )}
                    displayEventEnd={false}
                    slotDuration={"00:15:00"}
                    slotLabelInterval={{ minutes: SLOT_LABEL_MINUTES }}

                    contentHeight={"auto"}

                    // must be true to accept cross-calendar moves
                    droppable={true}

                    // affects drops from PB & TC, not other calendars
                    dropAccept={el => {
                        return el.classList.contains( "pegboard-full-slot" ) || el.classList.contains( "search-panel-job" );
                    }}

                    // received an external event, eg PB, TC, other calendars.
                    eventReceive={this.eventReceived}

                    dragRevertDuration={0}
                    dayHeaderContent={arg => {
                        const mdate = dayjs( arg.date );
                        return mdate.format( "ddd D-MMM" );
                    }}

                    // this is moving an event inside a single calendar
                    eventDrop={info => rescheduleTimedEventThunk( user.userId, info.event )}

                    eventDragStart={this.startDragOperation}

                    eventDragStop={info => {
                        const { jsEvent: { clientX, clientY }, event: { extendedProps: { jobId, calendarEventId } } } = info;

                        // we have to clear this filter here because a drag that goes nowhere won't trigger eventDrop,
                        // so if we do this in there the filter never clears and the event disappears
                        setDragInfoThunk({ dragEventId: '' });

                        this.props.setDragState( false );

                        this.clearActiveHandles();

                        // TODO need to fix this
                        // if we started a scroll timer, it must be stopped
                        //  this.stopScrollTimer();

                        // nothing to do for calendarEvent drags, they can't go to the PB
                        if ( calendarEventId )
                            return;

                        // eventDragStop is guaranteed to be called after a drag operation;
                        // successful calendar drags will be handled by eventDrop (same calendar)
                        // or eventReceive (different calendar). All we have to handle here are
                        // drags to the pegboard.

                        const pegboard = document.querySelector( '#pegboard-slot-container' );
                        if ( pegboard !== null ) {
                            const pegboardRect = pegboard.getBoundingClientRect();
                            if ( clientX >= pegboardRect.left && clientX <= pegboardRect.left + pegboardRect.width &&
                                clientY >= pegboardRect.top && clientY <= pegboardRect.top + pegboardRect.height
                            )
                                dragJobToPegboard( jobId );
                        }



                        // // stop any vertical scroll timer in MainCalendar
                        // handleDragStop();
                    }}

                    slotLabelContent={this.drawSlotLabels}

                    views={{
                        threeDay: {
                            type:          "timeGrid",
                            duration:      { days: 3 },
                            buttonText:    __( '3 day' )
                        },

                        fiveDay: {
                            type:          "timeGridWeek",
                            duration:      { days: 7 },         // But the weekends aren't showing, so 5.
                            buttonText:    __( '5 day' ),
                            weekends:      false,
                            dateAlignment: "week"
                        },

                        week: {
                            type:          "timeGridWeek",
                            duration:      { days: 7 },
                            weekends:      true,
                            dateAlignment: "week",
                            buttonText:    __( 'Week' )
                        }
                    }}
                    eventContent={renderEventContent}
                    eventClassNames={generateEventClassNames}
                    eventDidMount={arg => {
                        arg.el.addEventListener( 'contextmenu', e => this.handleRightClick( e, arg.event ) );
                    }}
                    eventWillUnmount={arg => {
                        arg.el.removeEventListener( 'contextmenu', e => this.handleRightClick( e, arg.event ) );
                    }}
                />

                {
                    !!eventMenuInfo && <this.EventPopupMenu />
                }

                <div id={"right-scroll-handle-" + user.userId} data-userid={user.userId}  className='right-scroll-handle base-calendar-scroll-handle' >
                    <LicoIcon icon="chevron-right-solid" style={{ fontSize: 55 }} />
                </div>

            </div>
        </div>;
    }

    static propTypes = {
        user:                           PropTypes.object.isRequired,
        className:                      PropTypes.string,
        appointments:                   PropTypes.arrayOf( PropTypes.object ).isRequired,
        calendarEvents:                 PropTypes.arrayOf( PropTypes.object ).isRequired,
        outOfHourEvents:                PropTypes.arrayOf( PropTypes.object ),
        setExistingJobThunk:            PropTypes.func.isRequired,
        setExistingBusyCardThunk:       PropTypes.func.isRequired,
        patchTimedEventThunk:           PropTypes.func.isRequired,
        addJobThunk:                    PropTypes.func.isRequired,
        rescheduleTimedEventThunk:      PropTypes.func.isRequired,
        createAppointmentThunk:         PropTypes.func.isRequired,
        addCalendarEventRequest:        PropTypes.func.isRequired,
        dragJobToPegboard:              PropTypes.func.isRequired,
        getDateRangeThunk:              PropTypes.func.isRequired,
        setDragInfoThunk:               PropTypes.func.isRequired,
        setExpandCalendarUp:            PropTypes.func.isRequired,
        setExpandCalendarDown:          PropTypes.func.isRequired,
        topExpanded:                    PropTypes.bool.isRequired,
        bottomExpanded:                 PropTypes.bool.isRequired,
        earlyEventsInView:              PropTypes.bool.isRequired,
        lateEventsInView:               PropTypes.bool.isRequired,
        sendPendingNote:                PropTypes.func.isRequired,
        minStartTime:                   PropTypes.any,
        maxEndTime:                     PropTypes.any,
        pendingNote:                    PropTypes.object,
        deleteCalendarEventRequest:     PropTypes.func.isRequired,
        defaultDurationMins:            PropTypes.number.isRequired,
        viewStart:                      PropTypes.string.isRequired,
        searchPanelShown:               PropTypes.bool,
        registerCalendarRef:            PropTypes.func.isRequired,
        setDragState:                   PropTypes.func.isRequired,
        setUpdateCalendarEventStatics:  PropTypes.func.isRequired
    };
}


const mapStateToProps = ( state, props ) => {

    // the defaults are here to prevent propTypes complaining about missing required parameters
    const {
        appointments,
        topExpanded = false,
        bottomExpanded = false,
        earlyEventsInView: earlyApptsInView = false,
        lateEventsInView: lateApptsInView  = false,
        outOfHourEvents,
        viewStart,
        minStartTime,
        maxEndTime
    } = selectUserCalendarState( state, props.user.userId );

    const {
        calendarEvents,
        earlyEventsInView: earlyCalendarEventsInView = false,
        lateEventsInView: lateCalendarEventsInView = false
    } = selectUserCalendarEvents( state, props.user.userId );
    const { jobcard: { pendingNote } } = state;

    const { preferences: { defaultAppointmentDuration } } = state.user;

    return {
        outOfHourEvents,
        minStartTime,
        maxEndTime,
        appointments,
        calendarEvents,
        topExpanded,
        bottomExpanded,
        earlyEventsInView:   earlyApptsInView || earlyCalendarEventsInView,
        lateEventsInView:    lateApptsInView || lateCalendarEventsInView,
        pendingNote,
        defaultDurationMins: defaultAppointmentDuration || DEFAULT_APPOINTMENT_DURATION,
        viewStart,
        searchPanelShown:       selectSearchPanelShown( state )
    };
};

const mapDispatchToProps = {
    setExistingJobThunk,
    setExistingBusyCardThunk,
    patchTimedEventThunk,
    addJobThunk,
    rescheduleTimedEventThunk,
    createAppointmentThunk,
    addCalendarEventRequest,
    dragJobToPegboard,
    getDateRangeThunk,
    setDragInfoThunk,
    selectUserCalendarState,
    setExpandCalendarUp,
    setExpandCalendarDown,
    sendPendingNote,
    appointmentRemovedThunk,
    deleteCalendarEventRequest,
    setUpdateCalendarEventStatics
};

export default connect( mapStateToProps, mapDispatchToProps )( UserCalendar );
export { UserCalendar, renderEventContent, MIN_FULL_EVENT_DURATION_MSECS };
