import React, { useEffect } from 'react';

import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import logo from "../../../images/licorice-logo-teal.svg";
import { getVersionInfoThunk, versionRequestType } from '../../../redux/reducers/meta.js';
import { bannerLogo, bannerTooltip } from '../../../scss/BannerLogo.module.scss';
import { __ } from '../../../utils/i18n.jsx';
import { Image } from '../atomic/Image.jsx';
import { LOGO_BYLINE } from "../strings.js";

const BannerLogo = props => {
    const { color, className = '', alt, src, version, getVersionInfoThunk } = props;
    const useColor = color || "var(--teal)";

    useEffect( () => {
        getVersionInfoThunk( versionRequestType.BASIC );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const versionTooltip = <div className={bannerTooltip}>
        <span>{alt ?? LOGO_BYLINE}</span>
        <hr style={{ width: "70%" }} />
        <div><span>{ __( "Version: " )}</span><span>{version.licorice}</span></div>
    </div>;

    return (
        <>
            <Tooltip title={versionTooltip} enterDelay={300}>
                <div className={bannerLogo} onMouseEnter={() => getVersionInfoThunk( versionRequestType.BASIC )}>
                    <Image className={className} src={src ?? logo} color={useColor} />
                </div>
            </Tooltip>
        </>
    );
};

BannerLogo.propTypes = {
    color:                  PropTypes.string,
    className:              PropTypes.string,
    alt:                    PropTypes.string,
    src:                    PropTypes.string,
    version:                PropTypes.object.isRequired,
    getVersionInfoThunk:    PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    version:               state.meta.version
});

const mapDispatchToProps = {
    getVersionInfoThunk
};

export { BannerLogo };

export default connect( mapStateToProps, mapDispatchToProps )( BannerLogo );
