/*********************************************************************************************************************
 * @file Calendar reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 13-Jan-2021
 *********************************************************************************************************************/

import { AppointmentState } from '@licoriceio/constants';
import { pick, has } from '@licoriceio/utils';
import dayjs from 'dayjs';

import { GET, uri, dragSource } from '../../constants.js';
import { setAddUserCalendar, setDeleteUserCalendar, setAddAppointments, setDeleteAppointment, setPatchAppointment,
    setDragInfo, setDateRange, setExpandCalendarUp, setExpandCalendarDown, setCalendarViewDates, makeBusinessHourEvents, 
    setUpdateAppointmentStatics, setUserFromSettings, setAddAppointment,
    setDeleteJob, setAppointmentImported, setOtherUserTimeLogs, patchOtherUserTimeLogs,
    setLoadOrganisationSettings, clearCalendars  } from '../../redux/actions/index.js';
import { ezRedux, genericReducer, genericRequest, makeMapById, sliceStoredFields } from '../../redux/reducerUtil.js';
import { getJobAppointmentCount, selectNameDataReady } from '../../redux/selectors/index.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { getUserSession, saveToUserSession } from '../../utils/local-storage.js';
import { deleteOnUserId } from '../../utils/misc.js';
import { makeDefaultEventDates } from '../../utils/user-prefs.js';
import { getPreviousPBMouseDownAltKey } from '../pegboard/PegboardSlot.jsx';
import { removeJobFromPegboard } from '../pegboard/reducer.js';
import { extendEngineer } from '../settings/engineer/reducer.js';
import { makeCalendarTimeData } from '../settings/organisation/reducer.js';
import { unwrapBusinessHours } from '../settings/utilities.jsx';

import { findOutOfHoursEvents, mergeToCurrentViewSettings } from './shared.js';
import { 
    setUpdateAppointmentStaticsThunk, getJobCardAppointments, getJobAppointmentHistory, getAppointmentsRequest,
    getCalendarEventsRequest
} from './sharedThunks.js';
import { getJobActionThunk, setAddAppointmentThunk, addAppointmentThunk, patchTimedEventThunk } from './thunks.js';

/**
 * @typedef {object}                UserCalendarState
 * @property {object}               appointmentMap
 * @property {ExtendedEngineer}     user
 * @property {dayjs.Dayjs}          minLoaded
 * @property {dayjs.Dayjs}          maxLoaded
 * @property {Date}                 viewStart
 * @property {Date}                 viewEnd
 * @property {boolean}              topExpanded
 * @property {boolean}              bottomExpanded
 * @property {boolean}              earlyEventsInView
 * @property {boolean}              lateEventsInView
 */

/**
 * @typedef {object}                CalendarState
 * @property {any}                  currentDate
 * @property {object}               userMap
 * @property {array}                _allCalendars
 * @property {boolean}              altPressed
 * @property {string}               dragEventId
 * @property {object}               organisationTimeData
 */

/**
 * @type {CalendarState}
 */
const initialState = Object.freeze({
    userMap:                    {},
    _allCalendars:              [],
    altPressed:                 false,
    dragEventId:                '',
    empty:                      [],
    organisationTimeData:       null
});

const OUT_OF_HOURS_GROUP = 'outOfHours';

/** services */

const _asyncFetchOneAppointment = abstractedCreateAuthRequest( GET, uri.SINGLE_APPOINTMENT );


/** requests */

const fetchOneAppointmentRequest = appointmentId => genericRequest({}, _asyncFetchOneAppointment, setAddAppointmentThunk, [ appointmentId ]);

/** thunk actions */

/**
 * Refreshes the current dates for the specified user, if job count > 0
 * @param {object} payload - userId and count
 * @returns void
 */
const refreshCalendarApptsThunk = payload => async ( dispatch, getState ) => {
    if ( payload.count > 0 ) 
    {
        const { calendar: { userMap } } = getState();
        const userCalendar = userMap[ payload.userId ];
        if ( userCalendar ) 
        {
            const { minLoaded, maxLoaded } = userCalendar;
            dispatch( getAppointmentsRequest( payload.userId, minLoaded, maxLoaded ) );
        }
        else
            console.warn( "Got a refresh request for a non-existent user?", payload );
    }
};

const updateCalendarInSession = ( userId, viewStart ) => {

    const { calendars } = getUserSession();

    // this can happen when resuming a session with saved login; the calendar component
    // generates a date event before we have restored the global user id
    if ( !calendars ) 
        return;
    
    const calendar = calendars.find( calendar => calendar.user.userId === userId );
    calendar.viewStart = viewStart;

    saveToUserSession({ calendars });
};

const removeCalendarFromSession = calendarUserId => {

    const { calendars } = getUserSession();

    if ( !calendars ) 
        throw new Error( `Couldn't remove calendar, no session calendars found` );
    
    saveToUserSession({ calendars: deleteOnUserId( calendars, calendarUserId ) });
};

const getDateRangeThunk = ( userId, info ) => async ( dispatch, getState ) => {
    const state = getState();
    const calendar = state.calendar.userMap[ userId ];
    if ( !calendar )
        return;

    dispatch( setCalendarViewDates({
        userId,
        start:  info.start,
        end:    info.end
    }) );

    updateCalendarInSession( userId, dayjs( info.start ).toISOString() );

    const newMin = dayjs( calendar.minLoaded ).isAfter( info.start ) ? dayjs( info.start ).toISOString() : undefined;
    const newMax = dayjs( calendar.maxLoaded ).isBefore( info.end ) ? dayjs( info.end ).toISOString() : undefined;
    if ( newMin || newMax ) 
    {

        // the retrieval dates are (usually*) different from the new minLoaded/maxLoaded; minLoaded/maxLoaded describes the
        // complete range but we only want to get the new segment. (*It would be technically possible to
        // extend the range at both ends at once, ie a zoom out, but the UI doesn't support it yet)

        // unretrieved appts; basically, if we're extending one end of the range, we want to get from
        // the old limit at that end to the new end. Having calendar.maxLoaded and calendar.minLoaded reversed
        // looks weird but recall we know one of newMin or newMax is defined. (If we implement zoom out, both may be defined)
        dispatch( getAppointmentsRequest( userId, newMin || calendar.maxLoaded, newMax || calendar.minLoaded ) );
        dispatch( getCalendarEventsRequest( userId, newMin || calendar.maxLoaded, newMax || calendar.minLoaded ) );
        dispatch( makeBusinessHourEvents({ userId, minLoaded: newMin || calendar.maxLoaded, maxLoaded: newMax || calendar.minLoaded }) );

        // new range
        dispatch( setDateRange({ userId, dates: { minLoaded: newMin || calendar.minLoaded, maxLoaded: newMax || calendar.maxLoaded } }) );
    }

};

/**
 * Called after external drop (TC, PB) or from appt right-click Copy menu item
 * @param {string} newUserId
 * @param {object} event
 * @returns void
 */
const createAppointmentThunk = ( newUserId, event ) => async ( dispatch ) => {

    const { extendedProps: { jobId, externalSource, timeLogId } } = event;

    const PBAltKey = getPreviousPBMouseDownAltKey();

    const eventDates = makeDefaultEventDates( event );
    if ( !eventDates ) 
    {
        event.remove();
        return;
    }

    const newAppointment = {
        userId:            newUserId,
        jobId,
        ...eventDates
    };

    // add new appt
    dispatch( addAppointmentThunk( newAppointment, event ) );

    // if we came from the pegboard, and we didn't press Alt, delete the job from the PB
    if ( externalSource === dragSource.PEGBOARD && !PBAltKey )
        dispatch( removeJobFromPegboard( timeLogId ) );

};

/**
 * Called on receipt of new data from the provider.
 * All changes started here are frontend only.
 * @param {object} partialAppointment
 * @returns {Promise<void>}
 */
const appointmentDataChangedThunk = partialAppointment => async ( dispatch, getState ) => {

    const state = getState();
    const { calendar: { userMap }, job: { jobMap }, jobcard: { currentJobCardId } } = state;

    // appointmentId is the only field we're sure to see in partialAppointment
    const appointment = getAppointmentFromState( state, partialAppointment.appointmentId );
    let jobId = partialAppointment.jobId;

    if ( appointment ) 
    {

        // make sure jobId is correct now that we have the appt
        jobId = appointment.jobId;
        
        // if the ticket owner has changed, remove it from the current owner's map
        if ( partialAppointment.userId && partialAppointment.userId !== appointment.userId ) 
        {
            dispatch( setDeleteAppointment( appointment ) );

            // if the new owner has a calendar, add the patched appt to it
            if ( userMap[ partialAppointment.userId ]) 
            {

                // recall we can't change the appointment object as it is immutable
                const newAppt = Object.assign({}, { ...appointment, ...partialAppointment });
                dispatch( setAddAppointment( newAppt ) );
            }
        }
        else
            dispatch( setPatchAppointment({ userId: appointment.userId, appointmentId: appointment.appointmentId, data: partialAppointment }) );

        // rebuild all users; this is why we don't use the thunk actions above, there's no
        // point in rebuilding multiple times
        dispatch( setUpdateAppointmentStaticsThunk() );
    }
    else 
    {

        // fetch new appt
        _asyncFetchOneAppointment({}, state.auth, [ partialAppointment.appointmentId ])
            .then( ({ payload: appointment }) => {

                // if the appt owner has a calendar, we have work to do
                if ( userMap[ appointment.userId ]) 
                {

                    // the job may already exist or not
                    if ( !jobMap[ appointment.jobId ])
                        dispatch( getJobActionThunk({ jobId: appointment.jobId }) );

                    // add appointment
                    dispatch( setAddAppointmentThunk( appointment ) );
                }

            });
    }

    // if we know the job id and it's the open job card, refresh the appt list from the db
    if ( jobId === currentJobCardId ) 
    {
        dispatch( getJobCardAppointments( jobId ) );
        dispatch( getJobAppointmentHistory( jobId ) );
    }

};

const appointmentRemovedThunk = appointmentId => async ( dispatch, getState ) => {

    // an appointment has been removed on the backend (probably a rejected assignment).
    // All we have is the appointment id so we have to check each user's appointments
    const { calendar: { userMap } } = getState();
    Object.values( userMap ).some( calendar => {
        if ( calendar.appointmentMap[ appointmentId ]) 
        {
            dispatch( setDeleteAppointment( calendar.appointmentMap[ appointmentId ]) );
            dispatch( setUpdateAppointmentStaticsThunk( calendar.user.userId ) );
            return true;
        }
        return false;
    });
};

// functions

const convertAppointmentToFCEvent = ( appointment, jobMap, clientMap, userMap, pendingAppointmentMap ) => {
    const { jobId, startDate, endDate, appointmentId, state, userId, highlight, newItemFlag } = appointment;

    const { description, priority, companyId, title, providerJobId } = jobMap[ jobId ] ?? {};
    const { companyName } = clientMap[ companyId ] ?? { companyName: '' };
    const now = String( new Date() );

    return {
        start:              startDate,
        end:                endDate,
        allDay:             endDate === null,
        title,  
        editable:           !newItemFlag && state === AppointmentState.active,
        durationEditable:   endDate !== null && state === AppointmentState.active,
        extendedProps:      {
            now,
            appointmentId,
            jobId,
            state,
            durationEditable:           state === AppointmentState.active,
            userId,
            description,
            priority,
            companyName,
            activeJobAppointmentTotal:  getJobAppointmentCount( userMap, jobId ),
            pending:                    pendingAppointmentMap[ appointmentId ],
            highlight,
            newItemFlag,
            providerJobId
        }
    };
};


/**
 * Given the current state wrt both events and view dates, work out if any events lie outside
 * of the user's working hours.
 * @param {object} draft - current state, probably already changed by a reducer
 * @param {string} userId
 */
const findOutOfHoursAppointments = ( draft, userId ) => {
    if ( draft.userMap[ userId ])
        findOutOfHoursEvents( draft, userId, draft.userMap[ userId ].appointmentMap );
};

const buildAppointmentList = ( draft, payload ) => {
    const { userId, jobMap, clientMap, pendingAppointmentMap } = payload;
    const { dragEventId, altPressed, userMap } = draft;

    // we're updating for one or all users (eg if a job title changes, >1 users might have appts for that job )
    const userIds = userId ? [ userId ] : Object.keys( userMap );

    userIds.filter( userId => !!userMap[ userId ]).forEach( userId => {
        const calendar = userMap[ userId ];
        calendar._appointments = Object.values( calendar.appointmentMap ?? {})
            .filter( a => ( a.state === AppointmentState.active || a.state === AppointmentState.done ) &&
            ( altPressed || a.appointmentId !== dragEventId ) )
            .map( a => convertAppointmentToFCEvent( a, jobMap, clientMap, userMap, pendingAppointmentMap ) );
    });
};

// reducers

const makeBusinessHourEventsReducer = ( draft, payload ) => {
    const { userId, minLoaded, maxLoaded } = payload || {};

    const timeData = payload?.timeData || draft.organisationTimeData;

    // if we update the business hours in Settings, we have to recreate the business hour events for
    // all users (without overrides) and all days loaded
    const userIds = userId ? [ userId ] : Object.keys( draft.userMap );
    const resetAll = !userId;
    const resetUser = !minLoaded;

    userIds.filter( userId => resetAll || !!draft.userMap[ userId ]).forEach( userId => {
        const calendar = draft.userMap[ userId ];

        // if this user is overriding org time and we haven't been suppled timeData,
        // it's an organization settings change which we can ignore
        if ( calendar.user.preferences.overrideOrgHours && !payload?.timeData )
            return;

        // save the new time data to the calendar for rendering
        Object.assign( calendar, { ...timeData });

        const { _outOfHourEvents } = calendar;

        // if called from setting change, the draft version of OOHD is obsolete, so
        // use the new data that was passed in by preference.
        const outOfHourDays = timeData?.outOfHourDays || calendar.outOfHourDays;

        if ( resetAll || resetUser )
            _outOfHourEvents.splice( 0, _outOfHourEvents.length );

        // we need to create BH events for a range of days and we can't assume how big the range is (initial load is currently
        // 2 weeks but this may change or become configurable, and we can view 3, 5 or 7 days in a week).
        // We have a list of business hour blocks which can be considered as a template for a single week, Mon-Sun;
        // we need to move through the range and determine for each day which days from the template should apply,
        // and then create those events.

        // if we're not given a range (ie BH settings have been changed) reload the full range
        let currentDay = dayjs( minLoaded ?? calendar.minLoaded );
        const endOfRange = dayjs( maxLoaded ?? calendar.maxLoaded );
        while ( currentDay.isBefore( endOfRange ) ) {

            // we converted the schedule into lists for each day of the week, so just check for blocks on the relevant day.
            // block.weekday is day-of-week index, monday == 0; dayjs().day(), monday == 1 so we need to adjust by +6 %7
            const weekday = ( currentDay.day() + 6 ) % 7;
            if ( has( outOfHourDays, weekday ) ) 
            {

                _outOfHourEvents.push(
                    // eslint-disable-next-line no-loop-func
                    ...outOfHourDays[ weekday ].map( block => ({
                        groupId: OUT_OF_HOURS_GROUP,
                        start:   new Date( currentDay.add( block.startTime, 'minute' ) ),
                        end:     new Date( currentDay.add( block.endTime, 'minute' ) ),
                        display: 'background'
                    }) ) );
            }
            else 
            {
                
                // this means no business hour chunks were defined for this; it may be a weekend or
                // it may not. Add an all day event which will highlight the whole day including STT area.
                _outOfHourEvents.push({
                    groupId: OUT_OF_HOURS_GROUP,
                    start:      new Date( currentDay.add( 0, 'minute' ) ),
                    allDay:     true,
                    display:    'background'
                });
            }

            currentDay = currentDay.add( 1, 'day' );
        }
    });

};

/**
 * @typedef {object} AppointmentWithJob
 * @property {Database.Appointment} appointments
 * @property {Database.Job} jobs
 */

/**
 * @param {object} draft
 * @param {AppointmentWithJob} payload  - Note this comes with the imported job when job was imported
 */
const addAppointmentReducer = ( draft, payload ) => {
    // const newData = { [ payload.appointmentId ]: payload };
    const { appointments: app } = payload.appointments ? payload : { appointments: payload };

    // if we copied an appt to another user, their calendar may not be open
    if ( app.userId in draft.userMap )
        Object.assign( draft.userMap[ app.userId ].appointmentMap, { [ app.appointmentId ]: app });

};

// handles action from engineer settings which is fired for every user, but should
// only update the user draft if one of the displayed users was modified
const conditionalUserReducer = ( draft, user ) => {
    const calendar = draft.userMap[ user.userId ];
    if ( calendar ) 
    {
        Object.assign( calendar.user, 
            {
                ...user, preferences: { ...draft.userMap[ user.userId ].user.preferences, ...user.preferences }
            }); 

        // redo time data in case it was changed
        if ( user.preferences ) 
        {
            const timeData = user.preferences.overrideOrgHours
                ? makeCalendarTimeData( unwrapBusinessHours( user.preferences.businessHours ) )
                : draft.organisationTimeData;
            Object.assign( calendar, timeData );
            makeBusinessHourEventsReducer( draft, { userId: user.userId, timeData });
        }

    }
};

const addUserCalendarReducer = ( draft, payload ) => {

    const user = extendEngineer( payload.user );

    // if this is the login user, we have the organisation business hours which we may use for the login user's calendar
    // but will certainly use to store for other users, so convert them now.
    const organisationTimeData = payload.user.organisationBusinessHours ? makeCalendarTimeData( unwrapBusinessHours( payload.user.organisationBusinessHours ) ) : null;

    // for the login user, we're guaranteed to get something in user.preferences.businessHours, either the override
    // settings or the organisation settings, so we don't have to wait for the organisation data to load.
    const timeData = user.preferences.overrideOrgHours ? makeCalendarTimeData( unwrapBusinessHours( user.preferences.businessHours ) ) : organisationTimeData;

    // On login, add the organisation business hours to calendar state so it's available if the login user 
    // turns off custom hours or when we load another calendar without custom hours
    if ( organisationTimeData )
        draft.organisationTimeData = organisationTimeData;

    draft.userMap[ payload.user.userId ] = {
        user,
        appointmentMap:         {},
        _appointments:          [],
        _outOfHourEvents:       [],
        minLoaded:              payload.minLoaded,
        maxLoaded:              payload.maxLoaded,
        viewStart:              payload.startWeek,
        ...timeData,
        topExpanded:            payload.topExpanded,
        bottomExpanded:         payload.bottomExpanded,
        earlyEventsInView:      false,
        lateEventsInView:       false
    };
    
    makeBusinessHourEventsReducer( draft, { userId: user.userId, timeData });

    draft._allCalendars.push( draft.userMap[ payload.user.userId ]);
};

const removeUserCalendarReducer = ( draft, payload ) => {
    delete draft.userMap[ payload ];

    // remove the reference in _allCalendars
    draft._allCalendars = deleteOnUserId( draft._allCalendars, payload );

    removeCalendarFromSession( payload );
};

const addAppointmentsReducer = ( draft, payload ) => {
    Object.assign( draft.userMap[ payload.userId ].appointmentMap, payload.payload
        .map( r => pick( r.appointment, sliceStoredFields.appointment ) )
        .reduce( makeMapById( 'appointmentId' ), {}) );
};

const patchAppointmentReducer = ( draft, payload ) => {
    if ( draft.userMap[ payload.userId ]) 
    {

        Object.assign( draft.userMap[ payload.userId ].appointmentMap, {
            [ payload.appointmentId ]: {
                ...draft.userMap[ payload.userId ].appointmentMap[ payload.appointmentId ], ...payload.data
            }
        }); 
    }
};

const deleteJobReducer = ( draft, payload ) => {
    Object.values( draft.userMap ).forEach( user => {
        const appts = Object.values( user.appointmentMap ).filter( appt => appt.jobId === payload.jobId );
        appts.forEach( appt => delete draft.userMap[ user.user.userId ].appointmentMap[ appt.appointmentId ]);

        // any deleted job is no longer on the pegboard
        if ( has( user, 'pegboardItems' ) ) 
            user.pegboardItems = user.pegboardItems.filter( item => item.realJobId !== payload.jobId );
    });
};

const calendarViewDatesReducer = ( draft, payload ) => {
    draft.userMap[ payload.userId ].viewStart = payload.start;
    draft.userMap[ payload.userId ].viewEnd = payload.end;

    mergeToCurrentViewSettings({ viewStart: payload.start, viewEnd: payload.end });

    findOutOfHoursAppointments( draft, payload.userId );
};

const updateAppointmentStatics = ( draft, payload  ) => {
    buildAppointmentList( draft, payload );

    // changes to job details require updates to all users hence no userId is supplied,
    // but these changes don't affect appointment times
    if ( payload.userId )
        findOutOfHoursAppointments( draft, payload.userId );
};

const _setAppointmentImported = ( draft, payload ) => {
    delete draft.userMap[ payload.data.userId ].appointmentMap[ payload.data.appointmentId ].newItemFlag;

    // re-imports return old job id to be used instead
    if ( payload.payload !== payload.data.jobId )
        draft.userMap[ payload.data.userId ].appointmentMap[ payload.data.appointmentId ].jobId = payload.payload;
};

const _setOtherUserTimeLogs = ( draft, payload ) => {
    draft.userMap[ payload.userId ].pegboardItems = payload.payload;
};

const _patchOtherUserTimeLogs = ( draft, payload ) => {

    // we have a list of timelog data updates which have changed elsewhere and we wish to
    // update those items which belong to other users whose calendars we're displaying.
    // (Currently the timelogs for a user are not changed by anyone except themselves so we
    // don't need to update the PB items for the login user.)
    // 
    // We don't worry too much about the nature of the change, we just make sure the list
    // of PB items held for each other user is up to date wrt presence/absence on the list and timer running status.
    // This doesn't handle additions to the list, since we need the full job info as well for that so we
    // just reload the list in that case.
    
    for ( const tl of payload ) {
        if ( draft.userMap[ tl.userId ] && has( draft.userMap[ tl.userId ], 'pegboardItems' ) ) 
        {
            const { pegboardItems } = draft.userMap[ tl.userId ];
            const index = pegboardItems.findIndex( item => item.timeLog.timeLogId === tl.timeLogId );
            if ( index >= 0 ) 
            {

                // currently on PB as far as local data knows
                if ( tl.pegboard ) 
                {

                    // on PB as it should be, update data
                    Object.assign( pegboardItems[ index ].timeLog, tl );
                }
                else 
                {

                    // no longer on PB, remove it
                    pegboardItems.splice( index, 1 );
                }
            }
        }
    }
};

const _setLoadOrganisationSettings = ( draft, payload ) => {
    draft.organisationTimeData = makeCalendarTimeData( unwrapBusinessHours( payload.data.timeEntrySettings.businessHours ) );
    makeBusinessHourEventsReducer( draft );
};

const reducers = {
    [ setAddUserCalendar ]:                     addUserCalendarReducer,
    [ setDeleteUserCalendar ]:                  removeUserCalendarReducer,
    [ setAddAppointments ]:                     addAppointmentsReducer,
    [ setAddAppointment ]:                      addAppointmentReducer,
    [ setPatchAppointment ]:                    patchAppointmentReducer,
    [ setDeleteJob ]:                           deleteJobReducer,
    [ setDragInfo ]:                            genericReducer,
    [ setDateRange ]:                           ( draft, payload ) => Object.assign( draft.userMap[ payload.userId ], payload.dates ),
    [ setUserFromSettings ]:                    conditionalUserReducer,
    [ setDeleteAppointment ]:                   ( draft, payload ) => delete draft.userMap[ payload.userId ].appointmentMap[ payload.appointmentId ],
    [ setExpandCalendarUp ]:                    ( draft, userId ) => draft.userMap[ userId ].topExpanded = !draft.userMap[ userId ].topExpanded,
    [ setExpandCalendarDown ]:                  ( draft, userId ) => draft.userMap[ userId ].bottomExpanded = !draft.userMap[ userId ].bottomExpanded,
    [ setCalendarViewDates ]:                   calendarViewDatesReducer,
    [ makeBusinessHourEvents ]:                 makeBusinessHourEventsReducer,
    [ setUpdateAppointmentStatics ]:            updateAppointmentStatics,
    [ setAppointmentImported ]:                 _setAppointmentImported,
    [ setOtherUserTimeLogs ]:                   _setOtherUserTimeLogs,
    [ patchOtherUserTimeLogs ]:                 _patchOtherUserTimeLogs,
    [ setLoadOrganisationSettings ]:            _setLoadOrganisationSettings,
    [ clearCalendars ]:                         draft => Object.assign( draft, initialState )

};

/** selectors */

const selectCalendars = ( state, includeLoginUser = true ) => {
    const { user: { userId } } = state;
    return includeLoginUser 
        ? state.calendar._allCalendars
        : state.calendar._allCalendars.filter( calendar => calendar.user.userId !== userId );
};

const selectUserCalendarState = ( state, userId ) => {
    const { calendar: { userMap } } = state;
    const calendar = userMap[ userId ] ?? { user: { preferences: {} } };
    const { _appointments, _outOfHourEvents, topExpanded, bottomExpanded, earlyEventsInView, lateEventsInView, pegboardItems,
        viewStart, minStartTime, maxEndTime } = calendar;

    return {

        // display no appts until we have the mappings
        appointments:           selectNameDataReady( state ) ? _appointments : [],

        outOfHourEvents:        _outOfHourEvents,
        pegboardItems:          pegboardItems || [],
        topExpanded,
        bottomExpanded,
        earlyEventsInView,
        lateEventsInView,
        viewStart:              dayjs( viewStart ).toISOString(),
        minStartTime,
        maxEndTime
    };
};

/**
 * Return the appointment with the specified id
 * @param {BaseState} state - global state
 * @param {string} appointmentId - appointment Id
 * @returns {Database.Appointment}
 */
const getAppointmentFromState = ( state, appointmentId ) => {

    // we have multiple calendarEvent maps, one per engineer calendar, so we really
    // need a user id as well. However, that would make the job/busy card switching much
    // trickier, so just check all open calendars for this id.

    if ( !appointmentId )
        return null;

    // find the user id who owns this event.
    const users = Object.values( state.calendar.userMap );
    const user = users.find( user => user.appointmentMap[ appointmentId ]?.appointmentId );
    return user?.appointmentMap[ appointmentId ];
};

export {
    patchTimedEventThunk,
    appointmentDataChangedThunk,
    fetchOneAppointmentRequest,
    appointmentRemovedThunk,
    refreshCalendarApptsThunk,

    createAppointmentThunk,
    selectCalendars,
    getDateRangeThunk,
    selectUserCalendarState,
    OUT_OF_HOURS_GROUP,
    findOutOfHoursEvents
};


/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
