/** ******************************************************************************************************************
 * @file UI integration progress.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 18-May-2021
 *********************************************************************************************************************/

import React from 'react';

import { IntegrationStages } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { UX_INTEGRATION_FINISH } from '../../../ux-constants.js';
import progressImg from '../../../images/integration_animation.svg';
import { setIntegrationProgress } from '../../../redux/actions/index.js';
import {
    integrationProgress,
    integrationImage,
    textBox,
    heavy,
    presentation,
    progressText,
    meter,
    percentage,
    task,
    total as totalStyle,
    textTask,
    textTotal,
    holder,
    rightAlign,
    leftSpace
} from '../../../scss/IntegrationProgress.module.scss';
import { _$, __ } from '../../../utils/i18n.jsx';
import BannerLogo from '../../common/images/BannerLogo.jsx';
import { LicoButton, LicoIcon } from "../../common/index.js";
import PreAuthLayout from '../../layouts/PreAuthLayout.jsx';
import { HOME } from "../../navigation/routes.js";

const {
    STARTING,
    USER,
    LINK,
    ENGINEERS,
    COMPANY_IDS,
    SITE_COUNTS,
    SITES,
    CONTACTS,
    COMPANIES,
    ASSET_TYPES,
    ASSET_STATUSES,
    MANUFACTURERS,
    SLAS,
    ASSETS,
    TICKETS,
    COMPLETING,
    DONE,
    ERROR
} = IntegrationStages;
const stageText = {
    [ STARTING ]:       __( "Starting" ),
    [ USER ]:           __( "Syncing user information" ),
    [ LINK ]:           __( "Linking users" ),
    [ ENGINEERS ]:      __( "Syncing engineers" ),
    [ COMPANY_IDS ]:    __( "Syncing company IDs" ),
    [ SITE_COUNTS ]:    __( "Syncing site counts" ),
    [ SITES ]:          __( "Syncing sites" ),
    [ CONTACTS ]:       __( "Syncing contacts" ),
    [ COMPANIES ]:      __( "Syncing companies" ),
    [ ASSET_TYPES ]:    __( "Syncing asset types" ),
    [ ASSET_STATUSES ]: __( "Syncing asset statuses" ),
    [ MANUFACTURERS ]:  __( "Syncing manufacturers" ),
    [ SLAS ]:           __( "Syncing SLAs" ),
    [ ASSETS ]:         __( "Syncing assets" ),
    [ TICKETS ]:        __( "Syncing tickets" ),
    [ COMPLETING ]:     __( "Completing integration" ),
    [ DONE ]:           __( "Integration complete" ),
    [ ERROR ]:          __( "Error during integration" )
};

const getStageText = ( stage, current, total ) => total && total > 1 && stage !== DONE ? `${stageText[ stage ]}: ${current} of ${total}` : `${stageText[ stage ]}...`;
const getPercentage = ( current, total ) => total ? ( current / total * 100 | 0 ) + '%' : current || '100%';

const IntegrationProgress = ({ progress, history, setIntegrationProgress, provider: { displayName, integrated } }) => {
    const {
        stage,
        stageLast,
        progressForStage: {
            current,
            total
        }
    } = progress;

    const onFinish = () => {
        setIntegrationProgress({ ...progress, goneHome: true });
        history.push( HOME );
    };

    return (
        <PreAuthLayout crispView={true}>
            <div className={integrationProgress}>
                <BannerLogo />
                <div className={textBox}>
                    <p className={heavy}>{_$( "Integrating with {displayName}", { displayName })}</p>
                    <p className={presentation}>{_$( "Please wait while Licorice syncs data from {displayName}.", { displayName })}</p>
                    <p className={presentation}>{_$( "No information will be modified in {displayName} at this time.", { displayName })}</p>
                </div>
                <object type="image/svg+xml" className={integrationImage} data={progressImg} alt="Decorative">svg-animation</object>

                <div className={progressText}>{getStageText( stage, current, total )}</div>
                {
                    stage !== DONE &&
                    <div className={holder}>
                        <progress className={`${meter} ${task}`} value={current} max={total} />
                        <div className={percentage}><span className={textTask}>{getPercentage( current, total )}</span></div>
                    </div>
                }

                <div className={progressText}>{__( "Total:" )}</div>
                <div className={holder}>
                    <progress className={`${meter} ${totalStyle}`} value={stage} max={stageLast} />
                    <div className={percentage}><span className={textTotal}>{getPercentage( stage, stageLast )}</span></div>
                </div>
                {
                    integrated && (
                        <LicoButton className={rightAlign} type="button" onClick={onFinish} data-ux={UX_INTEGRATION_FINISH}>
                            {__( "Finished" )} <LicoIcon className={leftSpace} icon="arrow-right"/>
                        </LicoButton>
                    )
                }
            </div>
        </PreAuthLayout>
    );
};

IntegrationProgress.propTypes = {
    progress:                   PropTypes.object,
    provider:                   PropTypes.object,
    history:                    PropTypes.any,
    setIntegrationProgress:     PropTypes.func.isRequired
};

const mapStateToProps = state => ({ 
    progress:       state.integration.progress, 
    provider:       state.integration.provider
});
const mapDispatchToProps = { setIntegrationProgress };

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( IntegrationProgress ) );
export { IntegrationProgress };
