/********************************************************************************************************************
 * @file storeSelectors.js - access to store data via the store itself, ie no state was available
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21/12/2021
 *********************************************************************************************************************/
import { has, hasValues } from '@licoriceio/utils';

import { store } from '../../publicStore.js';

function checkForPegboardChange({ timeLogIds })
{
    const state = store.getState().timelog;
    let update = false;

    if ( hasValues( timeLogIds ) )
        update = timeLogIds.some( id => has( state.timeLogMap, id ) && state.timeLogMap[ id ].pegboard );

    return update;
};


/**
 * If the specified job id is on the pegboard, return the timeLogId otherwise undefined
 * @param {string} jobId - job Id
 * @return {string|undefined}
 */
const jobOnPegboard = jobId => Object.values( store.getState().timelog.timeLogMap || {}).find( item => item.pegboard && item.jobId === jobId )?.timeLogId;

export {
    checkForPegboardChange,
    jobOnPegboard
};
