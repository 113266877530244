/********************************************************************************************************************
 * @file Actions for the busyCard store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setCalendarEvents =                createAction( 'CALENDAR_EVENT.CALENDAR_EVENTS/SET' );
export const setConnectorCalendarEvent =        createAction( 'CALENDAR_EVENT.ADD_CONNECTOR_CALENDAR_EVENT/SET' );
export const setCurrentCalendarEventId =        createAction( 'CALENDAR_EVENT.CALENDAR_EVENT_ID/SET' );
export const setCalendarEventDetails =          createAction( 'CALENDAR_EVENT.CALENDAR_EVENT_DETAILS/SET' );
export const setPatchCalendarEvent =            createAction( 'CALENDAR_EVENT.CALENDAR_EVENT_UPDATE/SET' );
export const setDeleteCalendarEvent =           createAction( 'CALENDAR_EVENT.DELETE_CALENDAR_EVENT/SET' );
