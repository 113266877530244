/** ******************************************************************************************************************
 * @file Everything and the kitchen sink.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 31-Oct-2020
 *********************************************************************************************************************/

import kindOf from 'kind-of';
import validator from 'validator';
import { isNumber } from '../helpers/index.js';

/** */

const { isUUID, isISO8601, isDate, isEmail } = validator;

const isString = s => kindOf( s ) === 'string';

const MISSING = Symbol.for( 'missing' );

/**
 * @param {string} s
 * @return {string|null}
 */
const validUUID   = s => isUUID( s ) ? s : null;

/**
 * @param {string|Date} d
 * @return {Date|null}
 */
const validDate   = d => isISO8601( d ) ? new Date( d ) : isDate( d ) ? d : null;

/**
 * @param {string} e
 * @return {string|null}
 */
const validEmail  = e => isEmail( e ) ? e : null;

/** @private */
const settlement = cb => ( result, i ) => result.status === 'fulfilled'
    ? cb?.( result.value, i )
    : console.error( isString( result.reason ) ? new Error( result.reason ) : result.reason );

/**
 * @param {[]} list
 * @param {function} reqCb
 * @param {function|number} cb
 * @param {number} [max]
 * @return {Promise<[]>}
 */
async function bySlice( list, reqCb, cb, max )
{
    let baseIndex = 0;

    if ( isNumber( cb ) )
    {
        max = cb;
        cb = void 0;
    }

    const settleCb     = settlement( cb );
    const executeBlock = c => Promise.allSettled( c.map( reqCb ) ).then( res => cb ? res.map( ( r, i ) => settleCb?.( r, baseIndex + i ) ) : null );

    while ( baseIndex < list.length )
    {
        await executeBlock( list.slice( baseIndex, baseIndex + max ) );
        baseIndex += max;
    }

    return list;
}

// noinspection CommaExpressionJS
const fn  = msg => x => ( console.log( msg, x ), x );
/**
 * @param {string} msg
 * @param {any} x
 * @return {(function(*=): *)|*}
 */
const tap = ( msg, x = MISSING ) => x === MISSING ? fn( msg ) : fn( msg )( x );

/**
 * @param {string[]} lines
 * @param {function} start
 * @param {function} end
 * @param {boolean} [include=true]
 * @param {boolean} [inclusive=false]
 * @return {string[]}
 */
const withBlock = ( lines, start, end, include = true, inclusive = false ) => {
    const newLines = [];
    let inside     = false;
    let deferFalse = false;

    for ( const line of lines )
    {
        if ( inside && end( line ) )
        {
            if ( !inclusive )
            {
                inside = false;
                continue;
            }
            else
                deferFalse = true;
        }
        else if ( !inside && start( line ) )
        {
            inside = true;
            if ( !inclusive ) continue;
        }

        inside ^ include || newLines.push( line );

        if ( deferFalse )
            inside = deferFalse = false;
    }

    return newLines;
};

export {
    withBlock,
    tap,
    validUUID,
    validDate,
    validEmail,
    bySlice,
    MISSING
};
