import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { snackbarKey } from "../../../constants.js";
import { UX_CLIENTSITE_ADD_LIST } from "../../../ux-constants.js";
import { resetFoundChanges, setClientSettings } from "../../../redux/actions/index.js";
import { footer, leftFooter, rightFooter, saveButton, addButton, iconStyle, fabStyle, errorStyle } from '../../../scss/ManageClient.module.scss';
import { filterLine } from '../../../scss/ManageEngineers.module.scss';
import { clientNm, linkHeader, screenTitle, settings, spaceAround } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { LicoButton, LicoLiveSearch, LicoSmallButton } from "../../common/index.js";
import LicoSnackbar from "../../common/LicoSnackbar.jsx";
import LicoTable from "../../common/LicoTable.jsx";
import { ListPager } from "../../common/ListPager.jsx";
import NavigationDialog from "../../common/NavigationDialog.jsx";
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import { setNewSite, continueSiteNavigation, getClientLocation, PAGE_SIZE, saveSiteChanges, setSiteFilterString, setUnsavedSite } from "./reducer.js";

const Sites = ( props ) => {
    const { getClientLocation, clientLocations, setNewSite, setUnsavedSite, saveSiteChanges, foundChanges, siteFilterString, columns, showDuplicateErrorMessage,
        setSiteFilterString, currentSitePage, totalSiteCount, confirmNavigationDialogOpen, setClientSettings, continueSiteNavigation, resetFoundChanges } = props;
    const { clientId, clientName } = useParams();
    const name = decodeURIComponent( clientName );
    const [ locations, setLocations ] = useState( clientLocations );
    const [ newRowCount, setNewRowCount ] = useState( 1 );

    const addRow = () => {
        setLocations([
            ...locations,
            {
                siteId:        -newRowCount,
                name:         '',
                addressLine1:       '',
                addressLine2:       '',
                state:        '',
                country:      '',
                postalCode:   ''
            }
        ]);
        setNewRowCount( newRowCount + 1 );
    };

    const prevLocation = useLocation().pathname;
    const history = useHistory();


    useEffect(  () => {
        getClientLocation({ page: 0, siteFilterString, clientId });
    }, [ siteFilterString ]);


    useEffect( () => {
        setLocations( clientLocations );
    }, [ clientLocations ]);

    const handleLocationChange = ( id, field, value ) => {
        setLocations( locations.map( location => location.siteId === id ? { ...location, [ field ]: value } : location ) );
        if ( id <= 0 ) 
            setNewSite({ siteId: id, [ field ]: value });
        else
            setUnsavedSite({ siteId: id, [ field ]: value });
    };

    const  showValidationErrorMessage = foundChanges && locations.some( location => {
        return !location.name;
    });


    return (
        <div>
            <LicoSnackbar
                metaKey={ snackbarKey.CLIENT_DETAILS_SAVED }
                label={__( "Changes saved." )}
            />
            <NavigationDialog
                isOpen={confirmNavigationDialogOpen}
                saveAction={() => continueSiteNavigation( true )}
                discardAction={() => continueSiteNavigation( false )}
                cancelAction={() => setClientSettings({ confirmNavigationDialogOpen: false })}
            />
            <PreventTransitionPrompt
                when={foundChanges}
                history={history}
                saveAction={saveSiteChanges}
                discardAction={resetFoundChanges}
            />
            <SettingsLayout>
                <div className={settings}>
                    <div className={screenTitle}>
                        <Link className={linkHeader} to="/settings/clients">Clients</Link>
                        <span className={spaceAround}> {'>'} </span>
                        <div className={clientNm}>{name}</div>
                    </div>
                    <div className={filterLine}>
                        <LicoLiveSearch
                            placeholder={__( "Filter" )}
                            value={siteFilterString}
                            onChange={e => setSiteFilterString( e.target.value )}
                            id="siteFilter"
                        />
                    </div>
                </div>
                <LicoTable columns={columns} rows={locations} onChange={handleLocationChange}  uniqueKey="siteId"/>
                {showValidationErrorMessage && <div className={errorStyle}>{__( "Please fill in all required fields" )}</div>}
                {showDuplicateErrorMessage && <div className={errorStyle}>{__( "Site Name Already Exists" )}</div>}
                <div className={footer}>
                    <div className={ leftFooter}>
                        <LicoSmallButton
                            labelClass={addButton}
                            label={__( "Add Site" )}
                            fabClass={fabStyle}
                            dataName={UX_CLIENTSITE_ADD_LIST}
                            onClick={addRow}
                            iconClass={iconStyle}
                        />
                        <LicoButton
                            className={saveButton}
                            disabled={!foundChanges || showValidationErrorMessage }
                            onClick={() => saveSiteChanges()}
                        >{__( "Save" )}
                        </LicoButton>
                    </div>

                    <div className={rightFooter} >
                        <ListPager
                            page={currentSitePage}
                            pageSize={PAGE_SIZE}
                            totalPages={Math.ceil( totalSiteCount / PAGE_SIZE )}
                            getPage={getClientLocation}
                            filter={siteFilterString}
                        />
                    </div>
                </div>
            </SettingsLayout>

        </div>
    );
};

Sites.propTypes = {
    clientLocations:             PropTypes.array,
    getClientLocation:           PropTypes.func.isRequired,
    setNewSite:                  PropTypes.func.isRequired,
    setUnsavedSite:              PropTypes.func.isRequired,
    saveSiteChanges:             PropTypes.func.isRequired,
    resetFoundChanges:           PropTypes.func.isRequired,
    foundChanges:                PropTypes.bool.isRequired,
    siteFilterString:            PropTypes.string.isRequired,
    setSiteFilterString:         PropTypes.func.isRequired,
    currentSitePage:             PropTypes.number.isRequired,
    totalSiteCount:              PropTypes.number.isRequired,
    confirmNavigationDialogOpen: PropTypes.bool.isRequired,
    setClientSettings:           PropTypes.func.isRequired,
    continueSiteNavigation:      PropTypes.func.isRequired,
    columns:                     PropTypes.array.isRequired,
    showDuplicateErrorMessage:   PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        clientLocations:             state.client.clientLocations,
        foundChanges:                state.client.foundChanges,
        siteFilterString:            state.client.siteFilterString,
        currentSitePage:             state.client.currentSitePage,
        totalSiteCount:              state.client.totalSiteCount,
        confirmNavigationDialogOpen: state.client.confirmNavigationDialogOpen,
        continueSiteNavigation:      state.client.continueSiteNavigation,
        columns:                     state.client.siteColumns,
        showDuplicateErrorMessage:   state.client.showDuplicateErrorMessage
    };
};

const mapDispatchToProps = {
    getClientLocation,
    setNewSite,
    setUnsavedSite,
    saveSiteChanges,
    setSiteFilterString,
    setClientSettings,
    continueSiteNavigation,
    resetFoundChanges
};

export default connect( mapStateToProps, mapDispatchToProps )( Sites );
