/********************************************************************************************************************
 * @file job.js - job selectors
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 22/12/2021
 *********************************************************************************************************************/

import { AppointmentState } from '@licoriceio/constants';


/**
 * @param {string|undefined|null} id
 * @return {function(BaseState): object|undefined}
 */
const selectJob = id => state  => id ? state.job.jobMap[ id ] : void 0;

const selectJobsMap = state  => state.job.jobMap;

/**
 * Return the job with the specified id
 * @param {BaseState} state - global state
 * @param {string} jobId - job Id
 * @returns {object}
 */
const getJobFromState = ( state, jobId ) => jobId ? state.job.jobMap[ jobId ] : null;

const getJobCompany = ( state, jobId ) => jobId && state.job.jobMap[ jobId ]?.companyId ? state.job.clientMap[ state.job.jobMap[ jobId ]?.companyId ] || {} : {};

const liveAppointmentStateRE = new RegExp( '\\b' + [ AppointmentState.done, AppointmentState.active, AppointmentState.init ].join( '\\b|\\b' ) + '\\b' );

const getJobAppointmentCount = ( userMap, jobId ) => {
    let count = 0;
    Object.values( userMap || {})
        .forEach( user => {
            count = count + Object.values( user.appointmentMap )
                .filter( appointment => appointment.jobId === jobId && liveAppointmentStateRE.test( appointment.state ) )
                .length;
        });
    return count;
};

const getResourceProgress = ( state, fileKey ) => state.job.fileMap[ fileKey ];

export {
    selectJob,
    selectJobsMap,
    getJobFromState,
    getJobCompany,
    getJobAppointmentCount,
    getResourceProgress
};
