/** ******************************************************************************************************************
 * @file Phrase translation.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 15-Aug-2020
 *********************************************************************************************************************/

import React from 'react';

import { isString, objectAt } from '@licoriceio/utils';

import enPhrases from './i18n/en.js';

const _subValue = subst => field => {
    const jsx = objectAt( subst, field, `MISSING(${field})` );
    return isString( jsx ) ? jsxSpan( jsx ) : jsx;
};
const jsxSpan = txt => txt === '' ? null : React.createElement( 'span', {}, txt );

let phrases = enPhrases;

/**
 * @param {string} phrase
 * @param {object} [subst]
 * @return {string}
 */
const __ = ( phrase, subst ) => {
    const p = phrases[ phrase ] ?? `MISSING(${phrase})`;

    if ( subst == null ) return p;

    const subValue = _subValue( subst ?? {});

    return p.replace( /{([^}]+)}/g, ( $0, $1 ) => subValue( $1 ) );
};

/**
 * @param {string} phrase
 * @param {object} [subst]
 * @return {object}
 */
const _$ = ( phrase, subst ) => {
    let index = 0;
    let key = '_$';
    let p;
    const subValue = _subValue( subst ?? {});

    if ( !( p = phrases[ phrase ]) ) return jsxSpan( `MISSING(${phrase})` );

    if ( subst == null ) return React.createElement( React.Fragment, {}, jsxSpan( p ) );

    return React.createElement(
        React.Fragment,
        {},
        ...p.split( /({(?:[^}]+)})/ )
            .filter( s => s !== '' )
            .map( s => s[ 0 ] === '{' && s[ s.length - 1 ] === '}' ? subValue( s.substring( 1, s.length - 1 ), key + index++ ) : s !== '' ? jsxSpan( s ) : null )
            .filter( Boolean )
    );
};

const setLocale = async ( l = 'en-US' ) =>
    phrases = await import( `./i18n/${l.split( '-' )[ 0 ] || 'en'}.js` ).catch( () => import( './i18n/en.js' ) );

export {
    __,
    _$,
    setLocale
};
