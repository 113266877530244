/** ******************************************************************************************************************
 * @file Variants of integration layouts.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 04-08-2022
 *********************************************************************************************************************/
import { CONNECTWISE, integrationNames, StatusNames } from '@licoriceio/constants';

import { __ } from '../../../utils/i18n.jsx';

const {
    BILLING,
    BOARD,
    JOB_STATUS,
    PRIORITY,
    PERSONAL,
    INTERNAL,
    BILLING_TYPE,
    OTHER_NEW_JOB_STATUS_LIST,
    OTHER_RESOLVED_JOB_STATUS_LIST
} = integrationNames;

const integrationDataFields = {
    [ CONNECTWISE ]: {

        // note that the automatic fields for each section are generated from the integration settings data
        // received from the backend, specifically the "selected" data. See integration/reducer.js for details.
        [ BILLING ]:        {
            sectionTitle:           __( 'Ticket Billing' ),
            serviceBoardTooltip:    {
                location:   true,
                department: true
            }
        },
        [ BILLING_TYPE ]:        {
            sectionTitle:           __( 'Ticket Work Types' )
        },
        [ BOARD ]:          {
            sectionTitle:       __( 'Create New Jobs In' ),
            emptyListMessage:   __( 'The current Location and Department settings do not match any Service Boards' ),
            jobStatusTooltip:   true
        },
        [ JOB_STATUS ]:     {
            sectionTitle:       __( 'Map Job Statuses' ),
            fieldLabelFilter:   label => label !== StatusNames.UNMAPPED_STATUS,
            warningCallback:   ({ value, extendedDropdown: { fieldValue, settings: { selected, boardStatusNames } } }) => { 

                // Does the current status label for this field (value) appear in the list for the 
                // current service board?
                const serviceBoardFieldId = selected.board[ 0 ].id;
                const serviceBoardId = fieldValue[ serviceBoardFieldId ];

                return boardStatusNames[ serviceBoardId ]?.includes( value ) ? '' : __( 'Warning: Status does not exist for Default Board' );
            },

            // linkedField keys off field label (all we have, unfortunately) and has criteria
            // for selecting the another field from the selection, which is not displayed by default,
            // since it's order cannot be set to our liking.
            linkedField: {
                [ StatusNames.NOT_SCHEDULED ]: {
                    licoriceTypeName: OTHER_NEW_JOB_STATUS_LIST
                },
                [ StatusNames.RESOLVED_JOB ]: {
                    licoriceTypeName: OTHER_RESOLVED_JOB_STATUS_LIST
                }
            },
            overrideLabel: {
                [ StatusNames.NOT_SCHEDULED ]:  __( "Create New Jobs as" ),
                [ StatusNames.RESOLVED_JOB ]:   __( "Set Resolved Jobs as" )
            }
        },
        [ PRIORITY ]:       {
            sectionTitle: __( 'Map Priorities' ),
            info:         {
                text:      __( 'All other priorities will be mapped as Normal Priority' ),
                iconAfter: 'info',
                color:     'cool'
            }
        },
        [ INTERNAL ]:       {
            sectionTitle: __( 'Map Internal Card Types' )
        },
        [ PERSONAL ]:       {
            sectionTitle: __( 'Map Personal Card Types' )
        }
    }
};

export {
    integrationDataFields
};
