/********************************************************************************************************************
 * @file Actions for the company store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setCompanyCompany = createAction( 'COMPANY.COMPANY/SET' );
