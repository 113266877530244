import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_REGISTRATION_EMAIL } from '../../ux-constants.js';
import { setAdvance, setFormValueRegistration, setTouchedRegistration } from '../../redux/actions/index.js';
import { root } from '../../scss/Email.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { onEnter } from '../../utils/misc.js';
import { LicoTextField } from "../common/index.js";

const EmailForm = ({ emailAddress, setFormValueRegistration, fieldTouched, fieldError, setTouchedRegistration, setAdvance }) => (
    <div className={root}>
        <div>
            {__( "This will be the account you use to access your Licorice. If there is an existing instance for your company, please ask an existing user to invite you." )}
        </div>
        <br/>

        <LicoTextField
            name="emailAddress"
            type="email"
            data-ux={UX_REGISTRATION_EMAIL}
            label={__( "Work email" )}
            autoFocus

            value={emailAddress}
            helperText={fieldTouched.emailAddress ? fieldError.emailAddress : ''}
            error={!!fieldError.emailAddress && fieldTouched.emailAddress}

            onChange={e => setFormValueRegistration({ field: e.target.name, value: e.target.value })}
            onBlur={e => setTouchedRegistration( e.target.name )}
            onKeyPress={onEnter( setAdvance )}
        />
    </div>
);

EmailForm.propTypes = {
    emailAddress:             PropTypes.string.isRequired,
    setFormValueRegistration: PropTypes.func.isRequired,
    fieldTouched:             PropTypes.object.isRequired,
    fieldError:               PropTypes.object.isRequired,
    setTouchedRegistration:   PropTypes.func.isRequired,
    setAdvance:               PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    emailAddress: state.registration.emailAddress,
    fieldTouched: state.registration.fieldTouched,
    fieldError:   state.registration.fieldError
});

const mapDispatchToProps = { setFormValueRegistration, setTouchedRegistration, setAdvance };

export default connect( mapStateToProps, mapDispatchToProps )( EmailForm );
export { EmailForm };
