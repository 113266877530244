import React, { useState } from 'react';

import { nop } from '@licoriceio/utils';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root:  {},
    label: {},
    input: {
        borderRadius:     40,
        height:           42,
        width:            258,
        borderWidth:      1,
        borderColor:      "var(--cobalt-3)",
        "&::placeholder": {
            fontSize: 13
        },
        boxSizing:   "content-box",
        marginRight: 14
    },
    inputLabel: {
        fontSize: 13,
        top:      2
    }
});

export const LicoSearch = props => {
    const {
        label,
        options,
        placeholder,
        onSelect = nop,
        initialSelected,
        disabled = false
    } = props;
    const classes = useStyles( props );
    const [ textValue, setTextValue ] = useState( '' );

    React.useEffect( () => void setTextValue( '' ), [ initialSelected ]);

    const handleSelect = React.useCallback( ( event, value ) => {
        if ( value )
        {
            onSelect( event, value );
            setTextValue( '' );
        }
    }, [ onSelect ]);

    const handleTextChange = React.useCallback( event => setTextValue( event.target.value ), [ setTextValue ]);

    return <>
        <Autocomplete
            options={options}
            getOptionLabel={option => option.label}
            isOptionEqualToValue={( option, value ) => value.label === option.label}
            onChange={handleSelect}
            disabled={disabled}
            blurOnSelect = {true}
            renderInput={params => {
                const newParams = {
                    ...params,
                    InputProps: {
                        ...params.InputProps,
                        endAdornment: <></>, // get rid of the endAdornment, 
                        className:    `${params.InputProps.className} ${classes.input}`,
                        classes:      {
                            root:           classes.input,
                            notchedOutline: classes.input,
                            focused:        classes.input
                        },

                        style: {
                            paddingTop: 0
                        }
                    },

                    InputLabelProps: {
                        ...params.InputLabelProps,
                        classes: {
                            root: classes.inputLabel
                        }
                    }
                };

                // @ts-ignore
                return ( <TextField
                    {...newParams}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        // @ts-ignore
                        ...newParams.inputProps,
                        onChange: e => {
                            handleTextChange( e );
                            params.inputProps.onChange( e );
                        },
                        value: textValue,
                        style: {
                            fontSize:    13,
                            paddingTop:  3,
                            paddingLeft: 20
                        }
                    }} /> );
            }}
        />
    </>;
};

LicoSearch.propTypes = {
    label:           PropTypes.string,
    options:         PropTypes.array,
    placeholder:     PropTypes.string,
    onSelect:        PropTypes.func,
    initialSelected: PropTypes.object,
    disabled:        PropTypes.bool
};
