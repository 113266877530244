import { GET, POST, PATCH, uri } from "../../constants.js";
import { setPatchCalendarEvent } from "../../redux/actions/index.js";
import { genericRequest } from "../../redux/reducerUtil.js";
import { abstractedCreateAuthRequest } from "../../services/util/baseRequests.js";

const fetchOneJobService = abstractedCreateAuthRequest( GET, uri.SINGLE_JOB );

const addAppointmentService = abstractedCreateAuthRequest( POST, uri.APPOINTMENTS );

const patchJobEngineers = payload => genericRequest( payload.data, abstractedCreateAuthRequest( PATCH, uri.JOB_ENGINEERS, a => a ), undefined, [ payload.id ]);

const updateCalendarEventService = abstractedCreateAuthRequest( PATCH, uri.SINGLE_CALENDAR_EVENT );

const patchCalendarEventRequest = payload => genericRequest( payload, updateCalendarEventService, setPatchCalendarEvent, [ payload.calendarEventId ]);


// don't need a response to this as we can't reschedule while job card is open
const addJobAppointmentHistory = payload => genericRequest( payload, abstractedCreateAuthRequest( POST, uri.HISTORY ) );

const addCalendarEventRequest = payload => genericRequest( payload, abstractedCreateAuthRequest( POST, uri.CALENDAR_EVENTS ), setPatchCalendarEvent );

export {
    fetchOneJobService,
    addAppointmentService,
    patchJobEngineers,
    updateCalendarEventService,
    patchCalendarEventRequest,
    addJobAppointmentHistory,
    addCalendarEventRequest
};
