import React from 'react';

import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { __ } from '../../../utils/i18n.jsx';
import { UX_JOBCARD_PROVIDER_LINK } from '../../../ux-constants.js';

import { LicoStaticLink } from './LicoStaticLink.jsx';

const ProviderJobLink = ({ className, providerJobId, providerJobURL, noLabel }) => {

    const jobIdText = providerJobId
        ? noLabel
            ? providerJobId
            : `${__( "Job ID:" )} ${providerJobId}` 
        : '';
    const actualJobURL = providerJobId && providerJobURL ? providerJobURL.replace( '<jobId>', providerJobId ) : undefined;

    const content = <span>
        {actualJobURL
            ? <LicoStaticLink to={actualJobURL} className={className} target="_blank" data-ux={UX_JOBCARD_PROVIDER_LINK}>{jobIdText}</LicoStaticLink>
            : <span style={{ marginRight: 10 }} >{jobIdText}</span>
        }
    </span>;

    // can't just put the tooltip around the link (where we're already testing the url), has to be the whole content
    return actualJobURL
        ? <Tooltip title={__( "Open the underlying ticket in a new tab" )}>
            {content}
        </Tooltip>
        : content;
};

ProviderJobLink.propTypes = {
    className:              PropTypes.string,
    providerJobId:          PropTypes.string,
    providerJobURL:         PropTypes.string,
    noLabel:                PropTypes.bool
};

const mapStateToProps = state => ({
    providerJobURL:         state.organisation.live?.providerJobURL
});

export default connect( mapStateToProps, {})( ProviderJobLink );
export { ProviderJobLink };
