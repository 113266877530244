/*********************************************************************************************************************
 * @file User detail reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 24-Dec-2020
 *********************************************************************************************************************/
import { Coms, UserRole, coms } from '@licoriceio/constants';
import * as Yup from 'yup';

import { POST, uri, userDialogMode } from '../../constants.js';
import { 
    setUserDialog, setCloseUserDialog, setFormValidationUserDetails, setUpdateUser, setTouchedUserDetails, setUserDialogMode,
    showToken,
    patchJobCardUsers
} from '../../redux/actions/index.js';
import { ezRedux, formValueReducer, validateOnBlurReducer, genericRequest } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { THIS_FIELD_IS_REQUIRED, INVALID_EMAIL } from '../../utils/common-types.js';

/**
 * @typedef {object} UserDetailState
 * @property {string}                   [userId]
 * @property {string}                   [name]
 * @property {string}                   [role]
 * @property {string}                   [email]
 * @property {number}                   [emailIndex]
 * @property {string}                   [phone]
 * @property {number}                   [phoneIndex]
 * @property {string}                   [title]
 * @property {object}                   [contactInfo]
 * @property {string}                   error
 * @property {object}                   fieldTouched
 * @property {object}                   fieldError
 * @property {("view"|"edit"|add")}     mode
 */


/**
  * @type {UserDetailState}
  */
const initialState = Object.freeze({
    error:              '',
    fieldTouched:       {},
    fieldError:         {},
    mode:               userDialogMode.VIEW,
    userId:             undefined
});


/** requests */
const createToken = payload => genericRequest( payload, abstractedCreateAuthRequest( POST, uri.CREATE_TOKEN ), showToken );

/** thunk actions */

const formSchema = Yup.object().shape({
    name:     Yup.string().required( THIS_FIELD_IS_REQUIRED ),
    email:    Yup.string().email( INVALID_EMAIL )
});

const setFormValueAction = payload => async ( dispatch ) => {

    // update form state (validation, errors)
    dispatch( setFormValidationUserDetails( payload ) );

    const { value, field } = payload;

    // update job state with the new value, which may come from a select list
    const change = {
        [ field ]:          value?.id ?? value
    };

    // update local state to refresh screen
    dispatch( setUpdateUser( change ) );
};

/** reducers */

const setUserDialogReducer = ( draft, payload ) => {
    const { mode, userId, name, role, title, contactInfo } = payload;
    draft.mode = mode || userDialogMode.VIEW;
    const phone = coms.find.best( contactInfo, Coms.Types.PHONE );
    const email = coms.find.best( contactInfo, Coms.Types.EMAIL );

    Object.assign( draft, {
        userId,
        name:           name ?? ( draft.name || '' ),
        role:           role || UserRole.user,
        title:          title ?? ( draft.title || '' ),
        email:          email?.value,
        phone:          phone?.value,
        labels: {
            email:      email?.label,
            phone:      phone?.label
        },
        contactInfo:    contactInfo || [],
        fieldError:     {},
        fieldTouched:   { name: true }
    });
};

const setUpdateUserReducer = ( draft, payload ) => {
    Object.assign( draft, payload );

    // if we've changed email or phone, update the contactInfo object as well;
    // this can only be done in the reducer, we can't update it before we save.
    const keys = Object.keys( payload );
    if ( keys.length === 1 && ( keys[ 0 ] === Coms.Types.EMAIL || keys[ 0 ] === Coms.Types.PHONE ) ) {
        const type = keys[ 0 ];
        const com = coms.updateOneValue( draft.contactInfo, type, payload[ type ], { label: draft.labels[ type ] });
        if ( !com )
        {
            // no contact of this type, create as work by default
            const newCom = coms.create[ type ]( payload[ type ]).work;
            draft.contactInfo.push( newCom );
            draft.labels[ type ] = newCom.label;
        }
    }
};

const _patchJobCardUsers = ( draft, payload ) => {
    const { userId } = draft;

    // a relevant change is one that applies to the currently displayed user (if any)
    if ( userId )
    {
        const relevantChange = payload.data.people.find( p => p.userId === userId );

        if ( relevantChange && draft.mode === userDialogMode.VIEW )
        { 
            if ( payload.fullRecord ) 
            {
                // I don't know where a payload like this comes from but I only just added it so I'm afraid to remove it...
                setUserDialogReducer( draft, payload.fullRecord );
            } 
            else
            {
                if ( relevantChange.contactInfo )
                {
                    const phone = coms.find.best( relevantChange.contactInfo, Coms.Types.PHONE );
                    if ( phone ) draft.phone = phone.value;
                    const email = coms.find.best( relevantChange.contactInfo, Coms.Types.EMAIL );
                    if ( email ) draft.email = email.value;
                }
                draft.title = relevantChange.title ?? draft.title;
                draft.name = relevantChange.name ?? draft.name;
            }
        }
    }
    
};

const reducers = {
    [ setUserDialog ]:                  setUserDialogReducer,
    [ setCloseUserDialog ]:             ( draft ) => delete draft.userId,
    [ setFormValidationUserDetails ]:   ( draft, arg ) => formValueReducer( draft, arg, formSchema ),
    [ setTouchedUserDetails ]:          ( draft, arg ) => validateOnBlurReducer( draft, arg, formSchema ),
    [ setUpdateUser ]:                  setUpdateUserReducer,
    [ setUserDialogMode ]:              ( draft, payload ) => draft.mode = payload,
    [ patchJobCardUsers ]:              _patchJobCardUsers
};

export {
    setFormValueAction,
    setTouchedUserDetails,
    createToken
};

export default ezRedux( reducers, initialState );
