/*********************************************************************************************************************
 * @file Worklog item component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React from 'react';

import { MINUTE, NoteFlags, NoteType, UserRole } from '@licoriceio/constants';
import { numMinutesToString } from '@licoriceio/utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { skipEmailEnabled } from '../../scss/WorkLog.module.scss';
import { workLogItem, rightSideItem, name, text, textContainer, time, iconStack, rightStack, hiddenIcon, spacer, icon, iconRow }
    from '../../scss/WorkLogItem.module.scss';
import { getAvatarColors } from '../../utils/common-types.js';
import { UX_JOBCARD_CLIENT_CHAT_LIST_ITEM, UX_JOBCARD_ENGINEER_CHAT_LIST_ITEM } from '../../ux-constants.js';
import RichEditor from '../common/editor/RichEditor.jsx';
import { H3, LicoIcon } from '../common/index.js';
import LicoAvatar from '../common/LicoAvatar.jsx';

import { billingTypeItemData } from './reducer.js';

const WorkLogItem = ({ note, previousNote, noteType, user, billingTypeName }) => {

    // messages appear on the right when they are from "us"; any engineer on client chat, or this engineer on worklog
    const rightSide = noteType === NoteType.message ? note.role === UserRole.engineer : note.userId === user.userId;

    const userAvatarColor = `avatar-${getAvatarColors( note?.preferences ?? user.preferences )}`;

    // change appearance flow for RHS items
    const itemClass = rightSide ? `${workLogItem} ${rightSideItem}` : workLogItem;
    const iconStackClass = rightSide ? `${iconStack} ${rightStack}` : iconStack;

    let boxClass;

    if ( noteType === NoteType.message )
        boxClass = user.userId === note.userId ? 'chat-box-user' : 'chat-box';
    else
        boxClass = user.userId === note.userId ? 'worklog-box-user' : 'worklog-box';

    // look up the billing type icon; might be null if the note was converted from the provider
    // and has no matching timeLog record
    const { icon: iconName, iconSlashed } = note.billingTypeId 
        ? billingTypeItemData[ billingTypeName ]
        : { icon: null, iconSlashed: false };

    return (
        <>
            {
                ( previousNote === null || previousNote.userId !== note.userId ) &&
                    <div className={itemClass}>
                        <div className={spacer} />
                        <div>
                            <LicoAvatar avatar={note.avatar} userId={note.userId} size = "small" />
                        </div>

                        <div className={name}>
                            <H3 gutterBottom = {false}>{note.name}</H3>
                        </div>
                    </div>
            }

            <div className={itemClass} data-ux={noteType === NoteType.message ? UX_JOBCARD_CLIENT_CHAT_LIST_ITEM : UX_JOBCARD_ENGINEER_CHAT_LIST_ITEM }>
                <div className={spacer} />
                <div>
                    <LicoAvatar className={hiddenIcon} avatar={note.avatar} size = "small" />
                </div>
                <div className={`${textContainer} ${userAvatarColor} ${boxClass}`}>
                    <div className={`${text} ${noteType}`}>
                        <RichEditor 
                            textValue={note.content} 
                            disabled
                            displayOnly
                        />
                    </div>
                </div>
                {( noteType === NoteType.worklog || rightSide ) &&
                    <div className={iconStackClass}>
                        <div className={iconRow} >
                            {( note.flags & NoteFlags.SKIP_CLIENT_EMAIL ) !== 0 && <LicoIcon 
                                icon="envelope" 
                                className={skipEmailEnabled}
                                slashed
                                slashPositionFix={-2}
                            />}
                            {iconName && <LicoIcon 
                                className={icon} 
                                icon={iconName} 
                                slashed={iconSlashed}
                                style={{ color: note.billable ? 'var(--teal)' : 'var(--grey-3)' }} 
                            />}
                        </div>
                        <div className={time}>{numMinutesToString( Math.ceil( note.duration / MINUTE ) )}</div>
                    </div>
                }
                <div className={spacer} />
            </div>
        </>
    );
};

WorkLogItem.propTypes = {
    note:               PropTypes.object.isRequired,
    previousNote:       PropTypes.object,
    noteType:           PropTypes.string.isRequired,
    user:               PropTypes.object.isRequired,
    billingTypeName:    PropTypes.string
};

const mapStateToProps = ({ user, jobcard }, props ) => ({ 
    user,
    billingTypeName:        jobcard.billingType.idToName[ props.note.billingTypeId ]
});

export default connect( mapStateToProps )( WorkLogItem );
export { WorkLogItem };
