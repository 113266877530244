/** ******************************************************************************************************************
 * @file Helpers fof the Licorice communications objects.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 13-02-2024
 *********************************************************************************************************************/
const ComNames = {
    MAIN:      'main',
    HOME:      'home',
    WORK:      'work',
    CUSTOM:    'custom',
    MOBILE:    'mobile',
    PAGER:     'pager',
    PERSONAL:  'personal',
    BUSINESS:  'business',
    FACEBOOK:  'facebook',
    TWITTER:   'twitter',
    LINKED_IN: 'linkedIn',
    PHONE:     'phone',
    EMAIL:     'email',
    FAX:       'fax',
    WEB:       'web',
    SOCIAL:    'social'
};

const Coms = {
    Names: ComNames,

    Types: {
        PHONE:  ComNames.PHONE,
        EMAIL:  ComNames.EMAIL,
        FAX:    ComNames.FAX,
        WEB:    ComNames.WEB,
        SOCIAL: ComNames.SOCIAL
    },

    PhoneLabels: {
        WORK:   ComNames.WORK,
        MOBILE: ComNames.MOBILE,
        HOME:   ComNames.HOME,
        MAIN:   ComNames.MAIN,
        PAGER:  ComNames.PAGER,
        CUSTOM: ComNames.CUSTOM
    },

    EmailLabels: {
        WORK:   ComNames.WORK,
        HOME:   ComNames.HOME,
        MOBILE: ComNames.MOBILE,
        CUSTOM: ComNames.CUSTOM
    },

    FaxLabels: {
        WORK:   ComNames.WORK,
        HOME:   ComNames.HOME,
        CUSTOM: ComNames.CUSTOM
    },

    WebLabels: {
        BUSINESS: ComNames.BUSINESS,
        PERSONAL: ComNames.PERSONAL,
        CUSTOM:   ComNames.CUSTOM
    },

    SocialLabels: {
        CUSTOM: ComNames.CUSTOM
    },

    TypeLabels: {
        [ ComNames.PHONE ]:  null,
        [ ComNames.EMAIL ]:  null,
        [ ComNames.FAX ]:    null,
        [ ComNames.WEB ]:    null,
        [ ComNames.SOCIAL ]: null
    }
};

Coms.TypeLabels[ ComNames.PHONE ]  = Coms.PhoneLabels;
Coms.TypeLabels[ ComNames.EMAIL ]  = Coms.EmailLabels;
Coms.TypeLabels[ ComNames.FAX ]    = Coms.FaxLabels;
Coms.TypeLabels[ ComNames.WEB ]    = Coms.WebLabels;
Coms.TypeLabels[ ComNames.SOCIAL ] = Coms.SocialLabels;

class BaseCom
{
    constructor( type, value, custom = null )
    {
        this.type = type;
        this.value = value;
        if ( custom ) this.setCustom( custom );
    }

    get default()
    {
        this.isDefault = true;
        return this;
    }

    setCustom( name )
    {
        this.label = Coms.Names.CUSTOM;
        this.custom = name;
        return this;
    }

    setProviderId( pid )
    {
        this.providerId = String( pid );
        return this;
    }

    setProviderTypeId( ptid )
    {
        this.providerTypeId = String( ptid );
        return this;
    }

    diff( other )
    {
        const changes = [];

        if ( this.type !== other.type ) changes.push({ op: 'replace', path: 'type', value: other.type });
        if ( this.value !== other.value ) changes.push({ op: 'replace', path: 'value', value: other.value });
        if ( this.label !== other.label ) changes.push({ op: 'replace', path: 'label', value: other.label });
        if ( ( this.label === Coms.Names.CUSTOM || other.label === Coms.Names.CUSTOM ) && this.custom !== other.custom )
            changes.push({ op: 'replace', path: 'custom', value: other.custom });
        if ( this.isDefault !== other.isDefault ) changes.push({ op: 'replace', path: 'isDefault', value: other.isDefault });
        if ( this.providerId !== other.providerId ) changes.push({ op: 'replace', path: 'providerId', value: other.providerId });
        if ( this.providerTypeId !== other.providerTypeId ) changes.push({ op: 'replace', path: 'providerTypeId', value: other.providerTypeId });

        return changes;
    }

    same( other )
    {
        return this.diff(  other ).length === 0;
    }
}

class PhoneCom extends BaseCom
{
    constructor( value, custom = null )
    {
        super( Coms.Types.PHONE, value, custom );
    }

    diff( other )
    {
        const changes = super.diff( other );
        if ( this.extension !== other.extension ) changes.push({ op: 'replace', path: 'extension', value: other.extension });
        return changes;
    }

    same(  other )
    {
        return this.diff( other ).length === 0;
    }

    get [ Coms.PhoneLabels.WORK ]()
    {
        this.label = Coms.PhoneLabels.WORK;
        return this;
    }

    get [ Coms.PhoneLabels.HOME ]()
    {
        this.label = Coms.PhoneLabels.HOME;
        return this;
    }

    get [ Coms.PhoneLabels.MOBILE ]()
    {
        this.label = Coms.PhoneLabels.MOBILE;
        return this;
    }

    get [ Coms.PhoneLabels.MAIN ]()
    {
        this.label = Coms.PhoneLabels.MAIN;
        return this;
    }

    get [ Coms.PhoneLabels.PAGER ]()
    {
        this.label = Coms.PhoneLabels.PAGER;
        return this;
    }

    setExtension( extension )
    {
        this.extension = extension;
        return this;
    }
}

class EmailCom extends BaseCom
{
    constructor( value, custom )
    {
        super( Coms.Types.EMAIL, value, custom );
    }

    diff( other )
    {
        const changes = super.diff( other );

        if ( this.domain !== other.domain ) changes.push({ op: 'replace', path: 'domain', value: other.domain });

        return changes;
    }

    same( other )
    {
        return this.diff( other ).length === 0;
    }

    get [ Coms.EmailLabels.WORK ]()
    {
        this.label = Coms.EmailLabels.WORK;
        return this;
    }

    get [ Coms.EmailLabels.HOME ]()
    {
        this.label = Coms.EmailLabels.HOME;
        return this;
    }

    get [ Coms.EmailLabels.MOBILE ]()
    {
        this.label = Coms.EmailLabels.MOBILE;
        return this;
    }
}

class FaxCom extends BaseCom
{
    constructor( value, custom = null )
    {
        super( Coms.Types.FAX, value, custom );
    }

    get [ Coms.FaxLabels.WORK ]()
    {
        this.label = Coms.FaxLabels.WORK;
        return this;
    }

    get [ Coms.FaxLabels.HOME ]()
    {
        this.label = Coms.EmailLabels.HOME;
        return this;
    }
}

class WebCom extends BaseCom
{
    constructor( value, custom = null )
    {
        super( Coms.Types.WEB, value, custom );
    }

    get [ Coms.WebLabels.PERSONAL ]()
    {
        this.label = Coms.WebLabels.PERSONAL;
        return this;
    }

    get [ Coms.WebLabels.BUSINESS ]()
    {
        this.label = Coms.WebLabels.BUSINESS;
        return this;
    }
}

class SocialCom extends BaseCom
{
    constructor( value, custom )
    {
        if ( !custom )
            throw new Error( `No social service provided for '${value}'` );
        super( Coms.Types.SOCIAL, value, custom );
    }
}

const forEachType = fn => Object.values( Coms.Types ).forEach( fn );

const findResult = ( list, fn, ctx ) => {
    let result = null;

    return list.some( ( item, index, list ) => result = fn.call( ctx, item, index, list ) ) ? result : null;
};

const indexOf = ( list, item ) => list.findIndex( i => i === item );

const coms = {
    create: {
        phone:  ( value, custom = null ) => new PhoneCom( value, custom ),
        email:  ( value, custom = null ) => new EmailCom( value, custom ),
        fax:    ( value, custom = null ) => new FaxCom( value, custom ),
        web:    ( value, custom = null ) => new WebCom( value, custom ),
        social: ( value, service ) => new SocialCom( value, service )
    },

    find,
    has,
    filter,
    indexOf,
    updateOneValue
};

function filter( contactInfo, type, matchAgainst = {})
{
    const matchProps = Object.entries({ ...matchAgainst, type });

    return contactInfo.filter( com => matchProps.every( ([ key, value ]) => com[ key ] === value ) );
}

function find( contactInfo, type, matchAgainst = {})
{
    const matchProps = Object.entries({ ...matchAgainst, type });

    return contactInfo.find( com => matchProps.every( ([ key, value ]) => com[ key ] === value ) );
}

function has( list, type, match = {})
{
    return !!find( list, type, { ...match });
}

function updateOneValue( contactInfo, type, value, matchAgainst = {})
{
    const com = find( contactInfo, type, matchAgainst );
    if ( com )
    {
        if ( value.length > 0 )
            com.value = value;
        else
        {
            const index = indexOf( contactInfo, com );
            contactInfo.splice( index, 1 );
        }
    }

    return com;
}

find.default = ( list, type, match = {}) => find( list, type, { ...match, isDefault: true });

/**
 * Find the default item in each successive label, stopping at first match.
 * If no default is found, find the first item in each label, searched in order, accepting first item found.
 *
 * @param {CommunicationItems} list
 * @param {Types} type
 * @param {Partial<CommunicationItem>} match
 * @return {CommunicationItem|null}
 */
find.best = ( list, type, match = {}) =>
    findResult( Object.values( Coms.TypeLabels[ type ]), label => find.default[ type ]( list, { ...match, label }) ) ??
    findResult( Object.values( Coms.TypeLabels[ type ]), label => filter( list, type, { ...match, label })[ 0 ]);

forEachType( type => find[ type ] = ( list, match ) => find( list, type, match ) );
forEachType( type => find.default[ type ] = ( list, match ) => find.default( list, type, match ) );
forEachType( type => find.best[ type ] = ( list, match ) => find.best( list, type, match ) );

has.default = ( list, type, match = {}) => has( list, type, { ...match, isDefault: true });
forEachType( type => has[ type ] = ( list, match ) => has( list, type, match ) );
forEachType( type => has.default[ type ] = ( list, match ) => has.default( list, type, match ) );

export { coms, Coms };
