import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoButton } from '../atomic/form/index.js';

const useStyles = makeStyles({
    root: ({
        width:        390,
        borderRadius: 5,
        color:        "var(--black-1)"
    }),
    confirmButton: {
        backgroundColor: "var(--watermelon)",
        margin:          0,
        "&:hover":       {
            backgroundColor: "var(--watermelon-light)"
        }
    },
    content: {
        padding: "13px 21px"
    },
    title: {
        fontSize:   16,
        fontFamily: "gotham-medium, sans-serif",
        padding:    "14px 21px"
    },
    contentText: {
        color:    "var(--black-1)",
        fontSize: 14
    },
    actions: {
        display:        "flex",
        flexFlow:       "row nowrap",
        justifyContent: "space-between",
        padding:        "0 18px 26px"
    }
});

export const MessageDialog = props => {
    const {
        isOpen,
        message,
        title,
        onClose
    } = props;
    const classes = useStyles( props );
    
    return (
        <Dialog
            open={ isOpen }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.root }}
        >
            <Card>
                <DialogTitle id="alert-dialog-title" className={ classes.title }>
                    { title }
                </DialogTitle>
            </Card>
            <DialogContent className={ classes.content }>
                <DialogContentText id="alert-dialog-description" className={ classes.contentText }>
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions
                className={  classes.actions }>
                <LicoButton onClick={ onClose } className={ classes.confirmButton } color="inherit" size="small">
                Okay
                </LicoButton>
            </DialogActions>
        </Dialog>
    );
};

MessageDialog.propTypes = {
    isOpen:  PropTypes.bool.isRequired,
    title:   PropTypes.string,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func
};
