import React from 'react';

import { SECOND } from '@licoriceio/constants';
import { Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { setMeta } from '../../redux/actions/index.js';
import { popup } from '../../scss/LicoSnackbar.module.scss';

const LicoSnackbar = ({ label, meta, metaKey, setMeta, ...rest }) => {
    return <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={meta?.[ metaKey ]}
        autoHideDuration={4 * SECOND}
        onClose={() => setMeta({ [ metaKey ]: false })}
        {...rest}
    >
        <div className={popup} onClick={() => setMeta({ [ metaKey ]: false })} >
            {label}
        </div>
    </Snackbar>;
};

LicoSnackbar.propTypes = {
    label:          PropTypes.string.isRequired,
    meta:           PropTypes.object.isRequired,
    metaKey:        PropTypes.string.isRequired,
    setMeta:        PropTypes.func.isRequired
};

const mapStateToProps = ({ meta }) => ({ meta });
const mapDispatchToProps = { setMeta };

export default connect( mapStateToProps, mapDispatchToProps )( LicoSnackbar );

