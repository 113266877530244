import { __ } from '../../utils/i18n.jsx';

// Text
export const LOGO_BYLINE = __( "Licorice - The end of IT tickets forever" );

// Routes
export const PRIVACY = "https://licorice.io/privacy-policy/";
export const TERMS = "https://licorice.io/terms-and-conditions/";
export const EULA = "https://licorice.io/eula/";

// Error messages
export const EXCEED_MAX_LOGIN_ATTEMPT = __( "Maximum login attempts exceeded." );
export const INCORRECT_PASSWORD = __( "Incorrect username or password." );
export const MUST_ACCEPT_TERMS = __( "You must accept all terms to continue." );
export const THIS_FIELD_IS_REQUIRED = __( "This field is required." );
export const PASSWORD_MIN_LENGTH = __( "Password must be at least 12 characters." );
export const SUBDOMAIN_FORMAT = 'Only letters, numbers with hyphens in the middle allowed.';
export const INVALID_EMAIL = __( "Invalid email." );
export const UNEXPECTED_ERROR = __( "Unexpected error." );

// Labels
export const BUTTON_LOGIN = __( "Login" );
export const BUTTON_SIGN_UP = __( "Sign up" );
export const BUTTON_ACCEPT = __( "Accept" );
