import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { pager, arrow, pagerText, gothamMedium, visible, hidden } from '../../scss/ListPager.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { LicoIcon } from '../common/index.js';

const ListPager = ({ className = "", page, filter, totalPages, getPage }) => {
    const leftVisibility = page > 0 ? visible : hidden;
    const rightVisibility = ( page + 1 ) < totalPages ? visible : hidden;

    return <div className={`${pager} ${className}`}>
        <span className={`${arrow} ${leftVisibility}`} onClick={() => getPage({ page: page - 1, filter })}>
            <LicoIcon icon="angle-left" />
        </span>
        <span className={pagerText}>
            <span>{ __( "Page" ) }&nbsp;</span>
            <span className={gothamMedium}>{page + 1}</span>
            <span>&nbsp;{ __( "of" ) }&nbsp;</span>
            <span className={gothamMedium}>{totalPages || totalPages + 1 }</span>
        </span>
        <span className={`${arrow} ${rightVisibility}`} onClick={() => getPage({ page: page + 1, filter })}>
            <LicoIcon icon="angle-right" />
        </span>
    </div>;
};

ListPager.propTypes = {
    page:           PropTypes.number.isRequired,
    totalPages:     PropTypes.number.isRequired,
    getPage:        PropTypes.func.isRequired,
    className:      PropTypes.string,
    filter:         PropTypes.string
};

export default connect()( ListPager );
export { ListPager };
