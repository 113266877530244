/*********************************************************************************************************************
 * @file User reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21-Dec-2020
 *********************************************************************************************************************/

import { extendEngineer } from '../../components/settings/engineer/reducer.js';
import { ezRedux, genericReducer } from '../../redux/reducerUtil.js';
import { setUserFromSettings, setUserMfaVerified, setCompanyCompany, setUser,
    setExternalJobCount, setDecrementExternalJobCount, setLoginFields, setCacheRecord, 
    setMeta } from '../actions/index.js';

import { cacheType } from './cache.js';

/**
 * @typedef {object} UserState
 * @property {string} [_id]
 */

/**
  * @type {UserState}
  */
const initialState = Object.freeze({
});


/** thunk actions */

// dispatched after initial retrieval of user
const initUser = payload => async dispatch => {
    const { user, company, user: { eula, termsConditions, privacyPolicy }, connectorName, dbState: { dbStateId, revision, integrationType } } = payload;

    // if the user doesn't have all permissions, we're going to the 2nd login
    // screen; seed the fields in auth state which are used for that screen.
    // We don't use the firstTimeLogin field for redirection to integration any more
    // but I'm retaining it in case we want to do something else on first login, ie messages, tutorials, etc.
    if ( !( eula && termsConditions && privacyPolicy ) )
        dispatch( setLoginFields({ eula, termsConditions, privacyPolicy, firstTimeLogin: true }) );
    else
        dispatch( setLoginFields({ connectorName }) );

    // user response contains both user and company info
    dispatch( setUser( extendEngineer( user ) ) );
    dispatch( setCompanyCompany( company ) );

    // add the user straight into the cache so we can assume all users are there
    dispatch( setCacheRecord({ type: cacheType.USER, id: user.userId, payload: user }) );

    // set meta info re db
    dispatch( setMeta({ dbStateId, revision, integrationType }) );
};

/** reducers */

// handles action from engineer settings which is fired for every user, but should
// only update the user draft if the logged-in user was modified. Also handles backend updates via socket.
const conditionalUserReducer = ( draft, user ) => {
    if ( draft.userId === user.userId )
        Object.assign( draft, { ...user, preferences: { ...draft.preferences, ...user.preferences } });
};

// when user mfa is confirmed, we should update the flag in preferences so the user
// profile screen displays correctly. All the work was done on the backend by verifyMfa;
// this is frontend only.
const _setUserMfaVerified = draft => Object.assign( draft.preferences, { twoFactorAuthenticationRequired: true });

const reducers = {
    [ setUser ]:                        genericReducer,
    [ setUserFromSettings ]:            conditionalUserReducer,
    [ setExternalJobCount ]:            ( draft, count ) => draft.externalJobCount = count,
    [ setDecrementExternalJobCount ]:   ( draft ) => draft.externalJobCount = draft.externalJobCount - 1,
    [ setUserMfaVerified ]:             _setUserMfaVerified
};


export {
    initUser
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
