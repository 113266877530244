/*********************************************************************************************************************
 * @file Settings utilities
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 14-May-2021
 *********************************************************************************************************************/
import React from 'react';

import { ConfigurationTypes } from '@licoriceio/constants';
import PropTypes from 'prop-types';

import { uri, GET, PATCH } from '../../constants.js';
import { UX_SETTINGS_SAVE_BUTTON } from '../../ux-constants.js';
import { genericRequest } from '../../redux/reducerUtil.js';
import { settingsSection, sectionHeading, settingsBox, boxLabel, saveButton, topBoxLabel } from '../../scss/Settings.module.scss';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { __ } from '../../utils/i18n.jsx';
import { LicoButton } from '../common/index.js';

// requests

const getOneConfiguration = ( configuration, action, query ) => genericRequest(
    {}, abstractedCreateAuthRequest( GET, uri.SINGLE_CONFIGURATION ), [ action ], [ configuration ], query );

const patchOneConfiguration = ( payload, action ) => genericRequest(
    { type: ConfigurationTypes.SETTINGS, data: payload.data }, 
    abstractedCreateAuthRequest( PATCH, uri.SINGLE_CONFIGURATION ), 
    [ action ], 
    [ payload.configurationId ]
);

// functions

const validateBusinessHours = businessHours => {

    // we want to avoid overlapping sections, which will make it much harder to display the
    // out of hours areas correctly.
    const daySections = [];
    const error = businessHours.map( bh => {

        if ( !bh.weekdays.some( Boolean ) ) 
            return __( "You must select at least one day in each item" );

        const error = bh.weekdays.map( ( flag, index ) => {
            if ( flag ) {
                daySections[ index ] ??= [];

                // check if this section overlaps any existing section
                const match = daySections[ index ].some( section => bh.startTime < section.endTime && bh.endTime > section.startTime );
                if ( match ) 
                    return __( 'There are overlapping items' );

                daySections[ index ].push({ startTime: bh.startTime, endTime: bh.endTime });
                return "";
            }
        }).find( Boolean );

        return error;
    }).find( Boolean );

    return error || "";
};

const handleBusinessHourChange = ( businessHours, name, value, blockIndex, dayIndex ) => {
    if ( /startTime|endTime|weekdays/.test( name ) ) {
        if ( name === 'weekdays' )
            businessHours[ blockIndex ][ name ][ dayIndex ] = value;
        else
            businessHours[ blockIndex ][ name ] = value;

        return validateBusinessHours( businessHours );
    }

    return null;
};

/**
 * Convert a single-byte bitmap value into a list of booleans representing days
 * of the week
 * @param {number} map - a value in the range 0 - 128
 * @returns {boolean[]}
 */
const convertWeekdayMap = map => {
    const flags = [];
    for ( let i = 0; i < 7; i++ )
        flags[ i ] = !!( map & ( 1 << i ) );
    return flags;
};

/**
 * Convert a list of booleans into a bitmap
 * @param {boolean[]} weekdays - list of booleans
 * @returns {number}
 */
const convertWeekdayArray = weekdays => {
    let weekdayMap = 0;
    weekdays.forEach( ( flag, i ) => weekdayMap = weekdayMap | ( ( flag ? 1 : 0 ) << i ) );
    return weekdayMap;
};

/**
 * Converts a minute in the day into an object with hour and minute fields
 * @param {number} minutes - minute of the day, 0 - 1439
 * @param {string} name - Start or End
 * @returns {object}
 */
const splitMinutes = ( minutes, name ) => ({
    [ `workday${name}H` ]: Math.floor( minutes / 60 ),
    [ `workday${name}M` ]: minutes % 60
});

/**
 * Convert the stored businessHours list into a form that's usable and editable
 * @param {BusinessHours[]} businessHours
 * @returns {UsableBusinessHours[]}
 */
const unwrapBusinessHours = businessHours => businessHours.map( bh => ({
    startTime:  bh.workdayStartH * 60 + bh.workdayStartM,
    endTime:    bh.workdayEndH * 60 + bh.workdayEndM,
    weekdays:   convertWeekdayMap( bh.weekdayMap )
}) );

/**
 * Convert the usable businessHours list back into the stored form
 * @param {BusinessHours[]} businessHours
 * @returns {UsableBusinessHours[]}
 */
const wrapBusinessHours = businessHours => businessHours.map( bh => ({
    ...splitMinutes( bh.startTime, 'Start' ),
    ...splitMinutes( bh.endTime, 'End' ),
    weekdayMap: convertWeekdayArray( bh.weekdays )
}) );

// components

const SettingsSection = ({ label, children }) => <div className={settingsSection}>
    {label && <><div className={sectionHeading}>{label}</div>
        <hr /></>}
    {children}
</div>;

SettingsSection.propTypes = {
    label:    PropTypes.string,
    children: PropTypes.node
};

const SettingsBox = ({ label, children, topLabel }) => <div className={settingsBox}>
    <div className={`${boxLabel} ${topLabel ? topBoxLabel : ''}`}>{label}</div>
    {children}
</div>;

SettingsBox.propTypes = {
    label:      PropTypes.string.isRequired,
    children:   PropTypes.node,
    topLabel:   PropTypes.bool
};

const SaveButton = ({ handler, disabled, label }) => <LicoButton
    onClick={() => handler()}
    className={saveButton}
    disabled={disabled}
    data-ux={UX_SETTINGS_SAVE_BUTTON}
>
    {label || __( "Save" )}
</LicoButton>;

SaveButton.propTypes = {
    handler:  PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label:    PropTypes.string
};

export {
    getOneConfiguration,
    patchOneConfiguration,
    handleBusinessHourChange,
    validateBusinessHours,
    convertWeekdayMap,
    convertWeekdayArray,
    splitMinutes,
    unwrapBusinessHours,
    wrapBusinessHours,
    SettingsSection,
    SettingsBox,
    SaveButton
};
