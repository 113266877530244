// import { selectHasUserAcceptedTerms } from "./user.js";

import { isTokenExpired } from "../../utils/jwt.js";

export const selectIsAuth = state => !!state.auth.token;

export const selectIsAuthAndHasAcceptedTerms = state => selectIsAuth( state ) || !isTokenExpired( state?.auth?.token );
export const selectAuth = state => state.auth;

export const selectRememberSession = state => state.auth.rememberSession;

export const getAuthState = state => state.auth;

