/********************************************************************************************************************
 * @file Actions for the password store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setLoginEmail = createAction( 'FORGOT_PASSWORD.LOGIN_EMAIL/SET' );
export const setTouchedForgotPassword = createAction( 'FORGOT_PASSWORD.TOUCHED/SET' );
export const setFormValueForgotPassword = createAction( 'FORGOT_PASSWORD.FORM_VALUE/SET' );
