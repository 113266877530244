import React from "react";

import { Button } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { AssetDialogMode } from "../../../constants.js";
import { saveButton, cancelButton, footer, footerButtons, footerMsg } from '../../../scss/AssetForm.module.scss';
import { __ } from "../../../utils/i18n.jsx";

const FooterSection = ({ selectedAsset, onClose }) => {
    const mode = selectedAsset?.mode || AssetDialogMode.VIEW;
    const [ footerMessage, setFooterMessage ] = React.useState( null );
    const checkRequiredFields = () => {
        const isNonEmptyString = ( value ) => {
            return typeof value === 'string' && value.trim() !== '';
        };
        const hasNonEmptyName = ( obj ) => {
            return obj && isNonEmptyString( obj.name );
        };
        // Check "details" array
        const detailsValid = selectedAsset.details.every( ( detail ) => {
            if ( detail.requiredFlag ) 
                return isNonEmptyString( detail.answer ) || hasNonEmptyName( detail.answer );
            return true;
        });
        // Check "questions" array
        const questionsValid = selectedAsset.questions.every( ( question ) => {
            if ( question.requiredFlag ) 
                return isNonEmptyString( question.answer ) || hasNonEmptyName( question.answer );
            return true;
        });

        // Check if both "details" and "questions" arrays are valid
        const allFieldsValid = detailsValid && questionsValid;
        if ( allFieldsValid ) 
            onClose(); // @todo: save the asset
        else 
            setFooterMessage( __( "Please fill in all required fields" ) );
        
    };

    return (
        <div  className={footer}>
            <div className={footerButtons}>
                {/* save button is commented out for now for readonly mode.   */}
                {/* <Button */}
                {/*     className={saveButton} */}
                {/*     disabled={mode === AssetDialogMode.VIEW} */}
                {/*     onClick={checkRequiredFields} */}
                {/* > */}
                {/*     {mode === AssetDialogMode.ADD ? __( "Add" ) : __( "Save" )} */}
                {/* </Button> */}
                <Button
                    className={cancelButton}
                    disabled={ false}
                    onClick={onClose}
                >
                    {__( "Cancel" )}
                </Button>
            </div>
            {footerMessage && <div className={footerMsg}>{footerMessage}</div> }
        </div>
    );
};

FooterSection.propTypes = {
    selectedAsset: PropTypes.object.isRequired,
    onClose:       PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    selectedAsset: state.jobcard.selectedAsset
});

export default connect( mapStateToProps )( FooterSection );

