import { AppointmentState, Priority, ADD, HistoryActions, MINUTE } from '@licoriceio/constants';
import { pick } from '@licoriceio/utils';
import { v4 as uuidv4 } from 'uuid';

import { mergeMode, PATCH, uri } from '../../constants.js';
import { 
    setAddAppointment, setJobCardNewAppointment,
    setDeleteCalendarEvent, setAddTimeLog, setAddLocalTimeLog, setUpdateJob, setDragInfo, setUpdateCalendarEventStatics,
    setUpdateCompany, setPatchAppointment, setPatchCalendarEvent, setDeleteAppointment
} from "../../redux/actions/index.js";
import { sliceMergeAction, genericRequest } from '../../redux/reducerUtil.js';
import { safeRequest } from '../../redux/safe-request.js';
import { abstractedCreateAuthRequest } from "../../services/util/baseRequests.js";
import { makeDefaultEventDates, getDefaultAppointmentDuration } from '../../utils/user-prefs.js';
import { setNewJobThunk } from '../jobcard/thunks.js';

import { 
    fetchOneJobService, addAppointmentService, patchJobEngineers, patchCalendarEventRequest, addJobAppointmentHistory,
    addCalendarEventRequest
} from './requests.js';
import { setUpdateAppointmentStaticsThunk, setExistingJobThunk, closeCalendarEventThunk, addLocalTimeLogThunk } from './sharedThunks.js';

const addJobCardNewAppointmentThunk = payload => async dispatch => {
    dispatch( setAddAppointment( payload ) );
    dispatch( setUpdateAppointmentStaticsThunk( payload.userId ) );
    dispatch( setJobCardNewAppointment( payload ) );
};

// opened a new job card OR switched back from new calendar event
const addJobThunk = payload => async ( dispatch, getState ) => {

    const jobId = uuidv4();
    let eventDates;

    const { calendarEvent: { busyCardDetails }, jobcard: { newAppointment }, user: { preferences } } = getState();

    // when adding from calendar, check time is ok and then create local appt
    if ( payload ) 
    {
        if ( payload.date ) 
        {
            const { date, allDay } = payload;
            let duration = payload.duration || preferences.defaultAppointmentDuration * MINUTE;

            // single click or drag in single cell gets converted to default appt time.
            if ( duration === 15 * MINUTE )
                duration = getDefaultAppointmentDuration( preferences ) * MINUTE;
            
            eventDates = makeDefaultEventDates({
                start:  date,
                end:    allDay ? "" : new Date( date.getTime() + duration ),
                allDay
            });

            // null return from makeDefaultEventDates means the time was too close to end of day
            if ( !eventDates ) 
                return;
        }
        else if ( payload.jobAddedFromPegboard ) 
        {
            // add a temporary card until the real timeLog arrives
            dispatch( addLocalTimeLogThunk( jobId ) );
        }
    }

    // no payload means we've switched back from a new calendar event to a new job, and the original
    // new job details still apply.
    if ( !payload ) 
    {
        payload = busyCardDetails;
        dispatch( closeCalendarEventThunk() );

        // the old appt was deleted on the switch to the busy card but the details (ie times) are still there;
        // just update the job and appt ids and restore it.
        const newAppt = { ...newAppointment, jobId, appointmentId: uuidv4() };
        dispatch( setAddAppointment( newAppt ) );
        dispatch( setJobCardNewAppointment( newAppt ) );

        eventDates = makeDefaultEventDates({
            start:      new Date( newAppt.startDate ),
            end:        newAppt.endDate ? new Date( newAppt.endDate ) : null,
            allDay:     newAppt.endDate === null
        });

    }

    const newJob = {
        jobId,
        newItemFlag:        true,
        priority:           Priority.normal,
        completedDate:      null,
        estimatedTime:      60,
        description:        "",
        title:              "",
        billable:           true
    };

    // put the empty job in state
    dispatch( setUpdateJob( newJob ) );
    dispatch( setUpdateAppointmentStaticsThunk( ) );

    // open the card and retain details (eg PB vs calendar) for initial insert
    dispatch( setNewJobThunk({ ...payload, jobId: newJob.jobId, billable: newJob.billable }) );

    if ( eventDates ) 
    {

        // display the local appt immediately
        dispatch( addJobCardNewAppointmentThunk({
            jobId,
            appointmentId:      uuidv4(),
            userId:             payload.userId,
            newItemFlag:        true,
            state:              AppointmentState.active,
            ...eventDates
        }) );
    }
    
};



const setDragInfoThunk = payload => async ( dispatch, getState ) => {

    dispatch( setDragInfo( payload ) );

    // if we're changing dragEventId, we should rebuild the lists
    // (the source event of the drag may or may not be filtered out)
    if ( 'dragEventId' in payload ) 
    {
        const { user: { userId } } = getState();
        dispatch( setUpdateAppointmentStaticsThunk( userId ) );
        dispatch( setUpdateCalendarEventStatics( userId ) );
    }
};


const getJobActionThunk = payload => async ( dispatch, getState ) => {

    const { job: { jobMap, clientMap } } = getState();

    if ( jobMap[ payload.jobId ]) 
    {
        if ( payload.openJobCard )
            dispatch( setExistingJobThunk( jobMap[ payload.jobId ]) );
    }
    else 
    {
        fetchOneJobService( undefined, getState().auth, [ payload.jobId ], {
            related: [ 'job->?company' ], fields: [ 'job.*', 'company.companyName' ]
        })
            .then( result => {
                if ( !result.hasError ) 
                {
                    const jobData = result.payload[ 0 ];

                    if ( jobData.companyId && !clientMap[ jobData.companyId ]) 
                        dispatch( setUpdateCompany( jobData ) );

                    dispatch({ 
                        type:       sliceMergeAction( 'job' ), 
                        payload:    { 
                            slice:      'job', 
                            id:         payload.jobId, 
                            mode:       mergeMode.NO_BLANKING, 
                            result
                        } });

                    dispatch( setUpdateAppointmentStaticsThunk( ) );

                    // open the job card if required, eg after clicking on a new job notification
                    if ( payload.openJobCard )
                        dispatch( setExistingJobThunk( payload ) );
                }
            });
    }
};

const setAddAppointmentThunk = payload => async ( dispatch, getState ) => {
    const { calendar: { userMap }, job: { jobMap } } = getState();

    // ignore unless the appointment's owner's calendar is open
    if ( payload.userId in userMap ) 
    {
        dispatch( setAddAppointment( payload ) );
        if ( !( payload.jobId in jobMap ) )
            dispatch( getJobActionThunk({ jobId: payload.jobId }) );
        dispatch( setUpdateAppointmentStaticsThunk( payload.userId ) );
    }
};

const addAppointmentRequest = appointment => genericRequest( appointment, addAppointmentService, setAddAppointmentThunk );

const addAppointmentThunk = appointment => async ( dispatch, getState ) => {

    // we want to create a new appointment immediately so there's no visual lag.
    // We also need this to work for multiple new appointments; it's easy to
    // reschedule 2 appointments quickly, so we use the start time as part of the id.

    const appointmentId = uuidv4();

    // we may be copying an appt to another user; create local appt iff we have their calendar open
    const { calendar: { userMap }, user: { userId } } = getState();
    if ( appointment.userId in userMap ) 
    {
        const newLocalAppt = {
            ...appointment,
            appointmentId,
            newItemFlag:    true,
            jobId:          appointment.jobId,
            state:          AppointmentState.active
        };
        dispatch( setAddAppointmentThunk( newLocalAppt ) );
    }

    // add the appointment
    dispatch( addAppointmentRequest({ ...appointment, appointmentId, newItemFlag: true, creatorId: userId }) );
};

const setPatchAppointmentThunk = payload => async ( dispatch ) => {
    dispatch( setPatchAppointment( payload.appointment ) );
    dispatch( setUpdateAppointmentStaticsThunk( payload.appointment.userId ) );
};

const safePatchAppointmentRequest = payload => safeRequest({
    resource: payload.jobId,
    data:     payload.data,
    request:  abstractedCreateAuthRequest( PATCH, uri.SINGLE_APPOINTMENT ),
    action:   setAddAppointmentThunk,
    ids:      [ payload.appointmentId ]
});

const patchAppointmentRequest = 
    payload => genericRequest( payload.data, abstractedCreateAuthRequest( PATCH, uri.SINGLE_APPOINTMENT ), setAddAppointmentThunk, [ payload.appointmentId ]);

const patchTimedEventThunk = payload => async ( dispatch ) => {

    const { data, appointmentId, localOnly = false } = payload;
    delete payload.localOnly;

    if ( appointmentId ) 
    {
        const { state } = data;

        // locally update appt
        dispatch( setPatchAppointmentThunk({ appointment: payload }) );

        // update backend if required
        if ( !localOnly ) 
        {
            if ( state === AppointmentState.cancelled )
                dispatch( safePatchAppointmentRequest( payload ) );
            else
                dispatch( patchAppointmentRequest( payload ) );
        }
    }
    else 
    {

        // For CEs, data comes in separately for reasons that escape me, but we want it combined
        // since that's how the other calls to setPatchCalendarEvent work.
        const calendarEvent = { ...payload, ...data };
        delete calendarEvent.data;
        delete calendarEvent.type;

        // local state
        dispatch( setPatchCalendarEvent( calendarEvent ) );

        // update backend if required
        if ( !localOnly )
            dispatch( patchCalendarEventRequest( calendarEvent ) );
    }
};

const rescheduleTimedEventThunk = ( newUserId, event ) => async ( dispatch, getState ) => {

    const { extendedProps: { appointmentId, jobId, userId, calendarEventId, typeId, description, isPrivate } } = event;
    const { calendar: { altPressed, userMap }, job: { jobMap } } = getState();

    dispatch( setDragInfo({ altPressed: null }) );

    const eventDates = makeDefaultEventDates( event );

    // illegal operation, likely a cross-day drag
    if ( !eventDates ) 
    {
        if ( newUserId !== userId )
            dispatch( setUpdateAppointmentStaticsThunk( userId ) );
        event.remove();
        return;
    }

    if ( appointmentId ) 
    {

        if ( altPressed ) 
        {

            // add new appt
            dispatch( addAppointmentThunk({
                jobId,
                userId:             newUserId,
                rescheduling:       newUserId === userId,
                newItemFlag:        true,
                ...eventDates
            }) );
        }
        else 
        {

            // patch appointment; it may be in a different user's calendar
            dispatch( patchTimedEventThunk({
                userId:            newUserId,
                appointmentId,
                data:               { ...eventDates, userId: newUserId }
            }) );

            // record the old time
            dispatch( addJobAppointmentHistory({
                table:      'appointment',
                action:     HistoryActions.reschedule,
                id:         appointmentId,
                parentId:   jobId,
                data:       pick( userMap[ userId ].appointmentMap[ appointmentId ], [ 'startDate', 'endDate', 'userId' ])
            }) );

            // if we dragged from one calendar to another, we have to remove the local copy
            // from the old user
            if ( newUserId !== userId ) 
            {
                dispatch( setDeleteAppointment({
                    userId,
                    appointmentId
                }) );
                dispatch( setUpdateAppointmentStaticsThunk( userId ) );

                // add new user to job unless they're the job owner 
                const job = jobMap[ jobId ];
                if ( job?.userId !== newUserId )
                    dispatch( patchJobEngineers({ id: jobId, data: { action: ADD, people: [ newUserId ] } }) );
            }
        }

    }
    else 
    {

        const baseEvent = {
            userId:             newUserId,
            licoriceNameId:     typeId,
            title:              event.title,
            description,
            isPrivate,
            ...eventDates
        };

        if ( altPressed ) 
        {

            // create a copy of the referenced event with the new user & date fields as specified
            dispatch( addCalendarEventRequest( baseEvent ) );

        }
        else 
        {

            // supply all the fields in case we're moving to a different user who doesn't have this CE already
            dispatch( patchTimedEventThunk({
                calendarEventId,
                ...baseEvent
            }) );

            // if we dragged from one calendar to another, we have to remove the local copy
            // from the old user
            if ( newUserId !== userId ) 
            {
                dispatch( setDeleteCalendarEvent({
                    userId,
                    calendarEventId
                }) );
                dispatch( setUpdateCalendarEventStatics( userId ) );

            }
        }

    }

    // remove the temporary drag event created by FC
    event.remove();

};


export {
    addJobCardNewAppointmentThunk,
    closeCalendarEventThunk,
    addJobThunk,
    setDragInfoThunk,
    getJobActionThunk,
    setAddAppointmentThunk,
    addAppointmentThunk,
    addAppointmentRequest,
    setPatchAppointmentThunk,
    patchTimedEventThunk,
    rescheduleTimedEventThunk
};
