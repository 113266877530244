/********************************************************************************************************************
 * @file Actions for the integration store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setProviders = createAction( 'INTEGRATION.PROVIDERS/SET' );
export const setProvider = createAction( 'INTEGRATION.PROVIDER/SET' );
export const setIntegrationFieldTouched = createAction( 'INTEGRATION.FIELD_TOUCHED/SET' );
export const setIntegrationFieldValue = createAction( 'INTEGRATION.FIELD_VALUE/SET' );
export const setIntegrationError = createAction( 'INTEGRATION.ERROR/SET' );
export const setIntegrationSettings = createAction( 'INTEGRATION.SETTINGS/SET' );
export const setMappingValue = createAction( 'INTEGRATION.MAP_VALUE/SET' );
export const setIntegrationProgress = createAction( 'INTEGRATION.PROGRESS/SET' );
