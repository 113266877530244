import React, { useRef } from 'react';

import { Popover } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setJobCardAssets } from "../../../redux/actions/index.js";

import  AssetAutoComplete  from './AssetsAutoComplete.jsx';

const AssetPopover = ({ isPopoverOpen, onClose, anchorEl, clientId }) => {
    const popoverRef = useRef( null );

    return (
        <>
            { isPopoverOpen && (
                <div ref={popoverRef}>
                    <Popover
                        open={Boolean( anchorEl )}
                        anchorEl={anchorEl}
                        onClose={onClose}
                        anchorOrigin={{
                            vertical:   'top',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical:   'top',
                            horizontal: 'left'
                        }}
                        PaperProps={{
                            style: {
                                overflowY: 'unset',
                                overflowX: 'unset'
                            }
                        }}
                    >
                        <AssetAutoComplete  anchorEl={anchorEl} clientId={clientId}/>
                    </Popover>

                </div>
            )}
        </>
    );
};

AssetPopover.propTypes = {
    isPopoverOpen:     PropTypes.bool.isRequired,
    onClose:           PropTypes.func,
    anchorEl:          PropTypes.any || '',
    clientId:          PropTypes.string.isRequired
};


const mapDispatchToProps = {
    setJobCardAssets
};

export default connect( null, mapDispatchToProps )( AssetPopover );
