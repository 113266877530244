/**
 * The steps are:
 *
 * if ( !password ) ask for email and password
 * if ( !settingsMfa ) get settingsMfa
 * if ( !mfaCode ) get mfa code
 * doLogin( email, password, code )
 * if ( token && terms ) redirect to success
 *
 *
 * For MFA enable, the steps are
 * Mfa( redirect )
 * verify password (repeat while wrong, escape aborts)
 * verify mfa (repeat while wrong, escape aborts)
 * set mfa in user settings
 * redirect
 *
 *         {token && user.userId && !hasAcceptedTerms && <UserTerms/>}
 *         {token && hasAcceptedTerms && <Redirect to={landingPage}/>}
 */

import React, { useEffect } from 'react';

import { SECOND } from '@licoriceio/constants';
import { has } from '@licoriceio/utils';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { uri, POST } from '../../constants.js';
import { setCreds, setLoginFields, setMfaBackLogin } from '../../redux/actions/index.js';
import { getLoadingStates } from '../../redux/reducerUtil.js';
import { selectSystemReady } from '../../redux/selectors/index.js';
import * as scss from '../../scss/Login.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { MfaStates } from '../../utils/mfa.js';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { P, LicoIcon } from "../common/index.js";
import PreAuthLayout from '../layouts/PreAuthLayout.jsx';
import { SETTINGS_INTEGRATION, HOME } from '../navigation/routes.js';

import Mfa from './Mfa.jsx';
import NamePassword from './NamePassword.jsx';
import { doLogin, checkPassword, setQrCode } from './reducer.js';
import UserTerms from './UserTerms.jsx';


const {
    root,
    loading,
    subdomainText,
    errorLine,
    subHeading,
    back: backStyle
} = scss;

const ChewyCenter = ({ rootClasses, snackBar, mfaState, domain, children, error, back }) => {
    const fatViews = [ MfaStates.NONE, MfaStates.USE, MfaStates.RECOVERY, MfaStates.SETUP ];
    const slimView = !!mfaState && !fatViews.includes( mfaState );

    return <PreAuthLayout className={rootClasses} rootComponent={snackBar} slimView={slimView}>
        <BannerLogo />
        {domain && <P align="center" className={subdomainText}> {domain}</P>}

        {back && <div className={subHeading}>
            <span className={backStyle} onClick={back}>
                <LicoIcon icon="angle-left" />
                &nbsp;
                {__( "Back" )}
            </span>
        </div>}

        <div className={errorLine}>{error} &nbsp;</div>
        {children}
    </PreAuthLayout>;
};

ChewyCenter.propTypes = {
    rootClasses:    PropTypes.string.isRequired,
    snackBar:       PropTypes.node,
    mfaState:       PropTypes.string.isRequired,
    domain:         PropTypes.string.isRequired,
    children:       PropTypes.node.isRequired,
    error:          PropTypes.string,
    back:           PropTypes.func
};

const Login = props => {
    const {
        domain,
        settings,
        error,
        email,
        password,
        setCreds,
        token,
        provider,
        user,
        passwordReset,
        setLoginFields,
        loadingLogin,
        loadingTerms,
        mfaState,
        mfaVerified,
        checkPassword,
        doLogin,
        setQrCode,
        qrcode,
        setMfaBackLogin,
        timestamp,
        systemReady
    } = props;

    const rootClasses      = root + ( loadingLogin || loadingTerms ? ( ' ' + loading ) : '' );
    const hasAcceptedTerms = user?.eula && user?.termsConditions && user?.privacyPolicy;
    const display = ( comp, back ) => (
        <ChewyCenter rootClasses={rootClasses} snackBar={snackBar} mfaState={mfaState} domain={domain} error={error} back={back}>
            {comp}
        </ChewyCenter>
    );

    const snackBar = <Snackbar
        anchorOrigin={{
            vertical:   "top",
            horizontal: "center"
        }}
        open={passwordReset}
        autoHideDuration={3 * SECOND}
        onClose={() => setLoginFields({ passwordReset: false })}
    >
        <MuiAlert variant="filled" onClose={() => setLoginFields({ passwordReset: false })} severity="success">
            {__( "Password changed successfully" )}
        </MuiAlert>
    </Snackbar>;

    // don't redirect until the provider info has arrived; after that, check the system ready flag in case
    // we're running standalone. (Exception; if we've already chosen standalone, don't wait for provider)
    const landingPage = systemReady || has( provider, 'integrated' )
        ? systemReady ? HOME : SETTINGS_INTEGRATION
        : null;

    // password is here since we also check that in the checkPassword call and if we don't have it
    // we don't re-get settings ie on wrong pw. Timestamp is here so we retry every time we press the button,
    // in case there was a connection issue.
    useEffect( () => {
        if ( !settings && email )
            checkPassword();
    }, [ settings, checkPassword, email, password, timestamp ]);

    useEffect( () => {
        if ( email && password && settings && ( !settings.mfa || qrcode || mfaVerified ) )
            doLogin();
    }, [ email, password, settings, qrcode, doLogin, mfaVerified ]);

    // !settings so this stays up until settings is loaded. At that point, we either log in
    // or start MFA.
    if ( !email || !password || ( !settings || ( !settings.mfa && ( !token || !landingPage ) ) ) )
        return display( <NamePassword onDone={setCreds} /> );

    // !token keeps the MFA code screen (either version) up during login
    if ( !token && mfaState !== MfaStates.NONE )
        return display( <Mfa organisationMfa={true} onDone={q => setQrCode( q )} />, setMfaBackLogin );

    if ( !token || !user.userId || !landingPage )
        return null;

    if ( !hasAcceptedTerms )
        return display( <UserTerms/> );

    if ( token && user.userId && hasAcceptedTerms && landingPage )
        return display( <Redirect to={landingPage}/> );

    return display( null );
};

Login.propTypes = {
    domain:                 PropTypes.string.isRequired,
    settings:               PropTypes.object,
    error:                  PropTypes.string,
    email:                  PropTypes.string.isRequired,
    password:               PropTypes.string,
    setCreds:               PropTypes.func.isRequired,
    token:                  PropTypes.string,
    user:                   PropTypes.object.isRequired,
    passwordReset:          PropTypes.bool,
    setLoginFields:         PropTypes.func.isRequired,
    loadingLogin:           PropTypes.bool,
    loadingTerms:           PropTypes.bool,
    mfaState:               PropTypes.string.isRequired,
    mfaVerified:            PropTypes.bool.isRequired,
    checkPassword:          PropTypes.func.isRequired,
    doLogin:                PropTypes.func.isRequired,
    setQrCode:              PropTypes.func.isRequired,
    qrcode:                 PropTypes.string,
    setMfaBackLogin:        PropTypes.func.isRequired,
    provider:               PropTypes.object,
    timestamp:              PropTypes.string,
    systemReady:            PropTypes.bool.isRequired
};

const mapStateToProps = state => {

    const [ loadingLogin, loadingTerms ] = getLoadingStates( state, POST + uri.LOGIN, POST + uri.UPDATE_TERMS );

    const { login: { qrcode, error, password, email, mfaState, settings, mfaVerified, timestamp }, integration: { provider } } = state;

    return {
        qrcode,
        error,
        password,
        email,
        provider,
        mfaState,
        settings,
        mfaVerified,
        loadingLogin,
        loadingTerms,
        timestamp,
        token:          state.auth.token,
        user:           state.user,
        domain:         state.meta.domain,
        passwordReset:  state.meta.passwordReset,
        systemReady:    selectSystemReady( state )
    };
};

const mapDispatchToProps = { setLoginFields, checkPassword, doLogin, setQrCode, setCreds, setMfaBackLogin };

export default connect( mapStateToProps, mapDispatchToProps )( Login );
export { Login, ChewyCenter };
