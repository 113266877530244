/**
 * @typedef {object} GenericDialogParentProps
 * @property {boolean} isOpen
 * @property {string} title
 * @property {string} message
 * @property {any[]} actions
 * @property {string[]} labels
 * @property {DialogButtonColors[]} [colors]
 */

import React from 'react';

import { Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { root, content, contentText, actionsStyle, cancelButton, safetyButton, dangerButton, titleStyle } from '../../scss/GenericDialog.module.scss';
import { LicoButton } from '../common/index.js';

const DialogButtonColors = {
    safety: 'safety',
    danger: 'danger',
    cancel: 'cancel'
};

/**
 * @param {GenericDialogParentProps} props
 * @return {JSX.Element}
 */
const GenericDialog = props => {

    const {
        isOpen,
        title,
        message,
        buttons,
        extraContent
    } = props;

    const colorClass = {
        [ DialogButtonColors.safety ]: safetyButton,
        [ DialogButtonColors.danger ]: dangerButton,
        [ DialogButtonColors.cancel ]: cancelButton
    };

    return (
        <Dialog
            open={isOpen}
            onClose={buttons[ buttons.length - 1 ].action}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                paper: root
            }}
        >
            <Card>
                <DialogTitle id="alert-dialog-title" className={titleStyle}>
                    {title}
                </DialogTitle>
            </Card>
            <DialogContent className={content}>
                <DialogContentText id="alert-dialog-description" className={contentText}>
                    {message}
                    {extraContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                className={actionsStyle}>
                {buttons.map( ( button, i ) =>
                    <LicoButton
                        key={i}
                        onClick={button.action}
                        disabled={!button.action}
                        className={colorClass[ button.color || DialogButtonColors.cancel ]}
                        color="inherit"
                        size="small"
                        autoFocus={i === buttons.length - 1}
                    >
                        {button.label}
                    </LicoButton>
                )}
            </DialogActions>
        </Dialog >
    );
};

GenericDialog.propTypes = {
    isOpen:       PropTypes.bool.isRequired,
    title:        PropTypes.string.isRequired,
    message:      PropTypes.any.isRequired,
    buttons:      PropTypes.array.isRequired,
    extraContent: PropTypes.any
};

export default connect()( GenericDialog );

export {
    GenericDialog,
    DialogButtonColors
};
