import React from 'react';

import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

export const LicoCircularProgress = ({ progress }) => <CircularProgress value = {progress * 100} variant = "determinate" />;

LicoCircularProgress.propTypes = {
    progress: PropTypes.number
};
