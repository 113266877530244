import React, { PureComponent } from 'react';

import { numMinutesToString } from '@licoriceio/utils';
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setEngineerId, setStartTime } from '../../redux/actions/index.js';
import { scheduleCalendar, contentRow, calendar, engineerHeading, engineerList, staticListItem, loader } from '../../scss/ScheduleCalendar.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { renderEventContent, MIN_FULL_EVENT_DURATION_MSECS } from "../calendar/UserCalendar.jsx";
import { LicoIcon } from "../common/index.js";
import FullCalendar, { timeGridPlugin, interactionPlugin } from '../FullCalendar.jsx';

import BottomRow from './BottomRow.jsx';
import {
    progressStage,
    findEngineerTimes,
    endFromStart,
    slotMinutes
} from './reducer.js';
import SelectEngineerLine from './SelectEngineerLine.jsx';
import TopRow from './TopRow.jsx';

const generateEventClassNames = info => {

    const { event: { start, end, groupId, extendedProps: { engineerId, priority } } } = info;

    // background groups just use the group name as the class
    if ( groupId )
        return [ groupId ];

    const classes = [ priority ];
    if ( start && end ) {

        // is this event too short to display sizer handle?
        const duration = end.getTime() - start.getTime();
        if ( duration < MIN_FULL_EVENT_DURATION_MSECS )
            classes.push( "no-resizer" );
    }

    if ( engineerId )
        classes.push( 'scheduler-booked' );

    return classes;
};

/**
 * @typedef {object} CalEvent
 * @property {string} [title]
 * @property {string|Date} start
 * @property {Date} end
 * @property {string} [display]
 * @property {string} [color]
 * @property {boolean} [editable]
 * @property {string} [constraint]
 * @property {string} [groupId]
 * @property {string} [engineerId]
 */

/** */
class ScheduleCalendar extends PureComponent
{
    render()
    {
        const {
            job,
            startTime,
            setStartTime,
            engineerId,
            setEngineerId,
            findEngineerTimes,
            weekStart,
            currentWeekData,
            defaultDuration,
            showWeekends
        } = this.props;

        // draw the current event selection
        /** @type {CalEvent[]} */
        const events = startTime
            ? [
                {
                    title:          job.title,
                    start:          startTime,
                    end:            endFromStart( startTime, defaultDuration ).format(),
                    constraint:     'engineerBooked',
                    engineerId:     engineerId,
                    priority:       job.priority,
                    companyName:    job.companyName
                }
            ]
            : [];

        const exclusions = currentWeekData.exclusions || [];
        exclusions.forEach( exclusion => {
            events.push({
                groupId: 'engineerBooked',
                start:   new Date( exclusion.start ),
                end:     new Date( exclusion.end ),
                display: 'background',
                color:   exclusion.background || '#ddd'
            });
        });

        // const colors = {
        //     "1": "#f00",
        //     "2": "#00f"
        // };

        // const engineers = currentWeekData.engineers || [];
        // engineers.forEach( e => {
        //     e.ranges.forEach( ev => {
        //         events.push({
        //             groupId: 'engineerBooked',
        //             start:   new Date( ev.start ),
        //             end:     new Date( ev.end ),
        //             display: 'background',
        //             // color: '#ddd',
        //             color:   colors[ String( e.id ) ]
        //         });
        //     });
        // });

        return <div className={scheduleCalendar}>
            <TopRow job={job}/>

            <div className={contentRow}>

                <div className={calendar}>
                    <FullCalendar
                        initialView="fiveDay"
                        firstDay={1}
                        plugins={[ timeGridPlugin, interactionPlugin ]}
                        headerToolbar={{
                            left:   "today prev next",
                            center: 'title',
                            right:  ''
                        }}

                        buttonText={{
                            today: __( "Today" )
                        }}

                        weekends={false}
                        height="100%"
                        expandRows={true}
                        events={events}
                        eventDurationEditable={false}
                        dateClick={( arg ) => {

                            // not sure why FC doesn't do this for us, or at least make it easy.
                            if ( arg.jsEvent.target && arg.jsEvent.target.classList.contains( 'fc-bg-event' ) )
                                return;

                            const start = dayjs( arg.dateStr );
                            if ( start.hour() < 17 || ( start.hour() === 17 && start.minute() === 0 ) )
                            {
                                setStartTime( arg.dateStr );
                                setEngineerId( '' );
                            }
                        }}

                        // not allowing drag for now
                        editable={false}
                        // eventDrop={arg => {
                        //     setStartTime(arg.event.startStr);
                        //     setEngineerId('');
                        // }}

                        allDaySlot={false}
                        displayEventEnd={false}
                        slotMinTime={numMinutesToString( currentWeekData.startMinute )}
                        slotMaxTime={numMinutesToString( currentWeekData.endMinute )}
                        slotDuration={`00:${String( slotMinutes ).padStart( 2, '0' )}`}
                        dayHeaderContent={arg => {
                            const mdate = dayjs( arg.date );
                            return mdate.format( "ddd D-MMM" );
                        }}

                        validRange={{
                            start: new Date(),
                            end:   new Date( currentWeekData.lastValidDay )
                        }}

                        views={{
                            fiveDay: {
                                type:          "timeGridWeek",
                                duration:      { days: 7 },  // But if the weekends aren't showing, 5. Depends if Sat/Sun in Open Hours.
                                weekends:      showWeekends,
                                dateAlignment: "week"
                            }
                        }}

                        datesSet={arg => {
                            if ( arg.startStr !== weekStart )
                                findEngineerTimes( arg.startStr );
                        }}

                        eventContent={renderEventContent}
                        eventClassNames={generateEventClassNames}
                    />

                </div>

                <div className={engineerList}>
                    <div className={engineerHeading}>
                        {__( "AVAILABLE ENGINEERS" )}
                    </div>
                    {currentWeekData.loading
                        ? <div className={staticListItem}><LicoIcon className={loader} icon="cog"/></div>
                        : startTime
                            ? ( currentWeekData.slotEngineerLists[ startTime ] || []).map( e => <SelectEngineerLine key={e.id} engineer={e}/> )
                            : <div className={staticListItem}>{__( "Select an appointment time to see available Engineers" )}</div>}
                </div>

            </div>

            <BottomRow previousLabel={__( "< Back" )} previousStage={progressStage.LANDING}
                nextLabel={__( "Continue >" )} nextStage={progressStage.CONFIRM} nextDisabled={!engineerId}/>

        </div>;
    }

    static propTypes = {
        job:                PropTypes.object.isRequired,
        startTime:          PropTypes.string.isRequired,
        engineerId:         PropTypes.string.isRequired,
        setStartTime:       PropTypes.func.isRequired,
        setEngineerId:      PropTypes.func.isRequired,
        findEngineerTimes:  PropTypes.func.isRequired,
        weekStart:          PropTypes.string.isRequired,
        currentWeekData:    PropTypes.object.isRequired,
        defaultDuration:    PropTypes.any.isRequired,
        showWeekends:       PropTypes.bool.isRequired
    };
}

const mapStateToProps = state => {

    const { scheduling: { job, startTime, engineerId, weekStart, currentWeekData, defaultDuration, showWeekends } } = state;

    return {
        job, startTime, engineerId, weekStart, currentWeekData, defaultDuration, showWeekends
    };
};

const mapDispatchToProps = {
    setStartTime,
    setEngineerId,
    findEngineerTimes
};

export default connect( mapStateToProps, mapDispatchToProps )( ScheduleCalendar );
export { ScheduleCalendar };
