import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';

import { UX_LOGOUT_BUTTON, UX_SETTINGS, UX_USER_MENU } from "../../ux-constants.js";
import { USER_LOGOUT } from '../../redux/actions/index.js';
import { selectSystemReady } from '../../redux/selectors/index.js';
import * as scss from '../../scss/UserMenu.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { NavMenuTrigger, NavMenuListButtonItem, NavMenuListLinkItem } from "../common/index.js";
import LicoAvatar from '../common/LicoAvatar.jsx';

import { SETTINGS, SETTINGS_INTEGRATION } from './routes.js';

const { accountButton } = scss;

const UserMenu = ({ avatar, gravatarEmail, systemReady }) => {
    const dispatch     = useDispatch();
    const handleLogout = useCallback( () => void dispatch({ type: USER_LOGOUT }), [ dispatch ]);

    return (
        <div className={accountButton}>
            <NavMenuTrigger
                dataName={UX_USER_MENU}
                menuId="userMenu"
                buttonProps={{
                    className: accountButton,
                    children:  ""
                }}
                buttonChildren={<LicoAvatar avatar={avatar} gravatarEmail={gravatarEmail}/>}
            >
                <NavMenuListLinkItem to={systemReady ? SETTINGS : SETTINGS_INTEGRATION} dataName={UX_SETTINGS} text={__( "Settings" )}>settings </NavMenuListLinkItem>
                <NavMenuListButtonItem onClick={handleLogout} data-ux={UX_LOGOUT_BUTTON} text={__( "Logout" )}/>
            </NavMenuTrigger>
        </div>
    );
};

UserMenu.propTypes = {
    avatar:         PropTypes.object,
    gravatarEmail:  PropTypes.string,
    systemReady:    PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    systemReady:    selectSystemReady( state )
});

export default connect( mapStateToProps )( UserMenu );
export { UserMenu };
