import React from "react";

import { red } from "@mui/material/colors";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AssetDialogMode } from "../../../constants.js";
import { setSelectedAsset } from "../../../redux/actions/index.js";
import scss from '../../../scss/AssetForm.module.scss';
import { LicoSwitch } from "../../common/index.js";

const AssetFormViewFields = ({ sectionName, fieldList = [], mode, selectedAsset, setSelectedAsset }) => {
    const {
        readonly,
        fieldContainer,
        viewLabel,
        formHeading,
        formQuestionList,
        editInputField,
        toggleField,
        redMsg
        // editSelectField
    } = scss;

    // Function to render the field value based on the mode
    const renderFieldValue = ( field ) => {
        const answer = mode === AssetDialogMode.ADD ? '' :  ( typeof field.answer === 'string' || typeof field.answer === 'boolean' )  ? field.answer : field.answer?.name;
        const fieldEntryType = field?.fieldType?.toLowerCase();
        if ( fieldEntryType === 'text' ) {
            return (
                <input className={editInputField}
                    type="text"
                    value={answer}
                    onChange={( e ) => handleInputChange( field.id, e.target.value )}
                    disabled={true}  // disable until apis are ready to update asset.
                />
            );
        }
        else if ( fieldEntryType === 'textarea' ) {
            return ( <textarea
                value={answer}
                onChange={( e ) => handleInputChange( field.id, e.target.value )}
                className={editInputField}
                rows={4}
                cols={50}
                disabled={true}  // disable until apis are ready to update asset.
            /> );
        }
        else if ( fieldEntryType === 'date' ) {
            const formattedDate = answer ? new Date( answer ).toISOString().slice( 0, -8 ) : '';
            return (
                <input
                    type="datetime-local"
                    value={formattedDate}
                    onChange={( e ) => handleInputChange( field.id, e.target.value )}
                    className={editInputField}
                    disabled={true}  // disable until apis are ready to update asset.
                />
            );
        }
        else if (  fieldEntryType ===  "boolean" ) {
            return  (

                <div className={toggleField}>
                    <LicoSwitch
                        name="assetForm"
                        checked={answer}
                        onChange={( e ) => {
                            handleInputChange( field.id, e.target.checked );
                        }}
                        valueTransform={x => x}
                        disabled={true}  // disable until apis are ready to update asset.
                    />
                </div>

            ); }
        //  Commenting out the select field for now, as the dropdown options are not yet available.
            
        // else if ( fieldEntryType === 'select' ) {
        //     const options = getOptions( field.question );
        //     // in progress
        //
        //     if ( field.question.toLowerCase() === 'Vendor' ) {
        //         return <DynamicSelect
        //             dataName='assetForm'
        //             initialText={answer}
        //             open={true}
        //         />;
        //     } else
        //     { return (
        //         <LicoSelect
        //             name={field.question}
        //             options={options}
        //             inputClassName={editSelectField}
        //             externalValue={answer}
        //             noMargins={true}
        //             noLabel={true}
        //         />
        //
        //     ); }
        // }
        else {
            // change default to view mode
            return (
                <input className={editInputField}
                    type="text"
                    value={answer}
                    onChange={( e ) => handleInputChange( field.id, e.target.value )}
                    disabled={true}  // disable until apis are ready to update asset.

                />
            );
        }
        
    };

    const handleInputChange = ( fieldId, newValue ) => {

        if ( mode !== AssetDialogMode.ADD )
            mode = AssetDialogMode.EDIT;
        // TO-do: Filter out some fields from the details and questions
        // Find the field in both details and questions
        const updatedDetails = selectedAsset.details.map( ( detail ) =>
            detail.id === fieldId ? { ...detail, answer: newValue } : detail
        );
        const updatedQuestions = selectedAsset.questions.map( ( question ) =>
            question.id === fieldId ? { ...question, answer: newValue } : question
        );

        // Update selectedAsset with the new details and questions
        const updatedSelectedAsset = {
            ...selectedAsset,
            mode:      mode,
            details:   updatedDetails,
            questions: updatedQuestions
        };
        setSelectedAsset( updatedSelectedAsset );
    };

    // Commenting out the select field for now, as the dropdown options need to be added for edit mode.
    // const getOptions = ( question ) => {
    //     if ( question === 'Status' ) { return  [
    //         "Active",
    //         "Inactive"
    //     ]; }
    //     // if ( question === 'Manufacturer' ) return ManufacturerList;
    //     return [ { label: 'Option 1', value: 'option1', id: 'option1' },
    //     { label: 'Option 2', value: 'option2', id: 'option1' },
    //     { label: 'Option 3', value: 'option3', id: 'option1' } ];
    // };
    

    return (
        <div className={formQuestionList}>
            <div  >
                {  <div className={`${formHeading} ${readonly}`}>{sectionName}</div> }
                { fieldList.map( field  => (
                    <div key={field.id} className={readonly} >
                        <div className={fieldContainer}>
                            <div className={viewLabel}>
                                {field.question}
                                {field?.requiredFlag ? <span className={redMsg}>*</span> : null}
                            </div>
                            {renderFieldValue( field )}
                        </div>
                    </div>
                ) )}
            </div>
        </div>
    );
};

AssetFormViewFields.propTypes = {
    sectionName:          PropTypes.string.isRequired,
    fieldList:            PropTypes.array,
    mode:                 PropTypes.string,
    selectedAsset:        PropTypes.object.isRequired,
    setSelectedAsset:     PropTypes.func.isRequired

};
const mapStateToProps = state => ({
    selectedAsset: state.jobcard.selectedAsset,
    mode:          state.jobcard.selectedAsset?.mode
});

const mapDispatchToProps = {
    setSelectedAsset
};
export default connect( mapStateToProps, mapDispatchToProps )( AssetFormViewFields );
