import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card } from '@mui/material';
import PropTypes from 'prop-types';

import { UX_WARNING_YES_BUTTON, UX_WARNING_NO_BUTTON } from '../../../ux-constants.js';
import { warningConfirmDialog, confirmButton, cancelButton, content, titleClass, contentText, actions } from '../../../scss/WarningConfirmDialog.module.scss';
import { __ } from '../../../utils/i18n.jsx';
import { LicoButton } from '../atomic/form/index.js';

export const WarningConfirmDialog = props => {
    const { isOpen, message, title, onContinue, onCancel } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: warningConfirmDialog }}
        >
            <Card>
                <DialogTitle id="alert-dialog-title" className={titleClass}>
                    {title}
                </DialogTitle>
            </Card>
            <DialogContent className={content}>
                <DialogContentText id="alert-dialog-description" className={contentText}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                className={actions}>
                <LicoButton onClick={onContinue} data-ux={UX_WARNING_YES_BUTTON} className={confirmButton} color="inherit" size="small">
                    {__( "Yes" )}
                </LicoButton>
                <LicoButton onClick={onCancel} data-ux={UX_WARNING_NO_BUTTON} className={cancelButton} autoFocus color="inherit" size="small">
                    {__( "No" )}
                </LicoButton>
            </DialogActions>
        </Dialog>
    );
};

WarningConfirmDialog.propTypes = {
    isOpen:     PropTypes.bool.isRequired,
    title:      PropTypes.any,
    message:    PropTypes.any.isRequired,
    onContinue: PropTypes.func,
    onCancel:   PropTypes.func
};
