import React from 'react';

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const Caption = ({ className = '', children, ...rest }) => <Typography gutterBottom variant="caption"{...rest} className={className}>{children}</Typography>;

Caption.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node
};
