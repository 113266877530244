/********************************************************************************************************************
 * @file
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21-Dec-2021
 *********************************************************************************************************************/

let store;

const publishStore = newStore => store = newStore;
const getStore = () => store;

export { store, publishStore, getStore };
