/** ******************************************************************************************************************
 * @file H# from react-common.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 29-May-2021
 *********************************************************************************************************************/
import React from 'react';

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

// @todo default color primary
const H3 = ({ className = '', children, ...rest }) =>
    <Typography gutterBottom variant="h3" {...rest} className={className}>{children}</Typography>;

H3.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node
};

const H2 = ({ className = '', children, ...rest }) =>
    <Typography gutterBottom variant="h2" {...rest} className={className} >{children}</Typography>;

H2.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node
};

const H1 = ({ className = '', children, ...rest }) =>
    <Typography gutterBottom variant="h1" {...rest} className={className} >{children}</Typography>;

H1.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node
};

export { H1, H2, H3 };
