import React from 'react';

import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { uri } from '../../constants.js';
import { UX_GET_RESET_CODE } from "../../ux-constants.js";
import { setLoginEmail, setTouchedForgotPassword } from '../../redux/actions/index.js';
import { selectIsUserForgotPasswordLoading, selectPasswordResetEmailSent, selectorInitMeta } from '../../redux/selectors/index.js';
import * as scss from '../../scss/ForgotPassword.module.scss';
import { __, _$ } from '../../utils/i18n.jsx';
import { onEnter } from '../../utils/misc.js';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { LicoTextField, LicoButton, FormErrorPanel, P } from "../common/index.js";
import PreAuthLayout from '../layouts/PreAuthLayout.jsx';

import { requestUserPasswordToken } from './reducer.js';


const { greeting } = scss;

const ForgotPassword = ({
    loginEmail,
    fieldError,
    fieldTouched,
    setLoginEmail,
    setTouchedForgotPassword,
    requestUserPasswordToken,
    uriError
}) => {

    const userForgotPasswordLoading = useSelector( selectIsUserForgotPasswordLoading );
    const passwordResetEmailSent = useSelector( selectPasswordResetEmailSent );
    const meta = useSelector( selectorInitMeta );

    const submit = () => {

        // if they click the button without typing anything, the error hasn't been set
        if ( !loginEmail ) {
            setLoginEmail({ field: 'loginEmail', value: '' });
            setTouchedForgotPassword( 'loginEmail' );
        }
        else
            requestUserPasswordToken({ email: loginEmail });
    };

    return  <PreAuthLayout>
        <BannerLogo />

        {passwordResetEmailSent
            ? <>
                <P className={greeting}>{__( "Password Reset" )}</P>
                <p>{__( "A password email has been sent to you." )}</p>
            </>
            : <>
                <FormErrorPanel message={uriError[ uri.USER_REQUEST_PASSWORD_TOKEN ] ? __( "There was an error sending the reset password email" ) : undefined} />
                <p>{_$( "To reset your password, enter the email you use to sign in to {domain}.", meta )}</p>
                <LicoTextField
                    type="email"
                    name="loginEmail"
                    value={loginEmail}
                    label=""
                    required
                    autoFocus
                    onKeyDown={onEnter( submit )}
                    onChange={e => setLoginEmail({ field: e.target.name, value: e.target.value })}
                    helperText={fieldTouched && fieldError}
                    error={fieldTouched && !!fieldError}
                    onBlur={e => setTouchedForgotPassword( e.target.name )}
                    allowLastPass={true}
                />
                <LicoButton
                    data-ux={UX_GET_RESET_CODE}
                    style={{ marginTop: 12 }}
                    disabled={userForgotPasswordLoading || ( fieldTouched && !!fieldError ) }
                    onClick={submit}
                >
                    {__( "Send reset link" )}
                </LicoButton>
            </>}
    </PreAuthLayout>;
};

ForgotPassword.propTypes = {
    loginEmail:               PropTypes.string,
    fieldError:               PropTypes.string,
    fieldTouched:             PropTypes.boolean,
    setLoginEmail:            PropTypes.func.isRequired,
    setTouchedForgotPassword: PropTypes.func.isRequired,
    requestUserPasswordToken: PropTypes.func.isRequired,
    uriError:                 PropTypes.object
};

const mapStateToProps = state => ({
    loginEmail:         state.forgotPassword.loginEmail,
    fieldError:         state.forgotPassword.fieldError?.loginEmail,
    fieldTouched:       state.forgotPassword.fieldTouched?.loginEmail,
    uriError:           state.error.uriError
});

const mapDispatchToProps = {
    setLoginEmail,
    setTouchedForgotPassword,
    requestUserPasswordToken
};

export { ForgotPassword };

export default connect( mapStateToProps, mapDispatchToProps )( ForgotPassword );
