/*********************************************************************************************************************
 * @file Backdrop generic modal container
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Mar-2023
 *********************************************************************************************************************/
import React from 'react';

import PropTypes from 'prop-types';

import { invisibleBackdrop, backdrop } from '../../scss/Backdrop.module.scss';

const INVISIBLE_BACKDROP_ID = 'INVISIBLE_BACKDROP_ID';

const Backdrop = ({
    onClose,
    children
}) => (
    <>

        <div className={backdrop}/>

        <div id={INVISIBLE_BACKDROP_ID} className={invisibleBackdrop} onMouseDown={e => {

            const targetId = e.target.getAttribute( 'id' );
            if ( targetId === INVISIBLE_BACKDROP_ID ) 
                onClose();

        }}>
            { children }
        </div>
    </>
);

Backdrop.propTypes = {
    children:                        PropTypes.node,
    onClose:                         PropTypes.func.isRequired
};

export default Backdrop;
