import React from 'react';

import { FormControlLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root:      {},
    textField: {
        marginLeft: 0
    }
});

const useStylesLabel = makeStyles({
    label: {
        fontSize:    12,
        marginRight: 12
    }
});

export const LicoInlineTextField = ({ label, placeholder, className, inputClassName = "", ...rest }) => {
    const classes = useStyles( rest );
    const labelClasses = useStylesLabel( rest );

    const control = <TextField
        variant="outlined"
        placeholder={placeholder || label}
        inputProps={{
            className:       `slim  ${inputClassName}`, // See the override in the theme for this particular style
            "data-lpignore": true
        }}
        {...rest}
        className={className || classes.textField}
    />;

    return label
        ? <FormControlLabel 
            classes={labelClasses}
            labelPlacement="start"

            control={control}
            label={label} />
        : control;
};

LicoInlineTextField.propTypes = {
    inputClassName: PropTypes.string,
    label:          PropTypes.string,
    placeholder:    PropTypes.string,
    className:      PropTypes.string
};
