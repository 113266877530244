import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { engineerField, UserProfileMode } from '../../../constants.js';
import { UX_SETTINGS_INVITE_BUTTON } from '../../../ux-constants.js';
import { setEngineerField, setUserProfile } from '../../../redux/actions/index.js';
import { editable, fieldOutline, readonly, profileButton, inviteButton, resendButton } from '../../../scss/EngineerLine.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { LicoButton, LicoTableRow, LicoInlineTextField, LicoSwitch, LicoFab, LicoIcon } from "../../common/index.js";

import { getEngineerState, postInvitation } from './reducer.js';

/**
 * @typedef {EngineerLine & { props: EngineerLineProps }} EngineerLineAndProps
 */

/**
 * @this {EngineerLineAndProps}
 */
class EngineerLine extends PureComponent
{

    // activated means has successfully replied to an invite; doesn't mean they're active now
    isActivated = !!this.props.engineer.activationDate;

    // most fields editable by admin (if the engineer is activated (not active)) or the engineer in question
    isFieldDisabled = this.props.userId === this.props.engineer.userId
        ? false
        : ( this.props.adminLoggedIn ? !this.isActivated : true );


    // active field can only be changed by admin and for activated users only and not themselves
    isActiveSwitchDisabled = this.props.userId === this.props.engineer.userId
        ? true
        : ( this.props.adminLoggedIn ? !this.isActivated : true );

    isInviteButtonDisabled = this.props.adminLoggedIn
        ? this.isActivated || !this.props.engineer.loginEmail || this.props.confirmedInvitation
        : true;


    userProfileIcon = this.isActivated
        ? this.props.adminLoggedIn || this.props.userId === this.props.engineer.userId
            ? UserProfileMode.EDIT
            : UserProfileMode.VIEW
        : null;

    fieldError = field => this.props.rowErrors[ this.props.index ]
        ? this.props.rowErrors[ this.props.index ][ field ] || ''
        : '';

    textField = field => {
        const {
            engineer,
            index,
            setEngineerField
        } = this.props;

        const disabled = field === engineerField.EMAIL_ADDRESS || this.isFieldDisabled;

        return <LicoInlineTextField
            value={engineer[ field ] || ''}
            InputProps={{
                classes: {
                    root:           disabled ? readonly : editable,
                    notchedOutline: fieldOutline
                }
            }}
            fullWidth={true}
            onChange={e => setEngineerField({ field, index, value: e.target.value })}
            error={this.fieldError( field ).length > 0}
            helperText={this.fieldError( field )}
            disabled={disabled}
        />;
    };

    switchField = ( field, disabled ) =>  {
        const {
            engineer,
            index,
            setEngineerField
        } = this.props;

        return <LicoSwitch
            checked={engineer[ field ]}
            disabled={disabled}
            name={`${field}-${index}`}
            onChange={( e, value ) => setEngineerField({ field, index, value })}
        />;
    };

    render() {
        const {
            engineer,
            widths,
            postInvitation,
            setUserProfile,
            userId,
            adminLoggedIn
        } = this.props;

        const resendFlag = Number( engineer.count === undefined ? 1 :  engineer.count ); // TODO count is undefined after the patch request

        // admin field can only be changed by admin and for active users only and not themselves
        const isAdminFieldDisabled = userId === engineer.userId
            ? true
            : ( adminLoggedIn ?  !( resendFlag ) : true );

        return <LicoTableRow widths={widths}>
            {this.textField( engineerField.NAME )}
            {this.textField( engineerField.EMAIL_ADDRESS )}
            {this.textField( engineerField.TITLE )}
            {this.textField( engineerField.PHONE )}

            {this.switchField( engineerField.ADMIN, isAdminFieldDisabled )}
            {this.switchField( engineerField.ACTIVE, this.isActiveSwitchDisabled )}
            {this.switchField( engineerField.ELIGIBLE, this.isFieldDisabled )}

            <LicoButton
                onClick={() => postInvitation({
                    recipient: engineer.userId
                })}
                disabled={this.isInviteButtonDisabled}
                data-ux={UX_SETTINGS_INVITE_BUTTON}
                className={`${inviteButton} ${resendFlag && !this.isInviteButtonDisabled ? resendButton : ''}`}
            >
                {this.isActivated ? __( "Activated" ) : resendFlag ? __( "Resend" ) : __( "Invite" )}
            </LicoButton>
            {this.userProfileIcon ? <LicoFab className={profileButton} onClick={() => setUserProfile({
                user:               engineer,
                engineerProfile:    true,
                mode:               this.userProfileIcon
            })}><LicoIcon icon={this.userProfileIcon} /></LicoFab> : null}
        </LicoTableRow>;
    }
}

/**
 * @typedef {object} EngineerLineProps
 * @property {number} index
 * @property {any} engineer
 * @property {[]} widths
 * @property {function} setEngineerField
 * @property {function} postInvitation
 * @property {[]} rowErrors
 * @property {number} editTime
 * @property {string} userId
 * @property {boolean} adminLoggedIn
 */

EngineerLine.propTypes = {
    index:                  PropTypes.number.isRequired,
    engineer:               PropTypes.any.isRequired,
    widths:                 PropTypes.array.isRequired,
    setEngineerField:       PropTypes.func.isRequired,
    postInvitation:         PropTypes.func.isRequired,
    confirmedInvitation:    PropTypes.bool,
    rowErrors:              PropTypes.array.isRequired,
    userId:                 PropTypes.string.isRequired,
    adminLoggedIn:          PropTypes.bool.isRequired,
    setUserProfile:         PropTypes.func.isRequired
};

const mapStateToProps = state => {

    const { editTime, rowErrors } = getEngineerState( state );

    return {
        editTime,
        rowErrors,
        userId:         state.user.userId,
        adminLoggedIn:  state.user.admin || false
    };
};

const mapDispatchToProps = {
    setEngineerField,
    postInvitation,
    setUserProfile
};

export default connect( mapStateToProps, mapDispatchToProps )( EngineerLine );
export { EngineerLine };
