/********************************************************************************************************************
 * @file Actions for the pegboard store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setTimeLogs = createAction( 'TIME_LOG.PEGBOARD/SET' );
export const setAddTimeLog = createAction( 'TIME_LOG.ADD/SET' );
export const setAddLocalTimeLog = createAction( 'TIME_LOG.LOCAL_ADD/SET' );
export const setDeleteLocalTimeLog = createAction( 'TIME_LOG.LOCAL_DELETE/SET' );
export const setDeleteTimeLog = createAction( 'TIME_LOG.DELETE/SET' );
export const setPatchTimeLog = createAction( 'TIME_LOG.PATCH/SET' );
export const setFinishTimeLog = createAction( 'TIME_LOG.FINISH/SET' );
export const setUpdatePegboardTimers = createAction( 'TIME_LOG.UPDATE_TIMERS/SET' );
export const setElapsedMeter = createAction( 'TIME_LOG.ELAPSED/SET' );
export const adjustTimeLogTime = createAction( 'TIME_LOG.ADJUST_TIME/SET' );
export const setLocalJobTime = createAction( 'TIME_LOG.LOCAL_JOB_TIME/SET' );
export const setIntervalId = createAction( 'TIME_LOG.INTERVAL_ID/SET' );
export const setIncrementTimeEntered = createAction( 'TIME_LOG.INCREMENT_TIME_ENTERED/SET' );
