import React, {  useEffect, useState } from 'react';

import { integrationNames, StatusNames } from '@licoriceio/constants';
import { Autocomplete, Chip, Divider, List, TextField } from "@mui/material";
import Popper from "@mui/material/Popper";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { GET, nameDataFormat, searchPanelChipType, uri } from "../../constants.js";
import { setJobOffset } from "../../redux/actions/index.js";
import { getTypeAheadControlState, setTypeAheadFilter, TypeAheadControl } from "../../redux/reducers/typeAhead.js";
import { getLoadingStates } from "../../redux/reducerUtil.js";
import { selectNameData } from "../../redux/selectors/index.js";
import {
    caretDown,
    chipCss,
    closeIcon,
    filterLine,
    filterLineChips,
    filterWidgetColumn,
    lastWidgetColumn,
    optionAvatar,
    optionAvatarCompany,
    optionRender,
    spacer,
    topJobLine,
    widget,
    widgetColumn,
    chipsRow,
    selectedFilterCss
} from '../../scss/SearchPanel.module.scss';
import { autocompleteOptions, closePanelWrap, displayCenter, hidePanel, roundInputBox, roundInputRoot, searchAutoComplete } from '../../scss/SearchTabContent.module.scss';
import { getAvatarFromUserOrCompany } from "../../utils/common-types.js";
import { __ } from '../../utils/i18n.jsx';
import { LicoAvatar, LicoFab, LicoIcon, LicoPopupMenuItem } from '../common';
import { getTeams } from "../settings/team/reducer.js";

import JobItem from "./JobItem.jsx";
import JobPager from './JobPager';
import JobStatusPopover from "./JobStatusPopover.jsx";
import { clearFilterString, filterStringChanged, getJobsForTab, jobStatusMap, selectJobState, updateFilterList } from "./reducer.js";
import popOverUseStyles from "./SearchPanelPopOverStyles";


const SearchTabContent = ({
    filteredJobIds,
    filterString,
    filterStringChanged,
    jobOffset,
    numberVisibleJobs,
    setJobOffset,
    options,
    setTypeAheadFilter,
    getJobsForTab,
    id,
    filterList,
    updateFilterList,
    jobStatusOptions,
    user,
    clearFilterString,
    getTeams,
    nameToId,
    total
}) => {
    const [ selectedFilter, setSelectedFilter ] = useState( filterList );
    const [ chips, setChips ] = useState([]);
    const [ anchorEl, setAnchorEl ] = useState( null );
    const isMenuOpen = Boolean( anchorEl );
    useEffect( () => {
        getTeams();
    }, []);

    const resetElAnchor = () => {
        setAnchorEl( null );
    };
    const classes = popOverUseStyles();

    const handleDeleteChip =  chipToDelete => {
        setSelectedFilter( prevState => {
            return prevState.filter( item => {
                return (
                    item !== chipToDelete &&
                    item.name !== chipToDelete &&
                    item.label !== chipToDelete &&
                    item.icon !== ( chipToDelete?.props?.icon || null ) &&
                    ( item.icon !== 'hand' || chipToDelete !== __( "Me" ) )
                );
            });
        });
    };


    const handleStatusClick = ( jobStatus ) => {
        if ( !selectedFilter.some( ( chip ) => chip.type === searchPanelChipType.STATUS && chip?.label === jobStatus?.label ) )
            setSelectedFilter( ( prevChips ) => [ ...prevChips, { ...jobStatus, type: searchPanelChipType.STATUS } ]);
        resetElAnchor();
    };
    const handleIconClick = ( icon ) => {
        if ( isSelected( icon ) ) {
            const chip = selectedFilter.find( item => item.icon === icon );
            handleDeleteChip( chip );
        }
        else {
            if ( icon === 'hand' ) {
                setSelectedFilter(  prevChips  => [
                    ...prevChips,
                    {   icon, userId: user.userId, label: 'Assigned to me', role: user.role,  isFilterButton: true  }
                ]);
            }
            else {
                const status = findStatus( icon );
                setSelectedFilter( prevChips  => [
                    ...prevChips,
                    { type: searchPanelChipType.STATUS,  icon, label: status?.label, id: status?.id, slashed: status?.slashed, isFilterButton: true }
                ]);
            }
        }
    };


    const findStatus =  icon  => {
        switch ( icon ) {
            case 'sparkles': {
                return { id: nameToId[ StatusNames.REOPENED_JOB ], label: StatusNames.REOPENED_JOB };
            }
            case 'calendar':
                return { slashed: true, id: nameToId[ StatusNames.NOT_SCHEDULED ], label: StatusNames.NOT_SCHEDULED  };
        }
    };
    const onAutocompleteChange = ( e, newValue ) => {
        if ( newValue === null ) 
            return;
        


        if ( !selectedFilter.some( ( chip ) => chip === newValue ) )
            setSelectedFilter( newValue );

    };


    useEffect( () => {
        if ( selectedFilter.length > 0 ) {
            const filteredChips = selectedFilter.filter( chip => !chip.isFilterButton );
            const selectedChips = filteredChips?.map( chip => {
                return typeof chip === searchPanelChipType.STRING
                    ? chip
                    : chip?.name || chip?.label;
            }
            );
            setChips( selectedChips );
        }
        else
            setChips([]);
        updateFilterList( selectedFilter, id );
        getJobsForTab( id );

    }, [ selectedFilter, id, updateFilterList, getJobsForTab, numberVisibleJobs ]);

    const customPopperStyle = {
        width: '300px'
    };

    const setJobOffsetFotTab = ( offset ) => {
        setJobOffset({ id, offset });
        getJobsForTab( id );
    };

    const processOptions = ( arrays ) => {
        const expandedArray = [];
        // Process the first array (people) and add avatar property
        if ( arrays[ 0 ] && arrays[ 0 ].length > 0 ) {
            const processedPeople = arrays[ 0 ].map( ( item ) => {
                return { ...item, avatar: getAvatarFromUserOrCompany( item ), type: searchPanelChipType.USER };
            });
            expandedArray.push( ...processedPeople );
        }
        // Process the second array (companies) and add company property
        if ( arrays[ 1 ] && arrays[ 1 ].length > 0 ) {
            const processedCompanies = arrays[ 1 ].map( ( item ) => {
                return { ...item, type: searchPanelChipType.COMPANY };
            });
            expandedArray.push( ...processedCompanies );
        }
        if ( arrays[ 2 ] && arrays[ 2 ].length > 0 ) {
            const processedTeams = arrays[ 2 ].map( ( item ) => {
                return { ...item, type: searchPanelChipType.TEAM, label: item?.tagName };
            });
            expandedArray.push( ...processedTeams );
        }
        // Push the rest of the array elements (starting from index 1)
        expandedArray.push( ...arrays.slice( 3 ).flat() );
        return expandedArray;
    };

    let prevOptionType = null; // this is required to render the divider between different types of options

    const renderOptions = ( props, option ) => {
        const result = option.name || option.label;
        const id = option.userId || option.companyId;
        let renderedOption = (
            <div className={optionRender} key={id} {...props}>
                {
                    option.type === searchPanelChipType.COMPANY ? (
                        <LicoIcon icon={'lightBuilding'} className={`${optionAvatarCompany} small`} />
                    ) : option.type === searchPanelChipType.TEAM ? (
                        <LicoIcon icon={'users'} className={`${optionAvatarCompany} small`} />
                    ) : (
                        <LicoAvatar
                            avatar={option.avatar}
                            gravatarEmail={option.item && option.item.preferences?.gravatarEmail}
                            size="small"
                            className={`${optionAvatar} small`}
                        />
                    )
                }

                <div className={autocompleteOptions}>{result}</div>
            </div>
        );
        if ( prevOptionType !== null && option.type !== prevOptionType ) {
            renderedOption = (
                <>
                    <Divider/>
                    {renderedOption}
                </>
            );
        }
        prevOptionType = option.type; // Update the previous option's type
        return renderedOption;
    };


    const showDropdown = filterString?.length >= 3 && options.some( option => option.length > 0 );
    const filteredJobStatusOptions = jobStatusOptions.filter(
        jobStatus => jobStatus.label !== StatusNames.NOT_SCHEDULED && jobStatus.label !== StatusNames.REOPENED_JOB
    );

    const onInputChange = ( event, value, reason ) => {
        if ( reason === 'reset' ) {
            clearFilterString( value, id );
            return;
        }
        filterStringChanged( value, id );
        filterString = value;
        setTypeAheadFilter({ name: TypeAheadControl.people, filter: value.length >= 1 ? value : '' });
        setTypeAheadFilter({ name: TypeAheadControl.searchPanelCompany, filter: value.length >= 3 ? value : '' });
    };

    const selectedIcons = selectedFilter.filter( item => !!item.icon ).map( item => item.icon );

    const isSelected = ( icon ) => selectedIcons.includes( icon );

    const renderInout = ( params ) => (
        <TextField
            {...params}
            variant="outlined"
            placeholder="Filter"
            className={roundInputBox}
            InputProps={{
                ...params.InputProps,
                className: roundInputBox
            }}
        />
    );

    return (
        <div>
            <div className={topJobLine}>
                <span className={filterLine}>
                    <div className={filterLineChips}>
                        <div className={displayCenter}>
                            <LicoFab
                                color="inherit"
                                licoVariant="tiny"
                                onClick={() => getJobsForTab( id, numberVisibleJobs )}
                                className={`${closePanelWrap} grey-background`}
                            >
                                <LicoIcon icon="sync" className={hidePanel}/>
                            </LicoFab>
                        </div>
                        <div className={widget}>
                            <div className={filterWidgetColumn}>
                                <Autocomplete
                                    className={searchAutoComplete}
                                    freeSolo
                                    options={processOptions( options )}
                                    multiple
                                    disableClearable
                                    classes={{
                                        root:      roundInputRoot,
                                        inputRoot: roundInputRoot
                                    }}
                                    getOptionLabel={( option ) => option.name || option.label}
                                    filterSelectedOptions
                                    onChange={onAutocompleteChange}
                                    onInputChange={onInputChange}
                                    value={selectedFilter}
                                    renderOption={( props, option ) => renderOptions( props, option )}
                                    renderInput={renderInout}
                                    renderTags={() => []}
                                    PopperComponent={( props ) => (
                                        showDropdown &&
                                        <Popper {...props} style={customPopperStyle} placement="bottom-start"/>
                                    )}
                                />

                            </div>

                            <div className={`${widgetColumn} ${isSelected( "hand" ) ? selectedFilterCss : ""}`} onClick={() => handleIconClick( "hand" )}>
                                <span>{__( "Me" )}</span>
                            </div>
                            <div className={`${widgetColumn} ${isSelected( "sparkles" ) ? selectedFilterCss : ""}`} onClick={() => handleIconClick( "sparkles" )}>
                                <LicoIcon icon="sparkles" tooltip={StatusNames.REOPENED_JOB} tooltipPlacement={"top"} />
                            </div>
                            <div className={`${widgetColumn} ${isSelected( "calendar" )  ? selectedFilterCss : ""}`} onClick={() => handleIconClick( "calendar" )}>
                                <LicoIcon icon="calendar" slashed={true} tooltip={StatusNames.NOT_SCHEDULED} tooltipPlacement={"top"} />
                            </div>


                            <div className={lastWidgetColumn} onClick={e => setAnchorEl( e.currentTarget )}>
                                <LicoIcon icon="caretDown" className={caretDown}/>
                            </div>
                            <JobStatusPopover open={isMenuOpen} anchorEl={anchorEl} onClose={() => resetElAnchor()} classes={classes} jobStatus={jobStatusOptions}
                                map={filteredJobStatusOptions.map( jobStatus => (

                                    <LicoPopupMenuItem
                                        onClick={() => handleStatusClick( jobStatus )}
                                        icon= {( jobStatusMap[ jobStatus.label ].icon )}
                                        slashed = {jobStatusMap[ jobStatus.label ].slashed}
                                        key={jobStatus.id}
                                    >  {jobStatus.label} </LicoPopupMenuItem>

                                ) )}/>

                        </div>
                    </div>
                    <div className={chipsRow}>
                        {chips.map( ( chip, index ) => {
                            return (
                                <Chip
                                    key={index}
                                    label={chip}
                                    deleteIcon={<LicoIcon icon="close" className={closeIcon}/>}
                                    onDelete={() => handleDeleteChip( chip )}
                                    className={`RhsChip ${chipCss}`}
                                />
                            );
                        })}
                    </div>
                </span>
            </div>
            <div id="search-container">
                {(
                    <List>
                        {filteredJobIds
                            .map( ( t, index ) => (
                                <JobItem
                                    job={t}
                                    key={index}
                                    tabId={id}
                                />
                            ) )}
                    </List>
                )}
            </div>

            <div className={spacer}/>
            {total > numberVisibleJobs && (
                <JobPager
                    label={__( "Jobs" )}
                    start={jobOffset}
                    pageSize={numberVisibleJobs}
                    total={total}
                    setOffset={setJobOffsetFotTab}
                />
            )}
        </div>
    );
};
SearchTabContent.propTypes = {
    filteredJobIds:         PropTypes.array,
    filterString:           PropTypes.string.isRequired,
    filterStringChanged:    PropTypes.func.isRequired,
    clearFilterString:      PropTypes.func.isRequired,
    jobOffset:              PropTypes.number,
    numberVisibleJobs:      PropTypes.number,
    setJobOffset:           PropTypes.func,
    getJobsForTab:          PropTypes.func,
    options:                PropTypes.array.isRequired,
    setTypeAheadFilter:     PropTypes.func.isRequired,
    id:                     PropTypes.string.isRequired,
    filterList:             PropTypes.array.isRequired,
    updateFilterList:       PropTypes.func.isRequired,
    jobStatusOptions:       PropTypes.array.isRequired,
    user:                   PropTypes.object.isRequired,
    getTeams:               PropTypes.func.isRequired,
    teams:                  PropTypes.array,
    nameToId:               PropTypes.object.isRequired,
    total:                  PropTypes.string
};

const mapStateToProps = ( state, ownProps ) => {
    const [ loadingJobs ] = getLoadingStates( state, GET + uri.TICKETS );
    const { data: people } = getTypeAheadControlState( state, TypeAheadControl.people );
    const { options: companies } = getTypeAheadControlState( state, TypeAheadControl.searchPanelCompany );
    const teams          =      state.team.teams;
    const {
        filteredJobIds,
        convertedJobMap,
        filterString,
        jobOffset,
        numberVisibleJobs,
        filterList,
        total
    } = selectJobState( state, ownProps.id );
    return {
        filteredJobIds,
        convertedJobMap,
        filterString,
        jobOffset,
        numberVisibleJobs,
        filterList,
        total,
        loadingJobs:            !!loadingJobs,
        options:                [ ( people || []), ( companies || []), ( teams || []) ],
        jobStatusOptions:       selectNameData( state, integrationNames.JOB_STATUS, nameDataFormat.OPTIONS ),
        nameToId:               state.jobcard.jobStatus.nameToId,
        user:                   state.user
    };
};

const mapDispatchToProps = {
    setJobOffset,
    filterStringChanged,
    setTypeAheadFilter,
    getJobsForTab,
    updateFilterList,
    clearFilterString,
    getTeams
};

export default connect( mapStateToProps, mapDispatchToProps )( SearchTabContent );
