/** ******************************************************************************************************************
 * @file Utilities for time and date things.
 * @author Julian Jensen <julian@licorice.io>
 * @since 1.0.0
 * @date 15-Aug-2020
 *********************************************************************************************************************/
import kindOf from 'kind-of';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import duration from 'dayjs/plugin/duration.js';
import { inRange, isInt } from '../helpers/index.js';

dayjs.extend( isoWeek );
dayjs.extend( relativeTime );
dayjs.extend( duration );

const defaultLocale = new Intl.NumberFormat().resolvedOptions().locale;
const toDate = dt => kindOf( dt ) === 'string' ? new Date( dt ) : dt;
let currentLocale = defaultLocale;

const dateFormat = {
    month: 'short',
    day:   '2-digit',
    year:  'numeric'
};

const timeFormat = {
    hour:   '2-digit',
    minute: '2-digit',
    hour12: false
};

const normalDate = {
    year:  'numeric',
    month: 'short',
    day:   'numeric'
};

const normalTime = {
    hour:   'numeric',
    minute: 'numeric'
};

const shortDateFormatDMY = new Intl.DateTimeFormat( currentLocale, dateFormat );
const shortTimeFormatHM = new Intl.DateTimeFormat( currentLocale, timeFormat );
const localeDate = new Intl.DateTimeFormat( currentLocale, normalDate );
const localeTime = new Intl.DateTimeFormat( currentLocale, normalTime );

/**
 * The `formatRange()` function is not implemented in Firefox yet.
 * @todo No i18n here using `'to'`
 *
 * @param {Date} start
 * @param {Date} end
 * @return {string}
 */
const formatRangeHM = ( start, end ) => `${shortTimeFormatHM.format( start )} to ${shortTimeFormatHM.format( end )}`;

const shortDateDMY = ( dt = Date.now() ) =>
    shortDateFormatDMY.formatToParts( toDate( dt ) )
        .reduce( ( str, { type, value }) => str.replace( `%${type}%`, value ), '%day%-%month%-%year%' );

const shortTimeHM = ( dt = Date.now() ) => shortTimeFormatHM.format( toDate( dt ) );
const rangeShortTimeHM = ( start, end ) => formatRangeHM( toDate( start ), toDate( end ) );
const normalDateTime = ( dt = Date.now() ) => localeDate.format( dt ) + ' ' + localeTime.format( dt );

const setLocale = locale => currentLocale = locale;
const getLocale = () => currentLocale || 'en-US';

function snapMinutes( dt, interval )
{
    /** @type {dayjs.Dayjs} */
    const converted = dayjs( dt );
    const minutes = converted.minute();

    const fractional = minutes % interval;

    if ( fractional === 0 ) return dt;

    const finish = converted.add( interval - fractional, 'minute' ).second( 0 ).millisecond( 0 );

    return kindOf( dt ) === 'string' ? finish.toISOString : dt instanceof Date ? finish.toDate() : finish;
}

const ensureInterval = ( start, finish, interval ) => {
    const end = dayjs( finish );
    if ( !dayjs( start ).isSame( end, 'minute' ) ) return finish;

    const adjusted = end.add( interval, 'minute' );

    return kindOf( finish ) === 'string' ? adjusted.toISOString : finish instanceof Date ? adjusted.toDate() : adjusted;
};

const ensureIntervalFit = ( start, finish, totalInterval, interval ) => {
    const minuteDiff = dayjs( finish ).diff( start, 'minute' );
    let intervalMinutes = ~~( totalInterval / 60000 );
    intervalMinutes = ~~( ( intervalMinutes + ( interval - 1 ) ) / interval ) * interval;

    return  intervalMinutes <= minuteDiff ? intervalMinutes * 60000 : ( minuteDiff * 60000 );
};

function numMinutesToString( nMinutes, ampm )
{
    if ( nMinutes == null || Number.isNaN( nMinutes ) || nMinutes < 0 )
        return "";
    else {
        // see comment in numSecondsToString
        const nHours = Math.floor( nMinutes / 60 );
        const nFinalMinutes = String( Math.floor( nMinutes % 60 ) ).padStart( 2, '0' );
        return ampm
            ? `${nHours > 12 ? nHours - 12 : nHours}:${nFinalMinutes}${nHours < 12 ? 'am' : 'pm'}`
            : `${nHours}:${nFinalMinutes}`;
    }
}

function numSecondsToString( nSeconds, showSeconds = true )
{
    if ( nSeconds == null || nSeconds < 0 )
        return "";
    else {

        // can't use Date for this; we can add any number of seconds/minutes/hours etc
        // but the formatter for the time component will only return the "remainder"
        // after the whole number of days. We want to have all overflow in the hours value.

        const nHours = String( Math.floor( nSeconds / 3600 ) ).padStart( 2, '0' );
        nSeconds %= 3600;  // Used modulo to get the remaining seconds after subtracting hours
        const nMinutes = String( Math.floor( nSeconds / 60 ) ).padStart( 2, '0' );
        const nFinalSeconds = String( Math.floor( nSeconds % 60 ) ).padStart( 2, '0' );
        return `${nHours}:${nMinutes}${showSeconds ? ':' + nFinalSeconds : ''}`;
    }
}

const rxNumbersOnly = /^(\d+)(?:m|min|mins)?$/;
const rxHasColon = /^(\d\d?)(?:h|hr|hrs|hour|hours|:)(\d\d)?$/;
const rxHasSecondsColon = /^\d\d?:\d\d:\d\d$/;
const rxHasDecimal = /^(\d+)*\.(\d+)*$/;

function stringToNumSeconds( str ) {

    let result = stringToNumMinutes( str );
    if ( result >= 0 ) 
        result *= 60;
    else {

        result = -1;

        // eg. 1:30:25
        if ( rxHasSecondsColon.test( str ) )
        {
            const [ hours, minutes, seconds ] = str.split( ':' );
            return ( Number( hours ) * 3600 ) + Number( minutes ) * 60 + Number( seconds );
        }
    }

    // Default
    return result;
}


function stringToNumMinutes( str, cap = 600 ) {

    let groups;

    // remove all whitespace
    str = str.replace( /\s+/g, '' );

    let result = -1;
    
    // eg. 90 = 90minutes
    groups = rxNumbersOnly.exec( str );
    if ( groups ) {
        const [ , minutes ] = groups;
        result = Number( minutes );
    }
    else {

        // eg. 1.5 = 90 minutes. Note that '.' is a valid pattern and resolves to 0.
        groups = rxHasDecimal.exec( str );
        if ( groups ) {
            const [ , hours = 0, fraction = 0 ] = groups;
            result = ( Number( hours ) + Number( '0.' + fraction ) ) * 60;
        }
        else {

            // eg. 1:30
            groups = rxHasColon.exec( str );
            if ( groups ) {
                let [ , hours, minutes = 0 ] = groups;
                hours = Number( hours );
                minutes = Number( minutes );
                if ( minutes < 60 )
                    result = ( Number( hours ) * 60 ) + Number( minutes );
            }
        }
    }

    // cap
    if ( cap && result > cap )
        result = cap;

    // Default
    return result;
}

const timestampAge = timestamp => dayjs( timestamp ).fromNow();

/**
 * find the gaps between a series of time definitions
 * @param {array} series - list of objects with startTime and endTime, ordered by startTime     
 * @returns {array}
 */
const getTimeSeriesGaps = series => {

    if ( !series || series.length < 2 )
        return [];

    // get a list of gaps by taking the end of the first chunk with the start of the next and so on
    let finish = series[ 0 ].endTime;
    return series.slice( 1 )
        .map( time => {
            const oldFinish = finish;
            finish = time.endTime;
            return { startTime: oldFinish, endTime: time.startTime };
        });

};

const humanDuration = durationMsecs => { 
    const duration = dayjs.duration( durationMsecs );
    const roundedMinutes = Math.ceil( duration.asMinutes() / 5 ) * 5;
    return roundedMinutes < 60 
        ? `${roundedMinutes} minutes`
        : roundedMinutes > 60 
            ? dayjs.duration( roundedMinutes, 'minutes' ).format( 'H:mm' )
            : 'an hour';
};

const getCommonDateParts = d => ({
    date:  String( d.getUTCDate() ).padStart( 2, '0' ),
    month: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ][ d.getUTCMonth() ],
    year:  d.getUTCFullYear()
});

/**
 * @param {Date} [d]
 * @return {string}
 */
const getShortDate = ( d = new Date() ) => {
    const { date, month, year } = getCommonDateParts( d );

    return `${date}-${month}-${year}`;
};

/**
 * @param {Date} [d]
 * @return {string}
 */
const getShortDateTime = ( d = new Date() ) => {
    const { date, month, year } = getCommonDateParts( d );
    const hours   = d.getUTCHours();
    const minutes = String( d.getUTCMinutes() ).padStart( 2, '0' );

    return `${date}-${month}-${year} ${hours}:${minutes}`;
};

/**
 * @function
 * @param {number} hour
 * @return {boolean}
 */
const validHour = inRange( 0, 23 );

/**
 * @function
 * @param {number} minute
 * @return {boolean}
 */
const validMinute = inRange( 0, 59 );

/**
 * @param {number} h
 * @param {number} m
 * @return {number|null}
 */
const validTime = ( h, m ) => isInt( h ) && validHour( h ) && isInt( m ) && validMinute( m ) ? ( m | 0 ) : null;

export {
    validTime,
    validHour,
    validMinute,
    getShortDate,
    getShortDateTime,
    numMinutesToString,
    numSecondsToString,
    stringToNumMinutes,
    stringToNumSeconds,
    ensureIntervalFit,
    ensureInterval,
    snapMinutes,
    shortDateDMY,
    shortTimeHM,
    rangeShortTimeHM,
    setLocale,
    getLocale,
    toDate,
    normalDateTime,
    timestampAge,
    getTimeSeriesGaps,
    humanDuration
};
