/********************************************************************************************************************
 * @file Actions for the login store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setLoginError = createAction( 'LOGIN.ERROR/SET' );
export const setQrCode = createAction( 'LOGIN.MFA.QRCODE/SET' );
export const setQrCodeUrl = createAction( 'LOGIN.MFA.QRCODE.URL/SET' );
export const setLoginMfaTransition = createAction( 'LOGIN.MFA.TRANSITION/SET' );
export const setLoginVerified = createAction( 'LOGIN.PWD.VERIFIED/SET' );
export const setLoginMfaVerified = createAction( 'LOGIN.MFA.VERIFIED/SET' );
export const setFormValueLogin = createAction( 'LOGIN.FORM_VALUE/SET' );
export const setTouchedLogin = createAction( 'LOGIN.TOUCHED/SET' );
export const setLoginFields = createAction( 'LOGIN.GENERIC/SET' );
export const setFieldError = createAction( 'LOGIN.FIELD_ERROR/SET' );
export const setLogout = createAction( 'LOGIN.LOGOUT/SET' );
export const setSettingsLogin = createAction( 'LOGIN.MFA.SETTINGS/SET' );
export const setOrganisationSettings = createAction( 'LOGIN.MFA.GET_ORG_SETTINGS' );
export const setMfaBackLogin = createAction( 'LOGIN.MFA.BACK/SET' );
export const setCreds = createAction( 'LOGIN.CREDS/SET' );
