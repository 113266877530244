/*********************************************************************************************************************
 * @file WorkLog component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { useEffect, useRef, useState } from 'react';

import { NoteType } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setChatTabIndex, setNotesFullView } from "../../redux/actions/index.js";
import { selectJobNotes } from '../../redux/selectors/index.js';
import { workLog, workLogList, fullView, workLogFullView, workLogListMax } from '../../scss/WorkLog.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { onEsc } from '../../utils/misc.js';
import { UX_JOBCARD_CLIENT_CHAT_TAB, UX_JOBCARD_CLIENT_CHAT_LIST, UX_JOBCARD_ENGINEER_CHAT_TAB, UX_JOBCARD_ENGINEER_CHAT_LIST } from '../../ux-constants.js';
import { closeEitherCard } from '../calendar/shared.js';
import LicoTabs from '../common/LicoTabs.jsx';

import NoteEntryField from './NoteEntryField.jsx';
import WorkLogItem from './WorkLogItem.jsx';

const NoteList = ({ notes, noteType, notesFullView }) => {

    const noteListRef = useRef( null );
    const [ first, setFirst ] = useState( true );

    useEffect( () => {
        if ( notes.length > 0 ) {
            noteListRef.current.scrollBy({ top: 10000, behavior: first ? 'auto' : 'smooth' });
            if ( first )
                setFirst( false );
        }

    // first not included in dependencies because otherwise changing it triggers a new scroll
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ notes.length, noteListRef, first, setFirst ]);

    return (
        <div id={"noteList" + noteType} 
            className={notesFullView ? workLogListMax : workLogList }
            ref={noteListRef} 
            data-ux={noteType === NoteType.message ? UX_JOBCARD_CLIENT_CHAT_LIST : UX_JOBCARD_ENGINEER_CHAT_LIST}
        >
            {notes.map( ( note, i ) => <WorkLogItem noteType={noteType} key={i} note={note} previousNote={i > 0 ? notes[ i - 1 ] : null}/> )}
        </div>
    );
};

NoteList.propTypes = {
    notes:              PropTypes.array.isRequired,
    noteType:           PropTypes.string.isRequired,
    notesFullView:      PropTypes.bool
};

const NotePanel = ({ noteType, notes, job, autoFocus, disabled, readOnlyCompany, notesFullView, completed }) => (
    <div className={notesFullView ? fullView : ''}>
        <NoteList notes={notes.filter( n => n.noteType === noteType )} noteType={noteType} notesFullView = {notesFullView} />
        <NoteEntryField
            job={job}
            noteType={noteType}
            autoFocus={autoFocus}
            disabled={disabled}
            readOnlyCompany={readOnlyCompany}
            completed = {completed}
        />
    </div>
);

NotePanel.propTypes = {
    notes:              PropTypes.array.isRequired,
    noteType:           PropTypes.string.isRequired,
    job:                PropTypes.object.isRequired,
    autoFocus:          PropTypes.bool.isRequired,
    disabled:           PropTypes.bool.isRequired,
    readOnlyCompany:    PropTypes.bool.isRequired,
    notesFullView:      PropTypes.bool,
    completed:          PropTypes.bool.isRequired
};

const WorkLog = props => {
    const {
        job,
        notes,
        closeEitherCard,
        readOnlyCompany,
        notesFullView,
        setNotesFullView,
        chatTabIndex,
        setChatTabIndex,
        completed
    } = props;

    const notesDisabled = job.newItemFlag || completed;

    const tabContent = [
        {
            label:      __( "Client Chat" ),
            dataName:   UX_JOBCARD_CLIENT_CHAT_TAB,
            noteType:   NoteType.message
        },
        {
            label:      __( "Engineer Chat" ),
            dataName:   UX_JOBCARD_ENGINEER_CHAT_TAB,
            noteType:   NoteType.worklog
        }
    ];

    // enable persistentContent below to get persistent scroll position on tab change (but worklog tab will
    // not be scrolled down initially because ???)
    return (
        <div className={`${workLog} ${notesFullView ? workLogFullView : ''}`}>

            <LicoTabs
                persistentContent={false}
                onKeyDown={onEsc( closeEitherCard )}
                tabIndex={chatTabIndex}
                setTabIndex={setChatTabIndex}
                content={tabContent.map( tab => ({
                    label:      tab.label,
                    dataName:   tab.dataName,
                    content:    (
                        <NotePanel
                            noteType={tab.noteType}
                            notes={notes}
                            job={job}
                            autoFocus={true}
                            disabled={notesDisabled}
                            readOnlyCompany={readOnlyCompany}
                            notesFullView={notesFullView}
                            completed={completed}
                        />
                    )
                }) ) }
                contentVisible={notesFullView}
                showResizeIcon={true}
                setContentVisible={setNotesFullView}
                className = "WorkLogTab"/>

        </div>
    );
};

WorkLog.propTypes = {
    job:                PropTypes.object.isRequired,
    notes:              PropTypes.array.isRequired,
    closeEitherCard:    PropTypes.func.isRequired,
    readOnlyCompany:    PropTypes.bool,
    notesFullView:      PropTypes.bool,
    setNotesFullView:   PropTypes.func.isRequired,
    chatTabIndex:       PropTypes.number.isRequired,
    setChatTabIndex:    PropTypes.func.isRequired,
    completed:          PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    notes:              selectJobNotes( state ),
    notesFullView:      state.jobcard.notesFullView,
    setNotesFullView:   state.jobcard.setNotesFullView,
    chatTabIndex:       state.jobcard.chatTabIndex
});

const mapDispatchToProps = {
    closeEitherCard,
    setNotesFullView,
    setChatTabIndex
};

export default connect( mapStateToProps, mapDispatchToProps )( WorkLog );
export { WorkLog };
