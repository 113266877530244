import React from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setSendAppointment } from '../../redux/actions/index.js';
import { root, bold, message, spacer, appointment, apptCell, apptContent, apptHeading, sendToCalendar } from '../../scss/ConfirmationPage.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { LicoSwitch } from "../common/index.js";
import { defaultContact } from '../settings/engineer/reducer.js';

import BottomRow from './BottomRow.jsx';
import { progressStage } from './reducer.js';
import TopRow    from './TopRow.jsx';


const ConfirmationPage = ({ title, description, job, engineerName, startTime, emailAddress, sendAppointment, setSendAppointment }) => {

    const appointmentTime = dayjs( startTime );

    return <div className={root}>
        <TopRow job={job} />

        <div className={message}>
            {__( "You have selected an appointment with " )}
            <span className={bold}>{engineerName}</span>
            {__( " to see you on " )}
            <span className={bold}>{appointmentTime.format( "dddd D-MMM" )}</span>
            {__( " at " )}
            <span className={bold}>{appointmentTime.format( "h:mma" )}.</span>
            <br/>
            {__( "Press Book to book the appointment" )}
        </div>

        <div className={appointment}>
            <div className={`${apptCell} ${apptHeading}`}>
                {appointmentTime.format( 'D-MMM' )}
            </div>
            <div className={`${apptCell} ${apptHeading} ${apptContent}`}>
                {title}
            </div>
            <div className={`${apptCell}`}>
                {appointmentTime.format( 'dddd h:mma' )}
            </div>
            <div className={`${apptCell} ${apptContent}`}>
                {description}
            </div>
        </div>

        <div className={sendToCalendar}>
            <LicoSwitch name="sendToCalendar" checked={sendAppointment} onChange={( e, checked ) => setSendAppointment( checked )} />
            {__( "Send this appointment to your calendar at " )}<span className={bold}>{emailAddress}?</span>
        </div>

        <div className={spacer}>&nbsp;</div>

        <BottomRow previousLabel={__( "< Back" )} previousStage={progressStage.CALENDAR}
            nextLabel={__( "Book" )} nextStage={progressStage.SUCCESS} />
    </div>;
};

ConfirmationPage.propTypes = {
    title:              PropTypes.string.isRequired,
    description:        PropTypes.string.isRequired,
    job:                PropTypes.object.isRequired,
    engineerName:       PropTypes.string,
    startTime:          PropTypes.string,
    emailAddress:       PropTypes.string,
    sendAppointment:    PropTypes.bool,
    setSendAppointment: PropTypes.func.isRequired
};

const mapStateToProps = state => {

    const { scheduling: { job, engineerName, startTime, sendAppointment, user } } = state;
    const { title, description, priority } = job;

    return {
        job,
        title,
        description,
        priority,
        engineerName,
        startTime,
        sendAppointment,
        emailAddress:       defaultContact( user, 'email' ) || user.loginEmail
    };
};

const mapDispatchToProps = { setSendAppointment };

export default connect( mapStateToProps, mapDispatchToProps )( ConfirmationPage );
export { ConfirmationPage };
