/********************************************************************************************************************
 * @file constants.js - shared calendar items
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21/12/2021
 *********************************************************************************************************************/
import { AppointmentState } from '@licoriceio/constants';
import dayjs from 'dayjs';

import { uri, POST } from '../../constants.js';
import { setAddTimeLog } from '../../redux/actions/index.js';
import { genericRequest } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { getJobCardEngineers, getJobCardChecklist } from '../jobcard/requests.js';

import { closeCalendarEventThunk, setExistingJobThunk, getJobCardAppointments, updateMeter } from './sharedThunks.js';

const addTimeLogOnPegboard = ( jobId ) => genericRequest({}, abstractedCreateAuthRequest( POST, uri.TIME_LOG_ADD_ON_PB ), [ setAddTimeLog, [ updateMeter, { jobId } ] ], [ jobId ]);
const addPausedTimeLogOnPegboard = ( jobId ) => genericRequest({}, abstractedCreateAuthRequest( POST, uri.TIME_LOG_ADD_ON_PB_PAUSED ), [ setAddTimeLog ], [ jobId ]);

const closeEitherCard = () => async ( dispatch ) => {
    dispatch( setExistingJobThunk( null ) );
    dispatch( closeCalendarEventThunk() );
};

// Job thunks

const _currentViewSettings = {};
const getCurrentViewSettings = () => ({ ..._currentViewSettings });
const mergeToCurrentViewSettings = obj => Object.assign( _currentViewSettings, obj );

/**
 * Given the current state wrt both events and view dates, work out if any events lie outside
 * of the user's working hours.
 * @param {object} draft - current state, probably already changed by a reducer
 * @param {string} userId
 * @param {{ [ s: string ]: object }} eventMap
 */
const findOutOfHoursEvents = ( draft, userId, eventMap ) => {
    const calendar = draft.userMap[ userId ];
    let { viewStart, viewEnd } = getCurrentViewSettings();
    let { minStartTime, maxEndTime } = calendar;

    viewStart = dayjs( viewStart );
    viewEnd = dayjs( viewEnd );

    // filter timed events on visible range
    const filterEvents = event => {

        // STT appts can't be early or late, and we don't care about rescheduled events
        if ( event.endDate == null || event.state === AppointmentState.rescheduled )
            return false;

        // make sure appt is in date range before checking time
        const start = dayjs( event.startDate );
        return start.isAfter( viewStart ) && start.isBefore( viewEnd );
    };

    // increment early & late counts for appts or calendar events
    let earlyCount = 0;
    let lateCount = 0;
    const countOutOfRangeEvents = event => {

        // we have the user's start and end times in minutes already so get those for the appt
        // and do the tests
        const start = dayjs( event.startDate );
        const end = dayjs( event.endDate );

        const startTime = start.hour() * 60 + start.minute();
        if ( startTime >= maxEndTime )
            ++lateCount;

        const endTime = end.hour() * 60 + end.minute();
        if ( endTime <= minStartTime && endTime > 0 )
            ++earlyCount;

    };

    // we have to split the date checks and the time checks because the date checks
    // short-circuit but the time checks don't; if an appt is out of the date range,
    // we can ignore it, but an appt that isn't early can still be late and v-v.

    Object.values( eventMap )
        .filter( filterEvents )
        .forEach( countOutOfRangeEvents );

    calendar.earlyEventsInView = earlyCount > 0;
    calendar.lateEventsInView = lateCount > 0;
};


export {
    getCurrentViewSettings,
    mergeToCurrentViewSettings,
    findOutOfHoursEvents,
    getJobCardAppointments,
    getJobCardEngineers,
    getJobCardChecklist,
    addTimeLogOnPegboard,
    addPausedTimeLogOnPegboard,
    closeEitherCard
};
