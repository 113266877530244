/* eslint-disable function-call-argument-newline */
/*********************************************************************************************************************
 * @file Integration reducers
 * @todo This is somewhat out of date; ikm to fix
 *
 * We have several types of dropdowns, in addition to normal input fields.
 *
 * 1. Standard dropdown, takes a basic array of `{ id: string, label: string }`
 * 2. Dependent dropdown, picks an array from an object map based on the selection of
 *    another dropdown
 * 3. Dynamic dropdown, picks its array of options from an object map[ based on its unique key.
 *    New dropdowns can be added to this group by selecting an unselected key.
 *
 * For the standard dropdown, the options list is specififed by the name of the property key it
 * should use to look at in the master integration object.
 *
 * The dependent dropdown uses the following:
 * - A parent list that has a selected `id` or nothing.
 * - An object that has an array for each possible `id` in the parent list.
 * - It selects the list of options taking the `id` of the parent, looking that up in the
 *   object map. If it has an `id` then start with that option, otherwise take the first available.
 *
 * Each dropdown group will have the following:
 * - sectionTitle
 * - info
 * - adder
 * - dropdowns
 *
 * Each dropdown will have, at least, the following:
 * - name (name of the value)
 * - label (display label for the dropdown)
 *
 * In addition, each of the dropdown types will have additional fields, shown below.
 *
 * Each standard dropdown has the following:
 * - array of options
 *
 * Each dependent dropdown has, in addition to the above:
 * - parent (name of dropdown that determines which dropdown to show)
 * - object of arrays of options (indexed by value of parent)
 *
 * Each dynamic dropdown will have just one entry instead of the array of dropdowns. Dropdwons get added.
 * - currently selected keys, each gets a dropdown
 * - objectMap of options, unselected keys can be added as new dropdowns
 *
 * Each dropdown tracks:
 * - options
 * - value
 * 
 * Notes moved here from frontend README
 *
 * 1. Get provider data
 * 2. If we're integrated
 *    1. Set connection settings from provider data
 *    2. Try connection
 *    3. If okay, get settings
 * 3. If we're not integrated
 *    1. Set `hasSettings = false`
 * 
 * #### Trying the Connection
 * 
 * 1. Set `connecting = true`
 * 2. Set `connected = false`
 * 3. If okay, reverse the two connection values
 * 4. If not okay, set `connecting = true`
 * 
 * @author Julian Jensen Mjjensen@licorice.io>
 * @since 1.0.0
 * @date 12-Oct-2020
 *********************************************************************************************************************/

import { integrationFieldType } from '@licoriceio/constants';
import { hasStringValue, isObject, has } from '@licoriceio/utils';

import { setProvider, setProviders, setIntegrationFieldTouched, 
    setIntegrationFieldValue, setIntegrationSettings, setIntegrationProgress
} from '../../../redux/actions/index.js';
import { ezRedux } from '../../../redux/reducerUtil.js';

import { integrationDataFields } from './integration-layouts.js';

/**
 * @type {Readonly<IntegrationState>}
 */
const initialState = Object.freeze({
    fieldValue:         {},
    fieldTouched:       {},
    provider:           {},
    formErrors:         {},
    connectFieldsValid: false,
    connectFieldsSame:  false,
    canConnectFields:   [],
    connectionFields:   []
});

function connectionFieldsSame( provider, current, previous )
{
    for ( const [ name, { connect } ] of Object.entries( provider.fields ) )
    {
        if ( typeof connect !== 'number' ) continue;

        const inC = Object.hasOwn( current, name );

        if ( inC ^ Object.hasOwn( previous, name ) ) return false;

        if ( !inC ) continue;

        if ( current[ name ]?.toLowerCase() !== previous[ name ]?.toLowerCase() ) return false;
    }

    return true;
}

// can't store functions in store
const checkErrors = {};

const setValue = ( state, { name, key, index }, value ) => {
    const currentValue = state.fieldValue[ name ];

    if ( Array.isArray( currentValue ) && index !== undefined )
        currentValue[ index ] = value;
    else if ( isObject( currentValue ) && key !== undefined )
        currentValue[ key ] = value;
    else
        state.fieldValue[ name ] = value;
};

const hasConnectionErrors = draft => draft.canConnectFields.some( key => draft.formErrors[ key ]);

const connectionFieldsComplete = draft => draft.canConnectFields.every( key => draft.fieldValue[ key ]);

const connectionFieldErrors = draft => {
    draft.canConnectFields.forEach( field =>
        draft.formErrors[ field ] = checkErrors[ field ]?.( draft.fieldValue[ field ]) );

    draft.connectFieldsValid = connectionFieldsComplete( draft ) && !hasConnectionErrors( draft );

    draft.connectFieldsSame = draft.provider?.integrationData 
        ? connectionFieldsSame( draft.provider, draft.fieldValue, draft.provider.integrationData )
        : false;
};    
        
const _setFieldTouched = ( draft, name ) => {
    draft.fieldTouched[ name ] = true;
    connectionFieldErrors( draft );
};

const _setFieldValue = ( draft, { name, value, index, key }) => {

    // check for changes before we update value
    const valueChanged = value !== draft.fieldValue?.[ name ];

    setValue( draft, { name, index, key }, value );

    if ( draft.canConnectFields?.includes( name ) && valueChanged )
        connectionFieldErrors( draft );

};


const _setProvider = ( draft, provider ) => {
    
    Object.assign( draft.provider, provider );

    // we can send a partial record, eg just { connected: false }; only do the other actions if we have a full provider record
    if ( provider.fields ) {
        const canConnectFields = [];
        const connectionFields = [];
    
        for ( const [ field, props ] of Object.entries( provider.fields ) )
        {
            if ( hasStringValue( props, 'connect', 'number', false ) )
            {
                connectionFields[ props.connect ] = { name: field, ...props };
                canConnectFields[ props.connect ] = field;
                const validate = props.validate ? new RegExp( props.validate, 'i' ) : /.*/i;
                checkErrors[ field ] = v => validate.test( v || '' ) ? null : '* invalid';
                if ( field in provider.integrationData ) 
                    draft.fieldValue[ field ] = provider.integrationData[ field ];
            }

        }

        draft.canConnectFields = canConnectFields;
        draft.connectionFields = connectionFields;

        connectionFieldErrors( draft );
    }
};

const _setIntegrationSettings = ( draft, settings ) => {

    draft.settings = settings;

    // the settings data we have dictates the type of control and the source of the data, 
    // but not the physical order; this comes from integrationDataFields for now (as do any labels).
    // So, we store each section's controls in an object so we can pull them out as needed as we 
    // iterate over integrationDataFields.
    const sections = {};

    // we have need to set a field's value remotely with reference to the name only, ie not the licoriceNameId
    // (when we change a master field and want to reset dependent fields), so record field info by name.
    const fieldInfo = {};

    const dataSections = integrationDataFields[ draft.provider?.connectorName ];

    // set the current dropdown values
    // if the section isn't listed in dataSections, it's not in the layout at the moment so just ignore it
    Object.entries( settings.selected ).filter( ([ sectionName ]) => has( dataSections, sectionName ) ).forEach( ([ sectionName, section ]) => {

        sections[ sectionName ] = {
            dropdowns:  []
        };

        const addDropdown = ( field, respectFilter = true ) => {

            // we need to exclude the unmapped job status dropdown and all we have is the label.
            if ( respectFilter && dataSections[ sectionName ].fieldLabelFilter && !dataSections[ sectionName ].fieldLabelFilter( field.label ) )
                return;

            // linked fields are added after their parent fields
            if ( respectFilter && field.linkedField )
                return;

            // this is flawed; this structure is used to send updates to dependent fields, when we change the master.
            // However, the dependency data uses names and we need ids. We're using the coincidence that field.selection 
            // can be considered as name for the fields involved in dependency; it wouldn't work if we had 2 dept fields.
            // @todo needs to re-examined when we fix the dependency data
            fieldInfo[ field.selection ] = {
                id:             field.id,
                sectionName
            };

            const dropdown = {
                licoriceNameId:     field.id,
                optionType:         field.selection,
                name:               field.id,

                // all fields supply a label but we can override this label via integrationDataFields
                label:              field.label,

                mode:               field.mode,

                // only dynamic fields need sorting
                sort:               false // !field.key
            };

            // set the current value under the field name (key); for fields without keys (eg status dropdowns) 
            // we use the licorice id.

            if ( typeof field.value === 'string' ) {

                // this is a job status, value identified by the name of the status
                draft.fieldValue[ field.id ] = field.value;

                // create a new option list using the number of boards each status is used in.
                // Currently, we only need to do this once since only the job status dropdowns do this,
                // so we can store the new option list in the section. If there were different field types,
                // ie if field.selection had more than value within the section, we'd have to store the new
                // option lists with the dropdown, ie:
                // dropdown.options = settings.options[ field.selection ].map( ({ id, value }) => ({ id, label: `${id} (${value.length})` }) );
                sections[ sectionName ].options ??= settings.options[ field.selection ]
                    .toSorted( ( a, b ) => a.id.localeCompare( b.id ) )
                    .map( ({ id, value }) => ({ id, label: `${id} (${value.length})` }) );
                
                // we also need a map from id to value (which will be an array of ids) for the backend update
                sections[ sectionName ].optionValues ??= settings.options[ field.selection ].reduce( ( acc, cur ) => ({ ...acc, [ cur.id ]: cur.value }), {});
            }
            else if ( Array.isArray( field.value ) ) {

                if ( field.mode === integrationFieldType.LABEL_CHIP ) {

                    // labelChip fields need a map from id to label with count; we already have the data
                    sections[ sectionName ].optionLabels ??= Object.fromEntries( Object.values( sections[ sectionName ].options ).map( ({ id, label }) => ([ id, label ]) ) );

                    // eg CW job statuses, which are just stored as a list of strings (status names);
                    // when we add or remove items, we act on all mappings with that name
                    draft.fieldValue[ field.id ] = field.value.reduce( ( acc, cur ) => ({ ...acc, [ cur ]: sections[ sectionName ].optionLabels[ cur ] }), { });
                    dropdown.options = sections[ sectionName ].options;
                }
                else {
                    // standard array field holding a list of provider names, we store an object relating id to name for the selected statuses.
                    // None exist currently, but was used for active customer statuses
                    draft.fieldValue[ field.id ] = field.value.reduce( ( acc, cur ) => ({ ...acc, [ cur.id ]: cur.label }), { });
                }
            }
            else {

                // regular dropdown field mapping to a single providerName
                draft.fieldValue[ field.id ] = field.value.id;
            }

            // we want to display tooltips on some billing fields showing the names of the service boards that the current selection 
            // makes available, ie boards where the constraint for that field matches the field value, or where there is no constraint for that field.
            // So, we want a tooltip (or the base info for one) for each possible value for the field.
            if ( dataSections[ sectionName ].serviceBoardTooltip?.[ field.selection ]) {

                // we know the type name for this field ( field.selection )
                // we know the possible values for this field ( settings.options[ field.selection ] )
                // we have a list of service boards ( settings.options.board )
                // each of those boards has a constraint object, which may have a property matching
                // our type name. If it does, that board is will only eligible if that constraint property's 
                // value matches the current value.
                // We want to link each possible value for the field to a list of eligible service boards.
                dropdown.serviceBoardInfo = settings.options[ field.selection ].reduce( ( acc, { providerNameId }) => {
                    acc[ providerNameId ] = settings.options.board
                        .filter( ({ constraints }) => constraints[ field.selection ] ? constraints[ field.selection ] === providerNameId : true )
                        .map( ({ providerNameId, label }) => ({ providerNameId, label }) );
                    return acc;
                }, {});
            }

            sections[ sectionName ].dropdowns.push( dropdown );

            // check for linked field
            if ( dataSections[ sectionName ].linkedField?.[ field.label ]) {

                // criteria is a map of properties that we'll look for in the selected data for this section
                const criteria = dataSections[ sectionName ].linkedField?.[ field.label ];
                let fields = Object.values( settings.selected[ sectionName ]);

                // cope with multiple criteria even though we don't need it yet
                Object.entries( criteria ).forEach( ([ property, value ]) => {
                    fields = fields.filter( field => field[ property ] === value );
                });
                if ( fields.length === 0 ) 
                    console.warn( 'Ran out of fields looking for linked field', criteria );
                else if ( fields.length === 1 ) 
                    addDropdown( fields[ 0 ], false );
                else
                    console.warn( 'Multiple fields found for linked field', criteria );
            }
        };

        // note that this section object comes from the settings data we received from the backend;
        // we use it to build the local section data, ie the list of fields
        section.forEach( field => addDropdown( field ) );
    });

    const dependentOn = {};
    Object.entries( settings.dependencies ).forEach(
        ([ masterField, dependents ]) => {

            // we should be able to find a field with a matching type
            // @todo to be revised when the backend dependency data has the ids
            let realMasterField;
            for ( const section of Object.values( settings.selected ) ) {
                realMasterField = section.find( field => field.selection === masterField );
                if ( realMasterField ) 
                    break;
            }
            if ( !realMasterField ) {
                // throw ( new Error( "No realMasterField found" )  );
                console.error( 'No realMasterField found', masterField );
                return;
            }

            // copy under the id, which is how the updates will come thru
            draft.settings.dependencies[ realMasterField.id ] = dependents;

            // we want to reverse the dependency information so we know when to filter a dependent field
            const dependentFieldNames = Object.keys( dependents );
            dependentFieldNames.reduce( ( acc, cur ) => {
                acc[ cur ] = {
                    optionType:         masterField,
                    licoriceNameId:     realMasterField.id
                };
                return acc;
            }, dependentOn );

            // eligible masterFields must have entries for each dependent
            const validIds = Object.values( dependents ).reduce( ( acc, cur ) => {
                return acc.length === 0 ? Object.keys( cur ) : acc.filter( id => Object.keys( cur ).includes( id ) );
            }, []);

            // we replace the options list for the master field with the filtered version.
            // We're only coping with one level of dependence at this stage.
            draft.settings.options[ masterField ] = draft.settings.options[ masterField ].filter( ({ providerNameId }) => validIds.includes( providerNameId ) );
        });
    draft.dependentOn = dependentOn;

    draft.sections = sections;
    draft.fieldInfo = fieldInfo;
};

const reducer = {
    [ setProvider ]:                        _setProvider,
    [ setProviders ]:                       ( draft, payload ) => draft.providers = payload,
    [ setIntegrationFieldTouched ]:         _setFieldTouched,
    [ setIntegrationFieldValue ]:           _setFieldValue,
    [ setIntegrationSettings ]:             _setIntegrationSettings,
    [ setIntegrationProgress ]:             ( draft, payload ) => draft.progress = payload
};

export default ezRedux( reducer, initialState );
