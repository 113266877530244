import React from 'react';

import { numMinutesToString } from '@licoriceio/utils';
import { Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';


const timeOptions = [ ...Array( 48 ).keys() ].map( i => ({ value: i * 30, label: numMinutesToString( i * 30 ) }) );
const AMPMTimeOptions = [ ...Array( 48 ).keys() ].map( i => ({ value: i * 30, label: numMinutesToString( i * 30, true ) }) );

const TimeSelector = ({ name, value, onChange, ampm }) =>
    <Select
        name={name}
        value={value}
        onChange={e => onChange({
            name:       e.target.name,
            value:      e.target.value
        })}
        variant="outlined"
        classes={{ selectMenu: "slim bold" }}
    >
        {( ampm ? AMPMTimeOptions : timeOptions ).map( option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem> )}
    </Select>;

TimeSelector.propTypes = {
    name:       PropTypes.string.isRequired,
    value:      PropTypes.number.isRequired,
    onChange:   PropTypes.func.isRequired,
    ampm:       PropTypes.bool
};

export default TimeSelector;
export { TimeSelector };
