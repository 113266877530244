/********************************************************************************************************************
 * @file Actions for the organisation store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setLoadOrganisationSettings = createAction( 'ORGANISATION.LOAD_SETTINGS/SET' );
export const setSettingValueOrganisation = createAction( 'ORGANISATION.SETTING/SET' );
export const setSettingValueOrganisationOpenHours = createAction( 'ORGANISATION.OPEN_HOURS_SETTING/SET' );
export const setAddOrganisationBusinessHours = createAction( 'ORGANISATION.ADD_BUSINESS_HOURS/SET' );
export const setRemoveOrganisationBusinessHours = createAction( 'ORGANISATION.REMOVE_BUSINESS_HOURS/SET' );
export const setAddOrganisationOpenHours = createAction( 'ORGANISATION.ADD_OPEN_HOURS/SET' );
export const setRemoveOrganisationOpenHours = createAction( 'ORGANISATION.REMOVE_OPEN_HOURS/SET' );
export const discardOrganisationSettingChanges = createAction( 'ORGANISATION.DISCARD_CHANGES/SET' );
export const setAddCAClientMode = createAction( 'ORGANISATION.ADD_CA_CLIENT_MODE/SET' );
export const setAddCAClient = createAction( 'ORGANISATION.ADD_CA_CLIENT/SET' );
export const setRemoveCAClient = createAction( 'ORGANISATION.REMOVE_CA_CLIENT/SET' );
export const discardOnlineAppointmentsSettingChanges = createAction( 'ORGANISATION.ONLINE_APPTS_DISCARD_CHANGES/SET' );
export const setEligibleEngineers = createAction( 'ORGANISATION/CHECK_ELIGIBLE_ENGINEERS' );
export const setDirectURL = createAction( 'ORGANISATION/DIRECT_URL/SET' );
