import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { snackbarKey, UserProfileMode } from '../../../constants.js';
import { UX_SETTINGS_INVITE_POPUP } from '../../../ux-constants.js';
import { setEngineerSettings, discardAllChanges, setUserProfile } from '../../../redux/actions/index.js';
import { filterLine, listPagerWrap, listPager } from '../../../scss/ManageEngineers.module.scss';
import { settings, screenTitle, saveButton, addButton } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { MfaStates } from "../../../utils/mfa.js";
import { H1, LicoButton, LicoLiveSearch, LicoTable } from "../../common/index.js";
import LicoSnackbar from '../../common/LicoSnackbar.jsx';
import ListPager    from '../../common/ListPager.jsx';
import NavigationDialog    from '../../common/NavigationDialog.jsx';
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import UserProfileModal from '../../common/UserProfileModal.jsx';
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import EngineerLine from './EngineerLine.jsx';
import {
    getEngineerState,
    continueNavigation,
    continueUserProfileClose,
    patchEngineer,
    getPage,
    saveAllChanges
} from './reducer.js';

const labels = [
    __( "Name" ),
    __( "Email" ),
    __( "Title" ),
    __( "Phone" ),
    __( "Admin" ),
    __( "Active" ),
    __( "Client Scheduling" ),
    "",
    ""
];

const widths = [
    "16%",
    "21%",
    "11%",
    "11%",
    "7%",
    "7%",
    "7%",
    "9%",
    "10%"
];

const initUserProfile = {
    user:               {
        password:                           '',
        error:                              '',
        foundChanges:                       false,
        mode:                               UserProfileMode.ADD,
        fieldTouched:                       {},
        fieldError:                         {},
        userId:                             undefined,
        twoFactorAuthenticationRequired:    undefined,
        engineerProfile:                    false,
        name:                               '',
        loginEmail:                         '',
        preferences:                        undefined,
        mfaState:                           MfaStates.NONE,
        verified:                           false,
        mfaVerified:                        false,
        qrcode:                             null,
        qrCodeUrl:                          null,
        defaultAppointmentDuration:         null
    },
    engineerProfile:    true,
    mode:            UserProfileMode.ADD

};

class ManageEngineers extends PureComponent {

    componentDidMount() {
        const matches = /\?filter=(.+)$/.exec( window.location.search ) || [];
        this.props.getPage({ page: 0, withCount: true, filter: unescape( matches[ 1 ] || '' ) });
    }

    render() {
        const {
            continueNavigation,
            continueUserProfileClose,
            unsavedEngineers,
            foundChanges,
            foundErrors,
            filterString,
            page,
            pageSize,
            totalPages,
            getPage,
            confirmNavigationDialogOpen,
            confirmUserProfileDialogOpen,
            setEngineerSettings,
            saveAllChanges,
            discardAllChanges,
            history,
            engineerProfile,
            setUserProfile,
            adminLoggedIn
        } = this.props;

        return <SettingsLayout>
            <LicoSnackbar
                data-ux={UX_SETTINGS_INVITE_POPUP}
                metaKey={ snackbarKey.INVITE_CREATED }
                label={__( "Invitation sent!" )} />
            <LicoSnackbar
                metaKey={ snackbarKey.ENGINEERS_SAVED }
                label={__( "Changes saved." )} />
            <LicoSnackbar
                metaKey={ snackbarKey.ENGINEER_ADDED }
                label={__( "Engineer was added." )} />

            <NavigationDialog
                isOpen={confirmNavigationDialogOpen}
                saveAction={() => continueNavigation( true )}
                discardAction={() => continueNavigation( false )}
                cancelAction={() => setEngineerSettings({ confirmNavigationDialogOpen: false })}
            />

            <NavigationDialog
                isOpen={confirmUserProfileDialogOpen}
                saveAction={() => continueUserProfileClose( true )}
                discardAction={() => continueUserProfileClose( false )}
                cancelAction={() => setEngineerSettings({ confirmUserProfileDialogOpen: false })}
            />

            <PreventTransitionPrompt
                when={foundChanges}
                history={history}
                saveAction={saveAllChanges}
                discardAction={discardAllChanges}
            />
            
            { engineerProfile && <UserProfileModal/>}

            <div className={settings}>
                <H1 className={screenTitle}>{__( "Engineers" )}</H1>
                <div className={filterLine}>
                    <LicoLiveSearch
                        placeholder={__( "Filter" )}
                        value={filterString}
                        onChange={e => getPage({ page: 0, withCount: true, filter: e.target.value })}
                    />
                </div>

                <LicoTable
                    labels={labels}
                    widths={widths}
                >

                    {unsavedEngineers && unsavedEngineers.map( ( e, i ) => <EngineerLine
                        index={i}
                        engineer={e}
                        key={e.userId}
                        widths={widths}
                        confirmedInvitation={e.active}
                    /> )}

                </LicoTable>

                <div className={listPagerWrap}>
                    <ListPager
                        className={listPager}
                        page={page}
                        pageSize={pageSize}
                        totalPages={totalPages}
                        getPage={getPage}
                        filter={filterString}
                    />

                    { adminLoggedIn && <LicoButton
                        className={addButton}
                        data-ux={'AddButton'}
                        onClick={() => { setUserProfile( initUserProfile );
                        }
                        }
                    >
                        {__( "Add" )}
                    </LicoButton> }
                    <LicoButton
                        onClick={saveAllChanges}
                        className={saveButton}
                        disabled={!foundChanges || foundErrors}
                        data-ux={'saveButton'}
                    >
                        {__( "Save" )}
                    </LicoButton>
                </div>

            </div>
        </SettingsLayout>;
    }

    static propTypes = {
        unsavedEngineers:               PropTypes.array,
        filterString:                   PropTypes.string.isRequired,
        foundChanges:                   PropTypes.bool,
        continueNavigation:             PropTypes.func.isRequired,
        continueUserProfileClose:       PropTypes.func.isRequired,
        // getEngineers:                   PropTypes.func.isRequired,
        getPage:                        PropTypes.func.isRequired,
        setEngineerSettings:            PropTypes.func.isRequired,
        saveAllChanges:                 PropTypes.func.isRequired,
        discardAllChanges:              PropTypes.func.isRequired,
        foundErrors:                    PropTypes.bool.isRequired,
        page:                           PropTypes.number.isRequired,
        pageSize:                       PropTypes.number,
        totalPages:                     PropTypes.number.isRequired,
        confirmNavigationDialogOpen:    PropTypes.bool.isRequired,
        confirmUserProfileDialogOpen:   PropTypes.bool.isRequired,
        history:                        PropTypes.object.isRequired,
        engineerProfile:                PropTypes.bool,
        setUserProfile:                 PropTypes.func.isRequired,
        adminLoggedIn:                  PropTypes.bool.isRequired
    };
}

const mapStateToProps = state => {
    const { 
        unsavedEngineers, foundChanges, foundErrors, filterString, page, pageSize, totalPages, confirmNavigationDialogOpen, confirmUserProfileDialogOpen
    } = getEngineerState( state );
    const { userProfile: { engineerProfile } } = state;
    return { 
        unsavedEngineers, foundChanges, foundErrors, filterString, page, pageSize, totalPages, confirmNavigationDialogOpen, confirmUserProfileDialogOpen,
        engineerProfile, adminLoggedIn:  state.user.admin || false
    };
};

const mapDispatchToProps = {
    continueNavigation,
    continueUserProfileClose,
    patchEngineer,
    getPage,
    setEngineerSettings,
    saveAllChanges,
    discardAllChanges,
    setUserProfile
};

export default connect( mapStateToProps, mapDispatchToProps )( ManageEngineers );
export { ManageEngineers };
