
import { MINUTE } from "@licoriceio/constants";

import { DEFAULT_APPOINTMENT_DURATION } from "../constants.js";
import { store } from "../publicStore.js";

/**
 * 
 * @param {object} event - object with start (Date), allDay (boolean) and maybe end (Date)
 * @returns {object} - startDate (string), endDate (string|null)
 */
const makeDefaultEventDates = event => {
    const { start, allDay } = event;
    let { end } = event;

    // reject (ie undo locally) any moves that span more than one day
    if ( end && start.getDay() !== end.getDay() && ( end.getHours() > 0 || end.getMinutes() > 0 ) )
        return;

    const startDate = start || new Date();

    // if we're not all day and we don't have an end time, use the user or system default duration
    if ( !end && !allDay ) 
        end = new Date( startDate.getTime() + getDefaultAppointmentDuration() * MINUTE );

    return {
        startDate:      startDate.toISOString(),
        endDate:        allDay
            ? null
            : end.toISOString()
    };
};

const getDefaultAppointmentDuration = preferences => {

    if ( !preferences )
        preferences = store.getState().user.preferences;
    return preferences.defaultAppointmentDuration ?? DEFAULT_APPOINTMENT_DURATION;
};

export {
    makeDefaultEventDates,
    getDefaultAppointmentDuration
};
