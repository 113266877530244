import { UserRole } from "@licoriceio/constants";

import { engineerField, GET, snackbarKey, TableFieldType, uri } from "../../../constants.js";
import {
    resetFoundChanges,
    setClientFilterString,
    setClientLocation,
    setClients,
    setClientStatus,
    setClientUsers,
    setNewClients,
    setNewUsers,
    setUnsavedClients,
    setUnsavedUsers,
    setTotalClientCount,
    setUnsavedSite,
    setNewSite,
    setCurrentPage,
    setUserFilterString,
    setSiteFilterString,
    setCurrentUserPage,
    setTotalUserCount,
    setTotalSiteCount,
    setCurrentSitePage,
    setClientSettings
} from "../../../redux/actions/client.js";
import {  setMeta } from "../../../redux/actions/index.js";
import { ezRedux, genericReducer, genericRequest } from "../../../redux/reducerUtil.js";
import { abstractedCreateAuthRequest } from "../../../services/util/baseRequests.js";
import { __ } from "../../../utils/i18n.jsx";


/**
 * @typedef {object} ClientState
 * @property {array} clients
 * @property {array} clientStatuses
 * @property {array} unsavedClient
 * @property {string} filterString
 */


const initialState = Object.freeze({
    allClients:                   [],
    totalClientCount:             0,
    totalUserCount:               0,
    totalSiteCount:               0,
    currentPage:                  0,
    currentUserPage:              0,
    clientStatuses:               {},
    unsavedClient:                undefined,
    filterString:                 '',
    userFilterString:             '',
    siteFilterString:             '',
    clientLocation:               [],
    foundChanges:                 false,
    unsavedClients:               [],
    newClients:                   [],
    unsavedUsers:                 [],
    newUsers:                     [],
    unsavedSites:                 [],
    newSites:                     [],
    confirmUserProfileDialogOpen:  false,
    confirmNavigationDialogOpen:   false,
    showDuplicateErrorMessage:    false,
    selectedClientId:              '',
    clientContactId:               '',
    clientColumns:                 [
        {
            field:        'status',
            headerName:   __( "Status" ),
            editable:     true,
            width:        70,
            type:         TableFieldType.SINGLE_SELECT,
            valueOptions: []
        },
        {
            field:      'companyName',
            headerName: __( "Client Name*" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'phone',
            headerName: __( "Main Phone" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'users',
            headerName: __( "Users" ),
            sortable:   false,
            type:       TableFieldType.LINK,
            width:      50
        },
        {
            field:      'locations',
            headerName: __( "Sites" ),
            type:       TableFieldType.LINK,
            width:      50
        },
        {
            field:      'note',
            headerName: __( "Status Note" ),
            editable:   false,
            flex:       1
        }
    ],
    userColumns:                   [
        {
            field:      'defaultUser',
            headerName: __( "Default User" ),
            type:         TableFieldType.RADIO,
            width:        80
        },
        {
            field:        'active',
            headerName:   __( "Status" ),
            editable:     true,
            width:        200,
            type:         TableFieldType.SINGLE_SELECT,
            valueOptions: [
                {
                    id:    'true',
                    value: "Active"
                }, {
                    id:    'false',
                    value: "Inactive"
                }
            ]
        },
        {
            field:      'name',
            headerName: __( "Full Name*" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'title',
            headerName: __( "Title" ),
            editable:   true,
            flex:       1
        },
        {
            field:       'phone',
            headerName:  __( "Mobile" ),
            editable:    true,
            renderField: ( row ) => {
                const contactInfo = row?.contactInfo?.find( contact => contact.type === engineerField.PHONE );
                return contactInfo?.value || '';
            },
            flex:        1
        },
        {
            field:       'email',
            renderField: ( row ) => {
                const contactInfo = row?.contactInfo?.find( contact => contact.type === engineerField.EMAIL  );
                return contactInfo?.value || '';
            },
            headerName:  __( "Email*" ),
            editable:    true,
            flex:        1,
            isRequired: true
        }
    ],
    siteColumns:                   [
        {
            field:      'name',
            headerName: __( "Site Name*" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'addressLine1',
            headerName: __( "Street Address" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'addressLine2',
            headerName: __( "Lot/Apt/Unit/Suite" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'state',
            headerName: __( "State" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'country',
            headerName: __( "Country" ),
            editable:   true,
            flex:       1
        },
        {
            field:      'postalCode',
            headerName:  __( "Post Code" ),
            editable:   true,
            flex:       1
        }
    ]
});

export const PAGE_SIZE = 20;

const _asyncGetClients = abstractedCreateAuthRequest( "GET", uri.COMPANY_LIST );
const _asyncGetClientLocations = abstractedCreateAuthRequest( GET, uri.COMPANY_SITES );
const _asyncGetClientUsers = abstractedCreateAuthRequest( GET, uri.CLIENT_USERS );
const _asyncPatchUsers = abstractedCreateAuthRequest( "PATCH", uri.SINGLE_USER );

const formatFilterString =  filterString  => {
    // If the filterString is not empty, add a ~ to the beginning of the string to indicate a like search and not exact name search
    return ( filterString ? "~" + filterString : "~" );
};

const getClients = ( page, filterString ) => ( dispatch, getState ) => {
    _asyncGetClients( undefined,
        getState().auth,
        [],
        { offset: page * PAGE_SIZE, limit: PAGE_SIZE, withCount: 1, companyName: formatFilterString( filterString ) }).then( result => {
        dispatch( setCurrentPage( page ) );
        dispatch( setTotalClientCount( result.count ) );
        dispatch( setClients( result.payload ) );

    });
};

const checkDuplicateClients = payload => async ( dispatch, getState ) => {
    const { companyName, companyId } = payload;
    const result = await _asyncGetClients(
        undefined,
        getState().auth,
        [],
        { companyName: formatFilterString( companyName ) }
    );
    const clients = result.payload;
    const showDuplicateErrorMessage = clients.some( client => clients.filter( c => c.companyName === companyName && c.companyId !== companyId ).length >= 1 );
    dispatch( setClientSettings({ showDuplicateErrorMessage: clients.some( client => clients.filter( c => c.companyName === companyName && c.companyId !== companyId ).length >= 1 ) }) );
};

const checkDuplicateUsers =  payload  => async ( dispatch, getState ) => {
    const clientId = getState().client.selectedClientId;
    const { loginEmail, userId } = payload;
    const result = await _asyncGetClientUsers( undefined, getState().auth, [ clientId ], {  loginEmail: loginEmail || "" });
    const users = result.payload;
    const showDuplicateErrorMessage = users.some( user => users.filter( u => u.loginEmail === loginEmail && u.userId !== userId ).length >= 1 );
    dispatch( setClientSettings({ showDuplicateErrorMessage: showDuplicateErrorMessage }) );
};

const checkDuplicateSites =  payload  => async ( dispatch, getState ) => {
    const clientId = getState().client.selectedClientId;
    const { name, siteId } = payload;
    const result = await _asyncGetClientLocations( undefined, getState().auth, [ clientId ], { name: name || "" });
    const sites = result.payload;
    const showDuplicateErrorMessage = sites.some( site => sites.filter( s => s.name === name && s.siteId !== siteId ).length >= 1 );
    dispatch( setClientSettings({ showDuplicateErrorMessage: showDuplicateErrorMessage }) );
};


const getStatuses = () => genericRequest({}, abstractedCreateAuthRequest( "GET", uri.COMPANY_STATUSES ), setClientStatus, []);

const getUsers = ( page, userFilterString, clientId ) => ( dispatch, getState ) => {
    _asyncGetClientUsers( undefined, getState().auth, [ clientId ], { offset: page * PAGE_SIZE, limit: PAGE_SIZE, withCount: 1, name: userFilterString || "" }).then( result => {
        dispatch( setCurrentUserPage( page ) );
        dispatch( setTotalUserCount( result.count ) );
        dispatch( setClientUsers( result.payload ) );
    });
};

const getLocation =  ( page, siteFilterString, clientId ) => ( dispatch, getState ) => {
    _asyncGetClientLocations( undefined, getState().auth, [ clientId ], { offset: page * PAGE_SIZE, limit: PAGE_SIZE, withCount: 1, name: siteFilterString }).then( result => {
        dispatch( setCurrentSitePage( page ) );
        dispatch( setTotalSiteCount( result.count ) );
        dispatch( setClientLocation( result.payload ) );
    });
};
const patchClients =  payload  => genericRequest( payload,
    abstractedCreateAuthRequest( "PATCH", uri.COMPANY_BULK_UPDATE ), [ resetFoundChanges, [ setMeta, { [ snackbarKey.CLIENT_DETAILS_SAVED ]: true } ] ]);
const addClients =  payload  => genericRequest( payload,
    abstractedCreateAuthRequest( "POST", uri.COMPANY_CREATE ), [ resetFoundChanges, [ setMeta, { [ snackbarKey.CLIENT_DETAILS_SAVED ]: true } ] ]);

const patchUsers = payload => ( dispatch, state ) => {
    const { userFilterString, selectedClientId } = state().client;
    const promises = payload.map( user => {
        const updatedUsers = { ...user, ...{ contactInfo: [ ...( user?.contactInfo || []) ] } };
        const requestUsers = transformToUserInfo( updatedUsers );
        return dispatch( genericRequest( requestUsers, _asyncPatchUsers, [], [ user.userId ]) );
    });
    return Promise.all( promises ).then( () => { dispatch( setMeta({ [ snackbarKey.CLIENT_DETAILS_SAVED ]: true }) ); dispatch( getClientUsers({  page: 0, userFilterString, selectedClientId }) ); dispatch( resetFoundChanges() ); });  // Dispatch a snackbar message

};

const patchSite = ( payload ) => ( dispatch ) => {
    const promises = payload.map( site => {
        return dispatch( genericRequest( site, abstractedCreateAuthRequest( "PATCH", uri.UPDATE_SITE ), resetFoundChanges, [ site.siteId ]) );
    });
    return Promise.all( promises ).then( () => { dispatch( setMeta({ [ snackbarKey.CLIENT_DETAILS_SAVED ]: true }) ); });
};

const addSite = ( payload ) => ( dispatch ) => {
    const { companyId, newSites } = payload;
    const promises = newSites.map( site => {
        site = { ...site, companyId };
        return dispatch( genericRequest( site, abstractedCreateAuthRequest( "POST", uri.CREATE_SITE ), resetFoundChanges, []) );
    });
    return Promise.all( promises ).then( () => { dispatch( setMeta({ [ snackbarKey.CLIENT_DETAILS_SAVED ]: true }) ); });
};

const addUsers = ( companyId,  payload ) => ( dispatch, state ) => {
    const { userFilterString } = state().client;
    const promises = payload.map( user => {
        const newUsers = { ...user, ...{ contactInfo: [ ...( user?.contactInfo || []) ] } };
        const requestUsers = transformToUserInfo( newUsers );
        return dispatch( genericRequest( requestUsers, abstractedCreateAuthRequest( "POST", uri.CLIENT_USER_ADD ), [], [ companyId ]) );
    });
    return Promise.all( promises ).then( () => { dispatch( setMeta({ [ snackbarKey.CLIENT_DETAILS_SAVED ]: true }) ); dispatch( getClientUsers({  page: 0, userFilterString, companyId }) ); dispatch( resetFoundChanges() ); });
};
const getPage = ( payload ) => async ( dispatch, getState ) => {
    const { page, filterString } = payload;
    const foundChanges = getState().client.foundChanges;
    if ( foundChanges ) 
        dispatch( setClientSettings({ confirmNavigationDialogOpen: true }) );
    else
        dispatch( getClients( page, filterString ) );

};
const statusList = () => ( dispatch ) => {
    dispatch( getStatuses() );
};

const getClientLocation =  payload  => ( dispatch, getState ) => {
    const clientId = getState().client.selectedClientId;
    const { page, siteFilterString, clientId: paramClientId } = payload;
    const pageClientId = clientId || paramClientId;
    dispatch( setClientSettings({ selectedClientId: pageClientId }) );
    dispatch( getLocation( page, siteFilterString, pageClientId ) );
};

const getClientUsers =  payload => ( dispatch, getState ) => {
    const clientId = getState().client.selectedClientId;
    const { page, userFilterString, clientId: paramClientId } = payload;
    const pageClientId = clientId || paramClientId;
    dispatch( setClientSettings({ selectedClientId: pageClientId }) );
    dispatch( getUsers( page, userFilterString, pageClientId ) );
};




const continueNavigation = saveChanges => async ( dispatch, getState ) => {
    if ( saveChanges )
        dispatch( saveClientChanges() );
    else 
        dispatch( resetFoundChanges() );
    
    const { currentPage, filterString } = getState().client;
    getPage({ page: currentPage, filterString: filterString });
};

const saveClientChanges = () => async ( dispatch, getState ) => {
    await dispatch( verifyDuplicateClient() );
    const { unsavedClients, newClients, showDuplicateErrorMessage } = getState().client;

    if ( unsavedClients.length !== 0 && !showDuplicateErrorMessage )
        dispatch( patchClients( unsavedClients ) );
        
    if ( newClients.length !== 0  && !showDuplicateErrorMessage )
    {
        const newClientsWithoutCompanyId = newClients.map( client => {
            const { companyId, ...rest } = client;
            return rest;
        });
        dispatch( addClients( newClientsWithoutCompanyId ) );
    }
};
const verifyDuplicateClient = ( ) => async ( dispatch, getState ) => {
    const { unsavedClients, newClients } = getState().client;
    unsavedClients.forEach( client => { client?.companyName ? dispatch( checkDuplicateClients({ companyName: client.companyName, companyId: client.companyId }) ) : null; });
    newClients.forEach( client => { client?.companyName ? dispatch( checkDuplicateClients({ companyName: client.companyName, companyId: client.companyId }) ) : null; });
};

const verifyDuplicateUser = ( ) => async ( dispatch, getState ) => {
    const { unsavedUsers, newUsers } = getState().client;
    unsavedUsers.forEach( user => { user?.email ? dispatch( checkDuplicateUsers({ loginEmail: user.email, userId: user.userId }) ) : null; });
    newUsers.forEach( user => { user?.email ? dispatch( checkDuplicateUsers({ loginEmail: user.email, userId: user.userId }) ) : null; });
};

const verifyDuplicateSite = ( ) => async ( dispatch, getState ) => {
    const { unsavedSites, newSites } = getState().client;
    unsavedSites.forEach( site => { site?.name ? dispatch( checkDuplicateSites({ name: site.name, siteId: site.siteId }) ) : null; });
    newSites.forEach( site => { site?.name ? dispatch( checkDuplicateSites({ name: site.name, siteId: site.siteId }) ) : null; });
};

const continueUserNavigation =  saveChanges => async ( dispatch, getState ) => {
    if ( saveChanges )
        dispatch( saveUserChanges() );
    else
    {
        dispatch( resetFoundChanges() );
        const { currentUserPage, userFilterString, selectedClientId } = getState().client;
        dispatch( getClientUsers({ page: currentUserPage, userFilterString, clientId: selectedClientId }) );
    }

};

const continueSiteNavigation = saveChanges => async ( dispatch, getState ) => {
    if ( saveChanges )
        dispatch( saveSiteChanges() );
    else
    {
        dispatch( resetFoundChanges() );
        const { currentSitePage, siteFilterString, selectedClientId } = getState().client;
        dispatch( getClientLocation({ page: currentSitePage, siteFilterString: siteFilterString, clientId: selectedClientId }) );
    }
};

const saveUserChanges = () => ( dispatch, getState ) => {
    dispatch( verifyDuplicateUser() );
    const state = getState();
    const companyId = state.client.selectedClientId;
    const { unsavedUsers, newUsers } = state.client;
    if ( unsavedUsers.length !== 0 )
        dispatch( patchUsers( unsavedUsers ) );
    if ( newUsers.length !== 0 )
    {
        if ( newUsers.length !== 0 )
        {
            const newUsersWithoutUserId = newUsers.map( user => {
                const { userId, ...rest } = user;
                return rest;
            });
            dispatch( addUsers( companyId,  newUsersWithoutUserId ) );
        }
    }
};

const saveSiteChanges = () => ( dispatch, getState ) => {
    dispatch( verifyDuplicateSite() );
    const state = getState();
    const companyId = state.client.selectedClientId;
    const { unsavedSites, newSites } = state.client;

    if ( unsavedSites.length !== 0 )
        dispatch( patchSite( unsavedSites ) );
    if ( newSites.length !== 0 ) {
        const newSitesWithoutSitesId = newSites.map( site => {
            const { siteId, ...rest } = site;
            return rest;
        });
        dispatch( addSite({ companyId, newSites: newSitesWithoutSitesId }) );
    }
};
const showSavedMessage = () => async ( dispatch ) => {
    dispatch( setMeta({ [ snackbarKey.ENGINEERS_SAVED ]: true }) );
};

const setClientStatusReducer = ( draft, payload ) => {
    const clientStatuses = {};
    payload.forEach( status => {
        clientStatuses[ status.licoriceNameId ] = status.name;
    });
    draft.clientStatuses = clientStatuses;
};
const transformToCompanyInfo =  payload  => {
    if ( payload.phone )
    {
        // If phone field is present in the payload, transform it to contactInfo format
        const phoneInfo = {
            type:      "phone",
            label:     "main",
            value:     payload.phone,
            isDefault: true
        };
        payload.contactInfo = [ phoneInfo ];
        // remove payload.phone from the payload
        delete payload.phone;
    }
    if ( payload.status )
    {
        payload.companyStatus = payload.status;
        delete payload.status;
    }
    return payload;
};
const transformToUserInfo = ( payload ) => {
    if ( payload.phone  )
    {
        const phoneInfo = {
            type:      "phone",
            label:     "main",
            value:     payload.phone,
            isDefault: true
        };
        payload.contactInfo.push( phoneInfo );
        delete payload.phone;
    }
    if ( payload.email )
    {
        const emailInfo = {
            type:      "email",
            label:     "main",
            value:     payload.email,
            isDefault: true
        };
        delete payload.email;
        payload.contactInfo.push( emailInfo );
    }
    if ( payload.status )
    {
        payload.companyStatus = payload.status;
        delete payload.status;
    }
    if ( payload.contactInfo.length === 0 ) 
        delete payload.contactInfo;
    
    payload = { ...payload, role: UserRole.user };
    return payload;
};
const setUnsavedClientReducer = ( draft, payload ) => {
    draft.foundChanges = true;
    payload = transformToCompanyInfo( payload );
    // Check if the payload's companyId is already present in unsavedClients
    const existingClientIndex = draft.unsavedClients.findIndex( client => client.companyId === payload.companyId );

    if ( existingClientIndex !== -1 )
    {

        // If the companyId is present, update the existing client with the new fields
        draft.unsavedClients[ existingClientIndex ] = {
            ...draft.unsavedClients[ existingClientIndex ],
            ...payload
        };
    } else
        draft.unsavedClients.push( payload );
};
const setUnsavedUsersReducer = ( draft, payload ) => {
    draft.foundChanges = true;
    const existingUserIndex = draft.unsavedUsers.findIndex( user => user.userId === payload.userId );
    if ( existingUserIndex !== -1 ) {
        draft.unsavedUsers[ existingUserIndex ] = {
            ...draft.unsavedUsers[ existingUserIndex ],
            ...payload
        };
    } else
        draft.unsavedUsers.push( payload );
};
const setNewClientsReducer = ( draft, payload ) => {
    draft.foundChanges = true;
    payload = transformToCompanyInfo( payload );
    // Check if the payload's companyId is already present in unsavedClients
    const existingClientIndex = draft.newClients.findIndex( client => client.companyId === payload.companyId );
    if ( existingClientIndex !== -1 )
    {
        // If the companyId is present, update the existing client with the new fields
        draft.newClients[ existingClientIndex ] = {
            ...draft.newClients[ existingClientIndex ],
            ...payload
        };
    }
    else
        draft.newClients.push( payload );
};
const resetFoundChangesReducer = ( draft ) => {
    draft.foundChanges = false;
    draft.unsavedClients = [];
    draft.newClients = [];
    draft.unsavedUsers = [];
    draft.newUsers = [];
    draft.unsavedSites = [];
    draft.newSites = [];
    draft.confirmUserProfileDialogOpen = false;
    draft.confirmNavigationDialogOpen = false;
};
const setNewUsersReducer = ( draft, payload ) => {
    draft.foundChanges = true;
    const existingUserIndex = draft.newUsers.findIndex( user => user.userId === payload.userId );
    if ( existingUserIndex !== -1 )
    {
        draft.newUsers[ existingUserIndex ] = {
            ...draft.newUsers[ existingUserIndex ],
            ...payload
        };
    }
    else
        draft.newUsers.push( payload );
};
const setUnsavedSitesReducer = ( draft, payload ) => {
    draft.foundChanges = true;
    const existingSiteIndex = draft.unsavedSites.findIndex( site => site.siteId === payload.siteId );
    if ( existingSiteIndex !== -1 )
    {
        draft.unsavedSites[ existingSiteIndex ] = {
            ...draft.unsavedSites[ existingSiteIndex ],
            ...payload
        };
    }
    else
        draft.unsavedSites.push( payload );
};
const setNewSitesReducer = ( draft, payload ) => {
    draft.foundChanges = true;
    const existingSiteIndex = draft.newSites.findIndex( site => site.siteId === payload.siteId );
    if ( existingSiteIndex !== -1 )
    {
        draft.newSites[ existingSiteIndex ] = {
            ...draft.newSites[ existingSiteIndex ],
            ...payload
        };
    }
    else
        draft.newSites.push( payload );
};

const reducers = {
    [ setClients ]:            ( draft, payload ) => draft.allClients = payload,
    [ setClientStatus ]:       setClientStatusReducer,
    [ setClientLocation ]:     ( draft, payload ) => draft.clientLocations = payload,
    [ setClientUsers ]:        ( draft, payload ) => draft.clientUsers = payload,
    [ setClientFilterString ]: ( draft, payload ) => draft.filterString = payload,
    [ setUnsavedClients ]:      setUnsavedClientReducer,
    [ resetFoundChanges ]:      resetFoundChangesReducer,
    [ setNewClients ]:          setNewClientsReducer,
    [ setUnsavedUsers ]:        setUnsavedUsersReducer,
    [ setNewUsers ]:            setNewUsersReducer,
    [ setTotalClientCount ]:    ( draft, payload ) => draft.totalClientCount = parseInt( payload, 10 ),
    [ setTotalUserCount ]:      ( draft, payload ) => draft.totalUserCount = payload,
    [ setTotalSiteCount ]:      ( draft, payload ) => draft.totalSiteCount = payload,
    [ setCurrentPage ]:         ( draft, payload ) => draft.currentPage = payload,
    [ setCurrentUserPage ]:     ( draft, payload ) => draft.currentUserPage = payload,
    [ setCurrentSitePage ]:     ( draft, payload ) => draft.currentSitePage = payload,
    [ setUnsavedSite ]:         setUnsavedSitesReducer,
    [ setNewSite ]:             setNewSitesReducer,
    [ setUserFilterString ]:    ( draft, payload ) => draft.userFilterString = payload,
    [ setSiteFilterString ]:    ( draft, payload ) => draft.siteFilterString = payload,
    [ setClientSettings ]:      genericReducer
};

export {
    getPage,
    getStatuses,
    statusList,
    getClientLocation,
    getClientUsers,
    setClientFilterString,
    setUserFilterString,
    setSiteFilterString,
    setUnsavedClients,
    saveClientChanges,
    setNewClients,
    setUnsavedUsers,
    saveUserChanges,
    setNewUsers,
    setNewSite,
    setUnsavedSite,
    saveSiteChanges,
    continueNavigation,
    continueUserNavigation,
    continueSiteNavigation

};
export default ezRedux( reducers, initialState );
