const isTokenExpired = token => {
    if ( !token ) return true;
    // Split the token into its three parts (header, payload, and signature) based on the dot ('.') separator.
    // Decode the payload using atob to convert it from base64 to a JSON string.
    // Compare the 'exp' claim with the current timestamp to determine if the token is expired.
    const expiry = ( JSON.parse( atob( token.split( '.' )[ 1 ]) ) ).exp;
    return ( Math.floor( ( new Date() ).getTime() / 1000 ) ) >= expiry;
};

export {
    isTokenExpired
};
