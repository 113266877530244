/*********************************************************************************************************************
 * @file TeammateCalendarHeader component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React from 'react';

import { Priority } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PEGBOARD_SLOTS } from '../../constants';
import { getJobFromState, getJobCompany } from '../../redux/selectors';
import { 
    TeammateCalendarHeaderMain, avatar, pegboardItem, timerPaused, topLine, topLineText, runningIcon, detail, blankItem, snoozeIcon, userName
} from '../../scss/TeammateCalendarHeader.module.scss';
import { priorityColors, priorityBorderColors } from '../../utils/misc.js';
import { LicoIcon } from '../common';
import LicoAvatar from '../common/LicoAvatar.jsx';

import { selectUserCalendarState } from './reducer';

const TeammatePegboardCard = ({ job, company, timeLog }) => {

    const { pausedAt, start } = timeLog;
    const { title, description, priority } = job;
    const { companyName } = company;

    const priorityValue = priority || Priority.normal;
    const priorityColor = priorityColors[ priorityValue ];
    const priorityBorder = priorityBorderColors[ priorityValue ];

    const subjectText = companyName || title || description;
    const detailText = companyName 
        ? title || description
        : title 
            ? description 
            : "";

    const timerRunning = pausedAt === null && !!start;

    return <div key={timeLog.timeLogId} className={`${pegboardItem}
            ${priorityBorder} 
            ${timerRunning ? '' : timerPaused}
        `}>
        <div className={topLine}>
            <div className={topLineText}>{subjectText}</div>
            {timerRunning && <LicoIcon icon="person-running" className={`${runningIcon} ${priorityColor}`} />}
        </div>
        <div className={`${detail} ${priorityColor}`}>{detailText}</div>
    </div>;
};
TeammatePegboardCard.propTypes = {
    job:            PropTypes.object.isRequired,
    company:        PropTypes.object,
    timeLog:        PropTypes.object.isRequired
};

const BlankPegboardCard = () => <div className={blankItem}><div className={snoozeIcon}><LicoIcon icon="snooze"  /></div></div>;

const TeammateCalendarHeader = ({
    user,
    pegboardItems
}) => (
    <div className={TeammateCalendarHeaderMain}>
        <div className={avatar}>
            <span style={{ marginRight: 10 }}>
                <LicoAvatar gravatarEmail={user.preferences.gravatarEmail} avatar={user.avatar} className="small" />
            </span>
            <span className={userName}>{user.name}</span>
        </div>
        {new Array( PEGBOARD_SLOTS )
            .fill( true )
            .map( ( v, i ) => pegboardItems[ i ])
            .map( ( item, i ) => i < pegboardItems.length
                ? <TeammatePegboardCard 
                    key={item.timeLog.timeLogId} 
                    job={item.job} 
                    company={item.company} 
                    timeLog={item.timeLog} /> 
                : <BlankPegboardCard key={i} />
            )
        }
    </div>
);

TeammateCalendarHeader.propTypes = {
    user:                   PropTypes.any.isRequired,
    pegboardItems:          PropTypes.array
};

const mapStateToProps = ( state, props ) => { 
    const { pegboardItems } = selectUserCalendarState( state, props.user.userId );

    const items = pegboardItems.map( item => ({
        timeLog:        item.timeLog,
        job:            getJobFromState( state, item.realJobId ),
        company:        getJobCompany( state, item.realJobId )
    }) );

    return {
        pegboardItems:  items
    };

};

export default connect( mapStateToProps )( TeammateCalendarHeader );
export { TeammateCalendarHeader };
