/*********************************************************************************************************************
 * @file Auth reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 2-Dec-2020
 *********************************************************************************************************************/
import { setAuth } from '../../redux/actions/index.js';
import { ezRedux, genericReducer } from '../../redux/reducerUtil.js';
import { getAuth } from "../../utils/local-storage.js";
/**
 * @typedef {object} AuthState
 * @property {string} token
 * @property {boolean} rememberSession
 */

/**
  * @type {any | null}
  */
const authState = getAuth();

const initialState = Object.freeze({
    rememberSession:   authState?.rememberSession ?? false,
    token:             authState?.token ?? '',
    userInitState:     authState?.userInitState ?? {}
});



const reducers = {
    [ setAuth ]:                genericReducer
};


/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
