/** ******************************************************************************************************************
 * @file Traditional doubly linked list, masquerading as a standard JavaScript collection.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 22-12-2023
 *********************************************************************************************************************/
import { IterableElementBase } from './iterable-base.js';

class LinkedListNode
{
    value;
    next;
    prev;
    #list;

    constructor( linkedList, value = null, next = null, prev = null )
    {
        this.#list = linkedList;
        this.value = value;
        this.next = next;
        this.prev = prev;
    }

    get list()
    {
        return this.#list;
    }

    // We are the previous node
    insertAfter( value )
    {
        return this.list.insertAfter( this, value );
    }

    // We are the next node
    insertBefore( value )
    {
        return this.list.insertBefore( this, value );
    }

    unlinkNode()
    {
        return this.list.unlinkNode( this );
    }

    moveToHead()
    {
        return this.list.moveNodeToHead( this );
    }

    valueOf()
    {
        return this.value;
    }

    toString()
    {
        return `${this.value}`;
    }

    [ Symbol.toPrimitive ]( hint )
    {
        return hint === 'number' ? Number( this.valueOf() ) : this.toString();
    }
}

class LinkedList extends IterableElementBase
{
    head = null;
    tail = null;
    size = 0;

    constructor( src = null )
    {
        super();

        if ( Array.isArray( src ) )
            for ( const value of src.toReversed() ) this.insert( value );
        else if ( src instanceof LinkedList )
        {
            let p = src.lastNode;

            while ( p )
            {
                this.insert( p.value );
                p = p.prev;
            }
        }
    }

    static fromArray( arr )
    {
        return new LinkedList( arr );
    }

    copy()
    {
        return new LinkedList( this );
    }

    moveNodeToHead( node )
    {
        if ( !( node instanceof LinkedListNode ) )
            throw new Error( `insertNode() takes a 'LinkedListNode' argument. Did you mean to call insert() instead?` );

        if ( node.list !== this )
            throw new Error( `insertNode() takes a 'LinkedListNode' argument that belongs to this list.` );

        if ( node === this.head ) return node;

        if ( this.tail === node ) this.tail = node.prev;

        if ( node.next ) node.next.prev = node.prev;
        if ( node.prev ) node.prev.next = node.next;

        node.prev = null;
        node.next = this.head;
        this.head = node;

        if ( node.next )
            node.next.prev = node;
        else
            this.tail = node;

        return node;
    }

    insert( data )
    {
        const newNode = this.head =
            new LinkedListNode( this, data instanceof LinkedListNode ? data.value : data, this.head );

        if ( newNode.next )
            newNode.next.prev = newNode;
        else
            this.tail = newNode;

        ++this.size;

        return newNode;
    }

    insertAfter( prevNode, data )
    {
        if ( !prevNode )
            throw new Error( "Previous node cannot be null" );

        const newNode = new LinkedListNode( this,
            data instanceof LinkedListNode ? data.value : data,
            prevNode.next,
            prevNode );

        prevNode.next = newNode;

        if ( newNode.next )
            newNode.next.prev = newNode;

        if ( this.tail === prevNode )
            this.tail = newNode;

        ++this.size;

        return newNode;
    }

    insertBefore( nextNode, data )
    {
        if ( !nextNode )
            throw new Error( "Next node cannot be null" );

        const newNode = new LinkedListNode( this,
            data instanceof LinkedListNode ? data.value : data,
            nextNode,
            nextNode.prev );

        nextNode.prev = newNode;

        if ( newNode.prev )
            newNode.prev.next = newNode;

        if ( this.head === nextNode )
            this.head = newNode;

        ++this.size;

        return newNode;
    }

    insertEnd( data )
    {
        const newNode = new LinkedListNode( this, data instanceof LinkedListNode ? data.value : data );

        if ( !this.head )
            this.head = this.tail = newNode;
        else
        {
            this.tail.next = newNode;

            newNode.prev = this.tail;

            this.tail = newNode;
        }

        ++this.size;

        return newNode;
    }

    unlinkNode( delNode )
    {
        // if head or del is null, deletion is not possible
        if ( !this.head || !delNode ) return;

        if ( this.head === delNode ) this.head = delNode.next;
        if ( this.tail === delNode ) this.tail = delNode.prev;

        if ( delNode.next ) delNode.next.prev = delNode.prev;
        if ( delNode.prev ) delNode.prev.next = delNode.next;

        --this.size;

        delNode.next = delNode.prev = null;

        return delNode;
    }

    /**
     * @return {IterableIterator<E>}
     * @private
     */
    *_getIterator()
    {
        let current = this.head;

        while ( current )
        {
            yield current.value;
            current = current.next;
        }
    }

    toString()
    {
        let node = this.head;

        let s = [];

        while ( node )
        {
            s.push( `${node}` );
            node = node.next;
        }

        return '[ ' + s.join( ', ' ) + ' ]';
    }

    get first()
    {
        return this.head?.value;
    }

    get last()
    {
        return this.tail?.value;
    }

    get firstNode()
    {
        return this.head;
    }

    get lastNode()
    {
        return this.tail;
    }

    push( value )
    {
        return this.insertEnd( value );
    }

    pop()
    {
        if ( !this.head ) return;

        const node = this.tail;

        this.unlinkNode( node );

        return node.value;
    }

    shift()
    {
        if ( !this.head ) return;

        const node = this.head;

        this.unlinkNode( node );

        return node.value;
    }

    unshift( value )
    {
        return this.insertBefore( this.head, value );
    }

    nodeAt( index )
    {
        if ( index < 0 || index >= this.size ) return;

        let current = this.head;

        while ( index-- > 0 ) current = current.next;

        return current;
    }

    at( index )
    {
        return this.nodeAt( index )?.value;
    }

    #finder( fn, found, notFoundValue = void 0, startAt = 0 )
    {
        if ( this.size === 0 ) return found( notFoundValue, notFoundValue );

        let index = 0, node = this.head;

        while ( node )
        {
            if ( index < startAt )
            {
                ++index;
                node = node.next;
                continue;
            }

            if ( fn( node, index++, this ) )
                return found( node, index - 1 );
            node = node.next;
        }

        return found( notFoundValue, notFoundValue );
    }

    #revfinder( fn, found, notFoundValue = void 0, startAt = this.size - 1 )
    {
        if ( this.size === 0 ) return found( notFoundValue, notFoundValue );

        let index = this.size - 1, node = this.tail;

        while ( node )
        {
            if ( index > startAt )
            {
                --index;
                node = node.prev;
                continue;
            }

            if ( fn( node, index--, this ) ) return found( node, index + 1 );
            node = node.prev;
        }

        return found( notFoundValue, notFoundValue );
    }

    findNode( fn, thisArg = null )
    {
        return this.#finder( fn.bind( thisArg ), node => node );
    }

    findNodeIndex( fn, thisArg = null, startAt = 0 )
    {
        if ( typeof thisArg === 'number' )
            [ thisArg, startAt ] = [ null, thisArg ];

        return this.#finder( fn.bind( thisArg ), ( node, index ) => index, -1, startAt );
    }

    findLastNodeIndex( fn, thisArg = null, startAtIndex = this.size - 1 )
    {
        if ( typeof thisArg === 'number' )
            [ thisArg, startAtIndex ] = [ null, thisArg ];

        return this.#revfinder( fn.bind( thisArg ), ( node, index ) => index, -1, startAtIndex );
    }

    findIndex( fn, thisArg = null, startAt = 0 )
    {
        if ( typeof thisArg === 'number' )
            [ thisArg, startAt ] = [ null, thisArg ];

        return this.#finder( ( node, i, self ) => fn.call( thisArg, node.value, i, self ), ( node, index ) => index, -1, startAt );
    }

    findLastIndex( fn, thisArg = null, startAtIndex = this.size - 1 )
    {
        if ( typeof thisArg === 'number' )
            [ thisArg, startAtIndex ] = [ null, thisArg ];

        return this.#revfinder( ( node, i, self ) => fn.call( thisArg, node.value, i, self ), ( node, index ) => index, -1, startAtIndex );
    }

    insertAt( index, value )
    {
        if ( index < 0 || index > this.size ) return;

        if ( index === 0 )
            return this.insert( value );
        else if ( index === this.size )
            return this.insertEnd( value );
        else
            return this.insertAfter( this.nodeAt( index - 1 ), value );
    }

    unlinkAt( index )
    {
        if ( index < 0 || index >= this.size ) return;

        if ( index === 0 )
            return this.shift();
        else if ( index === this.size - 1 )
            return this.pop();

        return this.unlinkNode( this.nodeAt( index ) ).value;
    }

    isEmpty()
    {
        return this.size === 0;
    }

    get empty()
    {
        return this.size === 0;
    }

    clear()
    {
        this.head  = this.tail = undefined;
        this.size = 0;
    }

    indexOfNode( node, startAt = 0 )
    {
        return this.findNodeIndex( n => n === node, startAt );
    }

    indexOf( value, startAt = 0 )
    {
        return this.findIndex( n => n === value, startAt );
    }

    lastIndexOf( value, startAtIndex = this.size - 1 )
    {
        return this.findLastIndex( n => n === value, startAtIndex );
    }

    reverse( inPlace = false )
    {
        if ( !inPlace )
        {
            const ll = new LinkedList();
            let p    = this.head;

            while ( p )
            {
                ll.insert( p.value );
                p = p.next;
            }

            return ll;
        }

        let current = this.head;

        [ this.head, this.tail ] = [ this.tail, this.head ];

        while ( current )
        {
            const next                     = current.next;
            [ current.prev, current.next ] = [ current.next, current.prev ];
            current                        = next;
        }

        return this;
    }

    toArray()
    {
        let p = this.head;

        return Array.from({ length: this.size }, () => { const v = p.value; p = p.next; return v; });
    }

    filter( fn, context = null )
    {
        const ll = new LinkedList();

        let p = this.head, index = 0;

        while ( p )
        {
            if ( fn.call( context, p.value, index++, this ) ) ll.insertEnd( p.value );
            p = p.next;
        }

        return ll;
    }

    map( fn, context = null )
    {
        const ll = new LinkedList();

        let p = this.head, index = 0;

        while ( p )
        {
            ll.insertEnd( fn.call( context, p.value, index++, this ) );
            p = p.next;
        }

        return ll;
    }
}

LinkedList.prototype.deleteNode = LinkedList.prototype.unlinkNode;

export { LinkedList, LinkedListNode };
