/********************************************************************************************************************
 * @file Actions for the engineer store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setEngineers = createAction( 'ENGINEER.ENGINEERS/SET' );
export const setFilterString = createAction( 'ENGINEER.FILTER_STRING/SET' );
export const setEngineerField = createAction( 'ENGINEER.ENGINEER_FIELD/SET' );
export const setEngineer = createAction( 'ENGINEER.ENGINEER/SET' );
export const setEngineerSettings = createAction( 'ENGINEER.SETTINGS/SET' );
export const discardAllChanges = createAction( 'ENGINEER.DISCARD_CHANGES/SET' );
export const recordInvitation = createAction( 'ENGINEER.RECORD_INVITATION/SET' );
export const addEngineer = createAction( 'ENGINEER.ADD/SET' );
