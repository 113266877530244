/** ******************************************************************************************************************
 * @file Connector constants.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 20-Dec-2020
 *********************************************************************************************************************/
import { Coms } from '../coms.js';

const CONNECTWISE = 'connectwise';
const CONNECTWISE_NAME = 'ConnectWise';

const MAX_ENTRIES = Symbol.for( 'max_entries' );
const MAX_PAGE_SIZE = 1000;
const IMPORT_PAGE_SIZE = MAX_PAGE_SIZE;

const MEDIUM = 'Medium';
const LOW    = 'Low';
const ST = 'ST Task';

const PROGRESS_TAIL = '-progress';

const NOT_SCHEDULED_INDEX = 0;
const SCHEDULED_INDEX = 1;
const IN_PROGRESS_INDEX = 2;
const WAIT_CLIENT_INDEX = 3;
const WAIT_PARTS_INDEX = 4;
const WAIT_THIRD_PARTY_INDEX = 5;
const REOPENED_JOB_INDEX = 6;
const CANCELLED_JOB_INDEX = 7;
const RESOLVED_JOB_INDEX = 8;
const UNMAPPED_STATUS_INDEX = 9;

const MAX_LICORICE_STATUS = UNMAPPED_STATUS_INDEX;
const LICORICE_STATUS_LENGTH = MAX_LICORICE_STATUS + 1;

const BILLING = 'billing';
const BOARD = 'board';
const JOB_STATUS = 'jobStatus';
const PRIORITY = 'priority';
const COMPANY_STATUS = 'companyStatus';

const LOCATION = 'location';
const DEPARTMENT = 'department';
const WORKROLE = 'workRole';
const WORKTYPE = 'workType';
const BILLING_TYPE = 'billingType';

const DEFAULT_BOARD = BOARD;

const HIGH = 'high';
const CRITICAL = 'critical';
const NORMAL = 'normal';

const PERSONAL = 'personalcardtype';
const INTERNAL = 'internalcardtype';

const COMS = 'coms';

const SCHEDULE = 'schedule';
const IMPORTED_STATUSES = 'importedCompanyStatuses';

const HIDDEN_COMPANY_STATUS = 'hiddenCompanyStatus';
const HIDDEN_JOB_STATUS = 'hiddenJobStatus';

const OTHER_NEW_JOB_STATUS_LIST = 'otherNewJobStatusList';
const OTHER_RESOLVED_JOB_STATUS_LIST = 'otherResolvedJobStatusList';

// this is a licoriceName.name value which may be displayed, hence title case
const UNMAPPED_NAME = 'Unmapped';
const otherDesc = 'All provider statuses mapped to this name will map to our New status on import from provider';
const resolvedDesc = 'All provider statuses mapped to this name will map to our Resolved status on import from provider';
/**
 * This consolidates all the predefined Licorice internal fields. Note that some of these fields are used to initialize
 * other working variables; not everything goes into licoriceName.
 *
 * @type {RawLicoriceName[]}
 */
const rawLicoriceNameData = [
    { name: 'Internal Meeting',                 description: '', typeName: INTERNAL },
    { name: 'Internal Technical',               description: '', typeName: INTERNAL },
    { name: 'Training',                         description: '', typeName: INTERNAL },
    { name: 'Do Not Disturb',                   description: '', typeName: INTERNAL },
    { name: UNMAPPED_NAME,                      description: "Unmapped internal card type", typeName: INTERNAL, unmapped: true },

    { name: 'Sick Leave',                       description: '', typeName: PERSONAL },
    { name: 'Vacation Leave',                   description: '', typeName: PERSONAL },
    { name: 'Public Holiday',                   description: '', typeName: PERSONAL },
    { name: 'Break/Lunch',                      description: '', typeName: PERSONAL },
    { name: 'Personal Todo',                    description: '', typeName: PERSONAL },
    { name: UNMAPPED_NAME,                      description: "Unmapped personal card type", typeName: PERSONAL, unmapped: true },

    { name: 'Regular (business hours)',         description: '', typeName: BILLING_TYPE, billingTypeKey: 'REGULAR' },
    { name: 'After-hours',                      description: '', typeName: BILLING_TYPE, billingTypeKey: 'AFTER_HOURS' },
    { name: 'Not-billable',                     description: '', typeName: BILLING_TYPE, billingTypeKey: 'NOT_BILLABLE' },
    { name: 'Gratis',                           description: '', typeName: BILLING_TYPE, billingTypeKey: 'GRATIS' },
    { name: UNMAPPED_NAME,                      description: "Unmapped work type", typeName: BILLING_TYPE, billingTypeKey: 'UNMAPPED', unmapped: true },

    { name: "Not Scheduled",                    description: '', typeName: JOB_STATUS, statusKey: "NOT_SCHEDULED" },
    { name: "Scheduled",                        description: '', typeName: JOB_STATUS, statusKey: "SCHEDULED" },
    { name: "In Progress...",                   description: '', typeName: JOB_STATUS, statusKey: "IN_PROGRESS" },
    { name: "Waiting on Client...",             description: '', typeName: JOB_STATUS, statusKey: "WAIT_CLIENT" },
    { name: "Waiting on Parts...",              description: '', typeName: JOB_STATUS, statusKey: "WAIT_PARTS" },
    { name: "Waiting on Third Party...",        description: '', typeName: JOB_STATUS, statusKey: "WAIT_THIRD_PARTY" },
    { name: "Reopened",                         description: '', typeName: JOB_STATUS, statusKey: "REOPENED_JOB" },
    { name: "Cancelled",                        description: '', typeName: JOB_STATUS, statusKey: "CANCELLED_JOB" },
    { name: "Resolved Job",                     description: '', typeName: JOB_STATUS, statusKey: "RESOLVED_JOB" },
    { name: "Unmapped Status",                  description: 'Unmapped job status', typeName: JOB_STATUS, statusKey: "UNMAPPED_STATUS", unmapped: true },

    { name: 'Billing Location',                 description: '', typeName: LOCATION, singleMapping: true },
    { name: 'Billing Department',               description: '', typeName: DEPARTMENT, singleMapping: true },
    { name: 'Billing Work Role',                description: '', typeName: WORKROLE, singleMapping: true },
    { name: 'Default Board',                    description: '', typeName: BOARD, singleMapping: true },
    { name: 'Critical',                         description: '', typeName: CRITICAL, singleMapping: true },
    { name: 'High',                             description: '', typeName: HIGH, singleMapping: true },
    { name: 'Normal',                           description: '', typeName: NORMAL, singleMapping: true },
    { name: 'List all other New statuses',      description: otherDesc, typeName: OTHER_NEW_JOB_STATUS_LIST, data: { linkedField: true }, singleMapping: true },
    { name: 'List all other Resolved statuses', description: resolvedDesc, typeName: OTHER_RESOLVED_JOB_STATUS_LIST, data: { linkedField: true }, singleMapping: true },
    { name: Coms.Types.PHONE,                   description: Coms.PhoneLabels.HOME,    typeName: COMS, singleMapping: true },
    { name: Coms.Types.PHONE,                   description: Coms.PhoneLabels.MOBILE,  typeName: COMS, singleMapping: true },
    { name: Coms.Types.PHONE,                   description: Coms.PhoneLabels.WORK,    typeName: COMS, singleMapping: true },
    { name: Coms.Types.PHONE,                   description: Coms.PhoneLabels.MAIN,    typeName: COMS, singleMapping: true },
    { name: Coms.Types.PHONE,                   description: Coms.PhoneLabels.PAGER,   typeName: COMS, singleMapping: true },
    { name: Coms.Types.PHONE,                   description: Coms.PhoneLabels.CUSTOM,  typeName: COMS, singleMapping: true },

    { name: Coms.Types.EMAIL,                   description: Coms.EmailLabels.HOME,    typeName: COMS, singleMapping: true },
    { name: Coms.Types.EMAIL,                   description: Coms.EmailLabels.WORK,    typeName: COMS, singleMapping: true },
    { name: Coms.Types.EMAIL,                   description: Coms.EmailLabels.MOBILE,  typeName: COMS, singleMapping: true },
    { name: Coms.Types.EMAIL,                   description: Coms.EmailLabels.CUSTOM,  typeName: COMS, singleMapping: true },

    { name: Coms.Types.FAX,                     description: Coms.FaxLabels.WORK,      typeName: COMS, singleMapping: true },
    { name: Coms.Types.FAX,                     description: Coms.FaxLabels.HOME,      typeName: COMS, singleMapping: true },
    { name: Coms.Types.FAX,                     description: Coms.FaxLabels.CUSTOM,    typeName: COMS, singleMapping: true },

    { name: Coms.Types.WEB,                     description: Coms.WebLabels.PERSONAL,  typeName: COMS, singleMapping: true },
    { name: Coms.Types.WEB,                     description: Coms.WebLabels.BUSINESS,  typeName: COMS, singleMapping: true },
    { name: Coms.Types.WEB,                     description: Coms.WebLabels.CUSTOM,    typeName: COMS, singleMapping: true },

    { name: Coms.Types.SOCIAL,                  description: Coms.SocialLabels.CUSTOM, typeName: COMS, singleMapping: true }
];

const StatusNames = rawLicoriceNameData.filter( name => name.statusKey ).reduce( ( acc, cur ) => {
    acc[ cur.statusKey ] = cur.name;
    return acc;
}, {});
const StatusNameList = Object.values( StatusNames );

// This is the company status data for use in Standalone systems (copied from Firehazard). It doesn't appear 
// in the mapping screen so has none of the fields seen in rawLicoriceNameData that are related to that.
// This data is loaded directly into licoriceName when standalone is selected.
const rawCompanyStatusNameData = [
    {
        name:        "Active",
        description: "",
        data:        '{"defaultFlag": true, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Inactive",
        description: "Verify we can provide service to this company.  This company is no longer an active customer.",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Imported",
        description: "Establish a valid Company Status for this company. Also make sure they are fully setup.",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Credit Hold",
        description: "Do NOT Service. Ask for payment.\nsee activity tab for details and\ncontact the account manager",
        data:        '{"defaultFlag": false, "disallowSavingFlag": true}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Problem",
        description: "A problem exists with this client that you should be aware of. Service is approved but make sure you know about the problem by check with the account",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Not-Approved",
        description: "Do not Service\nthey have not been setup for Service yet\ncheck with their account manager",
        data:        '{"defaultFlag": false, "disallowSavingFlag": true}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Solid",
        description: "",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Attention needed",
        description: "Client needs Attention!\nsee Activity tab for details",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "May Leave",
        description: "Client may Leave us.  \nSee activity tab for details",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Delinquent",
        // eslint-disable-next-line max-len
        description: "Payments from this client are delinquent.  Service is still approved for this client. We may want to pick up a check. Check with the account manager and review the Activity tab for clarification. ",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    },
    {
        name:        "Active (Casual)",
        description: "Have we been authorised to work on this ticket?",
        data:        '{"defaultFlag": false, "disallowSavingFlag": false}',
        typeName:    COMPANY_STATUS
    }
];


// we have to correlate some UI settings to billing type, and I don't want to specify it in licoriceName.data due
// to how much there is, and it includes processed CSS classnames, etc. Store the billing type names in a constant and
// use them to link the billing type mappings with the UI data, eg uiData = { [ BillingTypeNames.REGULAR ]: { regular data }, etc }.
const BillingTypeNames = rawLicoriceNameData.filter( name => name.billingTypeKey ).reduce( ( acc, cur ) => {
    acc[ cur.billingTypeKey ] = cur.name;
    return acc;
}, {});

const integrationNames = {
    BILLING,
    BOARD,
    JOB_STATUS,
    PRIORITY,
    COMPANY_STATUS,

    LOCATION,
    DEPARTMENT,
    WORKROLE,
    WORKTYPE,
    BILLING_TYPE,

    DEFAULT_BOARD,

    HIGH,
    CRITICAL,
    NORMAL,

    PERSONAL,
    INTERNAL,
    SCHEDULE,

    COMS,

    IMPORTED_STATUSES,
    HIDDEN_COMPANY_STATUS,
    HIDDEN_JOB_STATUS,

    OTHER_NEW_JOB_STATUS_LIST,
    OTHER_RESOLVED_JOB_STATUS_LIST
};

const LABEL_CHIP = 'labelChip';
const integrationFieldType = {
    NORMAL,
    LABEL_CHIP
};

const groupNames = [
    'converters',
    'users',
    'companies',
    'jobs',
    'administrative',
    'notes',
    'timeEntries',
    'callbacks',
    'schedules',
    'status',
    'system'
];

export {
    PROGRESS_TAIL,
    groupNames,
    integrationNames,
    integrationFieldType,

    rawLicoriceNameData,
    rawCompanyStatusNameData,
    StatusNames,
    StatusNameList,

    BillingTypeNames,

    NOT_SCHEDULED_INDEX,
    SCHEDULED_INDEX,
    IN_PROGRESS_INDEX,
    CANCELLED_JOB_INDEX,
    WAIT_CLIENT_INDEX,
    WAIT_PARTS_INDEX,
    WAIT_THIRD_PARTY_INDEX,
    REOPENED_JOB_INDEX,
    RESOLVED_JOB_INDEX,
    UNMAPPED_STATUS_INDEX,

    MAX_LICORICE_STATUS,
    LICORICE_STATUS_LENGTH,

    CONNECTWISE,
    CONNECTWISE_NAME,
    MEDIUM,
    LOW,
    ST,
    PERSONAL,
    INTERNAL,
    BILLING_TYPE,
    MAX_ENTRIES,
    MAX_PAGE_SIZE,
    IMPORT_PAGE_SIZE,
    UNMAPPED_NAME
};
