/**
 * find out the next active appt
 *
 * @param {Database.Appointment[]} appointments
 * @returns {{nextActiveAppointment: undefined, nextActiveSTTIndex: number, nextIndex: number, nextActiveSTTAppointment: undefined}}
 */
import { DAY } from '@licoriceio/constants';

export function findNextActiveAppointment( appointments )
{
    const now = new Date();
    let nextIndex = -1;
    let nextAllDayIndex = -1;
    let nextActiveAppointment;
    let nextActiveSTTIndex = -1;
    let nextActiveSTTAppointment;

    try {
        if ( appointments )
        {
            // process list in reverse so we go from earliest to latest, so we find the earliest appt past "now"
            for ( let i = appointments.length - 1; i >= 0; i-- )
            {
                const appt = appointments[ i ];
                const start = new Date( appt.startDate );
                const startTime = start.getTime();
                const nextDay = dt => new Date( dt ).getTime() + DAY;

                if ( appt.state === "ACTIVE" && start > now )
                {
                    if ( appt.endDate )
                    {
                        // if we've already found an all-day event, this event has to be on the same day to override it.
                        // eslint-disable-next-line max-depth
                        if ( nextAllDayIndex === -1 || startTime < nextDay( appointments[ nextAllDayIndex ].startDate ) )
                            nextIndex = i;

                        // having found a timed appt, we stop either way; either it overrode a hypothetical all day
                        // appt or it didn't, further timed appts are later and so can't change the result
                        break;
                    }
                    else if ( nextAllDayIndex === -1 ) 
                        nextAllDayIndex = i;
                }
                // see if any valid some time today appointment
                else if ( appt.state === 'ACTIVE' && !appt.endDate && now.getTime() >= startTime && now.getTime() < startTime + DAY )
                    nextActiveSTTIndex = i;
            }
        }
        
        // if we found an all day appt but not a timed appt, highlight the all day appt
        if ( nextIndex === -1 && nextAllDayIndex > -1 )
            nextIndex = nextAllDayIndex;   
        
        if ( nextIndex >= 0 && appointments && appointments.length ) 
            nextActiveAppointment = appointments[ nextIndex ];

        if ( nextActiveSTTIndex >= 0 && appointments && appointments.length ) 
            nextActiveSTTAppointment = appointments[ nextActiveSTTIndex ];
        
    }
    catch ( error )
    {
        error.message = 'Encountered error in findNextActiveAppointment: ' + error.message;
        console.error( error );
    }

    return {
        nextIndex,
        nextActiveAppointment,
        nextActiveSTTIndex,
        nextActiveSTTAppointment
    };
}
