/** ******************************************************************************************************************
 * @file Integration updates.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 19-May-2021
 *********************************************************************************************************************/

import { initUserSession } from '../components/calendar/sharedThunks.js';
import { getDirectUrl } from '../components/settings/organisation/reducer.js';
import { uri } from '../constants.js';
import { store } from '../publicStore.js';
import { setError, setIntegrationProgress, setNotification, setProvider } from '../redux/actions/index.js';
import { getNamesThunk } from '../redux/reducers/names.js';
import { createFatalError } from '../services/util/baseRequests.js';

const dispatch = obj => store.dispatch( obj );

const integrationComplete = async notification => {
    dispatch( setNotification( notification ) );

    // mark provider as integrated
    dispatch( setProvider({ integrated: true }) );

    // mappings are loaded on login on an integrated system, but we need to load them directly after integration
    // as well.
    dispatch( getNamesThunk() );

    // we get most configuration on login, but the provider job url is only available after integration
    dispatch( getDirectUrl( notification.data ) );

    dispatch( initUserSession() );
};

const integrationUpdate = n => {
    const {
        stage,
        stageLast,
        progressForStage: {
            current,
            total
        }
    } = n.data;

    console.log( `Integration update: ${n.status}, stage ${stage}/${stageLast}, progress ${current}/${total}` );
    dispatch( setIntegrationProgress( n.data ) );
};

const integrationFailed = n => dispatch( setError({
    uri:   uri.PERFORM_INTEGRATION,
    error: createFatalError( n.data?.error )
}) );

export {
    integrationComplete,
    integrationUpdate,
    integrationFailed
};
