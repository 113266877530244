import React from 'react';

import { MenuItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const NavMenuListLinkItem = ({ text, to, dataName }) => (
    <MenuItem component={Link} data-ux={dataName} to={to}>
        <ListItemText inset primary={text} disableTypography />
    </MenuItem>
);

NavMenuListLinkItem.propTypes = {
    to:       PropTypes.string.isRequired,
    text:     PropTypes.string.isRequired,
    dataName: PropTypes.string
};
