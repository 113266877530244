/** ******************************************************************************************************************
 * @file Fills out functions that are still in stage 3 (or 2 ).
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 10-Oct-2020
 *********************************************************************************************************************/

import { isFunc } from '../helpers/index.js';
import Zet from 'zet';

// noinspection CommaExpressionJS
const _ = typeof globalThis !== 'undefined' ? globalThis : ( 1, eval )( 'this' ); // eslint-disable-line no-eval

if ( !isFunc( Object.getPrototypeOf( Set ).symmetricDifference ) )
    _.Set = Zet;
