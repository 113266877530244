import React from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { root, message, bold } from '../../scss/SuccessPage.module.scss';
import { __ } from "../../utils/i18n.jsx";

import TopRow from './TopRow.jsx';


const SuccessPage = ({ job, engineerName, startTime }) => {
    return (
        <div className={root}>
            <TopRow job={job} />
            <div className={message}>
                {__( "Thank you, you have made an appointment and " )}
                <span className={bold}>{engineerName}</span>
                {__( " will see you on " )}
                <span className={bold}>{startTime.format( "dddd D-MMM" )}</span>
                {__( " at " )}
                <span className={bold}>{startTime.format( "h:mma" )}.</span>
            </div>
        </div>
    );
};

SuccessPage.propTypes = {
    job:          PropTypes.object.isRequired,
    engineerName: PropTypes.string.isRequired,
    startTime:    PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    job:          state.scheduling.job,
    engineerName: state.scheduling.engineerName,
    startTime:    dayjs( state.scheduling.startTime )
});

export default connect( mapStateToProps )( SuccessPage );
export { SuccessPage };
