import React from 'react';

import { MenuItem, ListItemText, Button } from '@mui/material';
import PropTypes from 'prop-types';

export const NavMenuListButtonItem = ({ text, onClick, children, ...rest }) => (
    <MenuItem  component={Button} onClick={onClick} {...rest}>
        {children}
        <ListItemText inset primary={text} disableTypography/>
    </MenuItem>
);

NavMenuListButtonItem.propTypes = {
    onClick:  PropTypes.func,
    text:     PropTypes.string.isRequired,
    children: PropTypes.node
};
