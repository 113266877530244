/** ******************************************************************************************************************
 * @file Description of this file goes here.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 19-May-2021
 *********************************************************************************************************************/

import { DELETE } from '@licoriceio/constants';
import databaseTables from '@licoriceio/relations';
import { has } from '@licoriceio/utils';

import { appointmentDataChangedThunk, appointmentRemovedThunk } from '../components/calendar/reducer.js';
import { setUpdateAppointmentStaticsThunk, getOtherUserTimeLogsRequest, updateMeters } from '../components/calendar/sharedThunks.js';
import { setAddAppointmentThunk } from '../components/calendar/thunks.js';
import { refreshCurrentJobNotes, getJobCardPeopleIfOpen } from '../components/jobcard/reducer.js';
import { checkForAddedJobThunk, getJobsForTab } from '../components/searchPanel/reducer.js';
import { mergeMode, snackbarKey } from '../constants.js';
import { store } from '../publicStore.js';
import { setUserFromSettings, noteDataDeleted, patchJobCardEngineers, patchJobCardUsers, setJobChange, setUpdateCompany, setAuth,
    setPatchCalendarEvent, setConnectorCalendarEvent, setDeleteCalendarEvent, setIncrementTimeEntered, setCacheRecord, patchOtherUserTimeLogs, 
    USER_LOGOUT, setMeta 
} from '../redux/actions/index.js';
import { cacheType, refreshCacheRecord } from '../redux/reducers/cache.js';
import { deleteJobThunk, confirmClientThunk } from '../redux/reducers/job.js';
import { sliceMergeAction } from '../redux/reducerUtil.js';
import { checkForPegboardChange } from '../redux/selectors/index.js';
import { getLoginUserId, log as _log } from '../utils/misc.js';

const log = _log( 'liveUpdates' );

const getId = ( type, obj ) => obj[ databaseTables[ type ].idColumn ];

const dispatch = obj => store.dispatch( obj );

const jumpTable = {
    user,
    timeLog,
    job,
    appointment,
    calendarEvent,
    schedule:          calendarEvent, // @todo check this out, "schedule" may need to stay plural if coming from CW
    note,
    company,
    userJob,
    token:     ({ data: { updated: [ payload ] } }) => dispatch( setAuth({ token: payload.token }) )
};

const dataRecordsUpdate = n => {

    log( `notification data update received:`, n );

    jumpTable[ n.data?.type ]?.( n );
};


function userJob( notification )
{
    // console.log( 'userJobs update', notification );

    // we only care about userJobs if the job card is open, and even then only if the userJob
    // refers to the open job. If the userJob change is a removal, we don't get the jobId so we can't check it,
    // and so I'm just reloading the list in all cases (if a card is open).
    dispatch( getJobCardPeopleIfOpen() );
}

function appointment( notification )
{
    // console.log( 'appointments update', notification );
    if ( notification.data.added )
        notification.data.added.map( a => dispatch( setAddAppointmentThunk( a ) ) );

    if ( notification.data.updated )
        notification.data.updated.map( a => dispatch( appointmentDataChangedThunk( a ) ) );

    if ( notification.data.removed )
        notification.data.removed.map( id => dispatch( appointmentRemovedThunk( id ) ) );

}

function calendarEvent( notification )
{
    // console.log( 'calendarEvents update', notification );
    const { added = [], updated = [], removed = [] } = notification.data;
    if ( added.length )
        added.forEach( a => dispatch( setConnectorCalendarEvent( a ) ) );
    if ( updated.length )
        updated.forEach( u => dispatch( setPatchCalendarEvent( u ) ) );
    if ( removed.length )
        removed.forEach( r => dispatch( setDeleteCalendarEvent({ calendarEventId: r }) ) );
}

function note( notification )
{
    // console.log( 'notes update', notification );
    const { added = [], updated = [], removed = [] } = notification.data;

    if ( added.length )
        added.forEach( n => dispatch( refreshCurrentJobNotes( n ) ) );
    if ( updated.length )
        updated.forEach( n => dispatch( refreshCurrentJobNotes( n ) ) );
    if ( removed.length )
        removed.forEach( n => dispatch( noteDataDeleted( n ) ) );
}

function company( notification )
{
    // console.log( 'companies update', notification );
    const { updated = [] } = notification.data;

    if ( updated.length ) {
        updated.forEach( company => {
            if ( has( company, 'companyStatus' ) ) 
                dispatch( refreshCacheRecord({ type: cacheType.COMPANY, id: company.companyId }) );
            if ( company.meta?.customNote ) 
                dispatch( setCacheRecord({ type: cacheType.COMPANY, id: company.companyId, payload: { customNote: company.meta.customNote } }) );
            if ( has( company, 'companyName' ) )
                dispatch( setUpdateCompany( company ) );
        });
        dispatch( setUpdateAppointmentStaticsThunk() );
    }

}

function job( notification )
{
    // console.log( 'job update', notification );
    const { added = [], updated = [], removed = [] } = notification.data;
    const allIds = added.map( _ => getId( 'job', _ ) ).concat( updated.map( _ => getId( 'job', _ ) ) ).concat( removed );

    const action = checkForPegboardChange({ jobIds: allIds }) && updateMeters;
    if ( action ) dispatch( action() );

    if ( added.length ) {
        added.forEach( job => {

            // jobs added from the integrator may have the company name in the job
            if ( has( job, 'companyName' ) ) {
                dispatch( setUpdateCompany({ companyId: job.companyId, companyName: job.companyName }) );
                delete job.companyName;
            }

            dispatch( setJobChange({ job, action: 'added' }) );
            if ( job.providerJobId )
                dispatch( checkForAddedJobThunk( job.providerJobId ) );
        });
    }

    if ( updated.length ) {
        updated.forEach( job => {

            // for now, updates from the backend will replace blank values but nothing else;
            // this can be refined in future to give the user a choice between frontend / backend / merged
            dispatch({ type: sliceMergeAction( 'job' ), payload: { slice: 'job', id: job.jobId, mode: mergeMode.NO_BLANKING, result: { payload: job } } });

            // if the job card is open, reload the people. We used to test for changes to the user list but changes to the company itself
            // should also trigger this
            dispatch( getJobCardPeopleIfOpen( ) );

            // changes to a job may include a new client which we currently don't have a name for;
            // just check every time, it's a very quick no-op if we don't need it.
            dispatch( confirmClientThunk( job.companyId ) );
        });
    }

    if ( removed.length )
        removed.forEach( job => dispatch( deleteJobThunk({ jobId: job, localOnly: true }) ) );

    dispatch( setUpdateAppointmentStaticsThunk() );
    dispatch( getJobsForTab() );
}

function timeLog( notification )
{
    // console.log( 'timeLogs update', notification );
    const userId = getLoginUserId();

    const { added = [], updated = [], removed = [] } = notification.data;

    const timeLogs = added.concat( updated ).concat( removed.map( id => ({ timeLogId: id }) ) );

    // process timelogs belonging to the current user
    const currentUserTLs = timeLogs.filter( tl => tl.userId === userId );

    const jobIds = currentUserTLs.map( o => o.jobId ).filter( Boolean );
    const timeLogIds = currentUserTLs.map( o => o.timeLogId ).filter( Boolean );

    const action = checkForPegboardChange({ timeLogIds, jobIds }) && updateMeters;
    if ( action ) dispatch( action() );

    // check updates for closed timeLogs belonging to us, and update Time Entered Today
    // if we find any. Closing timelogs on the PB doesn't return the old record which holds
    // the snapped duration value.
    updated.filter( tl => tl.timeLogId && tl.closed && tl.userId === userId )
        .forEach( tl => dispatch( setIncrementTimeEntered( tl ) ) );

    // timelogs belonging to other users are of interest to us if we have those users calendars open
    dispatch( patchOtherUserTimeLogs( updated ) );
    const otherUserAdded = added.filter( tl => tl.pegboard && tl.userId !== userId );
    if ( otherUserAdded.length > 0 ) {
        const otherCalendars = new Set( Object.keys( store.getState().calendar.userMap ).filter( id => id !== userId ) );
            
        for ( const tl of otherUserAdded ) {
            if ( otherCalendars.has( tl.userId ) ) {
                dispatch( getOtherUserTimeLogsRequest( tl.userId ) ); 

                // prevent multiple requests for the same user
                otherCalendars.delete( tl.userId );
            }
        }
    }
    
}

function user( notification )
{
    // console.log( 'users update', notification );
    const { updated = [], removed = [] } = notification.data;

    // user data (typically name) exist in state in various places
    if ( updated.length ) {

        // check if the logged-in user has been deactivated; that takes precedence over anything else
        const { user } = store.getState();
        const loginUserUpdate = updated.find( u => u.userId === user.userId );
        if ( loginUserUpdate && has( loginUserUpdate, 'active' ) && !loginUserUpdate.active ) {
            dispatch( setMeta({ [ snackbarKey.LOGGED_OUT_INACTIVE ]: true }) );
            setTimeout( () => {
                dispatch({ type: USER_LOGOUT });               
            }, 3000 );
            return;
        }
        if ( loginUserUpdate && has( loginUserUpdate, 'admin' ) && loginUserUpdate.admin !== user.admin ) {
            dispatch( setMeta({ [ user.admin ? snackbarKey.LOGGED_OUT_LOST_ADMIN : snackbarKey.LOGGED_OUT_GOT_ADMIN ]: true }) );
            setTimeout( () => {
                dispatch({ type: USER_LOGOUT });               
            }, 3000 );
            return;
        }

        dispatch( patchJobCardUsers({ data: { action: 'update', people: updated } }) );
        dispatch( patchJobCardEngineers({ data: { action: 'update', people: updated } }) );

        // the login user may have changed
        updated.forEach( user => dispatch( setUserFromSettings( user ) ) );
    }
    else if ( removed.length ) {
        dispatch( patchJobCardUsers({ data: { action: DELETE, people: updated } }) );
        dispatch( patchJobCardEngineers({ data: { action: DELETE, people: updated } }) );
    }
}

export {
    dataRecordsUpdate
};
