/** ******************************************************************************************************************
 * @file Some mathy functions.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 21-11-2021
 *********************************************************************************************************************/

const { sqrt, min: mmin, max: mmax, abs, round } = Math;

/**
 * A class for analyzing sequences of numbers.
 */
class SequenceAnalysis
{
    numbers;
    orgNumbers;
    #min;
    #max;
    #average;
    #mean;
    #variance;
    #stdDeviation;
    stack = [];

    /**
     * Creates a new SequenceAnalysis instance.
     *
     * @param {number[]} numbers - The numbers to analyze.
     */
    constructor( numbers )
    {
        this.numbers = this.orgNumbers = numbers.slice();
    }

    /**
     * Returns the minimum value in the sequence.
     *
     * @param {boolean} [noCache=false] - If true, does not use the cached value.
     * @returns {number} The minimum value.
     */
    min( noCache )
    {
        return noCache ? mmin( ...this.numbers ) : this.#min || ( noCache ? this.#min = mmin( ...this.numbers ) : mmin( ...this.numbers ) );
    }

    /**
     * Returns the maximum value in the sequence.
     *
     * @param {boolean} [noCache=false] - If true, does not use the cached value.
     * @returns {number} The maximum value.
     */
    max( noCache )
    {
        return noCache ? mmax( ...this.numbers ) : this.#max || ( noCache ? this.#max = mmax( ...this.numbers ) : mmax( ...this.numbers ) );
    }

    /**
     * Returns the average value of the sequence.
     *
     * @returns {number} The average value.
     */
    average()
    {
        return this.#average || ( this.#average = this.numbers.reduce( ( sum, v ) => sum + v ) / this.numbers.length );
    }

    /**
     * Returns the mean of the sequence.
     *
     * @returns {number[]} The mean of the sequence.
     */
    mean()
    {
        return this.#mean ||= ( this.#mean = this.numbers.map( n => n - this.average() ) );
    }

    /**
     * Returns the variance of the sequence.
     *
     * @returns {number} The variance of the sequence.
     */
    variance()
    {
        return this.#variance || ( this.#variance = this.mean().reduce( ( v, num ) => v + num ** 2 ) / this.numbers.length );
    }

    /**
     * Returns the standard deviation of the sequence.
     *
     * @returns {number} The standard deviation of the sequence.
     */
    stdDeviation()
    {
        return this.#stdDeviation ||= ( this.#stdDeviation = sqrt( this.variance() ) );
    }

    /**
     * Returns the standard deviation of the sequence.
     *
     * @param {number} stdDev - The standard deviation to use.
     * @returns {number[]} The standard deviation of the sequence.
     */
    sigma( stdDev )
    {
        return this.mean().map( v => abs( v / stdDev ) );
    }

    /**
     * Resets the sequence to the original numbers.
     *
     * @returns {SequenceAnalysis} This instance.
     */
    reset()
    {
        this.numbers = this.orgNumbers.slice();

        return this;
    }

    /**
     * Saves the current state of the sequence.
     *
     * @returns {SequenceAnalysis} This instance.
     */
    save()
    {
        this.stack.push( this.numbers.slice() );

        return this;
    }

    /**
     * Restores the state of the sequence to the previous saved state.
     *
     * @returns {SequenceAnalysis} This instance.
     */
    restore()
    {
        this.numbers = this.stack.pop();
    
        return this;
    }

    /**
     * Sets the numbers to analyze.
     *
     * @param {number[]} nums - The numbers to analyze.
     * @returns {SequenceAnalysis} This instance.
     */
    withNumbers( nums )
    {
        this.numbers = this.orgNumbers = nums.slice();
        this.#min = this.#max = this.#average = this.#mean = this.#variance = this.#stdDeviation = void 0;
    }

    /**
     * The `iqr` function calculates the interquartile range (IQR) of a sequence, which is a measure of the data spread.
     *
     * The code starts by sorting the numbers in the sequence and then checking if the lower and upper bounds have been
     * specified. If not, it calculates the interquartile range using the first and third quartiles and a factor that
     * determines the number of outliers.
     *
     * The code then drops any numbers from the sequence that are less than the lower bound or greater than the upper
     * bound. It then calculates the number of outliers that were dropped.
     *
     * Finally, the code returns an array containing the lower bound, upper bound, and number of outliers.
     *
     * @param {number} [lo] - The lower bound.
     * @param {number} [hi] - The upper bound.
     * @param {number} [factor=1.5] - The outlier factor.
     * @returns {number[]} An array containing the lower bound, upper bound, and number of outliers.
     */
    iqr( lo, hi, factor = 1.5 )
    {
        const sorted = this.numbers.slice().sort( ( a, b ) => Number( a ) - Number( b ) );

        if ( typeof lo !== 'number' )
        {
            const lng    = sorted.length;
            const mid    = ( lng + 1 ) >> 1;
            const q1i    = mid >> 1;
            const q3i    = mid + ( mid >> 1 );
            const q1     = sorted[ q1i ];
            const q3     = sorted[ q3i ];
            const iqr    = q3 - q1;
            lo     = q1 - factor * iqr;
            hi     = q3 + factor * iqr;
        }

        const dropped = sorted.filter( n => n <= lo || n >= hi );
        this.numbers = sorted.filter( n => n > lo && n < hi );

        return [ lo, hi, dropped.length ];
    }
}

/**
 * A function that analyzes a sequence of numbers using sub-sequences. The function takes two arguments: an array of
 * numbers and the number of sub-sequences to use.
 *
 * The code starts by checking if the length of the numbers array times two is less than the number of sub-sequences.
 * If this is the case, the function returns null, indicating that the number of sub-sequences is too large.
 *
 * Next, the code calculates the number of elements in each sub-sequence by dividing the length of the numbers array by
 * the number of sub-sequences. It then creates an array of sub-sequences by slicing the numbers array.
 *
 * The code then enters a loop that iterates over the sub-sequences. For each sub-sequence, it creates a new
 * SequenceAnalysis instance and calls the perSeq function.
 *
 * The perSeq function calculates the average, variance, standard deviation, minimum, and maximum values of the
 * sequence. If outlier removal is enabled, the function also calculates the interquartile range and drops any numbers
 * that fall outside the range.
 *
 * The code then creates an array of the standard deviations of the sub-sequences and passes it to a new
 * SequenceAnalysis instance. It then calculates the standard deviations of the standard deviations using the sigma
 * function.
 *
 * The code then loops over the standard deviations and sets the sigma property of each sub-sequence to the
 * corresponding value.
 *
 * The code then creates an object that contains the `total` and `sequences` properties. The `total` property contains
 * the results of the analysis on the entire sequence, and the `sequences` property contains an array of objects that
 * contain the results of the analysis on each sub-sequence.
 *
 * The code then creates an array of the averages of the sub-sequences and passes it to a new `SequenceAnalysis`
 * instance. It then calculates the standard deviation of the averages using the `sigma` function.
 *
 * The code then creates an array of the standard deviations of the sub-sequences and passes it to a new
 * `SequenceAnalysis` instance. It then calculates the standard deviations of the standard deviations using the `sigma`
 * function.
 *
 * The code then loops over the standard deviations and sets the `sigma` property of each sub-sequence to the
 * corresponding value.
 *
 * Finally, the code returns the final object that contains the results of the analysis for both the entire sequence
 * and the sub-sequences.
 *
 * @param {number[]} numbers - The numbers to analyze.
 * @param {number} numberOfSequences - The number of sub-sequences to use.
 * @returns {object} An object containing the results of the analysis.
 */
function withSubSequences( numbers, numberOfSequences )
{
    if ( numbers.length * 2 < numberOfSequences )
        return null;

    const numLng = numbers.length;

    const eachSeq = ( numLng  + ( numberOfSequences - 1 )  ) / numberOfSequences | 0;

    const subSeqs = [];
    let start = 0;

    while ( start < numLng )
    {
        subSeqs.push( numbers.slice( start, start + eachSeq ) );
        start += eachSeq;
    }

    let lo, hi, dropped;

    function perSeq( seq, first = false, removeOutliers = false )
    {
        const sa = new SequenceAnalysis( seq );

        if ( removeOutliers )
        {
            if ( first )
                [ lo, hi, dropped ] = sa.iqr();
            else
                [ , , dropped ] = sa.iqr( lo, hi );
        }

        return {
            average:      sa.average(),
            variance:     sa.variance(),
            stdDeviation: sa.stdDeviation(),
            min:          sa.min(),
            max:          sa.max(),
            ...( removeOutliers ? { outliers: dropped } : {})
        };
    }

    const final = {
        all:     null,
        trimmed: null
    };

    for ( let n = 0; n < 2; ++n )
    {
        const result = {
            total:     perSeq( numbers, true, n === 1 ),
            sequences: subSeqs.map( s => perSeq( s, false, n === 1 ) )
        };

        const devs = result.sequences.map( r => r.stdDeviation );
        const sc = new SequenceAnalysis( devs );
        const sigmas = sc.sigma( result.total.stdDeviation );

        sigmas.forEach( ( s, i ) => result.sequences[ i ].sigma = s );

        final[ n === 0 ? 'all' : 'trimmed' ] = result;
    }

    return final;
}

const sq5             = sqrt( 5 );
const phi             = ( 1 + sq5 ) / 2;

/**
 * Calculates the nth Fibonacci number.
 *
 * @param {number} n - The index of the Fibonacci number.
 * @returns {number} The nth Fibonacci number.
 */
const fibonacci       = n => round( phi ** n / sq5 );

/**
 * This defines a function called `nextDelay` that calculates the next delay factor based on the index
 * and a delay factor.
 *
 * The function uses the _Fibonacci_ sequence to calculate the next delay factor by calling the fibonacci function and
 * incrementing the index by 1. The Fibonacci sequence is a series of numbers where each number is the sum of the two
 * preceding numbers. In this case, the Fibonacci sequence is used to calculate the delay factor by starting with the
 * first two numbers (`0` and `1`) and multiplying each successive number by the previous one.
 *
 * The delay factor is then multiplied by the current index to calculate the next delay factor. This allows the delay
 * to increase gradually over time, with each subsequent call to the function resulting in a longer delay.
 *
 * The function returns the calculated delay factor.
 *
 * @param {number} i - The index.
 * @param {number} delayFactor - The delay factor.
 * @returns {number} The next delay factor.
 */
const nextDelay       = ( i, delayFactor ) => fibonacci( i + 1 ) * delayFactor;

export {
    SequenceAnalysis,
    withSubSequences,
    fibonacci,
    nextDelay
};
