/********************************************************************************************************************
 * @file Actions for the error store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const startRequest = createAction( 'REQUEST/START' );
export const endRequest = createAction( 'REQUEST/END' );
export const setError = createAction( 'ERROR.ERROR/SET' );
export const setClearError = createAction( 'ERROR.CLEAR/SET' );
export const setClearAllError = createAction( 'ERROR.CLEAR_ALL/SET' );
