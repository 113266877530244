import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { 
    setUserMfaTransition, setUserQrCode, setUserProfile, setMfaBackUserProfile, discardUserSettingChanges, setSettingValueUserProfile,
    setAddUserBusinessHours, setRemoveUserBusinessHours
} from '../../../redux/actions/index.js';
import { root } from '../../../scss/Login.module.scss';
import { MfaStates } from '../../../utils/mfa.js';
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';
import { ChewyCenter } from '../../login/Login.jsx';
import Mfa from '../../login/Mfa.jsx';
import { saveUserSettingChanges } from '../engineer/reducer.js';

import UserProfileContent from './UserProfileContent.jsx';

const UserProfile = ({
    organisationMfa,
    user,
    foundChanges,
    saveUserSettingChanges,
    discardUserSettingChanges,
    mfaState,
    mfaVerified,
    setUserProfile,
    setMfaBackUserProfile,
    setUserQrCode,
    domain,
    history,
    fieldError
}) => {

    useEffect( () => {

        // This effect serves to copy the live user preferences into the user profile screen's slice,
        // where they're edited and maybe saved back to the user on exit.
        // logging out from this screen fires this with blank prefs so check this
        if ( user?.userId ) 
            setUserProfile({ user });
        
    }, [ setUserProfile, user ]);

    return (
        mfaState === MfaStates.NONE || mfaVerified
            ? <SettingsLayout>

                <PreventTransitionPrompt
                    when={foundChanges}
                    history={history}
                    saveAction={saveUserSettingChanges}
                    discardAction={discardUserSettingChanges}
                />

                <UserProfileContent />

            </SettingsLayout>
            : <ChewyCenter rootClasses={root} mfaState={mfaState} domain={domain} error={fieldError.mfa} back={setMfaBackUserProfile}>
                <Mfa userMfaSetup={true} organisationMfa={organisationMfa} onDone={q => setUserQrCode( q )} />
            </ChewyCenter>
    );

};

UserProfile.propTypes = {
    organisationMfa:                    PropTypes.bool,
    user:                               PropTypes.object,
    foundChanges:                       PropTypes.bool,
    saveUserSettingChanges:             PropTypes.func,
    discardUserSettingChanges:          PropTypes.func,
    mfaState:                           PropTypes.string,
    mfaVerified:                        PropTypes.bool,
    setUserProfile:                     PropTypes.func,
    setMfaBackUserProfile:              PropTypes.func,
    setUserQrCode:                      PropTypes.func,
    domain:                             PropTypes.string,
    history:                            PropTypes.object,
    fieldError:                         PropTypes.object
};

const mapStateToProps = state => {

    const {
        organisation: { live },
        user,
        userProfile: { twoFactorAuthenticationRequired, mfaState, mfaVerified, defaultAppointmentDuration, foundChanges, businessHours, overrideOrgHours, fieldError },
        meta: { domain }
    } = state || {};
    const organisationMfa = live?.twoFactorAuthenticationRequired;

    return {
        organisationMfa,
        twoFactorAuthenticationRequired,
        defaultAppointmentDuration,
        businessHours,
        overrideOrgHours,
        user,
        mfaState,
        mfaVerified,
        foundChanges,
        domain,
        fieldError
    };
};

const mapDispatchToProps = {
    setUserProfile,
    setSettingValueUserProfile,
    setUserMfaTransition,
    setMfaBackUserProfile,
    setUserQrCode,
    saveUserSettingChanges,
    discardUserSettingChanges,
    setAddUserBusinessHours,
    setRemoveUserBusinessHours
};

export default connect( mapStateToProps, mapDispatchToProps )( UserProfile );
export { UserProfile };
