import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        color: "var(--black-1)"
    }
});

export const LicoLink = props => {
    const { to, className = '', dataName, children } = props;
    const classes = useStyles( props );
    return <Link className={classes.root + ' ' + className} data-ux={dataName} to = {to}>{children}</Link>;
};

LicoLink.propTypes = {
    to:        PropTypes.string,
    className: PropTypes.string,
    dataName:  PropTypes.string,
    children:  PropTypes.node
};
