import React from 'react';

import PropTypes from 'prop-types';

import { root, content, page, bottomText, copyRight, link, slim, crisp } from '../../scss/PreAuthLayout.module.scss';
import { __ } from '../../utils/i18n.jsx';
import ErrorAlert from '../common/ErrorAlert.jsx';
import { Caption, EULA, LicoStaticLink, PRIVACY, TERMS } from "../common/index.js";
import VersionTooltip from '../common/VersionTooltip.jsx';

const currentYear = new Date().getFullYear();

const PreAuthLayout = ({ className, children, rootComponent, slimView, crispView }) => (
    <div className={`${root} ${className}`}>
        {rootComponent}
        <div className={content}>
            <div className={`${page} ${slimView ? slim : ''} ${crispView ? crisp : ''}`}>{children}</div>
            <div className={bottomText}>
                <VersionTooltip >
                    <Caption color="textSecondary" className={copyRight}>{__( 'Patent pending. ©{currentYear} Licorice.', { currentYear })}</Caption>
                </VersionTooltip>
                <LicoStaticLink target="_blank" to={EULA} className={link}>{__( 'EULA' )}</LicoStaticLink>
                <LicoStaticLink target="_blank" to={TERMS} className={link}>{__( 'Terms' )}</LicoStaticLink>
                <LicoStaticLink target="_blank" to={PRIVACY} className={link}>{__( 'Privacy' )}</LicoStaticLink>
            </div>
        </div>
        <ErrorAlert />
    </div>
);
PreAuthLayout.propTypes = {
    className:              PropTypes.string,
    children:               PropTypes.any,
    rootComponent:          PropTypes.any,
    slimView:               PropTypes.bool,
    crispView:              PropTypes.bool
};

export { PreAuthLayout };

export default PreAuthLayout;
