import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import logo from "../../images/licorice-logo-white.svg";
import { root, content, page, unknown, header } from '../../scss/ClientSchedule.module.scss';
import { loader } from '../../scss/misc.module.scss';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { LicoIcon } from "../common/index.js";

import CancelPage       from './CancelPage.jsx';
import ConfirmationPage from './ConfirmationPage.jsx';
import LandingPage      from './LandingPage.jsx';
import { findScheduleJob, progressStage } from './reducer.js';
import ScheduleCalendar from './ScheduleCalendar.jsx';
import SuccessPage from './SuccessPage.jsx';

const componentStages = {
    [ progressStage.LOADING ]:   () => <LicoIcon className={`${loader} spinner`} icon="cog" />,
    [ progressStage.CANCELLED ]: () => <CancelPage />,
    [ progressStage.LANDING ]:   () => <LandingPage />,
    [ progressStage.CALENDAR ]:  () => <ScheduleCalendar />,
    [ progressStage.CONFIRM ]:   () => <ConfirmationPage />,
    [ progressStage.SUCCESS ]:   () => <SuccessPage />,
    [ progressStage.UNKNOWN ]:   error => <div className={unknown}>{error}</div>
};

const ClientSchedule = ({ findScheduleJob, progress, error }) => {
    const { tokenId } = useParams();

    if ( progress === progressStage.LOADING )
        findScheduleJob( tokenId );

    // render component
    const component = progress <= progressStage.LOADING ? componentStages[ progress ]() : <div className={page}>{componentStages[ progress ]( error )}</div>;

    return (
        <div className={root}>
            <div className={header}>
                <BannerLogo src={logo}/>
            </div>
            <div className={content}>
                {component}
            </div>
        </div>
    );
};

ClientSchedule.propTypes = {
    progress:           PropTypes.number.isRequired,
    error:              PropTypes.string,
    findScheduleJob:    PropTypes.func.isRequired
};

const mapStateToProps = ({ scheduling: { progress, error } }) => ({
    progress,
    error
});
const mapDispatchToProps = { findScheduleJob };

export default connect( mapStateToProps, mapDispatchToProps )( ClientSchedule );
export { ClientSchedule };
