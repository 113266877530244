import React from 'react';

import { omit } from '@licoriceio/utils';
import { Button as MuiButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAnyLoading } from '../../../../redux/selectors/index.js';

const useStyles = makeStyles({
    root: {
        backgroundColor: "var(--teal)",
        color:           "var(--black-1)",

        "&:hover": {
            backgroundColor: "var(--teal-dark)",
            boxShadow:       "none"
        },
        "&:disabled": {
            backgroundColor: "var(--lightgrey-1)",
            boxShadow:       "none"
        }
    },
    grey4: {
        backgroundColor: "var(--grey-4) !important",
        color:           "var(--black-1)",

        '&:hover':       {
            backgroundColor: "var(--grey-3) !important"
        }
    }
});


const LicoButton = props => {

    const {
        children,
        className = '',
        colorClassName = "root",
        color = "secondary",
        element = "button",
        disabled,
        disabledByApi,
        ...rest
    } = props;

    const classes = useStyles( props );
    const colorClass = classes[ colorClassName ];

    return (
        <MuiButton 
            className={`${classes.root} ${colorClass} ${className}`} 
            variant="contained" 
            color={color} 
            component={element} 
            disabled={disabled || disabledByApi} {... omit( rest, 'disableDuring' )}
        >
            {children}
        </MuiButton>
    );
};

LicoButton.propTypes = {
    className:      PropTypes.string,
    colorClassName: PropTypes.oneOf([ "root", "grey4" ]),
    element:        PropTypes.oneOf([ "button", "a" ]),
    children:       PropTypes.node,
    color:          PropTypes.string,
    disabled:       PropTypes.bool,
    disabledByApi:  PropTypes.bool,
    disableDuring:  PropTypes.array
};

const mapStateToProps = ( state, parentProps ) => ({
    disabledByApi: getAnyLoading( state, parentProps.disableDuring )
});

export default connect( mapStateToProps, {})( LicoButton );
export { LicoButton };
