import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setClearAllError } from '../../redux/actions/index.js';
import { selectAllFatalErrors }               from '../../redux/selectors/index.js';
import { emailLink } from '../../scss/ErrorAlert.module.scss';
import { __, _$ } from '../../utils/i18n.jsx';

const EmailLink = ( email, label ) => ( <a className={emailLink} href={`mailto:${email}`}>{label || email}</a> );

// Our errors are all listed here with no assumptions or requirements given to the actual screen layout.
const errorTexts = {
    NO_CONNECTION: () => ({
        title: __( "Could not connect to Licorice server." ),
        body:  _$( "If this problem keeps occurring, please email us at {email}. If it's critical, please call your beta contact.", { email: EmailLink( "help@licorice.io" ) })
    })
};

/**
 * Using a standard template removes the need to repeat this code for every error. We can also easily change how error messages look
 * by just changing this function.
 *
 * @param {string|JSX.Element} title
 * @param {string|JSX.Element} body
 * @return {JSX.Element}
 */
const makeErrorMessage = ({ title, body }) => (
    <div>
        <p>{title}</p>
        {body && <p>{body}</p>}
    </div>
);

const ErrorAlert = ({ errors, setClearAllError }) => {

    const [ open, setOpen ] = useState( !!errors );

    const handleClose = () => {
        setClearAllError();
        setOpen( false );
    };

    if ( errors?.length && !open ) setOpen( true );

    const errorContent = error => typeof error === "object"
        ? errorTexts[ error.type ]( error )
        : { title: error };

    return errors?.length > 0
        ? (
            <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} >
                <Alert 
                    elevation={6} 
                    variant="filled" 
                    onClose={handleClose} 
                    severity="warning" 
                    color="error" 
                >
                    {makeErrorMessage( errorContent( errors[ 0 ]) )}
                </Alert>
            </Snackbar>
        )
        : null;
};

ErrorAlert.propTypes = {
    errors:             PropTypes.any,
    setClearAllError:   PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    errors: selectAllFatalErrors( state )
});

const mapDispatchToProps = {
    setClearAllError
};

export default connect( mapStateToProps, mapDispatchToProps )( ErrorAlert );
export { ErrorAlert };
