/*********************************************************************************************************************
 * @file CalendarSearchBar component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setDeleteUserCalendar } from '../../redux/actions/index.js';
import { TypeAheadControl } from '../../redux/reducers/typeAhead.js';
import { CalendarSearchBarMain, sizerThing, chip } from '../../scss/CalendarSearchBar.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { SelectStyle } from '../../utils/misc.js';
import DynamicSelect from '../common/DynamicSelect.jsx';
import { LicoPlainChip } from '../common/index.js';

import { selectCalendars } from './reducer.js';
import { addCalendarThunk } from './sharedThunks.js';


const CalendarSearchBar = ({
    allCalendars,
    addCalendarThunk,
    setDeleteUserCalendar,
    user
}) => (
    <div className={CalendarSearchBarMain}>
        <span className={sizerThing}>
            <DynamicSelect
                name={TypeAheadControl.calendarUsers}
                autoFocus={false}
                placeholder={ __( "Search for teammates" )}
                style={SelectStyle.round}
                excludedIds={[ user.userId, ...allCalendars.map( calendar => calendar.user.userId ) ] }
                onChange={( event, value ) => {
                    addCalendarThunk({ user: value.fullRecord });
                }}
                // onBlur={() => this.setState({ inputOpen: false })}
            />
        </span>
        <LicoPlainChip className = {chip} label = {__( "Me" )}/>
        {allCalendars.map( ( calendar, i ) =>
            <LicoPlainChip
                className = {chip}
                label = {calendar.user.name}
                key = {i}
                onDelete = {() => setDeleteUserCalendar( calendar.user.userId )}
            /> )}
    </div>
);

CalendarSearchBar.propTypes = {
    allCalendars:                   PropTypes.array.isRequired,
    addCalendarThunk:               PropTypes.func.isRequired,
    setDeleteUserCalendar:          PropTypes.func.isRequired,
    user:                           PropTypes.any.isRequired
};

const mapStateToProps = state => ({ allCalendars: selectCalendars( state, false ) });
const mapDispatchToProps = { addCalendarThunk, setDeleteUserCalendar };

export default connect( mapStateToProps, mapDispatchToProps )( CalendarSearchBar );
export { CalendarSearchBar };
