import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { POST, uri } from "../constants";
import { store } from '../publicStore.js';
import { errorBoundaryRoot, errorSection, errorSectionLabel, errorSectionContent, userMessage, reloadMessage  } from '../scss/ErrorBoundary.module.scss';
import { BACKEND_URL } from "../services/util/baseRequests.js";
import { __ } from '../utils/i18n';

const MISSING_ERROR = 'Error was swallowed during propagation.';

const logErrorToCloud = ( errorId, error ) =>
{
    // console.log( 'logErrorToCloud', JSON.stringify({ errorId, error }, undefined, 2 ) );
    const { user: { userId = 'unknown user' } } = store.getState();

    fetch( `${BACKEND_URL}${uri.FRONTEND_ERROR}`, { method: POST, body: JSON.stringify({ errorId, error, userId }) })
        .then( async r => {
            if ( !r.ok ) 
                console.error( 'request failed', r.statusText );
        })
        .catch( error => {
            console.error( 'request threw error', error );
        });

};

const FirstLinePanel = ({ errorId, firstLine, text }) => {
    const [ expanded, setExpanded ] = useState( false );

    useEffect( () => {
        if ( expanded )
            logErrorToCloud( errorId, text );
    }, [ errorId, text, expanded ]);

    return <div className={errorSection} onClick={() => {
        setExpanded( true );
    }}>
        <div className={errorSectionLabel}>{__( "Location:" )}</div>
        <div className={errorSectionContent}>{expanded ? text : firstLine}</div>
    </div>;
};
FirstLinePanel.propTypes = {
    errorId:        PropTypes.string.isRequired,
    firstLine:      PropTypes.string.isRequired,
    text:           PropTypes.string.isRequired
};

export const withErrorBoundary = BaseComponent => ( class Hoc extends React.Component {
    // Enhance component name for debugging and React-Dev-Tools
    static displayName = `withErrorBoundary(${BaseComponent.name})`;
    // reference to original wrapped component
    static WrappedComponent = BaseComponent;

    state = {
        error:          undefined,
        firstLine:      undefined,
        stack:          undefined,
        errorId:        undefined
    };

    componentDidCatch( error = new Error( MISSING_ERROR ), info = {})
    {
        const errorId  = uuidv4();
        const componentStack = info.componentStack.trim();

        const matches = /(.*)\n/.exec( componentStack );
        const firstLine = matches ? matches[ 1 ] : componentStack;

        this.setState({ errorId, error, firstLine, stack: componentStack });
        logErrorToCloud( errorId, `${error.toString()} ${firstLine}` );
    }

    render() {
        const { children, ...restProps } = this.props;
        const { errorId, error, firstLine, stack } = this.state;

        if ( error ) 
            return <BaseComponent {...restProps} errorId={errorId} error={error} firstLine={firstLine} stack={stack}/>;

        return <>{children}</>;
    }

    static propTypes = {
        children: PropTypes.node
    };
});


const ErrorBoundaryRender = ({ errorId, error, firstLine, stack }) => {
    return <div><div className={errorBoundaryRoot} >
        <div className={errorSection}>
            <div className={errorSectionLabel}>{__( "An error has occurred:" )}</div>
            <div className={errorSectionContent}>{error.toString()}</div>
        </div>
        <div className={errorSection}>
            <div className={errorSectionLabel}>{__( "Tracking number:" )}</div>
            <div className={errorSectionContent}>{errorId}</div>
        </div>
        <FirstLinePanel errorId={errorId} firstLine={firstLine} text={stack} />
        <div className={userMessage}>
            {__( "The error has been recorded by the system. Please use the tracking number shown above if you wish to make an enquiry." )}
        </div>
        <div className={reloadMessage} onClick={() => window.location.reload()}>{__( "Click here to reload application" )}</div>
    </div></div>;
};
ErrorBoundaryRender.propTypes = {
    error:          PropTypes.object.isRequired,
    errorId:        PropTypes.string.isRequired,
    firstLine:      PropTypes.string.isRequired,
    stack:          PropTypes.string.isRequired
};

export const ErrorBoundary = withErrorBoundary( ErrorBoundaryRender ); 
