/*********************************************************************************************************************
 * @file Notification panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 1-Dec-2020
 *********************************************************************************************************************/

import React, { PureComponent } from 'react';

import { NotificationTypes } from "@licoriceio/constants";
import { asArray } from '@licoriceio/utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setScrollTop } from '../../redux/actions/index.js';
import { notificationPanel, notificationList, spacer, clearButton } from '../../scss/NotificationPanel.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { LicoButton } from '../common/index.js';

import Notification from './Notification.jsx';
import { notificationViewed } from './reducer.js';

const ignoredNotifications = new Set([ NotificationTypes.HEARTBEAT_FLATLINE, NotificationTypes.HEARTBEAT_NORMAL ]);

class NotificationPanel extends PureComponent {

    render() {
        const {
            notifications = [],
            user: { userId },
            notificationViewed
        } = this.props;

        const nowTime = new Date().getTime();

        const currentNotifications = asArray( notifications )
            .filter(  n => ( !n.hideUntilTime || n.hideUntilTime < nowTime ) && !ignoredNotifications.has( n.type ) )
            .sort( ( a, b ) => b.responses.length - a.responses.length || b.createdOnTime - a.createdOnTime );

        const response = n11 => n11.responses.length > 0 ? ({
            type:           n11.type,
            notificationId: n11.notificationId,
            action:         0,
            ...( n11.type === NotificationTypes.APPOINTMENT_ASSIGNED ? { ...n11.data } : {})
        }) : null;

        const clearButtonDisabled = currentNotifications.filter( n => n.dismissable ).length === 0;

        return <div id="notification-panel" className={notificationPanel}  >
            <div id="notification-list" className={notificationList}>
                {currentNotifications.map( ( n, i ) => <Notification
                    key={n.notificationId || i}
                    notification={n}
                    userId={userId}
                    response={response( n )}
                /> )}
            </div>

            <div className={spacer} />

            <div className={clearButton} >
                <div>
                    <LicoButton disabled={clearButtonDisabled} colorClassName="grey4" size="small" onClick={() =>
                        currentNotifications.filter( n => n.dismissable ).forEach( notification => notificationViewed({ notification }) )
                    }>{__( "Clear All" )}</LicoButton>
                </div>
            </div>
        </div>;
    }

    static propTypes = {
        notifications:              PropTypes.arrayOf( PropTypes.any ),
        user:                       PropTypes.any.isRequired,
        notificationViewed:         PropTypes.func.isRequired
    };
}

const mapStateToProps = state => ({
    notifications:      state.notification.notifications,
    scrollTop:          state.notification.scrollTop,
    user:               state.user
});

const mapDispatchToProps = {
    notificationViewed,
    setScrollTop
};

export default connect( mapStateToProps, mapDispatchToProps )( NotificationPanel );
export { NotificationPanel };
