import { unified } from "unified";
import markdown from "remark-parse";
import { slateToRemark, remarkToSlate, mdastToSlate, slateToMdast } from "remark-slate-transformer";
import remarkGfm from "remark-gfm";
import stringify from "remark-stringify";
import { fromMarkdown } from 'mdast-util-from-markdown';

import { isObject, isString } from './type-helpers.js';

// conversion processors
const markdownToSlateProcessor = unified().use( remarkGfm ).use( markdown ).use( remarkToSlate );
const slateToMarkdownProcessor = unified().use( remarkGfm ).use( slateToRemark ).use( stringify );
const astToMarkdownProcessor = unified().use( stringify );

export const markdownToSlate = text => markdownToSlateProcessor.processSync( text ).result;
export const slateToMarkdown = data => slateToMarkdownProcessor.stringify( slateToMarkdownProcessor.runSync({ type: "root", children: data }) );

// slate to ast (Licorice->db)
export const slateToAst = slateJSON => slateToMdast({ type: "root", children: slateJSON }, {});

// existing note content in existing systems will be plain text; we don't want to convert those records,
// so cope with that when sending content from the db. Any update to notes (ie from CW) will change the content
// to ast.
// Our astTo* functions will use this to convert any input to ast before processing; they will cope with:
// * ast json text
// * plain text
// * ast object
const produceAst = ( content, logger = console ) => {
    try 
    { 
        return isObject( content )
            ? content
            : isString( content ) && /^\s*(?:\[.*]|\{.*})\s*$/s.test( content ) ? JSON.parse( content ) : fromMarkdown( content );
    }
    catch ( e )
    {
        logger.error( new Error( e, { cause: new Error( `Error parsing following content to AST: ${content}` ) }) );
        return fromMarkdown( 'An error occurred parsing this note' );
    }

};

// ast to markdown (db->CW)
export const astToMarkdown = ( ast, logger ) => astToMarkdownProcessor.stringify( produceAst( ast, logger ) );

// Slate expresses empty paragraphs as { type: 'paragraph', children: [ { text: '' } ]}
// The AST conversion strips the empty text nodes out, so we have children: [], and this upsets Slate.
// So, we override paragraphs with no children to return the empty text node.
const astToSlateOverrides = {
    paragraph:  ( node, next ) => ({ ...node, children: node.children.length ? next( node.children ) : [ { text: '' } ] })
};

// ast to slate (db->Licorice)
export const astToSlate = ( ast, logger ) => mdastToSlate( produceAst( ast, logger ), astToSlateOverrides );

// md to ast (CW->db)
export const markdownToAst = text => fromMarkdown( text );
