import { isFunc, isPromise } from './type-helpers.js';

const nop            = () => {};
const identity       = x => x;
const identityN      = n => ( ...args ) => args[ n ];
const undef          = () => void 0;
const truthy         = () => true;
const falsy          = () => false;
const nullFn         = () => null;
const asyncNop       = async () => {};
const asyncIdentity  = async x => x;
const asyncIdentityN = n => async ( ...args ) => args[ n ];
const asyncUndef     = async () => void 0;

const isNotNull = x => x != null;
const isDefined = x => x != null;
const isUndef   = x => x == null;

const qdeferObject = () => {
    let resolve, reject;
    const promise = new Promise( ( res, rej ) => [ resolve, reject ] = [ res, rej ]);

    return { promise, resolve, reject };
};

function qdefer( asObject = false )
{
    if ( asObject ) return qdeferObject();

    let a = nop, b = identity;

    return [ new Promise( ( x, y ) => [ a, b ] = [ x, y ]), a, b ];
}

const compose = ( ...fns ) => initial => fns.reduce( ( value, fn ) => isPromise( value ) ? value.then( fn ) : fn( value ), initial );

async function deferCall( previous, _set, func )
{
    let activity, resolve, reject;

    const afterThis = ( p, f ) => new Promise( ( yes, no ) => p.then( f ).then( yes, no ) );

    if ( previous != null )
        return afterThis( previous, func );

    [ activity, resolve, reject ] = qdefer();
    _set( activity );

    resolve( func() ).catch( reject );
}

async function defer( chain, setChain, func )
{
    try
    {
        await deferCall( chain, setChain, func );
    }
    catch ( e )
    {
        console.error( e );
    }
}

/**
 * ## execFirst
 * - When lock is clear
 *   - Set lock A
 *   - Execute function call
 *   - Release lock A with result
 *   - Clear lock A
 *   - Return own result
 * - When lock A is set
 *   - Wait for lock A
 *   - Return result of lock A
 *
 * ## execOnce
 * - When lock is clear
 *   - Set lock
 *   - Execute function call
 *   - Set lock result to result of call
 * - When lock is set
 *   - Return result of lock
 *
 * ## execQueue
 * - When lock is clear
 *   - Set lock A
 *   - Execute function call
 *   - Release lock A
 *   - Return own result
 * - When lock is blocking
 *   - Set lock B
 *   - Wait for lock A
 *   - Execute function call
 *   - Release lock B
 *   - Return own result
 */

const EARLY = 1;
const CLEAR = 2;
const WAIT  = 4;

/**
 * @param {number} flags
 * @return {( fn: ( ...args: any[] ) => any, ctx?: object ) => ( ...args: any[] ) => Promise<any>}
 * @private
 */
function _deferExec( flags )
{
    return ( fn, ctx ) => {
        let lock;

        return ( ...args ) => {
            let promise = f => Promise.resolve( f() );

            if ( lock != null )
            {
                if ( flags & EARLY ) return lock;

                if ( flags & WAIT )
                    promise = ( prevLock => fn => prevLock.then( fn ) )( lock );
            }

            let okay, fail;
            [ lock, okay, fail ] = qdefer();

            promise = promise( () => fn.call( ctx ?? null, ...args ) ).then( okay, fail );

            if ( flags & CLEAR )
                promise.finally( () => lock = okay = fail = null );

            return lock;
        };
    };
}

defer.first = _deferExec( EARLY | CLEAR );
defer.once = _deferExec( EARLY );
defer.queue = _deferExec( WAIT | CLEAR );

const curry = ( f, ...i ) => ( s => s( s )( i ) )( s => p => ( ...c ) => c.length + p.length >= f.length ? f( ...p.concat( c ) ) : s( s )( p.concat( c ) ) );

function once( fn )
{
    let called = false;
    let res;

    return ( ...args ) => {
        if ( called ) return res;
        res    = fn( ...args );
        called = true;
        return res;
    };
}

/**
 * @template {any} T
 * @param {function():T} fn
 * @param {function|any} [whenError=null]
 * @return {Promise<T|any>}
 */
async function tryNull( fn, whenError = null )
{
    try
    {
        return await fn();
    }
    catch ( e )
    {
        if ( isFunc( whenError ) ) return whenError( e );

        console.error( e );
        return whenError;
    }
}

export {
    tryNull,
    once,
    curry,
    defer,
    compose,
    qdefer,
    qdeferObject,
    nop,
    identity,
    identityN,
    undef,
    asyncNop,
    asyncIdentity,
    asyncIdentityN,
    asyncUndef,
    truthy,
    falsy,
    nullFn,
    isNotNull,
    isDefined,
    isUndef
};
