/*********************************************************************************************************************
 * @file Worklog item component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React, { PureComponent } from 'react';

import { FormControlLabel, Checkbox, Popover, TextField  } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_JOBCARD_TASK_ADD_BUTTON, UX_JOBCARD_TASK_LIST, UX_JOBCARD_TASK_ITEM, UX_JOBCARD_TASK_ITEM_DELETE, UX_JOBCARD_TASK_INPUT } from "../../ux-constants.js";
import { selectJobChecklist } from '../../redux/selectors/index.js';
import { checklistStyle, checklistItem, checkbox, label, input, checkItems, checklistCloseButton, inputRow } from '../../scss/Checklist.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { H3, LicoFab, LicoIcon, LicoSmallButton } from '../common/index.js';

import { addJobCardChecklistItem, deleteJobCardChecklistItem, updateJobCardChecklistItem } from './reducer.js';


const ChecklistItem = ({ item, disabled, onDelete, onUpdate }) => {

    return <div className={checklistItem} data-ux={UX_JOBCARD_TASK_ITEM}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={item.status}
                    onClick={() => onUpdate({ task: { status: !item.status }, checklistId: item.checklistId })}
                    className={checkbox}
                    disabled={disabled}
                />
            }
            label={item.name}
            classes = {{ label }}
        />

        {!disabled && <LicoFab
            onClick = {() => onDelete( item.checklistId ) }
            licoVariant = "tiny"
            className={checklistCloseButton}>
            <LicoIcon dataName={UX_JOBCARD_TASK_ITEM_DELETE} icon="close" />
        </LicoFab>}
    </div>;
};
ChecklistItem.propTypes = {
    item:           PropTypes.any.isRequired,
    disabled:       PropTypes.bool.isRequired,
    onDelete:       PropTypes.func.isRequired,
    onUpdate:       PropTypes.func.isRequired
};

class Checklist extends PureComponent
{
    constructor( props )
    {
        super( props );

        this._inputRowRef = React.createRef();

        this.state = {
            showInput:  false,
            textValue:  ''
        };
    }

    closeInput = () =>  {
        this.setState({
            textValue: '',
            showInput: false
        });
    };

    render() {
        const {
            jobId,
            checklist,
            disabled,
            addJobCardChecklistItem,
            deleteJobCardChecklistItem,
            updateJobCardChecklistItem,
            preventAdd
        } = this.props;

        const {
            showInput,
            textValue
        } = this.state;

        return <div className={checklistStyle}>
            <H3>{__( "Checklist" )}</H3>

            {checklist && <div className={checkItems} data-ux={UX_JOBCARD_TASK_LIST} >
                {checklist.map( item =>
                    <ChecklistItem
                        key={item.checklistId || item.name}
                        item={item}
                        disabled={disabled}
                        onDelete={deleteJobCardChecklistItem}
                        onUpdate={updateJobCardChecklistItem}
                    />
                )}
            </div>}

            {!disabled && !preventAdd && <div className={inputRow} ref={this._inputRowRef}>
                {showInput &&
                <Popover
                    anchorEl={this._inputRowRef.current}
                    anchorOrigin={{
                        vertical:   'top',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical:   'top',
                        horizontal: 'left'
                    }}
                    open={showInput}
                >
                    <TextField
                        autoComplete="off"
                        autoFocus={true}
                        variant="outlined"
                        name="newItem"
                        value={textValue}
                        onChange={e => this.setState({ textValue: e.target.value })}
                        onBlur={this.closeInput}
                        onKeyUp={e => {
                            if ( e.code === 'Enter' && textValue.length > 0 ) {
                                addJobCardChecklistItem({ task: { name: textValue }, jobId });
                                this.setState({ textValue: '' });
                            }
                            if ( e.code === 'Escape' )
                                this.closeInput();
                        }}
                        className={input}
                        data-ux={UX_JOBCARD_TASK_INPUT}
                        inputProps={{
                            className: "slim"
                        }} />
                </Popover>
                }
                <LicoSmallButton label={__( "Add task" )} onClick={() => this.setState({ showInput: true })} fabClass="grey-background" dataName={UX_JOBCARD_TASK_ADD_BUTTON} />
            </div>}

        </div>
        ;
    }

    static propTypes = {
        jobId:                          PropTypes.string.isRequired,
        checklist:                      PropTypes.arrayOf( PropTypes.any ),
        disabled:                       PropTypes.bool.isRequired,
        preventAdd:                     PropTypes.bool,
        addJobCardChecklistItem:        PropTypes.func.isRequired,
        deleteJobCardChecklistItem:     PropTypes.func.isRequired,
        updateJobCardChecklistItem:     PropTypes.func.isRequired
    };
}


const mapStateToProps = state => ({
    checklist: selectJobChecklist( state )
});

const mapDispatchToProps = {
    addJobCardChecklistItem,
    deleteJobCardChecklistItem,
    updateJobCardChecklistItem
};

export default connect( mapStateToProps, mapDispatchToProps )( Checklist );
export { Checklist };
