/*********************************************************************************************************************
 * @file search Panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { useEffect } from 'react';

import { HOUR, Priority, UserRole } from '@licoriceio/constants';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';

import "react-placeholder/lib/reactPlaceholder.css";
import { cacheType } from '../../redux/reducers/cache';
import { selectCacheData } from '../../redux/selectors/index.js';
import { 
    jobStyle, jobHeader, jobCompany, jobFooter, summary,
    jobTooltip, clientTT, descriptionTT, noteListTT, notesForDateTT, noteDateTT, userNoteTT, noteUserTT, noteContentTT, clientUserTT,
    desc, time, resolvedJobs, openJobHeader, openJobUser, openSummary, openDesc, openJobFooter, openJobCompany
} from '../../scss/JobItem.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { priorityColors } from '../../utils/misc.js';
import { getDefaultAppointmentDuration } from '../../utils/user-prefs.js';
import { getJobActionThunk } from "../calendar/thunks.js";
import RichEditor from "../common/editor/RichEditor.jsx";

import { getJobNotes } from './reducer.js';



const elapsedTime = d => {
    const hoursOld = ( Date.now() - d.getTime() ) / HOUR;

    let [ age, unit ] = hoursOld < 1
        ? [ Math.floor( hoursOld * 60 ), __( "min" ) ]
        : hoursOld < 24
            ? [ Math.floor( hoursOld ), __( "hr" ) ]
            : hoursOld < 365 * 24
                ? [ Math.floor( hoursOld / 24 ), __( "day" ) ]
                : [ Math.floor( hoursOld / ( 24 * 365 ) ), __( "yr" ) ];

    if ( age < 1 )
        age = 1;
    if ( age > 1 )
        unit += __( "s" );
    return '' + age + ' ' + unit;
};


const JobNote = ({ userName, content, clientUser }) => ( <div className={`${userNoteTT} ${clientUser ? clientUserTT : ''}`}>
    <div className={noteContentTT}><RichEditor
        textValue={content}
        disabled
        displayOnly
    /></div>
    <div className={noteUserTT}>{userName}</div>
</div> );
JobNote.propTypes = {
    userName:       PropTypes.string,
    content:        PropTypes.string,
    clientUser:     PropTypes.bool
};

const SearchPanelJobTooltip = ({ job, users, tabId, getJobNotes }) => {
    const {
        title = '',
        companyName,
        priority,
        notes
    } = job;

    useEffect( () => {
        getJobNotes( tabId, job.jobId );
    }, [ getJobNotes, job.jobId, tabId ]);

    const noteRetrievalDone = job.notes != undefined;
    const hasSomeNotes = noteRetrievalDone && Object.keys( job.notes ).length > 0;

    return <div>
        <div className={clientTT}>{companyName}</div>
        <div className={priorityColors[ priority || Priority.normal ]}>{title}</div>
        <hr />

        <div className={descriptionTT}>{job.description}</div>
        <hr />
        <ReactPlaceholder
            showLoadingAnimation
            type='text'
            minRows={3}
            ready={noteRetrievalDone}
        >
            <div className={noteListTT} >
                {hasSomeNotes ? Object.keys( notes || {}).map( ( noteDate, i ) => 
                    <div className={notesForDateTT} key={i}>
                        <div className={noteDateTT}> 
                            {noteDate}
                        </div> 
                        {notes[ noteDate ].map( ( note, j ) => {
                            const user = users[ note.userId ]?.record;
                            return <JobNote key={j} userName={user?.name} content={note.content} clientUser={user?.role === UserRole.user} />;
                        }) } 
                    </div>
                ) : __( "No notes" )}
            </div>
        </ReactPlaceholder>

    </div>;
};
SearchPanelJobTooltip.propTypes = {
    job:                    PropTypes.any.isRequired,
    getJobNotes:            PropTypes.func.isRequired,
    tabId:                  PropTypes.string.isRequired,
    users:                  PropTypes.object
};

const JobItem = ({ job, getJobNotes, users, getJobActionThunk, tabId, isStatusResolved }) => {

    const {
        title = '',
        companyId,
        companyName,
        createdOn,
        priority,
        userName,
        description,
        contactName
    } = job;
    const openJob = jobId => {
        getJobActionThunk({ jobId, openJobCard: true });
    };

    return <Tooltip
        classes={{ tooltip: jobTooltip }}
        title={<SearchPanelJobTooltip job={job} users={users} tabId={tabId} getJobNotes={getJobNotes} />}
        enterDelay={500}
        arrow
        // leaveDelay={100000} // only need this if debugging
        placement="right-end"
    >
        <div
            id={job.providerJobId}
            className={`${jobStyle} search-panel-job ${priorityColors[ priority || Priority.normal ]} ${isStatusResolved ? resolvedJobs : ''}`}
            data-job-id={job.jobId}
            data-provider-job-id={job.providerJobId}
            data-title={title}
            data-description={job.description}
            data-time={getDefaultAppointmentDuration()}
            data-company={companyName}
            data-company-id={companyId}
            data-priority={priority}
            data-new-item-flag={true}
            draggable
            onClick ={() =>   openJob( job.jobId ) }
        >
            {!isStatusResolved && <>
                <div className={openJobHeader}>
                    <span className={openJobCompany}>{companyName} </span>
                    <span className={openJobUser}>{userName}</span>
                </div>

                <span className={`${openSummary} ${priorityColors[ priority || Priority.normal ]}`}> {title} </span>
                <span className={openDesc}>{description || __( '<no description for job>' )} </span>

                <div className={openJobFooter}>
                    <span className={`${openSummary} ${priorityColors[ priority || Priority.normal ]}`}>{contactName}</span>
                    <span className={time}>{elapsedTime( new Date( createdOn ) )}</span>
                </div>

            </>}
            { isStatusResolved && <>
                <div className={jobHeader}>
                    <div>
                        <span className={jobCompany}>{companyName} </span></div>
                    <span className={summary}>{title} </span>
                </div>

                <div className={jobFooter}>
                    <span className={jobCompany}>{userName}</span>
                    <span className={desc}>{description || __( '<no description for job>' )} </span>
                    <span className={time}>{elapsedTime( new Date( createdOn ) )}</span>
                </div>
            </>}
        </div>

    </Tooltip>;
};

JobItem.propTypes = {
    job:                    PropTypes.any.isRequired,
    getJobNotes:            PropTypes.func.isRequired,
    users:                  PropTypes.object,
    getJobActionThunk:      PropTypes.func.isRequired,
    tabId:                  PropTypes.string.isRequired,
    isStatusResolved:       PropTypes.bool.isRequired
};

const mapStateToProps = ( state, props ) => ({
    users:                  selectCacheData( state, cacheType.USER ),
    isStatusResolved:       props.job.statusId === state.jobcard.completedStatus
});

const mapDispatchToProps = {
    getJobNotes,
    getJobActionThunk
};

export default connect( mapStateToProps, mapDispatchToProps )( JobItem );

