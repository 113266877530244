/*********************************************************************************************************************
 * @file Search reducer
 * @author Nishtha Rajani <nrajani@licorice.io>
 * @since 2.0.0
 * @date 14-Sep-2023
 *********************************************************************************************************************/
import { StatusNames, integrationNames, UserRole } from '@licoriceio/constants';
import { has } from '@licoriceio/utils';
import dayjs from 'dayjs';
import { v4 as uuid } from "uuid";

import { GET, searchPanelChipType, uri } from '../../constants.js';
import {
    setActiveTabId,
    setAddToSearchPanel,
    setCalculateJobPage,
    setDecrementExternalJobCount,
    setFilterList, setFilterString,
    setSearchPanel, setMeta,
    setNumberVisibleJobs,
    setPatchJob,
    setRemoveFromSearchPanel,
    setRemoveJob,
    setJobOffset,
    setSearchPanelHeight,
    setJobs,
    setTypeNames,
    setJobCardCreatedTab
} from '../../redux/actions/index.js';
import { cacheType, requestCacheRecords } from '../../redux/reducers/cache.js';
import { setSomeTypeNames } from '../../redux/reducers/names.js';
import { ezRedux, makeMapById } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { saveToUserSession } from "../../utils/local-storage.js";



/**
 * @typedef {object}    Job
 * @property {boolean}  billable
 * @property {string}   companyId
 * @property {string}   companyName
 * @property {string}   createdOn
 * @property {string}   creatorId
 * @property {number}   estimatedTime
 * @property {string}   userId
 * @property {string}   priority
 * @property {string}   providerBoardId
 * @property {string}   providerJobId
 * @property {string}   sourceLastUpdated
 * @property {string}   state
 * @property {string}   title
 * @property {string}   type
 * @property {string}   description // initially undefined but loaded on demand after mouseover
 */


/**
 * @typedef SearchPanel
 * @property {number} activeTabId
 *  @property {number} searchPanelHeight
 * @property {Array<{
 *   id: number,
 *   jobMap: Record<string, Job>,
 *   filteredJobIds: null | Array<string>,
 *   filterString: string,
 *   jobOffset: number,
 *   numberVisibleJobs: number,
 *   filterList: Array<any>
 * }>} tabs
 */


/**
 * @typedef   {object} filterList
 *  @property {number} id
 *  @property {number} tabIndex
 *  @property {Array<FilterParam>} filterParams
 *  @property {Array<number>} filterResultJobIds
 *  */

/**
 * @typedef {object} FilterParam
 * @property {number} id
 * @property {object} company/user/status
 */


const startingId = uuid();
const initialState = Object.freeze({
    activeTabId:          startingId,
    searchPanelHeight:    0,
    tabs:                 [
        {
            id:                   startingId,
            jobMap:            {},
            filteredJobIds:    null,
            filterString:         '',
            jobOffset:         0,
            numberVisibleJobs: 0,
            filterList:           [],
            jobCardCreatedTab:    false
        }
    ]
});

/** services */

const _asyncFetchJobs = abstractedCreateAuthRequest( GET, uri.JOBS_BY );
const _asyncFetchJobNotes = abstractedCreateAuthRequest( GET, uri.JOB_NOTES );


const jobStatusMap = {
    [ StatusNames.NOT_SCHEDULED ]: {
        icon:    "calendar",
        slashed: true
    },
    [ StatusNames.SCHEDULED ]: {
        icon: "calendar-tick"
    },
    [ StatusNames.IN_PROGRESS ]: {
        icon: "person-running"
    },
    [ StatusNames.WAIT_CLIENT ]: {
        icon: "user"
    },
    [ StatusNames.WAIT_PARTS ]: {
        icon: "puzzlePieceSimple"
    },
    [ StatusNames.WAIT_THIRD_PARTY ]: {
        icon: "building"
    },
    [ StatusNames.REOPENED_JOB ]: {
        icon: "sparkles"
    },
    [ StatusNames.CANCELLED_JOB ]: {
        icon:    "folder",
        slashed: true
    },
    [ StatusNames.RESOLVED_JOB ]: {
        icon: "folder"
    },
    [ StatusNames.UNMAPPED_STATUS ]: {
        icon: "question-circle"
    }
};

const getJobs = payload => ( dispatch, getState ) => {
    const { userId, companyId, statusId, terms, engineerId, pageSize = 10, page = 0 } = payload;
    const queryParams = { userId, companyId, statusId, terms, engineerId };

    // Function to convert the query params object to a JSON-like structure for the fetch call

    const createQueryParams = params =>
        Object.fromEntries(
            Object.entries( params )
                .filter( ([ , value ]) => value !== '' && value != null && ( !Array.isArray( value ) || value.length > 0 ) )
                .map( ([ key, value ]) => [
                    key,
                    Array.isArray( value ) ? value.filter( v => v != null ) : value
                ])
        );
    const validParams = createQueryParams( queryParams );

    _asyncFetchJobs( undefined, getState().auth, undefined, { pageSize, page, ...validParams }).then( result => {
        dispatch( setCalculateJobPage() );
        dispatch( setJobs({ data: result.payload, total: result.count }) );
    });
};

const getJobNotes = ( tabId, jobId ) => ( dispatch, getState ) => {

    _asyncFetchJobNotes( undefined, getState().auth, [ jobId ], {
        pageSize:     10,
        ignoreActive: true,
        related:      [ 'note->user', 'note(timeLogId)->?timeLog' ],
        fields:       [ 'note.*', 'user.name', 'user.preferences', 'user.role', 'timeLog.billable' ]
    }).then( result => {
        if ( !result.hasError ) {
            dispatch( setPatchJob({
                jobId,
                notes: result.payload,
                tabId: tabId
            }) );

            const userIds = result.payload.map( note => note.userId );
            dispatch( requestCacheRecords({ type: cacheType.USER, ids: userIds }) );
        }
    });
};

const windowHeightChanged = newHeight => ( dispatch, getState ) => {
    if ( newHeight < 0 ) 
        newHeight = window.innerHeight;
    
    dispatch( setSearchPanelHeight( newHeight ) );
    if ( getState().searchPanel?.tabs?.[ 0 ]?.filteredJobIds )
        dispatch( setCalculateJobPage() );
};

const clearFilterString = ( filterString, id ) => ( dispatch, getState ) => {
    const { searchPanel } = getState();
    const index = searchPanel?.tabs.findIndex( t => t.id === id );
    if ( index < 0 )
        return;
    dispatch( setFilterString({ id, filterString }) );
};

const filterStringChanged = ( filterString, id ) => ( dispatch, getState ) => {
    clearFilterString( filterString, id )( dispatch, getState );
    dispatch( getJobsForTab( id ) );
    dispatch( setCalculateJobPage() );
};

const checkForAddedJobThunk = providerJobId => ( dispatch, getState ) => {
    const { job: { jobMap } } = getState();
    if ( providerJobId in jobMap ) {
        dispatch( setRemoveJob( providerJobId ) );
        dispatch( setDecrementExternalJobCount() );
    }
};

/** reducers */

const setJobsReducer = ( draft, payload ) => {
    const { data, total } = payload;
    const index = draft?.tabs?.findIndex( t => t.id === draft.activeTabId );
    if ( index === -1 ) return;
    draft.tabs[ index ].jobMap = data.reduce( makeMapById( 'jobId' ), {});
    draft.tabs[ index ].total = total || 0;
    draft.tabs[ index ].filteredJobIds = data.slice().sort( ( a, b ) =>
        b.hasOwnProperty( 'createdOn' )
            ? b.createdOn.localeCompare( a.createdOn )
            : Number( a.providerJobId ) - Number( b.providerJobId )
    );

};


const patchJobReducer = ( draft, args ) => {
    const { jobId, notes, tabId, ...rest } = args;
    const index = draft?.tabs.findIndex( t => t.id === tabId );
    if ( index < 0 )
        return;
    const tab = draft?.tabs[ index ];
    const tabArray = tab.filteredJobIds;
    const job = tabArray.find( t => t?.jobId === jobId );
    if ( job ) {
        if ( notes ) {
            job.notes = notes
                .sort( ( a, b ) => b.createdOn.localeCompare( a.createdOn ) )
                .reduce( ( acc, cur ) => {
                    const noteDay = dayjs( cur.createdOn ).format( 'D MMMM YYYY' );
                    if ( !has( acc, noteDay ) )
                        acc[ noteDay ] = [];
                    acc[ noteDay ].push( cur );
                    return acc;
                }, {});
        }
        
        Object.assign( job, rest );
    }
};



const JOB_HEADER_HEIGHT = 88;    // inc top margin
const JOB_PAGER_HEIGHT = 55;     // inc bottom margin
const JOB_HEIGHT = 130;

const calculateJobPageReducer = ( draft ) => {

    // we've stored the size so we can set the search panel's
    // minimum height to match the pegboard.
    // We can also work out the maximum number of jobs
    // that can be displayed at this size.
    // Note that we can display more jobs on the first page
    // if there is no pager to be displayed.

    const searchPanelHeight = draft.searchPanelHeight;
    const absoluteMax = Math.max( Math.floor( ( searchPanelHeight - JOB_HEADER_HEIGHT ) / JOB_HEIGHT ), 1 );
    const pagerMax = Math.max( Math.floor( ( searchPanelHeight - ( JOB_HEADER_HEIGHT + JOB_PAGER_HEIGHT ) ) / JOB_HEIGHT ), 1 );

    // resizing doesn't change offset unless we can move to a single page;
    // if we're display records 4-6 of 10 and we grow the page, we simply
    // display 4-7 or whatever, we don't adjust the offset to start from 5 because
    // the front page can now hold 1-4. Jobs disappearing is bad.
    draft.tabs.forEach( ( draftItem ) => {
        if ( draftItem?.filteredJobIds && draftItem?.filteredJobIds?.length <= absoluteMax )
            draftItem.numberVisibleJobs = absoluteMax;
        else
            draftItem.numberVisibleJobs = pagerMax;
    });
};

const updateFilterListReducer = ( draft, payload ) => {
    const { selectedFilterList, id = draft.activeTabId } = payload;

    const index = draft?.tabs?.findIndex( t => t.id === id );
    if ( index >= 0 ) {
        draft.tabs[ index ].filterList = selectedFilterList;
        saveSearchPanelToUserSession( draft );
    }
};

const saveSearchPanelToUserSession = ( draft ) => {
    const { tabs } = draft;
    const filteredTabs = tabs.map( tab => {
        const { id, filterString, filterList } = tab;
        return  {
            id,
            filterString,
            filterList,
            jobMap:            {},
            filteredJobIds:    null,
            jobOffset:         0,
            numberVisibleJobs: 0
        };
    });

    const searchPanel = {
        tabs:        filteredTabs,
        activeTabId: draft.activeTabId
    };
    saveToUserSession({ searchPanel });
};


const removeFromSearchPanelReducer = ( draft, id ) => {
    const searchPanel = draft?.tabs;
    const tabIndex = id;
    if ( tabIndex >= 0 ) {
        let newActiveTabIdIndex;
        if ( tabIndex === searchPanel.length - 1 )
            newActiveTabIdIndex = searchPanel.length - 2;
        else if ( tabIndex <= 0 )
            newActiveTabIdIndex = 0;
        else
            newActiveTabIdIndex = tabIndex - 1;
        draft.tabs.splice( tabIndex, 1 );
        draft.activeTabId = draft?.tabs[ newActiveTabIdIndex ]?.id;
        saveSearchPanelToUserSession( draft );
    }
};

const setJobOffsetReducer = ( draft, payload ) => {
    const { id, offset } = payload;
    const index = draft?.tabs?.findIndex( t => t.id === id );
    if ( index >= 0 )
        draft.tabs[ index ].jobOffset = offset;
};

const setNumberVisibleJobsReducer = ( draft, payload ) => {
    const { id, numberVisibleJobs } = payload;
    const index = draft?.tabs.findIndex( t => t.id === id );
    if ( index >= 0 )
        draft.tabs[ index ].numberVisibleJobs = numberVisibleJobs;
};

const addToSearchPanelReducer = ( draft, payload ) => {
    const tabId = uuid();
    const {  jobCardCreatedTab, userId, role, ...restPayload } = payload;
    draft.activeTabId = tabId;
    draft.searchPanelHeight = 0;
    draft?.tabs?.push({
        id:                   tabId,
        jobMap:               {},
        filteredJobIds:        null,
        filterString:         '',
        jobOffset:            0,
        numberVisibleJobs:    0,
        jobCardCreatedTab:    jobCardCreatedTab,
        filterList:           [
            {
                type:           'icon',
                icon:           'hand',
                label:          'Assigned to me',
                isFilterButton: true,
                userId:         userId,
                role:           role
            },
            {
                type:           'status',
                icon:           'calendar',
                slashed:        true,
                isFilterButton: true,
                ...restPayload.notScheduledStatus
            }
        ]
    });
    saveSearchPanelToUserSession( draft );
};

const updateActiveTabIdReducer = ( draft, payload ) => {
    const { id } = payload;
    draft.activeTabId = id;
    saveSearchPanelToUserSession( draft );
};


const reducers = {
    [ setJobs ]:                        setJobsReducer,
    [ setPatchJob ]:                    patchJobReducer,
    [ setJobOffset ]:                   setJobOffsetReducer,
    [ setNumberVisibleJobs ]:           setNumberVisibleJobsReducer,
    [ setSearchPanelHeight ]:    ( draft, searchPanelHeight ) => {
        draft.searchPanelHeight = searchPanelHeight;
    },
    [ setCalculateJobPage ]:            calculateJobPageReducer,
    [ setRemoveFromSearchPanel ]:       removeFromSearchPanelReducer,
    [ setAddToSearchPanel ]:            addToSearchPanelReducer,
    [ setFilterList ]:                  updateFilterListReducer,
    [ setActiveTabId ]:                 updateActiveTabIdReducer,
    [ setSearchPanel ]:             ( draft, payload ) => {
        const { tabs, activeTabId } = payload;
        draft.tabs = tabs;
        draft.activeTabId = activeTabId;
    },
    [ setFilterString ]: ( draft, payload ) => {
        const { id, filterString } = payload;
        const index = draft?.tabs?.findIndex( t => t.id === id );
        if ( index >= 0 )
            draft.tabs[ index ].filterString = filterString;

    },
    [ setTypeNames ]:                   setSomeTypeNames( integrationNames.JOB_STATUS ),
    [ setJobCardCreatedTab ]: ( draft, payload ) => {
        draft.tabs.forEach( tab => tab.jobCardCreatedTab = payload.jobCardCreatedTab );
    }
};

/** selectors */

const selectSearchPanel = state => state.searchPanel?.tabs;
const selectActiveTabId = state => state.searchPanel?.activeTabId;
const selectActiveTabIndex = state => {
    const activeTabId = state?.searchPanel?.activeTabId;
    const index = state?.searchPanel?.tabs?.findIndex( t => t.id === activeTabId );
    if ( index >= 0 )
        return index;
    else
        return 0;
};
const getJobCardCreatedTab = state => {
    const activeTabId = state?.searchPanel?.activeTabId;
    const index = state?.searchPanel?.tabs?.findIndex( t => t.id === activeTabId );
    if ( index >= 0 )
        return state?.searchPanel?.tabs[ index ]?.jobCardCreatedTab;
    else
        return false;
};


const selectJobState = ( state, id ) => {
    const job = state?.searchPanel?.tabs?.find( t => t.id === id );
    const { jobMap, filteredJobIds, filterString, jobOffset, numberVisibleJobs, filterList, total } = job;
    return {
        jobMap:         jobMap || {},
        filteredJobIds: filteredJobIds || [],
        filterString,
        jobOffset,
        numberVisibleJobs,
        filterList,
        total
    };
};

const removeFromSearchPanel = id => ( dispatch ) => {
    dispatch( setRemoveFromSearchPanel( id ) );
};

const addToSearchPanel = ( payload ) => ( dispatch, getState ) => {
    const  { jobCardCreatedTab } = payload;
    const { jobcard: { jobStatus },  user: { userId, role }  } = getState();
    const notScheduledStatus =  jobStatus.nameToId[ StatusNames.NOT_SCHEDULED ];
    dispatch( setAddToSearchPanel({ notScheduledStatus: { id: notScheduledStatus, label: notScheduledStatus.name },
        jobCardCreatedTab:  jobCardCreatedTab || false, userId, role }) );
};

const updateFilterList = ( selectedFilterList, id ) => ( dispatch ) => {
    dispatch( setFilterList({ selectedFilterList, id }) );
};

const updateActiveTabId = ( id ) => ( dispatch ) => {
    dispatch( setActiveTabId({ id }) );
};

const addNewTabWithClient = ( client ) => ( dispatch, getState ) => {
    dispatch( setMeta({ searchPanelShown: true }) );
    dispatch( addToSearchPanel({ jobCardCreatedTab: true }) );
    // 73 is the height of the header
    let newHeight = window.innerHeight - 73;
    if ( newHeight < 0 )
        newHeight = window.innerHeight;
    dispatch( windowHeightChanged( newHeight ) );
    const { activeTabId } = getState().searchPanel.activeTabId;
    // update the company object to add label and userId for client user
    if ( client?.userId )
        dispatch( updateFilterList([ { ...client, userId: client?.userId, label: client?.name  } ], activeTabId ) );
    else
        dispatch( updateFilterList([ { ...client, label: client?.companyName, companyId: client?.companyId  } ], activeTabId ) );


};

const getJobsForTab = ( id ) => ( dispatch, getState ) => {
    const { searchPanel } = getState();
    const { activeTabId, tabs, searchPanelHeight } = searchPanel;
    id ??= activeTabId;
    const index = tabs.findIndex( t => t.id === id );
    if ( index < 0 )
        return;
    const filterList = tabs[ index ]?.filterList;
    const filterString = tabs[ index ]?.filterString;

    const pageSize = tabs[ index ]?.numberVisibleJobs || 10;
    const page = Math.ceil( tabs[ index ]?.jobOffset / pageSize );

    let userId = [];
    let engineerId = [];
    let companyId = [];
    let statusId = [];
    let terms = [];

    // Iterate through the filterList array to get the userIds, companyIds, statusIds and free text for query
    filterList.forEach( item => {
        if ( item.userId ) {
            if ( item.role === UserRole.engineer )
                engineerId.push( item.userId );
            else
                userId.push( item.userId );

        } else if ( item.type === searchPanelChipType.TEAM ) {
            item?.members.forEach( member => {
                if ( member.user.role === UserRole.engineer )
                    engineerId.push( member.user.userId );
                else
                    userId.push( member.user.userId );
            });
        } else if ( item?.companyId || ( item.company && item.company.companyId ) ) {
            const value = item?.companyId || item.company.companyId;
            companyId.push( value );
        } else if ( item.type === searchPanelChipType.STATUS ) {
            const value = item.id;
            statusId.push( value );
        } else if ( item.type === searchPanelChipType.ICON ) {
            const value = item.statusId;
            statusId.push( value );
        } else if ( typeof item === searchPanelChipType.STRING )
            terms.push( item );

    });

    if ( filterString )
        terms.push( filterString );
    dispatch( getJobs({ userId, engineerId, companyId, statusId, terms, pageSize, page }) );
};

export {
    getJobs,
    getJobsForTab,
    selectJobState,
    windowHeightChanged,
    getJobNotes,
    filterStringChanged,
    checkForAddedJobThunk,
    selectSearchPanel,
    removeFromSearchPanel,
    addToSearchPanel,
    updateFilterList,
    selectActiveTabId,
    selectActiveTabIndex,
    updateActiveTabId,
    addNewTabWithClient,
    clearFilterString,
    getJobCardCreatedTab,
    jobStatusMap
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
