/*********************************************************************************************************************
 * @file Company reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21-Dec-2020
 *********************************************************************************************************************/

import { setCompanyCompany } from '../../redux/actions/index.js';
import { ezRedux, genericReducer } from '../../redux/reducerUtil.js';

/**
 * @typedef {object} CompanyState
 * @property {string} _id
 */

/**
  * @type {CompanyState}
  */
const initialState = Object.freeze({
});

/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setCompanyCompany ]:      genericReducer
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
