import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: ({
        padding: "30px 40px"
    })
});

export const Section = props => (
    <section className={`${useStyles( props ).root} ${props.className || ''}`}>
        {props.children}
    </section>
);


Section.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node
};
