import React, { useCallback } from 'react';

import { Priority } from '@licoriceio/constants';
import { nop } from '@licoriceio/utils';
import { FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

import { UX_JOBCARD_PRIORITY_BUTTON, UX_JOBCARD_PRIORITY_MENU } from '../../../../ux-constants.js';
import { dispatchChangeAction, dispatchBlurThunk } from '../../../../redux/reducerUtil.js';
import { priorityButton, button, labelRoot, label } from '../../../../scss/PriorityButton.module.scss';
import { LicoIcon } from '../../images/LicoIcon.jsx';
import { NavMenuListButtonItem } from '../../navigation/NavMenuListButtonItem.jsx';
import { NavMenuTrigger } from '../../navigation/NavMenuListTrigger.jsx';
import { PriorityColorMap } from '../../theme.js';

const priorityIcons = {
    [ Priority.normal ]:   "user",
    [ Priority.high ]:     "users",
    [ Priority.critical ]: "building"
};

const priorityEntries = Object.entries( PriorityColorMap );

export const PriorityButton = props => {
    const {
        onSelect = nop,
        value = Priority.normal,
        name,
        disabled = false,
        labelClass,
        formPackage,
        slicePackage
    } = props;

    const handleClick = useCallback( ( event, value ) => {
        if ( formPackage )
            formPackage.onChange({ id: formPackage.id, field: name, value });
        else if ( slicePackage ) {
            dispatchChangeAction( slicePackage, { updates: { [ name ]: value } });
            dispatchBlurThunk( slicePackage, { field: name });
        }
        else
            onSelect( event, value );
    }, [ formPackage, slicePackage, onSelect, name ]);

    return <div className={priorityButton} data-ux={UX_JOBCARD_PRIORITY_BUTTON}>

        <FormControlLabel
            classes={{ root: labelRoot, label: labelClass || label }}

            control={
                <NavMenuTrigger
                    menuId="priorityMenu"
                    isFab={true}
                    buttonProps={{
                        className: button,
                        style:     { backgroundColor: PriorityColorMap[ value ] },
                        disabled:  disabled,
                        children:  ""
                    }}
                    buttonChildren={ <LicoIcon icon={priorityIcons[ value ]} /> }
                    menuListLabel="Priority"
                    dataName={UX_JOBCARD_PRIORITY_MENU}
                >
                    {
                        priorityEntries.map( v => (
                            <NavMenuListButtonItem
                                value={v[ 0 ]}
                                data-value={v[ 0 ]}
                                onClick={( event ) => {
                                    handleClick( event, v[ 0 ]);
                                }}
                                text={v[ 0 ]}
                                key={v[ 0 ]}
                            >
                                <LicoIcon icon={priorityIcons[ v[ 0 ] ]} style={{ color: v[ 1 ] }} />
                            </NavMenuListButtonItem>
                        ) )
                    }
                </NavMenuTrigger >
            }
            label="Priority:" />
    </div>;
};

PriorityButton.propTypes = {
    onSelect:     PropTypes.func,
    value:        PropTypes.string,
    name:         PropTypes.string,
    disabled:     PropTypes.bool,
    labelClass:   PropTypes.string,
    formPackage:  PropTypes.object,
    slicePackage: PropTypes.object
};
