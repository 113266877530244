import makeStyles from '@mui/styles/makeStyles';

const popOverUseStyles = makeStyles({

    list: {
        backgroundColor: 'var(--black-0)',
        borderRadius:    4,
        padding:         '0px !important',
        display:         'flex',
        flexDirection:   'column',
        alignItems:      'center',
        justifyContent:  'center',
        margin:          '0px !important'
    },
    listItem: {
        display:         'flex',
        flexDirection:   'column',
        alignItems:      'flex-start',
        justifyContent:  'center',
        padding:          '6px 6px 5px 10px !important',
        "&:hover":       {
            background: "var(--black-1)"
        },
        "&:active": {
            "& .menuIcon": {
                background: "var(--white)",
                color:      "var(--lime)"
            }
        }
    },
    listItemGroup: {
        display:        'flex',
        flexDirection:  'row',
        alignItems:     'center',
        justifyContent: 'space-between',
        width:          '100%',
        fontSize:       '12px'
    },
    listItemText: {
        paddingLeft: '8px',
        color:       "var(--white)",
        flexBasis:   '95%',
        flexShrink:  0
    },
    listItemIconGroup: {
        display:        'flex',
        flexBasis:      '5%',
        justifyContent: 'center'
    },
    listItemIconSize: {
        maxWidth:    '14px',
        minWidth:    '14px'
    },
    listIcon: {
        width:  '12px',
        height: '12px',
        margin: '1.5px 10.5px 4.5px',
        color:  'var(--cobalt-3)'
    },
    listText: {
        fontSize:     '12px',
        color:        'var(--white)',
        whiteSpace:   'nowrap',
        overflow:     'hidden',
        textOverflow: 'ellipsis',
        paddingLeft:    '10px'
    }


});

export default popOverUseStyles;
