/********************************************************************************************************************
 * @file Actions for the job store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setUpdateJob = createAction( 'JOB.UPDATE_JOB/SET' );
export const setUpdateCompany = createAction( 'JOB.UPDATE_COMPANY/SET' );
export const setSplitJobs = createAction( 'JOB.SPLIT_JOBS/SET' );
export const setDeleteJob = createAction( 'JOB.DELETE_JOB/SET' );
export const setAddJobs = createAction( 'JOB.JOBS/SET' );
export const setJobChange = createAction( 'JOB.JOBS/CHANGE' );
export const setFileUploadStart = createAction( 'JOB.FILE_UPLOAD_START/SET' );
export const setFileUploadProgress = createAction( 'JOB.FILE_UPLOAD_PROGRESS/SET' );
export const setFileUploadComplete = createAction( 'JOB.FILE_UPLOAD_COMPLETE/SET' );
