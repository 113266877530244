import { has } from '@licoriceio/utils';

import { store } from '../publicStore.js';
import { setDraftNoteText } from '../redux/actions/index.js';

import { initializeCacheFunctions } from './cache-data.js';
import { setSocket, shutdownSocket } from './socketClient.js';

let socketInitialized = false;
const dispatch = obj => store.dispatch( obj );

const cacheDataAction = {
    draftNote: setDraftNoteText
};

const cacheDataHandler = data => {
    if ( data.empty ) {
        dispatch( cacheDataAction[ data.type ]({ text: '' }) );
        return;
    }

    if ( cacheDataAction[ data.type ]) {
        if ( !has( data, 'text' ) )
            data.text = '';
        dispatch( cacheDataAction[ data.type ]( data ) );
    }
    else
        throw new Error( 'Unhandled cache data: ' + JSON.stringify( data ) );
};

function initializeSocket({ token, userId })
{
    if ( socketInitialized ) return;
    socketInitialized = true;

    const sfn = setSocket({ token, userId, cacheDataHandler });
    initializeCacheFunctions( sfn );
}

function closeSocket() {
    if ( socketInitialized ) {
        socketInitialized = false;
        shutdownSocket();
    }
}

export {
    initializeSocket,
    closeSocket
};
