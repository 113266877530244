import React from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

const SCROLLBAR_WIDTH = 14;

const styles = createStyles({
    root: ({
        display: 'flex'
    }),
    listScroller: {
        overflowY: "hidden",
        maxHeight: props => props.maxHeight
    },
    label: {
        fontSize:      14,
        alignSelf:     "end",
        fontFamily:    "gotham-medium, sans-serif",
        color:         "var(--black-1)",
        paddingRight:  10,
        paddingBottom: 4
    }
});

const RawLicoTable = ({ classes, labels, widths, children }) => (
    <div>
        <div className={classes.root}>
            {
                labels.map( ( label, i ) =>
                    <div key={i} className={classes.label} style={{ width: widths[ i ] }}>
                        {labels[ i ]}
                    </div>
                )
            }
            <div style={{ minWidth: SCROLLBAR_WIDTH }} />
        </div>
        <div className={classes.listScroller} >
            {children}
        </div>
    </div>
);

RawLicoTable.propTypes = {
    labels:    PropTypes.arrayOf( PropTypes.string ).isRequired,
    widths:    PropTypes.arrayOf( PropTypes.string ).isRequired,
    classes:   PropTypes.object.isRequired,
    children:  PropTypes.node
};

const LicoTable = withStyles( styles )( RawLicoTable );
export { LicoTable };
