import React from 'react';

import { makeStyles }        from '@mui/styles';
import PropTypes from 'prop-types';

import ErrorAlert from '../ErrorAlert.jsx';

import { AuthHeader }        from './sections/AuthHeader.jsx';


const useStyles = makeStyles({
    rootSAL: ({
        display:         "flex",
        flexFlow:        "column nowrap",
        backgroundColor: "var(--black-1)",
        overflow:        "hidden"
    }),
    bodySAL: {
        minHeight:     "100%",
        display:       "flex",
        flexDirection: "row",
        overflow:      "hidden"
    }
});

export const StandardAuthLayout = props => {
    const {
        children,
        headerChildren
    } = props;
    const classes = useStyles( props );

    return (
        <main className={classes.rootSAL}>
            <AuthHeader>{headerChildren} </AuthHeader>
            <ErrorAlert />
            <div className={classes.bodySAL}>{children}</div>
        </main>
    );
};

StandardAuthLayout.propTypes = {
    headerChildren: PropTypes.node,
    children:       PropTypes.node
};
