import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bottomRow, spacer } from '../../scss/BottomRow.module.scss';
import { LicoButton } from "../common/index.js";

import { setProgress } from './reducer.js';

/**
 * @typedef {object} BottomRowProps
 * @property {string} previousLabel
 * @property {number} previousStage
 * @property {string} nextLabel
 * @property {number} nextStage
 * @property {boolean} [nextDisabled]
 */

const BottomRow = ({
    previousLabel,
    previousStage,
    nextLabel,
    nextStage,
    nextDisabled = false,
    setProgress
}) => (
    <div className={bottomRow}>
        <LicoButton
            colorClassName="grey4"
            onClick={() => setProgress( previousStage )}
        >
            {previousLabel}
        </LicoButton>
        <span className={spacer}>&nbsp;</span>
        <LicoButton
            onClick={() => setProgress( nextStage )}
            disabled={nextDisabled}
        >
            {nextLabel}
        </LicoButton>
    </div>
);

BottomRow.propTypes = {
    previousLabel: PropTypes.string.isRequired,
    previousStage: PropTypes.number.isRequired,
    nextLabel:     PropTypes.string.isRequired,
    nextStage:     PropTypes.number.isRequired,
    nextDisabled:  PropTypes.bool,
    setProgress:   PropTypes.func
};

const mapDispatchToProps = { setProgress };

export default connect( null, mapDispatchToProps )( BottomRow );
export { BottomRow };
