/*********************************************************************************************************************
 * @file UserCalendar component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { PureComponent }                     from 'react';

import { DAY } from "@licoriceio/constants";
import PropTypes                                    from 'prop-types';
import { connect }                                  from 'react-redux';

import { selectCurrentCalendarEventId, selectCurrentJobCardId, selectSystemReady, selectUser } from '../../redux/selectors/index.js';
import { MainCalendarStyle, innerBox, outerBox }    from '../../scss/MainCalendar.module.scss';
import TimedActionPanel                             from '../common/TimedActionPanel.jsx';

import CalendarSearchBar                            from './CalendarSearchBar.jsx';
import { selectCalendars }                        from './reducer.js';
import UserCalendar                                 from './UserCalendar.jsx';

// track drags via a local variable
let _dragInProgress = false;

class MainCalendar extends PureComponent {
    constructor( props )
    {
        super( props );

        this._innerboxRef = React.createRef();
        this._scrollTimer = 0;
        this._scrollPeriod = 500;
        this._dwellPeriodMsecs = 500;
    }

    componentDidMount() {
        if ( this._innerboxRef.current ) {
            this._innerboxRef.current.addEventListener( 'mousemove', this.mousemovehandler );
            this._innerboxRef.current.addEventListener( 'mouseup', this.stopScrollTimer );
        }
    }

    componentWillUnmount() {
        if ( this._innerboxRef.current ) {
            this._innerboxRef.current.removeEventListener( 'mousemove', this.mousemovehandler );
            this._innerboxRef.current.removeEventListener( 'mouseup', this.stopScrollTimer );
        }
    }

    registerCalendarRef = ( userId, calendarRef ) => {
        if ( !userId ) return;
        this.calendarRefs = this.calendarRefs ?? {};
        this.calendarRefs[ userId ] = calendarRef;
    };

    doScroll = ( box, amount, waitForDwell ) => {
        const initialScrollTop = box.scrollTop;

        if ( waitForDwell && this._scrollTimer && this._scrollPeriod > 20 ) return;

        // multiple scroll events can spawn vey quickly as the mouse moves,
        // make sure any existing timeout is cleared
        this.stopScrollTimer();

        if ( waitForDwell )
        {
            // this event came from a mouse move; if it fires, the mouse paused in the scroll region
            // for long enough to start the scrolling, which will now proceed via
            // timed events. However, if we move again
            // console.log("start wait for dwell")
            const innerbox = document.getElementById( 'innerbox' );
            if ( innerbox )
                innerbox.setAttribute( 'data-period', "500" );

            this._scrollTimer = setTimeout( () => { this.doScroll( box, amount, false ); }, 500 );
            this._scrollPeriod = 500;
        }
        else
        {
            box.scrollBy( 0, amount );

            const diff = box.scrollTop - initialScrollTop;

            if ( ( diff > 1 || diff < -1 ) )
            {
                this._scrollPeriod = 20;
                this._scrollTimer = setTimeout( () => { this.doScroll( box, amount, false ); }, 20 );
                const innerbox = document.getElementById( 'innerbox' );
                if ( innerbox )
                    innerbox.setAttribute( 'data-period', "20" );
            }
        }
    };

    stopCalendarScrollTimer = () => {
        if ( this.calenderScrollTimer ) {
            clearInterval( this.calenderScrollTimer );
            this.calenderScrollTimer = 0;
        }
    };

    handleArrowHoverHandler = ( e ) => {
        // ignore any events that don't have the left button pressed
        if ( !_dragInProgress ) return;

        this.stopCalendarScrollTimer();

        let userId = '';

        const leftScrollHandles = document.getElementsByClassName( "left-scroll-handle" );
        const rightScrollHandles = document.getElementsByClassName( "right-scroll-handle" );

        this.clearActiveHandles( false );

        if ( leftScrollHandles && rightScrollHandles )
        {
            let scrollDirection = 0;

            for ( let i = 0; i < leftScrollHandles?.length; i++ ) {
                const leftRect = leftScrollHandles?.[ i ]?.getBoundingClientRect();
                if ( e.clientX >= leftRect.left && e.clientX <= leftRect.right && e.clientY >= leftRect.top && e.clientY <= leftRect.bottom )
                {
                    userId = leftScrollHandles?.[ i ]?.getAttribute( "data-userid" );
                    scrollDirection = -1;
                    leftScrollHandles?.[ i ]?.classList.add( "active-calendar-scroll-handle" );
                }
                else
                {
                    const rightRect = rightScrollHandles?.[ i ]?.getBoundingClientRect();
                    if ( e.clientX >= rightRect.left && e.clientX <= rightRect.right && e.clientY >= rightRect.top && e.clientY <= rightRect.bottom )
                    {
                        userId = rightScrollHandles?.[ i ]?.getAttribute( "data-userid" );
                        scrollDirection = 1;
                        rightScrollHandles?.[ i ]?.classList.add( "active-calendar-scroll-handle" );
                    }
                }
            }

            if ( scrollDirection )
                this.calenderScrollTimer = window.setInterval( () => { this.doCalendarScroll( userId, scrollDirection ); }, this._dwellPeriodMsecs );

        }

    };

    mousemovehandler = e => {

        this.handleArrowHoverHandler( e );


        // ignore any events that don't have the left button pressed
        if ( e.buttons !== 1 ) return;

        const innerbox = document.getElementById( 'innerbox' );

        if ( innerbox )
        {
            // a generic solution would involve navigating up the tree and summing the offsetTop values.
            const yPosition = e.clientY - ( innerbox.offsetTop + innerbox.offsetParent.offsetTop );

            // there's a ~60px difference between height and max reported Y value; don't know why,
            // maybe a margin? This happens to be the height of the calendar nav bar but I don't see
            // a link.
            const yMax = innerbox.clientHeight - 60;

            const scrollZoneHeight = yMax * 0.15;
            const inTopZone = yPosition < scrollZoneHeight;
            const startBottomZone = yMax - scrollZoneHeight;
            if ( inTopZone || yPosition > startBottomZone ) {

                // in the zone...
                const [ scrollDirection, scrollProportion ] = inTopZone
                    ? [ -1, ( scrollZoneHeight - yPosition ) ]
                    : [ 1, ( yPosition - startBottomZone ) ];

                // the magic number here is the max scroll amount. We use max() to ensure
                // we don't scroll a tiny amount which can be mistaken for end of scroll.
                const scrollAmount = scrollDirection * Math.max( 5, 25 * scrollProportion / scrollZoneHeight );
                // console.log("scrollAmount", scrollAmount);
                let waitForDwell = true;

                if ( this._scrollTimer )
                {
                    // if the dwell timer was running and we're still in the zone,
                    // do nothing; the scroll will start when the timer fires. Prevents
                    // the user from having to freeze the mouse to start scroll.
                    const period = Number( innerbox.getAttribute( 'data-period' ) );
                    if ( period > 20 ) {
                        // console.log("still waiting for dwell", period);
                        return;
                    }
                    waitForDwell = false;
                }

                this.doScroll( innerbox, scrollAmount, waitForDwell );
                return;
            }
        }

        // clear any existing timer if we're not in the zone
        if ( this._scrollTimer ) {
            // console.log("stop timer, out of zone");
            clearTimeout( this._scrollTimer );
            this._scrollTimer = 0;
        }
    };

    stopScrollTimer = () => {
        if ( this._scrollTimer ) {
            clearTimeout( this._scrollTimer );
            this._scrollTimer = 0;
        }
    };

    setDragState = ( dragState ) => _dragInProgress = dragState;

    doCalendarScroll = ( userId, direction ) => {
        if ( !userId ) return;
        const calendar = this.calendarRefs?.[ userId ]?.current.getApi();
        if ( calendar === undefined ) {
            console.warn( 'scroll w/o cal', calendar );
            return;
        }

        const currentDate = calendar.getDate();

        // assume 3,5 or 7 day views for now
        const scrollDays = calendar.view.getOption( 'duration' ).days === 3
            ? 3
            : 7;

        calendar.gotoDate( new Date( currentDate.getTime() + ( scrollDays * DAY * direction ) ) );
        const calendarDiv = document.getElementById( userId );
        if ( calendarDiv ) {
            const title = calendarDiv.querySelector( ".fc-toolbar-title" );
            if ( title )
                title.style.animationPlayState = "running";
        }
    };

    clearActiveHandles = ( hideHandles = true ) => {
        const handles = document.getElementsByClassName( "base-calendar-scroll-handle" );

        Array.from( handles ).forEach( ( handle ) => {
            handle.classList.remove( "active-calendar-scroll-handle" );
            if ( hideHandles && handle instanceof HTMLElement )
                handle.style.opacity = "0";

        });
    };

    render() {
        const {
            user,
            allCalendars,
            currentJobCardId,
            currentCalendarEventId,
            systemReady
        } = this.props;

        return <div className={MainCalendarStyle}>

            {user && <CalendarSearchBar user={user} />}

            <div id="outerbox" className={outerBox} >
                <div id="innerbox" className={innerBox} ref={this._innerboxRef}>
                    {systemReady && <>
                        {allCalendars.map( calendar =>
                            <UserCalendar
                                registerCalendarRef={this.registerCalendarRef}
                                key={calendar.user.userId}
                                user={calendar.user}
                                setDragState={this.setDragState}
                                className={`${calendar.user.userId === user.userId ? '' : 'otherUser'}`}
                            /> )}
                    </>}
                </div>
            </div>
            { !currentJobCardId && !currentCalendarEventId && <TimedActionPanel />}
        </div>;
    }

    static propTypes = {
        user:                   PropTypes.object.isRequired,
        allCalendars:           PropTypes.arrayOf( PropTypes.any ).isRequired,
        currentJobCardId:       PropTypes.string,
        currentCalendarEventId: PropTypes.string,
        systemReady:            PropTypes.bool.isRequired
    };
}

const mapStateToProps = state => ({
    user:                   selectUser( state ),
    allCalendars:           selectCalendars( state ),
    currentJobCardId:       selectCurrentJobCardId( state ),
    currentCalendarEventId: selectCurrentCalendarEventId( state ),
    systemReady:            selectSystemReady( state )
});

export default connect( mapStateToProps )( MainCalendar );
export { MainCalendar };
