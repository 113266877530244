import { uri, POST, PATCH, GET } from '../../constants.js';

const createLoadingSelector = label => state => !!( state.loading[ label ]);

export const createMultiLoadingSelector = uris => state => {
    const values = Object.entries( state.loading );
    const filteredValues = values.filter( v => uris.includes( v[ 0 ]) ).map( v => v[ 1 ]);
    return !!filteredValues.find( v => v );
};

export const getAnyLoading = ( state, labels = []) => labels.some( label => state.loading[ label ]);

// the base request code now dispatches the loading actions using method + URI
export const selectIsAcceptInviteLoading = createLoadingSelector( PATCH + uri.ACCEPT_INVITATION );
export const selectIsCheckInviteLoading = createLoadingSelector( GET + uri.SINGLE_INVITATION );
export const selectIsUserForgotPasswordLoading = createLoadingSelector( POST + uri.USER_PASSWORD_TOKEN );
export const selectIsFetchResetTokenLoading = createLoadingSelector( GET + uri.USER_TOKEN_VALIDATION );
export const selectIsResetPasswordLoading = createLoadingSelector( PATCH + uri.USER_RESET_PASSWORD );

const cursorLoadExcludeList = [];

// Anything we want to display a loading cursor for
export const selectAnyLoading = state => {
    const values = Object.entries( state.loading );
    const filteredValues = values.filter( v => !cursorLoadExcludeList.includes( v[ 0 ]) ).map( v => v[ 1 ]);

    return !!filteredValues.find( v => v );
};
