import React from 'react';

import { Priority } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setJobTitle, setJobDescription, setJobPriority } from '../../redux/actions/index.js';
import { root, titleClass, spacer, criticalMessage, labelClass } from '../../scss/LandingPage.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { LicoTextField, PriorityButton } from "../common/index.js";

import BottomRow from './BottomRow.jsx';
import { progressStage } from './reducer.js';
import TopRow    from './TopRow.jsx';


const LandingPage = ({
    title,
    description,
    priority,
    job,
    criticalPriorityMessage,
    foundEligibles,
    setJobTitle,
    setJobDescription,
    setJobPriority
}) => (
    <div className={root}>
        <TopRow job={job} />

        <LicoTextField
            inputClassName={titleClass}
            label={__( "Title" )}
            onChange={e => setJobTitle( e.target.value )}
            value={title}
        />

        <LicoTextField
            label={__( "Description" )}
            multiline
            minRows={8}
            onChange={e => setJobDescription( e.target.value )}
            value={description}
        />

        <PriorityButton
            value={priority}
            onSelect={( e, value ) => setJobPriority( value )}
            labelClass={labelClass}
        />

        <div className={criticalMessage}>
            {priority === Priority.critical ? criticalPriorityMessage : ''}
        </div>

        <div className={spacer}>&nbsp;</div>

        <BottomRow
            previousLabel={__( "< Cancel" )}
            previousStage={progressStage.CANCELLED}
            nextLabel={__( "Continue >" )}
            nextStage={progressStage.CALENDAR}
            nextDisabled={!foundEligibles || priority === Priority.critical}
        />
    </div>
);

LandingPage.propTypes = {
    title:                      PropTypes.string.isRequired,
    description:                PropTypes.string.isRequired,
    priority:                   PropTypes.string.isRequired,
    job:                        PropTypes.object.isRequired,
    foundEligibles:             PropTypes.bool.isRequired,
    setJobTitle:                PropTypes.func.isRequired,
    setJobDescription:          PropTypes.func.isRequired,
    setJobPriority:             PropTypes.func.isRequired,
    criticalPriorityMessage:    PropTypes.string
};

const mapStateToProps = state => {

    const { scheduling: { job, criticalPriorityMessage, foundEligibles } } = state;
    const { title, description, priority } = job;

    return {
        job, title, description, priority, criticalPriorityMessage, foundEligibles
    };
};

const mapDispatchToProps = {
    setJobTitle,
    setJobDescription,
    setJobPriority
};

export default connect( mapStateToProps, mapDispatchToProps )( LandingPage );
export { LandingPage };
