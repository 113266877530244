/** ******************************************************************************************************************
 * @file Main file loader for the `@licoriceio/constants` module.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 29-Oct-2020
 *********************************************************************************************************************/

export * from './src/database/index.js';
export * from './src/connector/index.js';
export * from './src/api/index.js';
export * from './src/coms.js';

/** @type {{[ s: string] , NotificationType}} */
export const NotificationTypes = {
    APPOINTMENT_ASSIGNED:           'APPOINTMENT_ASSIGNED',     // Accept or reject
    INTEGRATION_COMPLETE:           'INTEGRATION_COMPLETE',
    INTEGRATION_FAILED:             'INTEGRATION_FAILED',
    INTEGRATION_PROGRESS:           'INTEGRATION_PROGRESS',     // Keep as hidden to update progress bar
    UPLOAD_PROGRESS:                'UPLOAD_PROGRESS',          // Keep as hidden to update file progress
    JOB_CREATED:                    'JOB_CREATED',              // online only, passive
    JOB_STATUS_CHANGED:             'JOB_STATUS_CHANGED',       // passive
    REOPENED_JOB:                   'REOPENED_JOB',             // Owner only
    RESOLVED_JOB:                   'RESOLVED_JOB',             // Owner only
    APPOINTMENT_REJECTED:           'APPOINTMENT_REJECTED',
    DATA_RECORDS_UPDATE:            'DATA_RECORDS_UPDATE',
    PROVIDER_ERROR:                 'PROVIDER_ERROR',
    APPOINTMENT_CHANGED:            'APPOINTMENT_CHANGED',
    APPOINTMENT_DELETED:            'APPOINTMENT_DELETED',      // deleted on provider
    JOB_DELETED:                    'JOB_DELETED',
    HEARTBEAT_NORMAL:               'HEARTBEAT_NORMAL',
    HEARTBEAT_SLOW:                 'HEARTBEAT_SLOW',           // Not sent to FE, it gets the flatline instead
    HEARTBEAT_FLATLINE:             'HEARTBEAT_FLATLINE',
    TICKETS_IMPORTED:               'TICKETS_IMPORTED',
    SERVER_MESSAGE:                 'SERVER_MESSAGE',
    DUPLICATE_KEY:                  'DUPLICATE_KEY',
    ENGINEER_ACTIVATED:             'ENGINEER_ACTIVATED',
    ENGINEER_DEACTIVATED:           'ENGINEER_DEACTIVATED',
    ENGINEER_ADDED:                 'ENGINEER_ADDED',
    JOB_ENGINEER_ADDED:             'JOB_ENGINEER_ADDED',
    JOB_ENGINEER_REMOVED:           'JOB_ENGINEER_REMOVED',
    JOB_NOTE_ADDED:                 'JOB_NOTE_ADDED',
    MAPPED_STATUS_NOT_ON_BOARD:     'MAPPED_STATUS_NOT_ON_BOARD',
    PROVIDER_STATUS_NOT_SELECTED:   'PROVIDER_STATUS_NOT_SELECTED',
    SYNC_PROGRESS:                  'SYNC_PROGRESS',
    NOTES_ADDED:                    'NOTES_ADDED'
};

export const IntegrationStages = {
    STARTING:       0,
    USER:           1,
    LINK:           2,
    ENGINEERS:      3,
    COMPANY_IDS:    4,
    SITE_COUNTS:    5,
    SITES:          6,
    CONTACTS:       7,
    COMPANIES:      8,
    ASSET_TYPES:    9,
    ASSET_STATUSES: 10,
    MANUFACTURERS:  11,
    SLAS:           12,
    ASSETS:         13,
    EVENTS:         14,
    TICKETS:        15,
    COMPLETING:     16,
    DONE:           17,
    ERROR:          18
};

export const NodeEvents = {
    END:    'end',
    FINISH: 'finish',
    CLOSE:  'close',
    ERROR:  'error',
    DRAIN:  'drain'
};

export const LICORICE = 'licorice';

export const INVALID = 'invalid';
export const VALID = 'valid';
export const ENGINEERS = 'engineers';
export const ADMINS = 'admins';
export const TEAMS = 'teams';
export const PROVIDER_REACTIVATED_ENGINEER = 'provider-reactivated-engineer';

export const NotificationTargetTypes = {
    JOB: 'job'
};
export const TARGET_TYPE = 'targetType';

export const CREATE = 'create';
export const ADD = 'add';
export const ADD_PAUSED = 'add_paused';
export const PLAY = 'play';
export const PAUSE = 'pause';
export const LOG = 'log';
export const EDIT = 'edit';
export const REMOVE = 'remove';
export const DELETE = 'delete';

export const SystemChecks = {
    HEAPSTATS: 'sys/heap/request'
};

export const internalCards = [
    'Internal Meeting',
    'Internal Technical',
    'Training',
    'Do not disturb'
];

export const personalCards = [
    'Personal Leave',
    'Vacation',
    'Public Holiday',
    'Break/Lunch',
    'Personal Todo'
];

export const ConfigurationTypes = {
    MAIN:        'main',
    SETTINGS:    'settings',
    INTEGRATION: 'integration'
};

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECS_IN_MINUTE = 60;

export const MAX_TIME_LOG_DURATION = 10 * HOUR - MINUTE;

export const socketTransports = [ 'websocket', 'polling' ];

export const YES = 'y';
export const NO = 'n';
export const UNKNOWN = 'u';

export const connectionErrorCodes = {
    USER_NOT_AUTHENTICATED: 'USER_NOT_AUTHENTICATED',   // Most likely incorrect company name
    PROVIDER_NOT_FOUND:     'PROVIDER_NOT_FOUND',       // Missing or malformed company name or site URL
    BAD_KEY:                'BAD_KEY',                  // Incorrect public/private key
    UNSPECIFIED:            'UNSPECIFIED'               // Any other error
};

export const RPC_PING_RESPONSE = 'pong';
export const OPERATIONS    = 'operations';
export const TEMPLATE_REQ  = '__template__';
export const TEMPLATE_FUNC = '__function__';

export const NOTIFICATIONS_QUEUE = 'notifications-queue';
export const OPS_QUEUE = 'ops-queue';
export const GLOBAL_NAMESPACE = 'global';

export const EventActions = {
    change:     'change',
    cancel:     'cancel',
    reschedule: 'reschedule'
};

export const HistoryActions = {
    reschedule: 'reschedule'
};

// MFA app name
export const MFA_APP_NAME = "LicoriceApp";

// querying for null/notnull via API
export const NOTNULL = 'is-not-null';
export const NULL = 'is-null';

export const VALID_KEY = 'namespace:iterator:valid';

export const ResourceUploadStages = {
    started:    'started',
    loading:    'loading',
    complete:   'complete',
    error:      'error'
};

export const ProviderOperationTypes = {
    CREATE:     'create',
    UPDATE:     'update',
    DELETE:     'delete',
    REPLACE:    'replace'
};

export const Sync = {
    Version: {
        LOCAL:  'local',
        FLIGHT: 'flight',
        REMOTE: 'remote'
    },
    Dir: {
        IN:    1,
        OUT:   2,
        INOUT: 3
    },
    ADD:     'add',
    UPDATE:  'update',
    REPLACE: 'replace',
    DELETE:  'delete'
};

export const NO_SYNC = -1;

export const NpSignal = {
    Cause: {
        STARTUP:     'startup',
        INTEGRATION: 'integration'
    },
    Namespace: {
        PREFIX:     'ns',
        CREATE:     'ns-create',
        RX:         'ns-rx',
        SERVER:     'ns-rx',
        TX:         'ns-tx',
        CLIENT:     'ns-tx',
        DROP:       'ns-drop',
        RXOPS:      'ns-ops-rx',
        OPERATIONS: 'ns-ops-rx',
        TXOPS:      'ns-ops-tx',
        DROPDONE:   'ns-drop-done',
        UP:         'ns-up',
        DOWN:       'ns-down',
        READY:      'ns-ready'
    },
    Provider: {
        PREFIX:     'conn',
        CREATE:     'conn-create',
        RX:         'conn-rx',
        SERVER:     'conn-rx',
        TX:         'conn-tx',
        CLIENT:     'conn-tx',
        DROP:       'conn-drop',
        RXOPS:      'conn-ops-rx',
        OPERATIONS: 'conn-ops-rx',
        TXOPS:      'conn-ops-tx',
        UP:         'conn-up',
        DOWN:       'conn-down',
        READY:      'conn-ready'
    },
    Drop: {
        START:     'drop-start',
        PROVIDER:  'drop-provider',
        NAMESPACE: 'drop-namespace'
    }
};

export const DbStateFlags = {
    NAMES_INITIALIZED: 1 << 0,
    INCREMENTAL_SYNC:  1 << 1
};

export const NoteFlags = {
    SKIP_CLIENT_EMAIL: 1 << 0
};

export const ALIGNMENT_LOCK = 'namespace:manager:lock';
export const DATABASE = 'database';
export const PROVIDER = 'provider';
export const pPROVIDER = 'provider:';

export const CLUSTER_STATE_EVENT = 'cluster-state';
export const CLUSTER_STATE_SUFFIX = ':state';
export const CLUSTER_STAGE_PREFIX = 'stage:';

export const NONE = 0;
export const START = 1;
export const ADD_OPS = 2;
export const ADD_NS = 3;
export const READY = 4;
export const CHANGE_PROVIDER = 5;
export const DROP_PROVIDER = 6;

export const STAGE_CHANGE = 'stage-change';

// this list must remain in sync with the colors defined in baseCss.scss/$figmaAvatarColors, and
// the avatar-{white|black}-* combinations of those colors generated in the same file.
export const avatars = [
    'white-blueberry',
    'white-cyan',
    'white-dragonfruit',
    'white-grape',
    'white-iris',
    'white-tangerine',
    'white-teal',
    'white-watermelon',

    'black-cyan',
    'black-dragonfruit',
    'black-iris',
    'black-tangerine',
    'black-teal',
    'black-watermelon',
    'black-wattle',
    'black-white'
];
