import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    rootAH: ({
        height:          70,
        backgroundColor: "var(--black-2)"
    }),
    inner: {
        display: "flex",
        height:  70
    }
});

export const AuthHeader = ({ children, ...rest }) => {
    const classes = useStyles( rest );
    return (
        <header className={classes.rootAH}>
            <div className = {classes.inner}>
                {children}
            </div>
        </header>
    );
};

AuthHeader.propTypes = {
    children: PropTypes.node
};
