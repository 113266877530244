/** ******************************************************************************************************************
 * @file Defines all constants for UX testing.
 * @author Marshall Cowan <mcowan@licorice.io>
 * @since 1.0.0
 * @date 21-Jun-2021
 *********************************************************************************************************************/

export const UX_UPDATE_TERMS = 'updateTerms';
export const UX_GET_RESET_CODE = 'getResetCode';
export const UX_REMEMBER_SESSION = 'rememberSession';
export const UX_FORGOT_PASSWORD = 'forgotPasswordLink';
export const UX_LOGIN_BUTTON = 'loginButton';

export const UX_REGISTRATION_BETA_KEY = "betaKey";
export const UX_REGISTRATION_EMAIL = "emailAddress";
export const UX_REGISTRATION_NEXT = "nextRegScreen";
export const UX_REGISTRATION_ENGINEER_NAME = "engineerName";
export const UX_REGISTRATION_PASSWORD = "password";
export const UX_REGISTRATION_COMPANY_NAME = "companyName";
export const UX_REGISTRATION_LICORICE_URL = "licoriceUrl";

export const UX_INTEGRATION_COMPANY_NAME = "companyName";
export const UX_INTEGRATION_SITE_URL = "siteUrl";
export const UX_INTEGRATION_PUBLIC_KEY = "publicKey";
export const UX_INTEGRATION_PRIVATE_KEY = "privateKey";
export const UX_INTEGRATION_CONNECT = "connect";
export const UX_INTEGRATION_START = "start";
export const UX_INTEGRATION_FINISH = "finish";

export const UX_HOME_LINK = 'homeLink';
export const UX_LOGOUT_BUTTON = 'logoutButton';
export const UX_SETTINGS = 'settings';
export const UX_USER_MENU = 'userMenu';

export const UX_SETTINGS_PROFILE = 'settingProfile';
export const UX_SETTINGS_ENGINEERS = 'settingsEngineers';
export const UX_SETTINGS_CLIENTS_LOCATIONS = 'settingsClientsLocations';
export const UX_SETTINGS_CLIENTS_USERS = 'settingsClientsUsers';
export const UX_SETTINGS_ORGANISATION = 'settingsOrganisation';
export const UX_SETTINGS_CLIENTS = 'settingsClients';
export const UX_SETTINGS_CLIENT_SCHEDULING = 'settingsClientScheduling';
export const UX_SETTINGS_INTEGRATIONS = 'settingsIntegrations';
export const UX_SETTINGS_TEAMS = 'settingsTeams';
export const UX_SETTINGS_SAVE_BUTTON = 'settingsSaveButton';
export const UX_SETTINGS_JOURNAL = 'settingsJournal';

export const UX_SETTINGS_ORGANISATION_CLIENT_CHAT = 'settingsOrganisationClientChat';

export const UX_SETTINGS_INVITE_BUTTON = 'inviteButton';
export const UX_SETTINGS_INVITE_POPUP = 'invitePopup';

export const UX_USER_REGISTRATION_CREATE_ACCOUNT_BUTTON = "createAccount";

export const UX_JOBCARD_CLIENT_DROPDOWN = 'jcClientSelect';
export const UX_JOBCARD_TITLE = 'jcTitle';
export const UX_JOBCARD_DESCRIPTION = 'jcDescription';
export const UX_JOBCARD_DELETE_BUTTON = 'jcDelete';
export const UX_JOBCARD_CLOSE_BUTTON = 'jcClose';
export const UX_JOBCARD_PROVIDER_LINK = 'jcProviderLink';
export const UX_JOBCARD_PRIORITY_BUTTON = "jcPriorityButton";
export const UX_JOBCARD_PRIORITY_MENU = "jcPriorityMenu";
export const UX_JOBCARD_ESTIMATED_TIME = "jcEstimatedTime";
export const UX_JOBCARD_CLIENT_STATUS = "jcClientStatus";
export const UX_JOBCARD_CLIENT_CUSTOM_STATUS = "jcClientCustomStatus";
export const UX_JOBCARD_TYPE_SELECTOR_JOB = "jcTypeSelectorJob";
export const UX_JOBCARD_TYPE_SELECTOR_INTERNAL = "jcTypeSelectorInternal";
export const UX_JOBCARD_TYPE_SELECTOR_PERSONAL = "jcTypeSelectorPersonal";

export const UX_JOBCARD_CLIENT_CHAT_TAB = 'jcClientChatTab';
export const UX_JOBCARD_CLIENT_CHAT_TEXT = 'jcClientChatText';
export const UX_JOBCARD_CLIENT_CHAT_SEND = 'jcClientChatSend';
export const UX_JOBCARD_CLIENT_CHAT_BILLABLE = 'jcClientChatBillable';
export const UX_JOBCARD_CLIENT_CHAT_TIMER_VIEW = 'jcClientChatTimerView';
export const UX_JOBCARD_CLIENT_CHAT_TIMER_EDIT = 'jcClientChatTimerEdit';
export const UX_JOBCARD_CLIENT_CHAT_LIST = 'jcClientChatList';
export const UX_JOBCARD_CLIENT_CHAT_LIST_ITEM = 'jcClientChatListItem';

export const UX_JOBCARD_ENGINEER_CHAT_TAB = 'jcEngineerChatTab';
export const UX_JOBCARD_ENGINEER_CHAT_TEXT = 'jcEngineerChatText';
export const UX_JOBCARD_ENGINEER_CHAT_SEND = 'jcEngineerChatSend';
export const UX_JOBCARD_ENGINEER_CHAT_BILLABLE = 'jcEngineerChatBillable';
export const UX_JOBCARD_ENGINEER_CHAT_TIMER_VIEW = 'jcEngineerChatTimerView';
export const UX_JOBCARD_ENGINEER_CHAT_TIMER_EDIT = 'jcEngineerChatTimerEdit';
export const UX_JOBCARD_ENGINEER_CHAT_LIST = 'jcEngineerChatList';
export const UX_JOBCARD_ENGINEER_CHAT_LIST_ITEM = 'jcEngineerChatListItem';

export const UX_JOBCARD_CLIENT_CHAT = {
    text:       UX_JOBCARD_CLIENT_CHAT_TEXT,
    send:       UX_JOBCARD_CLIENT_CHAT_SEND,
    billable:   UX_JOBCARD_CLIENT_CHAT_BILLABLE,
    timerView:  UX_JOBCARD_CLIENT_CHAT_TIMER_VIEW,
    timerEdit:  UX_JOBCARD_CLIENT_CHAT_TIMER_EDIT,
    list:       UX_JOBCARD_CLIENT_CHAT_LIST
};

export const UX_JOBCARD_ENGINEER_CHAT = {
    text:       UX_JOBCARD_ENGINEER_CHAT_TEXT,
    send:       UX_JOBCARD_ENGINEER_CHAT_SEND,
    billable:   UX_JOBCARD_ENGINEER_CHAT_BILLABLE,
    timerView:  UX_JOBCARD_ENGINEER_CHAT_TIMER_VIEW,
    timerEdit:  UX_JOBCARD_ENGINEER_CHAT_TIMER_EDIT,
    list:       UX_JOBCARD_ENGINEER_CHAT_LIST
};

export const UX_JOBCARD_ENGINEER_ADD_BUTTON = "jcEngineerAddButton";
export const UX_JOBCARD_ENGINEER_ADD_LIST = "jcEngineerAddList";
export const UX_JOBCARD_ENGINEER_CHIP_LIST = "jcEngineerChipList";
export const UX_JOBCARD_ENGINEER_CHIP_ITEM = "jcEngineerChipItem";
export const UX_JOBCARD_ENGINEER_CHIP_ITEM_DELETE = "jcEngineerChipItemDelete";

export const UX_JOBCARD_USER_ADD_BUTTON = "jcUserAddButton";
export const UX_JOBCARD_USER_ADD_LIST = "jcUserAddList";
export const UX_JOBCARD_USER_CHIP_LIST = "jcUserChipList";
export const UX_JOBCARD_USER_CHIP_ITEM = "jcUserChipItem";
export const UX_JOBCARD_USER_CHIP_ITEM_DELETE = "jcUserChipItemDelete";

export const UX_JOBCARD_TASK_ADD_BUTTON = "jcTaskAddButton";
export const UX_JOBCARD_TASK_LIST = "jcTaskList";
export const UX_JOBCARD_TASK_INPUT = "jcTaskInput";
export const UX_JOBCARD_TASK_ITEM = "jcTaskItem";
export const UX_JOBCARD_TASK_ITEM_DELETE = "jcTaskItemDelete";

export const UX_JOBCARD_APPOINTMENT_LIST = "jcAppointmentList";
export const UX_JOBCARD_APPOINTMENT = "jcAppointment";
export const UX_JOBCARD_APPOINTMENT_ACTIVE = "jcAppointmentActive";
export const UX_JOBCARD_APPOINTMENT_RESCHEDULED = "jcAppointmentRescheduled";
export const UX_JOBCARD_APPOINTMENT_CANCELLED = "jcAppointmentCancelled";
export const UX_JOBCARD_APPOINTMENT_DONE = "jcAppointmentDone";

export const UX_WARNING_YES_BUTTON = "warningYes";
export const UX_WARNING_NO_BUTTON = "warningNo";

export const UX_TIMED_ACTION_PANEL_UNDO = "TAPUndo";
export const UX_TIMED_ACTION_PANEL_CLOSE = "TAPClose";

export const UX_UNSCHEDULED_BUTTON = "unscheduled";
export const UX_SEARCH_PANEL = "searchPanel";
export const UX_TICKET_FILTER = "ticketFilter";

export const UX_PEGBOARD_PANEL = "pegboardPanel";
export const UX_PEGBOARD_CARD = "pegboardCard";
export const UX_PEGBOARD_CARD_CLIENT = "pegboardCardClient";
export const UX_PEGBOARD_CARD_TITLE = "pegboardCardTitle";
export const UX_PEGBOARD_CARD_DELETE_BUTTON = "pegboardCardDeleteButton";
export const UX_PEGBOARD_EMPTY_BUTTON = "pegboardEmptyButton";

export const UX_EVENT_POPUP_COPY = "eventPopupCopy";
export const UX_EVENT_POPUP_COPY_TO = "eventPopupCopyTo";
export const UX_EVENT_POPUP_MOVE = "eventPopupMove";
export const UX_EVENT_POPUP_MOVE_TO = "eventPopupMoveTo";
export const UX_EVENT_POPUP_CANCEL = "eventPopupCancel";
export const UX_EVENT_POPUP_DONE = "eventPopupDone";
export const UX_EVENT_POPUP_DELETE = "eventPopupDelete";
export const UX_NOTIFICATION_ALERT_BUTTON = "notificationAlertButton";

export const UX_CALENDAR_STT_CELL = "calendarSTTCell";
export const UX_CLIENTUSER_ADD_BUTTON = "clientUserAddButton";
export const UX_CLIENTSITE_ADD_LIST = "clientUserAddList";
