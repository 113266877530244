import React, { useEffect, useState } from 'react';

import { isNumber } from "@licoriceio/utils";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

import { engineerField, snackbarKey } from "../../../constants.js";
import { UX_JOBCARD_TASK_ADD_BUTTON } from "../../../ux-constants.js";
import { resetFoundChanges, setClientSettings } from "../../../redux/actions/index.js";
import { filterLine, saveButton, footer, leftFooter, rightFooter, addButton, fabStyle, iconStyle, errorStyle } from '../../../scss/ManageClient.module.scss';
import { settings, clientScreenTitle } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { LicoButton, LicoLiveSearch, LicoSmallButton } from "../../common/index.js";
import LicoSnackbar from "../../common/LicoSnackbar.jsx";
import LicoTable from "../../common/LicoTable.jsx";
import { ListPager } from "../../common/ListPager.jsx";
import NavigationDialog from "../../common/NavigationDialog.jsx";
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import { getPage, statusList, setClientFilterString, setUnsavedClients, saveClientChanges, setNewClients, PAGE_SIZE, continueNavigation } from './reducer.js';


const Clients = ( props ) => {
    const {
        allClients, getPage, statusList, filterString, setClientFilterString,
        setUnsavedClients, saveClientChanges, foundChanges, setNewClients, totalClientCount, currentPage, confirmNavigationDialogOpen,
        setClientSettings, continueNavigation, clientStatus,
        resetFoundChanges, columns, showDuplicateErrorMessage
    } = props;
    const history = useHistory();
    const [ clients, setClients ] = useState( allClients );
    const [ newRowCount, setNewRowCount ] = useState( 1 );

    const addRow = () => {
        const status = Object.keys( clientStatus ).find( key => clientStatus[ key ]?.toLowerCase() === engineerField.ACTIVE );
        setClients([ ...clients, { companyId: -newRowCount, status: status, companyName: '', phone: '', users: 0, locations: 0, note: '' } ]);
        setNewClients({ "companyId": -newRowCount, status });
        setNewRowCount( newRowCount + 1 );
    };

    useEffect( () => {
        Promise.all([ getPage({ page: 0, filterString }), statusList() ]);
    }, [ filterString ]);

    useEffect( () => {
        const modifiedClients = allClients?.map( client => ({
            companyId:   client.companyId,
            status:      client?.status?.licoriceNameId,
            companyName: client.companyName,
            phone:       client?.contactInfo?.find( info => info.type === engineerField.PHONE && info.isDefault )?.value || '',
            users:       client.userCount,
            locations:   client.siteCount,
            note:        client?.companyStatusNote,
            contactId:   client?.contactId
        }) ) ?? [];
        setClients( modifiedClients );
    }, [ allClients ]);

    const handleClientChange = ( id, field, value ) => {
        setClients( clients.map( client => client.companyId === id ? { ...client, [ field ]: value } : client ) );
        if ( isNumber( id ) && id < 0 ) 
            setNewClients({ "companyId": id, [ field ]: value });
        else 
            setUnsavedClients({ "companyId": id, [ field ]: value });
        

    };

    const modifiedColumns = columns.map( column => {
        if ( column.field === 'users' || column.field === 'locations' ) {
            return {
                ...column,
                onClick: ( id, props ) => {
                    if ( isNumber( id ) && id < 0 )  return;
                    // These props are the properties associated with the specific cell or row being interacted with
                    // in the table. They are known at the time of the onClick event. Hence, the ESLint warnings
                    // about missing props validation are disabled
                    // eslint-disable-next-line react/prop-types
                    const path = column.field === 'users' ? `/settings/clients/${id}/users/${encodeURIComponent( props.companyName )}`
                        // eslint-disable-next-line react/prop-types
                        : `/settings/clients/${id}/locations/${encodeURIComponent( props.companyName )}`;
                    history.push( path );
                    // eslint-disable-next-line react/prop-types
                    setClientSettings({ selectedClientId: id, selectedContactId: props.contactId });
                }


            };
        }
        return column;
    });

    const showValidationErrorMessage = foundChanges && ( clients.some( client => !client.companyName ) );

    return (
        <div>
            <LicoSnackbar
                metaKey={snackbarKey.CLIENT_DETAILS_SAVED}
                label={__( "Changes saved." )}

            />
            <NavigationDialog
                isOpen={confirmNavigationDialogOpen}
                saveAction={() => continueNavigation( true )}
                discardAction={() => continueNavigation( false )}
                cancelAction={() => setClientSettings({ confirmNavigationDialogOpen: false })}
            />
            <PreventTransitionPrompt
                when={foundChanges}
                history={history}
                saveAction={saveClientChanges}
                discardAction={resetFoundChanges}
            />
            <SettingsLayout>
                <div className={settings}>
                    <div className={clientScreenTitle}>{__( "Clients" )}</div>
                    <div className={filterLine}>
                        <LicoLiveSearch
                            placeholder={__( "Filter" )}
                            value={filterString}
                            onChange={e => setClientFilterString( e.target.value )}
                            id="ClientFilter"
                        />
                    </div>
                </div>
                <LicoTable columns={modifiedColumns} rows={clients} onChange={handleClientChange} uniqueKey={"companyId"}/>
                {showValidationErrorMessage && <div className={errorStyle}>{__( "Please fill in all required fields" )}</div>}
                {showDuplicateErrorMessage && <div className={errorStyle}>{__( "Client Name Already Exists" )}</div>}
                <div className={footer}>
                    <div className={leftFooter}>
                        <LicoSmallButton
                            labelClass={addButton}
                            label={__( "Add Client" )}
                            fabClass={fabStyle}
                            dataName={UX_JOBCARD_TASK_ADD_BUTTON}
                            onClick={addRow}
                            iconClass={iconStyle}
                        />
                        <LicoButton
                            className={saveButton}
                            onClick={saveClientChanges}
                            disabled={!foundChanges || showValidationErrorMessage}
                        >{__( "Save" )}
                        </LicoButton>
                    </div>

                    <div className={rightFooter}>
                        <ListPager
                            page={currentPage}
                            pageSize={PAGE_SIZE}
                            totalPages={Math.ceil( totalClientCount / PAGE_SIZE )}
                            getPage={getPage}
                            filter={filterString}
                        />
                    </div>
                </div>

            </SettingsLayout>

        </div>
    );
};
Clients.propTypes = {
    allClients:                  PropTypes.array.isRequired,
    getPage:                     PropTypes.func.isRequired,
    statusList:                  PropTypes.func.isRequired,
    filterString:                PropTypes.string.isRequired,
    setClientFilterString:       PropTypes.func.isRequired,
    setUnsavedClients:           PropTypes.func.isRequired,
    saveClientChanges:           PropTypes.func.isRequired,
    resetFoundChanges:           PropTypes.func.isRequired,
    foundChanges:                PropTypes.bool,
    setNewClients:               PropTypes.func.isRequired,
    totalClientCount:            PropTypes.number.isRequired,
    currentPage:                 PropTypes.number.isRequired,
    confirmNavigationDialogOpen: PropTypes.bool.isRequired,
    setClientSettings:           PropTypes.func.isRequired,
    continueNavigation:          PropTypes.func.isRequired,
    columns:                     PropTypes.array.isRequired,
    showDuplicateErrorMessage:   PropTypes.bool.isRequired,
    clientStatus:                PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        allClients:                  state?.client?.allClients,
        clientStatus:                state?.client?.clientStatuses,
        filterString:                state?.client?.filterString,
        foundChanges:                state?.client?.foundChanges,
        totalClientCount:            state?.client?.totalClientCount,
        currentPage:                 state?.client?.currentPage,
        confirmNavigationDialogOpen: state?.client?.confirmNavigationDialogOpen,
        showDuplicateErrorMessage:   state?.client?.showDuplicateErrorMessage,
        refreshPage:                 state?.client?.refreshPage,
        columns:                     state.client.clientColumns.map( column => {
            if ( column.field === "status" ) {
                return {
                    ...column,
                    valueOptions: Object.entries( state.client.clientStatuses ).map( ([ id, value ]) => ({
                        id,
                        value: value
                    }) )
                };
            }
            return column;
        })

    };
};

const mapDispatchToProps = {
    getPage,
    statusList,
    setClientFilterString,
    setUnsavedClients,
    saveClientChanges,
    setNewClients,
    continueNavigation,
    setClientSettings,
    resetFoundChanges
};

export default connect( mapStateToProps, mapDispatchToProps )( Clients );

