import React from 'react';

import { TextField, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoIcon } from '../../images/LicoIcon.jsx';

const useStyles = makeStyles({
    root:  {},
    label: {},
    input: {
        borderRadius: 40,
        height:       42,
        width:        "100%",
        borderWidth:  1,
        borderColor:  "var(--cobalt-3)",
        boxSizing:    "content-box",
        marginRight:  14
    },
    inputLabel: {
        fontSize: 13,
        top:      2
    },
    redoIcon: {
        fontSize:  16,
        color:     "var(--grey-2)", 
        cursor:    "pointer",
        "&:hover": {
            color: "var(--grey-2)"
        }
    }
});

export const LicoLiveSearch = props => {
    const {
        label,
        value,
        placeholder,
        disabled = false,
        onChange,
        showRedo = false,
        dataName,
        onKeyDown,
        autoFocus
    } = props;

    const classes = useStyles( props );

    return <TextField
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        data-ux={dataName}
        InputProps={{
            autoFocus:    autoFocus,
            className:    classes.input,
            endAdornment: ( !showRedo || disabled ) ? null : <InputAdornment className={classes.redoIcon} onClick={onChange} position="end">
                <LicoIcon  icon="redo" />
            </InputAdornment>
        }}
        inputProps={{ style: { fontSize: 13 } }}
    />;
};

LicoLiveSearch.propTypes = {
    label:              PropTypes.string,
    value:              PropTypes.string.isRequired,
    placeholder:        PropTypes.string,
    dataName:           PropTypes.string, 
    disabled:           PropTypes.bool,
    onChange:           PropTypes.func,
    showRedo:           PropTypes.bool,
    onKeyDown:          PropTypes.func,
    autoFocus:          PropTypes.bool
};
