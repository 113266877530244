import React, { useState } from 'react';


import { TextField, Tooltip  } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import InputMask from "react-input-mask";
import * as Yup from 'yup';

import { dispatchBlurThunk, dispatchChangeAction, validateField, installKeySaveActions } from '../../../../redux/reducerUtil.js';
import { H2, P } from '../typography/index.js';

const useStyles = makeStyles({
    root: {
        alignItems:   "flex-end"
    },
    rootMargin: {
        marginBottom: 6
    },
    label: {
        marginBottom: 9
    },
    subLabel: {
        marginBottom: 9,
        lineHeight:     "16px",
        fontSize:     12
    },
    textFieldMargin: {
        marginBottom: 20
    },
    inlineTextFieldMargin: {
        marginBottom: 0
    },
    inlineLabel: {
        display:       "flex",
        flexDirection: "row",
        alignItems:    "center"
    },
    inlineMargin: {
        marginBottom:  14
    },
    textFieldRoot: {
    }
});

const TextFieldTooltip = withStyles({
    tooltip: {
        background:   "var(--black-1)",
        fontSize:     12,
        borderRadius: 4,
        fontFamily:   "gotham-book, sans-serif",
        maxWidth:     200,
        border:       "1px solid white"
    },
    arrow: {
        color: "var(--black-1)"
    }
})( Tooltip );


export const LicoTextField = props => {
    const {
        label,
        subLabel,
        labelElement,
        placeholder,
        allowLastPass,
        inlineLabel,
        labelClassName,
        inputClassName,
        mask,
        value,
        disabled,
        tooltip,
        disabledTooltip,
        formPackage,
        name = "anon",
        forceFullWidth,
        inputProps,
        noMargins,
        slicePackage,
        validation,
        ...rest
    } = props;
    let { onChange, onBlur, helperText, error, onKeyDown } = props;
    const classes = useStyles( props );
    const [ fieldTouched, setFieldTouched ] = useState( false );
    const [ errorMessage, setErrorMessage ] = useState( '' );
    const [ schema ] = useState( validation ? Yup.object().shape({ [ name ]: validation }) : null );

    const labels = label ? (
        subLabel
            ? <React.Fragment>
                <H2 className={classes.label} gutterBottom={false}>
                    {label}
                </H2>
                <P className={classes.subLabel} gutterBottom={false}>{subLabel}</P>
            </React.Fragment>
            : <H2 className={labelClassName || classes.label} gutterBottom={false}>
                {label}
            </H2>
    ) : null;

    if ( slicePackage ) {
        onChange = e => dispatchChangeAction( slicePackage, { updates: { [ name ]:  e.target.value } });

        onBlur = () => {
            setFieldTouched( true );
            const newError = validateField( schema, name, value );
            setErrorMessage( newError );

            // call the generic thunk to either add an error or possibly (if there no other errors) trigger a save
            dispatchBlurThunk( slicePackage, { field: name, error: newError });
        };
        helperText = fieldTouched ? errorMessage : '';
        error = !!errorMessage && fieldTouched; 

        if ( slicePackage.keySaveAction )
            onKeyDown = installKeySaveActions( slicePackage, onBlur, props.onKeyDown );
    }

    const textField = mask
        ? <InputMask
            mask={mask}
            maskChar=" "
            formatChars={
                {
                    '9': '[0-9]',
                    'a': '[A-Za-z]',
                    '*': '[A-Za-z0-9]',
                    'h': '[A-Fa-f0-9]'
                }
            }
            value={value}
            onChange={onChange}
            alwaysShowMask
            disabled={disabled}
        >
            {( inputProps ) => <TextField
                variant="outlined"
                fullWidth={Boolean( forceFullWidth || !inlineLabel )}
                placeholder={placeholder !== undefined ? placeholder : label}
                name={name}
                onKeyDown={onKeyDown}

                {...rest}
                inputProps={{
                    ...inputProps,
                    "data-lpignore": "true",
                    "texttransform": "uppercase",
                    "spellCheck":    "false",
                    "style":         {
                        fontFamily:     "monospace",
                        fontSize:       16,
                        padding:        14,
                        textTransform:  "uppercase"
                    }
                }}
            />}
        </InputMask>
        : <TextField
            variant="outlined"
            fullWidth={Boolean( forceFullWidth || !inlineLabel )}
            placeholder={placeholder !== undefined ? placeholder : label}
            value={value || ''}
            disabled={disabled}

            name={name}

            onChange={formPackage
                ? e => formPackage.onChange({ id: formPackage.id, field: e.target.name, value: e.target.value })
                : onChange}

            onBlur={formPackage
                ? e => formPackage.onBlur( e.target.name )
                : onBlur}

            helperText={formPackage
                ? formPackage.fieldTouched[ name ] && formPackage.fieldError[ name ]
                : helperText}

            error={formPackage
                ? !!formPackage.fieldError[ name ] && formPackage.fieldTouched[ name ]
                : error}

            {...rest}
            onKeyDown={onKeyDown}
            className={`${inputClassName} ${noMargins ? '' : inlineLabel ? classes.inlineTextFieldMargin : classes.textFieldMargin}`}
            inputProps={{
                ...inputProps,
                "data-lpignore": allowLastPass ? "false" : "true"
            }}
        />;

    // copied from LicoSearchableSelect; should be made common
    const showTooltip = ( !!disabled && disabledTooltip !== undefined && disabledTooltip.length > 0 ) ||
        ( tooltip !== undefined && tooltip.length > 0 );

    return ( <label className={`${inlineLabel ? classes.inlineLabel : classes.root}
            ${noMargins ? '' : inlineLabel ? classes.inlineMargin : classes.rootMargin}`}>
        {labelElement || labels}
        {showTooltip
            ? <TextFieldTooltip
                title={disabled ? disabledTooltip || tooltip || '' : tooltip || ''}
                arrow
                placement="left"
                disableHoverListener={!showTooltip}
                disableTouchListener={true}
                disableFocusListener={true}
            >
                {textField}
            </TextFieldTooltip>
            : textField}
    </label> );
};

LicoTextField.propTypes = {
    label:              PropTypes.string,
    subLabel:           PropTypes.string,
    labelElement:       PropTypes.any,
    message:            PropTypes.string,
    placeholder:        PropTypes.string,
    allowLastPass:      PropTypes.bool,
    inlineLabel:        PropTypes.bool,
    labelClassName:     PropTypes.string,
    inputClassName:     PropTypes.string,
    mask:               PropTypes.any,
    name:               PropTypes.string,
    value:              PropTypes.string,
    onChange:           PropTypes.func,
    onKeyDown:          PropTypes.func,
    tooltip:            PropTypes.string,
    disabledTooltip:    PropTypes.string,
    formPackage:        PropTypes.object,
    slicePackage:       PropTypes.object,
    forceFullWidth:     PropTypes.bool,
    noMargins:          PropTypes.bool,
    onBlur:             PropTypes.func,
    helperText:         PropTypes.string,
    error:              PropTypes.bool,
    disabled:           PropTypes.bool,
    inputProps:         PropTypes.object,
    validation:         PropTypes.object
};
