import React from 'react';

import { ResourceUploadStages } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getResourceProgress } from '../../../redux/selectors';
import ProgressBar from '../ProgressBar.jsx';

import EditorImage from './EditorImage';

const EditorImageWrapper = props => {

    const { element, resource } = props;

    return <>
        {resource && resource.progress !== ResourceUploadStages.complete
            ? <ProgressBar percentage={Number( Math.floor( resource.loaded / resource.fileSize * 100 ) )} label={element.title} />
            : <EditorImage {...props} />
        }
    </>;
};
EditorImageWrapper.propTypes = {
    element:           PropTypes.object.isRequired,
    resource:          PropTypes.object
};

const mapStateToProps = ( state, props ) => ({
    resource:   getResourceProgress( state, props.element.url )
});

export default connect( mapStateToProps )( EditorImageWrapper );
export { EditorImageWrapper };
