/*********************************************************************************************************************
 * @file Login reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Nov-2020
 *********************************************************************************************************************/
import { BAD_MFA, MAXIMUM_ATTEMPTS } from '@licoriceio/errors';
import * as Yup from 'yup';

import { uri, POST } from '../../constants.js';
import {
    setLoginError, setQrCode, setQrCodeUrl, setLoginMfaTransition, setLoginVerified, setLoginMfaVerified, setUserFromSettings,
    setClearAllError, setMeta, setFormValueLogin, setTouchedLogin, setLoginFields, setFieldError, setLogout,
    setSettingsLogin, setOrganisationSettings, setMfaBackLogin, setCreds, setAuth } from '../../redux/actions/index.js';
import { initUser } from '../../redux/reducers/user.js';
import { ezRedux, validateForm, genericReducer, formValueReducer, focusReducer  } from '../../redux/reducerUtil.js';
import { login } from '../../services/login.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { initializeSocket } from '../../sockets/initialize-socket.js';
import { INCORRECT_PASSWORD, INCORRECT_MFA, EXCEED_MAX_LOGIN_ATTEMPT } from "../../utils/common-types.js";
import { isTokenExpired } from "../../utils/jwt.js";
import { saveAuth } from "../../utils/local-storage.js";
import { MfaStates, setVerifiedReducer, setMfaVerifiedReducer, setMfaTransitionReducer, setCredsReducer, setMfaBackReducer } from '../../utils/mfa.js';
import { setLoginUserId } from '../../utils/misc.js';
import { THIS_FIELD_IS_REQUIRED, INVALID_EMAIL, MUST_ACCEPT_TERMS } from "../common/index.js";
import { getProviders } from '../settings/integration/requests.js';

/**
 * @typedef {object} LoginState
 * @property {string} email
 * @property {string} password
 * @property {boolean} rememberSession
 * @property {boolean} eula
 * @property {boolean} termsConditions
 * @property {boolean} privacyPolicy
 * @property {boolean} firstTimeLogin
 * @property {string} error
 * @property {object} fieldTouched
 * @property {object} fieldError
 * @property {string} mfaState
 * @property {boolean} verified
 * @property {boolean} mfaVerified
 * @property {?object} settings
 * @property {?string} qrcode
 * @property {?string} qrcodeUrl
 */

/**
  * @type {LoginState}
  */
const initialState = Object.freeze({
    email:            '',
    password:         '',
    rememberSession:  false,
    eula:             false,
    privacyPolicy:    false,
    termsConditions:  false,
    firstTimeLogin:   false,
    error:            '',
    fieldTouched:     {},
    fieldError:       {},
    mfaState:         MfaStates.NONE,
    verified:         false,
    mfaVerified:      false,
    qrcode:           null,
    settings:         null,
    qrCodeUrl:        null,
    timestamp:        ''
});

// services
const _asyncGetUserSettings = abstractedCreateAuthRequest( POST, uri.USER_SETTINGS );
// const _asyncUpdateUserSettings = abstractedCreateAuthRequest( PATCH, uri.SET_USER_SETTINGS );
const _asyncUpdateTerms = abstractedCreateAuthRequest( POST, uri.UPDATE_TERMS );

// form validation via Yup
const formSchema = Yup.object().shape({
    email:      Yup.string()
        .email( INVALID_EMAIL )
        .required( THIS_FIELD_IS_REQUIRED ),
    password:   Yup.string().required( THIS_FIELD_IS_REQUIRED )
});

// non-fatal errors from the login process and their messages
const loginError = {
    [ BAD_MFA ]:                INCORRECT_MFA,
    [ MAXIMUM_ATTEMPTS ]:       EXCEED_MAX_LOGIN_ATTEMPT
};

const _setSettings = ( draft, payload ) => {

    if ( payload.password?.verified ) 
    {
        draft.settings = payload;
        draft.error = '';

        // we should examine settings and go to either NONE (mfa not in use), CONFIRM (start of mfa setup) or USE (mfa already set up)
        draft.mfaState = payload.mfa ? payload.mfaConfirmed ? MfaStates.USE : MfaStates.CONFIRM : MfaStates.NONE;
    }
    else
        draft.error = loginError[ payload.password.error?.errorCode ] || INCORRECT_PASSWORD;
};


const _setQrCode = ( draft, code ) => draft.qrcode = code;
const _setQrCodeUrl = ( draft, url ) => draft.qrCodeUrl = url;


// async handlers

const checkPassword = () => ( dispatch, getState ) => {
    const { auth, login: { email, password } } = getState();

    _asyncGetUserSettings({ email, password }, auth )
        .then( settings => dispatch( setSettingsLogin( settings.payload ) ) )
        .catch( () => {

            // reset the store value of password to blank so pressing the button again without making changes will attempt login again,
            // as the setCreds call copies the screen password to the store thus triggering the effect.
            // This is a bit clunky but the process has to cope with MFA being used or not so I'm not inclined to try to simplify it now.
            dispatch( setCreds({ password: '' }) );
        });
};

/**
 * Attempt to login
 */
const doLogin = () => ( dispatch, getState ) => {

    dispatch( setLoginError( undefined ) );
    const loginState = getState().login;
    const { email, password, rememberSession } = loginState;

    const fieldError = validateForm( formSchema, loginState );
    dispatch( setFieldError( fieldError ) );

    if ( Object.keys( fieldError ).length === 0 ) 
    {
        const data = { email, password, rememberSession };

        if ( loginState.qrcode )
            data.code = loginState.qrcode;

        login( data )
            .then( result => {
                if ( result.hasError ) 
                    // error with the login process itself
                    dispatch( setLoginError( result.error.errors ) );
                else if ( result.payload.error ) 

                    // failed to login for a known reason, eg MFA code wrong.
                    // The payload emulates the return from the /user/settings route, which
                    // also produces this kind of error (wrong PW or exceeded max attempts)
                    dispatch( setSettingsLogin({ password: { error: result.payload.error } }) );
                
                else {
                    const { payload, payload: { token } } = result;
                    dispatch( setAuth({ rememberSession, token }) );
                    saveAuth({ rememberSession, token, userInitState: payload });
                    dispatch( prepareSystem( payload ) );
                }

            });

    }
    else {

        // show the errors (in case they just pushed the button straight away)
        Object.keys( fieldError ).map( field => dispatch( setTouchedLogin( field ) ) );
    }

};

const updateTerms = () => async ( dispatch, getState ) => {

    dispatch( setLoginError( undefined ) );
    const { login: { eula, termsConditions, privacyPolicy }, user, auth } = getState();

    if ( eula && termsConditions && privacyPolicy ) 
    {
        _asyncUpdateTerms({ eula, termsConditions, privacyPolicy }, auth, [ user.userId ])
            .then( result => {
                if ( result.hasError )
                    dispatch( setLoginError( result.error.errors ) );
                else
                    dispatch( setUserFromSettings( result.payload ) );
            });
    }
    else
        dispatch( setLoginError( MUST_ACCEPT_TERMS ) );

};

// called from App component mount; fixed init actions
const setInit = arg => dispatch => {

    dispatch( setClearAllError() );

    dispatch( setMeta({
        domain:                 arg.domain,
        searchPanelShown:       false,
        notificationPanelShown: false
    }) );

    if ( arg.rememberSession && !isTokenExpired( arg?.userInitState?.token ) ) 
        dispatch( prepareSystem( arg.userInitState ) );
};

const prepareSystem = payload => async ( dispatch ) => {

    const { token, user: { userId } } = payload;

    // record user, company info in store
    dispatch( initUser( payload ) );

    initializeSocket({ token, userId });

    // store a copy of the login user id outside of state so we can access it from reducers, places without store references, etc
    setLoginUserId( userId );

    dispatch( getProviders() );
};

const reducers = {
    [ setLoginError ]:              ( draft, value ) => draft.error = value,
    [ setFieldError ]:              ( draft, errorMap ) => draft.fieldError = errorMap,
    [ setFormValueLogin ]:          ( draft, arg ) => formValueReducer( draft, arg, formSchema ),
    [ setTouchedLogin ]:            focusReducer,
    [ setLoginFields ]:             genericReducer,
    [ setLogout ]:                  ( ) => window.location.reload( ),
    [ setLoginVerified ]:           setVerifiedReducer,
    [ setLoginMfaVerified ]:        setMfaVerifiedReducer,
    [ setLoginMfaTransition ]:      setMfaTransitionReducer,
    [ setSettingsLogin ]:           _setSettings,
    [ setQrCodeUrl ]:               _setQrCodeUrl,
    [ setQrCode ]:                  _setQrCode,
    [ setCreds ]:                   setCredsReducer,
    [ setOrganisationSettings ]:    ( draft, payload ) => console.log( 'org settings', payload ),
    [ setMfaBackLogin ]:            setMfaBackReducer
};

export {
    doLogin,
    updateTerms,
    setInit,
    prepareSystem,
    setQrCode,
    checkPassword,
    setLoginError
};

export default ezRedux( reducers, initialState );
