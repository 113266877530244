/**
 * @typedef {object} ExtendedNotification
 * @extends Database.Notification
 * @property {number} createdOnTime
 * @property {number} updatedOnTime
 * @property {number} hideUntilTime
 * @property {number} handlingSort
 * @property {boolean} dismissable
 * @property {any} jsonData
 */

/**
 * @param {BaseState} state
 * @return {ExtendedNotification[]}
 */
const selectNotifications = state => state.notification.notifications;
const selectNotificationCount = state => state?.notification?.notifications?.length;

export {
    selectNotifications,
    selectNotificationCount
};
