/*********************************************************************************************************************
 * @file Forgot/Reset password reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21-Dec-2020
 *********************************************************************************************************************/
import { TokenPurpose } from '@licoriceio/constants';
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';

import { uri, GET, POST, PATCH } from '../../constants.js';
import { setLoginEmail, setTouchedForgotPassword, setFormValueForgotPassword, setMeta } from '../../redux/actions/index.js';
import { ezRedux, genericRequest, formValueReducer, focusReducer } from '../../redux/reducerUtil.js';
import { abstractedCreateRequest } from '../../services/util/baseRequests.js';
import { THIS_FIELD_IS_REQUIRED, INVALID_EMAIL } from "../common/index.js";
import { formatStrength, validateStrength } from '../registration/reducer.js';
/**
 * @typedef {object} PasswordResetState
 * @property {string}   loginEmail
 * @property {object}   fieldError
 * @property {object}   fieldTouched
 * @property {string}   [resetToken]
 */

/**
  * @type {PasswordResetState}
  */
const initialState = Object.freeze({
    loginEmail:     '',
    fieldTouched:   {},
    fieldError:     {},
    password:       ''
});

const forgotPasswordSchema = Yup.object().shape({
    loginEmail: Yup.string().email( INVALID_EMAIL ).required( THIS_FIELD_IS_REQUIRED ),
    password:     Yup.string().required( THIS_FIELD_IS_REQUIRED )
});

/** services */

const _asyncRequestUserPasswordToken = abstractedCreateRequest( POST, uri.USER_REQUEST_PASSWORD_TOKEN );
const _asyncUserValidateToken = abstractedCreateRequest( GET, uri.USER_TOKEN_VALIDATION );
const _asyncResetPassword = abstractedCreateRequest( PATCH, uri.USER_RESET_PASSWORD );

/** requests */

const requestUserPasswordToken = payload => genericRequest( payload, _asyncRequestUserPasswordToken, [ [ setMeta, { passwordResetEmailSent: true } ] ]);
const validateResetToken = payload => genericRequest({}, _asyncUserValidateToken, [ [ setMeta, 'resetToken' ] ], [ TokenPurpose.pwdreset, payload.token ]);
const resetPassword = payload => genericRequest( payload, _asyncResetPassword, [ [ setMeta, { passwordReset: true } ] ]);

/** reducers */

const updateForm = ( draft, arg ) => {
    formValueReducer( draft, arg, forgotPasswordSchema );
    draft.fieldTouched[ arg?.field ] = false;
    if ( arg?.field === 'password' ) {
        if ( draft.password ) {
            const inputs = [];
            if ( draft.loginEmail?.length > 0 ) inputs.push( ...draft.loginEmail.split( /[.@_-]/ ) );
            draft.strength = formatStrength( zxcvbn( draft.password, inputs ) );
            draft.fieldError.password = validateStrength( draft.strength );
        }
        else
            draft.strength = null;
    }
};


const reducers = {
    [ setLoginEmail ]:                  ( draft, payload ) => formValueReducer( draft, payload, forgotPasswordSchema ),
    [ setFormValueForgotPassword ]:     updateForm,
    [ setTouchedForgotPassword ]:       focusReducer
};

export {
    requestUserPasswordToken,
    validateResetToken,
    resetPassword
};

export default ezRedux( reducers, initialState );
