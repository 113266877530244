import { asArray } from '../helpers/index.js';

const associations = {
    "FM": {
        "name":  "Federated States Of Micronesia",
        "other": [ "Micronesia" ]
    },
    "MH": {
        "name": "Marshall Islands"
    },
    "PW": {
        "name": "Palau"
    }
};

const territories = {
    "AS": {
        "name": "American Samoa"
    },
    "GU": {
        "name": "Guam"
    },
    "MP": {
        "name": "Northern Mariana Islands"
    },
    "PR": {
        "name": "Puerto Rico"
    },
    "VI": {
        "name":  "Virgin Islands",
        "other": [ "U.S.V.I.", "U.S. Virgin Islands" ]
    }
};

const states = {
    "AL": {
        "name": "Alabama",
        "AP":   "Ala."
    },
    "AK": {
        "name":  "Alaska",
        "AP":    "Alaska",
        "other": [ "Alas." ]
    },
    "AZ": {
        "name": "Arizona",
        "AP":   "Ariz."
    },
    "AR": {
        "name": "Arkansas",
        "AP":   "Ark."
    },
    "CA": {
        "name":  "California",
        "AP":    "Calif.",
        "other": [ "Cal." ]
    },
    "CO": {
        "name":  "Colorado",
        "AP":    "Colo.",
        "other": [ "Col.", "CL" ]
    },
    "CT": {
        "name": "Connecticut",
        "AP":   "Conn."
    },
    "DE": {
        "name": "Delaware",
        "AP":   "Del."
    },
    "DC": {
        "name":  "District of Columbia",
        "AP":    "D.C.",
        "other": [ "Wash. D.C.", "Washington D.C." ]
    },
    "FL": {
        "name":  "Florida",
        "AP":    "Fla.",
        "other": [ "Flor." ]
    },
    "GA": {
        "name": "Georgia",
        "AP":   "Ga."
    },
    "HI": {
        "name": "Hawaii",
        "AP":   "Hawaii"
    },
    "ID": {
        "name":  "Idaho",
        "AP":    "Idaho",
        "other": [ "Ida." ]
    },
    "IL": {
        "name":  "Illinois",
        "AP":    "Ill.",
        "other": [ "Ills.", "Ill's" ]
    },
    "IN": {
        "name": "Indiana",
        "AP":   "Ind."
    },
    "IA": {
        "name":  "Iowa",
        "AP":    "Iowa",
        "other": [ "Ioa." ]
    },
    "KS": {
        "name":  "Kansas",
        "AP":    "Kans.",
        "other": [ "Ka." ]
    },
    "KY": {
        "name":  "Kentucky",
        "AP":    "Ky.",
        "other": [ "Ken.", "Kent." ]
    },
    "LA": {
        "name": "Louisiana",
        "AP":   "La."
    },
    "ME": {
        "name": "Maine",
        "AP":   "Maine"
    },
    "MD": {
        "name": "Maryland",
        "AP":   "Md."
    },
    "MA": {
        "name": "Massachusetts",
        "AP":   "Mass."
    },
    "MI": {
        "name": "Michigan",
        "AP":   "Mich."
    },
    "MN": {
        "name": "Minnesota",
        "AP":   "Minn."
    },
    "MS": {
        "name": "Mississippi",
        "AP":   "Miss."
    },
    "MO": {
        "name": "Missouri",
        "AP":   "Mo."
    },
    "MT": {
        "name": "Montana",
        "AP":   "Mont."
    },
    "NE": {
        "name":  "Nebraska",
        "AP":    "Neb.",
        "other": [ "Nebr.", "NB" ]
    },
    "NV": {
        "name": "Nevada",
        "AP":   "Nev."
    },
    "NH": {
        "name": "New Hampshire",
        "AP":   "N.H."
    },
    "NJ": {
        "name":  "New Jersey",
        "AP":    "N.J.",
        "other": [ "N.Jersey" ]
    },
    "NM": {
        "name":  "New Mexico",
        "AP":    "N.M.",
        "other": [ "New M.", "N. Mex" ]
    },
    "NY": {
        "name":  "New York",
        "AP":    "N.Y.",
        "other": [ "N. York" ]
    },
    "NC": {
        "name":  "North Carolina",
        "AP":    "N.C.",
        "other": [ "N. Car.", "North Car." ]
    },
    "ND": {
        "name":  "North Dakota",
        "AP":    "N.D.",
        "other": [ "NoDak", "N. Dak.", "North Dak." ]
    },
    "OH": {
        "name":  "Ohio",
        "AP":    "Ohio",
        "other": [ "O." ]
    },
    "OK": {
        "name": "Oklahoma",
        "AP":   "Okla."
    },
    "OR": {
        "name":  "Oregon",
        "AP":    "Ore.",
        "other": [ "Oreg." ]
    },
    "PA": {
        "name":  "Pennsylvania",
        "AP":    "Pa.",
        "other": [ "Penn.", "Penna." ]
    },
    "RI": {
        "name":  "Rhode Island",
        "AP":    "R.I.",
        "other": [ "R.I. & P.P.", "R. Isl." ]
    },
    "SC": {
        "name":  "South Carolina",
        "AP":    "S.C.",
        "other": [ "S. Car.", "South Car." ]
    },
    "SD": {
        "name":  "South Dakota",
        "AP":    "S.D.",
        "other": [ "SoDak", "S. Dak.", "South Dak." ]
    },
    "TN": {
        "name": "Tennessee",
        "AP":   "Tenn."
    },
    "TX": {
        "name":  "Texas",
        "AP":    "Texas",
        "other": [ "Tex." ]
    },
    "UT": {
        "name": "Utah",
        "AP":   "Utah"
    },
    "VT": {
        "name": "Vermont",
        "AP":   "Vt."
    },
    "VA": {
        "name":  "Virginia",
        "AP":    "Va.",
        "other": [ "Virg." ]
    },
    "WA": {
        "name":  "Washington",
        "AP":    "Wash.",
        "other": [ "Wn." ]
    },
    "WV": {
        "name":  "West Virginia",
        "AP":    "W.Va",
        "other": [ "W. Virg.", "West Virg." ]
    },
    "WI": {
        "name":  "Wisconsin",
        "AP":    "Wis.",
        "other": [ "Wisc.", "WS" ]
    },
    "WY": {
        "name": "Wyoming",
        "AP":   "Wyo."
    }
};

const allRegions = { ...states, ...territories, ...associations };

// sanitize words to only lowercase and uppercase letters
function sanitizeToLettersOnly( words )
{
    if ( !words ) return [];

    const sanitize = word => word.trim().replace( /[^a-zA-Z]/g, '' ).toUpperCase();

    return Array.isArray( words ) ? words.map( sanitize ) : sanitize( words );
}

// map all state variations to their USPS abbreviation
function getPatterns( json )
{
    return Object.fromEntries(
        Object.entries( json ).map(
            ([ key, value ]) => [ key, [ key, value.name, value.AP, value.other ].flatMap( sanitizeToLettersOnly ) ]
        )
    );
}

const patterns = {
    state:      getPatterns( states ),
    territory:  getPatterns( territories ),
    associated: getPatterns( associations )
};

const defaultOptions = {
    region:     'state',    // [String|Array] ('all', 'state', 'territory', 'associated')
    returnType: 'USPS'      // [String|Function] ('USPS', 'name', 'AP')
};

export default function normalizeState( state, options = {})
{
    state   = sanitizeToLettersOnly( state );

    const opts    = { ...defaultOptions, ...options };
    const regions = opts.region === 'all' ? [ 'state', 'territory', 'associated' ] : asArray( opts.region );

    for ( const r of regions )
    {
        const p = patterns[ r ];

        const uspsKey = Object.keys( p ).find( key => ~p[ key ].indexOf( state ) );

        if ( !uspsKey ) continue;

        if ( opts.returnType === 'USPS' )
            return uspsKey || null;

        return allRegions[ uspsKey ][ opts.returnType ] || null;
    }
}
