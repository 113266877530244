/**
 * @param {SelfUser|{}} user
 * @return {boolean}
 */
const isUser = user => user?.userId !== undefined;

const selectHasUserAcceptedTerms = state => {
    const user = state.user;
    return !!isUser( user ) && user.eula && user.privacyPolicy && user.termsConditions;
};

/**
 * @param {BaseState} state
 * @return {ExtendedEngineer&Database.User|undefined}
 */
const selectUser = state => state.user?.userId ? state.user : undefined;

const selectExternalJobCount = state => state.user.externalJobCount ?? 0;

const selectUserTerms = state => {
    const user = state.user;

    return isUser( user ) ? {
        eula:    user.eula,
        privacy: user.privacyPolicy,
        terms:   user.termsConditions
    } : void 0;
};


export {
    selectHasUserAcceptedTerms,
    selectUser,
    selectExternalJobCount,
    selectUserTerms
};
