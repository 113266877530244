import { uri, GET, POST } from "../../../constants.js";
import { genericRequest } from "../../../redux/reducerUtil.js";
import { abstractedCreateAuthRequest } from "../../../services/util/baseRequests.js";

import { setProvidersThunk, setProviderConnectionThunk } from "./thunks.js";

const getProviders = () => genericRequest({}, abstractedCreateAuthRequest( GET, uri.PROVIDERS ), setProvidersThunk );
const connectToProvider = ( providerName, args ) => genericRequest( 
    args, abstractedCreateAuthRequest( POST, uri.CONNECT_TO_PROVIDER ), setProviderConnectionThunk, [ providerName ]);

export {
    getProviders,
    connectToProvider
};
