import React from 'react';

import { pruneFirstHostPart } from '@licoriceio/utils';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_REGISTRATION_COMPANY_NAME, UX_REGISTRATION_LICORICE_URL } from "../../ux-constants.js";
import { setFormValueRegistration, setTouchedRegistration } from '../../redux/actions/index.js';
import { root, urlPostLabel } from '../../scss/Company.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { onEnter } from '../../utils/misc.js';
import { H3, LicoTextField } from "../common/index.js";

import { performRegistration } from './reducer.js';

const CompanyForm = ({ companyName, url, setFormValueRegistration, fieldTouched, fieldError, setTouchedRegistration, performRegistration }) => (

    <div className={root}>
        <div>{__( "Your company is the space where you and your team can collaborate with customers." )}</div>

        <br/>

        <LicoTextField
            name="companyName"
            label={__( "Company name" )}
            data-ux={UX_REGISTRATION_COMPANY_NAME}
            autoFocus
            value={companyName}

            helperText={fieldTouched.companyName ? fieldError.companyName : ''}
            error={!!fieldError.companyName && fieldTouched.companyName}

            onChange={e => setFormValueRegistration({ field: e.target.name, value: e.target.value })}
            onBlur={e => setTouchedRegistration( e.target.name )}
            onKeyPress={onEnter( performRegistration )}
        />

        <Grid container spacing={2} alignItems="center" alignContent="stretch">
            <Grid item xs={9}>
                <LicoTextField
                    value={url}
                    name="url"
                    data-ux={UX_REGISTRATION_LICORICE_URL}
                    label={__( "Licorice URL" )}

                    helperText={fieldTouched.url ? fieldError.url : ''}
                    error={!!fieldError.url && fieldTouched.url}

                    onChange={e => setFormValueRegistration({ field: e.target.name, value: e.target.value })}
                    onBlur={e => setTouchedRegistration( e.target.name )}
                    onKeyPress={onEnter( performRegistration )}
                />
            </Grid>

            <Grid item xs={3}>
                <H3 component="p" className={urlPostLabel}>.{pruneFirstHostPart( window.location.hostname )}</H3>
            </Grid>
        </Grid>
    </div>
);

CompanyForm.propTypes = {
    companyName:                    PropTypes.string.isRequired,
    url:                            PropTypes.string.isRequired,
    setFormValueRegistration:       PropTypes.func.isRequired,
    fieldTouched:                   PropTypes.object.isRequired,
    fieldError:                     PropTypes.object.isRequired,
    setTouchedRegistration:         PropTypes.func.isRequired,
    performRegistration:            PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    companyName:    state.registration.companyName,
    url:            state.registration.url,
    fieldTouched:   state.registration.fieldTouched,
    fieldError:     state.registration.fieldError
});

const mapDispatchToProps = { setFormValueRegistration, setTouchedRegistration, performRegistration };

export default connect( mapStateToProps, mapDispatchToProps )( CompanyForm );
export { CompanyForm };
