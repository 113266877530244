import React from 'react';

import { pick } from '@licoriceio/utils';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';

import { UserProfileMode } from '../../../constants.js';
import { setUserMfaTransition, setSettingValueUserProfile, setAddUserBusinessHours, setRemoveUserBusinessHours, discardUserSettingChanges } from '../../../redux/actions/index.js';
import { editable, fieldOutline, readonly } from '../../../scss/EngineerLine.module.scss';
import { settings, screenTitle, bold, boxLabel, numberField, numberFieldLine, centredFlex, flexColumn, circularDiv } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { MfaStates } from '../../../utils/mfa.js';
import BusinessHoursList from '../../common/BusinessHoursList.jsx';
import { H1, LicoSwitch, LicoTextField, LicoInlineTextField } from "../../common/index.js";
import NumberDropdown from '../../common/NumberDropdown.jsx';
import { saveUserSettingChanges } from '../engineer/reducer.js';
import { SettingsSection, SettingsBox, SaveButton } from '../utilities.jsx';

const useStyles = makeStyles({
    propWidth: ({
        width:              props => `${props.width} !important`
    })
});

const textChange = setter => e => setter({
    name:       e.target.name,
    value:      e.target.value
});

const switchChange = setter => ( e, checked ) => setter({
    name:       e.target.name,
    value:      checked
});

const ProfileTextField = ({ value, label, note, onChange, disabled, maxLength, width, error }) => (
    <span className={`${centredFlex} ${numberFieldLine}`}>
        <LicoTextField
            name={Object.keys( value )[ 0 ]}
            value={String( Object.values( value )[ 0 ])}
            placeholder=""
            inputClassName={`${numberField} ${useStyles({ width }).propWidth}`}
            label={label}
            labelClassName={boxLabel}
            size="small"
            error={!!error}
            helperText={error}
            inlineLabel
            noMargins
            disabled={disabled}
            inputProps={{
                maxLength
            }}
            onChange={onChange}
            onBlur={onChange}
        />
        {note}
    </span>
);

ProfileTextField.propTypes = {
    value:      PropTypes.any,
    label:      PropTypes.string,
    note:       PropTypes.any,
    onChange:   PropTypes.func.isRequired,
    disabled:   PropTypes.bool,
    width:      PropTypes.string,
    maxLength:  PropTypes.number,
    error:      PropTypes.string
};

const UserProfileContent = ({
    engineerProfile,
    name,
    title,
    phone,
    loginEmail,
    useGravatar,
    useAlternativeGravatarEmail,
    alternativeGravatarEmail,
    mode,
    twoFactorAuthenticationRequired,
    defaultAppointmentDuration,
    businessHours,
    overrideOrgHours,
    organisationMfa,
    foundChanges,
    saveUserSettingChanges,
    discardUserSettingChanges,
    setSettingValueUserProfile,
    setUserMfaTransition,
    setAddUserBusinessHours,
    setRemoveUserBusinessHours,
    fieldError
}) => {
    const editMode = mode === UserProfileMode.EDIT || mode === UserProfileMode.ADD;
    return (

        <div className={settings}>
            <H1 className={screenTitle}>{engineerProfile ? name : __( "Your Profile" )}</H1>

            <SettingsSection >
                <SettingsBox label={__( "Profile picture" )} topLabel >
                    <div className={flexColumn}>
                        <span>
                            <LicoSwitch name="useGravatar" checked={useGravatar} onChange={switchChange( setSettingValueUserProfile )}  disabled={!editMode}  />
                            {__( "Display a Gravatar image for my avatar" )}
                        </span>
                        <span>
                            <LicoSwitch name="useAlternativeGravatarEmail" disabled={!useGravatar} 
                                checked={useAlternativeGravatarEmail} onChange={switchChange( setSettingValueUserProfile )} />
                            {__( "Use this alternative email for my Gravatar image" )}
                            &nbsp;
                            &nbsp;
                            <LicoInlineTextField
                                value={alternativeGravatarEmail || ''}
                                name="alternativeGravatarEmail"
                                InputProps={{
                                    classes: {
                                        root:           ( !useGravatar || !useAlternativeGravatarEmail ) ? readonly : editable,
                                        notchedOutline: fieldOutline
                                    }
                                }}
                                onChange={textChange( setSettingValueUserProfile )}
                                disabled={!useGravatar || !useAlternativeGravatarEmail}
                            />
                        </span>
                        {useGravatar && <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                            <Gravatar email={useAlternativeGravatarEmail ? alternativeGravatarEmail : loginEmail} size={200} />
                            <Gravatar email={useAlternativeGravatarEmail ? alternativeGravatarEmail : loginEmail} size={40} className={circularDiv} />
                            <Gravatar email={useAlternativeGravatarEmail ? alternativeGravatarEmail : loginEmail} size={24} className={circularDiv} />
                        </div>}
                    </div>
                    
                </SettingsBox>
                <ProfileTextField value={{ name }} label={__( "Name*" )} onChange={textChange( setSettingValueUserProfile )}  width="16rem" error={fieldError?.name}  disabled={!editMode} />
                <ProfileTextField value={{ title }} label={__( "Position" )} onChange={textChange( setSettingValueUserProfile )} width="16rem"  disabled={!editMode}/>
                <ProfileTextField value={{ loginEmail  }} onChange={textChange( setSettingValueUserProfile )} disabled={ mode !== UserProfileMode.ADD} label={__( "Email*" )}   width="20rem" error={fieldError?.loginEmail}  />
                <ProfileTextField value={{ phone }} label={__( "Phone" )} onChange={textChange( setSettingValueUserProfile )} width="12rem"  disabled={!editMode}/>
            </SettingsSection>
            <SettingsSection label={ __( "Time Entry" ) }>
                <>
                    <LicoSwitch
                        name="overrideOrgHours"
                        checked={overrideOrgHours}
                        disabled={!editMode || businessHours?.length === 0}
                        onChange={switchChange( setSettingValueUserProfile )}
                    />
                    {__( "I have different work hours from the organisation" )}
                </>
                {businessHours && editMode &&
                            <SettingsBox label={__( "My work hours" )} topLabel >
                                <BusinessHoursList
                                    businessHours={businessHours}
                                    setValue={setSettingValueUserProfile}
                                    addItem={setAddUserBusinessHours}
                                    removeItem={setRemoveUserBusinessHours}
                                    error={fieldError.businessHours}
                                    allowEmptyList
                                />
                            </SettingsBox>
                }
            </SettingsSection>
            {
                !engineerProfile && <SettingsSection label={ __( "Security" ) }>
                    {organisationMfa && <div className={bold}>{__( "Two-factor authentication is required by your organisation" )}</div>}
                    {twoFactorAuthenticationRequired !== undefined && (
                        <>
                            <LicoSwitch
                                name="twoFactorAuthenticationRequired"
                                checked={twoFactorAuthenticationRequired}
                                onChange={( e, checked ) => {

                                    if ( checked ) 
                                        setUserMfaTransition( MfaStates.CONFIRM );
                                    
                                    setSettingValueUserProfile({
                                        name:      e.target.name,
                                        value:     checked
                                    });

                                }}
                            />
                            {__( "Enable two-factor authentication" )}
                        </>
                    )}
                </SettingsSection>
            }
            {defaultAppointmentDuration &&
                        <SettingsSection label={ __( "Calendar" ) }>
                            <NumberDropdown
                                value={{ defaultAppointmentDuration }}
                                label={__( "Default Appointment Duration" )}
                                options={[ 15, 30, 45, 60 ].map( value => ({ id: value, label: value === 60 ? __( '1 hour' ) : __( '{value} minutes', { value }) }) )}
                                labelClassName={boxLabel}
                                inputClassName='slim'
                                setValue={setSettingValueUserProfile}
                                disabled={!editMode}
                            />
                        </SettingsSection>
            }            
            {
                mode !== UserProfileMode.VIEW && <>
                    <SaveButton 
                        handler={saveUserSettingChanges} 
                        disabled={!foundChanges || Object.values( fieldError ).filter( s => s?.length > 0 ).length > 0 } 
                    />
                    {engineerProfile && <SaveButton 
                        handler={discardUserSettingChanges} 
                        label={__( 'Cancel' )}
                    />}
                </>
            }
        </div>

    );

};

UserProfileContent.propTypes = {
    twoFactorAuthenticationRequired:    PropTypes.bool,
    organisationMfa:                    PropTypes.bool,
    foundChanges:                       PropTypes.bool,
    saveUserSettingChanges:             PropTypes.func,
    discardUserSettingChanges:          PropTypes.func,
    setSettingValueUserProfile:         PropTypes.func,
    setUserMfaTransition:               PropTypes.func,
    fieldError:                         PropTypes.object,
    defaultAppointmentDuration:         PropTypes.number,
    businessHours:                      PropTypes.array,
    overrideOrgHours:                   PropTypes.bool,
    setAddUserBusinessHours:            PropTypes.func.isRequired,
    setRemoveUserBusinessHours:         PropTypes.func.isRequired,
    engineerProfile:                    PropTypes.bool.isRequired,
    name:                               PropTypes.string,
    title:                              PropTypes.string,
    loginEmail:                         PropTypes.string,
    phone:                              PropTypes.string,
    useGravatar:                        PropTypes.bool,
    useAlternativeGravatarEmail:        PropTypes.bool,
    alternativeGravatarEmail:           PropTypes.string,
    mode:                               PropTypes.string.isRequired
};

const mapStateToProps = state => {

    const {
        organisation: { live },
        userProfile
    } = state || {};
    const organisationMfa = live?.twoFactorAuthenticationRequired;
    const profileExtract = pick( userProfile, [ 
        'engineerProfile',
        'name',
        'title',
        'phone',
        'loginEmail',
        'mode',
        'useGravatar',
        'useAlternativeGravatarEmail',
        'alternativeGravatarEmail',
        'twoFactorAuthenticationRequired', 
        'mfaState', 
        'mfaVerified', 
        'defaultAppointmentDuration', 
        'foundChanges', 
        'businessHours', 
        'overrideOrgHours', 
        'fieldError'
    ]);

    return {
        ...profileExtract,
        organisationMfa
    };
};

const mapDispatchToProps = {
    setSettingValueUserProfile,
    setUserMfaTransition,
    setAddUserBusinessHours,
    setRemoveUserBusinessHours,
    saveUserSettingChanges,
    discardUserSettingChanges
};

export default connect( mapStateToProps, mapDispatchToProps )( UserProfileContent );
export { UserProfileContent };
