import React from 'react';

import { UserRole } from '@licoriceio/constants';
import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userDialogMode } from '../../constants.js';
import { setCloseUserDialog, setUserDialogMode } from '../../redux/actions/index.js';
import { userDetails, label, header, headerName, editButton, closeButton,
    readonly, viewLabel, viewValue, saveButton } from '../../scss/UserDetails.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { LicoIcon, LicoTextField, LicoFab, LicoButton } from '../common/index.js';
import { saveUserChanges } from '../jobcard/reducer.js';

import { setFormValueAction, setTouchedUserDetails, createToken } from './userDetails.js';

const UserDetailsForm = ({
    anchorEl,
    open,
    userId,
    mode,
    name,
    role,
    phone,
    email,
    title,
    companyName,
    fieldError,
    fieldTouched,
    setCloseUserDialog,
    setFormValueAction,
    setTouchedUserDetails,
    setUserDialogMode,
    saveUserChanges
}) => {

    const formPackage = {
        onChange:   setFormValueAction,
        onBlur:     setTouchedUserDetails,
        fieldError,
        fieldTouched
    };

    const fields = [
        {
            name:       'client',
            label:      __( 'Client' ),
            value:      companyName,
            disabled:   true
        },
        {
            name:   'name',
            label:  __( 'Name' ),
            value:  name
        },
        {
            name:   'title',
            label:  __( 'Position' ),
            value:  title
        },
        {
            name:   'email',
            label:  __( 'Email' ),
            value:  email
        },
        {
            name:   'phone',
            label:  __( 'Phone' ),
            value:  phone
        }
    ];

    const editField = field => <LicoTextField
        key={field.name}
        name={field.name}
        label={field.label}
        value={field.value}
        disabled={field.disabled}
        autoFocus={field.name === 'name'}
        size="small"
        labelClassName={label}
        inlineLabel
        formPackage={formPackage}
        inputClassName="flexGrow"
    />;

    const viewField = field => <div key={field.name} className={readonly}>
        <div className={viewLabel}>{field.label}</div>
        <div className={viewValue}>{field.value?.length ? field.value : <span>&nbsp;</span> }</div>
    </div>;

    if ( !open )
        return null;

    return <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'left'
        }}
        transformOrigin={{
            vertical:   'top',
            horizontal: 'left'
        }}
        open={open}
        onClose={() => setCloseUserDialog()}
    >

        <div className={userDetails}>
            <div className={header}>
                <div className={headerName}>{mode === userDialogMode.ADD ? __( "Add new user" ) : name}</div>
                {role === UserRole.user && mode === userDialogMode.VIEW && <LicoFab
                    onClick = {() => setUserDialogMode( userDialogMode.EDIT ) }
                    licoVariant = "small"
                    className={editButton}>
                    <LicoIcon icon="edit" />
                </LicoFab>}

                {/* don't commit this, it's for testing client appts
                {role === UserRole.user && mode === userDialogMode.VIEW &&
                    <LicoFab
                        onClick = {() => {
                            const job = jobMap[ currentJobCardId ];
                            const user = users.find( u => u.userId === userId );
                            console.log( 'add token', job, user  );
                            createToken({ job, user, email: 'ickphum@gmail.com' });
                        } }
                        licoVariant = "small"
                        className={editButton}>
                        <LicoIcon icon="user" />
                    </LicoFab>
                }
                */}

                <div className="flexGrow" />
                <LicoFab
                    onClick = {setCloseUserDialog}
                    licoVariant = "small"
                    className={closeButton}>
                    <LicoIcon icon="close" />
                </LicoFab>
            </div>
            {fields

                // don't display client for engineers
                .filter( field => field.name !== 'client' || role === UserRole.user )

                .map( field => mode === userDialogMode.VIEW ? viewField( field ) : editField( field ) )}
            {mode !== userDialogMode.VIEW && <div style={{ display: "flex" }}>
                <div className="flexGrow" />
                <LicoButton
                    onClick={() => saveUserChanges({
                        userId,
                        name,
                        title
                    })}
                    className={saveButton}
                    disabled={false}
                >
                    { mode === userDialogMode.EDIT ? __( "Save" ) : __( "Add" ) }
                </LicoButton>
            </div>}

        </div>
    </Popover>;
};

UserDetailsForm.propTypes = {
    companyName:            PropTypes.string,
    mode:                   PropTypes.string.isRequired,
    userId:                 PropTypes.string,
    name:                   PropTypes.string,
    role:                   PropTypes.string,
    phone:                  PropTypes.string,
    email:                  PropTypes.string,
    title:                  PropTypes.string,
    fieldError:             PropTypes.object.isRequired,
    fieldTouched:           PropTypes.object.isRequired,
    setCloseUserDialog:     PropTypes.func.isRequired,
    setFormValueAction:     PropTypes.func.isRequired,
    setTouchedUserDetails:  PropTypes.func.isRequired,
    setUserDialogMode:      PropTypes.func.isRequired,
    saveUserChanges:        PropTypes.func.isRequired,
    anchorEl:               PropTypes.object,
    open:                   PropTypes.bool
};

const mapStateToProps = state => {

    const { userDetails: { userId, mode, name, role, contactInfo, title } } = state;
    const { jobcard: { currentJobCardId, users }, job: { jobMap } } = state;

    return {
        userId,
        mode,
        name,
        role,
        phone:                  contactInfo?.find( contact => contact.type === "phone" )?.value || '',
        email:                  contactInfo?.find( contact => contact.type === "email" )?.value || '',
        title,
        fieldTouched:           state.userDetails.fieldTouched,
        fieldError:             state.userDetails.fieldError,
        currentJobCardId,
        jobMap,
        users
    };
};
const mapDispatchToProps = {
    setCloseUserDialog,
    setFormValueAction,
    setTouchedUserDetails,
    setUserDialogMode,
    saveUserChanges,
    createToken
};

export default connect( mapStateToProps, mapDispatchToProps )( UserDetailsForm );
export { UserDetailsForm };
