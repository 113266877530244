import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { buildEnv } from '../../build-env.js';
import { getVersionInfoThunk, versionRequestType } from '../../redux/reducers/meta.js';
import { tooltip } from '../../scss/VersionTooltip.module.scss';
import { __ } from '../../utils/i18n.jsx';

import { LOGO_BYLINE } from './strings.js';

const NoMaxWidthTooltip = styled( ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={2000} />
) )({
    [ `& .${tooltipClasses.tooltip}` ]: {
        maxWidth: 'none'
    }
});

const VersionTooltip = ({ version, versionArgs, getVersionInfoThunk, children }) => (
    <NoMaxWidthTooltip
        title={
            <div className={tooltip}>
                <span>{LOGO_BYLINE}</span>
                <hr style={{ width: "70%" }} />
                <div><span>{ __( "Package: " )}</span><span>{version.licorice}</span></div>
                <div><span>{ __( "Build: " )}</span><span>{version.build}</span></div>
                <div><span>{ __( "Commit: " )}</span><span>{( version.commits?.local || "" ).substr( 0, 8 )}</span></div>
                <div><span>{ __( "Environment: " )}</span><span>{version.envVersion}/{version.envBuild}</span></div>
                <div><span>{ __( "UI Environment: " )}</span><span>{buildEnv.LICORICE_VERSION}/{buildEnv.BUILD_VERSION}</span></div>
            </div>}
        onOpen={() => getVersionInfoThunk( versionArgs ? versionRequestType.EXTENDED : versionRequestType.BASIC, versionArgs )}
    >
        <span>{children}</span>
    </NoMaxWidthTooltip>
);
VersionTooltip.propTypes = {
    version:                PropTypes.object,
    versionArgs:            PropTypes.object,
    children:               PropTypes.node,
    getVersionInfoThunk:    PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    version:               state.meta.version
});

const mapDispatchToProps = {
    getVersionInfoThunk
};

export { VersionTooltip };

export default connect( mapStateToProps, mapDispatchToProps )( VersionTooltip );
