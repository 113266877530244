import React from 'react';

import { Tooltip  } from '@mui/material';
import PropTypes from 'prop-types';

import { __ } from '../../utils/i18n.jsx';

const EventTooltip = ({ heading, summary, description, providerJobId, children }) => (
    <Tooltip
        title={
            <div className="tooltip">
                <div>{heading}</div>
                <div className="highlight">{summary}</div>
                { !!description && <>
                    <hr />
                    <div>{description}</div>
                </>}
                { !!providerJobId && <>
                    <hr />
                    <div>{ `${__( "Job Id" )}: ${providerJobId}`}</div>
                </>}
            </div>}
        arrow
        placement="right-end"
        enterDelay={500}
    >
        {children}
    </Tooltip>
);

EventTooltip.propTypes = {
    heading:            PropTypes.string,
    summary:            PropTypes.string,
    description:        PropTypes.string,
    providerJobId:      PropTypes.string,
    children:           PropTypes.node
};

export default EventTooltip;
export { EventTooltip };
