/*********************************************************************************************************************
 * @file Configuration reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 2-Dec-2020
 *********************************************************************************************************************/

import { integrationNames } from '@licoriceio/constants';
import { asArray } from '@licoriceio/utils';

import { GET, uri } from '../../constants.js';
import { setTypeNames } from '../../redux/actions/index.js';
import { genericRequest } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';

const { PERSONAL, INTERNAL, JOB_STATUS, BILLING_TYPE } = integrationNames;

const getNamesRequest = type => genericRequest({}, abstractedCreateAuthRequest( GET, uri.NAMES_BY_TYPE ), [ [ setTypeNames, { type } ] ], [ type ]);

/**
 * @typedef {object} SingleNameState
 * @property {object[]} names
   @property {object} idToName
 * @property {object} nameToId

/**
 * @typedef {object} NameState
 * @property {SingleNameState} personalcardtype
   @property {SingleNameState} internalcardtype
 * @property {SingleNameState} jobStatus
 * @property {SingleNameState} billingType
 */

// const initialState = Object.freeze({});

const getNamesThunk = () => dispatch => {
    dispatch( getNamesRequest( PERSONAL ) );
    dispatch( getNamesRequest( INTERNAL ) );
    dispatch( getNamesRequest( JOB_STATUS ) );
    dispatch( getNamesRequest( BILLING_TYPE ) );
};

const _setTypeNames = ( draft, payload ) => {
    draft[ payload.type ] = {
        objects:    payload.payload,
        idToName:   {},
        nameToId:   {},
        options:    payload.payload.map( name => ({ id: name.licoriceNameId, label: name.name }) )
    };

    // If there was just one hash we'd get it in one step via reduce, but doing that for 2 involves
    // 2 passes thru the loop
    payload.payload.forEach( name => {
        draft[ payload.type ].idToName[ name.licoriceNameId ] = name.name;
        draft[ payload.type ].nameToId[ name.name ] = name.licoriceNameId;
    });
};

const setSomeTypeNames = ( ...types ) => ( draft, payload ) => asArray( types ).includes( payload.type ) ? _setTypeNames( draft, payload ) : draft;

// If we want a separate slice to store all the names, restore this code (and initialState above) and create the 'names' reducer as usual.
// For now, each name type will live in whatever slices need them FOR 
// const reducers = {
//     [ setTypeNames ]:                   setSomeTypeNames( 'personalcardtype', 'internalcardtype' )
// };
//
// export default ezRedux( reducers, initialState );

export { getNamesThunk, setSomeTypeNames };
