/*********************************************************************************************************************
 * @file Pegboard reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 30-Dec-2020
 *********************************************************************************************************************/

import { HOUR, MINUTE, SECOND, DELETE } from '@licoriceio/constants';
import { numMinutesToString, getTimeSeriesGaps, pick, stringToNumMinutes } from '@licoriceio/utils';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { uri, POST, PATCH, timeEnteredProgress, PEGBOARD_SLOTS } from '../../constants.js';
import { setTimeLogs, setAddTimeLog, setDeleteTimeLog, setPatchTimeLog, setFinishTimeLog, setUpdatePegboardTimers,
    setElapsedMeter, adjustTimeLogTime, setLocalJobTime, setIntervalId, setIncrementTimeEntered, setAddLocalTimeLog, setDeleteLocalTimeLog,
    setEditableTime } from '../../redux/actions/index.js';
import { ezRedux, genericReducer, genericRequest, makeMapById } from '../../redux/reducerUtil.js';
import { getJobFromState, selectCacheRecord } from '../../redux/selectors/index.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { addTimeLogOnPegboard, addPausedTimeLogOnPegboard  } from '../calendar/shared.js';
import { setExistingJobThunk, updateMeter, getTimeEnteredToday, addLocalTimeLogThunk } from '../calendar/sharedThunks.js';

/**
 * @typedef {object} TimeLogState
 * @property {string} intervalId
 * @property {object<string, number>} jobTimeMap - current timer value in seconds keyed by jobId
 * @property {object<string, Database.TimeLog>} timeLogMap   - timeLogs and interval IDs keyed by timeLogId
 * @property {object<string, number>} elapsedMeters   - progress bars by jobId
 * @property {number} needsUpdate
 */

/**
  * @type {TimeLogState}
  */
const initialState = Object.freeze({
    intervalId:             null,
    timeLogMap:             null,
    jobTimeMap:             {},
    elapsedMeters:          {},
    needsUpdate:            0,
    _items:                 [],
    timeEntered:            0,
    timeEnteredDayStart:    null
});

/** services */

const _asyncDeleteTimeLog = abstractedCreateAuthRequest( DELETE, uri.TIME_LOG_REMOVE );
const _asyncResumePegboardTimer = abstractedCreateAuthRequest( PATCH, uri.TIME_LOG_PLAY );
const _asyncPausePegboardTimer = abstractedCreateAuthRequest( PATCH, uri.TIME_LOG_PAUSE );
const _asyncFinishTimeLog = abstractedCreateAuthRequest( PATCH, uri.TIME_LOG_LOG );
const _asyncCreateTimeLog = abstractedCreateAuthRequest( POST, uri.TIME_LOG_CREATE );
const _asyncEditTimeLog = abstractedCreateAuthRequest( PATCH, uri.TIME_LOG_EDIT );

const deleteTimeLog = payload => genericRequest({}, _asyncDeleteTimeLog, [ [ setDeleteTimeLog, { timeLogId: payload  } ] ], [ payload ]);
const resumePegboardTimer = ( timeLogId, jobId ) => genericRequest({}, _asyncResumePegboardTimer, [ setPatchTimeLog, [ updateMeter, { jobId } ] ], [ timeLogId ]);
const pausePegboardTimer = payload => genericRequest({ pegboard: payload.pegboard }, _asyncPausePegboardTimer, [ setPatchTimeLog ], [ payload.timeLogId ]);
const finishTimeLog = ( payload, jobId ) => genericRequest(
    {
        duration:       payload.duration,
        billingTypeId:  payload.billingTypeId,
        billable:       payload.billable,
        note:           payload.note
    }, _asyncFinishTimeLog, [ [ setFinishTimeLog, { oldTimeLogId: payload.timeLogId, note: payload.note } ], [ updateMeter, { jobId } ] ], [ payload.timeLogId ]
);
const createTimeLog = payload =>
    genericRequest( 
        pick( payload, [ 'timeLogId', 'duration', 'billingTypeId' ]),
        _asyncCreateTimeLog, 
        [ setAddTimeLog, [ updateMeter, { jobId: payload.jobId } ] ], 
        [ payload.jobId ]
    );
const editTimeLog = ( payload, jobId ) => genericRequest({ duration: payload.duration }, _asyncEditTimeLog, [ setPatchTimeLog, [ updateMeter, { jobId } ] ], [ payload.timeLogId ]);


/**
 * Find the timelog for the specified job, or null if one doesn't exist
 *
 * @param {string} jobId
 * @param {TimeLogState} timeLogState
 * @returns Database.TimeLog | null
 */
const getJobTimeLog = ( jobId, timeLogState ) => {

    const timeLogs = Object.values( timeLogState.timeLogMap || {}).filter( tl => tl.jobId === jobId );

    if ( timeLogs.length > 0 ) {
        if ( timeLogs.length > 1 )
            console.warn( 'Multiple timelogs for job', jobId, timeLogs );
        return timeLogs[ 0 ];
    }
    else
        return null;
};

/** thunk actions */

/**
 * Either unpauses an existing timeLog or creates a new one
 * @param {string} jobId
 * @returns void
 */
const dragJobToPegboard = jobId => ( dispatch, getState ) => {
    const state = getState();
    const { companyId, statusId } = getJobFromState( state, jobId );
    if ( statusId === state.jobcard.completedStatus )
        return;


    const timeLog = getJobTimeLog( jobId, state.timelog );

    // important safety tip; is there room on the pegboard?
    if ( !timeLog?.pegboard )
    {
        const { timelog: { _items } } = state;
        if ( _items.length === PEGBOARD_SLOTS ) 
            return;   
    }
    
    if ( timeLog ) 
    {

        // only update the timelog if the job was not on the PB or was paused
        if ( !timeLog.pegboard || timeLog.pausedAt )
        {
            // local update to show on board immediately, will be overwritten by real response from resumePegboardTimer
            if ( !timeLog.pegboard )
                dispatch( setPatchTimeLog({ ...timeLog, start: new Date().toISOString(), pausedAt: null, pegboard: true }) );

            dispatch( resumePegboardTimer( timeLog.timeLogId, jobId ) );
        }
    }
    else {
        const companyMeta = selectCacheRecord( state, 'company', companyId );

        // we should have fetched the company status when the job was added or imported but just in case, don't crash if we don't have it
        if ( companyMeta?.readOnly )
            dispatch( addPausedTimeLogOnPegboard( jobId ) );
        else 
        {
            dispatch( addLocalTimeLogThunk( jobId ) );
            dispatch( addTimeLogOnPegboard( jobId ) );
        }

    }

    dispatch( setExistingJobThunk({ jobId }) );
};

/**
 * Either pauses a started timeLog or deletes an unstarted one
 *
 * @param {string} timeLogId
 * @returns void
 */
const removeJobFromPegboard = timeLogId => ( dispatch, getState ) => {
    const timeLog = getState().timelog.timeLogMap[ timeLogId ];

    // I don't believe the else branch ever fires here; items on the PB always have start set.
    // To remove the current timeLog from a job and not have it immediately replaced, you have
    // to pause it, remove the job from the PB and then finish that timelog with a message.
    if ( timeLog.start )
    {
        // local update
        dispatch( setPatchTimeLog({ ...timeLog, pegboard: false }) );

        dispatch( pausePegboardTimer({ timeLogId, pegboard: false }) );
    }
    else
        dispatch( deleteTimeLog( timeLogId ) );
};

/**
 * Either edits an existing timeLog or creates a new one with the specified duration.
 *
 * @param {string} jobId
 * @param {string} timeLogId
 * @param {string} editableTime - the unvalidated string entered by the user
 * @returns void
 */
const saveTimerValue = ( jobId, timeLogId, editableTime, billingTypeId ) => ( dispatch, getState ) => {
    const { timelog: { jobTimeMap } } = getState();

    const newSeconds = stringToNumMinutes( editableTime ) * 60;
    const currentSeconds = jobTimeMap[ jobId ];

    // already saved timer, bail
    if ( newSeconds === currentSeconds ) 
        return;

    // update local time so if a note is about to be submitted, the time will be correct
    dispatch( setLocalJobTime({ jobId, seconds: newSeconds }) );
    dispatch( setEditableTime( '' ) );

    // no rounding on edited duration for now
    // convert to minutes (rounding up) then to counts of minimumTime (rounding up) then multiply out to go back to minutes
    // const newMinutes = Math.ceil( Math.ceil( newSeconds / 60 ) / minimumTimeEntry ) * minimumTimeEntry;

    if ( newSeconds >= 0 ) {
        if ( timeLogId )
            dispatch( editTimeLog({ timeLogId, duration: newSeconds * SECOND }, jobId ) );
        else {

            let newTimeLog;

            if ( !timeLogId ) {

                // set the id here so we can finish it if necessary, ie if we commit a time edit by creating a note
                timeLogId = uuidv4();
                newTimeLog = {
                    timeLogId,
                    jobId,
                    billingTypeId,
                    duration:       newSeconds * SECOND
                };
            }

            dispatch( createTimeLog({ timeLogId, jobId, duration: newSeconds * SECOND, billingTypeId }) );

            // if we created it, add it locally so the pending note can be added with the new id
            if ( newTimeLog )
                dispatch( setAddTimeLog( newTimeLog ) );
        }
    }
};

const finishCurrentTimeLog = ({ jobId, currentTimerSecs, billable, billingTypeId, note }) => ( dispatch, getState ) => {
    const timeLog = getJobTimeLog( jobId, getState().timelog );

    if ( timeLog?.start )
    {
        dispatch( finishTimeLog({
            timeLogId:  timeLog.timeLogId,
            duration:   currentTimerSecs * SECOND,
            billable,
            billingTypeId,
            note
        }, jobId ) );
    }

    return timeLog?.start ? timeLog.timeLogId : undefined;
};

/**
 * Check the day we last retrieved the starting total for time entered and request it again
 * if necessary, ie at midnight
 * @returns void
 */
const checkTimeEnteredDay = () => ( dispatch, getState ) => {

    const { timelog: { timeEnteredDayStart } } = getState();

    const dayStartNow = dayjs().startOf( 'day' ).toISOString();

    if ( dayStartNow > timeEnteredDayStart )
        dispatch( getTimeEnteredToday() );
};

/** reducers */

/**
 * update the render list for pegboard items
 * @param {TimeLogState} draft
 */
const updatePegboardStatics = draft => {
    draft._items = Object.values( draft.timeLogMap || {}).filter( tl => tl.pegboard );
};

/**
 * Update state after the closure of a timelog
 *
 * @param {TimeLogState} draft
 * @param {object} payload - payload contains the id of the closed timeLog
 * and possibly a new timer which replaces it
 */
const finishTimeLogReducer = ( draft, payload ) => {
    const { timeLogMap, jobTimeMap } = draft;
    const { oldTimeLogId, payload: { jobId } } = payload;

    // this is a log action which has closed the existing timeLog and
    // if the timer was running, has sent back a new timeLog record
    delete timeLogMap[ oldTimeLogId ];
    delete jobTimeMap[ jobId ];

    if ( payload.payload && !payload.payload.finish )
    {
        timeLogMap[ payload.payload.timeLogId ] = payload.payload;
        updatePegboardTimers( draft );
    }
    else
        updatePegboardStatics( draft );

};

/**
 * Calculate the elapsed time on the specified timer and update draft.jobTimeMap
 * @param {TimeLogState} draft
 * @param {Database.TimeLog} timeLog
 */
const updatePegboardTimer = ( draft, timeLog ) => {

    // start of calculation period is editedAt (if defined) or start.
    // end of calculation period is pausedAt (if defined) or now.
    // calculated result is then reduced by cumulativePause and increased by duration (which must have come from an edit)
    const { jobId, start, pausedAt, editedAt, cumulativePause, duration, pendingFinish } = timeLog;
    draft.jobTimeMap[ jobId ] = ( start && !pendingFinish )
        ? Math.ceil( ( ( pausedAt
            ? new Date( pausedAt )
            : new Date()
        ).getTime() - new Date( editedAt || start ).getTime() - cumulativePause + duration ) / SECOND )
        : duration
            ? duration / SECOND
            : 0;
};

/**
 * Update all running timers or all started timers (if updateAll is true)
 * @param {TimeLogState} draft
 * @param {boolean} updateAll - force update on all started timers, for init
 */
const updatePegboardTimers = ( draft, updateAll ) => {

    // update job times as of now for all timers with a start time and either they're running or we're forcing all to update (at init)
    Object.values( draft.timeLogMap || {})
        .filter( tl => ( updateAll || tl.pausedAt === null ) )
        .forEach( tl => updatePegboardTimer( draft, tl ) );
    updatePegboardStatics( draft );
};

/**
 * Set initial timeLog state and start the shared timer
 * @param {TimeLogState} draft
 * @param {object} payload - payload.payload is a list of TimeLogs
 */
const setTimeLogsReducer = ( draft, payload ) => {
    draft.timeLogMap = payload.payload.map( r => r.timeLog ).reduce( makeMapById( 'timeLogId' ), {});
    updatePegboardTimers( draft, true );
};

/**
 * Update state for a changed or added timeLog
 * @param {TimeLogState} draft
 * @param {Database.TimeLog} payload
 */
const updateTimeLogReducer = ( draft, payload ) => {

    // I found a situation where a job had multiple timelogs but I don't know how it happened;
    // check for it happening.
    const jobCount = Object.values( draft.timeLogMap ).filter( tl => tl.jobId === payload.jobId ).length;
    if ( jobCount <= 1 ) 
    {
        draft.timeLogMap[ payload.timeLogId ] = payload;
        updatePegboardTimer( draft, payload );

        // if there's a local timelog, remove it
        _setDeleteLocalTimeLog( draft );

        updatePegboardStatics( draft );
    }
    else
        console.error( 'Job has multiple timeLogs', draft.timeLogMap, payload );
};

/**
 * Mark a timeLog as pending finish; used to display 0 time locally while a note is pending
 * @param {TimeLogState} draft
 * @param {Database.TimeLog} payload
 */
const adjustTimeLogTimeReducer = ( draft, payload ) => {
    draft.timeLogMap[ payload.timeLogId ].pendingFinish = payload.pendingFinish;
    updatePegboardTimer( draft, draft.timeLogMap[ payload.timeLogId ]);
    updatePegboardStatics( draft );
};
    
const _setElapsedMeter = ( draft, payload ) => draft.elapsedMeters[ payload.jobId ] = Math.ceil( payload.duration / MINUTE );

const _setIncrementTimeEntered = ( draft, payload ) => {

    // we get payloads from the initial sum-for-day request (which sets the total and the start of day time) 
    // and from time log finishes (which increment the total).
    if ( payload.closed ) 
        draft.timeEntered += payload.duration; 
    else {
        draft.timeEntered = payload.payload; 
        draft.timeEnteredDayStart = payload.start; 
    }
};

const _setDeleteLocalTimeLog = ( draft ) => {
    if ( draft.localTimeLogId ) {
        delete draft.timeLogMap[ draft.localTimeLogId ];
        delete draft.localTimeLogId;
        updatePegboardStatics( draft );
    }
};

const reducers = {
    [ setTimeLogs ]:                setTimeLogsReducer,
    [ setDeleteTimeLog ]:           ( draft, payload ) => { delete draft.timeLogMap[ payload.timeLogId ]; updatePegboardStatics( draft ); },
    [ setAddTimeLog ]:              updateTimeLogReducer,
    [ setPatchTimeLog ]:            updateTimeLogReducer,
    [ setFinishTimeLog ]:           finishTimeLogReducer,
    [ setUpdatePegboardTimers ]:    updatePegboardTimers,
    [ setElapsedMeter ]:            _setElapsedMeter,
    [ adjustTimeLogTime ]:          adjustTimeLogTimeReducer,
    [ setLocalJobTime ]:            ( draft, payload ) => draft.jobTimeMap[ payload.jobId ] = payload.seconds,
    [ setIntervalId ]:              genericReducer,
    [ setIncrementTimeEntered ]:    _setIncrementTimeEntered,
    [ setAddLocalTimeLog ]:         ( draft, payload ) => draft.localTimeLogId = payload,
    [ setDeleteLocalTimeLog ]:      _setDeleteLocalTimeLog
};

/** selectors */
const selectPegboardItems = ( state ) => state.timelog._items;

const getCurrentJobTimerState = ( state, jobId ) => {

    // TODO change job map to include timeLogId
    const timeLogs = Object.values( state.timelog.timeLogMap || {}).filter( item => item.jobId === jobId );
    const currentTimerSecs = state.timelog.jobTimeMap[ jobId ] || 0;
    if ( timeLogs.length === 1 ) {
        const { timeLogId, pausedAt } = timeLogs[ 0 ];
        return {
            currentTimerSecs,
            timeLogId,
            pausedAt
        };
    }
    else if ( timeLogs.length === 0 ) {

        // legitimate when we've just entered a manual time and are sending directly from edit mode
        return { currentTimerSecs };
    }
    else {

        // hopefully just a relic
        console.error( 'multiple TLs found for job', jobId, timeLogs );
        return { currentTimerSecs: 0 };
    }
};

/**
 * return time entered today and the progress this represents.
 * 
 * If there's no time entered yet, color is grey until 30 min past start and then turns yellow, and stays yellow 
 * until one hour past start time and then it turns red.
 * 
 * If there's time entered and we're before or equal to start time, we're on target. 
 * 
 * If there's time entered and we're after start time, calculate completion % and use 0-84/85-99/100+ as 
 * ranges for badly behind/behind/on target
 * 
 * @param {*} state 
 * @returns {object} - { timeEnteredString, progress }
 */
const selectTimeEntered = state => {

    const { user: { userId }, calendar: { userMap: calendarUserMap }, calendarEvent: { userMap }, timelog: { timeEntered } } = state;
    if ( !calendarUserMap[ userId ])
        return;

    const { businessHourDays } = calendarUserMap[ userId ];

    const timeEnteredString = numMinutesToString( Math.ceil( timeEntered / MINUTE ) );
    let progress = '';

    // we can only determine progress if we have the business hours
    // find hours for current day - recall our days have Monday === 0
    const now = dayjs();
    const day = ( now.day() + 6 ) % 7;

    if ( businessHourDays[ day ]) {

        // note that businessHours is a list of objects with startTime & endTime
        const businessHours = [ ...businessHourDays[ day ] ].sort( ( a, b ) => a.startTime - b.startTime );

        // if no time has been entered we don't need to calculate anything but we need start time
        // as the user gets some grace period before we start telling them they're behind.
        // Convert to "milliseconds since midnight"
        const nowMs = now.diff( now.startOf( 'day' ) );
        const workStartMs =  businessHours[ 0 ].startTime * MINUTE;
        const workEndMs = businessHours[ businessHours.length - 1 ].endTime * MINUTE;
        const { personalTimeToday } = userMap[ userId ] || { personalTimeToday: 0 };

        if ( timeEntered === 0 ) {
            // for the case where he Personal/Internal meetings first thing in the day, the TT should stay grey until 1 hour after they've passed.
            const adjustedTime = workStartMs + personalTimeToday;
            // if no times have been entered, they get 30 min after start time til it
            // turns yellow then 30 more til red
            progress = nowMs > ( adjustedTime + 1 * HOUR )
                ? timeEnteredProgress.BADLY_BEHIND
                : nowMs > ( adjustedTime + 30 * MINUTE )
                    ? timeEnteredProgress.BEHIND
                    : '';
        }
        else {

            // note that personalTimeToday only includes events that have started before now

            // there may be gaps in the user's business hours; these will be treated the same as personal
            // events, ie if the gap has started, it will be removed from time passed.
            let businessHourGapTime = 0;
            if ( businessHours.length > 1 ) {

                // get sum of all started gaps
                getTimeSeriesGaps( businessHours )
                    .filter( gap => gap.startTime * MINUTE < nowMs )
                    .forEach( gap => businessHourGapTime += gap.endTime - gap.startTime );
            }

            // business hour times are in minutes
            businessHourGapTime *= MINUTE;

            const effectiveNowMs = nowMs > workEndMs ? workEndMs : nowMs;
            const timePassed = effectiveNowMs - workStartMs - personalTimeToday - businessHourGapTime;
            // if the effective time of day is before the start of working hours, we're automatically on target,
            // otherwise calculate completion %
            if ( timePassed <= 0 )
                progress = timeEnteredProgress.ON_TARGET;
            else {
                const completionRatio = timeEntered / timePassed;
                progress = completionRatio <= 0.85
                    ? timeEnteredProgress.BADLY_BEHIND
                    : completionRatio < 1
                        ? timeEnteredProgress.BEHIND
                        : timeEnteredProgress.ON_TARGET;
            }          
        }
    }
    
    return { timeEnteredString, progress };
};

export {
    selectPegboardItems,
    pausePegboardTimer,
    removeJobFromPegboard,
    resumePegboardTimer,
    getCurrentJobTimerState,
    dragJobToPegboard,
    finishCurrentTimeLog,
    saveTimerValue,
    selectTimeEntered,
    checkTimeEnteredDay
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
