import React from "react";

import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { AssetDialogMode } from "../../../constants.js";
import { closeButton, header, headerName, headerSection, row, ellipsis, separator, headerSelect, liveSearch, alignCenter } from '../../../scss/AssetForm.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { LicoFab, LicoIcon, LicoLiveSearch, NumberDropdown } from "../../common/index.js";
import { deleteAssetFromJob, filterStringChanged } from "../reducer.js";


const HeaderSection = ({ selectedAsset, onClose, filterStringChanged, filterString, deleteAssetFromJob, jobId }) => {
    const getAnswerForField = fieldName  => {
        const field = selectedAsset?.details.find( ( detail ) => detail.question.toLowerCase() === fieldName );
        return field 
            ? typeof field.answer === 'string' ? field.answer : field.answer.name
            : '';
    };

    const closeAndDeleteAssetForm = () => {
        deleteAssetFromJob({ assetId: selectedAsset.assetId, jobId: jobId });
        onClose();
    };
    const mode = selectedAsset.mode;

    return (
        <div className={header}>
            <div className={row}>
                <div className={headerSection}>
                    <div className={ellipsis}>{ mode !== AssetDialogMode.ADD ?  getAnswerForField( "type" ) : __( "Add new Asset" )}</div>
                    {mode !== AssetDialogMode.ADD && <span className={separator}>|</span>}
                    {mode !== AssetDialogMode.ADD &&  <div className={`${headerName} ${ellipsis}`}>{selectedAsset.assetName}</div>}
                </div>
                { mode !== AssetDialogMode.ADD && <div className={headerSelect}>
                    <LicoLiveSearch
                        placeholder={__( "Filter" )}
                        value={filterString}
                        autoFocus
                        onChange={e => filterStringChanged( e.target.value )}
                        className={liveSearch}
                        id="assetForm"
                    />
                </div>}
            </div>
            <div className={alignCenter}>
                { mode !== AssetDialogMode.ADD && <div className={headerSelect}>
                    <NumberDropdown
                        value={{ id: 'status', name: getAnswerForField( "status" ) }}
                        options={[ { id: "active", label: __( "Active" ) }, { id: "inactive", label: __( "Inactive" ) } ]}
                        inputClassName='slim rounded'
                        minimumwidth={100}
                        disabled={true} // disabled until apis are available to update asset.
                    ></NumberDropdown>
                </div> }
                <LicoFab
                    onClick={ mode !== AssetDialogMode.ADD ? closeAndDeleteAssetForm : onClose }
                    licoVariant="small"
                    className={closeButton}>
                    <LicoIcon icon={mode === AssetDialogMode.ADD ? "close" : "delete"} />
                </LicoFab>
            </div>
        </div>
    );
};

HeaderSection.propTypes = {
    selectedAsset:       PropTypes.object.isRequired,
    onClose:             PropTypes.func.isRequired,
    filterStringChanged: PropTypes.func,
    filterString:        PropTypes.string,
    deleteAssetFromJob:    PropTypes.func.isRequired,
    jobId:               PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    selectedAsset: state.jobcard.selectedAsset,
    filterString:  state.jobcard.selectedAsset?.filterString || '',
    jobId:         state.jobcard.currentJobCardId
});

const mapDispatchToProps = {
    deleteAssetFromJob,
    filterStringChanged
};

export default connect( mapStateToProps, mapDispatchToProps )( HeaderSection );
