/*********************************************************************************************************************
 * @file Jobcard component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React from 'react';

import { UNMAPPED_NAME, integrationNames } from '@licoriceio/constants';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { nameDataFormat } from '../../constants.js';
import { selectNameData } from '../../redux/selectors/index.js';
import * as scss from '../../scss/BusyCard.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { closeEitherCard } from '../calendar/shared.js';
import { LicoTextArea, LicoTextField, LicoSelect, THIS_FIELD_IS_REQUIRED } from '../common/index.js';
import JobCardTopPanel from '../jobcard/JobCardTopPanel.jsx';

import { localCalendarEventSave } from './reducer.js';

const { busyCard, form } = scss;


const BusyCard = ({
    calendarEvent,
    closeEitherCard,
    personalCardOptions,
    internalCardOptions
}) => {

    const {
        calendarEventId,
        userId,
        licoriceNameId,
        title,
        description,
        isPrivate
    } = calendarEvent;

    const slicePackage = {
        slice:              'calendarEvent',
        id:                 calendarEventId,
        userId,
        localSaveThunk:     localCalendarEventSave,
        keySaveAction:      { Escape: closeEitherCard },
        qualifyingFields:   [ 'licoriceNameId' ]
    };
    
    const typeOptions = isPrivate ? personalCardOptions : internalCardOptions;

    return <div className={busyCard}>
        <JobCardTopPanel calendarEvent={calendarEvent} notes={[]} completed={false} />
        <div className={form}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <LicoSelect
                        name="licoriceNameId"
                        label={__( "Type" )}
                        options={typeOptions}
                        autoFocus
                        externalValue={licoriceNameId}
                        slicePackage={slicePackage}
                        validation={Yup.string().required( THIS_FIELD_IS_REQUIRED )}
                        displayOnlyLabels={[ UNMAPPED_NAME ]}
                    />
                </Grid>

                <Grid item xs={8}>
                    <LicoTextField
                        name="title"
                        label={__( "Title" )}
                        placeholder={__( "Type a short title" )}
                        value={title}
                        slicePackage={slicePackage}
                        disabled={!licoriceNameId}

                    />
                </Grid>
                <Grid item xs={12}>
                    <LicoTextArea
                        label={__( "Description" )}
                        placeholder={__( "Type a detailed description" )}
                        name="description"
                        value={description}
                        minRows={14}
                        slicePackage={slicePackage}
                        disabled={!licoriceNameId}
                    />
                </Grid>
            </Grid>


        </div>
    </div>;
};

BusyCard.propTypes = {
    calendarEvent:              PropTypes.any.isRequired,
    closeEitherCard:            PropTypes.func.isRequired,
    personalCardOptions:        PropTypes.array.isRequired,
    internalCardOptions:        PropTypes.array.isRequired
};

const prependBlankOption = options => [
    {
        id:     "",
        label:  ""
    }
].concat( options );

const mapStateToProps = state => ({
    personalCardOptions:    prependBlankOption( selectNameData( state, integrationNames.PERSONAL, nameDataFormat.OPTIONS ) ),
    internalCardOptions:    prependBlankOption( selectNameData( state, integrationNames.INTERNAL, nameDataFormat.OPTIONS ) )
});

export default connect( mapStateToProps, { closeEitherCard })( BusyCard );
export { BusyCard };
