/** ******************************************************************************************************************
 * @file Paragraph lifted from react-common.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 29-May-2021
 *********************************************************************************************************************/
import React from 'react';

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const P = ({ className = '', small, children, ...rest }) =>
    <Typography gutterBottom variant= {small ? "body2" : "body1"} classes = {{ body1: className, body2: className }} {...rest}>{children}</Typography>;

P.propTypes = {
    className: PropTypes.string,
    small:     PropTypes.bool,
    children:  PropTypes.node
};
