/*********************************************************************************************************************
 * @file BusyCard reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 13-Jan-2021
 *********************************************************************************************************************/

import { MINUTE, DELETE, integrationNames } from '@licoriceio/constants';
import { pick } from '@licoriceio/utils';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { uri, DEFAULT_APPOINTMENT_DURATION } from '../../constants.js';
import { setCalendarEvents, setCurrentCalendarEventId, setCalendarEventDetails, setAddUserCalendar, setPatchCalendarEvent,
    setDeleteCalendarEvent, setConnectorCalendarEvent, setUpdateCalendarEventStatics, setDeleteJob, setTypeNames
} from '../../redux/actions/index.js';
import { setSomeTypeNames } from '../../redux/reducers/names.js';
import { ezRedux, genericReducer, genericRequest, makeMapById, sliceStoredFields } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { getUserSession, saveToUserSession } from '../../utils/local-storage.js';
import { findOutOfHoursEvents } from '../calendar/shared.js';
import { setUpdateAppointmentStaticsThunk, setExistingJobThunk, setExistingBusyCardThunk } from '../calendar/sharedThunks.js';

const deleteCalendarEventService = abstractedCreateAuthRequest( DELETE, uri.SINGLE_CALENDAR_EVENT );

const deleteCalendarEventRequest = calendarEvent =>
    genericRequest({}, deleteCalendarEventService, [ [ setDeleteCalendarEvent, calendarEvent ], [ setExistingBusyCardThunk, null ] ], [ calendarEvent.calendarEventId ]);

/**
 * @typedef {object} BusyCardState
 * @property {string|null}  currentCalendarEventId
 * @property {object}       userMap
 * @property {object}       fieldError
 * @property {object}       busyCardDetails
 */

/**
  * @type {Readonly<BusyCardState>}
  */
const initialState = Object.freeze({
    currentCalendarEventId:     null,
    userMap:                    {},
    fieldError:                 {},
    busyCardDetails:            {}
});

const convertCalendarEventToFCEvent = ( draft, event ) => {

    const now = String( new Date() );
    const { calendarEventId, licoriceNameId, title, description, startDate, endDate, userId, isPrivate } = event;

    return {
        start:          startDate,
        end:            endDate,
        allDay:         event.endDate === null,
        title,
        extendedProps: {
            now,
            type:                       isPrivate 
                ? draft.personalcardtype?.idToName[ licoriceNameId ] 
                : draft.internalcardtype?.idToName[ licoriceNameId ],
            typeId:                     licoriceNameId,
            calendarEventId,
            durationEditable:           true,
            userId,
            description,
            isPrivate
        }
    };
};

const localCalendarEventSave = ({ item }) => async ( dispatch ) => {

    // we might have changed the title if it was blank so update with the saved version
    dispatch( setPatchCalendarEvent( item ) );
    
    dispatch( setUpdateCalendarEventStatics( item.userId ) );
};

const addCalendarEventThunk = isPrivate => async ( dispatch, getState ) => {

    // we always open a job card first so copy the event details from the job card
    const {
        jobcard: { newJobDetails },
        calendarEvent: { currentCalendarEventId, userMap, busyCardDetails },
        user: { preferences: { defaultAppointmentDuration = DEFAULT_APPOINTMENT_DURATION } }
    } = getState();

    let newCalendarEvent;

    if ( currentCalendarEventId ) {


        // we've changed the type of calendar event, ie between personal and internal.
        // Just patch the existing new event
        newCalendarEvent = { ...userMap[ busyCardDetails.userId ].calendarEventMap[ currentCalendarEventId ], isPrivate };
    }
    else {

        // we had the job card open so delete job

        const { userId, date, allDay, jobId } = newJobDetails;

        dispatch( setDeleteJob({ jobId: jobId }) );
        dispatch( setExistingJobThunk( null ) );
        dispatch( setUpdateAppointmentStaticsThunk( userId ) );

        newCalendarEvent = {
            calendarEventId:    uuidv4(),
            userId:             userId,
            newItemFlag:        true,
            startDate:          date,
            endDate:            allDay ? null : new Date( date.getTime() + defaultAppointmentDuration * MINUTE ).toISOString(),
            isPrivate,
            licoriceNameId:     "",
            description:        "",
            title:              ""
        };

    }

    // put the empty calendarEvent in state
    dispatch( setPatchCalendarEvent( newCalendarEvent ) );
    dispatch( setUpdateCalendarEventStatics( newCalendarEvent.userId ) );

    // open the card and remember how the card was opened
    dispatch( setCalendarEventDetails({ currentCalendarEventId: newCalendarEvent.calendarEventId, busyCardDetails: newJobDetails }) );
    saveToUserSession({ currentCalendarEventId: newCalendarEvent.calendarEventId });

};

const buildCalendarEventList = ( draft, singleUserId ) => {

    const { altPressed, dragEventId, userMap } = draft;

    // if we don't get a userId, build events for all users
    const userIds = singleUserId ? [ singleUserId ] : Object.keys( userMap );

    for ( const userId of userIds ) {

        const calendar = userMap[ userId ];
        const { calendarEventMap } = calendar;

        calendar._calendarEvents = Object.values( calendarEventMap ?? {})
            .filter( e => altPressed || e.calendarEventId !== dragEventId )
            .map( e => convertCalendarEventToFCEvent( draft, e ) );

        // we want to keep track of calendar events occuring today, so that the time
        // for those events can be removed from the Time Entered Today calc.
        // We use the static list since it's already a list, and we aren't trying to do a pro-rata calc
        // for events in progress; if the start time has passed, that whole event is included.
    
        // easier to compare times as a string since that's what's already in redux
        const startToday = dayjs().startOf( 'day' ).toISOString();
        const now = dayjs().toISOString();
        calendar.personalTimeToday = calendar._calendarEvents
            .filter( e => e.start > startToday && e.start <= now )
            .reduce( ( acc, cur ) => acc + dayjs( cur.end ).diff( dayjs( cur.start ) ), 0 );
    }

};

const updateCalendarEventStatics = ( draft, userId  ) => {

    const userIds = userId ? [ userId ] : Object.keys( draft.userMap );
    userIds.forEach( id => {
        buildCalendarEventList( draft, id );
        findOutOfHoursEvents( draft, id, draft.userMap[ id ].calendarEventMap );
    });
};

/** reducers */

const busyCardCardIdReducer = ( draft, calendarEventId ) => {
    draft.currentCalendarEventId = calendarEventId;
    saveToUserSession({ currentCalendarEventId: calendarEventId });
};

const addUserCalendarReducer = ( draft, payload ) => {
    draft.userMap[ payload.user.userId ] = {
        calendarEventMap:       {},
        _calendarEvents:        [],
        personalTimeToday:      0
    };
};
const addCalendarEventsReducer = ( draft, payload ) => {
    Object.assign( draft.userMap[ payload.userId ].calendarEventMap, payload.payload
        .map( ce => pick( ce, sliceStoredFields.calendarEvent ) )
        .reduce( makeMapById( 'calendarEventId' ), {}) );
    updateCalendarEventStatics( draft, payload.userId );

    // restore open event from session
    const session = getUserSession();
    if ( session.currentCalendarEventId ) {
        if ( payload.payload.find( event => event.calendarEventId === session.currentCalendarEventId ) ) {
            draft.currentCalendarEventId = session.currentCalendarEventId;
            draft.busyCardDetails = { userId: payload.userId };
        }
    }
};

const findUserForCalendarEvent = ( draft, calendarEventId ) => Object.keys( draft.userMap ).find( userId => !!draft.userMap[ userId ].calendarEventMap[ calendarEventId ]);
const patchCalendarEventReducer = ( draft, payload ) => {

    // For updates from CW, all we have is the calendarEventid, which could be in any user's calendar
    const userId = payload.userId || findUserForCalendarEvent( draft, payload.calendarEventId );

    if ( userId && userId in draft.userMap ) {
        draft.userMap[ userId ].calendarEventMap[ payload.calendarEventId ] = {
            ...draft.userMap[ userId ].calendarEventMap[ payload.calendarEventId ], ...payload
        };
        if ( !payload.newItemFlag )
            updateCalendarEventStatics( draft, userId );
    }
};

const deleteCalendarEventReducer = ( draft, payload ) => {

    // as for patchCalendarEventReducer
    const userId = payload.userId || findUserForCalendarEvent( draft, payload.calendarEventId );
    if ( userId && userId in draft.userMap ) {
        delete draft.userMap[ userId ].calendarEventMap[ payload.calendarEventId ];
        updateCalendarEventStatics( draft, userId );
    }
};

const _setTypeNames = ( draft, payload ) => {
    setSomeTypeNames( integrationNames.PERSONAL, integrationNames.INTERNAL )( draft, payload );
    buildCalendarEventList( draft );
};

/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setCurrentCalendarEventId ]:              busyCardCardIdReducer,
    [ setCalendarEventDetails ]:                genericReducer,
    [ setAddUserCalendar ]:                     addUserCalendarReducer,
    [ setCalendarEvents ]:                      addCalendarEventsReducer,
    [ setPatchCalendarEvent ]:                  patchCalendarEventReducer,
    [ setConnectorCalendarEvent ]:              patchCalendarEventReducer,
    [ setDeleteCalendarEvent ]:                 deleteCalendarEventReducer,
    [ setUpdateCalendarEventStatics ]:          updateCalendarEventStatics,
    [ setTypeNames ]:                           _setTypeNames
};

/**
 * action creators and async functions are imported by the component and added to mapDispatchToProps,
 * and can then be called by the component's handlers.
 */
export {
    addCalendarEventThunk,
    deleteCalendarEventRequest,
    localCalendarEventSave
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState, 'calendarEvent' );
