/*********************************************************************************************************************
 * @file UserProfile Modal component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Mar-2023
 *********************************************************************************************************************/
import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { discardUserSettingChanges, setEngineerSettings } from '../../redux/actions';
import { userProfileModal } from '../../scss/UserProfileModal.module.scss';
import Backdrop from '../layouts/Backdrop.jsx';
import UserProfileContent from '../settings/user/UserProfileContent';

const UserProfileModal = ({
    foundChanges,
    setEngineerSettings,
    discardUserSettingChanges
}) => (
    <Backdrop onClose={() => {
        console.log( 'close dialog' );
        if ( foundChanges )
            setEngineerSettings({ confirmUserProfileDialogOpen: true });
        else
            discardUserSettingChanges();
    }}>
        <div className={userProfileModal}>
            <UserProfileContent />
        </div>
    </Backdrop>
);

UserProfileModal.propTypes = {
    foundChanges:                   PropTypes.bool,
    setEngineerSettings:            PropTypes.func.isRequired,
    discardUserSettingChanges:      PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    foundChanges:   state.userProfile.foundChanges
});

const mapDispatchToProps = {
    setEngineerSettings,
    discardUserSettingChanges
};

export default connect( mapStateToProps, mapDispatchToProps )( UserProfileModal );
export { UserProfileModal };
