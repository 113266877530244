
import React from 'react';

import { isString } from '@licoriceio/utils';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';

import logo32white from '../../images/logo32white.png';
import { selectCacheUser } from '../../redux/selectors/index.js';
import { avatarRoot, avatarImage, avatarIcon, avatarText } from '../../scss/LicoAvatar.module.scss';
import { circularDiv } from '../../scss/Settings.module.scss';
import { getAvatarColors, getAvatarFromUserOrCompany } from '../../utils/common-types.js';
import { LicoIcon } from '../common/images/LicoIcon.jsx';

const isTextAvatar = avatar => isString( avatar ) ? /^(?:white|black)/.test( avatar ) : !!avatar?.color;

const LicoAvatar = ({ avatar, userAvatar, className = '', size = "medium", icon, gravatarEmail, cachedGravatarEmail }) => {

    let classNames = `${avatarRoot} ${size} ${className}`;

    if ( icon ?? avatar?.icon )
    {

        const avatarColorClass = avatar?.color ? "avatar-" + avatar.color : "";
        return <Avatar className={`${avatarImage} ${classNames} ${avatarColorClass} avatar-icon`}>
            <span><LicoIcon className={avatarIcon} icon={icon ?? avatar.icon} /></span>
        </Avatar>;
    }
    else if ( gravatarEmail || cachedGravatarEmail ) {
        const gravatarSize = {
            small:      24,
            medium:     40
        };
        return <Gravatar email={gravatarEmail || cachedGravatarEmail} size={gravatarSize[ size ] || 24} className={`${circularDiv} ${classNames}`} />;
    }

    if ( !avatar && userAvatar )
        avatar = userAvatar;

    if ( isTextAvatar( avatar ) )
    {
        if ( isString( avatar ) )
            avatar = { color: avatar };

        const avatarClass = 'avatar-' + getAvatarColors( avatar, ( avatar.firstLetter || 'x' ) + ( avatar.lastLetter || 'x' ) );

        classNames = `${classNames} ${avatarClass} avatar-icon`;

        return <Avatar className={classNames}>
            <span className={`${avatarText} avatar-text`}>{avatar.firstLetter}{avatar.lastLetter}</span>
        </Avatar>;
    }

    return <Avatar src={logo32white} className={`${avatarImage} ${classNames}`}/>;
};

LicoAvatar.propTypes = {
    avatar:                     PropTypes.object,
    className:                  PropTypes.string,
    size:                       PropTypes.string,
    icon:                       PropTypes.string,
    userAvatar:                 PropTypes.any,
    gravatarEmail:              PropTypes.string,
    cachedGravatarEmail:        PropTypes.string
};

const mapStateToProps = ( state, props ) => {

    const cachedUser = selectCacheUser( state, props.userId );
    return {
        userAvatar:             cachedUser ? getAvatarFromUserOrCompany( cachedUser ) : null,
        cachedGravatarEmail:    cachedUser?.preferences.gravatarEmail
    };
};

export default connect( mapStateToProps )( LicoAvatar );

export { LicoAvatar };
