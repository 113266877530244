import { LinkedList } from "@licoriceio/utils";
import dayjs from "dayjs";

import { getLoginUserId } from "./misc.js";

const sessionKey = {
    CALENDARS:              'calendars',
    JOB_CARD_ID:            'jobCardId',
    JOB_CARD_CHAT_TAB:      'jobCardChatTab',
    CALENDAR_EVENT_ID:      'calendarEventId'
};

const userSessionKey = userId => `user_session_${userId}`;

const _socketMessageList = new LinkedList();
const MAX_SOCKET_MESSAGES = 30;
// socket messages are purely stored for debugging so aren't linked to a user
const SOCKET_LOG_KEY = 'socketMessages';

/**
 * Return the saved session for the login user, or null if no session found
 * @returns {object|null} session
 */
const getUserSession = () => {
    const localStorageForUser = localStorage?.getItem( userSessionKey( getLoginUserId() ) );
    return localStorageForUser ? JSON.parse( localStorageForUser ) : null;
};

const saveAuth = ({ rememberSession, token, userInitState }) => {
    if ( rememberSession ) 
        localStorage.setItem( 'auth', JSON.stringify({ token, rememberSession, userInitState }) );
    
};

const getAuth = () => {
    const auth = localStorage?.getItem( 'auth' );
    return auth ? JSON.parse( auth ) : {
        token:           '',
        rememberSession: false,
        userInitState:   {}
    };
};

const removeAuth = () => {
    localStorage.removeItem( 'auth' );
};

/**
 * Add or replace a top level key in the user's saved session
 * @param {object} data - top level key to be replaced/added
 */
const saveToUserSession = data => {

    const userId = getLoginUserId();
    if ( !userId ) return;

    const session = getUserSession() || {};
    Object.assign( session, data );

    localStorage.setItem( userSessionKey( userId ), JSON.stringify( session ) );
};

/**
 * Clear the user's saved session, ie replace with an empty session
 */
const clearUserSession = () => {

    const userId = getLoginUserId();
    if ( !userId ) return;

    localStorage.setItem( userSessionKey( userId ), JSON.stringify({}) );
};


const addCalendarToSession = user => {

    const session = getUserSession();
    const calendars = session?.calendars || [];
    
    if ( calendars.find( calendar => calendar.user.userId === user.userId ) ) 
    {
        console.error( 'Session already contains calender', user );
        return;
    }

    calendars.push({ user: { userId: user.userId } });
    
    saveToUserSession({ calendars });
};

const logSocketMessage = message => {
    message.time = dayjs().toISOString();
    if ( Array.isArray( message.args ) && message.args.length === 1 )
        message.args = message.args[ 0 ];
    _socketMessageList.push( message );
    if ( _socketMessageList.size > MAX_SOCKET_MESSAGES )
        _socketMessageList.shift();
    localStorage.setItem( SOCKET_LOG_KEY, JSON.stringify( _socketMessageList.toArray() ) );
};

export {
    getUserSession,
    saveToUserSession,
    clearUserSession,
    sessionKey,
    addCalendarToSession,
    saveAuth,
    getAuth,
    removeAuth,
    logSocketMessage
};
