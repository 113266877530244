import { uri } from '../../constants.js';

/**
 * @param {ServiceError|SingleLineError|FatalError|undefined} item
 * @return {boolean}
 */
const isSingleLineError = item => item?.type === "singleLine";

/**
 * @param {ServiceError|SingleLineError|FatalError|undefined} item
 * @return {boolean}
 */
const isFatalError = item => item?.type === 'fatal';

/**
 * @param {string} uri
 * @return {function(BaseState): (string|undefined)}
 */
function createSingleLineErrorSelector( uri ) {
    return function( state ) {
        const error = state.error.uriError[ uri ];

        return isSingleLineError( error ) ? error.errors : undefined;
    };
}

const selectAcceptInviteError = createSingleLineErrorSelector( uri.ACCEPT_INVITATION );

/**
 * @param {BaseState} state
 * @return {string[]|undefined}
 */
function selectAllFatalErrors( state )
{
    const foundErrors = Object.values( state.error.uriError ).map( v => isFatalError( v ) && v.errors ).filter( Boolean );

    return foundErrors.length > 0 ? foundErrors : void 0;
}

export {
    createSingleLineErrorSelector,
    selectAcceptInviteError,
    selectAllFatalErrors
};
