import React from 'react';

import { numSecondsToString } from '@licoriceio/utils';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { tinyTimer, text, overMax, clickDisabled } from '../../scss/TinyTimer.module.scss';
import {  P } from '../common/index.js';

const TinyTimer = ({ numSeconds, maxSeconds, timerRunning, onClick, dataName, disabled, tooltip }) => {

    const exceededMaxSeconds = maxSeconds && numSeconds > maxSeconds;
    const displaySeconds = exceededMaxSeconds ? maxSeconds : numSeconds;

    let textClass = `${text}`;
    if ( exceededMaxSeconds && timerRunning )
        textClass += ` ${overMax}`;

    return <Tooltip title={tooltip}>
        <div className={`${tinyTimer} ${disabled && tooltip ? clickDisabled : ''}`} onClick={disabled ? null : onClick} data-ux={dataName} >
            <P gutterBottom={false} className={textClass} component="span">{numSecondsToString( displaySeconds )}</P>
        </div>
    </Tooltip>;
};

TinyTimer.propTypes = {
    numSeconds:     PropTypes.number.isRequired,
    maxSeconds:     PropTypes.number,
    timerRunning:   PropTypes.bool,
    onClick:        PropTypes.func.isRequired,
    dataName:       PropTypes.string,
    disabled:       PropTypes.bool.isRequired,
    tooltip:        PropTypes.string
};

export default TinyTimer;
export { TinyTimer };
