import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    licoStaticLink: ({
        fontFamily: "gotham-medium, sans-serif",
        color:      "var(--black-1)"
    })
});

/**
 * These are non-react-router links, ie. standard <a> tags for linking to external content
 * @param props
 */
export const LicoStaticLink = props => {
    const {
        to,
        className = '',
        target = "_self",
        children,
        ...rest
    } = props;

    const classes = useStyles( props );

    return (
        <a href={to} target ={target} className={`${classes.licoStaticLink} ${className}`} {...rest}>
            <Typography variant="caption" className={`${classes.licoStaticLink} ${className}`}>
                {children}
            </Typography >
        </a>
    );
};

LicoStaticLink.propTypes = {
    to:        PropTypes.string.isRequired,
    className: PropTypes.string,
    target:    PropTypes.string,
    children:  PropTypes.node
};
