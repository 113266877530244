const buildEnv = {
    HOSTNAME:         '4e5bad21-a0d8-4660-b5f1-b70e027db992-gfxbx',
    SERVER_PORT:      undefined,
    LICORICE_VERSION: '2.4.0-rc.1',
    BUILD_VERSION:    '50.21813fa',
    NODE_ENV:         'development',
    DEBUG_NAMES:      '',
    BUILD_TIME_LOCAL: '2024-07-23T02:22:44+00:00',
    BUILD_TIME_UTC:   '2024-07-23T02:22:44.952Z'
};

export { buildEnv };
