import React from 'react';

import PropTypes from 'prop-types';

import { __ } from '../../utils/i18n.jsx';

import GenericDialog, { DialogButtonColors } from './GenericDialog.jsx';

const NavigationDialog = ({ isOpen, saveAction, saveDisabled, discardAction, cancelAction, extraContent }) => (
    <GenericDialog
        isOpen={isOpen}
        title={__( "Unsaved Changes" )}
        message={ __( "You have unsaved changes. Would you like to save or discard them? (Or cancel the navigation.)" )}
        buttons={[
            {
                label:          __( "Save" ),
                action:         saveDisabled ? null : saveAction,
                color:          DialogButtonColors.safety
            },
            {
                label:          __( "Discard" ),
                action:         discardAction,
                color:          DialogButtonColors.danger
            },
            {
                label:          __( "Cancel" ),
                action:         cancelAction,
                color:          DialogButtonColors.cancel
            }
        ]}
        extraContent={extraContent}
    /> );

NavigationDialog.propTypes = {
    isOpen:         PropTypes.bool.isRequired,
    saveAction:     PropTypes.func.isRequired,
    saveDisabled:   PropTypes.bool,
    discardAction:  PropTypes.func.isRequired,
    cancelAction:   PropTypes.func.isRequired,
    extraContent:   PropTypes.any
};

export default NavigationDialog;
export { NavigationDialog };
