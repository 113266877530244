/********************************************************************************************************************
 * @file Index file for the redux selectors.
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { integrationTypes, integrationNames } from '@licoriceio/constants';

export const selectSystemReady = state => ( state.meta.integrationType === integrationTypes.STANDALONE ) || !!state.integration.provider?.integrated;

// define the "home" slice for each type of name data; some types appear in more than one slice (eg jobStatus)
// because reducers can only see their own slice but this is the slice that serves the data to components, thunks, etc,
// which start with a top level state object.
const nameTypeSlice = {
    [ integrationNames.PERSONAL ]:      'calendarEvent',
    [ integrationNames.INTERNAL ]:      'calendarEvent',
    [ integrationNames.JOB_STATUS ]:    'jobcard',
    [ integrationNames.BILLING_TYPE ]:  'jobcard'
};

export const selectNameData = ( state, nameType, dataName ) => {
    const slice = nameTypeSlice?.[ nameType ];
    if ( !slice )
        throw new Error( `No slice for name type ${nameType}` );
    return state?.[ slice ]?.[ nameType ]?.[ dataName ];
};

export const selectNameDataReady = state => {
    const nameTypeSlices = Object.entries( nameTypeSlice );
    const readyCount = nameTypeSlices.filter( ([ nameType, slice ]) => state[ slice ][ nameType ]?.nameToId ).length;
    return readyCount === nameTypeSlices.length;
};

export * from './cache.js';
export * from './calendar.js';
export * from './calendarEvent.js';
export * from './error.js';
export * from './isAuth.js';
export * from './job.js';
export * from './jobcard.js';
export * from './loading.js';
export * from './meta.js';
export * from './notifications.js';
export * from './storeSelectors.js';
export * from './user.js';
