
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECS_IN_MINUTE = 60;

const MAX_DURATION = 10 * HOUR - MINUTE;

/**
 * Convert milliseconds to normal time units.
 *
 * @param {number} millis
 * @return {{secs: number, mins: number, hrs: number, days: number}}
 */
function duration( millis )
{
    const secs  = Math.floor( millis / SECOND );
    const hours = Math.floor( secs / ( SECS_IN_MINUTE * MINUTES_IN_HOUR ) );

    return {
        secs: secs % SECS_IN_MINUTE | 0,
        mins: Math.floor( secs / SECS_IN_MINUTE ) % MINUTES_IN_HOUR | 0,
        hrs:  hours % HOURS_IN_DAY | 0,
        days: Math.floor( hours / HOURS_IN_DAY ) | 0
    };
}

/**
 * Convert millisecond duration to readable string.
 *
 * @param {number} millis
 * @param {boolean} [short=false]
 * @return {string}
 */
function durationString( millis, short = false )
{
    if ( !millis ) return 'instant';

    let { secs, mins: minutes, hrs: hours, days } = duration( millis );

    const shortNames = {
        day:    'day',
        hour:   'hour',
        minute: 'min',
        second: 'sec'
    };
    const longNames = {
        day:    'day',
        hour:   'hour',
        minute: 'minute',
        second: 'second'
    };
    const words = short ? shortNames : longNames;
    const plural = ( w, n ) => w + ( n !== 1 ? 's' : '' );
    const parts  = [];

    if ( days ) parts.push( `${days} ${plural( words.day, days )}` );
    hours %= HOURS_IN_DAY;
    if ( hours ) parts.push( `${hours} ${plural( words.hour, hours )}` );
    minutes %= MINUTES_IN_HOUR;
    if ( minutes ) parts.push( `${minutes} ${plural( words.minute, minutes )}` );
    secs %= SECS_IN_MINUTE;
    if ( secs ) parts.push( `${secs} ${plural( words.second, secs )}` );

    const p = parts.pop();

    if ( parts.length === 0 ) return p || 'instant';

    return parts.join( ', ' ) + ', and ' + p;
}

/**
 * Snap a duration to the next highest multiple of the snap interval
 * @param {number} duration - milliseconds
 * @param {number} snapIntervalMinutes - minutes
 * @param {number} [maxDuration] - millis
 * @returns {number}
 */
function snapDuration( duration, snapIntervalMinutes, maxDuration = MAX_DURATION )
{

    const intervalMillis = snapIntervalMinutes * MINUTE;

    if ( duration > 0 && duration <= intervalMillis )
        duration = intervalMillis;
    else if ( duration >= ( maxDuration - intervalMillis ) )

        // floor used so duration stays < max
        duration = Math.floor( maxDuration / intervalMillis ) * intervalMillis;
    else

        // snap up (or remain 0)
        duration = Math.ceil( duration / intervalMillis ) * intervalMillis;

    return duration;
}

export {
    duration,
    durationString,
    snapDuration
};
