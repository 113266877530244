import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { root } from '../../scss/CancelPage.module.scss';
import { __ } from '../../utils/i18n.jsx';

import TopRow from './TopRow.jsx';

const CancelPage = ({ job }) => (
    <div className={root}>
        <TopRow job={job} />
        <div>
            {__( "You have cancelled the scheduling of this appointment. You can restart scheduling by clicking on the link in the email you received." )}
        </div>
    </div>
);

CancelPage.propTypes = {
    job: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ job: state.scheduling.job });

export default connect( mapStateToProps )( CancelPage );
export { CancelPage };
