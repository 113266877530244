import React from 'react';

import { LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';

export const LicoProgress = ({ progress }) => <LinearProgress value = {progress * 100 } variant = "determinate" />;

LicoProgress.propTypes = {
    progress: PropTypes.number
};
