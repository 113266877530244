/*********************************************************************************************************************
 * @file Worklog item component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React from 'react';

import { AppointmentState } from '@licoriceio/constants';
import { humanDuration } from '@licoriceio/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday.js';
import isTomorrow from 'dayjs/plugin/isTomorrow.js';
import isYesterday from 'dayjs/plugin/isYesterday.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { 
    UX_JOBCARD_APPOINTMENT_LIST, UX_JOBCARD_APPOINTMENT_RESCHEDULED, UX_JOBCARD_APPOINTMENT_CANCELLED, UX_JOBCARD_APPOINTMENT_DONE,
    UX_JOBCARD_APPOINTMENT_ACTIVE
} from '../../ux-constants.js';
import { selectJobAppointments } from '../../redux/selectors/index.js';
import { appointmentList, appointment, bold, done, cancelled } from '../../scss/AppointmentList.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { findNextActiveAppointment, H3  } from '../common/index.js';
import LicoAvatar from '../common/LicoAvatar.jsx';

dayjs.extend( duration );
dayjs.extend( isToday );
dayjs.extend( isTomorrow );
dayjs.extend( isYesterday );
dayjs.extend( relativeTime );

/**
 * Return a nice representation of a date
 * @param {any} date - something that can be parsed into a date by dayjs
 * @returns {string} - 
 */
const humanDate = date => {
    return date.isToday() 
        ? __( 'Today' )
        : date.isTomorrow()
            ? __( 'Tomorrow' )
            : date.isYesterday()
                ? __( 'Yesterday' )
                : date.format( "ddd, D MMM" );
};

const formatAppointmentTime = appointment => {
    const startDate = dayjs( appointment.startDate );
    const day = humanDate( startDate );
    if ( appointment.endDate ) {
        const durationMilliSecs = new Date( appointment.endDate ).getTime() - new Date( appointment.startDate ).getTime();
        const duration = humanDuration( durationMilliSecs );
        const durationStr = day + " — " + startDate.format( "h:mm a" ) + " (" + duration + ")";
        return durationStr;
    }
    else
        return day;
};

const appointmentStateDataName = {
    [ AppointmentState.rescheduled ]:       UX_JOBCARD_APPOINTMENT_RESCHEDULED,
    [ AppointmentState.done ]:              UX_JOBCARD_APPOINTMENT_DONE,
    [ AppointmentState.cancelled ]:         UX_JOBCARD_APPOINTMENT_CANCELLED,
    [ AppointmentState.active ]:            UX_JOBCARD_APPOINTMENT_ACTIVE
};

const AppointmentList = ({ appointments }) => {
    // find out the next active appt
    const { nextIndex } = findNextActiveAppointment( appointments );

    return (
        <div className={appointmentList} data-ux={UX_JOBCARD_APPOINTMENT_LIST}>
            <H3>{__( "Appointments" )}</H3>
            {appointments && appointments.map( ( a, i ) =>
                <div
                    key={a.appointmentId}
                    className={`
                        ${appointment}
                        ${i === nextIndex ? bold : ""}
                        ${a.state === AppointmentState.cancelled || a.state === AppointmentState.rescheduled ? cancelled : ""}
                        ${a.state === AppointmentState.done ? done : ""}
                    `}
                    data-ux={appointmentStateDataName[ a.state ] || UX_JOBCARD_APPOINTMENT_ACTIVE}
                >
                    <LicoAvatar userId={a.userId} size='small' />
                    &nbsp;
                    {formatAppointmentTime( a )}
                </div> )}
        </div>
    );
};

AppointmentList.propTypes = {
    appointments: PropTypes.arrayOf( PropTypes.any ).isRequired
};

const mapStateToProps = state => ({ appointments: selectJobAppointments( state ) });

export default connect( mapStateToProps )( AppointmentList );
export { AppointmentList };
