/*********************************************************************************************************************
 * @file user profile reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 12-May-2021
 *********************************************************************************************************************/
import { UserProfileMode, engineerField, DEFAULT_APPOINTMENT_DURATION } from '../../../constants.js';
import {
    setUserProfileMfaError, setUserQrCode, setUserQrCodeUrl, setUserMfaTransition, setUserVerified, setUserMfaVerified,
    setMfaBackUserProfile, setSettingValueUserProfile, discardUserSettingChanges, setUserProfile,
    setAddUserBusinessHours, setRemoveUserBusinessHours, setEngineerSettings, setUserProfileEmailError, setUserProfileNameError
} from '../../../redux/actions/index.js';
import { ezRedux } from '../../../redux/reducerUtil.js';
import { MfaStates, setVerifiedReducer, setMfaTransitionReducer, setMfaBackReducer } from '../../../utils/mfa.js';
import { getDefaultAppointmentDuration } from '../../../utils/user-prefs.js';
import { defaultContact, setDefaultContact } from '../engineer/reducer.js';
import { handleBusinessHourChange, convertWeekdayMap, validateBusinessHours, unwrapBusinessHours } from '../utilities.jsx';


/**
 * @typedef {object} UserProfileState
 * @property {string} password
 * @property {string} error
 * @property {boolean} foundChanges
 * @property {object} fieldTouched
 * @property {object} fieldError
 * @property {boolean} twoFactorAuthenticationRequired
 * @property {string} mfaState
 * @property {boolean} verified
 * @property {boolean} mfaVerified
 * @property {?string} qrcode
 * @property {?string} qrcodeUrl
 */

/**
  * @type {UserProfileState}
  */
const initialState = Object.freeze({
    password:                           '',
    error:                              '',
    foundChanges:                       false,
    mode:                               UserProfileMode.VIEW,
    fieldTouched:                       {},
    fieldError:                         {},
    userId:                             undefined,
    twoFactorAuthenticationRequired:    undefined,
    engineerProfile:                    false,
    name:                               '',
    preferences:                        undefined,
    mfaState:                           MfaStates.NONE,
    verified:                           false,
    mfaVerified:                        false,
    qrcode:                             null,
    qrCodeUrl:                          null,
    defaultAppointmentDuration:         null
});

// thunk actions

const attemptUserProfileClose = () =>  ( dispatch, getState ) => {

    const { userProfile: { foundChanges } } = getState();

    if ( foundChanges )
        dispatch( setEngineerSettings({ confirmUserProfileDialogOpen: true }) );
    else
        dispatch( discardUserSettingChanges() );
};



// reducers

const _setQrCode = ( draft, code ) => draft.qrcode = code;
const _setQrCodeUrl = ( draft, url ) => draft.qrCodeUrl = url;

const _setUserProfile = ( draft, { user, engineerProfile = false, mode = UserProfileMode.EDIT, discarding = false }) => {

    if ( !user )
        return;

    draft.orig = { ...user };
    const preferences = user.preferences;
    draft.userId = user.userId;
    draft.preferences = preferences;
    draft.name = user.name;
    draft.title = user.title ?? '';
    draft.loginEmail = user.loginEmail;
    draft.phone = mode !== UserProfileMode.ADD ? defaultContact( user, 'phone' ) : '';
    draft.contactInfo = user.contactInfo;
    draft.engineerProfile = engineerProfile;
    draft.mode = mode;
    draft.twoFactorAuthenticationRequired = preferences?.twoFactorAuthenticationRequired || false;
    draft.defaultAppointmentDuration = mode !== UserProfileMode.ADD ? getDefaultAppointmentDuration( preferences ) : DEFAULT_APPOINTMENT_DURATION;
    draft.businessHours = unwrapBusinessHours( preferences?.businessHours || []);
    draft.overrideOrgHours = preferences?.overrideOrgHours || false;
    draft.useGravatar = preferences?.useGravatar || false;
    draft.useAlternativeGravatarEmail = preferences?.useAlternativeGravatarEmail || false;
    draft.alternativeGravatarEmail = preferences?.alternativeGravatarEmail || '';
    draft.mfaState = MfaStates.NONE;
    draft.foundChanges = false;
};

const settingValueReducer = ( draft, payload ) => {
    const { name, value, blockIndex, dayIndex } = payload;

    draft.foundChanges = true;

    if ( name === engineerField.LOGIN_EMAIL && value )
        draft.fieldError.loginEmail = '';


    if ( name === engineerField.NAME && value )
        draft.fieldError.name = '';


    const businessHourError = handleBusinessHourChange( draft.businessHours, name, value, blockIndex, dayIndex );
    
    if ( businessHourError !== null ) {

        // may be error or empty string
        draft.fieldError.businessHours = businessHourError;    

        if ( !businessHourError )
            draft.overrideOrgHours = true;
    }
    else {
        draft[ name ] = value;

        if ( name === engineerField.PHONE )
            setDefaultContact( draft, 'phone', value );

        if ( name === engineerField.LOGIN_EMAIL )
            setDefaultContact( draft, 'email', value );
    }
    if ( draft?.mode === UserProfileMode.ADD && name === engineerField.LOGIN_EMAIL ) 
        draft.loginEmail = value;
    

};

const discardChangeReducer = draft => {
    _setUserProfile( draft, { user: draft.orig, mode: draft.mode, engineerProfile: false, discarding: true });
    draft.error = '';
    draft.fieldError = {};
};

const addBusinessHoursReducer = draft => {
    draft.businessHours.push({ startTime: 540, endTime: 1020, weekdays: convertWeekdayMap( 1 ) });
    draft.foundChanges = true;
    draft.fieldError.businessHours = validateBusinessHours( draft.businessHours );
    if ( !draft.fieldError.businessHours )
        draft.overrideOrgHours = true;
};

const removeBusinessHoursReducer = ( draft, payload ) => {
    draft.businessHours.splice( payload, 1 );
    draft.foundChanges = true;
    if ( draft.businessHours.length === 0 )
        draft.overrideOrgHours = false;
    draft.fieldError.businessHours = validateBusinessHours( draft.businessHours );
};

const _setUserMfaVerified = draft => {

    // the database changes were done in verifyMfa on the backend, and the same
    // action is handled by the user reducer to update the user. All we need do is fix the profile screen.
    draft.foundChanges = false;
};

const _setMfaBackUserProfile = draft => {
    setMfaBackReducer( draft );
    draft.twoFactorAuthenticationRequired = false;
    draft.foundChanges = draft.preMfaChanges;
};

const reducers = {
    [ setUserProfileMfaError ]:     ( draft, value ) => draft.fieldError.mfa = value,
    [ setUserProfileEmailError ]:   ( draft, value ) => draft.fieldError.loginEmail = value,
    [ setUserProfileNameError ]:    ( draft, value ) => draft.fieldError.name = value,
    [ setUserVerified ]:            setVerifiedReducer,
    [ setUserMfaVerified ]:         _setUserMfaVerified,
    [ setUserMfaTransition ]:       setMfaTransitionReducer,
    [ setUserQrCodeUrl ]:           _setQrCodeUrl,
    [ setUserQrCode ]:              _setQrCode,
    [ setMfaBackUserProfile ]:      _setMfaBackUserProfile,
    [ setSettingValueUserProfile ]: settingValueReducer,
    [ setAddUserBusinessHours ]:    addBusinessHoursReducer,
    [ setRemoveUserBusinessHours ]: removeBusinessHoursReducer,
    [ discardUserSettingChanges ]:  discardChangeReducer,
    [ setUserProfile ]:             _setUserProfile
};
export {
    attemptUserProfileClose
};

export default ezRedux( reducers, initialState );
