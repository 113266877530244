/** ******************************************************************************************************************
 * @file Common functions that are based on the relations data.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 31-01-2024
 *********************************************************************************************************************/
const isRegExp = v => v?.constructor?.name === 'RegExp';

import { diff } from 'just-diff';

const deepRead = ( o, path ) => ( Array.isArray( path ) ? path : path.split( '.' ) )
    .reduce( ( cur, key ) => cur?.[ key ], o );

const _check    = ( value, checker ) =>
    value != null && ( isRegExp( checker ) ? checker.test( value ) : value === checker );
const _validate = ( record, check ) =>
    Object.entries( check ).every( ([ name, rxVal ]) => _check( record[ name ], rxVal ) );

class RelationHelpers
{
    required;
    validations;
    idColumn;
    jsonFields;
    copy;

    hasRequired( records, inverse = false )
    {
        if ( this.required.length === 0 )
            return Array.isArray( records ) ? Array.from( records, () => true ^ inverse ) : true ^ inverse;

        if ( !Array.isArray( records ) )
            return Boolean( this.required.every( req => records[ req ] != null ) ^ inverse );

        return records.map( record => this.required.every( req => record[ req ] != null ) ^ inverse );
    }

    filterHasRequired( records, inverse = false )
    {
        if ( this.required.length === 0 )
            return Array.isArray( records ) ? ( inverse ? [] : Array.from( records ) ) : ( inverse ? null : records );

        if ( !Array.isArray( records ) )
            return ( this.required.every( req => records[ req ] != null ) ^ inverse ) ? records : null;

        return records.filter( record => this.required.every( req => record[ req ] != null ) ^ inverse );
    }

    validates( records, inverse = false )
    {
        if ( !this.validations )
            return Array.isArray( records ) ? Array.from( records, () => true ^ inverse ) : true ^ inverse;

        if ( !Array.isArray( records ) )
            return Boolean( _validate( records, this.validations ) ^ inverse );

        return records.map( record => _validate( record, this.validations ) ^ inverse );
    }

    filterValidates( records, inverse = false )
    {
        if ( !this.validations )
            return Array.isArray( records ) ? ( inverse ? [] : Array.from( records ) ) : ( inverse ? null : records );

        if ( !Array.isArray( records ) )
            return ( _validate( records, this.validations ) ^ inverse ) ? records : null;

        return records.filter( record => _validate( record, this.validations ) ^ inverse );
    }

    isValid( records, inverse = false )
    {
        const mapFn = inverse
            ? record => this.validates( record, true ) || this.hasRequired( record, true )
            : record => this.validates( record ) && this.hasRequired( record );

        if ( !Array.isArray( records ) ) return mapFn( records );

        return records.map( mapFn );
    }

    filterIsValid( records, inverse = false )
    {
        const mapFn = inverse
            ? record => this.validates( record, true ) || this.hasRequired( record, true )
            : record => this.validates( record ) && this.hasRequired( record );

        if ( !Array.isArray( records ) ) return mapFn( records ) ? records : null;

        return records.filter( mapFn );
    }

    isInvalid( records )
    {
        return this.isValid( records, true );
    }

    filterIsInvalid( records )
    {
        return this.filterIsValid( records, true );
    }

    splitValid( records )
    {
        let result = [];

        if ( !Array.isArray( records ) )
            result[ +this.isInvalid( records ) ] = records;
        else
        {
            result = [ [], [] ];
            records.forEach( record => result[ +this.isInvalid( record ) ].push( record ) );
        }

        return result;
    }

    splitInvalid( records )
    {
        const [ a, b ] = this.splitValid( records );

        return [ b, a ];
    }

    #categorize( records, staleData, currentRecords = null )
    {
        const total     = records.length;
        const add       = [];
        const update    = [];
        const unchanged = [];
        const invalid   = [];

        let i = 0;

        for ( const record of records )
        {
            const { licoriceId, newer } = staleData[ i++ ] ?? {};

            if ( licoriceId && !record[ this.idColumn ])
                record[ this.idColumn ] = licoriceId;

            if ( !this.isValid( record ) )
            {
                invalid.push({ [ this.idColumn ]: record[ this.idColumn ] || licoriceId, ...record });
                continue;
            }

            if ( !licoriceId )
                add.push( record );
            else
            {
                let _record;

                if ( currentRecords )
                    _record = this.diff( currentRecords[ licoriceId ], record );

                if ( ( newer && !currentRecords ) || ( !newer && _record ) )
                    update.push( _record ?? { [ this.idColumn ]: licoriceId, ...record });
                else
                    unchanged.push({ [ this.idColumn ]: licoriceId, ...record });
            }
        }

        return { total, add, update, unchanged, invalid };
    }

    categorize( records, staleData, currentRecords )
    {
        if ( !Array.isArray( records ) )
        {
            staleData = Array.isArray( staleData ) ? staleData : [ staleData ];
            currentRecords = Array.isArray( currentRecords )
                ? currentRecords
                : currentRecords
                    ? [ currentRecords ]
                    : null;
            
            const { add, update, unchanged, invalid } =
                      this.#categorize([ records ], staleData, currentRecords );

            return add.length
                ? { add: add[ 0 ] }
                : update.length
                    ? { update: update[ 0 ] }
                    : unchanged.length
                        ? { unchanged: unchanged[ 0 ] }
                        : { invalid: invalid[ 0 ] };
        }

        return this.#categorize( records, staleData );
    }

    diff( _from, _to, ignore = [])
    {
        const updates = {};
        // eslint-disable-next-line no-unused-vars
        const { [ this.idColumn ]: _1, updatedOn: _2, createdOn: _3, _info: _4, ...from } = _from;
        // eslint-disable-next-line no-unused-vars
        const { [ this.idColumn ]: _5, updatedOn: _6, createdOn: _7, _info: _8, ...to } = _to;

        for ( const { op, path, value } of diff( from, to ) )
        {
            const [ p, key ] = path;

            switch ( op )
            {
                case 'add':
                case 'replace':
                    if ( key != null )
                    {
                        updates[ p ] ??= ( Array.isArray( _from[ p ]) ? Array.from( _from[ p ]) : { ..._from[ p ] });
                        updates[ p ][ key ] = value;
                    }
                    else
                        updates[ p ] = value;
                    break;

                case 'remove':
                    // We don't delete root keys in a database record.
                    if ( key === undefined ) break;

                    // Root key with index must be an array
                    if ( path.length === 2 && typeof key === 'number' && Array.isArray( from[ p ]) )
                        updates[ p ] = Array.from( from[ p ]).splice( key, 1 );

                    // Root key with a JSON object. We can't delete individual keys, so we apply
                    // all modifications and write the entire object.
                    else if ( this.jsonFields.includes( p ) )
                    {
                        if ( !Object.hasOwn( updates, p ) )
                            updates[ p ] = from[ p ];

                        const delKey = path.pop();
                        const base   = path.reduce( ( ptr, key ) => ptr?.[ key ], updates );

                        if ( base ) delete base[ delKey ];
                    }
                    break;
            }
        }

        return Object.keys( updates ).length > 0 ? updates : null;
    }

    copyValues( fromObject, selfObject )
    {
        if ( !this.copy ) return null;

        const result = {};

        const copy = dot => {
            for ( const { from, to } of this.copy )
            {
                if ( dot && from[ 0 ] === '.' && selfObject[ from.substring( 1 ) ] != null )
                    result[ to ] = deepRead( selfObject, from.substring( 1 ) );

                if ( !dot && from[ 0 ] !== '.' )
                    result[ to ] = String( deepRead( fromObject, from ) );
            }
        };

        copy( true );
        copy( false );

        return result;
    }

    static {
        RelationHelpers.prototype.removeInvalid = RelationHelpers.prototype.filterIsInvalid;
        RelationHelpers.prototype.removeValid = RelationHelpers.prototype.filterIsValid;
    }
}

export { RelationHelpers };
