import React from 'react';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';

import { LicoCheckBox } from "../common/index.js";


dayjs.extend( localizedFormat );

// 2001 is a year when jan 1 = monday
const weekdayNames = [ ...Array( 7 ).keys() ].map( i => dayjs( `2001-01-0${i + 1}` ).format( 'dddd' ) );

const WeekdayMap = ({ weekdays, setValue }) => <div>
    {weekdays.map( ( flag, i ) => <LicoCheckBox
        key={i}
        checked={flag}
        name="weekdays"
        onChange={( e, checked ) => setValue({
            name:       e.target.name,
            value:      checked,
            dayIndex:   i
        })}
    >
        {weekdayNames[ i ]}
    </LicoCheckBox> )}
</div>;

WeekdayMap.propTypes = {
    weekdays:       PropTypes.array.isRequired,
    setValue:       PropTypes.func.isRequired
};

export default WeekdayMap;
export { WeekdayMap };
