import React from 'react';

import PropTypes from 'prop-types';

import { __ } from '../../utils/i18n.jsx';

import GenericDialog, { DialogButtonColors } from './GenericDialog.jsx';

const IntegrationDialog = ({ isOpen, saveAction, cancelAction }) => (
    <GenericDialog
        isOpen={isOpen}
        title={__( "Run Standalone?" )}
        message={ __( "Do you choose to run Standalone and not integrate with a PSA?" )}
        buttons={[
            {
                label:          __( "Yes, Standalone" ),
                action:         saveAction,
                color:          DialogButtonColors.safety
            },
            {
                label:          __( "Cancel" ),
                action:         cancelAction,
                color:          DialogButtonColors.cancel
            }
        ]}
    /> );

IntegrationDialog.propTypes = {
    isOpen:         PropTypes.bool.isRequired,
    saveAction:     PropTypes.func.isRequired,
    cancelAction:   PropTypes.func.isRequired
};

export default IntegrationDialog;
export { IntegrationDialog };
