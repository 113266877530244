import { CONNECTWISE } from '@licoriceio/constants';
import { camelToScreamingSnake, ucf } from '@licoriceio/utils';
import Axios from "axios";
import { createAction } from 'redux-actions';

import { buildEnv } from "../../build-env.js";
import { errorType } from "../../constants.js";
import { getStore } from '../../publicStore.js';
import { startLoading, stopLoading, setError } from '../../redux/actions/index.js';

import { createSingleLineErrorFn } from "./util.js";

/**
 * The API URL will be generated as follow:
 *
 * In production:
 *
 * Currently:
 * The URL is the same as the origin but with the prefix "api-". This will  change since that requires us to use CORS.
 *
 * Soon:
 * The URL will be the same as the origin. We will adopt the local idea of prefixing the path with "/api" and using a Cloudflare
 * route worker to redirect the request to our API CNAME.
 *
 * In development:
 *
 * If `HOSTNAME === 'localhost'` then we omit the host and just use "/api" as a path prefix.
 * If `HOSTNAME !== 'localhost'`
 * then we use the host named and use the `protocol` from `window.location.protocol` and the `SERVER_PORT`.
 *
 * Handy things to remember about the URL object:
 * 1. If a field has no value, it's set to the empty string `''`
 * 2. The `protocol` value ends with `':'`
 * 3. `host` is the host name plus port number, if any
 * 4. `hostname` is the host name without a port number
 * 5. The `port` field is a string, not a number
 * 6. `search` starts with a `'?'` or is an empty string if no query parameters were present
 * 7. `pathname` starts with a slash (`'/'`), even if no path was present in the URL, there'll still be a slash here
 * 8. The `origin` field is the protocol, double slashes, and the `host` field but _not_ username and password, if they were part of the origin URL
 * 9. The `href` field is the same as `new URL( myUrl ).toString()` or `String( tmp )` and includes everything properly formatted
 */
const { protocol } = window.location;
const hostName = buildEnv.HOSTNAME;
const serverPort = buildEnv.SERVER_PORT;

const makeUrl = port => {
    let apiHost = '/api';

    if ( import.meta.env.MODE === 'development' && !hostName.startsWith( 'localhost' ) )
        apiHost = protocol + '//' + hostName.split( ':' )[ 0 ] + ':' + ( serverPort || '8086' ) + apiHost;
    else if ( import.meta.env.MODE === 'development' )
        apiHost = protocol + '//' + window.location.hostname.split( ':' )[ 0 ] + ':' + port + apiHost;

    console.debug( `apiHost ${apiHost}`, import.meta.env );

    return apiHost;
};

const BASE_URL = makeUrl( '3000' );
const BACKEND_URL = makeUrl( serverPort || '8086' );

function makeBoilerplate({ method, url, name, action: setter, preAction })
{
    const fetcher = abstractedCreateAuthRequest( method, url );
    const uname = ucf( name );
    const setName = 'set' + uname;
    const _setName = '_' + setName;
    const getName = 'get' + uname;

    const actionCreator = { [ setName ]: createAction( `INTEGRATION.${ucf( camelToScreamingSnake( name ) ).replace( /_/g, '.' )}/SET` ) }[ setName ];
    const action        = { [ _setName ]: ( draft, result ) => setter( draft, result ) }[ _setName ];
    const thunk         = {
        [ getName ]: data => ( dispatch, getState ) => {
            if ( preAction ) dispatch( preAction() );
            return fetcher( data, getState().auth, [ CONNECTWISE ]).then( result => dispatch( actionCreator( result.hasError ? { payload: null } : result ) ) );
        }
    }[ getName ];

    return [ actionCreator, action, thunk ];
}


function thunkify( method, url, setter, params = [ CONNECTWISE ])
{
    const fetcher = abstractedCreateAuthRequest( method, url );

    return ( data = {}) => ( dispatch, getState ) => {
        return fetcher( data, getState().auth, params )
            .then( result => {
                if ( result.hasError )
                    dispatch( setError( result.error ) );
                else
                    dispatch( setter( result.payload || result.data ) );
            });
    };
}

/**
 * @template {object|null|undefined} T
 *
 * @param {boolean} isAuthedRequest
 * @param {"GET" | "POST" | "PATCH" | "DELETE" | "PUT"} method
 * @param {string} url
 * @param {function(any): T} parseFn
 * @param {boolean} isArrayResponse
 * @param {string} [message]
 * @param {AxiosRequestConfig} [options]
 */
const abstractedCreateRequest = ( method, url ) => createAuthRequest( url, createSingleLineErrorFn( `Error ${method}ing ${url}` ), method );

/**
 * @template {object|null|undefined} T
 * @param {"GET" | "POST" | "PATCH" | "DELETE" | "PUT"} method
 * @param {string} url
 */
const abstractedCreateAuthRequest = abstractedCreateRequest;

// Same as the normal one, but typescript interface to enforce auth parameter
/**
 * @template {object|null|undefined} T
 * @template {object} U
 * @template {ServiceError<any>} V
 *
 * @param {string} uri
 * @param {function( AxiosResponse<any> ): V} parseFourHundredFunction
 * @param {"GET" | "POST" | "PATCH" | "DELETE" | "PUT"} method
 * @param {AxiosRequestConfig} [options]
 */
const createAuthRequest = createRequest;

// split the URL on any string looking like ':<alpha>+', so we can put the correct field name in the uri constant.
// We could change + to * to make the alpha portion optional...
const urlIdReplacer = ( baseUrl, ids ) => !ids ? baseUrl : baseUrl.split( /:[a-z]+/i ).reduce( ( acc, cur, i ) => [ ...acc, cur, ids[ i ] ], []).join( '' );

const createFatalError = message => ({ type: "fatal", errors: message });

/**
 * @template {object|null|undefined} T
 * @template {object} U
 * @template {ServiceError<any>} V
 *
 * @param {string} uri
 * @param {function( AxiosResponse<any> ): V} parseFourHundredFunction
 * @param {"GET" | "POST" | "PATCH" | "DELETE" | "PUT"} method
 * @param {AxiosRequestConfig} [options]
 * @return {function( ...* ): Promise<HandledServiceResponse<U, V>>}
 */
export function createRequest( uri, parseFourHundredFunction = createSingleLineErrorFn( `Error fetching ${uri}` ), method = 'GET', options = {})
{
    if ( uri === undefined ) {
        console.warn( 'createRequest with no URI', uri );
        console.trace();
    }

    return async function( data, auth, ids, params ) {

        const store = getStore();
        if ( !store ) 
            console.warn( 'No store in createRequest' );
    
        const authHeader = auth ? { 'Authorization': `Bearer ${auth.token}` } : {};

        // if we want to have multiple instances of the same param in the URL (eg ?key=1&key=2, which looks weird
        // but is useful sometimes.), we have to set up a parameter object.
        // Easiest thing is just to set it up always, rather than checking the values first.
        // Specify values as arrays to produce the above result, eg { key: [ 1, 2 ] }.
        const realParams = new URLSearchParams();

        if ( params )
        {
            Object.keys( params ).forEach( name => {
                const values = Array.isArray( params[ name ]) ? params[ name ] : [ params[ name ] ];
                values.forEach( value => realParams.append( name, value ) );
            });
        }

        const _options = { ...options, params: realParams };

        // The handling of URI ids is a bit hacky for now, we assume that there is just one. (ikm, later; I assume the 'one'
        // here refers to :id tokens in the URI.)
        const url = urlIdReplacer( BASE_URL + uri, ids );

        const loadingLabel = method + uri;

        if ( store )
            store.dispatch( startLoading( loadingLabel ) );

        try
        {
            const result = await Axios({
                url,
                method,
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                data,
                validateStatus: ( status ) => {
                    // We are accepting 300s as valid
                    // 400s are handled with the parseFourHundred function
                    return ( status >= 200 && status < 500 );
                },
                ..._options
            });

            const status = result.status;
            if ( store )
                store.dispatch( stopLoading( loadingLabel ) );

            if ( status >= 400 && status < 500 )
            {
                const error = parseFourHundredFunction( result );

                if ( store )
                    store.dispatch( setError({ uri: uri, error }) );

                return { hasError: true, error };
            }

            // uncomment this when debugging test data flows
            // console.log( `baserequest data from ${method}/${url}`, JSON.stringify({
            //     input:      data,
            //     result:     result.data
            // }, undefined, 4 ) );

            return {
                hasError:   false,
                payload:    result.data,
                headers:    result.headers,
                count:      result.headers[ 'x-licorice-count' ]
            };

        }
        catch ( err )
        {
            // Todo: format the error
            if ( store )
                store.dispatch( stopLoading( loadingLabel ) );
            console.error( 'err', JSON.stringify( err, undefined, 4 ) );
            if ( store )
                store.dispatch( setError({ uri, error: createFatalError( err.response?.status > 500 ? { type: errorType.NO_CONNECTION } : err.message ) }) );
            return { hasError: true, error: err };
        }
    };
}

export {
    abstractedCreateAuthRequest,
    abstractedCreateRequest,
    createAuthRequest,
    thunkify,
    makeBoilerplate,
    createFatalError,
    BASE_URL,
    BACKEND_URL
};
