

import { createBrowserHistory } from "history";
import { persistStore } from 'redux-persist';

import { setupStore  } from "./store.js";

export const store = setupStore();

export const persistor = persistStore( store );

export const history = createBrowserHistory();
