import React from 'react';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createServer, Response } from "miragejs";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";

import App from './components/app/App.jsx';
import { theme, Loader } from "./components/common/index.js";
import "./static/baseCss.css";
import "./static/fonts.css";
import { ErrorBoundary } from './components/ErrorBoundary.jsx';
import { store, persistor } from './createStore.js';
import * as serviceWorker from './serviceWorker.js';

const container = document.getElementById( 'root' );
const root = createRoot( container );
root.render( <Provider store={store}>
    <PersistGate loading={< Loader />} persistor={persistor} >
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme} >
                <CssBaseline />
                <ErrorBoundary>
                    <App purgeStore={persistor.purge} />
                </ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    </PersistGate>
</Provider> );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
