/** ******************************************************************************************************************
 * @file Description of this file goes here.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 14-Apr-2021
 *********************************************************************************************************************/

import { DELETE } from '@licoriceio/constants';

import { PUT, uri } from '../constants.js';
import { abstractedCreateAuthRequest } from '../services/util/baseRequests.js';

import { genericRequest } from './reducerUtil.js';

const acquireMutex = abstractedCreateAuthRequest( PUT, uri.SYSTEM_MUTEX, a => a );
const releaseMutex = abstractedCreateAuthRequest( DELETE, uri.SYSTEM_MUTEX, a => a );

const safeRequest = ({ resource, data, request, action = undefined, ids = [], params = undefined }) => async ( dispatch, getState ) => {
    acquireMutex({}, getState().auth, [ resource ])
        .then( result => {
            if ( !result.hasError && result.payload )
            {
                const mutex = result.payload;
                return genericRequest( data, request, action, ids, { ...( params || {}), mutex: result.payload })( dispatch, getState )
                    .then( () => releaseMutex({}, getState().auth, [ resource ], { mutex }) );
            }
        }).catch( e => {
            console.error( e );
            return { hasError: true, error: e };
        });
};

export { safeRequest };
