/*********************************************************************************************************************
 * @file Pegboard top timer component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React          from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { timeEnteredProgress } from '../../constants.js';
import { text, time, onTarget, behind, badlyBehind } from '../../scss/PegboardTopTimer.module.scss';
import { __ }         from '../../utils/i18n.jsx';
import { P }          from '../common/index.js';

import { selectTimeEntered } from './reducer.js';

const progressClass = {
    [ timeEnteredProgress.ON_TARGET ]:      onTarget,
    [ timeEnteredProgress.BEHIND ]:         behind,
    [ timeEnteredProgress.BADLY_BEHIND ]:   badlyBehind
};

const PegboardTopTimer = ({ timeEnteredString, progress }) => <div >
    <h3 className={text}>{__( "Time entered today" )}</h3>
    <P className={`${time} ${progressClass[ progress ]}`}>{timeEnteredString}</P>
</div>;

PegboardTopTimer.propTypes = {
    timeEnteredString:                  PropTypes.string,
    progress:                           PropTypes.string
};

const mapStateToProps = state => ({
    ...selectTimeEntered( state )
});

export default connect( mapStateToProps )( PegboardTopTimer );
export { PegboardTopTimer };
