import React from "react";

import cuid from "cuid";
import PropTypes from 'prop-types';
import { Prompt } from "react-router-dom";

import { LOGIN } from "../navigation/routes.js";

import NavigationDialog from './NavigationDialog.jsx';

// store dialog triggers; see Router.js for use
const triggerMap = {};

class PreventTransitionPrompt extends React.Component {

    /**
     * Dialog state
     */
    state = { open: false, approved: false };

    constructor() {
        super();

        // Define a unique global symbol to store a dialog trigger reference accessible via
        // a string key. Use cuid for unique ID.
        this.__trigger = Symbol.for( `__PreventTransitionPrompt_${cuid()}` );
    }

    /**
     * Attach global dialog trigger for this component instance to our Symbol trigger
     */
    componentDidMount() {
        triggerMap[ this.__trigger ] = this.show;
    }

    /**
     * Ensure we clean up and remove the reference from the global object
     */
    componentWillUnmount() {
        delete triggerMap[ this.__trigger ];
    }

    render() {
        const { when, saveAction, discardAction, extraContent, saveDisabled, SingleActionDialog  } = this.props;
        const { open, approved } = this.state;

        return (
            <React.Fragment>

                {/* React Router prompt, callback will return true to allow transition or dialog key to prevent */}
                <Prompt when={when && !approved} message={this.handleTransition} />

                { 
                    SingleActionDialog 
                        ? <SingleActionDialog
                            isOpen={open}
                            saveAction={() => {
                                saveAction();
                                this.handleGo();
                            }}
                            cancelAction={this.handleClose}
                        />
                        : <NavigationDialog
                            isOpen={open}
                            saveAction={() => {
                                saveAction();
                                this.handleGo();
                            }}
                            saveDisabled={saveDisabled}
                            discardAction={() => {
                                discardAction?.();
                                this.handleGo();
                            }}
                            cancelAction={this.handleClose}
                            extraContent={extraContent}
                        />
                }

            </React.Fragment>
        );
    }

    /**
     * Show the dialog. Invoked primarily from React Router transition
     * handler getUserConfirmation.
     *
     * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
     */
    show = allowTransitionCallback => {
        this.setState({ open: true }, () => allowTransitionCallback( false ) );
    };

    /**
     * Closes the dialog
     */
    handleClose = () => {
        this.setState({ open: false });
    };

    /**
     * Closes the dialog and navigate to the location we saved to state
     */
    handleGo = () => {
        this.setState({ open: false, approved: true }, () => this.props.history.push( this.state.nextLocation.pathname ) );
    };

    /**
     * Handles the Router transition. Returns true if allowed
     * or the dialog trigger key to enable the dialog, in which case we store the attempted location.
     */
    handleTransition = location => {
        
        // if we're navigating to the current location, or we're logging out, don't display a warning
        if ( location.pathname === this.props.history.location.pathname || location.pathname === LOGIN )
            return true;

        this.setState({ nextLocation: location });
        return Symbol.keyFor( this.__trigger );
    };

    static propTypes = {
        when:               PropTypes.bool,
        saveAction:         PropTypes.func.isRequired,
        saveDisabled:       PropTypes.bool,
        SingleActionDialog: PropTypes.func,
        discardAction:      PropTypes.func,
        extraContent:       PropTypes.any,
        history:            PropTypes.object.isRequired
    };
}

export default PreventTransitionPrompt;
export { triggerMap };
