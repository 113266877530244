/********************************************************************************************************************
 * @file Actions for the typeahead store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setCreateTypeAheadControl = createAction( 'TYPEAHEAD.CREATE_CONTROL/SET' );
export const setTypeAheadFields = createAction( 'TYPEAHEAD.FIELDS/SET' );
export const setTypeAheadCompanies = createAction( 'TYPEAHEAD.CLIENTS/SET' );
export const setTypeAheadPeople = createAction( 'TYPEAHEAD.ENGINEERS/SET' );
export const setTypeAheadAssets = createAction( 'TYPEAHEAD.ASSETS/SET' );
