/*********************************************************************************************************************
 * @file Engineer reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Dec-2020
 *********************************************************************************************************************/
import { uri, POST, GET } from '../../../constants.js';
import { setJournalPageRows, setJournalPageIndex, setJournalDownloadContent } from '../../../redux/actions/index.js';
import { ezRedux, genericRequest } from '../../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../../services/util/baseRequests.js';

/**
 * @typedef {object} JournalState
 * @property {array} displayedLines
 * @property {object} page
 */

/**
  * @type {JournalState}
  */
const initialState = Object.freeze({
    displayedLines:                     [],
    page:                               0,
    total:                              -1,
    downloadContent:                    null
});

// requests

const addJournal = ( type, entry ) => 
    genericRequest({ type, data: typeof entry === 'object' ? { ...entry } : { message: entry } }, abstractedCreateAuthRequest( POST, uri.JOURNAL ) );

const getJournals = ( filter, page, pageSize ) => genericRequest(
    {}, 
    abstractedCreateAuthRequest( GET, uri.JOURNAL ), 
    [ [ setJournalPageRows ] ], 
    [ filter?.length ? filter : '_' ], 
    { page, pageSize, orderBy: 'createdOn', withCount: true }
);

const getJournalsForDownload = filter => genericRequest(
    {}, 
    abstractedCreateAuthRequest( GET, uri.JOURNAL ), 
    [ setJournalDownloadContent ], 
    [ filter?.length ? filter : '_' ], 
    { page: 0, pageSize: 9999, orderBy: 'createdOn' }
);

/** reducers */
const _setJournalPageRows = ( draft, payload ) => {
    const { payload: rows, headers } = payload;

    draft.displayedLines = rows;

    // we're getting the count every time; maybe should only do it when we change the filter, but the 
    // rows returned is subject to change from outside forces so...
    draft.total = Number( headers[ "x-licorice-count" ]);
};

const _setJournalDownloadContent = ( draft, payload ) => {
    draft.downloadContent = payload ? JSON.stringify( payload, undefined, 4 ) : null;
};

const reducers = {
    [ setJournalPageRows ]:             _setJournalPageRows,
    [ setJournalPageIndex ]:            ( draft, payload ) => draft.page = payload,
    [ setJournalDownloadContent ]:      _setJournalDownloadContent
};

export {
    addJournal,
    getJournals,
    getJournalsForDownload
};

export default ezRedux( reducers, initialState );
