/*********************************************************************************************************************
 * @file NoteEntryField component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React, { useCallback, useEffect, useState } from 'react';

import { MAX_TIME_LOG_DURATION, NoteType, integrationNames } from '@licoriceio/constants';
import { numSecondsToString } from '@licoriceio/utils';
import { List, ListItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PEGBOARD_SLOTS, disablePlayPauseDuringRequests, nameDataFormat } from '../../constants';
import { setCommentBillable, setShowEditTimer, setEditableTime, setBillingType, setSkipClientEmail } from '../../redux/actions/index.js';
import { selectNameData } from '../../redux/selectors/index.js';
import { 
    buttons, submitIcon, widget, widgetColumn, playPauseIcon, billableEnabled, billableDisabled, tinyTimerCss, skipEmailEnabled, skipEmailDisabled 
} from '../../scss/WorkLog.module.scss';
import { __ }             from '../../utils/i18n.jsx';
import { requestIsLoading } from '../../utils/misc.js';
import { UX_JOBCARD_CLIENT_CHAT, UX_JOBCARD_ENGINEER_CHAT } from '../../ux-constants.js';
import { closeEitherCard } from '../calendar/shared.js';
import RichEditor from '../common/editor/RichEditor.jsx';
import { LicoIcon, LicoPopupMenu } from '../common/index.js';
import TinyTimer from '../common/TinyTimer.jsx';
import { dragJobToPegboard, getCurrentJobTimerState, pausePegboardTimer, saveTimerValue } from '../pegboard/reducer.js';

import popOverUseStyles from "./NoteEntryPopOverStyles.jsx";
import { queueNoteAction, sendPendingNote, setNoteText, billingTypeItemData } from './reducer.js';
import { addFile } from './thunks.js';

const WorkTypeItem = ({ name, billingTypeId, classes, setWidgetColumnClasses, setBillingMethod, handleBilling }) => {
    const itemData = billingTypeItemData[ name ];

    return <ListItem
        className={classes.listItem}
        onClick={() => {
            setWidgetColumnClasses( itemData.enabledClass, itemData.disabledClass );
            setBillingMethod(
                handleBilling( billingTypeId, itemData.icon, itemData.billable, itemData.iconSlashed )
            );
        }}
    >
        <div className={classes.listItemGroup}>
            <LicoIcon
                icon={itemData.icon}
                className={`${classes[ itemData.iconClass ]} ${classes.listItemIconSize}`}
                tooltip={itemData.tooltip}
                slashed={itemData.iconSlashed}
                tooltipDelay={700}
                label={itemData.name}
            />
            <div className={classes.listItemText}>
                <div className={classes[ itemData.iconClass ] }>{itemData.name}</div>
            </div>
        </div>

    </ListItem>;
};

WorkTypeItem.propTypes = {
    name:                           PropTypes.string.isRequired,
    billingTypeId:                  PropTypes.string.isRequired,
    classes:                        PropTypes.object.isRequired,
    setWidgetColumnClasses:         PropTypes.func.isRequired,
    setBillingMethod:               PropTypes.func.isRequired, 
    handleBilling:                  PropTypes.func.isRequired
};

const NoteEntryField = ({
    noteType,
    messageText,
    messageIsEmpty,
    draftMessageLoading,
    disabled,
    autoFocus,
    job,
    currentTimerSecs,
    billable,
    billingTypeId,
    billingTypeName,
    billingTypes,
    queueNoteAction,
    sendPendingNote,
    setNoteText,
    setCommentBillable,
    setBillingType,
    showEditTimer,
    editableTime,
    validEditTime,
    pausePegboardTimer,
    pausedAt,
    setShowEditTimer,
    saveTimerValue,
    setEditableTime,
    timeLogId,
    closeEitherCard,
    readOnlyCompany,
    addFile,
    dragJobToPegboard,
    items,
    playPausePendingReq,
    completed,
    setSkipClientEmail,
    emailContact,
    skipClientEmail
}) => {

    // returns true if there is space for a new job on the pegboard, or the job is already on the pegboard.
    const jobAvailableForPegboard = () => {
        return ( items?.length && items.some( item => item.jobId === job.jobId ) ) || ( PEGBOARD_SLOTS - items.length ) > 0;
    };

    const uxConstants = noteType === NoteType.message ? UX_JOBCARD_CLIENT_CHAT : UX_JOBCARD_ENGINEER_CHAT;

    const queueNote = useCallback( () => {
        if ( !messageIsEmpty ) 
            queueNoteAction({ jobId: job.jobId, noteType, messageText, billable, timeLogId });
    },
    [ job.jobId, noteType, messageText, messageIsEmpty, billable, timeLogId, queueNoteAction ]);

    const finishTimerEdit = useCallback( doQueue => {
        if ( validEditTime ) {
            saveTimerValue( job.jobId, timeLogId, editableTime, billingTypeId );
            if ( doQueue ) 
                queueNote();
        }
        else 
            setEditableTime( numSecondsToString( currentTimerSecs, false ) );
    }, [ validEditTime, job.jobId, timeLogId, currentTimerSecs, editableTime, saveTimerValue, setEditableTime, queueNote, billingTypeId ]);

    const handleTimerKeyDown = e => {
        if ( e.keyCode === 13 || e.keyCode === 27 ) {
            if ( e.keyCode === 13 ) {
                finishTimerEdit( e.ctrlKey );
                e.stopPropagation();
            }
            setShowEditTimer( false );
        }
    };

    const disabledSubmitIcon = disabled || messageText.length === 0 || !validEditTime || messageIsEmpty || completed;

    const [ widgetEnabledClass, setWidgetEnabledClass ] = useState( billableEnabled );
    const [ widgetDisabledClass, setWidgetDisabledClass ] = useState( billableDisabled );
    const [ anchorEl, setAnchorEl ] = useState( null );
    const isMenuOpen = Boolean( anchorEl );
    const resetElAnchor = () => {
        if ( anchorEl !== null )
            setAnchorEl( null );
    };

    const classes = popOverUseStyles();

    const setWidgetColumnClasses = ( enabledClass, disabledClass ) => {
        setWidgetEnabledClass( enabledClass );
        setWidgetDisabledClass( disabledClass );
    };

    const handleBilling = ( billingTypeId, icon, isCommentBillable, isSlashed, size ) => {
        setCommentBillable( isCommentBillable );
        setBillingType( billingTypeId );
        resetElAnchor();
        const BillingTypesComponent = () => {
            resetElAnchor();
            return (
                <div>
                    <LicoIcon
                        icon={icon}
                        slashed={isSlashed}
                        style={{ width: '32px', fontSize: '15px', display: 'flex' }}
                        size={size}

                    />
                </div>
            );
        };
        BillingTypesComponent.displayName = 'BillingTypesComponent';
        return BillingTypesComponent;
    };

    const [ billingMethod, setBillingMethod ] = useState( null );

    useEffect( () => {
        const data = billingTypeItemData[ billingTypeName ];
        if ( data ) {
            const { icon, billable, iconSlashed, enabledClass, disabledClass } = data;
            const method = handleBilling( billingTypeId, icon, billable, iconSlashed );
            setBillingMethod( method );
            setWidgetColumnClasses( enabledClass, disabledClass );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ billingTypeId, billingTypeName ]);

    const onPlay = () => {
        if ( !playPauseDisabled ) 
            dragJobToPegboard( job.jobId );
    };

    const onPause = () => {
        if ( !playPauseDisabled ) 
            pausePegboardTimer({ timeLogId });
    };

    const playPauseDisabled = ( pausedAt !== null && !jobAvailableForPegboard() ) || readOnlyCompany || disabled || playPausePendingReq;

    return (
        <div>

            <RichEditor
                addFile={addFile} 
                textValue={messageText} 
                placeholder={ disabled ? __( "Notes disabled" ) : __( "Press Ctrl-Enter to add message" ) }
                hotkey={{
                    'ctrl-Enter':   () => {
                        if ( !messageIsEmpty )
                            queueNoteAction({ jobId: job.jobId, noteType, messageText, currentTimerSecs, billable, timeLogId });
                    },
                    'Escape':       () => closeEitherCard()
                }}
                disabled={disabled} 
                autoFocus={autoFocus} 
                onChange={ ( text, isEmpty ) => { 

                    // the control generates a change event when loading, and we don't want that (empty) event
                    // updating the draft message and losing the old content. 
                    if ( !draftMessageLoading )
                        setNoteText({ noteType, text, isEmpty });
                }}
                dataName={uxConstants.text}
            />

            <div className={buttons}>
                <LicoIcon
                    icon="keyboard"
                    style={{ marginTop: 5, color: 'var(--grey-3)' }}
                    tooltip={<div>{__( 'Keys' )}<br/><ul>
                        <li>ctrl-alt-shift-C - {__( 'code block' )}</li>
                        <li>ctrl-shift-Q - {__( 'block quote' )}</li>
                        <li>ctrl-b - {__( 'bold' )}</li>
                        <li>ctrl-i - {__( 'italic' )}</li>
                        <li>ctrl-u - {__( 'underline' )}</li>
                        <li>ctrl-shift-X - {__( 'strikethrough' )}</li>
                    </ul>
                    </div>} />

                <div className={widget}>

                    {
                        noteType === NoteType.message && emailContact &&
                        <div className={`${widgetColumn} ${skipClientEmail ? skipEmailEnabled : skipEmailDisabled}`} onClick={() => setSkipClientEmail()} >
                            <LicoIcon 
                                tooltip={skipClientEmail 
                                    ? __( "This note will not be emailed to the client" ) 
                                    : __( "This note will be emailed to the client as usual" )}
                                tooltipDelay={200}
                                icon="envelope" 
                                slashed={skipClientEmail} 
                                className={playPauseIcon} 
                            />
                        </div>
                    }

                    <div className={`${widgetColumn} ${widgetEnabledClass}`} onClick={( event ) => setAnchorEl( event.currentTarget )}  >
                        <div>
                            {billingMethod}
                        </div>
                        <LicoPopupMenu
                            id="billing-type-menu"
                            anchorEl = {anchorEl}
                            anchorOrigin={{
                                vertical:   'top',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical:   'center',
                                horizontal: 'center'
                            }}
                            open={isMenuOpen}
                            onClose={( event ) => {
                                event.stopPropagation();
                                resetElAnchor();
                            } }
                        >
                            <List className={classes.list}>
                                {billingTypes.filter( wt => wt.name.toLowerCase() !== 'unmapped' ).map( wt => (
                                    <WorkTypeItem
                                        key={wt.name}
                                        name={wt.name}
                                        billingTypeId={wt.licoriceNameId}
                                        classes={classes}
                                        setWidgetColumnClasses={setWidgetColumnClasses}
                                        setBillingType={setBillingType}
                                        setBillingMethod={setBillingMethod}
                                        handleBilling={handleBilling}
                                    />
                                ) )}
                            </List>

                        </LicoPopupMenu>
                    </div>


                    {
                        showEditTimer
                            ? <div className={`${widgetColumn} ${widgetEnabledClass}`}  >

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    autoFocus={true}
                                    classes={{
                                        root: classes.textFieldRoot
                                    }}
                                    onKeyDown={handleTimerKeyDown}
                                    data-ux={uxConstants.timerEdit}
                                    inputProps={{
                                        "data-lpignore": "true",
                                        className:        classes.inputText
                                    }}
                                    value={editableTime}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onChange={e => setEditableTime( e.target.value )}
                                    onBlur={() => {
                                        setShowEditTimer( false );
                                        // save on blur
                                        finishTimerEdit();
                                    }}
    
                                />
                            </div>
                            :  <div className={`${tinyTimerCss} ${widgetColumn} ${( disabled || readOnlyCompany )  ? widgetDisabledClass : widgetEnabledClass}`}   >
                                <TinyTimer
                                    numSeconds={currentTimerSecs}
                                    maxSeconds={MAX_TIME_LOG_DURATION / 1000}
                                    timerRunning={pausedAt === null}
                                    dataName={uxConstants.timerView}
                                    disabled={disabled || readOnlyCompany}
                                    tooltip={readOnlyCompany ? __( "Times cannot be entered since the company is currently read-only" ) : ''}
                                    onClick={() => {
                                
                                        // editing time may cause change to timelog so we have to send any pending action first
                                        sendPendingNote();

                                        if ( pausedAt === null )
                                            pausePegboardTimer({ timeLogId });
                                        setEditableTime( numSecondsToString( currentTimerSecs, false ) );
                                        setShowEditTimer( true );
                                    }}
                                />
                            </div>
                    }
                    <div className={`${widgetColumn} ${playPauseDisabled ? widgetDisabledClass : widgetEnabledClass}`}  onClick={() => pausedAt === null ? onPause() : onPlay()}>{
                        pausedAt === null 
                            ? <LicoIcon icon="pause" 
                                className={ playPauseIcon }
                            />
                            : <div >
                                {!jobAvailableForPegboard()  ? (
                                    <LicoIcon
                                        icon="play"
                                        className={playPauseIcon}
                                        disabled={true}
                                        tooltip={__( "To unpause this Job you need a free slot on the Pegboard." )}
                                        tooltipPlacement="top"
                                    />
                                ) : (
                                    <LicoIcon icon="play"
                                        className={playPauseIcon}
                                    />
                                )}
                            </div>
                    }
                    </div>
                    <div className={`${widgetColumn} ${disabledSubmitIcon ? widgetDisabledClass : widgetEnabledClass}`}  onClick={disabledSubmitIcon ? null : queueNote} >
                        <LicoIcon   icon="submit" className={submitIcon}  />
                    </div>
                </div>
            </div>
        </div>
    );
};

NoteEntryField.propTypes = {
    noteType:               PropTypes.string.isRequired,
    job:                    PropTypes.object.isRequired,
    autoFocus:              PropTypes.bool.isRequired,
    messageText:                   PropTypes.string.isRequired,
    messageIsEmpty:                PropTypes.bool.isRequired,
    draftMessageLoading:    PropTypes.bool.isRequired,
    disabled:               PropTypes.bool.isRequired,
    currentTimerSecs:       PropTypes.number,
    setNoteText:            PropTypes.func.isRequired,
    queueNoteAction:        PropTypes.func.isRequired,
    sendPendingNote:        PropTypes.func.isRequired,
    setCommentBillable:     PropTypes.func.isRequired,
    billable:               PropTypes.bool,
    setBillingType:         PropTypes.func.isRequired,
    billingTypeId:          PropTypes.string.isRequired,
    billingTypeName:        PropTypes.string.isRequired,
    billingTypes:           PropTypes.array.isRequired,
    showEditTimer:          PropTypes.bool.isRequired,
    editableTime:           PropTypes.string.isRequired,
    validEditTime:          PropTypes.bool.isRequired,
    setShowEditTimer:       PropTypes.func.isRequired,
    setEditableTime:        PropTypes.func.isRequired,
    pausePegboardTimer:     PropTypes.func.isRequired,
    saveTimerValue:         PropTypes.func.isRequired,
    closeEitherCard:        PropTypes.func.isRequired,
    addFile:                PropTypes.func.isRequired,
    setSkipClientEmail:     PropTypes.func.isRequired,
    timeLogId:              PropTypes.string,
    pausedAt:               PropTypes.any,
    readOnlyCompany:        PropTypes.bool.isRequired,
    dragJobToPegboard:      PropTypes.func,
    items:                  PropTypes.array,
    playPausePendingReq:    PropTypes.bool.isRequired,
    completed:              PropTypes.bool.isRequired,
    emailContact:           PropTypes.bool.isRequired,
    skipClientEmail:        PropTypes.bool.isRequired
};

const mapStateToProps = ( state, props ) => {
    const { currentTimerSecs: jobTime, timeLogId, pausedAt } = getCurrentJobTimerState( state, props.job.jobId );

    // if this job has a pending note, the timer reads 0. We set the job time to 0 when we queue the note,
    // but the final patch to the timelog could come in and reset that to the real time.
    const currentTimerSecs = state.jobcard.pendingNote?.jobId === props.job.jobId ? 0 : jobTime;

    const playPausePendingReq = requestIsLoading( state, disablePlayPauseDuringRequests );

    const { billable, billingTypeId, showEditTimer, editableTime, validEditTime, messageText, messageIsEmpty, draftMessageLoading, skipClientEmail } = state.jobcard;
    return {
        billable,
        billingTypeId,
        billingTypeName:        state.jobcard.billingType.idToName[ billingTypeId ],
        billingTypes:           selectNameData( state, integrationNames.BILLING_TYPE, nameDataFormat.OBJECTS ),
        showEditTimer,
        editableTime,
        validEditTime,
        messageText,
        messageIsEmpty,
        draftMessageLoading,
        currentTimerSecs,
        timeLogId,
        pausedAt,
        items:                  state.timelog._items,
        playPausePendingReq,
        emailContact:           state.organisation.live.emailContact,
        skipClientEmail
    };
};

const mapDispatchToProps = {
    queueNoteAction,
    sendPendingNote,
    setCommentBillable,
    setBillingType,
    setShowEditTimer,
    setEditableTime,
    pausePegboardTimer,
    saveTimerValue,
    setNoteText,
    closeEitherCard,
    addFile,
    dragJobToPegboard,
    setSkipClientEmail
};

export default connect( mapStateToProps, mapDispatchToProps )( NoteEntryField );
export { NoteEntryField };
