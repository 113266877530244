/********************************************************************************************************************
 * @file Actions for the user store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setCacheRecord = createAction( 'CACHE.RECORD/SET' );
export const setExistingCacheRecordRequested = createAction( 'CACHE.RECORD_REQUESTED/SET' );
export const setCacheMaxLength = createAction( 'CACHE.MAX_LENGTH/SET' );
export const deleteCacheRecord = createAction( 'CACHE.RECORD/DELETE' );
