import React, { PureComponent } from 'react';

import { MINUTE, SECOND } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { clearCalendars } from '../../redux/actions/index.js';
import { updateTimeThunk } from '../../redux/reducers/meta.js';
import { getAuthState, selectAnyLoading }     from '../../redux/selectors/index.js';
import { rootApp, isLoading } from '../../scss/App.module.scss';
import { dispatchTimerUpdate } from '../calendar/sharedThunks.js';
import { setInit, prepareSystem } from '../login/reducer.js';
import { NavigationRouter } from '../navigation/Router.jsx';

const getUrl = () => {
    try {
        const url = new URL( window.location.href );
        return url.host;
    }
    catch ( err ) {
        console.error( "Error extracting URL from window.location.href", err.stack );
        return "[no-href]";
    }
};

class App extends PureComponent {

    componentDidMount() {
        this.props.setInit({ domain: getUrl(), userInitState: this.props?.userInitState, rememberSession: this.props.rememberSession });
        window.addEventListener( 'beforeunload', () => {
            !this.props.rememberSession && this.props.purgeStore();
        });

        const { user, company, connectorName, prepareSystem, clearCalendars } = this.props;

        // if there's already a real user in state when the app starts,
        // we've reloaded from a previous session so we should do the post-login actions
        if ( user?.userId ) {

            // clear the calendars so they're reloaded as usual
            clearCalendars();
            prepareSystem({ user, company, connectorName });
        }

        // regularly update the common time value in state
        this.minuteIntervalId = setInterval( () => this.props.updateTimeThunk(), MINUTE );
        this.secondIntervalidId = setInterval( () => this.props.dispatchTimerUpdate(), SECOND );
    }

    componentWillUnmount() {
        clearInterval( this.minuteIntervalId );
        clearInterval( this.secondIntervalidId );
    }

    render() {
        return <div className={`${rootApp} ${this.props.isLoading ? isLoading : ''}`}>
            <NavigationRouter />
        </div>;
    }
}

App.propTypes = {
    setInit:                PropTypes.func.isRequired,
    rememberSession:        PropTypes.bool.isRequired,
    purgeStore:             PropTypes.func.isRequired,
    updateTimeThunk:        PropTypes.func.isRequired,
    isLoading:              PropTypes.bool.isRequired,
    user:                   PropTypes.object,
    company:                PropTypes.object,
    connectorName:          PropTypes.string,
    prepareSystem:          PropTypes.func.isRequired,
    clearCalendars:         PropTypes.func.isRequired,
    dispatchTimerUpdate:    PropTypes.func.isRequired,
    userInitState:          PropTypes.object
};

const mapStateToProps = state => {

    const { token, rememberSession, userInitState } = getAuthState( state );

    return {
        isLoading:      selectAnyLoading( state ),
        isAuth:         !!token,
        token,
        rememberSession,
        userInitState,
        user:           state.user,
        company:        state.company,
        connectorName:  state.login.connectorName
    };
};

const mapDispatchToProps = {
    setInit,
    updateTimeThunk,
    prepareSystem,
    clearCalendars,
    dispatchTimerUpdate
};

export default connect( mapStateToProps, mapDispatchToProps )( App );
export { App };
