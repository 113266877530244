
import kindOf from 'kind-of';
import { isInt } from './type-helpers.js';

/**
 * @param {number} min
 * @param {number} max
 * @return {function(number): boolean}
 */
const inRange       = ( min, max ) => n => n >= min && n <= max;

/**
 * @param {number} n
 * @return {number|null}
 */
const validInt = n => isInt( n ) ? n : null;

/**
 * Returns a random integer between `min` (inclusive) and `max` (exclusive).
 *
 * If only one parameter is specified, returns a random integer between `0` and the specified number.
 *
 * @param {number} [min=0] - The minimum possible value.
 * @param {number} [max=Infinity] - The maximum possible value + 1.
 * @returns {number} A random integer between min and max.
 */
const rand = ( min, max ) => kindOf( max ) !== 'number' ? ~~( Math.random() * min ) : ~~( Math.random() * ( max - min ) ) + min;

/**
 * Returns the next highest power of 2 that is less than or equal to the specified number.
 *
 * @param {number} n - The number to evaluate.
 * @returns {number} The next highest power of 2.
 */
const nextPowerOf2 = n => ( ( n = ( n = ( n = ( n |= n >>> 1 ) | n >>> 2 ) | n >>> 4 ) | n >>> 8 ) | n >>> 16 ) + 1;

/**
 * Returns the previous highest power of 2 that is less than or equal to the specified number.
 *
 * @param {number} n - The number to evaluate.
 * @returns {number} The previous highest power of 2.
 */
const prevPowerOf2 = n => ( ( n = ( n = ( n = ( n |= n >>> 1 ) | n >>> 2 ) | n >>> 4 ) | n >>> 8 ) | n >>> 16 ) - ( n >>> 1 );

/**
 * Returns the integer base `2` logarithm of the specified number.
 *
 * @param {number} v - The number to evaluate.
 * @returns {number} The base 2 logarithm of the specified number.
 */
function log2( v )
{
    let r;

    if ( v > 0xffff ) { r = 16; v >>>= 16; }
    if ( v > 0xff ) { r |= 8; v >>>= 8; }
    if ( v > 0xf ) { r |= 4; v >>>= 4; }
    if ( v > 0x3 ) { r |= 2; v >>>= 2; }

    return r | ( v >>> 1 );
}

/**
 * Integer `floor` function, rounding integer down to nearest power of two.
 *
 * @param {number} x
 * @return {number}
 */
const flp = x => {
    x |= x >>> 1;
    x |= x >>> 2;
    x |= x >>> 4;
    x |= x >>> 8;
    x |= x >>> 16;
    return x - ( x >>> 1 );
};

/**
 * Integer `ceil` function, rounding integer up to nearest power of two.
 * @param x
 * @return {*}
 */
const clp = x => {
    --x;
    x |= x >>> 1;
    x |= x >>> 2;
    x |= x >>> 4;
    x |= x >>> 8;
    x |= x >>> 16;
    return x + 1;
};

/**
 * This is a function that counts the number of bits that are set in an integer value.
 *
 * It does this by performing a series of bitwise operations on the input value, starting with a series of shifts
 * and logical operations that reduce the bit count by half. The final step is a multiplication by a constant that
 * counts the number of set bits in each 8-bit group.
 *
 * The code is written in a way that is easy to understand and maintain, by using bitwise operators and clear variable
 * names. The use of constants and bitmasks helps to make the code efficient and fast.
 *
 * Despite the reassuring statements above, let's take a closer look at the code, nonetheless.
 *
 * ```
 * v -= ( ( v >> 1 ) & 0x55555555 );
 * ```
 *
 * This line begins by shifting the bits of `v` one place to the right. The `&` operation with `0x55555555` then
 * zeroes out every other bit. By subtracting this from the original `v`, we count the number of bits set in each
 * 2-bit segment of `v`.
 *
 * ```
 * v = ( v & 0x33333333 ) + ( ( v >> 2 ) & 0x33333333 );
 * ```
 *
 * Continuing from the previous operation, we now shift `v` two places to the right and perform the `&` operation with
 * `0x33333333` to zero out every other 2-bit segement. Adding this to the result of the first line allows us to count
 * how many of the two bits in each 4-bit segment are set.
 *
 * ```
 * return ( ( v + ( v >> 4 ) & 0xF0F0F0F ) * 0x1010101 ) >> 24;
 * ```
 *
 * Finally, this line does a similar operation as the second line, but for groups of 8 bits or 1 byte. Each byte will
 * hold the count of the set bits in that byte. We then multiply by `0x1010101` which allows us to sum the counts in
 * each byte, storing it in the most significant byte of the result. Right shifting `24` bits brings this byte to the
 * least significant position, giving us our final count of set bits in `v`.
 *
 * It's important to note that this algorithm works for 32-bit integers, hence, if `v` has more bits, the behavior is
 * undefined.
 *
 * The provided function `countUnset` is simply using bitwise NOT operator (`~`) to flip all bits in `v`, turning set
 * bits to unset and vice versa. Then, it uses the `countSet` function to count the newly set bits, effectively
 * counting the original number of unset bits in `v`.
 *
 * @param {number} v
 * @return {number}
 */
const countSet = v => {
    v -= ( ( v >> 1 ) & 0x55555555 );
    v = ( v & 0x33333333 ) + ( ( v >> 2 ) & 0x33333333 );
    return ( ( v + ( v >> 4 ) & 0xF0F0F0F ) * 0x1010101 ) >> 24;
};

const countUnset = v => countSet( ~v );

/**
 * This code is implementing a De Bruijn based algorithm for finding the number of trailing zeroes in an integer.
 *
 * Let's go through it line by line:
 *
 * A De Bruijn sequence deBruijn array of length 32 is defined.
 *
 * @type {number[]}
 */
const deBruijn = new Array( 32 );

/**
 * A for-loop is used to populate deBruijn based on the product of a magic constant 0x077CB531 and power of 2 1 << i,
 * where i varies from 0 to 31 (inclusive). The result is right-shifted 27 bits (>>> 27).
 *
 * The for loop is generating an array using a magic number and some bit manipulation. The magic number 0x077CB531 is a
 * De Bruijn constant which has properties useful in bit manipulation algorithms.
 *
 * It's important to note that >>> is the unsigned right shift operator. This operator shifts the first operand the
 * specified number of bits to the right. Excess bits shifted off to the right are discarded.
 */
for ( let i = 0; i < 32; ++i ) deBruijn[ ( 0x077CB531 * ( 1 << i ) ) >>> 27 ] = i;

/**
 * The `ctz` (count trailing zeroes) function calculates and returns the number of trailing zeroes for a given
 * integer `x`. Here, bit manipulation is used to isolate the least significant 1-bit (`x & -x`) which is then
 * multiplied by the De Bruijn constant and shifted `27` places to get the index in our `deBruijn` array, returning
 * the number of trailing zeroes.
 *
 * In the expression `x & -x`, `-x` is equal to `~x + 1`, its two's complement. The application of the bitwise
 * AND operator to `x` and `-x` yields the number isolated with the least significant 1-bit.
 *
 * It creates a function `ctz( x )` that provides the count of trailing zeroes in an integer `x`.
 *
 * Please note that it operates based on 32-bit integers so this may have unexpected results for numbers outside the
 * signed 32-bit integer range.
 *
 * @param {number} x
 * @return {number}
 */
const ctz = x => deBruijn[ ( ( x & -x ) * 0x077CB531 ) >>> 27 ];

/**
 * The code is a function that counts the number of leading zeros (also known as "clz") in a 32-bit integer. The
 * algorithm works by performing a series of bitwise operations on the input value, starting with a series of shifts
 * and logical operations that reduce the bit count by half. The final step is a multiplication by a constant that
 * counts the number of set bits in each 8-bit group.
 *
 * The code is written in a straightforward and easy to understand manner, using bitwise operators and clear variable
 * names. The use of constants and bitmasks helps to make the code efficient and fast. The code is written in a way
 * that is easy to maintain and modify, as it is well structured and organized.
 *
 * @param {number} x
 * @return {number}
 */
function clz( x )
{
    let y, n = 32;

    y = x >>> 16;
    if ( y !== 0 ) { n -= 16; x = y; }

    y = x >>> 8;
    if ( y !== 0 ) { n -= 8; x = y; }

    y = x >>> 4;
    if ( y !== 0 ) { n -= 4; x = y; }

    y = x >>> 2;
    if ( y !== 0 ) { n -= 2; x = y; }

    y = x >>> 1;
    if ( y !== 0 ) return n - 2;

    return n - x;
}

/**
 * The code is a part of high performance computing and is typically used in bitwise programming. This function is used
 * to find the position of the first zero byte in a 32-bit integer. This is a non-trivial bit manipulation function.
 *
 * Here's how it works:
 *
 * The function `firstZeroByte(x)` takes an integer `x` as input.
 *
 * 1. The first line of code `let y = ((x & 0x7f7f7f7f) + 0x7f7f7f7f) | x;` is an operation to set a bit in the highest
 *    position in each byte of `x` that was originally not zero. In this case, `0x7f` is a byte with all bits set except
 *    the highest one. Using bit-wise AND with `0x7f` leaves the bytes with the high bit off regardless of the original
 *    content of the byte. We increase each byte by 0x7f so bytes that were non-zero overflow and result in the top bit
 *    set to 1, while bytes that were zero to start with remain zero. This is combined with the original `x` using a
 *    binary OR to ensure that even if all the lower bits in a byte were set (which would make that byte equal to `0x7f`),
 *    the addition wraps the byte to zero, so putting the top bit back guarantees we didn't lose any initially non-zero
 *    bytes.
 * 2. The following four lines are shifting bits and bitwise AND operations to determine the number of non-empty bytes
 *    `t1`, `t2`, `t3`, `t4`.
 * 3. The bitwise right shift operator `>>>` by `31`, `23`, `15`, `7` is used to shift the bits of binary
 *    representation. The reason for using these specific numbers is connected with the byte (8-bit) nature of our data.
 * 4. The bitwise AND operator is used with `t1`, `t2`, `t3`, and `t4` to filter our results.
 * 5. Finally, the function returns the sum of `t1`, `t2`, `t3`, and `t4`, which indicates the position of the first
 *    zero byte.
 *
 * This kind of algorithm requires a fairly advanced understanding of bitwise operations and the binary number system.
 *
 * Please note that this function will return the count of nonzero bytes found until it hits the first zero byte, not
 * the position. It's basically useful when we need to find out the byte-length of a string that ends with a zero byte
 * (like C-style strings). Julian: And other things, too. Sheesh.
 *
 * @param {number} x
 * @return {number}
 */
function firstZeroByte( x )
{
    let y = ( ( x & 0x7f7f7f7f ) + 0x7f7f7f7f ) | x; // Leading 1 on nonzero bytes.

    const t1 = y >>> 31;                // tl = a.
    const t2 = ( y >>> 23 ) & t1;       // t2 = ab.
    const t3 = ( y >>> 15 ) & t2;       // t3 = abc.
    const t4 = ( y >>> 7 ) & t3;        // t4 = abcd.

    return t1 + t2 + t3 + t4;
}

function reverseBits( x )
{
    x = ( x & 0x55555555 ) << 1 | ( x >>> 1 ) & 0x55555555;
    x = ( x & 0x33333333 ) << 2 | ( x >>> 2 ) & 0x33333333;
    x = ( x & 0x0F0F0F0F ) << 4 | ( x >>> 4 ) & 0x0F0F0F0F;
    x = ( x << 24 ) | ( ( x & 0xFF00 ) << 8 ) | ( ( x >>> 8 ) & 0xFF00 ) | ( x >>> 24 );
    return x & 0xffffffff;
}

export {
    inRange,
    rand,
    validInt,
    nextPowerOf2,
    prevPowerOf2,
    log2,

    countSet,
    countUnset,
    ctz,
    clz,
    clp,
    flp,

    reverseBits,
    firstZeroByte
};
