import React from 'react';

import PropTypes from "prop-types";

import * as scss from "../../../scss/Asset.module.scss";
import { LicoIcon } from "../../common/index.js";

const {  association, Vector, associationContent, assetLabelList, close } = scss;

const AssetListItem = ({ label, onDelete, onClick }) => {
    return (
        <div className={ association }>
            <div className={associationContent} >
                <LicoIcon icon={"gripDotsVertical"} className={Vector} ></LicoIcon>
                <div className={assetLabelList}  onClick={onClick} >{label}</div>
            </div>
            <div><LicoIcon icon={"close"} onClick={onDelete} className={close}></LicoIcon> </div>
        </div>
    );
};

AssetListItem.propTypes = {
    label:    PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClick:  PropTypes.func.isRequired
    
};

export { AssetListItem };
