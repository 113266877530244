/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { INVALID } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';


import { UX_REGISTRATION_ENGINEER_NAME, UX_REGISTRATION_PASSWORD, UX_USER_REGISTRATION_CREATE_ACCOUNT_BUTTON } from '../../ux-constants.js';
import { setFormValueInvitation, setTouchedInvitation } from '../../redux/actions/index.js';
import { selectIsCheckInviteLoading } from '../../redux/selectors/index.js';
import { greeting, medium, fieldLabel, buttonRow, subdomainText } from '../../scss/AcceptInvitation.module.scss';
import { __, _$ } from '../../utils/i18n.jsx';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { Loader, LicoLink, LicoTextField, LicoButton, P  } from "../common/index.js";
import PasswordField from '../common/PasswordField.jsx';
import PreAuthLayout from '../layouts/PreAuthLayout.jsx';
import { LOGIN } from '../navigation/routes.js';

import { getInvitationStatus, acceptInvitation } from './reducer.js';

function useQuery() {
    return new URLSearchParams( useLocation().search );
}

const AcceptInvitation = ({
    emailAddress,
    password,
    strength,
    engineerName,
    fieldError,
    fieldTouched,
    setFormValueInvitation,
    setTouchedInvitation,
    getInvitationStatus,
    invitationData,
    acceptInvitation,
    inviteAccepted
}) => {
    const queryParams    = useQuery();
    const invitationCode = queryParams.get( "invitationCode" ) || "";

    const isCheckLoading = useSelector( selectIsCheckInviteLoading );

    useEffect( () => {
        getInvitationStatus( invitationCode );
    }, [ getInvitationStatus, invitationCode ]);
    
    if ( !invitationCode || inviteAccepted )
        return <Redirect to={LOGIN}/>; // Or perhaps redirect them to the main register

    return <PreAuthLayout>
        <BannerLogo />
        {invitationData && <P align="center" className={subdomainText}> {invitationData.namespace + ".licorice.io"}</P>}
        {( !invitationData || isCheckLoading )
            ? <Loader/>
            : <>
                {invitationData.status === INVALID
                    ? invitationData.reason === "active"
                        ? <P>{_$( "This invitation has already been accepted. You can login {here}", { here: <LicoLink to={LOGIN}>here.</LicoLink> })}</P>
                        : <P>{__( "This is not a valid Licorice invitation." )}</P>
                    : <>
                        <P className={greeting}>{_$( "Join {sender} on Licorice", { sender: invitationData.sender })}</P>

                        <LicoTextField name="emailAddress" type="email" label={__( "Email" )} value={emailAddress} disabled={true} />

                        <PasswordField
                            data-ux={UX_REGISTRATION_PASSWORD}
                            password={password}
                            strength={strength}
                            autoFocus
                            fieldTouched={fieldTouched}
                            fieldError={fieldError}
                            setFormValue={setFormValueInvitation}
                            setTouched={setTouchedInvitation}
                        />

                        <br/>

                        <LicoTextField
                            name="engineerName"
                            // label={__( "This is how your name will appear to others" )}
                            labelElement={<p className={`${medium} ${fieldLabel}`}>{__( "This is how your name will appear to others" )}</p>}
                            data-ux={UX_REGISTRATION_ENGINEER_NAME}
                            value={engineerName}

                            helperText={fieldTouched.engineerName && fieldError.engineerName}
                            error={!!fieldError.engineerName && fieldTouched.engineerName}

                            onChange={e => setFormValueInvitation({ field: e.target.name, value: e.target.value })}
                            onBlur={e => setTouchedInvitation( e.target.name )}
                        />

                        <div className={buttonRow}>
                            <LicoButton
                                data-ux={UX_USER_REGISTRATION_CREATE_ACCOUNT_BUTTON}
                                onClick={() => {
                                    if ( strength.score[ 0 ] === 4 ) {
                                        acceptInvitation({
                                            password:       password,
                                            invitationCode: invitationCode,
                                            name:           engineerName
                                        });
                                    }
                                }}
                                disabled={password.length === 0 || engineerName.length === 0}
                            >{__( "Create Account" )}</LicoButton>
                        </div>

                    </>
                }
            </>}

    </PreAuthLayout>;
};

AcceptInvitation.propTypes = {
    emailAddress:           PropTypes.string,
    password:               PropTypes.string,
    strength:               PropTypes.object,
    engineerName:           PropTypes.string,
    fieldError:             PropTypes.object,
    fieldTouched:           PropTypes.object,
    setFormValueInvitation: PropTypes.func.isRequired,
    setTouchedInvitation:   PropTypes.func.isRequired,
    getInvitationStatus:    PropTypes.func.isRequired,
    invitationData:         PropTypes.object,
    acceptInvitation:       PropTypes.func.isRequired,
    inviteAccepted:         PropTypes.bool
};

const mapStateToProps = state => ({
    fieldError:         state.invitation.fieldError,
    fieldTouched:       state.invitation.fieldTouched,
    invitationData:     state.invitation.invitationData,
    inviteAccepted:     state.invitation.inviteAccepted,
    emailAddress:       state.invitation.emailAddress,
    engineerName:       state.invitation.engineerName,
    password:           state.invitation.password,
    strength:           state.invitation.strength
});

const mapDispatchToProps = {
    setFormValueInvitation,
    setTouchedInvitation,
    getInvitationStatus,
    acceptInvitation
};

export { AcceptInvitation };

export default connect( mapStateToProps, mapDispatchToProps )( AcceptInvitation );
