import React, { useState, useCallback, useEffect } from 'react';

import { nop, numMinutesToString, stringToNumMinutes } from '@licoriceio/utils';
import PropTypes from 'prop-types';

import { dispatchBlurThunk, dispatchChangeAction } from '../../../../redux/reducerUtil.js';

import { LicoInlineTextField } from './LicoInlineTextField.jsx';

export const LicoTimeTextField = props => {
    const {
        label,
        inputClassName = '',
        initialValue,
        formPackage,
        slicePackage,
        name,
        ...rest
    } = props;
    let { onBlur = nop, onChange = nop } = props;
    const [ , setNumberValue ] = useState( initialValue );
    const [ textValue, setTextValue ] = useState( numMinutesToString( initialValue ) );

    useEffect( () => void setTextValue( numMinutesToString( initialValue ) ), [ initialValue ]);

    // we don't send external change events on each key, we wait for blur so we can parse the whole field
    const handleTextChange = useCallback( ( e ) => {
        onChange( e );
        setTextValue( e.target.value );
    }, [ onChange ]);

    const handleBlur = useCallback( event => {
        let numMinutes = stringToNumMinutes( textValue );
        if ( numMinutes < 0 )
            numMinutes = 0;
        setNumberValue( numMinutes );
        if ( formPackage ) {
            formPackage.onChange({ id: formPackage.id, field: name, value: numMinutes });
            formPackage.onBlur( event );
        }
        else if ( slicePackage ) {
            dispatchChangeAction( slicePackage, { updates: { [ name ]: numMinutes } });
            dispatchBlurThunk( slicePackage, { field: name });
        }
        else
            onBlur( numMinutes );
        setTextValue( numMinutesToString( numMinutes ) );

    }, [ formPackage, name, onBlur, textValue, slicePackage ]);

    return <LicoInlineTextField
        label={label}
        placeholder=' '
        inputClassName={inputClassName}
        value={textValue}
        onChange={handleTextChange}
        onBlur={handleBlur}
        {...rest}
    />;
};

LicoTimeTextField.propTypes = {
    onBlur:         PropTypes.func,
    initialValue:   PropTypes.number,
    formPackage:    PropTypes.object,
    slicePackage:   PropTypes.object,
    label:          PropTypes.string,
    onChange:       PropTypes.func,
    inputClassName: PropTypes.string,
    name:           PropTypes.string
};
