import React from 'react';

import { List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { NavListItem } from './NavListItem.jsx';

const useStyles = makeStyles({
    root: ({
        backgroundColor: "var(--lightgrey-3)"
    })
});

export const NavList = ({ listItems, ...rest }) => {
    const classes = useStyles( rest );
    const location = useLocation();

    return <List className = {classes.root} disablePadding={true}>
        {listItems.map( ( v, i ) => <NavListItem key={i} label={v.label} to={v.to} selected={location.pathname === v.to} testTag={v.testTag}/> )}
    </List>;
};

NavList.propTypes = {
    listItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            to:    PropTypes.string.isRequired
        })
    )
};
