/** ******************************************************************************************************************
 * @file Integration components that were moved here cleanup purposes and to make more general once we start
 * integrating with more providers.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 02-Apr-2021
 *********************************************************************************************************************/

import React from 'react';

import { ADD, DELETE, integrationNames } from '@licoriceio/constants';
import { isObject } from '@licoriceio/utils';

import { 
    button, buttonAlign, narrowField, wideField, boxLabel, serviceBoardTooltip, SBTheading, SBTError, integrationStatusFailed, integrationStatusWarning
} from '../../../scss/IntegrationForm.module.scss';
import { __ } from '../../../utils/i18n.jsx';
import LicoButton from '../../common/atomic/form/LicoButton.jsx';
import { LicoSelect, LicoTextField, H2, ChipBox } from '../../common/index.js';

const handlers = {};
const blurs = {};

const getValue = ( fieldValue, { name, key, index }) => {
    const value = fieldValue[ name ];

    return Array.isArray( value ) && index !== undefined ? value[ index ] : isObject( value ) && key !== undefined ? value[ key ] : value;
};

// this creates field definitions, which are then used to generate the actual field via makeConnectField
const makeConnectFields  = ({ connectionFields = [], setIntegrationFieldValue, setIntegrationFieldTouched, fieldValue, fieldTouched, formErrors, masterDisable }) =>
    connectionFields.map( ({ name, label }) => ({
        label,
        name,
        masterDisable,
        handler:        handlers[ name ] || ( handlers[ name ] = v => setIntegrationFieldValue({ name, value: v }) ),
        onBlur:         blurs[ name ] || ( blurs[ name ] = () => setIntegrationFieldTouched( name ) ),
        error:          fieldTouched[ name ] ? !!formErrors[ name ] : false,
        helperText:     fieldTouched[ name ] ? formErrors[ name ] : null,
        value:          fieldValue[ name ]
    }) );

const makeConnectField = ( labelClass, { label, name, handler, onBlur, value, masterDisable, error, helperText }) => (
    <LicoTextField
        label={label}
        name={name}
        key={name}
        type={/private/i.test( label ) ? 'password' : 'text' }
        data-ux={name}
        error={error}
        helperText={helperText}
        onChange={e => handler( e.target.value )}
        onBlur={e => onBlur( e.target.value )}
        value={value}
        placeholder=""
        disabled={!!masterDisable}
        inlineLabel={true}
        labelClassName={labelClass}
        inputClassName={/Key/.test( label ) ? narrowField : wideField}
        size="small"
        autoComplete="on"
    />
);

const makeServiceBoardTooltip = ( value, info ) => <div className={serviceBoardTooltip}>
    { info[ value ].length 
        ? <>
            <p className={SBTheading}>{__( "Valid Service Boards" )}</p>
            {info[ value ].map( board => <p key={board.providerNameId}>{board.label}</p> ) }
        </>
        : <p className={`${SBTheading} ${SBTError}`}>{__( "No valid Service Boards" )}</p>}
</div>;

const makeJobStatusTooltip = ( value, settings ) => <div className={serviceBoardTooltip}>
    { settings.boardStatusNames[ value ]?.length
        ? <>
            <p className={SBTheading}>{__( "Valid Job Statuses" )}</p>
            {settings.boardStatusNames[ value ]
                .toSorted()
                // .sort( ( a, b ) => a.localeCompare( b ) )
                .map( status => <p key={status}>{status}</p> )}
        </>
        :  <p className={`${SBTheading} ${SBTError}`}>{__( "No valid Job Statuses" )}</p>}
</div>;

const makeBoardField = ( labelClass, extendedDropdown, handler, masterDisable, preKey ) => {

    const { label, name, sectionName, fieldValue, settings } = extendedDropdown;
    const value = getValue( fieldValue, { name }) ?? '';

    // this is designed for the job status board warning, but is intended to be somewhat generic
    let warning;
    if ( extendedDropdown.warningCallback ) 
        warning = extendedDropdown.warningCallback({ value, extendedDropdown });

    return <div key={preKey}>
        <LicoSelect
            label={label}
            tooltip={extendedDropdown.serviceBoardInfo 
                ? makeServiceBoardTooltip( value, extendedDropdown.serviceBoardInfo )
                : sectionName === integrationNames.BOARD 
                    ? makeJobStatusTooltip( value, settings )
                    : null} 
            options={extendedDropdown.options || []}
            onSelect={( event, option ) => handler( option?.id )}
            labelClassName={labelClass}
            inputClassName='slim'
            disabled={masterDisable}
            externalValue={value}
            inlineLabel
        />
        {extendedDropdown.options.length === 0 && <span className={boxLabel}>
            <span className={labelClass} />
            <span className={integrationStatusFailed}>
                {extendedDropdown.emptyListMessage ?? __( "There are no options for this list" ) }
            </span>
        </span>}
        {warning && <span className={boxLabel}>
            <span className={labelClass} />
            <span className={integrationStatusWarning}>
                {warning}
            </span>
        </span>}

    </div>;
};

const makeChipBox = ( labelClass, d, handler, masterDisable, preKey ) => {

    return <label 
        className={boxLabel}
        key={preKey}
    >
        <H2 className={labelClass} gutterBottom={false}>
            {d.label}
        </H2>
        <ChipBox 
            existingChips={d.value}
            addChipAction={item => handler( item, ADD )}
            removeChipAction={item => handler( item, DELETE )}
            fullChipList={d.options}
            allowEmptyList
            disabled={masterDisable} />
    </label>;
};


// eslint-disable-next-line max-params
const integrationButton = ( disabled, userCompanyId, connectLabel, clicker, testTag ) => (
    <div className={buttonAlign}>
        <span
            className={button}>
            <LicoButton
                type="button"
                disabled={disabled}
                onClick={() => clicker({ userCompanyId })}
                data-ux={testTag}
            >
                {connectLabel}
            </LicoButton>
        </span>
    </div>
);

// make the board list based on the current values in the billing fields and the service board lists for those fields;
// eligible boards must be present in every list.
const makeBoardFilter = ( boards, billingDropdowns, fieldValue ) => boards.filter( board => {

    // find billing fields with board info
    for ( const field of billingDropdowns ) {
        if ( field.serviceBoardInfo ) {

            // find current value of that field
            const value = fieldValue[ field.licoriceNameId ];

            // serviceBoardInfo[ value ] is a list of boards; the board id must appear in that list to be valid
            // (being missing from any field is enough to disqualify a board)
            if ( !field.serviceBoardInfo[ value ].find( ({ providerNameId }) => providerNameId === board.providerNameId ) )
                return false;
        }
    }

    // we checked this board against all the fields and either were found in the current list or there was no list
    return true;
}).map( board => board.providerNameId );

export {
    getValue,
    makeConnectField,
    makeConnectFields,
    makeBoardField,
    makeChipBox,
    integrationButton,
    makeBoardFilter
};
