/********************************************************************************************************************
 * @file Actions for the invitation store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setTouchedInvitation = createAction( 'ACCEPT_INVITATION.TOUCHED/SET' );
export const setFormValueInvitation = createAction( 'ACCEPT_INVITATION.FORM_VALUE/SET' );
export const setInvitationData = createAction( 'ACCEPT_INVITATION.INVITATION_DATA/SET' );
export const setInvitationAccepted = createAction( 'ACCEPT_INVITATION.INVITATION_ACCEPTED/SET' );
