import React from 'react';

import { ADD, IntegrationStages, integrationFieldType } from '@licoriceio/constants';
import { CircularProgress, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { uri, POST, GET } from '../../../constants.js';
import { UX_INTEGRATION_CONNECT, UX_INTEGRATION_START } from '../../../ux-constants.js';
import { setIntegrationFieldValue, setIntegrationFieldTouched } from '../../../redux/actions/index.js';
import { getUriErrors } from '../../../redux/reducers/error.js';
import { selectSystemReady, createMultiLoadingSelector } from '../../../redux/selectors/index.js';
import {
    root,
    form,
    formLabel,
    disabledLabel,
    heading,
    helpline,
    integrationStatus,
    integrationStatusComplete,
    integrationStatusFailed,
    integrationStatusInProgress,
    integrator,
    smallButtonText,
    syncIcon,
    infoRed,
    infoCool,
    prePost,
    boxInput,
    boxLabel,
    boxTopRow,
    boxTextField,
    boxClose
} from '../../../scss/IntegrationForm.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { FormErrorPanel, LicoIcon, P, Foldable } from "../../common/index.js";
import IntegrationDialog from '../../common/IntegrationDialog.jsx';
import PreventTransitionPrompt from '../../common/PreventTransitionPrompt.jsx';
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import { integrationButton, makeBoardField, makeConnectField, makeConnectFields, makeChipBox, makeBoardFilter, getValue } from './integration-helpers.jsx';
import { integrationDataFields } from './integration-layouts.js';
import IntegrationProgress from './IntegrationProgress.jsx';
import { connectToProvider } from './requests.js';
import { refreshProviderSettings, updateMapValueThunk, startIntegration, initStandalone } from './thunks.js';

// this will move to the providers table when we have more than one provider
const connectwiseHowToURL = "https://licorice.io/news/how-to-integrate-licorice/connectwise";

const BoxedInput = ({ text, value, onChange, onClose }) => ( 
    <div className={boxInput} onKeyDown={e => e.key === "Enter" && onClose( true )}>
        <div className={boxTopRow}>
            <span className={boxLabel}>{text}</span>
            <span className={boxClose} onClick={() => onClose( false )}>
                <LicoIcon icon="close" />
            </span>
        </div>
        <TextField 
            className={boxTextField} 
            value={value} 
            autoFocus={true} 
            fullWidth={true} 
            variant="outlined"
            size="small"
            onChange={e => onChange( e.target.value )} 
        />
    </div> );

BoxedInput.propTypes = {
    text:     PropTypes.string.isRequired,
    value:    PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onClose:  PropTypes.func.isRequired
};

const loadingSelector = createMultiLoadingSelector([ 
    POST + uri.CONNECT_TO_PROVIDER, 
    GET + uri.INTEGRATION_SETTINGS,
    GET + uri.REFRESH_CONNECTION,
    POST + uri.PERFORM_INTEGRATION
]);


const IntegrationForm = props => {
    const {
        error,
        userCompanyId,
        progress,
        submissionError,
        fieldValue,

        // only used in makeConnectFields but in propTypes to keep as required
        // eslint-disable-next-line no-unused-vars
        fieldTouched,

        provider,
        settings,
        sections,
        dependentOn,
        loading,
        masterDisable,
        connectFieldsValid,
        connectFieldsSame,
        connectToProvider,
        refreshProviderSettings,
        updateMapValueThunk,
        startIntegration,
        history,
        initStandalone,
        systemReady
    } = props;

    if ( progress && !error && progress.stage <= progress.stageLast && !progress.goneHome ) return <IntegrationProgress progress={progress} />;

    const [ statusColorClass, statusMessage ] = !progress && !provider?.integrated
        ? [ "", "" ]
        : progress?.stage === IntegrationStages.DONE || provider?.integrated
            ? [ integrationStatusComplete, __( "Integration enabled" ) ]
            : progress?.stage === IntegrationStages.ERROR
                ? [ integrationStatusFailed, error?.message ?? __( "Integration failed. Please contact technical support." ) ]
                : progress
                    ? [ integrationStatusInProgress, __( "Integration in progress..." ) ]
                    : [ "", "" ];

    const trustConnection = ( provider?.connected && connectFieldsSame ) || provider?.integrated;
    const labelText = trustConnection ?  __( "Refresh" ) :  __( "Connect" );
    const connectLabel = <span className={smallButtonText}><LicoIcon className={syncIcon} size="1x" icon="sync" /> {labelText}</span>;
    const labelClass = `${formLabel} ${masterDisable ? disabledLabel : ""}`;

    const connectFields = makeConnectFields({ ...props, masterDisable });
    const makeConnect = arg => makeConnectField( labelClass, arg );

    const makeInfo = info =>
        info ? [ <div key="pre" className={`${prePost} ${info.color === 'red' ? infoRed : infoCool}`}>{info.text} <LicoIcon icon={info.iconAfter} /></div> ] : [];

    // this is the fixed layout information that dictates section order, labels, etc
    const dataSections = provider?.connectorName && settings ? integrationDataFields[ provider.connectorName ] : {};
    const sectionNames = Object.keys( dataSections );

    // make the board filter out here so we can disable the start button when it's empty
    const boardFilter = settings && makeBoardFilter( settings.options.board, sections.billing.dropdowns, fieldValue );
    
    const makeDropdown = ( dropdown, fieldIndex, sectionName ) => {

        const { name, licoriceNameId, optionType, mode } = dropdown;

        // dependencies; if this is a dependent field, we should filter the list of options according to the
        // current value for the controlling field
        const masterField = dependentOn?.[ optionType ];
      
        const filterList = masterField 
            ? settings.dependencies[ masterField.optionType ][ optionType ][ fieldValue[ masterField.licoriceNameId ] ] || []
            : optionType === 'board'

                // make the board list based on the current values in the billing fields and the service board lists for those fields;
                // eligible boards must be present in every list.
                ? boardFilter
                : null;

        return mode === integrationFieldType.LABEL_CHIP
            ? makeChipBox(
                labelClass, 
                {
                    label:      dataSections[ sectionName ].overrideLabel?.[ dropdown.label ] ?? dropdown.label, 
                    options:    dropdown.options ?? settings.options[ optionType ]?.map( ({ label, providerNameId, id }) => ({ label, id: providerNameId ?? id }) ), 
                    value:      getValue( fieldValue, { name }) ?? ''
                },
                ( option, action ) => 
                    updateMapValueThunk( 
                        licoriceNameId, 
                        action === ADD 
                            ? { ...option, optionType, mode } 
                            : { label: option, optionType, mode }, 
                        sectionName, 
                        action ),
                masterDisable, 
                `outer${sectionName}${name}`
            )
            : makeBoardField(
                labelClass, 
                {
                    name,
                    fieldValue,
                    settings,
                    sectionName,
                    label:              dataSections[ sectionName ].overrideLabel?.[ dropdown.label ] ?? dropdown.label, 
                    options:            sections[ sectionName ].options ?? settings.options[ optionType ]
                        ?.filter( ({ providerNameId }) => !filterList || filterList.includes( providerNameId ) )
                        .map( ({ label, providerNameId, id }) => ({ label, id: providerNameId ?? id }) ), 
                    serviceBoardInfo:   dropdown.serviceBoardInfo,
                    emptyListMessage:   dataSections[ sectionName ].emptyListMessage,
                    warningCallback:    dataSections[ sectionName ].warningCallback
                },
                id => updateMapValueThunk( licoriceNameId, id, sectionName ), 
                masterDisable, 
                `outer${sectionName}${name}`
            );
    };

    

    return <SettingsLayout>

        <PreventTransitionPrompt 
            when={!systemReady}
            history={history}
            saveAction={() => initStandalone( history )}
            SingleActionDialog={IntegrationDialog}
        />

        <form>

            <div className={root}>

                <P className={heading}>Integrations</P>
                <P className={helpline}>
                    {__( "For step-by-step instructions see" )}&nbsp;
                    <a
                        className={helpline}
                        href={connectwiseHowToURL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {connectwiseHowToURL}
                    </a>
                </P>

                <FormErrorPanel message={submissionError?.errors} />

                {
                    userCompanyId && provider && <div className={form}>
                        <div className={integrator}>{provider.displayName}
                            {
                                statusMessage !== ""
                                    ? <P className={`${integrationStatus} ${statusColorClass}`}>{statusMessage}</P>
                                    : null
                            }
                        </div>

                        {connectFields.map( makeConnect )}
                        {
                            integrationButton(
                                !connectFieldsValid || loading, userCompanyId, connectLabel, 
                                () => trustConnection
                                    ? refreshProviderSettings( provider.connectorName )
                                    : connectToProvider( provider.connectorName, fieldValue ), 
                                UX_INTEGRATION_CONNECT
                            )
                        }

                        {
                            trustConnection && settings 
                                ? sectionNames.map( sectionName => (
                                    <Foldable key={`outer${sectionName}`} headline={dataSections[ sectionName ].sectionTitle}>
                                        {
                                            makeInfo( dataSections[ sectionName ].info )
                                                .concat( sections[ sectionName ].dropdowns
                                                    .slice()
                                                    .sort( ( a, b ) => a.sort ? String( a.label ).localeCompare( b.label ) : 1 )
                                                    .map( ( dropdown, i ) => makeDropdown( dropdown, i, sectionName ) ) )
                                        }
                                    </Foldable> ) )
                                : loading
                                    ? <CircularProgress />
                                    : ""
                        }

                        {trustConnection && settings && !provider?.integrated && integrationButton(
                            masterDisable || ( boardFilter && boardFilter.length === 0 ), userCompanyId, __( "Start" ), 
                            () => startIntegration( provider.connectorName ), 
                            UX_INTEGRATION_START )}

                    </div>
                }
            </div>
        </form>
    </SettingsLayout>;
};

IntegrationForm.propTypes = {
    userCompanyId:              PropTypes.string,
    progress:                   PropTypes.object,
    error:                      PropTypes.object,
    submissionError:            PropTypes.any,
    fieldValue:                 PropTypes.object.isRequired,
    fieldTouched:               PropTypes.object.isRequired,
    provider:                   PropTypes.object,
    settings:                   PropTypes.object,
    sections:                   PropTypes.object,
    dependentOn:                PropTypes.object,
    connectFieldsValid:         PropTypes.bool.isRequired,
    loading:                    PropTypes.bool.isRequired,
    masterDisable:              PropTypes.bool.isRequired,
    connectFieldsSame:          PropTypes.bool.isRequired,
    connectToProvider:          PropTypes.func.isRequired,
    refreshProviderSettings:          PropTypes.func.isRequired,
    updateMapValueThunk:        PropTypes.func.isRequired,
    startIntegration:           PropTypes.func.isRequired,
    history:                    PropTypes.object.isRequired,
    initStandalone:             PropTypes.func.isRequired,
    systemReady:                PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    const [ submissionError ] = getUriErrors( state, uri.CONNECT_TO_PROVIDER );
    const userCompanyId = state.company.companyId;
    const { 
        fieldValue, fieldTouched, progress, provider, settings, sections, dependentOn, connectionFields, 
        formErrors, connectFieldsValid, connectFieldsSame
    } = state.integration;
    const loading = loadingSelector( state );
    const masterDisable = loading || ( progress && progress.stage < IntegrationStages.DONE ) || false;

    return {
        submissionError,
        userCompanyId,
        fieldValue,
        fieldTouched,
        progress,
        provider,
        settings,
        sections,
        dependentOn,
        loading,
        masterDisable,
        connectionFields,
        connectFieldsSame,
        formErrors,
        connectFieldsValid,
        systemReady:            selectSystemReady( state )
    };
};

const mapDispatchToProps = {
    setIntegrationFieldValue,
    setIntegrationFieldTouched,
    connectToProvider,
    refreshProviderSettings,
    updateMapValueThunk,
    startIntegration,
    initStandalone
};

export default connect( mapStateToProps, mapDispatchToProps )( IntegrationForm );
export { IntegrationForm };
