import React from 'react';

import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoIcon } from "../../images/LicoIcon.jsx";

const useStyles = makeStyles({
    root: ({
        height:       40,
        minWidth:     80,
        borderRadius: 20
    }),
    label: {
        fontWeight: 300,
        fontSize:   13
    }
});

export const LicoPlainChip = ({ label, className = '', onDelete, ...rest }) => {
    const { root, label: labelCss } = useStyles( rest );
    return <Chip 
        className = {`${root} ${className}`} 
        label = {label} 
        classes = {{ label: labelCss }}
        onDelete={onDelete}
        deleteIcon={<LicoIcon icon="close" />}
    />; 
};

LicoPlainChip.propTypes = {
    label:     PropTypes.string.isRequired,
    className: PropTypes.string,
    onDelete:  PropTypes.func
};
