/** ******************************************************************************************************************
 * @file Standard HTTP error codes.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 28-10-2021
 *********************************************************************************************************************/
// @cpd-ignore

import * as cheerio from 'cheerio';

const codeToMessage = {
    100: "Continue",
    101: "Switching Protocols",
    102: "Processing",
    200: "OK",
    201: "Created",
    202: "Accepted",
    203: "Non-Authoritative Information",
    204: "No Content",
    205: "Reset Content",
    206: "Partial Content",
    207: "Multi-Status",
    208: "Already Reported",
    226: "IM Used",
    300: "Multiple Choices",
    301: "Moved Permanently",
    302: "Found",
    303: "See Other",
    304: "Not Modified",
    305: "Use Proxy",
    307: "Temporary Redirect",
    308: "Permanent Redirect",
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed",
    413: "Payload Too Large",
    414: "URI Too Long",
    415: "Unsupported Media Type",
    416: "Range Not Satisfiable",
    417: "Expectation Failed",
    418: "I'm a teapot",
    421: "Misdirected Request",
    422: "Unprocessable Entity",
    423: "Locked",
    424: "Failed Dependency",
    425: "Unordered Collection",
    426: "Upgrade Required",
    428: "Precondition Required",
    429: "Too Many Requests",
    431: "Request Header Fields Too Large",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    506: "Variant Also Negotiates",
    507: "Insufficient Storage",
    508: "Loop Detected",
    509: "Bandwidth Limit Exceeded",
    510: "Not Extended",
    511: "Network Authentication Required"
};

const toSafeName = text => text.replace( /[ -]/g, '_' ).replaceAll( "'", '_a' ).toUpperCase();

const compareStatus = Object.entries( codeToMessage )
    .reduce( ( o, [ statusCode, name ]) => {
        name = toSafeName( name );
        return {
            ...o,
            [ name ]:       statusCode,
            [ statusCode ]: statusCode
        };
    }, {});

const nameToCodeToName = Object.entries( codeToMessage )
    .reduce( ( o, [ code, text ]) => {
        const name = toSafeName( text );
        return {
            ...o,
            [ name ]: code,
            [ code ]: name
        };
    }, {});

const HttpStatusCode = Object.entries( codeToMessage )
    .reduce( ( o, [ code, text ]) => ({ ...o, [ toSafeName( text ) ]: Number( code ) }), {});

function fromName( name )
{
    const statusCode = nameToCodeToName[ name ];

    if ( !statusCode ) return null;

    return {
        statusCode,
        code:    nameToCodeToName[ statusCode ],
        message: codeToMessage[ statusCode ]
    };
}

function fromStatusCode( statusCode )
{
    if ( !statusCode ) return null;

    return {
        statusCode,
        code:    nameToCodeToName[ statusCode ],
        message: codeToMessage[ statusCode ]
    };
}

function fromCode( code )
{
    return fromStatusCode( nameToCodeToName[ code ]);
}

const HttpStatus = nameToCodeToName;

const isStatus = ( statusCode, checkValue ) => compareStatus[ statusCode ] === compareStatus[ checkValue ];

function errorFromHtml( page )
{
    const $     = cheerio.load( page );
    const parts = [];

    $( 'div, p, span, b' ).each( function() { parts.push( $( this ).text() ); });

    const codePart = parts.find( p => /[45]\d\d/.test( p ) );

    const etext = ( codePart || ( parts[ 0 ] + ' - ' + parts[ 1 ]) ).replace( /^[\s\r\n]*|[\s\r\n]*$/gs, '' );
    const e     = new Error( etext );
    /(\d\d\d)/.test( etext );
    e.statusCode = Number( RegExp.$1 );

    return e;
}

function safeJsonOrHtml( data )
{
    try
    {
        if ( data?.[ 0 ] === '<' )
            return errorFromHtml( data );

        return JSON.parse( data );
    }
    catch ( e )
    {
        return e;
    }
}

export { errorFromHtml, safeJsonOrHtml, fromName, fromStatusCode, fromCode, nameToCodeToName, codeToMessage, HttpStatus, isStatus, HttpStatusCode, compareStatus };
