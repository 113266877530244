import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNewTeam, changeTeam, editTeam } from '../../../redux/actions/index.js';
import { TypeAheadControl } from '../../../redux/reducers/typeAhead.js';
import { 
    teamsRoot, teamBox, teamHeader, disabledTeam, editBtn, headerButton, add, addIcon, errorLabel, unteamed, unteamedBox, unteamedEngineer
} from '../../../scss/ManageTeams.module.scss';
import { settings, screenTitle } from '../../../scss/Settings.module.scss';
import { __, _$ } from "../../../utils/i18n.jsx";
import { onEsc, onEnter } from '../../../utils/misc.js';
import { H1, LicoSwitch, LicoIcon, ChipBox, LicoInlineTextField, WarningConfirmDialog } from "../../common/index.js";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import { getTeams, getTeamState, saveTeam, patchTeam, postTeamMember, deleteTeamMember, deleteTeam, getEngineers, getUnteamedEngineers } from './reducer.js';

const TeamBox = ({ team, editTeam, changeTeam, saveTeam, patchTeam, deleteTeam, postTeamMember, deleteTeamMember, setDeleteInfo, editingTeamId, fieldError, workingTeamName }) => {
    const chips = team.members.filter( m => m.user.active ).reduce( ( acc, cur ) => { acc[ cur.tagUserId ] = cur.user.name; return acc; }, {});

    // to exclude existing members, we need the user ids, not the tag user ids
    const memberUserIds = team.members.map( m => m.user.userId );

    const reset = () => {
        changeTeam({ tagName: workingTeamName });
        editTeam( null );
    };

    return <div className={`${teamBox} ${team.active ? '' : disabledTeam}`}>
        <div className={teamHeader}>
            <LicoSwitch 
                name={`enableTeam-${team.tagName}`} 
                checked={team.active} 
                onChange={( e, checked ) => {
                    changeTeam({ tagId: team.tagId, active: checked });
                    patchTeam({ tagId: team.tagId, active: checked });
                }} 
            />
            {editingTeamId === team.tagId 
                ? <LicoInlineTextField
                    name="editTeamName"
                    placeholder={__( "Team Name" )}
                    value={team.tagName}
                    autoFocus
                    onChange={event => changeTeam({ tagName: event.target.value })}
                    onBlur={reset}
                    onKeyDown={event => {
                        onEsc( reset )( event );
                        onEnter( () => saveTeam({ tagId: team.tagId, tagName: team.tagName }) )( event );
                    }}
                /> 
                : team.tagName}
            {editingTeamId !== team.tagId && <span className={`${headerButton} ${editBtn}`} onClick={() => editTeam( team.tagId )}><LicoIcon icon="edit" /></span>}
            <span className='flexGrow' />
            <span className={headerButton} onClick={() => setDeleteInfo({ 
                name:       'Team',
                cancel:     () => setDeleteInfo( null ),
                continue:   () => {
                    setDeleteInfo( null );
                    deleteTeam( team.tagId );
                }
            })}><LicoIcon icon="delete" /></span>
        </div>
        {editingTeamId === team.tagId && fieldError && <div className={errorLabel}>{fieldError}</div>}
        <ChipBox 
            existingChips={chips}
            addChipAction={item => postTeamMember({ tagId: team.tagId, userId: item.id, user: item.item }) }
            removeChipAction={id => deleteTeamMember({ tagId: team.tagId, tagUserId: id })}
            typeAheadId={TypeAheadControl.engineers}
            typeAheadExclusions={memberUserIds}
            disabled={false}
            allowEmptyList={true}
        />
    </div>;
};
TeamBox.propTypes = {
    team:               PropTypes.object.isRequired,
    fieldError:         PropTypes.string,
    editingTeamId:      PropTypes.string,
    workingTeamName:    PropTypes.string,
    setDeleteInfo:      PropTypes.func.isRequired,
    postTeamMember:     PropTypes.func.isRequired,
    deleteTeamMember:   PropTypes.func.isRequired,
    editTeam:           PropTypes.func.isRequired,
    changeTeam:         PropTypes.func.isRequired,
    saveTeam:           PropTypes.func.isRequired,
    patchTeam:          PropTypes.func.isRequired,
    deleteTeam:         PropTypes.func.isRequired
};

const TeamBoxStateProps = state => {
    const { 
        editingTeamId,
        fieldError,
        workingTeamName
    } = getTeamState( state );
    return { 
        editingTeamId,
        fieldError,
        workingTeamName
    };
};
const ConnectedTeamBox = connect( TeamBoxStateProps, { editTeam, changeTeam, saveTeam, patchTeam, deleteTeam, postTeamMember, deleteTeamMember })( TeamBox );

const ManageTeams = props => {

    const { teams, addingTeam, workingTeamName, getTeams, showNewTeam, changeTeam, saveTeam, getEngineers, unteamedEngineers, fieldError } = props;

    useEffect( () => {
        getTeams();
        getEngineers();
    }, [ getTeams, getEngineers ]);

    const [ confirmInfo, setConfirmInfo ] = useState( null );

    return <SettingsLayout>

        {confirmInfo && <WarningConfirmDialog
            title={_$( "Delete this {name}?", confirmInfo )}
            message={_$( "Are you sure you want to delete this {name}?", confirmInfo )}
            isOpen={!!confirmInfo}
            onCancel={confirmInfo?.cancel}
            onContinue={confirmInfo?.continue}
        />}

        <div className={settings}>
            <H1 className={screenTitle}>{__( "Teams" )}</H1>
            <div className={teamsRoot}>

                <div>
                    {teams.map( team => <ConnectedTeamBox 
                        key={team.tagId}
                        team={team}
                        setDeleteInfo={setConfirmInfo}
                    /> )}

                    <div className={add}>
                        <span className={addIcon}  onClick={() => showNewTeam( true )}>
                    +
                        </span>
                        {__( "Add Team" )}
                    &nbsp;
                    &nbsp;
                        {addingTeam && <LicoInlineTextField
                            name="workingTeamName"
                            value={workingTeamName}
                            autoFocus
                            placeholder={__( "Team Name" )}
                            onChange={event => changeTeam({ tagName: event.target.value })}
                            onBlur={() => showNewTeam( false )}
                            onKeyDown={event => {
                                onEsc( () => showNewTeam( false ) )( event );
                                onEnter( () => {
                                    if ( workingTeamName.length > 0 ) 
                                        saveTeam({ tagName: workingTeamName });
                                })( event );
                            }}
                        />}
                    </div>
                    {addingTeam && fieldError && <div className={errorLabel}>{fieldError}</div>}
                </div>
                <div className={unteamed}>
                    {unteamedEngineers.length === 0
                        ? __( "All Engineers are assigned to Teams" )
                        : <>
                            {__( "These Engineers are not assigned to a Team:" )}
                            <div className={unteamedBox}>
                                {unteamedEngineers.map( ( e, i ) => <span key={i} className={unteamedEngineer}>{e}</span> )}
                            </div>
                        </>
                    }
                </div>
            </div>

        </div>
    </SettingsLayout>;
};
ManageTeams.propTypes = {
    teams:                          PropTypes.array.isRequired,
    addingTeam:                     PropTypes.bool.isRequired,
    workingTeamName:                    PropTypes.string,
    fieldError:                     PropTypes.string,
    getTeams:                       PropTypes.func.isRequired,
    showNewTeam:                    PropTypes.func.isRequired,
    changeTeam:                     PropTypes.func.isRequired,
    saveTeam:                       PropTypes.func.isRequired,
    getEngineers:                   PropTypes.func.isRequired,
    unteamedEngineers:              PropTypes.array
};

const mapStateToProps = state => {
    const { 
        teams,
        addingTeam,
        workingTeamName,
        fieldError
    } = getTeamState( state );

    const unteamedEngineers = getUnteamedEngineers( state );
    return { 
        teams,
        addingTeam,
        workingTeamName,
        fieldError,
        unteamedEngineers
    };
};

const mapDispatchToProps = {
    getTeams,
    showNewTeam,
    changeTeam,
    saveTeam,
    getEngineers
};

export default connect( mapStateToProps, mapDispatchToProps )( ManageTeams );
export { ManageTeams };
