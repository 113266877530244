import makeStyles from '@mui/styles/makeStyles';

const popOverUseStyles = makeStyles({

    list: {
        backgroundColor: 'var(--black-0)',
        borderRadius:    4,
        padding:         '0px !important',
        display:         'flex',
        flexDirection:   'column',
        alignItems:      'center',
        justifyContent:  'center',
        margin:          '0px !important'
    },
    listItem: {
        display:         'flex',
        flexDirection:   'column',
        alignItems:      'flex-start',
        justifyContent:  'center',
        padding:          '6px 6px 5px 10px !important',
        "&:hover":       {
            background: "var(--black-1)"
        },
        "&:active": {
            "& .menuIcon": {
                background: "var(--white)",
                color:      "var(--lime)"
            }
        },
        cursor:          'pointer',
        fontFamily:      'gotham-book, sans-serif !important'

    },

    listItemGroup: {
        display:         'flex',
        flexDirection:   'row',
        alignItems:      'center',
        justifyContent: 'flex-start',
        fontSize:       '12px'
    },

    listItemText: {
        paddingLeft: '8px'
    },
    listItemIconSize: {
        maxWidth:    '14px',
        minWidth:    '14px'
    },
    gift: {
        color:         'var(--cyan)',
        fontWeight:   'normal'

    },
    creditCard: {
        color:    'var(--teal)'

    },
    moonstars: {
        color:    'var(--tangerine)',
        fontSize: '12px'
    },
    noCreditCard: {
        fontSize:   '12px'
    },
    textFieldRoot: {
        padding:                 0,
        position:                'relative',
        width:                   '76px',
        margin:                  0,
        height:                  '100%',
        '& .MuiInputBase-root': {
            height:       '100% !important',
            borderRadius: '0px '
        },
        '& .MuiOutlinedInput-root': {
            marginTop: '0px !important'
        }
    },
    inputText: {
        fontSize:        '13px',
        fontFamily:      "gotham-medium, sans-serif",
        padding:         '0px',
        display:         'flex',
        textAlign:       'center',
        color:           'var(--black-1) !important'
    }
});

export default popOverUseStyles;
