/*********************************************************************************************************************
 * @file Jobcard component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React, { useEffect, useState, createRef } from 'react';

import { AppointmentState, coms, Coms, ADD, DELETE } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { patchJobCardEngineers, setNotesFullView, setSelectedAsset } from "../../redux/actions/index.js";
import { TypeAheadControl } from '../../redux/reducers/typeAhead.js';
import { selectJobEngineers, selectJobUsers, selectJobAppointments, selectCacheRecord } from '../../redux/selectors/index.js';
import { addAsset, selector } from "../../scss/Asset.module.scss";
import { jobCard, leftSection, rightSection, rightSectionItem, form, rightSectionScroll } from '../../scss/JobCard.module.scss';
import { __, _$ } from '../../utils/i18n.jsx';
import { 
    UX_JOBCARD_USER_ADD_BUTTON, UX_JOBCARD_USER_ADD_LIST, UX_JOBCARD_USER_CHIP_LIST, UX_JOBCARD_USER_CHIP_ITEM, UX_JOBCARD_USER_CHIP_ITEM_DELETE,
    UX_JOBCARD_ENGINEER_ADD_BUTTON, UX_JOBCARD_ENGINEER_ADD_LIST, UX_JOBCARD_ENGINEER_CHIP_LIST, UX_JOBCARD_ENGINEER_CHIP_ITEM, UX_JOBCARD_ENGINEER_CHIP_ITEM_DELETE
} from '../../ux-constants.js';
import { patchJobEngineers } from '../calendar/requests.js';
import DynamicChipAdder from '../common/DynamicChipAdder.jsx';
import { LicoSmallButton } from "../common/index.js";

import AppointmentList from './AppointmentList.jsx';
import AssetForm from "./assets/AssetForm.jsx";
import AssetPopover  from "./assets/AssetPopover.jsx";
import Assets from "./assets/Assets.jsx";
import Checklist from './Checklist.jsx';
import JobCardForm from './JobCardForm.jsx';
import JobCardTopPanel from './JobCardTopPanel.jsx';
import { setJobCardPatchEngineer, setJobCardPatchUser, setJobOwner } from './reducer.js';
import WorkLog from './WorkLog.jsx';

const JobCard = ({
    job,
    existingEngineers,
    appointments,
    existingUsers,
    setJobCardPatchUser,
    setJobCardPatchEngineer,
    patchJobCardEngineers,
    patchJobEngineers,
    setJobOwner,
    provider,
    companyStatusName,
    companyStatusDescription, 
    readOnlyCompany,
    customNote,
    notesFullView,
    setNotesFullView,
    currentUser,
    completed,
    setSelectedAsset,
    assetsMap
}) => {
    const handleKeyUp = ( event ) => {
        if ( event.altKey && event.code === 'KeyF' )
            setNotesFullView( !notesFullView );
    };
    useEffect( () => {
        window.addEventListener( "keyup", handleKeyUp );
        return () => {
            window.removeEventListener( "keyup", handleKeyUp );
        };
        // eslint-disable-next-line
    }, [ notesFullView ]);


    // we need the new job flag to persist until right after the job is saved, otherwise
    // the worklog autofocus steals focus from the title
    const [ newJob ] = useState( job.newItemFlag );

    const [ popoverOpen, setPopoverOpen ] = useState( false );
    const [ anchorEl, setAnchorEl ] = useState( null );
    const [ openAssetForm, setOpenAssetForm ] = useState( false );
    const ref = createRef();

    const openPopOver = () => {
        setPopoverOpen( true );
        setAnchorEl( ref.current );
    };

    const getAnchorEl = () => ref.current;
    
    const closePopOver = () => {
        setPopoverOpen( false );
        setAnchorEl( null );
    };

    const viewAssetDetails = assetId  => {
        setOpenAssetForm( true );
        setSelectedAsset( assetsMap[ assetId ]);
    };
  
    return <div className={jobCard} >
        <JobCardTopPanel job={job} completed={completed} />
        <div className={form}>

            <div className={leftSection}>
                <JobCardForm
                    job={job}
                    completed={completed}
                    companyStatusName={companyStatusName}
                    companyStatusDescription={companyStatusDescription}
                    readOnlyCompany={readOnlyCompany}
                    customNote={customNote}
                    clientAutoFocus={!!newJob}
                />
                <WorkLog job={job} workLogAutoFocus={!newJob} readOnlyCompany={readOnlyCompany} completed={completed}/>
            </div>
            <div className={rightSection}>
                <div className={rightSectionScroll}>
                    <div className={rightSectionItem}>
                        <AppointmentList />
                    </div>
                    <div className={rightSectionItem}>
                        <DynamicChipAdder
                            name={TypeAheadControl.engineers}
                            heading={__( "Engineers" )}
                            label={__( "Add engineer" )}
                            storeId={job.jobId}
                            storeAction={setJobCardPatchEngineer}
                            existingOptions={existingEngineers}
                            preventAdd={job.newItemFlag}
                            preventDeleteCallback={ userId => {
                                if ( job.newItemFlag )
                                    return __( "Default Engineer can't be deleted until the job is created" );
                                else if ( userId === job.userId )
                                    return __( "Select a new Job Owner to delete this Engineer" );
                                else {

                                    // check if there's an appointment for this user
                                    if ( appointments.find( a => a.userId === userId && ( a.state === AppointmentState.active || a.state === AppointmentState.done ) ) )
                                        return __( "Remove or reassign all appointments for this Engineer before deleting them" );
                                }
                                return undefined;
                            }}
                            dataNameAdd={UX_JOBCARD_ENGINEER_ADD_BUTTON}
                            dataNameList={UX_JOBCARD_ENGINEER_ADD_LIST}
                            dataNameChipList={UX_JOBCARD_ENGINEER_CHIP_LIST}
                            dataNameChipItem={UX_JOBCARD_ENGINEER_CHIP_ITEM}
                            dataNameChipItemDelete={UX_JOBCARD_ENGINEER_CHIP_ITEM_DELETE}
                            ownerUserId={job.userId}
                            setOwnerUser={newOwnerId => {

                                // get the full record for the current owner from the engineer list before we start
                                // changing it; we need this to add that user as a regular engineer once they're
                                // no longer the owner.
                                // const oldOwnerRecord = existingEngineers.find( e => e.id === job.userId );

                                // Note that all the patch engineer actions we're doing here are backend only;
                                // the frontend list doesn't change, since it already contains old & new owner.
                                // All that changes is the owner designation which comes from the change to job.userId.

                                // remove the userJob record for the new owner (will be linked via job.userId)
                                patchJobEngineers({
                                    id:         job.jobId,
                                    data:       {
                                        action:     DELETE,
                                        people:     [ newOwnerId ]
                                    }
                                });

                                // change the owner field
                                setJobOwner({ field: 'userId', userId: newOwnerId, jobId: job.jobId });

                                // add a userJob record for the old owner; we need the full record for this
                                patchJobEngineers({
                                    id:         job.jobId,
                                    data:       {
                                        action:     ADD,
                                        people:     [ job.userId ]
                                    }
                                });

                            }}
                            grabAction={newOwnerId => {

                                // we know the owner must change but the new owner may or may not be an engineer on the job already.
                                // check the engineer list.
                                const newOwnerRecord = existingEngineers.find( e => e.id === newOwnerId );

                                // if the new owner is already on the list, delete it on the backend but leave it on the frontend list
                                if ( newOwnerRecord )
                                {
                                    patchJobEngineers({
                                        id:         job.jobId,
                                        data:       {
                                            action:     DELETE,
                                            people:     [ newOwnerId ]
                                        }
                                    });
                                }

                                // change the owner field
                                setJobOwner({ field: 'userId', userId: newOwnerId, jobId: job.jobId });

                                // Add a userJob record for the old owner (backend only). We know the old owner is on the frontend list.
                                patchJobEngineers({
                                    id:         job.jobId,
                                    data:       {
                                        action:     'add',
                                        people:     [ job.userId ]
                                    }
                                });

                                // If the current user wasn't on the job already, add them to the frontend list (they're linked via job.userId
                                // so don't need a userJob record but do need to be in the job card list). This is purely a frontend action;
                                // the backend work is done by setJobOwner.
                                if ( !newOwnerRecord )
                                {
                                    patchJobCardEngineers({
                                        fullRecord: currentUser,
                                        id:         job.jobId,
                                        data:       {
                                            action:     'add',
                                            people:     [ newOwnerId ]
                                        }
                                    });
                                }
                            }}
                        />
                    </div>

                    <div className={rightSectionItem}>
                        <DynamicChipAdder
                            name={TypeAheadControl.users}
                            heading={__( "Users" )}
                            label={__( "Add user" )}
                            preventLoneChipDelete={true}
                            preventUserIdDelete={job.contactId}
                            preventUserIdDeleteMessage={__( "Select a new Job Owner to delete this contact" )}
                            storeId={job.jobId}
                            storeAction={setJobCardPatchUser}
                            existingOptions={existingUsers}
                            existingOptionWarnings={[
                                {
                                    test:       option => !coms.has( option.item.contactInfo, Coms.Types.EMAIL ),
                                    message:    _$( "{provider} won't email updates until all Users have an email address. Add an email to the indicated Users(s).",
                                        { provider })
                                }
                            ]}
                            extraData={{ companyId: job.companyId }}
                            preventAdd={job.newItemFlag || !job.companyId || completed }
                            dataNameAdd={UX_JOBCARD_USER_ADD_BUTTON}
                            dataNameList={UX_JOBCARD_USER_ADD_LIST}
                            dataNameChipList={UX_JOBCARD_USER_CHIP_LIST}
                            dataNameChipItem={UX_JOBCARD_USER_CHIP_ITEM}
                            dataNameChipItemDelete={UX_JOBCARD_USER_CHIP_ITEM_DELETE}
                            ownerUserId={job.contactId}
                            setOwnerUser={payload => setJobOwner({ field: 'contactId', userId: payload, jobId: job.jobId })}
                        />
                    </div>

                    <div className={rightSectionItem}>
                        <Checklist jobId={job.jobId} disabled={completed} preventAdd={job.newItemFlag} />
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    {!job.newItemFlag && <Assets preventAdd={job.newItemFlag } clientId = {job.companyId} viewAssetDetails={viewAssetDetails} />}
                </div>
                { openAssetForm && <AssetForm anchorEl={getAnchorEl} onClose={() => setOpenAssetForm( false )}  />}

                { !job.newItemFlag && <div className={addAsset} >
                    <LicoSmallButton label={__( "Add asset" )} onClick={openPopOver}  fabClass="grey-background"  />
                    <div className={selector} >
                        <span style={{ marginLeft: 20 } } ref={ref}></span>
                        {<AssetPopover isPopoverOpen={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={closePopOver}
                            clientId={job.companyId}></AssetPopover>}
                    </div>
                </div> }
            </div>
        </div>
    </div>;
};

JobCard.propTypes = {
    job:                        PropTypes.any.isRequired,
    existingEngineers:          PropTypes.arrayOf( PropTypes.any ),
    appointments:               PropTypes.arrayOf( PropTypes.any ).isRequired,
    existingUsers:              PropTypes.arrayOf( PropTypes.any ),
    setJobCardPatchUser:        PropTypes.func.isRequired,
    setJobCardPatchEngineer:    PropTypes.func.isRequired,
    setJobOwner:                PropTypes.func.isRequired,
    provider:                   PropTypes.string.isRequired,
    companyStatusName:          PropTypes.string, 
    companyStatusDescription:   PropTypes.string, 
    readOnlyCompany:            PropTypes.bool,
    customNote:                 PropTypes.string,
    notesFullView:              PropTypes.bool,
    setNotesFullView:           PropTypes.func,
    patchJobCardEngineers:      PropTypes.func,
    patchJobEngineers:          PropTypes.func,
    currentUser:                PropTypes.object,
    completed:                  PropTypes.bool.isRequired,
    setSelectedAsset:           PropTypes.func.isRequired,
    assetsMap:                  PropTypes.object.isRequired
};

const mapStateToProps = ( state, props ) => {

    const { companyStatusName, companyStatusDescription, readOnly, customNote } = selectCacheRecord( state, 'company', props.job.companyId ) || {};

    return {
        existingEngineers:          selectJobEngineers( state ),
        existingUsers:              selectJobUsers( state ),
        provider:                   state.integration.provider.displayName,
        companyStatusName,
        companyStatusDescription,
        readOnlyCompany:            readOnly ?? false,
        customNote,
        notesFullView:              state.jobcard.notesFullView,
        setNotesFullView:           state.jobcard.setNotesFullView,
        currentUser:                state.user,
        appointments:               selectJobAppointments( state ),
        completed:                  props.job.statusId === state.jobcard.completedStatus,
        assetsMap:                  state.jobcard.assetsMap || {}

    };
};

const mapDispatchToProps = {
    setJobCardPatchUser,
    setJobCardPatchEngineer,
    setJobOwner,
    setNotesFullView,
    patchJobCardEngineers,
    patchJobEngineers,
    setSelectedAsset
};

export default connect( mapStateToProps, mapDispatchToProps )( JobCard );
export { JobCard };
