/** ******************************************************************************************************************
 * @file Date calculations, largely involving interval calculations.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 14-Feb-2021
 *********************************************************************************************************************/
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear.js';
import isLeapYear from 'dayjs/plugin/isLeapYear.js';
import weekday from 'dayjs/plugin/weekday.js';

import { inRange, range } from '../helpers/index.js';

dayjs.extend( dayOfYear );
dayjs.extend( isoWeek );
dayjs.extend( isoWeeksInYear );
dayjs.extend( isLeapYear );
dayjs.extend( weekday );

const dowOffsets = new Map();

/** @typedef {[ number, number ]} MonthRange */
/** @typedef {Array<number>} FirstDowOffsets */

/**
 * @typedef {object} DayOfYearValues
 * @property {MonthRange[]} monthRanges
 * @property {FirstDowOffsets} firstDowInYear
 */


/**
 * @param {number} year
 * @returns {DayOfYearValues}
 */
function calculateDayOfYearValues( year )
{
    if ( dowOffsets.has( year ) ) return dowOffsets.get( year );

    const dayAtOffsetZero = dayjs().dayOfYear( 1 ).weekday();

    const monthRanges = [];

    for ( let mnts = 0, mticks = dayjs().dayOfYear( 1 ); mnts < 12; ++mnts )
    {
        monthRanges.push([
            mticks.dayOfYear() - 1,
            mticks.add( 1, 'month' ).dayOfYear() - 2
        ]);
    }

    monthRanges[ 11 ][ 1 ] = dayjs().month( 11 ).date( 31 ).dayOfYear() - 1;

    const firstDowInYear = [];

    let dayOffset = 1;
    let index = ( dayAtOffsetZero + 1 ) % 7;
    firstDowInYear[ dayAtOffsetZero ] = 0;
    while ( index !== dayAtOffsetZero )
    {
        firstDowInYear[ index ] = dayOffset++;
        index = ( index + 1 ) % 7;
    }

    dowOffsets.set( year, { firstDowInYear, monthRanges });

    return { firstDowInYear, monthRanges };
}

/**
 * SEQUENCE: A -> B
 * START: O
 *
 *
 *
 * @param {number} first
 * @param {number} last
 * @param {number} [interval=1]
 * @param {number} [sequenceStart]
 * @returns {number[]}
 */
function calcFirstAndLastInRange( first, last, interval = 1, sequenceStart = 0 )
{
    // interval = 3, first = 2010, last = 2021, sequenceStart = 2015

    if ( last < sequenceStart ) return [ -1, -1 ];

    if ( sequenceStart > first ) first = sequenceStart;

    if ( sequenceStart < first )
        sequenceStart = ~~( ( first - sequenceStart ) / interval ) * interval + sequenceStart;

    if ( sequenceStart < first )
        sequenceStart += interval;

    if ( sequenceStart > last ) return [ -1, -1 ];

    const firstTick = sequenceStart;
    let lastTick = firstTick;

    if ( firstTick < last )
        lastTick = ~~( ( last - firstTick ) / interval ) * interval + firstTick;

    return [ firstTick, lastTick ];
}

/**
 * @param {number} start
 * @param {number} end
 * @param {number} [interval=1]
 * @param {number} sequenceStart
 * @returns {number[]}
 */
function calcSequenceInRange( start, end, interval = 1, sequenceStart = 0 )
{
    const [ a, b ] = calcFirstAndLastInRange( start, end, interval, sequenceStart );

    if ( a === -1 ) return [];

    const result = [ a ];

    if ( a === b ) return result;

    let n = a + interval;

    while ( n <= b )
    {
        result.push( n );
        n += interval;
    }

    return result;
}

/**
 * @param {number} queryValue
 * @param {number} start
 * @param {number} end
 * @param {number} [interval=1]
 * @param {number} sequenceStart
 * @returns {boolean}
 */
function existsInRange( queryValue, start, end, interval = 1, sequenceStart = 0 )
{
    const [ a, b ] = calcFirstAndLastInRange( start, end, interval, sequenceStart );

    if ( a === -1 ) return false;

    if ( a === queryValue ) return true;

    if ( a === b )
        return false;

    let n = a + interval;

    while ( n <= b && n < queryValue )
    {
        if ( n === queryValue ) return true;
        n += interval;
    }

    return false;
}

/**
 * @param {number} n            - the Nth instance to take
 * @param {number} start
 * @param {number} end
 * @param {number} [interval=1]
 * @param {number} [offset]
 * @returns {number}
 */
function takeNthInstanceInRange( n, start, end, interval = 1, offset = 0 )
{
    const seq = calcSequenceInRange( start + offset, end + offset, interval );

    return n >= seq.length ? -1 : ( seq[ n ] - offset );
}

/**
 * @param nth
 * @param dow
 * @param month
 * @param year
 * @returns {DateLib|null}
 */
function takeNthDowInMonth( nth, dow, month, year = dayjs().year() )
{
    const { monthRanges, firstDowInYear } = calculateDayOfYearValues( year );

    const dayInYear = takeNthInstanceInRange( nth, monthRanges[ month ][ 0 ], monthRanges[ month ][ 1 ], 7,  -firstDowInYear[ dow ]);
    if ( dayInYear === -1 ) return null;

    return dayjs().dayOfYear( dayInYear + 1 );
}

/**
 * @param {YearMonthDay} query
 * @param {YearMonthDay} first
 * @param {YearMonthDay} last
 * @returns {boolean}
 */
function yearInRange( query, first, last )
{
    if ( !query.year || !first.year || !last.year ) return true;

    return query.year >= first.year && query.year <= last.year;
}

/**
 * @param {YearMonthDay} query
 * @param {YearMonthDay} first
 * @param {YearMonthDay} last
 * @returns {boolean}
 */
function monthInRange( query, first, last )
{
    if ( !yearInRange( query, first, last ) ) return false;

    if ( !query.year || !first.year || !last.year )
        return query.month >= first.month && query.month <= last.month;

    if ( query.year < first.year || query.year > last.year ) return false;
    if ( query.year === first.year ) return query.month >= first.month;
    if ( query.year === last.year ) return query.month <= last.month;

    return true;
}

/**
 * @param {YearMonthDay} query
 * @param {YearMonthDay} first
 * @param {YearMonthDay} last
 * @returns {boolean}
 */
function dayOfMonthInRange( query, first, last )
{
    if ( !yearInRange( query, first, last ) || !monthInRange( query, first, last ) ) return false;

    if ( query.year === first.year && query.month === first.month ) return query.dayOfMonth >= first.dayOfMonth;
    if ( query.year === last.year && query.month === last.month ) return query.dayOfMonth <= last.dayOfMonth;

    return true;

}

function makeYearRange( intervalStart, from, to, interval, count )
{
    const check = inRange( from.year, to?.year || 1e6 );
    const hitsInRange = [];

    for ( const cur of range( intervalStart.year, to?.year ? to.year + 1 : 1e6, interval ) )
    {
        if ( check( cur ) ) hitsInRange.push({ ...intervalStart, year: cur });
        if ( !to )
        {
            --count;
            if ( count <= 0 ) break;
        }
    }

    return hitsInRange;
}

function makeMonthRange( intervalStart, from, to, interval, count )
{
    const hitsInRange = [];
    const check = ( year, month ) => ( year > from.year || ( year === from.year && month >= from.month ) ) &&
        ( to ? ( year < to.year || ( year === to.year && month <= to.month ) ) : true );

    let { year, month } = intervalStart;

    while ( to ? ( year < to.year || ( year === to.year && month <= to.month ) ) : count )
    {
        if ( check( year, month ) ) hitsInRange.push({ ...intervalStart, year, month });
        month += interval;

        while ( month >= 12 )
        {
            month -= 12;
            ++year;
        }

        if ( !to )
        {
            --count;
            if ( count <= 0 ) break;
        }
    }

    return hitsInRange;
}

function makeWeekRange( intervalStart, from, to, interval, count )
{
    let currentWeek = dayjs().year( intervalStart.year ).month( intervalStart.month ).date( intervalStart.dayOfMonth ).isoWeek();
    let curMaxForYear = dayjs().year( intervalStart.year ).isoWeeksInYear();
    let curYear = intervalStart.year;

    const lastYear = to?.year;
    const lastWeek = to && dayjs().year( to.year ).month( to.month ).date( to.dayOfMonth ).isoWeek();

    const startYear = from.year;
    const startWeek = dayjs().year( from.year ).month( from.month ).date( from.dayOfMonth ).isoWeek();
    const hitsInRange = [];

    while ( lastYear ? ( curYear < lastYear || ( curYear === lastYear && currentWeek <= lastWeek ) ) : count > 0 )
    {
        if ( curYear > startYear || ( curYear === startYear && currentWeek >= startWeek ) )
        {
            const c = dayjs().year( curYear ).isoWeek( currentWeek );
            hitsInRange.push({ ...intervalStart, year: curYear, month: c.month(), week: currentWeek, dayOfMonth: c.date() - 1 });
        }

        currentWeek += interval;
        if ( currentWeek >= curMaxForYear )
        {
            currentWeek -= curMaxForYear;
            ++curYear;
            curMaxForYear = dayjs().year( curYear ).isoWeeksInYear();
        }

        if ( !lastYear ) --count;
    }

    return hitsInRange;
}

function makeDayRange( intervalStart, from, to, interval, count )
{
    let curDay = dayjs().year( intervalStart.year ).month( intervalStart.month ).date( intervalStart.dayOfMonth + 1 ).dayOfYear() - 1;
    let curMaxForYear = dayjs().year( intervalStart.year ).month( 11 ).date( 31 ).dayOfYear();
    let curYear = intervalStart.year;

    const lastYear = to?.year;
    const lastDay = to && dayjs().year( to.year ).month( to.month ).date( to.dayOfMonth ).dayOfYear() - 1;
    const startYear = from.year;
    const startDay = dayjs().year( from.year ).month( from.month ).date( from.dayOfMonth ).dayOfYear() - 1;
    const hitsInRange = [];

    while ( lastYear ? ( curYear < lastYear || ( curYear === lastYear && curDay <= lastDay ) ) : count > 0 )
    {
        if ( curYear > startYear || ( curYear === startYear && curDay >= startDay ) )
        {
            const c = dayjs().year( curYear ).dayOfYear( curDay + 1 );
            hitsInRange.push({ ...intervalStart, year: curYear, month: c.month(), dayOfMonth: c.date() - 1 });
        }

        curDay += interval;
        if ( curDay >= curMaxForYear )
        {
            curDay -= curMaxForYear;
            ++curYear;
            curMaxForYear = dayjs().year( curYear ).month( 11 ).date( 31 ).dayOfYear();
        }

        if ( !lastYear ) --count;
    }

    return hitsInRange;
}

/**
 * @param {DateLib} date
 * @return {YearMonthDay}
 */
function extractDateParts( date )
{
    const year = date.getFullYear();
    const month = date.getMonth();
    const dayOfMonth = date.getDate() - 1;
    const dow = date.getDay();

    return { year, month, dayOfMonth, dow };
}

const dateToTimestamp = dt => {
    if ( typeof dt === 'string' )
        dt = new Date( dt );

    return `TIMESTAMP WITH TIME ZONE '${dt.toISOString().replace( /T/, ' ' ).replace( /\.\d\d\dZ/, '+00' )}'`;
};

export {
    dateToTimestamp,
    makeDayRange,
    makeWeekRange,
    makeMonthRange,
    makeYearRange,
    extractDateParts,
    takeNthInstanceInRange,
    calcSequenceInRange,
    calcFirstAndLastInRange,
    existsInRange,
    calculateDayOfYearValues,
    takeNthDowInMonth,
    dayOfMonthInRange,
    monthInRange,
    yearInRange
};

// For quick testing
// const ordinals = [ 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th' ];
// const numberAsOrdinal = n => n + ( n < 10 ? ordinals[ n ] : n < 20 ? 'th' : ordinals[ n % 10 ]);
// const dowNames = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
// const { monthRanges, firstDowInYear } = calculateDayOfYearValues( 2021 );
//
// for ( let nth = 0; nth < 5; ++nth )
// {
//     for ( let wod = 0; wod < 7; ++wod )
//     {
//         const dayInYear = takeNthInstanceInRange( nth, monthRanges[ 2 ][ 0 ], monthRanges[ 2 ][ 1 ], 7, -firstDowInYear[ wod ]);
//         if ( dayInYear !== -1 )
//             console.log( `The ${numberAsOrdinal( nth + 1 )} ${dowNames[ wod ]} in March is ${moment().dayOfYear( dayInYear + 1 ).format( 'dddd[ the ]Do' )}` );
//     }
//
//     console.log();
// }
//
// const tests = [
//     {
//         interval: 3,
//         start:    10,
//         end:      20
//     },
//     {
//         interval:      3,
//         start:         -20,
//         end:           -10,
//         sequenceStart: -30
//     },
//     {
//         interval:      3,
//         start:         2010,
//         end:           2021,
//         sequenceStart: 2015
//     },
//     {
//         interval:      3,
//         start:         2010,
//         end:           2015,
//         sequenceStart: 2015
//     },
//     {
//         interval:      1,
//         start:         2010,
//         end:           2022,
//         sequenceStart: 2015
//     }
//
// ];
//
// console.log();
// tests.forEach( ({ interval, start, end, sequenceStart = 0 }) => {
//     console.log( `Between ${start} to ${end}, starting in ${sequenceStart} with an interval of ${interval}` );
//     console.log( calcFirstAndLastInRange( interval, start, end, sequenceStart ) );
//     console.log( calcSequenceInRange( interval, start, end, sequenceStart ) );
//     console.log();
// });
