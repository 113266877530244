/*********************************************************************************************************************
 * @file TypeAhead reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 24-Dec-2020
 *********************************************************************************************************************/
import { uri, GET } from '../../constants.js';
import { setCreateTypeAheadControl, setTypeAheadFields, setTypeAheadCompanies, setTypeAheadPeople, setTypeAheadAssets } from '../../redux/actions/index.js';
import { ezRedux, genericRequest } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { convertToLicoSelectOptions } from '../../utils/common-types.js';
const TYPEAHEAD_KEY_DEBOUNCE_MSECS = 300;


const TypeAheadControl = {
    companyId:          "companyId",
    searchPanelCompany:  "searchPanelCompany",
    engineers:          "engineers",
    users:              "users",
    calendarUsers:      "calendarUsers",
    people:             "people",
    assets:             "assets"
};

/**
 * @typedef {object} ControlMapItem
 * @property {string} name
 * @property {boolean} open
 * @property {boolean} loading
 * @property {boolean} loaded
 * @property {object[]} options
 * @property {number} timeout
 */

/** @typedef {object<string, ControlMapItem>} ControlMap

/**
 * @typedef {object} TypeAheadState
 * @property {ControlMap} controlMap
 */

/**
 * @param {string} name
 * @return {ControlMapItem}
 */
const initControl = name => ({ name, open: false, loading: false, loaded: false, options: [], timeout: 0 });

/**
  * @type {Readonly<TypeAheadState>}
  */
const initialState = Object.freeze({
    controlMap: Object.values( TypeAheadControl ).reduce( ( ctrl, name ) => ({ ...ctrl, [ name ]: initControl( name ) }), {})
});

/** services */
const _asyncGetTypeAheadCompanies = abstractedCreateAuthRequest( GET, uri.COMPANY_TYPEAHEAD, a => a );
const _asyncGetTypeAheadUsers = abstractedCreateAuthRequest( GET, uri.PEOPLE_TYPEAHEAD, a => a );
const _asyncGetTypeAheadAssets = abstractedCreateAuthRequest( GET, uri.ASSETS_TYPEAHEAD, a => a );

/** requests */
const getTypeAheadCompanies = payload => genericRequest(
    {}, _asyncGetTypeAheadCompanies, [ [ setTypeAheadCompanies, { name: payload.control.name } ] ], [ payload.filter ], { pageSize: 8, orderBy: 'companyName' }
);

const getTypeAheadAssets = payload => {
    return genericRequest(
        {}, _asyncGetTypeAheadAssets, [ [ setTypeAheadAssets, { name: payload.control.name } ] ], [ payload.filter ], {
            pageSize:   5,
            orderBy:    'assetName',
            companyId:  payload?.extraData?.companyId,
            jobId:      payload?.extraData?.jobId
        }
    );
};
const getTypeAheadEngineers =
    payload => genericRequest(
        {}, _asyncGetTypeAheadUsers, [
            [
                setTypeAheadPeople, {
                    name: payload.control.name
                }
            ]
        ], [ payload.filter ], {
            role:         "engineer",
            active:       'true',
            ignoreActive: 'false',
            pageSize:     9,
            orderBy:      'name'
        }
    );

/* eslint-disable function-call-argument-newline */
const getTypeAheadCompanyUsers = payload => genericRequest(
    {},
    _asyncGetTypeAheadUsers,
    [ [ setTypeAheadPeople, { name: payload.control.name } ] ],
    [ payload.filter ],
    {
        related:                    'user->companyUser',
        fields:                     [ 'user.*', 'companyUser.companyId' ],
        'companyUser.companyId':    payload.extraData.companyId,
        pageSize:                   8,
        orderBy:                    'name'
    }

);
const getTypeAheadPeople = payload => genericRequest(
    {}, _asyncGetTypeAheadUsers, [
        [
            setTypeAheadPeople, {
                name: payload.control.name
            }
        ]
    ], [ payload.filter ], {
        active:       'true',
        ignoreActive: 'false',
        pageSize:     9,
        orderBy:      'name'
    }
);

// we need a map from control name to request. This used to key from URI but engineers and users use the
// same URI with different parameters. Engineers and calendarUsers get the same data but they coexist so
// need separate names.
const typeAheadRequestForControl = {
    [ TypeAheadControl.companyId ]:          getTypeAheadCompanies,
    [ TypeAheadControl.searchPanelCompany ]: getTypeAheadCompanies,
    [ TypeAheadControl.engineers ]:          getTypeAheadEngineers,
    [ TypeAheadControl.people ]:             getTypeAheadPeople,
    [ TypeAheadControl.calendarUsers ]:      getTypeAheadEngineers,
    [ TypeAheadControl.users ]:              getTypeAheadCompanyUsers,
    [ TypeAheadControl.assets ]:             getTypeAheadAssets

};

/** thunk actions */

const loadFromUri = ( args, dispatch, control ) => {
    const { name, filter, extraData } = args;

    dispatch( setTypeAheadFields({ name, loading: true }) );

    const request = typeAheadRequestForControl[ name ];

    dispatch( request({ filter: filter.length ? filter : '_', control, extraData }) );
};

const setTypeAheadFilter = args => async ( dispatch, getState ) => {
    const { name } = args;

    // on key, stop any existing timer and restart it
    // if the timer expires, load the control according to the filter
    const control = getState().typeAhead.controlMap[ name ];

    if ( control.timeout )
        clearTimeout( control.timeout );

    const timeout = setTimeout( loadFromUri, TYPEAHEAD_KEY_DEBOUNCE_MSECS, args, dispatch, control );
    dispatch( setTypeAheadFields({ name, timeout, loaded: false }) );
};

/** reducers */

const createControlReducer = ( draft, name ) => {
    draft.controlMap[ name ] = {
        name,
        open:       false,
        loading:    false,
        loaded:     false,
        options:    [],
        timeout:    null
    };
};

// easier to have separate reducers because how the list of records gets turned into
// the display labels is dependent on the field eg company vs engineer vs etc

const setTypeAheadCompaniesReducer = ( draft, payload ) => {
    const { name } = payload;
    const newData = {
        loading:    false,
        loaded:     true,
        options:    payload.payload
            .filter( company => !company.licoricePrimary )

            // entries selected by user will have userId and name fields
            .map( company => ({ id: company.companyId, label: `${company.companyName}${company.name ? ' (' + company.name + ')' : ''}`, company }) )
    };

    Object.assign( draft.controlMap[ name ], { ...draft.controlMap[ name ], ...newData });
};

// this looks generic but we're relying on convertToLicoSelectOptions() looking for userId
const setTypeAheadPeopleReducer = ( draft, payload ) => {
    const { name } = payload;
    const newData = {
        loading:    false,
        loaded:     true,
        options:    convertToLicoSelectOptions( payload.payload ),
        data:       payload.payload
    };

    Object.assign( draft.controlMap[ name ], { ...draft.controlMap[ name ], ...newData });
};

const setTypeAheadAssetReducer = ( draft, payload ) => {
    const { name } = payload;
    const newData = {
        loading: false,
        loaded:  true,
        options: payload.payload.map( asset => ({
            id:    asset.assetId,
            label: `${asset.assetName} (Asset Type: ${asset.assetTypeId}, Status: ${asset.assetStatusId})`,
            asset: asset
        }) ),
        data: payload.payload
    };
    Object.assign( draft.controlMap[ name ], { ...draft.controlMap[ name ], ...newData });
};


const setControlState = ( draft, args ) => {
    const { name, ...rest } = args;

    // clear the options list when we close it
    if ( args.open !== undefined && !args.open )
        rest.options = [];

    Object.assign( draft.controlMap[ name ], rest );
};


/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setTypeAheadFields ]:        setControlState,
    [ setCreateTypeAheadControl ]: createControlReducer,
    [ setTypeAheadCompanies ]:     setTypeAheadCompaniesReducer,
    [ setTypeAheadPeople ]:        setTypeAheadPeopleReducer,
    [ setTypeAheadAssets ]:        setTypeAheadAssetReducer

};


/** draft selectors */
const getTypeAheadControlState = ( draft, name ) => draft.typeAhead.controlMap[ name ];

/**
 * action creators and async functions are imported by the component and added to mapDispatchToProps,
 * and can then be called by the component's handlers.
 */
export {
    setTypeAheadFilter,
    getTypeAheadControlState,
    TypeAheadControl
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
