import React from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },

    icon: {
        borderRadius:               3,
        width:                      18,
        height:                     18,
        boxShadow:                  'inset 0 0 0 2px #ced4db',
        backgroundColor:            "var(--white)",
        '$root.Mui-focusVisible &': {
            outline:       '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2
        },

        'input:disabled ~ &': {
            background: "var(--lightgrey-4)"
        }
    },

    checkedIcon: {
        boxShadow:       'none',
        backgroundColor: "var(--teal)",
        '&:before':      {
            display:         'block',
            width:           18,
            height:          18,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""'
        },

        'input:disabled ~ &': {
            background: "var(--grey-4)"
        }
    },

    label: {
        fontFamily: "gotham-medium, sans-serif"
    }
});

// Inspired by blueprintjs
const StyledCheckbox = props => {
    const classes = useStyles();

    return (
        <Checkbox
            className={classes.root}
            checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
};

export const LicoCheckBox = props => {
    const {
        children,
        checked = false,
        labelPlacement = "end",
        formPackage,
        onChange,
        ...rest
    } = props;

    const classes = useStyles( props );

    const control = <StyledCheckbox
        checked={checked}
        onChange={formPackage
            ? e => formPackage.onChange({ id: formPackage.id, field: e.target.name, value: e.target.checked })
            : onChange}
        {...rest}
    />;

    return labelPlacement === "none"
        ? control
        : <FormControlLabel className={classes.root}
            labelPlacement={labelPlacement}
            classes={{ label: classes.label }}
            control={control}
            label={<Typography variant="h2" component="span" className={classes.label}>{children}</Typography>} />;
};

LicoCheckBox.propTypes = {
    checked:        PropTypes.bool,
    labelPlacement: PropTypes.oneOf([ "bottom", "top", "end", "start", "none", undefined ]),
    formPackage:    PropTypes.object,
    onChange:       PropTypes.func,
    children:       PropTypes.node
};
