import React, { useState } from 'react';

import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';

import { isOpen, chevron, content, foldable, folderLine, lineTail, text } from '../../../scss/Foldable.module.scss';
import { LicoIcon } from '../images/LicoIcon.jsx';

const Foldable = ({ headline, children }) => {

    const [ open, setOpen ] = useState( true );

    return (
        <div className={foldable}>
            <div className={folderLine} onClick={() => setOpen( !open )}>
                <LicoIcon icon="chevron-right" className={`${chevron} ${open ? isOpen : ''}`}/> <span className={text}>{headline}</span><div className={lineTail}/>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={content}>
                    {children}
                </div>
            </Collapse>
        </div>
    );
};

Foldable.propTypes = {
    headline: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};

export { Foldable };
