import React, { useState, useEffect, useRef, useCallback } from 'react';

import { SECOND } from '@licoriceio/constants';
import { LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { GET, uri } from '../../constants.js';
import { dnsChecker } from '../../scss/DNSChecker.module.scss';
import { abstractedCreateRequest } from '../../services/util/baseRequests.js';
import { __, _$ } from '../../utils/i18n.jsx';

const DNSStatus = {
    SUCCESS:        0,
    IN_PROGRESS:    1,
    FAILURE:        -1,
    ERROR:          -2
};

// Using the same control as Integration was too hard as the CSS used doesn't support
// indeterminate progress bars, for reasons I am at a loss to understand.
const IntegrationProgressClone = withStyles( ( ) => ({
    root: {
        height:       40,
        borderRadius: 0
    },
    colorPrimary: {
        backgroundColor: 'var(--lightgrey-3)'
    },
    bar: {
        borderRadius:    0,
        backgroundColor: 'var(--teal)'
    }
}) )( LinearProgress );

const dnsQuery = ( req => ( type, url ) => req({}, void 0, [ type, url ]).then( result => result.payload ) )( abstractedCreateRequest( GET, uri.DNS_QUERY ) );

/**
 * @param {object} state
 * @param {string} state.url - URL to check
 * @param {( code: number ) => any} state.setFinalStatus - setFinalStatus( code ), where 0 = failure (not found until timeout), 1 = success, -1 = error (bad endpoint etc.)
 */
const CheckDNS = ({ url,
    setFinalStatus, 
    endpoints = [ 'dns.google' ], 
    intervalMsec = 2000, 
    timeoutMsec = SECOND * 30,
    fixedDelaysMsecs,
    fixedDelayLabel
}) => {

    /**
     * These values are slightly strange wrt progress, but they're in sync with finalStatus this way
     * 1 = waiting for DNS to appear
     * 2-100 = waiting fixed period for DNS worker
     * 0 = success, final state
     * -1 = timed out, final state
     * -2 = error, final state
     */
    const [ checkStatus, setCheckStatus ] = useState( 1 );

    const retryIntervalRef = useRef();
    const timeoutTimerRef = useRef();
    const fixedIntervalRef = useRef();

    useEffect( () => {
        if ( checkStatus < 1 )
            setFinalStatus( checkStatus );
    }, [ setFinalStatus, checkStatus ]);

    const finish = useCallback( status => {
        clearInterval( retryIntervalRef.current );
        clearInterval( fixedIntervalRef.current );
        clearTimeout( timeoutTimerRef.current );
        // setFinalStatus( status );
        setCheckStatus( status );
    }, [ setCheckStatus ]);

    const doCheck = useCallback( async () => {
        try {
            const { answers } = await dnsQuery( 'A', url );
            // const { answers } = await query(
            //     {
            //         question: { type: 'A', name: url }
            //     },
            //     {
            //         endpoints
            //     });
            if ( answers.length > 0 ) {

                // did we want a fixed delay after the check?
                if ( fixedDelaysMsecs ) {

                    // we don't want to do checks or time out anymore
                    clearTimeout( timeoutTimerRef.current );
                    clearInterval( retryIntervalRef.current );

                    // not recommended to refresh progress bar faster than 200ms
                    const refreshIntervalMsecs = 200;

                    // increment the status until we reach 100; the useEffect hook
                    // will stop the interval at that point
                    fixedIntervalRef.current = setInterval( () => {
                        setCheckStatus( ( oldCheck ) => {
                            return oldCheck === 100
                                ? DNSStatus.SUCCESS
                                : Math.min( 100, oldCheck + ( 100 / ( fixedDelaysMsecs / refreshIntervalMsecs ) ) );
                        });

                    }, refreshIntervalMsecs );
                }
                else 
                    // if there's no fixed delay, we're done
                    finish( DNSStatus.SUCCESS );
            }

        } 
        catch ( error ) {

            // errors mean the DNS check could not be completed, e.g. bad endpoint (DNS server), no network, etc
            console.error( 'dns error', error );
            finish( DNSStatus.ERROR );
        }
    }, [ url, finish, fixedDelaysMsecs, setCheckStatus ]);

    useEffect( () => {

        retryIntervalRef.current = setInterval( async () => {
            try {
                await doCheck();
            }
            catch ( error ) {
                console.error( 'dns error', error );
            }
        }, intervalMsec );

        // DNS check not working just fake success on timeout
        // timeoutTimerRef.current = setTimeout( () => finish( DNSStatus.SUCCESS ), timeoutMsec );
        
        return () => {
            // clearTimeout( timeoutTimerRef.current );
            clearInterval( retryIntervalRef.current );
        };

    }, [ timeoutMsec, intervalMsec, doCheck, finish ]);

    // don't look up localhost urls. This check must come after all hooks as they can't be called conditionally.
    if ( /localhost:\d+$/.test( url ) ) {
        setFinalStatus( DNSStatus.SUCCESS );
        return null;
    }

    return <div className={dnsChecker} >
        {
            checkStatus === 1 && <div>
                <div>{_$( "Creating instance {url}...", { url })}</div>
                <IntegrationProgressClone />
            </div>
        }
        {
            checkStatus > 1 && checkStatus <= 100 && <div>
                <div>{fixedDelayLabel}</div>
                <IntegrationProgressClone variant="determinate" value={checkStatus} />
            </div>
        }
        {
            checkStatus === DNSStatus.SUCCESS && <div>
                {__( "Instance created successfully" )}
            </div>
        }
        {
            checkStatus === DNSStatus.FAILURE && <div>
                {_$( "Instance {url} was not found; contact Licorice support", { url })}
            </div>
        }
        {
            checkStatus === DNSStatus.ERROR && <div>
                {__( "Network problem; contact Licorice support" )}
            </div>
        }
    </div>;
};

CheckDNS.propTypes = {
    url:                PropTypes.string.isRequired, 
    setFinalStatus:     PropTypes.func.isRequired, 
    endpoints:          PropTypes.array, 
    intervalMsec:       PropTypes.number,
    timeoutMsec:        PropTypes.number,
    fixedDelaysMsecs:   PropTypes.number,
    fixedDelayLabel:    PropTypes.string
};

export default CheckDNS;
export { CheckDNS, DNSStatus };
