/********************************************************************************************************************
 * @file Actions for the notification store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setNotifications = createAction( 'NOTIFICATION.NOTIFICATIONS/SET' );
export const setNotification = createAction( 'NOTIFICATION.NOTIFICATION/SET' );
export const setScrollTop = createAction( 'NOTIFICATION.SCROLL_TOP/SET' );
export const removeNotification = createAction( 'NOTIFICATION.REMOVE/SET' );
