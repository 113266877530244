import React, { useEffect, useState } from "react";

import {  Popover } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AssetDialogMode } from "../../../constants.js";
import { setSelectedAsset } from "../../../redux/actions/index.js";
import { form,
    viewLabel,
    row,
    formFields,
    fieldRow,
    typeDropdown
} from "../../../scss/AssetForm.module.scss";
import { __ } from '../../../utils/i18n.jsx';
import { NumberDropdown } from "../../common/index.js";
import { getAssetTypes } from "../reducer.js";

import AssetFormViewFields from "./AssetFormViewFields.jsx";
import FooterSection from "./FooterSection.jsx";
import HeaderSection from "./HeaderSection.jsx";
const CreateAssetForm = ({ anchorEl, selectedAsset, onClose, setSelectedAsset  }) => {
    const isOpen = Boolean( anchorEl );
    const [ selectedType, setSelectedType ] = useState({});
    const renderTypeOptions = () => {
        return  getAssetTypes().map( ( assetType ) => {
            return { id: assetType.assetTypeId, label: assetType.type };
        });
    };

    // Function to render the field value based on the mode, will be updated when createform rules are ready.
    const viewFormField = ( typeDetails, mode ) => {
        const { details, questions } = typeDetails;
        return  <div className={row}>
            { <AssetFormViewFields sectionName={__( "Details" )} fieldList={details}  mode = {mode} ></AssetFormViewFields>}
            &nbsp;&nbsp;
            { <AssetFormViewFields sectionName={__(   "Technical" )} fieldList={questions}  mode = {mode}></AssetFormViewFields> }
        </div>;
    };

    useEffect( () => {
        const typeDetails = getAssetTypes().find( ( assetType ) => assetType.assetTypeId === selectedType.value );
        setSelectedAsset({ ...typeDetails, mode: AssetDialogMode.ADD });
    }, [ selectedType, setSelectedAsset ]);
    

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical:   'center',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical:   'center',
                horizontal: 'center'
            }}
        >
            <div className={form}>
                <HeaderSection onClose={onClose} ></HeaderSection>
                <div className={formFields}>
                    <div >
                        <div className={fieldRow}>
                            <div className={viewLabel}>Type</div>
                            <NumberDropdown options={renderTypeOptions()}
                                value ={selectedType} minimumwidth={400}
                                setValue={ setSelectedType}
                                inputClassName={typeDropdown}
                            ></NumberDropdown>
                        </div>
                        { viewFormField( selectedAsset, "add" ) }
                    </div>
                </div>
                {<FooterSection onClose={onClose}></FooterSection> }
            </div>
        </Popover>
    );
};
CreateAssetForm.propTypes = {
    anchorEl:           PropTypes.object,
    selectedAsset:      PropTypes.object,
    onClose:            PropTypes.func.isRequired,
    setSelectedAsset:   PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    selectedAsset: state.jobcard.selectedAsset
});

const mapDispatchToProps = {
    setSelectedAsset
};

export default connect( mapStateToProps, mapDispatchToProps )( CreateAssetForm );
