import React, { useMemo } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { timeDisplay, text } from '../../../scss/TimeDisplay.module.scss';
import { P } from '../atomic/typography/index.js';
import { LicoIcon } from '../images/LicoIcon.jsx';

dayjs.extend( relativeTime );

const TimeDisplay = ({ time, refTime }) => {

    const timeString = useMemo( () => dayjs( time ).from( refTime ), [ time, refTime ]);

    return <div className={timeDisplay}>
        <LicoIcon icon="clock" /> <P className={text}>{timeString}</P>
    </div>;
};

TimeDisplay.propTypes = {
    time:       PropTypes.object.isRequired,
    refTime:    PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    refTime:    state.meta.currentTime
});

export default connect( mapStateToProps )( TimeDisplay );
export { TimeDisplay };
