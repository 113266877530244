/********************************************************************************************************************
 * @file Actions for the meta store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setMeta = createAction( 'META.META/SET' );
export const setInvitationStatus = createAction( 'META.INVITATION_STATUS/SET' );
export const setUpdateTime = createAction( 'META.CURRENT_TIME/SET' );
export const setAddToMonitor = createAction( 'META.ADD_MONITOR/SET' );
export const setVersion = createAction( 'META/VERSION/SET' );
