/* eslint-env browser */
/*********************************************************************************************************************
 * @file App reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 8-Jan-2021
 *********************************************************************************************************************/
import { NotificationTypes, Socket, socketTransports } from '@licoriceio/constants';
import io from 'socket.io-client';

import { fetchOneAppointmentRequest, refreshCalendarApptsThunk } from '../components/calendar/reducer.js';
import { getJobActionThunk } from '../components/calendar/thunks.js';
import { notificationViewed } from '../components/notification/reducer.js';
import { store } from '../publicStore.js';
import { setNotification, setAddToMonitor, setFileUploadProgress, setPatchNoteList } from '../redux/actions/index.js';
import { jsonPrint } from '../redux/reducerUtil.js';
import { logSocketMessage } from '../utils/local-storage.js';
import { log as _log } from '../utils/misc.js';

import { integrationComplete, integrationFailed, integrationUpdate } from './integration-updates.js';
import { dataRecordsUpdate } from './live-updates.js';

const dispatch = obj => store.dispatch( obj );
const log = _log( 'notifications' );
const error = e => console.error( e );
const socketAddr = namespace => window.location.hostname + ( window.location.port === '3000' ? ':8086/' : '/' ) + namespace;

let socket;

/**
 * window.location.host + ( window.location.port === '3000' ? ':8086/' : '/' ) + namespace
 * @param {string} namespace
 * @param {SocketIOClient.ConnectOpts} options
 * @return {SocketIOClient.Socket}
 */
const getSocket = ( namespace, options ) => socket || ( socket = io.connect( socketAddr( namespace ), options ) );

function setSocket({ token, userId, cacheDataHandler })
{
    const dispatchNotification = n => {
        log( n );
        if ( n.userId === userId )
            dispatch( notificationViewed({ notification: n }) );
        else 
            dispatch( setNotification( n ) );
    };

    const fetchAppointment = n => {
        dispatchNotification( n );
        dispatch( getJobActionThunk({ jobId: n.data.jobId }) );
        dispatch( fetchOneAppointmentRequest( n.data.appointmentId ) );
    };

    const options = {
        forceNew:             true,
        reconnectionAttempts: Infinity,
        timeout:              10000,
        transportOptions:     {
            extraHeaders: {
                Authorization: `Bearer ${token}`
            }
        },
        transports: socketTransports
    };

    // get namespace from the URL so socket connection is reliable regardless of origin. We don't connect
    // to the socket before login so the namespace should be reliable, ie not us, au, localhost...
    const namespace = window.location.hostname.split( '.' )[ 0 ];

    let isConnected = false;
    try
    {
        getSocket( namespace, options );
    }
    catch ( e )
    {
        console.error( e );
    }

    const missing = x => console.error( `Missing notification handler:`, x );
    const errorNotification = n => {
        n.data = new Error( n.data.message );
        dispatchNotification( n );
    };

    const whatToDo = n => console.warn( n.data.message );   // @ian do something useful here, please

    const handlers = {
        [ NotificationTypes.APPOINTMENT_ASSIGNED ]:         fetchAppointment,
        [ NotificationTypes.INTEGRATION_COMPLETE ]:         integrationComplete,
        [ NotificationTypes.INTEGRATION_FAILED ]:           integrationFailed,
        [ NotificationTypes.INTEGRATION_PROGRESS ]:         integrationUpdate,
        [ NotificationTypes.JOB_CREATED ]:                  dispatchNotification,
        [ NotificationTypes.JOB_STATUS_CHANGED ]:           dispatchNotification,
        [ NotificationTypes.REOPENED_JOB ]:                 dispatchNotification,
        [ NotificationTypes.RESOLVED_JOB ]:                 dispatchNotification,
        [ NotificationTypes.APPOINTMENT_REJECTED ]:         fetchAppointment,
        [ NotificationTypes.DATA_RECORDS_UPDATE ]:          dataRecordsUpdate,
        [ NotificationTypes.PROVIDER_ERROR ]:               errorNotification,
        [ NotificationTypes.APPOINTMENT_CHANGED ]:          dispatchNotification,
        [ NotificationTypes.APPOINTMENT_DELETED ]:          dispatchNotification,
        [ NotificationTypes.JOB_DELETED ]:                  dispatchNotification,
        [ NotificationTypes.TICKETS_IMPORTED ]:             notification => dispatch( refreshCalendarApptsThunk( notification.data ) ),
        [ NotificationTypes.HEARTBEAT_FLATLINE ]:           errorNotification,
        [ NotificationTypes.HEARTBEAT_NORMAL ]:             whatToDo,
        [ NotificationTypes.UPLOAD_PROGRESS ]:              notification => dispatch( setFileUploadProgress( notification.data ) ),
        [ NotificationTypes.SERVER_MESSAGE ]:               whatToDo,
        [ NotificationTypes.ENGINEER_ACTIVATED ]:           dispatchNotification,
        [ NotificationTypes.ENGINEER_DEACTIVATED ]:         dispatchNotification,
        [ NotificationTypes.JOB_ENGINEER_ADDED ]:           dispatchNotification,
        [ NotificationTypes.JOB_ENGINEER_REMOVED ]:         dispatchNotification,
        [ NotificationTypes.JOB_NOTE_ADDED ]:               dispatchNotification,
        [ NotificationTypes.MAPPED_STATUS_NOT_ON_BOARD ]:   dispatchNotification,
        [ NotificationTypes.PROVIDER_STATUS_NOT_SELECTED ]: dispatchNotification,
        [ NotificationTypes.SYNC_PROGRESS ]:                dispatchNotification,
        [ NotificationTypes.NOTES_ADDED ]:                  notification => dispatch( setPatchNoteList( notification.data ) )
    };

    const on = eventName =>
        socket.on( eventName, err => {
            ( err instanceof Error ? error : console.log )( err || `socket ${eventName} on /${namespace}` );
            dispatch( setAddToMonitor({ type: eventName, text: namespace }) );

            ( isConnected = eventName === 'connect' ) && socket.emit( Socket.NEW_USER, { userId });
        });

    [ 'connect', 'connect_error', 'disconnect' ].forEach( on );

    [ ...Object.values( NotificationTypes ) ].forEach( type => socket.on( type, ( ...args ) => { 
        logSocketMessage({ type, args });
        dispatch( setAddToMonitor({ type, text: jsonPrint( args[ 0 ]) }) );
        return ( handlers[ type ] || missing )( ...args );
    }) );
    socket.on( Socket.CACHE_DATA_READY, cacheDataHandler );

    const sendNotification = n => isConnected && socket.emit( Socket.MESSAGE, n );

    const setCacheData = payload => isConnected && socket.emit( Socket.SET_CACHE_DATA, payload );
    const getCacheData = id => isConnected && socket.emit( Socket.GET_CACHE_DATA, id );

    return {
        sendNotification,
        setCacheData,
        getCacheData
    };
}

function shutdownSocket() {
    if ( socket ) {
        socket.off();
        socket.disconnect();
        socket = null;
    }
}

export {
    setSocket,
    shutdownSocket
};
