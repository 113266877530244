import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { uri, POST } from '../../constants.js';
import { UX_REGISTRATION_BETA_KEY } from '../../ux-constants.js';
import { getLoadingStates } from '../../redux/reducerUtil.js';
import { root, accessHeader, accessPending, keyField } from '../../scss/BetaKey.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { LicoTextField } from "../common/index.js";

import { checkBetaKey } from './reducer.js';


const BetaKeyForm = ({ betaKey, checkBetaKey, isPending }) => (
    <div className={`${root} ${isPending && accessPending}`}>
        <div className={accessHeader}>
            {__( "Welcome to Licorice" )}
        </div>
        <br />
        {__( "Licorice is the most advanced customer service interface in the world." )}
        <br />
        <br />
        {__( "Licorice is currently in limited release while we monitor systems and performance." )}
        <br />
        <br />
        {__( "To request access to Licorice please visit:" )}
        <span><a className="" href="https://licorice.io/access"><u>https://licorice.io/access</u></a>.</span>
        <br />
        <br />
        
        <div className={keyField}>
            <LicoTextField
                name="betaKey"
                data-ux={UX_REGISTRATION_BETA_KEY}
                autoFocus
                disabled={isPending}
                label={__( "Enter license key:" )}
                value={betaKey}
                helperText={isPending ? __( "checking..." ) : " "}
                onChange={e => checkBetaKey( e.target.value )}
                mask="hhhh-hhhh-hhhh-hhhh"
            />
            <span className="flexGrow">&nbsp;</span>
        </div>
    </div>
);

BetaKeyForm.propTypes = {
    betaKey:       PropTypes.string.isRequired,
    checkBetaKey:  PropTypes.func.isRequired,
    isPending:     PropTypes.bool
};

const mapStateToProps = state => ({
    betaKey:   state.registration.betaKey,
    isPending: getLoadingStates( state, POST + uri.REGISTRATION_CHECK )[ 0 ]
});

const mapDispatchToProps = { checkBetaKey };

export default connect( mapStateToProps, mapDispatchToProps )( BetaKeyForm );
export { BetaKeyForm };
