import React, { forwardRef } from 'react';

import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoIcon } from '../../images/LicoIcon.jsx';

const useStyles = makeStyles({
    root: {},

    menu_paper: {
        borderRadius:  4,
        color:         "var(--cobalt-3)",
        background:    "var(--grey-0)",
        paddingTop:    0,
        paddingBottom: 0,
        boxShadow:       '0 4px 1px 0 var(--lightgrey-5)'
    },
    menu_list: {
        paddingTop:    3,
        paddingBottom: 3
    },
    menu_item: {
        minWidth:    112,
        paddingLeft: 0,
        height:      28,
        fontSize:    12,
        color:       "var(--white)",
        "&:hover":   {
            background: "var(--black-1)"
        },
        "&:active": {
            "& .menuIcon": {
                background: "var(--white)",
                color:      "var(--lime)"
            }
        }
    },
    menu_item_disabled: {
        color:     "var(--cobalt-3)",
        "&:hover": {
            background: "var(--grey-0)"
        }
    },
    menu_icon: {
        color:         "var(--cobalt-3)",
        borderRadius:  "50%",
        borderWidth:   0,
        fontSize:      16,
        margin:        "0 11px 0 11px",
        width:         16,
        verticalAlign: "-0.25em"
    }
});

export const LicoPopupMenu = props => {
    const { id, anchorPosition, open, onClose, children, anchorEl, anchorOrigin = undefined, transformOrigin = undefined } = props;
    const { menu_paper, menu_list } = useStyles( props );

    return <Menu
        id={id}
        anchorPosition={anchorPosition}
        anchorReference={anchorPosition ? "anchorPosition" : undefined}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={onClose}
        keepMounted
        classes={{
            paper: menu_paper,
            list:  menu_list
        }}
        transitionDuration={0}
    >
        {children}
    </Menu>;
};

LicoPopupMenu.propTypes = {
    id:              PropTypes.string,
    anchorPosition:  PropTypes.any,
    open:            PropTypes.bool,
    onClose:         PropTypes.func,
    children:        PropTypes.node,
    anchorEl:        PropTypes.any,
    anchorOrigin:    PropTypes.object,
    transformOrigin: PropTypes.object
};

export const LicoPopupMenuItem = forwardRef( ( props, ref ) => {
    const { icon, disabled, children, className = '', iconClassName = '', slashed = false, ...rest } = props;
    const classes = useStyles( props );

    return <MenuItem
        className={`${className} ${classes.menu_item} ${disabled ? classes.menu_item_disabled : ''}`}
        ref={ref}
        // seems to be a conflict in the declarations for button; can't leave it to be
        // inserted by "rest"
        // button={undefined}

        {...rest}
    >
        {icon && <LicoIcon icon={icon} className={`${classes.menu_icon} ${iconClassName}`}  slashed={slashed}/>}
        {children}
    </MenuItem >;
});

LicoPopupMenuItem.displayName = 'LicoPopupMenuItem';

// noinspection JSValidateTypes
LicoPopupMenuItem.propTypes = {
    icon:           PropTypes.string,
    disabled:       PropTypes.bool,
    children:       PropTypes.node,
    className:      PropTypes.string,
    iconClassName:  PropTypes.string,
    slashed:        PropTypes.bool
};
