/*********************************************************************************************************************
 * @file Error reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21-Dec-2020
 *********************************************************************************************************************/

import { startRequest, endRequest, setError, setClearError, setClearAllError } from '../actions/index.js';
import { ezRedux } from '../reducerUtil.js';

/**
 * @typedef {object} ErrorState
 * @property {object} uriError
 * @property {object} uriActive
 */

/**
  * @type {ErrorState}
  */
const initialState = Object.freeze({
    uriError:  { test: 1 },
    uriActive: {}
});

const getUriError = ( state, uri ) => state.error.uriError[ uri ];
const getUriErrors = ( state, ...uris ) => uris.map( uri => state.error.uriError[ uri ]);

const uriStatus = ( state, uri ) => Number( state.error.uriActive[ uri ] || 0 );
const uriStatuses = ( state, ...uris ) => uris.map( uri => Number( state.error.uriActive[ uri ] || 0 ) );

const _startRequest = ( draft, uri ) => draft.uriActive[ uri ] = draft.uriActive[ uri ] ? ( draft.uriActive[ uri ] + 1 ) : 1;
const _endRequest = ( draft, uri ) => draft.uriActive[ uri ] = draft.uriActive[ uri ] ? ( draft.uriActive[ uri ] - 1 ) : 0;

const setErrorReducer = ( draft, payload ) => draft.uriError[ payload.uri ] = payload.error;


/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setError ]:         setErrorReducer,
    [ setClearError ]:    ( draft, uri ) => draft.uriError[ uri ] = null,
    [ setClearAllError ]: draft => draft.uriError = { },
    [ startRequest ]:     _startRequest,
    [ endRequest ]:       _endRequest

};

/**
 * action creators and async functions are imported by the component and added to mapDispatchToProps,
 * and can then be called by the component's handlers.
 */
export {
    getUriError,
    getUriErrors,
    uriStatus,
    uriStatuses
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
