import React, { useEffect, useState } from 'react';

import { TextField, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setSettingValueOrganisation, discardOnlineAppointmentsSettingChanges, setAddCAClientMode, setAddCAClient,
    setRemoveCAClient, setAddOrganisationOpenHours, setRemoveOrganisationOpenHours, setSettingValueOrganisationOpenHours } from '../../../redux/actions/index.js';
import { TypeAheadControl } from '../../../redux/reducers/typeAhead.js';
import { settings, screenTitle, boxLabel, infoIcon, fieldNote, centredFlex, priorityMessage, roundIcon, redIcon, greyIcon, numberField, numberFieldLine,
    leftControlBox, controlBox, chipBox, chipBoxChip, chipBoxChipLabel, clientSelector } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import BusinessHoursList from '../../common/BusinessHoursList.jsx';
import DynamicSelect from '../../common/DynamicSelect.jsx';
import GenericDialog, { DialogButtonColors } from '../../common/GenericDialog.jsx';
import { H1, LicoSwitch, LicoIcon, LicoTextField } from "../../common/index.js";
import NumberDropdown from '../../common/NumberDropdown.jsx';
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';
import { SettingsSection, SettingsBox, SaveButton } from '../utilities.jsx';

import { saveOnlineAppointmentsSettingChanges, checkForEligibleEngineers } from './reducer.js';


const switchChange = setter => ( e, checked ) => setter({
    name:      e.target.name,
    value:     checked
});

const textChange = setter => ( e ) => setter({
    name:       e.target.name,
    value:      e.target.value
});

const NumberField = ({ value, label, note, onChange, disabled }) => (
    <span className={`${centredFlex} ${numberFieldLine}`}>
        <LicoTextField
            name={Object.keys( value )[ 0 ]}
            value={String( Object.values( value )[ 0 ])}
            placeholder=""
            inputClassName={numberField}
            label={label}
            labelClassName={boxLabel}
            size="small"
            inlineLabel
            noMargins
            disabled={disabled}
            inputProps={{
                maxLength:  3
            }}
            onChange={onChange}
            onBlur={() => {
                if ( Object.values( value )[ 0 ] === '' )
                    onChange({ target: { name: Object.keys( value )[ 0 ], value: 0 } });
            }}
        />
        {note}
    </span>
);

NumberField.propTypes = {
    value:    PropTypes.any,
    label:    PropTypes.string,
    note:     PropTypes.any,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

const CompanyChip = ({ companyId, companyName, deleteAction }) => (
    <Chip
        deleteIcon={<LicoIcon icon="close" />}
        label={companyName}
        classes={{
            root:   chipBoxChip,
            label:  chipBoxChipLabel
        }}
        onDelete={deleteAction ? () => deleteAction( companyId ) : undefined}
    />
);

CompanyChip.propTypes = {
    companyId:    PropTypes.string.isRequired,
    companyName:  PropTypes.string.isRequired,
    deleteAction: PropTypes.func
};

const RoundIcon = ({ icon, size, className, onClick }) => (
    <div className={`${roundIcon} ${className}`} style={{ height: size, width: size }} onClick={onClick}>
        <LicoIcon icon={icon} />
    </div>
);

RoundIcon.propTypes = {
    icon:      PropTypes.string,
    size:      PropTypes.number.isRequired,
    className: PropTypes.string,
    onClick:   PropTypes.func
};

const warningMessage = __( "Enabling Client Scheduling will cause Licorice to reply to new client-generated tickets received via email, is this okay?" );
const disableWarningMessage = __( "Disabling Client Scheduling will cause Licorice to stop replying to new client-generated tickets received via email, is this okay?" );

const ClientAppointmentSettings = ({
    onlineAppointmentsEnabled,
    useEnabledClients,
    enabledClients,
    criticalPriorityMessage,
    defaultDuration,
    minimumLeadTimeHours,
    maximumLeadTimeDays,
    numberEligibleEngineers = -1,
    prePadding,
    postPadding,
    foundChanges,
    setSettingValueOrganisation,
    saveOnlineAppointmentsSettingChanges,
    checkForEligibleEngineers,
    discardOnlineAppointmentsSettingChanges,
    addClientApptClient,
    setAddCAClientMode,
    setAddCAClient,
    setRemoveCAClient,
    history,
    fieldError,
    openHours,
    setAddOrganisationOpenHours,
    setRemoveOrganisationOpenHours,
    setSettingValueOrganisationOpenHours
}) => {

    useEffect( () => { const checkEligibleEngineers = async () => {
        await checkForEligibleEngineers();
    };
    checkEligibleEngineers();
    }, [ checkForEligibleEngineers ]);


    const [ onlineAppointmentsDefaultValue, setOnlineAppointmentsDefaultValue ] = useState( undefined );
    const [ confirmationOpen, setConfirmationOpen ] = useState( false );
    const changingThisTime = ( onlineAppointmentsDefaultValue !== onlineAppointmentsEnabled );

    const fullWarning = <>{warningMessage}<br/><br/>{__( 'Eligible Engineers' )}: {numberEligibleEngineers >= 0 ? numberEligibleEngineers : "..."}<br/></>;
    const disableFullWarning = <>{disableWarningMessage}<br/><br/>{__( 'Eligible Engineers' )}: {numberEligibleEngineers >= 0 ? numberEligibleEngineers : "..."}<br/></>;

    useEffect( () => {
        if ( onlineAppointmentsDefaultValue === undefined ) 
            setOnlineAppointmentsDefaultValue( onlineAppointmentsEnabled );
        
    }, [ onlineAppointmentsEnabled ]);

    return <SettingsLayout>

        <PreventTransitionPrompt
            when={foundChanges}
            history={history}
            saveAction={saveOnlineAppointmentsSettingChanges}
            discardAction={discardOnlineAppointmentsSettingChanges}
            extraContent={changingThisTime ? fullWarning : undefined}
        />

        <GenericDialog
            isOpen={confirmationOpen && changingThisTime}
            title={onlineAppointmentsEnabled ? __( "Enable Client Scheduling?" ) : __( "Disable Client Scheduling?" )}
            message={onlineAppointmentsEnabled ? fullWarning : disableFullWarning}
            buttons={[
                {
                    label:          __( "Okay" ),
                    action:         () => {
                        setConfirmationOpen( false );
                        setOnlineAppointmentsDefaultValue( onlineAppointmentsEnabled );
                        saveOnlineAppointmentsSettingChanges();
                    },
                    color:          DialogButtonColors.safety
                },
                {
                    label:          __( "Cancel" ),
                    action:         () => setConfirmationOpen( false ),
                    color:          DialogButtonColors.cancel
                }
            ]}
        />


        <div className={settings}>
            <H1 className={screenTitle}>{__( "Client Scheduling" )}</H1>
            {onlineAppointmentsEnabled !== undefined && <>
                <SettingsSection label={ __( "Enable Client Scheduling Interface" ) }>
                    <div className={centredFlex} >
                        <LicoSwitch
                            name="onlineAppointmentsEnabled"
                            checked={onlineAppointmentsEnabled}
                            onChange={switchChange( setSettingValueOrganisation )}
                        />
                        {__( "When a user creates a new ticket, email them to book an appointment" )}
                        <LicoIcon className={infoIcon} icon="info" tooltip={__( "This is the master switch for Client Scheduling" )}/>

                    </div>
                    <div className={leftControlBox}>
                        <LicoSwitch
                            name="useEnabledClients"
                            checked={useEnabledClients}
                            onChange={switchChange( setSettingValueOrganisation )}
                            disabled={!onlineAppointmentsEnabled}
                        />
                        <div className={controlBox}>
                            <div className={centredFlex} >
                                {__( "Enable Client Scheduling for these clients only:" )}
                                <LicoIcon className={infoIcon} icon="info" tooltip={__( "This enables selective use of the Client Scheduling feature (e.g. for testing)" )} />
                            </div>
                            <div className={chipBox}>
                                {Object.entries( enabledClients ).map( ([ companyId, companyName ]) => <CompanyChip
                                    key={companyId}
                                    companyId={companyId}
                                    companyName={companyName}
                                    deleteAction={onlineAppointmentsEnabled && useEnabledClients ? setRemoveCAClient : undefined}
                                /> )}
                                {onlineAppointmentsEnabled && useEnabledClients && !addClientApptClient &&
                                <RoundIcon icon="add" size={30} className={greyIcon} onClick={() => setAddCAClientMode( true )}/>}
                                {onlineAppointmentsEnabled && useEnabledClients && addClientApptClient && <div className={clientSelector}>
                                    <DynamicSelect
                                        name={TypeAheadControl.companyId}
                                        autoFocus
                                        filterBy={data => data.filter( item => !item.company.userId )}
                                        onChange={( event, value ) => {
                                            setAddCAClient( value );
                                            setAddCAClientMode( false );
                                        }}
                                        onBlur={() => setAddCAClientMode( false )}
                                    />
                                </div>}
                            </div>
                        </div>

                    </div>
                </SettingsSection>
                <SettingsSection label={ __( "Client Scheduling Defaults" ) }>
                    <SettingsBox label={__( "Critical priority message" )}>
                        <span className={centredFlex}>
                            <RoundIcon icon="building" size={37} className={redIcon} />
                            <TextField
                                disabled={!onlineAppointmentsEnabled}
                                name="criticalPriorityMessage"
                                value={criticalPriorityMessage}
                                onChange={textChange( setSettingValueOrganisation )}
                                classes={{ root: priorityMessage }}
                                variant="outlined"
                                size="small"
                            />
                            <LicoIcon
                                className={infoIcon}
                                icon="info"
                                tooltip={__( "When a user attempts to schedule an appointment as Critical priority, this message will be displayed" )}
                            />
                        </span>
                    </SettingsBox>
                    <div className={centredFlex}>
                        <NumberDropdown
                            disabled={!onlineAppointmentsEnabled}
                            label={__( "Appointment duration" )}
                            value={{ defaultDuration }}
                            options={[ 15, 30, 45, 60 ].map( value => ({ id: value, label: value === 60 ? '1 ' + __( 'hour' ) : __( '{value} minutes', { value }) }) )}
                            labelClassName={boxLabel}
                            inputClassName='slim'
                            setValue={setSettingValueOrganisation}
                        />
                        <span className={fieldNote}>(Recommended 1 hour)</span>
                    </div>
                </SettingsSection>
                <SettingsSection label={ __( "Client Scheduling Calendar" ) }>
                    {openHours && <SettingsBox label={__( "Open Hours" )} topLabel>
                        <BusinessHoursList
                            businessHours={openHours}
                            setValue={setSettingValueOrganisationOpenHours}
                            addItem={setAddOrganisationOpenHours}
                            removeItem={setRemoveOrganisationOpenHours}
                            error={fieldError.openHours}
                        />
                    </SettingsBox>
                    }
                </SettingsSection>
                <SettingsSection label={ __( "Client Scheduling Rules" ) }>
                    <NumberField
                        disabled={!onlineAppointmentsEnabled}
                        value={{ minimumLeadTimeHours }}
                        label={__( "Require a minimum of" )}
                        note={__( "hours before a new appointment" )}
                        onChange={textChange( setSettingValueOrganisation )}
                    />
                    <NumberField
                        disabled={!onlineAppointmentsEnabled}
                        value={{ maximumLeadTimeDays }}
                        label={__( "Clients can schedule up to" )}
                        note={__( "calendar days into the future" )}
                        onChange={textChange( setSettingValueOrganisation )}
                    />
                    <NumberField
                        disabled={!onlineAppointmentsEnabled}
                        value={{ prePadding }}
                        label={__( "Add padding of" )}
                        note={__( "minutes before each appointment" )}
                        onChange={textChange( setSettingValueOrganisation )}
                    />
                    <NumberField
                        disabled={!onlineAppointmentsEnabled}
                        value={{ postPadding }}
                        label={__( "Add padding of" )}
                        note={__( "minutes after each appointments" )}
                        onChange={textChange( setSettingValueOrganisation )}
                    />
                </SettingsSection>
                <SaveButton handler={changingThisTime ? () => setConfirmationOpen( true ) : saveOnlineAppointmentsSettingChanges} disabled={!foundChanges} />
            </>}
        </div>
    </SettingsLayout>;
};

ClientAppointmentSettings.propTypes = {
    onlineAppointmentsEnabled:                  PropTypes.bool,
    useEnabledClients:                          PropTypes.bool,
    enabledClients:                             PropTypes.object,
    criticalPriorityMessage:                    PropTypes.string,
    defaultDuration:                            PropTypes.number,
    minimumLeadTimeHours:                       PropTypes.number,
    maximumLeadTimeDays:                        PropTypes.number,
    prePadding:                                 PropTypes.number,
    postPadding:                                PropTypes.number,
    numberEligibleEngineers:                    PropTypes.number,
    foundChanges:                               PropTypes.bool,
    setSettingValueOrganisation:                PropTypes.func.isRequired,
    saveOnlineAppointmentsSettingChanges:       PropTypes.func.isRequired,
    checkForEligibleEngineers:                  PropTypes.func.isRequired,
    discardOnlineAppointmentsSettingChanges:    PropTypes.func.isRequired,
    addClientApptClient:                        PropTypes.bool,
    setAddCAClientMode:                         PropTypes.func.isRequired,
    setAddCAClient:                             PropTypes.func.isRequired,
    setRemoveCAClient:                          PropTypes.func.isRequired,
    history:                                    PropTypes.object.isRequired,
    fieldError:                                 PropTypes.object.isRequired,
    openHours:                                  PropTypes.array,
    setAddOrganisationOpenHours:                PropTypes.func.isRequired,
    setRemoveOrganisationOpenHours:             PropTypes.func.isRequired,
    setSettingValueOrganisationOpenHours:       PropTypes.func.isRequired
};


const mapStateToProps = state => {

    const { organisation: { foundChanges, addClientApptClient, numberEligibleEngineers, fieldError, edit } } = state || {};
    if ( !edit )
        return { fieldError };
    const {
        onlineAppointmentsEnabled,
        useEnabledClients,
        enabledClients,
        criticalPriorityMessage,
        defaultDuration,
        minimumLeadTimeHours,
        maximumLeadTimeDays,
        prePadding,
        postPadding,
        openHours
    } = edit;

    return {
        foundChanges,
        numberEligibleEngineers,
        addClientApptClient,
        onlineAppointmentsEnabled,
        useEnabledClients,
        enabledClients,
        criticalPriorityMessage,
        defaultDuration,
        minimumLeadTimeHours,
        maximumLeadTimeDays,
        prePadding,
        postPadding,
        fieldError,
        openHours
    };
};

const mapDispatchToProps = {
    setSettingValueOrganisation,
    saveOnlineAppointmentsSettingChanges,
    checkForEligibleEngineers,
    discardOnlineAppointmentsSettingChanges,
    setAddCAClientMode,
    setAddCAClient,
    setRemoveCAClient,
    setAddOrganisationOpenHours,
    setRemoveOrganisationOpenHours,
    setSettingValueOrganisationOpenHours
};

export default connect( mapStateToProps, mapDispatchToProps )( ClientAppointmentSettings );
export { ClientAppointmentSettings };
