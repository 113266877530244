import React, { useState } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import { POST, uri } from '../../constants.js';
import { UX_REMEMBER_SESSION, UX_FORGOT_PASSWORD, UX_LOGIN_BUTTON } from "../../ux-constants.js";
import { setFormValueLogin, setTouchedLogin } from '../../redux/actions/index.js';
import { getLoadingStates } from '../../redux/reducerUtil.js';
import { infoIcon } from '../../scss/NamePassword.module.scss';
import * as scss from '../../scss/NamePassword.module.scss';
import { __ } from "../../utils/i18n.jsx";
import LicoButton from '../common/atomic/form/LicoButton.jsx';
import { LicoTextField, LicoCheckBox, LicoIcon, BUTTON_LOGIN, LicoLink, P } from "../common/index.js";
import { FORGOT_PASSWORD } from '../navigation/routes.js';

import { setLoginError } from './reducer.js';

const { forgotLink } = scss;

const NamePassword = ({ loadingLogin, onDone, setLoginError, passwordVerified }) => {
    const [ email, setEmail ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ touchedPassword, setTouchedPassword ] = useState( false );
    const [ rememberSession, setRememberSession ] = useState( false );

    const valid = () => isEmail( email ) && password.length > 0;

    return (
        <>
            <form>
                <LicoTextField
                    type="email"
                    name="email"
                    label={__( "Email" )}
                    value={email}
                    allowLastPass={true}
                    autoFocus
                    autoComplete="on"
                    helperText={email && !isEmail( email ) ? __( 'Not a valid email address' ) : ''}
                    error={email.length > 0 && !isEmail( email )}
                    onChange={e => {
                        setEmail( e.target.value );
                        setLoginError( '' );
                    }}
                    onKeyPress={e => e.key === 'Enter' && valid() && onDone({ email, password, rememberSession })}
                />

                <LicoTextField
                    type="password"
                    name="password"
                    label={__( "Password" )}
                    value={password}
                    allowLastPass={true}
                    autoComplete="on"
                    helperText={touchedPassword && password.length === 0 ? __( 'You must provide a password' ) : ''}
                    error={touchedPassword && password.length === 0}
                    onChange={e => {
                        setPassword( e.target.value );
                        setLoginError( '' );
                    }}
                    onBlur={() => setTouchedPassword( true )}
                    onKeyPress={e => e.key === 'Enter' && valid() && onDone({ email, password, rememberSession })}
                />
            </form>

            <LicoCheckBox
                name="rememberSession"
                onChange={e => setRememberSession( e.target.checked )}
                style={{ left: "-5px" }}
                checked={rememberSession}
                data-ux={UX_REMEMBER_SESSION}
            >{__( "Keep me logged in  " )}
                <LicoIcon
                    className={infoIcon}
                    icon="info"
                    tooltip={__( "When cleared, Licorice clears all login data on tab refresh/close. Be careful using this when logging on from a client's PC." )}
                />
            </LicoCheckBox>

            <div className={forgotLink}>
                <P variant="body2">
                    <LicoLink
                        to={FORGOT_PASSWORD}
                        className={forgotLink}
                        dataName={UX_FORGOT_PASSWORD}
                    >{__( "Forgot password?" )}</LicoLink>
                </P>

            </div>

            <LicoButton
                type="button"
                onClick={e => onDone({ email, password, rememberSession, timestamp: dayjs().toISOString(), ignoreSession: e.shiftKey })}
                data-ux={UX_LOGIN_BUTTON}
                disabled={loadingLogin || !email.length || !password.length || passwordVerified}
                disableDuring={[ POST + uri.USER_SETTINGS, POST + uri.LOGIN ]}
            >
                {BUTTON_LOGIN}
            </LicoButton>
        </>
    );
};

NamePassword.propTypes = {
    onDone:                 PropTypes.func.isRequired,
    setLoginError:          PropTypes.func.isRequired,
    loadingLogin:           PropTypes.bool,
    passwordVerified:       PropTypes.bool
};

const mapStateToProps = state => {
    const [ loadingLogin, loadingTerms ] = getLoadingStates( state, POST + uri.LOGIN, POST + uri.UPDATE_TERMS );

    const { email, password, rememberSession, fieldTouched, fieldError, settings } = state.login;

    return {
        email, password, rememberSession, fieldTouched, fieldError,
        loadingLogin,
        loadingTerms,
        passwordVerified:   settings?.password?.verified
    };
};

const mapDispatchToProps = {
    setFormValueLogin,
    setTouchedLogin,
    setLoginError
};

export default connect( mapStateToProps, mapDispatchToProps )( NamePassword );
export { NamePassword };
