/*********************************************************************************************************************
 * @file search Panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React from "react";

import { List } from "@mui/material";
import PropTypes from "prop-types";

import { LicoPopupMenu } from "../common/index.js";


const JobStatusPopover = ({ anchorEl, classes, jobStatus, map, onClose, open }) => {
    return <div>
        <LicoPopupMenu
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical:   "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical:   "top",
                horizontal: "left"
            }}
        >
            <List className={classes.list}>
                {jobStatus && map}
            </List>
        </LicoPopupMenu>
    </div>;
};

JobStatusPopover.propTypes = {
    open:      PropTypes.bool.isRequired,
    anchorEl:  PropTypes.any,
    onClose:   PropTypes.func.isRequired,
    classes:   PropTypes.any.isRequired,
    jobStatus: PropTypes.any.isRequired,
    map:       PropTypes.arrayOf( PropTypes.any ).isRequired
};

export default JobStatusPopover;
