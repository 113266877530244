/********************************************************************************************************************
 * @file Actions for the calendar store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';


export const setAddUserCalendar =               createAction( 'CALENDAR.ADD_USER_CALENDAR/SET' );
export const setDeleteUserCalendar =            createAction( 'CALENDAR.DELETE_USER_CALENDAR/SET' );
export const setAddAppointments =               createAction( 'CALENDAR.APPOINTMENTS/SET' );
export const setDeleteAppointment =             createAction( 'CALENDAR.DELETE_APPOINTMENT/SET' );
export const setPatchAppointment =              createAction( 'CALENDAR.PATCH_APPOINTMENT/SET' );
export const setDeleteJobAppointments =         createAction( 'CALENDAR.JOB_APPOINTMENTS_REMOVE/SET' );
export const setDragInfo =                      createAction( 'CALENDAR.DRAG_INFO/SET' );
export const setDateRange =                     createAction( 'CALENDAR.DATE_RANGE/SET' );
export const setExpandCalendarUp =              createAction( 'CALENDAR.EXPAND_UP/SET' );
export const setExpandCalendarDown =            createAction( 'CALENDAR.EXPAND_DOWN/SET' );
export const setCalendarViewDates =             createAction( 'CALENDAR.DATES/SET' );
export const makeBusinessHourEvents =           createAction( 'CALENDAR.MAKE_BUSINESS_HOUR_EVENTS/SET' );
export const setUpdateAppointmentStatics =      createAction( 'CALENDAR.UPDATE_APPOINTMENTS/SET' );
export const setUpdateCalendarEventStatics =    createAction( 'CALENDAR.UPDATE_CALENDAR_EVENTS/SET' );
export const setOtherUserTimeLogs =             createAction( 'CALENDAR.OTHER_USER_TIMELOGS/SET' );
export const patchOtherUserTimeLogs =           createAction( 'CALENDAR.OTHER_USER_TIMELOGS/PATCH' );
export const clearCalendars =                   createAction( 'CALENDAR.CLEAR_CALENDARS/SET' );
