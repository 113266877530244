import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { POST, uri } from '../../constants.js';
import { UX_UPDATE_TERMS } from "../../ux-constants.js";
import { setFormValueLogin } from '../../redux/actions/index.js';
import { getLoadingStates } from '../../redux/reducerUtil.js';
import { root, headingText, bodyText, link, buttonDiv, submitButton } from '../../scss/UserTerms.module.scss';
import { __ } from "../../utils/i18n.jsx";
import { LicoCheckBox, LicoButton, BUTTON_ACCEPT, EULA, H2, LicoStaticLink, PRIVACY, TERMS } from "../common/index.js";

import { updateTerms } from './reducer.js';


const UserTerms = props => {
    const {
        eula,
        termsConditions,
        privacyPolicy,
        loadingTerms,
        setFormValueLogin,
        updateTerms
    } = props;

    const acceptDisabled = !( eula && termsConditions && privacyPolicy );

    return (
        <form className={root}>

            <H2 component="p" className={headingText}>
                {__( "First-time login message" )}
            </H2>
            <H2 component="p" className={bodyText}>
                {__( "Since this is your first time logging in, you must accept Licorice's policies and license terms to continue." )}
            </H2>
            <H2 component="p" className={bodyText}>
                {__( "By checking each of the boxes below, you confirm that you have read, understood and agree to be bound by Licorice’s policies and licence terms. " )}
                {__( "If you do not agree to these you must immediately cease using Licorice’s services:" )}
            </H2>

            <LicoCheckBox
                name="eula"
                onChange={e => setFormValueLogin({
                    field: e.target.name,
                    value: e.target.checked
                })}
                checked={eula}
            >
                {__( "I have read and agree to Licorice’s " )}
                <LicoStaticLink to={EULA} target="_blank" className={link}>
                    {__( "End User License Agreement" )}
                </LicoStaticLink>
            </LicoCheckBox>

            <LicoCheckBox
                name="termsConditions"
                onChange={e => setFormValueLogin({
                    field: e.target.name,
                    value: e.target.checked
                })}
                checked={termsConditions}
            >
                {__( "I have read and agree to Licorice’s " )}
                <LicoStaticLink to={TERMS} target="_blank" className={link}>
                    {__( "Terms & Conditions" )}
                </LicoStaticLink>
            </LicoCheckBox>

            <LicoCheckBox
                name="privacyPolicy"
                onChange={e => setFormValueLogin({
                    field: e.target.name,
                    value: e.target.checked
                })}
                checked={privacyPolicy}
            >
                {__( "I have read and agree to Licorice’s " )}
                <LicoStaticLink to={PRIVACY} target="_blank" className={link}>
                    {__( "Privacy Policy" )}
                </LicoStaticLink>
            </LicoCheckBox>

            <div className={buttonDiv}>
                <LicoButton
                    type="button"
                    data-ux={UX_UPDATE_TERMS}
                    className={submitButton}
                    disabled={loadingTerms || acceptDisabled}
                    onClick={updateTerms}
                > {BUTTON_ACCEPT} </LicoButton>
            </div>
        </form>
    );
};

UserTerms.propTypes = {
    eula:              PropTypes.bool.isRequired,
    termsConditions:   PropTypes.bool.isRequired,
    privacyPolicy:     PropTypes.bool.isRequired,
    loadingTerms:      PropTypes.bool,
    setFormValueLogin:    PropTypes.func.isRequired,
    updateTerms:       PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const [ loadingTerms ] = getLoadingStates( state, POST + uri.UPDATE_TERMS );

    return {
        eula:            state.login.eula,
        termsConditions: state.login.termsConditions,
        privacyPolicy:   state.login.privacyPolicy,
        fieldTouched:    state.login.fieldTouched,
        error:           state.login.error,
        loadingTerms
    };
};

const mapDispatchToProps = {
    setFormValueLogin,
    updateTerms
};

export default connect( mapStateToProps, mapDispatchToProps )( UserTerms );
export { UserTerms };
