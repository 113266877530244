import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Lightbox } from "react-modal-image";
import { connect } from 'react-redux';
import { Transforms } from 'slate';
import { 
    useSlateStatic,
    useSelected,
    useFocused,
    ReactEditor
} from 'slate-react';

import loading32 from '../../../images/loading32.gif';
import { editorImage, imageBox, image, selectedImage, deleteButton } from '../../../scss/EditorImage.module.scss';
import { BASE_URL } from '../../../services/util/baseRequests';
import { LicoIcon } from '../images/LicoIcon.jsx';


const EditorImage = ({ attributes, children, element, auth }) => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath( editor, element );

    const [ popupOpen, setPopupOpen ] = useState( false );
  
    const selected = useSelected();
    const focused = useFocused();

    const [ imageData, setImageData ] = useState( loading32 );

    const callFileApi = useCallback( method => fetch( `${BASE_URL}/resource/${element.url}`, {
        method,
        headers: {
            'Authorization':    `Bearer ${auth.token}`
        }
    }), [ element.url, auth.token ]);

    useEffect( () => {

        callFileApi( 'GET' )
            .then( async res => {
                if ( res.ok ) {
                    var blob = await res.blob();
                    const reader = new FileReader();
                    reader.addEventListener( 'load', () => {
                        const url = reader.result;
                        setImageData( url );
                    });
                    reader.readAsDataURL( blob );
                }
            })
            .catch( ( err ) => {
                console.log( err );
            });
    }, [ callFileApi ]);

    return (
        <div {...attributes} className={editorImage}>
            {popupOpen && <Lightbox 
                large={imageData} 
                onClose={() => setPopupOpen( false )} 
                hideZoom
                hideDownload
                showRotate
            />}
            {children}
            <div
                contentEditable={false}
                className={imageBox}
                onClick={() => setPopupOpen( true )}
            >
                <img
                    src={imageData}
                    alt={element.title}
                    className={`${image} ${selected && focused ? selectedImage : ''}`}
                />
                {selected && focused && <span
                    className={deleteButton}
                    onClick={ async () => {
                        Transforms.removeNodes( editor, { at: path });
                        await callFileApi( 'DELETE' );
                    }}
                >
                    <LicoIcon icon="delete" />
                </span>}
            </div>
        </div>
    );
};
EditorImage.propTypes = {
    attributes:     PropTypes.any,
    children:       PropTypes.any,
    element:        PropTypes.object,
    auth:           PropTypes.object
};

const mapStateToProps = state => ({
    auth:   state.auth
});

export default connect( mapStateToProps )( EditorImage );
export { EditorImage };
