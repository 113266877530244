import React, { PureComponent } from 'react';

import { ADD, DELETE } from '@licoriceio/constants';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userDialogMode } from '../../constants.js';
import { setUserDialog } from '../../redux/actions/index.js';
import { TypeAheadControl } from '../../redux/reducers/typeAhead.js';
import { getJobCompany } from '../../redux/selectors/index.js';
import { chipContainer, addLine, selector, hidden, visible, warning, userChip, noDeleteIcon, grabber } from '../../scss/DynamicChipAdder.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { SelectStyle } from '../../utils/misc.js';
import { H3, LicoSmallButton, LicoIcon } from '../common/index.js';

import DynamicSelect from './DynamicSelect.jsx';
import UserChip from './UserChip.jsx';
import UserDetailsForm from './UserDetailsForm.jsx';

const NEW_USER_ID = "newUser";

/**
 * @typedef {object} DynamicChipAdderParentProps
 * @property {string} name
 * @property {string} label
 * @property {any} storeAction
 * @property {string} id
 * @property {string} [storeListName]
 * @property {object[]} existingOptions
 * @property {number} [minimumFilterLength]
 * @property {React.ReactNode} [helperText]
 * @property {boolean} [error]
 * @property {object} [extraData]
 * @property {boolean} [preventLoneChipDelete]
 * @property {string} [preventUserIdDelete]
 * @property {string} [preventUserIdDeleteMessage]
 * @property {string} [preventDeleteMessage]
 */

/**
 * @typedef {object} DynamicChipAdderState
 * @property {boolean} inputOpen
 */

/**
 * BE VERY AWARE; this is allegedly a generic control, but the only places it's currently used is to display
 * users; the engineer and user lists in the job card. Using it for other purposes should be done with care.
 * 
 * When the control start, it should display a list of current values. As the list is changed via adds or deletes,
 * the control state refreshes and also updates the backend accordingly. In addition to this, the control wraps a typeahead control
 * for which another API is required, although we don't pass formPackage as changes to this child table won't go
 * thru the parent record, ie adding an engineer to a job doesn't change the job.
 *
 * The control has a list of existing options, a button and a dynamic search control. The button opens the dynamic search control
 * which loads from the supplied API. Selections from the search control are added locally and the new item patched via
 * the supplied patch API. Existing elements can also be removed via their chip, which also results in a patch.
 */

class DynamicChipAdder extends PureComponent
{
    constructor( props )
    {
        super( props );

        this._addLineRef = React.createRef();
        this.state = {
            inputOpen:      false
        };
    }

    render() {
        const {
            name,
            label,
            heading,
            storeAction,
            storeId,
            storeListName = 'people',
            extraData,
            existingOptions,
            existingOptionWarnings = [],
            currentDetailUserId,
            companyName,
            setUserDialog,
            preventLoneChipDelete = false,
            preventDeleteMessage = '',
            preventAdd,
            preventUserIdDelete,
            preventUserIdDeleteMessage,
            preventDeleteCallback,
            dataNameAdd,
            dataNameList,
            dataNameChipList,
            dataNameChipItem,
            dataNameChipItemDelete,
            ownerUserId,
            setOwnerUser,
            loginUserId,
            grabAction
        } = this.props;

        const {
            inputOpen
        } = this.state;

        // if ownerUserId is set, that user is labelled bold (done in UserChip) and must come first in the list (done here).
        // Since we're sorting, we sort the remaining options by label.
        const options = existingOptions 
            ? existingOptions.slice().sort( ( a, b ) => {
                if ( ownerUserId ) {
                    if ( a.id === ownerUserId ) return -1;
                    if ( b.id === ownerUserId ) return 1;
                }
                return ( a.label ?? '' ).localeCompare( b.label );
            }) 
            : [];

        const classSel = selector + ' ' + ( inputOpen ? visible : hidden );
        const deleteOk = ( !preventLoneChipDelete || options.length > 1 ) && !preventDeleteMessage;

        // there may be a number of warning conditions, each with a test and a message.
        // We have to find out which warnings should be displayed, and which users should be highlighted to indicate a warning applies to them.
        // The affected user names are also tracked in case we have more than one warning to display, in which case highlighting the user
        // won't convey the full information.
        let warningIndexes = [];
        let optionWarningIds = {};
        const affectedUsers = [];
        if ( existingOptionWarnings.length > 0 ) {
            existingOptionWarnings.forEach( ( warning, w ) => {
                let warnOnOption = options.filter( warning.test );
                if ( warnOnOption.length ) {
                    warningIndexes.push( w );
                    affectedUsers[ w ] = '(' + warnOnOption.map( option => option.item.name.trim() ).join( ', ' ) + ')';
                    warnOnOption.reduce( ( acc, cur ) => {
                        acc[ cur.item.userId ] = true;
                        return acc;
                    }, optionWarningIds );
                }
            });
        }

        // note that a tooltip with a blank title is simply ignored, so we don't need to leave it out of the structure
        // if it's blank.
        let warningTooltip = "";
        const warningsFound = warningIndexes.length > 0;        
        if ( warningsFound ) { 
            warningTooltip = <div>
                {warningIndexes.map( i => <div key={i}>
                    {existingOptionWarnings[ i ].message}&nbsp;{warningIndexes.length > 1 ? affectedUsers[ i ] : ''}
                </div> )}
            </div>; 
        }

        return <>
            <H3 className={warningsFound ? warning : null}>
                {heading}
                &nbsp;
                {grabAction && loginUserId !== ownerUserId && <Tooltip
                    title={__( "Grab Job (add/assign yourself as Owner)." )}
                    placement="bottom-start"
                    arrow
                >
                    <span className={grabber} onClick={() => grabAction( loginUserId )}><LicoIcon icon="hand" /></span>
                </Tooltip>
                }
                {warningsFound && <Tooltip
                    title={warningTooltip}
                    placement="top-start"
                    arrow
                >
                    <span>
                        <LicoIcon icon="warning-circle"/>
                    </span>
                </Tooltip> }
            </H3>
            {options && <div>
                <div className={chipContainer} data-ux={dataNameChipList}>
                    {options.map( ( option, index ) => {
                        let isDeleteEnable = deleteOk && ( !preventUserIdDelete || option.id !== preventUserIdDelete );
                        let preventDeleteCallbackMessage;
                        if ( isDeleteEnable && preventDeleteCallback ) {
                            preventDeleteCallbackMessage = preventDeleteCallback( option.id );
                            if ( preventDeleteCallbackMessage )
                                isDeleteEnable = false;
                        }
                        return <UserChip
                            label={option.label}
                            key={index}
                            user={option.item}
                            avatar={option.avatar}
                            className={`${userChip} ${!isDeleteEnable ? noDeleteIcon : ''} ${optionWarningIds[ option.item.userId ] ? warning : ''}`}
                            onDelete={isDeleteEnable ? () => {
                                storeAction({
                                    data: {
                                        action:            DELETE,
                                        [ storeListName ]: [ option.id ]
                                    },
                                    id:   storeId
                                });
                            } : undefined}
                            preventDeleteMessage={preventDeleteMessage || preventUserIdDeleteMessage || preventDeleteCallbackMessage}
                            disabled={false}
                            dataName={dataNameChipItem}
                            dataNameDelete={dataNameChipItemDelete}
                            isDeleteEnable={isDeleteEnable}
                            isOwner={option.id === ownerUserId}
                            ownerUserId={ownerUserId}
                            setOwnerUser={setOwnerUser}
                        />;
                    })}
                </div>

                {!preventAdd && <div className={addLine} ref={this._addLineRef}>
                    <LicoSmallButton 
                        fabClass="grey-background" 

                        // hide the label when the list is open to save space
                        label={ inputOpen ? '' : label} 
                        dataName={dataNameAdd}
                        onClick={() => {
                            this.setState( prevState => ({ inputOpen: !prevState.inputOpen }) );
                            setTimeout( () => document.getElementById( name )?.focus(), 100 );
                        }} 
                    />

                    <div className={classSel}>
                        <DynamicSelect
                            name={name}
                            autoFocus={true}
                            initialText={""}
                            style={SelectStyle.chipAdder}
                            excludedIds={options.map( option => option.id )}
                            extraData={extraData}
                            onChange={( event, value ) =>
                                storeAction({
                                    data:   {
                                        action:            ADD,
                                        [ storeListName ]: [ value.id ]
                                    },
                                    id:         storeId,
                                    fullRecord: value.fullRecord,
                                    label:      value.label
                                })
                            }
                            onBlur={() => this.setState({ inputOpen: false })}
                            addHandler={name === TypeAheadControl.users
                                ? () => setUserDialog({
                                    userId:         NEW_USER_ID,
                                    mode:           userDialogMode.ADD,
                                    name:           '',
                                    title:          '',
                                    contactInfo:    []
                                })
                                : undefined}
                            addLabel={__( "Add new user" )}
                            dataName={dataNameList}
                        />
                    </div>
                </div>}
                { name === TypeAheadControl.users && !!companyName &&
                <UserDetailsForm
                    companyName={companyName}
                    anchorEl={this._addLineRef.current}
                    open={currentDetailUserId === NEW_USER_ID}
                />
                }

            </div>}
        </>
        ;
    }

    static propTypes = {
        name:                           PropTypes.string.isRequired,
        label:                          PropTypes.string.isRequired,
        heading:                        PropTypes.string.isRequired,
        storeAction:                    PropTypes.func.isRequired,
        storeId:                        PropTypes.string.isRequired,
        storeListName:                  PropTypes.string,
        extraData:                      PropTypes.object,
        existingOptions:                PropTypes.array,
        existingOptionWarnings:         PropTypes.array,
        currentDetailUserId:            PropTypes.string,
        companyName:                    PropTypes.string,
        setUserDialog:                  PropTypes.func.isRequired,
        preventDeleteCallback:          PropTypes.func,
        preventDeleteMessage:           PropTypes.string,
        preventLoneChipDelete:          PropTypes.bool,
        preventAdd:                     PropTypes.bool,
        preventUserIdDelete:            PropTypes.string,
        preventUserIdDeleteMessage:     PropTypes.string,
        dataNameAdd:                    PropTypes.string,
        dataNameList:                   PropTypes.string,
        dataNameChipList:               PropTypes.string,
        dataNameChipItem:               PropTypes.string,
        dataNameChipItemDelete:         PropTypes.string,
        ownerUserId:                    PropTypes.string,
        setOwnerUser:                   PropTypes.func,
        loginUserId:                    PropTypes.string,
        grabAction:                     PropTypes.func
    };
}


const mapStateToProps = state => {

    const { userDetails: { userId: currentDetailUserId }, jobcard: { currentJobCardId }, user: { userId: loginUserId } } = state;
    const { companyName } = getJobCompany( state, currentJobCardId );

    return {
        currentDetailUserId,
        loginUserId,
        companyName
    };
};
const mapDispatchToProps = {
    setUserDialog
};

export default connect( mapStateToProps, mapDispatchToProps )( DynamicChipAdder );
export { DynamicChipAdder };
