/********************************************************************************************************************
 * @file cache-data.js - exists to remove the dependency between reducer clients and the socket init code, which leads
 * to circular dependencies.
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21/12/2021
 *********************************************************************************************************************/
import { SECOND } from '@licoriceio/constants';

let _setCacheData, _getCacheData, _abortCacheData;

const throttleInterval = 3 * SECOND;

function throttle( fn )
{
    let inThrottle;
    let payload;
    const callFunc = args => {
        [ args, payload ] = [ payload ];
        inThrottle = true;
        if ( args != null ) fn( args );
    };
    const sync = () => {
        callFunc( );
        setTimeout( () => {
            if ( payload != null ) callFunc();
            inThrottle = false;
        }, throttleInterval );
    };
    const func = data => {
        payload = data;
        if ( !inThrottle ) return sync();
    };
    func.abort = () => payload = void 0;
    return func;
}

const initializeCacheFunctions = sfn => {
    _setCacheData   = throttle( sfn.setCacheData );
    _getCacheData   = sfn.getCacheData;
    _abortCacheData = setCacheData.abort;
};

const setCacheData = ( id, data ) => _setCacheData({ id, data });
const getCacheData = id => _getCacheData( id );
const abortCacheData = () => _abortCacheData();

export {
    initializeCacheFunctions,
    setCacheData,
    getCacheData,
    abortCacheData
};
