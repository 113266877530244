import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import {  setSelectedAsset } from "../../../redux/actions/index.js";
import { assetsLabel, assetsList, configSection } from "../../../scss/Asset.module.scss";
import {  __ } from "../../../utils/i18n.jsx";
import { deleteAssetFromJob } from "../reducer.js";

import { AssetListItem } from "./AssetListItem.jsx";
const Assets = props  => {
    const {  assetsMap, jobId, deleteAssetFromJob, viewAssetDetails } = props;
  
    return <div>
        <div className={configSection} >
            <div className={assetsLabel}>{__( "Assets" )}</div>

            <div className={assetsList}>
                {Object.keys( assetsMap ).map( ( assetId, index ) => {
                // below line is commented because we are not updating the asset info in the job card yet.
                // updateAssetInfo( assetId );
                    const asset = assetsMap[ assetId ];
                    // const nameDetail = asset.details.find( ( detail ) => detail.question.toLowerCase() === "name" );
                    // const name = typeof nameDetail.answer === 'string' ? nameDetail.answer : nameDetail.answer.name;
                    const name = asset?.assetName;
                    return <AssetListItem onDelete={ () => deleteAssetFromJob({ assetId, jobId })}
                        label={name}
                        icon={"computer"}
                        key={index}
                        onClick={() => viewAssetDetails( assetId )}
                    ></AssetListItem>;
                }
                )}

            </div>
        
        </div>
    </div>;
};

Assets.propTypes = {
    assetsMap:              PropTypes.object.isRequired,
    jobId:                  PropTypes.string.isRequired,
    deleteAssetFromJob:     PropTypes.func.isRequired,
    viewAssetDetails:       PropTypes.func.isRequired
};
const mapStateToProps = ( state )  => ({
    currentUser:        state.user,
    assetsMap:          state.jobcard.assetsMap || [],
    jobId:              state.jobcard.currentJobCardId
});

const mapDispatchToProps = {
    setSelectedAsset,
    deleteAssetFromJob
};

export default connect( mapStateToProps, mapDispatchToProps )( Assets );
