/** ******************************************************************************************************************
 * @file All error codes and error handling.
 *
 * Current callsites:
 * ErrorDef, text
 * ErrorDef, Error
 * ErrorDef
 * text
 * Error
 *
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 29-Sep-2020
 *********************************************************************************************************************/

import { fromCode, fromStatusCode, codeToMessage, nameToCodeToName, HttpStatus, compareStatus } from './codes.js';
import { ErrorDefs, BAD_CREDS, BAD_MFA, MAXIMUM_ATTEMPTS, ERROR_DEF } from './error-defs.js';
import { callProviderError, detectProvider } from './provider-errors.js';

// eslint-disable-next-line no-self-compare
const isNumber = n => typeof n === 'number' && n === n;
const isString = s => typeof s === 'string';
const isObject = o => typeof o === 'object' && !Array.isArray( o ) && o !== null;

const systemErrors  = Object.getOwnPropertyNames( globalThis ).filter( n => n.endsWith( 'Error' ) && n !== 'Error' ).map( n => globalThis[ n ]);
const isSystemError = e => systemErrors.some( sys => e instanceof sys );

function fromArgs( e, arg1, arg2 )
{
    if ( HttpStatus[ arg1 ])
    {
        e.statusCode = Number( compareStatus[ arg1 ]);
        arg1 = arg2 ?? codeToMessage[ e.statusCode ];
        arg2 = void 0;
    }
    
    if ( arg1?.[ ERROR_DEF ])
    {
        if ( isString( arg2 ) )
            return fromDefText( e, arg1, arg2 );
        else if ( arg2 instanceof Error )
            return fromDefError( e, arg1, arg2 );
        else
            return fromDef( e, arg1 );
    }
    else if ( arg1 instanceof Error )
        return fromError( e, arg1 );

    return fromText( e, arg1 );
}

/**
 * @param {ApiError} e
 * @param {ErrorDefinition} errorDef
 * @param {string} text
 */
function fromDefText( e, { message, statusCode, errorCode }, text )
{
    if ( message.includes( '%' ) ) message = message.replace( /%s/g, text || e.message );

    e.statusCode = statusCode;
    e.errorCode = Number( errorCode );
    e.message = message;
}

/**
 * @param {ApiError} e
 * @param {ErrorDefinition} errorDef
 * @param {Error} error
 */
function fromDefError( e, errorDef, error )
{
    fromDef( e, errorDef );
    e.cause = error;
}

/**
 * @param {ApiError} e
 * @param {ErrorDefinition} errorDef
 */
function fromDef( e, { message, statusCode, errorCode })
{
    if ( message.includes( '%' ) ) message = message.replace( /%s/, e.message );

    e.statusCode = statusCode;
    e.errorCode = Number( errorCode );
    e.message = message;
}

/**
 * @param {ApiError} e
 * @param {string} text
 */
function fromText( e, text )
{
    e.message = text;
    e.errorCode = e.statusCode;
}

/**
 * @param {ApiError} e
 * @param {Error} error
 */
function fromError( e, error )
{
    e.message = error.message;
    if ( error.statusCode ) e.statusCode = error.statusCode;
    e.cause = error;
}

/**
 * @param {Error} e
 * @param {ErrorConstructor|string} NextErrorConstructor
 * @param {string} [errorStr]
 * @return {ApiError|Error}
 */
function fromRpcError( e, NextErrorConstructor, errorStr = 'Error thrown in RPC function execution' )
{
    if ( typeof NextErrorConstructor === 'string' ) [ NextErrorConstructor, errorStr ] = [ RPCError, NextErrorConstructor ];

    if ( !e.stack.startsWith( '%' ) )
        return new NextErrorConstructor( errorStr, { cause: e });

    const [ header, ...lines ] = e.stack.split( /\r?\n/ );
    const [ , errorName, statusCode, errorCode, message ] = header.split( '%' ); // /%%?/ );
    const newError = new ( errorObject[ errorName ] || Error )( e );

    newError.statusCode = statusCode ? Number( statusCode ) : nameToCodeToName.INTERNAL_SERVER_ERROR;
    newError.errorCode = errorCode ? Number( errorCode ) : 1000;
    newError.code = nameToCodeToName[ newError.statusCode ];
    newError.message = message;
    newError.stack = errorName + ': ' + message + '\n' + lines.join( '\n' );

    return newError;
    // return NextErrorConstructor ? new NextErrorConstructor( errorStr, { cause: newError }) : newError;
}

class ApiError extends Error
{
    constructor( ...args )
    {
        if ( new.target === errorObject.ValidationError )
            args.unshift( ErrorDefs.VALIDATION );

        const causeIndex = args.findIndex( a => isObject( a ) && Object.hasOwn( a, 'cause' ) && a.cause instanceof Error );
        let cause;

        if ( causeIndex !== -1 )
            cause = args.splice( causeIndex, 1 )[ 0 ];

        const thisError = {};
        fromArgs( thisError, ...args );

        super( thisError.message, cause );

        // if ( this.stack )
        //     this.stack = stripLocal( this.stack );

        if ( this.cause )
            this.stack += `\n\n` + this.cause.stack;

        Object.assign( this, thisError );
    }

    toHttp( statusCodeOrCode = this.statusCode )
    {
        const e = isNumber( statusCodeOrCode ) ? fromStatusCode( statusCodeOrCode ) : fromCode( statusCodeOrCode );
        const newError = new HttpError( e.message, { cause: this });
        newError.statusCode = e.statusCode;
        newError.code = e.code;
    }
}

class ProviderError extends ApiError
{
    // constructor( data, statusCode, url, cause )
    constructor( errorData, cause )
    {
        const isStr = isString( errorData );

        super( isStr ? errorData : 'placeholder', cause );

        let providerCode;

        if ( ( providerCode = detectProvider( this ) ) )
            callProviderError( this, providerCode, errorData );
        else if ( !isStr )
            this.message = `Bad error creation, check code that called this`;
    }
}

class HttpError extends Error
{
    constructor( statusCode, statusText )
    {
        super( statusText );

        this.statusCode = statusCode;
    }
}

const httpError = statusCode => {
    throw new HttpError( statusCode, codeToMessage[ statusCode ]);
};

class AppointmentError extends ApiError {}
class CalendarEventError extends ApiError {}
class TimeLogError extends ApiError {}
class IntegrationError extends ApiError {}
class ConnectorError extends ApiError {}
class InvitationError extends ApiError {}
class NotificationError extends ApiError {}
class WebHookError extends ApiError {}
class JobError extends ApiError {}
class AssetError extends ApiError {}
class TokenError extends ApiError {}
class EmailError extends ApiError {}
class WorkLogError extends ApiError {}
class UserError extends ApiError {}
class NoteError extends ApiError {}
class CompanyError extends ApiError {}
class DatabaseError extends ApiError {}
class CallbackError extends ApiError {}
class ValidationError extends ApiError {}
class RouterError extends ApiError {}
class RPCError extends ApiError {}
class SystemError extends ApiError {}
class UnauthorizedError extends ApiError {}
class TimeoutError extends ApiError {}

const errorObject = {
    AppointmentError,
    CalendarEventError,
    TimeLogError,
    IntegrationError,
    ConnectorError,
    InvitationError,
    NotificationError,
    WebHookError,
    JobError,
    AssetError,
    TokenError,
    EmailError,
    WorkLogError,
    UserError,
    NoteError,
    CompanyError,
    DatabaseError,
    CallbackError,
    ValidationError,
    RouterError,
    RPCError,
    SystemError,
    UnauthorizedError,
    TimeoutError,
    HttpError,
    ProviderError
};

export * from './system-errors.js';
export * from './provider-errors.js';
export * from './codes.js';

export {
    fromArgs,
    isSystemError,
    systemErrors,
    BAD_CREDS,
    BAD_MFA,
    MAXIMUM_ATTEMPTS,
    ErrorDefs,
    AppointmentError,
    CalendarEventError,
    TimeLogError,
    IntegrationError,
    ConnectorError,
    InvitationError,
    NotificationError,
    WebHookError,
    JobError,
    AssetError,
    TokenError,
    EmailError,
    WorkLogError,
    UserError,
    NoteError,
    CompanyError,
    DatabaseError,
    CallbackError,
    ValidationError,
    RouterError,
    RPCError,
    SystemError,
    UnauthorizedError,
    TimeoutError,
    ApiError,
    HttpError,
    ProviderError,
    fromRpcError,
    httpError
};
