/* eslint-env node */
/** ******************************************************************************************************************
 * @file Defines all constants shared between packages.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 29-Sep-2020
 *********************************************************************************************************************/

const nodeEnv = process.env.NODE_ENV;

if ( !nodeEnv )
    throw new Error( `Environment variable '$NODE_ENV' has not been set` );

const UNAUTH = 'unauth';
const ATTACHMENT = 'attachment';

const DEVELOPMENT = 'development';
const PRODUCTION = 'production';
const TESTING = 'testing';
const TEST = 'test';
const isProduction = nodeEnv === PRODUCTION;
const isDevelopment = nodeEnv === DEVELOPMENT;

const NONE = 'none';

const Socket = {
    NEW_USER:           'sockNewUser',
    ACTIVITY:           'sockActivity',
    MESSAGE:            'sockMessage',
    GET_CACHE_DATA:     'sockGetCacheData',
    SET_CACHE_DATA:     'sockSetCacheData',
    CACHE_DATA_READY:   'sockCacheDataReady'
};


export {
    Socket,
    DEVELOPMENT,
    PRODUCTION,
    TESTING,
    TEST,
    UNAUTH,
    ATTACHMENT,
    NONE,
    isProduction,
    isDevelopment
};
