import React from 'react';

import { Fab, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const ButtonTooltip = withStyles({
    tooltip: {
        background:   "var(--black-1)",
        fontSize:     12,
        borderRadius: 4,
        fontFamily:   "gotham-book, sans-serif",
        maxWidth:     200,
        border:       "1px solid var(--white)"
    },
    arrow: {
        color: "var(--black-1)"
    }
})( Tooltip );

export const LicoFab = props => {
    const {
        onClick,
        licoVariant = "large",
        children,
        className,
        disabled,
        tooltip,
        disabledTooltip,
        ...rest
    } = props;

    const classes = makeStyles({ root: {} })( props );
    const showTooltip = ( !!disabled && disabledTooltip !== undefined && disabledTooltip.length > 0 ) ||
        ( !disabled && tooltip !== undefined && tooltip.length > 0 );

    const fab = <span>
        <Fab
            onClick={onClick}
            disabled={disabled}
            {...rest}
            className={`${classes.root} ${licoVariant} ${className}`}
        >
            {children}
        </Fab>
    </span>;

    return <>
        {
            showTooltip
                ? <ButtonTooltip
                    title={disabled ? disabledTooltip || '' : tooltip || ''}
                    arrow
                    placement="left"
                    disableHoverListener={!showTooltip}
                    disableTouchListener={true}
                    disableFocusListener={true}
                >
                    {fab}
                </ButtonTooltip>
                : fab
        }
    </>;
};

LicoFab.propTypes = {
    onClick:         PropTypes.func,
    licoVariant:     PropTypes.string,
    className:       PropTypes.string,
    tooltip:         PropTypes.string,
    disabledTooltip: PropTypes.string,
    children:        PropTypes.node,
    disabled:        PropTypes.bool
};
