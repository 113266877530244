import React from 'react';

import PropTypes from 'prop-types';

import { businessHoursBlock, businessHoursTimes, deleteButton } from '../../scss/BusinessHoursItem.module.scss';
import { LicoFab, LicoIcon } from "../common/index.js";

import { TimeRangeSelector } from './TimeRangeSelector.jsx';
import WeekdayMap from './WeekdayMap.jsx';

const BusinessHoursItem = ({ businessHours, index, setValue, removeItem, allowEmptyList }) => {

    const { startTime, endTime, weekdays } = businessHours;
    return (
        <div className={businessHoursBlock}>
            <div className={businessHoursTimes}>
                <TimeRangeSelector startValue={startTime} endValue={endTime} ampm={false} onChange={change => setValue({ ...change, blockIndex: index })} />
                {
                    ( index > 0 || allowEmptyList ) && (
                        <LicoFab
                            onClick={() => removeItem( index )}
                            className={deleteButton}
                        >
                            <LicoIcon icon="delete" />
                        </LicoFab>
                    )
                }
            </div>
            <WeekdayMap weekdays={weekdays} setValue={change => setValue({ ...change, blockIndex: index })} />
        </div>
    );
};

BusinessHoursItem.propTypes = {
    businessHours:  PropTypes.object,
    index:          PropTypes.number,
    setValue:       PropTypes.func.isRequired,
    removeItem:     PropTypes.func.isRequired,
    allowEmptyList: PropTypes.bool
};

export { BusinessHoursItem };
