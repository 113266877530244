/*********************************************************************************************************************
 * @file Registration reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Nov-2020
 *********************************************************************************************************************/
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';

import { GET, POST, uri } from '../../constants.js';
import strength0Image from '../../images/password-strength-0.png';
import strength1Image from '../../images/password-strength-1.png';
import strength2Image from '../../images/password-strength-2.png';
import strength3Image from '../../images/password-strength-3.png';
import strength4Image from '../../images/password-strength-4.png';
import {
    setProgress, setBetaKey, setFormValueRegistration, setTouchedRegistration, setAdvance, setErrorRegistration, setRegistrationDone, showCurrentErrors,
    setDNSStatus
} from '../../redux/actions/index.js';
import { ezRedux, formValueReducer, focusReducer } from '../../redux/reducerUtil.js';
import { abstractedCreateRequest } from '../../services/util/baseRequests.js';
import { __ } from '../../utils/i18n.jsx';
import { THIS_FIELD_IS_REQUIRED, SUBDOMAIN_FORMAT, INVALID_EMAIL } from "../common/index.js";

const progressStage = {
    BETA_KEY:       0,
    EMAIL:          1,
    IDENTITY:       2,
    COMPANY:        3,
    SUCCESS:        4
};

/**
 * @typedef {object} RegistrationState
 * @property {number} progress
 * @property {string} betaKey
 * @property {string} country
 * @property {string} emailAddress
 * @property {string} engineerName
 * @property {string} password
 * @property {string} companyName
 * @property {string} url
 * @property {string} error
 * @property {string} finalUrl
 * @property {object} fieldTouched
 * @property {object} fieldError
 */

/**
 * @type {RegistrationState}
 */
const initialState = Object.freeze({
    progress:       progressStage.BETA_KEY,
    country:        'United States',
    betaKey:        '',
    emailAddress:   '',
    engineerName:   '',
    password:       '',
    companyName:    '',
    url:            '',
    error:          '',
    finalUrl:       '',
    dnsStatus:      0, // DNSStatus.IN_PROGRESS,
    fieldTouched:   {},
    fieldError:     {},
    strength:       null
});

const stageFields = {
    [ progressStage.BETA_KEY ]:     [],
    [ progressStage.EMAIL ]:        [ 'emailAddress' ],
    [ progressStage.IDENTITY ]:     [ 'engineerName', 'password' ],
    [ progressStage.COMPANY ]:      [ 'companyName', 'url' ]
};

const _asyncValidateBetaKey = abstractedCreateRequest( GET, uri.REGISTRATION_CHECK );
const _asyncPerformRegistration = abstractedCreateRequest( POST, uri.REGISTRATION_REGISTER );

const urlRegex = /^[a-zA-Z0-9]([-a-zA-Z0-9]{1,254})?(?<!-)$/; // letters, numbers, hypens not in starting/ending character
const formSchema = Yup.object().shape({
    emailAddress: Yup.string().email( INVALID_EMAIL ).required( THIS_FIELD_IS_REQUIRED ),
    engineerName: Yup.string().required( THIS_FIELD_IS_REQUIRED ),
    password:     Yup.string().required( THIS_FIELD_IS_REQUIRED ),
    companyName:  Yup.string().required( THIS_FIELD_IS_REQUIRED ),
    url:          Yup.string().matches( urlRegex, SUBDOMAIN_FORMAT ).required( THIS_FIELD_IS_REQUIRED )
});

/**
 * Validate the beta key and change to next stage if valid
 * @param {string} betaKey - beta key as entered
 */
const checkBetaKey = betaKey => dispatch => {

    betaKey = betaKey.toLowerCase();
    dispatch( setBetaKey( betaKey ) );
    dispatch( setErrorRegistration( undefined ) );

    // using the mask field, the value always has the correct (full) length so we only validate when there are no spaces
    if ( betaKey.length > 0 && !/\s/.test( betaKey ) )
    {
        _asyncValidateBetaKey({}, void 0, [ betaKey ])
            .then( result => dispatch( result.payload.okay ? setProgress( progressStage.EMAIL ) : setErrorRegistration( __( "Invalid beta key" ) ) ) )
            .catch( reason => console.error( 'betaKey validation failed on backend', reason ) );
    }
};

/**
 * Attempt to register a new company
 */
const performRegistration = () => async ( dispatch, getState ) => {

    const registrationState = getState().registration;
    const { progress, fieldError, emailAddress: email, password, engineerName: name, companyName, url: namespace, betaKey } = registrationState;

    // catch errors on final screen
    const fieldNames = stageFields[ progress ];
    const pageErrors = fieldNames.some( field => fieldError[ field ]);

    if ( pageErrors )
        dispatch( showCurrentErrors() );
    else {
        dispatch( setErrorRegistration( undefined ) );

        const fieldLabel = {
            companyName:        __( "Company Name" ),
            namespace:          __( "Licorice URL" )
        };

        _asyncPerformRegistration({ email, password, name, companyName, namespace, betaKey })
            .then( result => {
                dispatch( result.hasError 
                    ? setErrorRegistration( result.error.errors ) 
                    : result.payload.illegalName
                        ? setErrorRegistration( `${fieldLabel[ result.payload.field ]} has an illegal value` ) 
                        : setRegistrationDone( result ) );
            });
    }
};

/**
 * Make all errors visible, ie set fieldTouched true for all fields in fieldErrors
 * @param {RegistrationState} draft - current draft
 */
const _showCurrentErrors = draft => {
    const fieldNames = stageFields[ draft.progress ];
    const fields = Object.assign({}, draft.fieldTouched );
    fieldNames.map( field => fields[ field ] = true );
    draft.fieldTouched = fields;
};

/**
 * Try to move to the next stage, checking for errors first
 *
 * @param {RegistrationState} draft - current draft
 */
const advanceReducer = ( draft ) => {

    // password strength errors are set in updateForm and will be lost after formValueReducer,
    // since password is in the form schema as mandatory.
    const passwordError = draft.fieldError.password;

    // if the user hasn't pressed a key, this won't have run
    formValueReducer( draft, undefined, formSchema );

    // restore password strength error if there's no other error
    if ( !draft.fieldError.password )
        draft.fieldError.password = passwordError;

    const fieldNames = stageFields[ draft.progress ];
    const pageErrors = fieldNames.some( field => draft.fieldError[ field ]);

    // pressing Enter makes all errors on this page visible
    if ( pageErrors )
        _showCurrentErrors( draft );
    else
        draft.progress = draft.progress + 1;
};

/**
 * Set login URL and move to the confirmation screen
 *
 * @param {RegistrationState} draft - current draft
 * @param {object} result - URL of new system
 */
const registrationDoneReducer = ( draft, result ) => {
    draft.progress = draft.progress + 1;
    draft.finalUrl = result.payload.url;
};

const updateForm = ( draft, arg ) => {
    formValueReducer( draft, arg, formSchema );
    draft.fieldTouched[ arg?.field ] = false;
    if ( arg?.field === 'password' ) {
        if ( draft.password ) {
            const inputs = [];
            if ( draft.emailAddress?.length > 0 ) inputs.push( ...draft.emailAddress.split( /[.@_-]/ ) );
            if ( draft.engineerName?.length > 0 ) inputs.push( ...draft.engineerName.split( /[ .-]/ ) );
            if ( draft.companyName?.length > 0 ) inputs.push( ...draft.companyName.split( /[ ,.-]/ ) );
            draft.strength = formatStrength( zxcvbn( draft.password, inputs ) );
            draft.fieldError.password = validateStrength( draft.strength );
        }
        else
            draft.strength = null;
    }
};

const scoreToAdvice = [
    __( "Too guessable: risky password. (guesses < 10^3)" ),
    __( "Very guessable: protection from throttled online attacks. (guesses < 10^6)" ),
    __( "Somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)" ),
    __( "Safely guessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)" ),
    __( "Very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)" )
];

const scoreToSlogan = [
    __( "Newbie" ),
    __( "Inferior" ),
    __( "Meh" ),
    __( "Singular" ),
    __( "Transcendent" )
];

const scoreToIcon = [
    strength0Image,
    strength1Image,
    strength2Image,
    strength3Image,
    strength4Image
];

const formatStrength = s => ({
    online:      s.crack_times_display.online_no_throttling_10_per_second,
    offline:     s.crack_times_display.offline_fast_hashing_1e10_per_second,
    warning:     s.feedback.warning,
    suggestions: s.feedback.suggestions,
    score:       [ s.score, scoreToAdvice[ s.score ], scoreToSlogan[ s.score ], scoreToIcon[ s.score ] ]
});

const validateStrength = strength => strength.score[ 0 ] < 4 ? __( 'Password rating must be Transcendent' ) : '';

/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setProgress ]:                ( draft, value ) => draft.progress = value,
    [ setErrorRegistration ]:       ( draft, value ) => draft.error = value,
    [ setBetaKey ]:                 ( draft, value ) => draft.betaKey = value,
    [ setFormValueRegistration ]:   updateForm,
    [ setTouchedRegistration ]:     focusReducer,
    [ setAdvance ]:                 advanceReducer,
    [ setRegistrationDone ]:        registrationDoneReducer,
    [ showCurrentErrors ]:          _showCurrentErrors,
    [ setDNSStatus ]:               ( draft, value ) => draft.dnsStatus = value
};

export {
    progressStage,
    checkBetaKey,
    performRegistration,
    formatStrength,
    validateStrength
};

export default ezRedux( reducers, initialState );
