import React, {  useCallback, useEffect, useState } from 'react';

import { MINUTE } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { dragSource } from '../../constants.js';
import {  selectNameDataReady } from "../../redux/selectors/index.js";
import { searchPanel as searchPanelStyle, shown, hide, dynamicTabPanelContainer } from '../../scss/SearchPanel.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { getDefaultAppointmentDuration } from '../../utils/user-prefs.js';
import { UX_SEARCH_PANEL } from '../../ux-constants.js';
import LicoTabs from "../common/LicoTabs.jsx";
import { Draggable } from '../FullCalendar.jsx';

import {
    removeFromSearchPanel,
    selectSearchPanel,
    windowHeightChanged,
    addToSearchPanel,
    selectActiveTabIndex,
    selectActiveTabId,
    updateActiveTabId, getJobCardCreatedTab
} from './reducer.js';
import SearchTabContent from "./SearchTabContent.jsx";

function SearchPanel( props ) {
    // list of existing context tabs
    const { searchPanelShown,
        windowHeightChanged,
        searchPanel,
        removeFromSearchPanel,
        addToSearchPanel,
        activeTabIndex,
        updateActiveTabId,
        nameDataReady
    } = props;

    const updateSize = useCallback( () => {
        let newHeight = window.innerHeight - 73;
        if ( newHeight < 0 ) 
            newHeight = window.innerHeight;
        
        windowHeightChanged( newHeight );
    }, [ windowHeightChanged ]);

    useEffect( () => {
        window.addEventListener( 'resize', updateSize );
        updateSize();
        return () => {
            window.removeEventListener( 'resize', updateSize );
        };

    }, [ updateSize ]);

    const [ draggableCreated, setDraggableCreated ] = useState( false );
    const createDraggable = useCallback( () => {
        if ( !draggableCreated ) 
        {
            let containerEl = document.getElementById( 'search-panel' );
            if ( containerEl ) {
    
                // eslint-disable-next-line no-new
                new Draggable( containerEl, {
                    itemSelector: '.search-panel-job',
                    eventData:    function( eventEl ) {
                        const jobId = eventEl.getAttribute( 'data-job-id' );
                        if ( jobId ) {
                            let timeMinutes = Number( eventEl.getAttribute( 'data-time' ) ) || getDefaultAppointmentDuration();
                            if ( timeMinutes % 15 ) timeMinutes += ( 15 - ( timeMinutes % 15 ) );
    
                            return {
                                title:                  eventEl.getAttribute( 'data-title' ),
                                clientName:             eventEl.getAttribute( 'data-company' ),
                                description:            eventEl.getAttribute( 'data-description' ),
                                jobId:                  jobId,
                                externalSource:         dragSource.SEARCH_PANEL,
                                duration:               timeMinutes * MINUTE,
                                priority:               eventEl.getAttribute( 'data-priority' ),
                                newItemFlag:            eventEl.getAttribute( 'data-new-item-flag' )
                            };
                        }
                        else
                            throw new Error( "no job id in drag" );
                    }
                });
                setDraggableCreated( true );
            }
        }
    }, [ draggableCreated ]);

    useEffect( () => {
        createDraggable();
    });

    const closeTab = ( id ) => {
        removeFromSearchPanel( id );
    };
    return (
        <>
            { nameDataReady &&
        <div
            id="search-panel"
            data-ux={UX_SEARCH_PANEL}
            className={`${searchPanelStyle} ${searchPanelShown ? shown : hide}`}
        >
            {searchPanelShown && searchPanel && (
                <LicoTabs
                    dynamicTab={true}
                    onDynamicTabClicked={() => {
                        addToSearchPanel({ jobCardCreatedTab: false });
                        updateSize();
                    }}
                    persistentContent={false}
                    content={searchPanel.map( ( tab ) => ({
                        label:             tab.filterList.length > 0 ? tab.filterList[ 0 ]?.label || tab.filterList[ 0 ]?.name || ( typeof tab.filterList[ 0 ] === "string"
                            ? tab.filterList[ 0 ]
                            : __( "New Tab" ) ) : __( "New Tab" ),
                        showResizeIcon:    false,
                        content:           <SearchTabContent id={tab.id}/>,
                        id:                tab.id,
                        jobCardCreatedTab: tab.jobCardCreatedTab
                    }) )}
                    contentVisible={false}
                    tabIndex={searchPanel.length ? activeTabIndex + 1 : 0}
                    setTabIndex={( tabIndex ) => {
                        const tab = searchPanel?.[ tabIndex - 1 ];
                        if ( tab ) {
                            addToSearchPanel({ jobCardCreatedTab: false });
                            updateActiveTabId( tab.id );
                        }
                    }}
                    showCloseIcon={true}
                    onClose={closeTab}
                    className={'ContextTab'}
                    tabPanelClassName={dynamicTabPanelContainer}
                />
            )}
        </div>
            }
        </>
    );
}

SearchPanel.propTypes = {
    windowHeightChanged:    PropTypes.func.isRequired,
    searchPanelShown:       PropTypes.bool.isRequired,
    searchPanel:            PropTypes.array.isRequired,
    removeFromSearchPanel:  PropTypes.func.isRequired,
    addToSearchPanel:       PropTypes.func.isRequired,
    activeTabIndex:         PropTypes.number.isRequired,
    updateActiveTabId:      PropTypes.func.isRequired,
    nameDataReady:          PropTypes.bool.isRequired
};



const mapDispatchToProps = {
    windowHeightChanged,
    removeFromSearchPanel,
    addToSearchPanel,
    updateActiveTabId
};

const mapStateToProps = ( state ) => ({
    searchPanel:          selectSearchPanel( state ),
    activeTabId:          selectActiveTabId( state ),
    activeTabIndex:       selectActiveTabIndex( state ),
    jobCardCreatedTab:    getJobCardCreatedTab( state ),
    nameDataReady:        selectNameDataReady( state )
});

export default connect( mapStateToProps, mapDispatchToProps )( SearchPanel );
