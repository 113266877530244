import React, { PureComponent } from 'react';

import { Chip, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setUserDialog } from '../../redux/actions/index.js';
import { getJobCompany } from '../../redux/selectors/index.js';
import { __ } from '../../utils/i18n.jsx';
import { LicoIcon, LicoPopupMenu, LicoPopupMenuItem } from '../common/index.js';

import LicoAvatar from './LicoAvatar.jsx';
import UserDetailsForm from './UserDetailsForm.jsx';

class UserChip extends PureComponent
{
    _chipRef = React.createRef();

    constructor( props )
    {
        super( props );

        this.state = {
            userMenuInfo: null
        };
    }

    handleRightClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ userMenuInfo: {  x: e.clientX, y: e.clientY } });
    };

    UserPopupMenu = () => {
        const {
            userMenuInfo
        } = this.state;
        return <LicoPopupMenu
            id="add-user"
            anchorPosition={userMenuInfo ? { left: userMenuInfo.x, top: userMenuInfo.y } : undefined}
            open={!!userMenuInfo}
            onClose={() => this.setState({ userMenuInfo: null })}
        >
            { userMenuInfo && [

                // users without names, ie CC email users, can't be made owner
                this.props.user.name 
                    ? <LicoPopupMenuItem
                        onClick={() => {
                            if ( !this.props.isOwner ) 
                            {
                                this.props.setOwnerUser( this.props.user.userId );
                                this.setState({ userMenuInfo: null });
                            }
                        }}
                        icon="plus-circle"
                        disabled={ this.props.isOwner }
                        key="setOwner"
                    >
                        {__( "Set as Owner" )}
                    </LicoPopupMenuItem> 
                    : null,
                <LicoPopupMenuItem
                    onClick={() => {
                        if ( this.props.isDeleteEnable )
                            this.props.onDelete();
                    }}
                    icon="minus-circle"
                    key="delete"
                    disabled={ !this.props.isDeleteEnable }
                >
                    {__( "Remove" )}
                </LicoPopupMenuItem>
            ]
            }
        </LicoPopupMenu>;
    };

    render() {
        const {
            label,
            user,
            onDelete,
            avatar,
            disabled,
            setUserDialog,
            userId,
            companyName,
            preventDeleteMessage,
            dataName,
            dataNameDelete,
            className,
            isOwner
        } = this.props;
        const {
            userMenuInfo
        } = this.state;

        const avatarSize = user.name ? "small" : "smallish";

        const chip = <Chip
            ref={this._chipRef}
            className={className}
            classes={{ label: `${isOwner ? "owner" : ""} ${user.name ? "" : "fixed-width-font"}` }}
            avatar={<LicoAvatar avatar={avatar} gravatarEmail={user.preferences.gravatarEmail} className={avatarSize} size={avatarSize} />}
            deleteIcon={<LicoIcon dataName={dataNameDelete} icon="xmark" />}
            label={label}
            onDelete={onDelete}
            onClick={() => setUserDialog( user )}
            disabled={disabled}
            data-ux={dataName}
        />;

        return <div  onContextMenu={ this.handleRightClick }>
            {onDelete ? chip : <Tooltip
                title={preventDeleteMessage || __( "You can't delete the only User for a Job " )}
                placement="right-start"
                enterDelay={500}
            >
                {chip}
            </Tooltip>}
            <UserDetailsForm companyName={companyName} anchorEl={this._chipRef.current} open={user.userId === userId} />
            {
                !!userMenuInfo && <this.UserPopupMenu />
            }
        </div>;
    }

    static propTypes = {
        label:                  PropTypes.string.isRequired,
        user:                   PropTypes.object.isRequired,
        onDelete:               PropTypes.func,
        avatar:                 PropTypes.object.isRequired,
        disabled:               PropTypes.bool.isRequired,
        setUserDialog:          PropTypes.func.isRequired,
        userId:                 PropTypes.string,
        companyName:            PropTypes.string,
        preventDeleteMessage:   PropTypes.string,
        dataName:               PropTypes.string,
        dataNameDelete:         PropTypes.string,
        className:              PropTypes.string,
        isDeleteEnable:         PropTypes.bool,
        isOwner:                PropTypes.bool,
        setOwnerUser:           PropTypes.func
    };
}

const mapStateToProps = state => {

    const { userDetails: { userId }, jobcard: { currentJobCardId } } = state;
    const { companyName } = getJobCompany( state, currentJobCardId );

    return {
        userId,
        companyName
    };
};
const mapDispatchToProps = {
    setUserDialog
};

export default connect( mapStateToProps, mapDispatchToProps )( UserChip );
export { UserChip };
