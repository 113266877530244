import React from 'react';

import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { dispatchBlurThunk, dispatchChangeAction, installKeySaveActions } from '../../../../redux/reducerUtil.js';

const useStyles = makeStyles({
    root: {
    },
    label: {
        fontFamily: "gotham-medium, sans-serif",
        fontSize:   15,
        color:      "var(--black-1)"
    }
});

export const LicoSwitch = props => {
    const {
        checked = false,
        name,
        formPackage,
        slicePackage,
        label = "",
        valueTransform = x => x,
        dataName,
        ...rest
    } = props;
    let { onChange, onKeyDown } = props;

    const classes = useStyles( rest );

    if ( slicePackage ) {
        onChange = e => {
            dispatchChangeAction( slicePackage, { updates: { [ name ]:  valueTransform( e.target.checked )  } });
            dispatchBlurThunk( slicePackage, { field: name });
        };

        // don't need to save since we save on change
        if ( slicePackage.keySaveAction )
            onKeyDown = installKeySaveActions( slicePackage, undefined, props.onKeyDown );
    }

    return (
        <>
            <Switch
                name={name}
                checked={checked}
                className={classes.root}
                onChange={formPackage
                    ? e => formPackage.onChange({
                        id:     formPackage.id,
                        field:  e.target.name,
                        value:  valueTransform( e.target.checked ) })
                    : onChange}
                onKeyDown={onKeyDown}
                data-ux={dataName}
                {...rest}
            />
            {!!label && <span className={classes.label}>{label}</span>}
        </>
    );
};

LicoSwitch.propTypes = {
    checked:            PropTypes.bool,
    label:              PropTypes.string,
    formPackage:        PropTypes.object,
    slicePackage:       PropTypes.object,
    onChange:           PropTypes.func,
    onKeyDown:          PropTypes.func,
    valueTransform:     PropTypes.func,
    name:               PropTypes.string.isRequired,
    dataName:           PropTypes.string
};
