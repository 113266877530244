import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { P } from '../atomic/typography/P.jsx';

const useStyles = makeStyles({
    root: ({
        display:         props => props.message ? "block" : "none",

        margin:          "20px 0",
        backgroundColor: "var(--lightgrey-4)",
        alignSelf:       "stretch"
    }),
    errorMessage: {
        fontSize:        "16px"
    }
});

export const FormErrorPanel = props => (
    <div className={useStyles( props ).root}>
        <P  className={useStyles( props ).errorMessage} color="error" gutterBottom = {false}> {props.message}</P>
    </div>
);

FormErrorPanel.propTypes = {
    message: PropTypes.string
};
