/********************************************************************************************************************
 * @file Actions for the user store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setUserFromSettings = createAction( 'USER.USER_FROM_SETTINGS/SET' );

export const setUser = createAction( 'USER.USER/SET' );
export const setExternalJobCount = createAction( 'USER.EXTERNAL_JOB_COUNT/SET' );
export const setDecrementExternalJobCount = createAction( 'USER.DECREMENT_EXTERNAL_JOB_COUNT/SET' );

