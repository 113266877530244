import React from 'react';

import PropTypes from 'prop-types';

import { progressBar, filler, labelClass } from '../../scss/ProgressBar.module.scss';

const ProgressBar = ({ percentage, label }) => {

    const progressStyle = {
        width:           `${percentage}%`
    };
    
    return (
        <div className={progressBar}>
            <div className={filler} style={progressStyle} />
            <div className={labelClass}>{label || `${Number( Math.floor( percentage ) )}%`}</div>    
        </div>
    );
};
ProgressBar.propTypes = {
    percentage:         PropTypes.number.isRequired,
    label:              PropTypes.string
};

export default ProgressBar;
export { ProgressBar };

