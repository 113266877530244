import React from "react";

import PropTypes from "prop-types";
import Helmet from 'react-helmet';

import { alert, logo, inactive } from "../../scss/NotificationAlert.module.scss";
import { LicoIcon } from "../common/index.js";

import { Badge } from "./Badge.jsx";


const  NotificationAlert = ({ onClick, count, dataName, showNotificationPanel }) =>
    (
        <>
            <Helmet>
                { !count ? <link rel="icon" href="/favicon.ico" /> : <link rel="icon" href="/logo_notification.svg" /> }
            </Helmet>
            <div className={alert} onClick={onClick} data-ux={dataName}>
                <LicoIcon  icon="bell" fontSize="medium" className={`${logo} ${!count ? inactive : ""}`}/>
                <Badge count={!showNotificationPanel ? ( count || 0 ) : 0}/>
            </div>
        </>
    );

NotificationAlert.propTypes = {
    onClick:               PropTypes.func,
    count:                 PropTypes.number,
    dataName:              PropTypes.string,
    showNotificationPanel: PropTypes.bool
};
export default NotificationAlert;
