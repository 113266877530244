/*********************************************************************************************************************
 * @file search Panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import "react-placeholder/lib/reactPlaceholder.css";
import { pager, arrow, pagerText, visible, hidden, gothamMedium, icon } from '../../scss/JobPager.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { LicoIcon } from '../common/index.js';

const JobPager = ({ label, start, pageSize, total, setOffset }) => (
    <div className={pager}>
        <span className={`${arrow} ${start > 0 ? visible : hidden}`} onClick={() => setOffset( Math.max( 0, start - pageSize ) )}>
            <LicoIcon icon="less-than"  className={icon}/>
        </span>
        <div className={pagerText}>
            <span >
                <span>{label}&nbsp;</span>
                <span className={gothamMedium}>{start + 1}-{Math.min( start + pageSize, total )}</span>
                <span>&nbsp;{__( "of" )}&nbsp;</span>
                <span className="gotham-medium">{total}</span>
            </span>
        </div>
        <span className={`${arrow} ${( start + pageSize ) < total ? visible : hidden}`} onClick={() => setOffset( start + pageSize )}>
            <LicoIcon icon="greater-than"  className={icon}/>
        </span>
    </div>
);

JobPager.propTypes = {
    label:     PropTypes.string.isRequired,
    start:     PropTypes.number,
    pageSize:  PropTypes.number,
    total:     PropTypes.string,
    setOffset: PropTypes.func.isRequired
};

export default connect()( JobPager );
export { JobPager };
