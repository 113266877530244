import React from 'react';

import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import ClientSchedule from '../clientSchedule/ClientSchedule.jsx';
import { PrivateRoute } from "../common/index.js";
import { triggerMap } from '../common/PreventTransitionPrompt.jsx';
import ForgotPassword from '../ForgotPassword/ForgotPassword.jsx';
import ResetPasswordPage from '../ForgotPassword/ResetPassword.jsx';
import AcceptInvitation from '../invitation/AcceptInvitation.jsx';
import Login from '../login/Login.jsx';
import Home from '../pages/Home.jsx';
// import IntegrationForm from '../pages/Settings/Integration/IntegrationForm.jsx';
import { SettingsPage } from '../pages/Settings/Settings.jsx';
import Registration from '../registration/Registration.jsx';
import Clients from '../settings/clients/Clients.jsx';
import ClientLocations from '../settings/clients/Sites.jsx';
import ClientUsers from '../settings/clients/Users.jsx';
import ManageEngineers from '../settings/engineer/ManageEngineers.jsx';
import IntegrationForm from '../settings/integration/IntegrationForm.jsx';
import Journal from '../settings/journal/Journal.jsx';
import ClientAppointmentSettings from '../settings/organisation/ClientAppointmentSettings.jsx';
import OrganisationSettings from '../settings/organisation/OrganisationSettings.jsx';
import ManageTeams from '../settings/team/ManageTeams.jsx';
import UserProfile from '../settings/user/UserProfile.jsx';

import * as Routes from "./routes.js";
import { SETTINGS_CLIENTS_USERS } from "./routes.js";

const privateRoutes = [
    {
        path:       Routes.SETTINGS,
        component:  SettingsPage
    },
    {
        path:       Routes.HOME,
        component:  Home
    },
    {
        path:       Routes.SETTINGS_PROFILE,
        component:  UserProfile
    },
    {
        path:       Routes.SETTINGS_ORGANISATION,
        component:  OrganisationSettings
    },
    {
        path:       Routes.SETTINGS_CLIENT_APPOINTMENTS,
        component:  ClientAppointmentSettings
    },
    {
        path:       Routes.SETTINGS_INTEGRATION,
        component:  IntegrationForm
    },
    {
        path:       Routes.SETTINGS_ENGINEER,
        component:  ManageEngineers
    },
    {
        path:       Routes.SETTINGS_CLIENTS_LOCATIONS,
        component:  ClientLocations
    },
    {
        path:       Routes.SETTINGS_CLIENTS_USERS,
        component:  ClientUsers
    },
    {
        path:       Routes.SETTINGS_CLIENTS,
        component:  Clients
    },
    {
        path:       Routes.SETTINGS_TEAM,
        component:  ManageTeams
    },
    {
        path:       Routes.SETTINGS_JOURNAL,
        component:  Journal
    }
];

const getUserConfirmation = ( dialogKey, callback ) => {

    // use "message" (actually random id) as Symbol-based key
    const dialogTrigger = triggerMap[ Symbol.for( dialogKey ) ];

    if ( dialogTrigger ) {

        // pass the callback to delegate to the invoked dialog
        return dialogTrigger( callback );
    }

    // Fallback to allowing navigation
    callback( true );
};

export const NavigationRouter = ({ isAuthenticated = true }) => {

    // hostname doesn't include port so can ignore ':'
    const hostParts = window.location.hostname.split( '.' );

    // we want to redirect when accessing a generic namespace, which at this stage means 'us' or 'au'.
    // Include 'localhost' so dev works as well.
    const isAppHostname = /^(?:us|au|localhost)$/i.test( hostParts[ 0 ]);

    // not sure why we need to exclude all routes as well as redirecting but without
    // the exclusion empty paths still went to login

    return ( <>

        <Router getUserConfirmation={getUserConfirmation} >

            {isAppHostname && <Redirect to={Routes.REGISTRATION} />}

            <Switch>
                {/* nb. Order is important because switch statement */}
                <Route path={Routes.REGISTRATION} exact component={Registration}/>
                {!isAppHostname && <>
                    <Route path={Routes.LOGIN} exact component={Login}/>
                    <Route path={Routes.ACCEPT_INVITE} exact component={AcceptInvitation}/>
                    <Route path={Routes.CLIENT_SCHEDULE} component={ClientSchedule}/>

                    {privateRoutes.map( ( v, i ) => (
                        <PrivateRoute
                            path={v.path}
                            exact
                            component={v.component}
                            isAuthenticated={isAuthenticated}
                            redirect={Routes.LOGIN}
                            key={i}
                        />
                    )
                    )}

                    <Route path={Routes.FORGOT_PASSWORD} exact component={ForgotPassword}/>
                    <Route path={Routes.RESET_PASSWORD} exact component={ResetPasswordPage}/>
                </>
                }
            </Switch>
        </Router>
    </>
    );

};

NavigationRouter.propTypes = {
    isAuthenticated: PropTypes.bool
};
