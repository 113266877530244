/*********************************************************************************************************************
 * @file Engineer reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Dec-2020
 *********************************************************************************************************************/
import { coms } from '@licoriceio/constants';

import { uri, engineerField, snackbarKey, PATCH, POST, UserProfileMode } from '../../../constants.js';
import {
    setUserFromSettings, setEngineers, setFilterString, setEngineerField, setEngineer, setEngineerSettings, discardAllChanges, setMeta,
    discardUserSettingChanges, recordInvitation, setUserProfileEmailError, setUserProfileNameError, setUser
} from '../../../redux/actions/index.js';
import { ezRedux, genericReducer, genericRequest } from '../../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../../services/util/baseRequests.js';
import { getAvatarFromUserOrCompany } from "../../../utils/common-types.js";
import { __ } from "../../../utils/i18n.jsx";
import { wrapBusinessHours } from '../utilities.jsx';

const patchUser = user => genericRequest( user, abstractedCreateAuthRequest( PATCH, uri.SINGLE_USER, a => a ), [ setUserFromSettings, setEngineer ], [ user.userId ]);
const addEngineer = engineer => genericRequest( engineer, abstractedCreateAuthRequest( POST, uri.ADD_ENGINEER, a => a ), [ setEngineer, [ setMeta, { engineerAdded: true } ] ], [ ]);

/**
 * @typedef {object} EngineerState
 * @property {array} engineers
 * @property {object} engineerMap
 * @property {array} unsavedEngineers
 * @property {array} rowErrors
 * @property {boolean} foundChanges
 * @property {boolean} foundErrors
 * @property {string} filterString
 * @property {object} invitationMap
 */


// currently we're using a fixed page size for screen and db retrievals, so they're always in sync
const PAGE_SIZE = 12;

const defaultContact = ( engineer, type ) =>
    coms.find.default( engineer.contactInfo, type )?.value || '';

const setDefaultContact = ( engineer, type, value ) => {

    if ( !engineer.contactInfo )
        engineer.contactInfo = [];

    const contact = coms.find.default( engineer.contactInfo, type );
    if ( contact )
        contact.value = value;
    else
        engineer.contactInfo.push( coms.create[ type ]( value ).default );

    return '';
};

// static rules for calculating the real field value(s) from the editable form
const realFieldCalc = {
    [ engineerField.ROLE_OPTION ]:      { field: engineerField.ROLE, func: value => value.id },
    [ engineerField.PHONE ]:            { func: ( value, oldEngineer ) => {
        setDefaultContact( oldEngineer, 'phone', value );
    } }
};

/**
  * @type {EngineerState}
  */
const initialState = Object.freeze({
    engineers:                      [],
    engineerMap:                    {},
    unsavedEngineers:               undefined,
    rowErrors:                      [],
    foundChanges:                   false,
    foundErrors:                    false,
    filterString:                   '',
    invitationMap:                  {},
    editTime:                       0,
    page:                           0,
    totalPages:                     0,
    confirmUserProfileDialogOpen:   false,
    confirmNavigationDialogOpen:    false
});

const getEngineerState = state => state.engineer;

// services
const _asyncGetEngineersTypeahead = abstractedCreateAuthRequest( "GET", uri.PEOPLE_TYPEAHEAD, a => a );
const _asyncGetEngineers = abstractedCreateAuthRequest( "GET", uri.ENGINEERS, a => a );
const _asyncPatchEngineer = abstractedCreateAuthRequest( "PATCH", uri.SINGLE_USER, a => a );
const _asyncPostInvitations = abstractedCreateAuthRequest( "POST", uri.INVITATIONS, a => a );


// requests

/* eslint-disable function-call-argument-newline */
const getEngineersTypeahead = payload =>
    genericRequest(
        {},
        _asyncGetEngineersTypeahead,
        [ [ setEngineers, payload ] ],
        [ payload.filter ],
        { 
            role:              "engineer", 
            leftJoinCountJSON: JSON.stringify({
                joinTable:      'token',
                joinTableKey:   'recipientId',
                where:          { purpose: 'invitation' }
            }),
            ignoreActive:    'true', 
            page:            payload.page, 
            orderBy:         'user.name', 
            withCount:       payload.withCount ? "true" : "false", 
            pageSize:        PAGE_SIZE
        }
    );

// const getEngineers = payload =>
//     genericRequest(
//         {},
//         _asyncGetEngineers,
//         [ [ setEngineers, payload ] ],
//         undefined,
//         { page: payload.page, withCount: payload.withCount ? "true" : "false", pageSize: PAGE_SIZE, ignoreActive: 'true', orderBy: 'active, desc, user.name, asc' }
//     );

/* eslint-enable function-call-argument-newline */

/**
 * Patch a single engineer; two actions are dispatched, one to update the store for this screen, one to possibly update
 * the current user.
 */
const patchEngineer = engineer => genericRequest( engineer, _asyncPatchEngineer, [ setEngineer, setUserFromSettings, showSavedMessage ], [ engineer.userId ]);

/**
 * Add a single invitation and update the meta store.
 */
const postInvitation = invitation => genericRequest( 
    invitation, _asyncPostInvitations, [ [ setMeta, { inviteCreated: true } ], [ recordInvitation, { invitation } ] ]);

const extendEngineer = engineer => {

    return {
        ...engineer,
        avatar:             getAvatarFromUserOrCompany( engineer ),
        phone:              defaultContact( engineer, 'phone' ),
        roleOption:         {
            id:    engineer.role,
            label: engineer.role
        },
        schedulable:        false,
        changed:            false
    };
};

// thunk actions

const showSavedMessage = () => async ( dispatch ) => {
    dispatch( setMeta({ [ snackbarKey.ENGINEERS_SAVED ]: true }) );
};

/**
 * Continue with navigation following the save/discard dialog, saving or not as appropriate
 * @param {boolean} saveChanges
 */
const continueNavigation = saveChanges => async ( dispatch, getState ) => {
    if ( saveChanges )
        dispatch( saveAllChanges() );

    dispatch( setEngineerSettings({
        confirmNavigationDialogOpen:    false,
        foundChanges:                   false
    }) );

    const engineerState = getState().engineer;

    dispatch( getPage({
        page:           engineerState.nextPage,
        filter:         engineerState.nextFilter,
        withCount:      engineerState.nextWithCount
    }) );
};

/**
 * Attempt to navigate to a new page, either by paging or by changing the filter.
 * @param {object} payload
 */
const getPage = payload => async ( dispatch, getState ) => {
    const { engineer: { foundChanges } } = getState();
    const { page, filter, withCount } = payload;

    if ( foundChanges ) {
        dispatch( setEngineerSettings({
            nextPage:                       page,
            nextFilter:                     filter,
            nextWithCount:                  withCount,
            confirmNavigationDialogOpen:    true
        }) );
    }
    else {
        dispatch( setFilterString( filter || '' ) );
        dispatch( getEngineersTypeahead({ ...payload, orderBy: 'name', filter: filter || '_' }) );
    }
};

const saveAllChanges = () => async ( dispatch, getState ) => {

    const unsavedEngineers = getState().engineer.unsavedEngineers;

    unsavedEngineers
        .filter( e => e.changed )
        .map( e => ({
            userId:         e.userId,
            name:           e.name,
            title:          e.title,
            contactInfo:    e.contactInfo,
            active:         e.active,
            admin:          e.admin,
            eligible:       e.eligible
        }) )
        .map( e => dispatch( patchEngineer( e ) ) );
};

/**
 * Continue with user profile dialog close following the save/discard dialog, saving or not as appropriate
 * @param {boolean} saveChanges
 */
const continueUserProfileClose = saveChanges => async dispatch => {
    if ( saveChanges )
        dispatch( saveUserSettingChanges() );
    else 
        dispatch( discardUserSettingChanges() );

    dispatch( setEngineerSettings({
        confirmUserProfileDialogOpen:    false
    }) );

};

const saveUserSettingChanges = () => ( dispatch, getState ) => {

    const {
        userProfile: { name, userId, title, loginEmail, contactInfo,
            twoFactorAuthenticationRequired, defaultAppointmentDuration, businessHours, overrideOrgHours, 
            useGravatar, useAlternativeGravatarEmail, alternativeGravatarEmail,
            preferences, engineerProfile, mode },
        user
    } = getState();

    // validate the required fields else return.
    if ( !name || !loginEmail ) {
        if ( !loginEmail )
            dispatch( setUserProfileEmailError( __( 'This field is required.' ) ) );
        if ( !name )
            dispatch( setUserProfileNameError( __( 'This field is required.' ) ) );
        return;
    }

    const newPreferences = { 
        ...preferences, 
        defaultAppointmentDuration, 
        twoFactorAuthenticationRequired, 
        overrideOrgHours,
        businessHours: wrapBusinessHours( businessHours ),
        useGravatar,
        useAlternativeGravatarEmail,
        alternativeGravatarEmail,
        gravatarEmail: useGravatar ? useAlternativeGravatarEmail ? alternativeGravatarEmail : loginEmail : ''
    };

    const engineer = { userId, name, title, contactInfo, loginEmail,  preferences: newPreferences };
    if ( mode !== UserProfileMode.ADD )
        dispatch( patchUser( engineer ) );
    else
        dispatch( addEngineer({ ...engineer, active: false, activationDate: null }) );

    if ( userId === user.userId )
        dispatch( setUser({ engineer }) );
    
    // close if in window
    if ( engineerProfile )
        dispatch( discardUserSettingChanges() );
    const engineerState = getState().engineer;
    dispatch( getPage({
        page:           engineerState.nextPage,
        filter:         engineerState.nextFilter,
        withCount:      engineerState.nextWithCount
    }) );
};

/** reducers */

const setEngineersReducer = ( draft, payload ) => {

    const { page, headers, withCount } = payload;
    draft.page = page;
    draft.engineers = payload.payload.map( extendEngineer );
    draft.engineerMap = Object.assign({}, ...draft.engineers.map( ( e ) => ({ [ e.userId ]: e }) ) );

    // we add withCount to all requests that reset the list
    if ( withCount )
        draft.totalPages = Math.ceil( Number( headers[ "x-licorice-count" ]) / PAGE_SIZE );

    // deep clone to isolate changes; not the quickest way but we're not dealing
    // with big data here
    draft.unsavedEngineers = JSON.parse( JSON.stringify( draft.engineers ) );
};

const setEngineerFieldReducer = ( draft, args ) => {
    const { index, field, value } = args;
    const { unsavedEngineers, rowErrors } = draft;

    const engineer = unsavedEngineers[ index ];
    engineer[ field ] = value;
    engineer.changed = true;

    // some fields are edited in a different format; keep the real field up-to-date
    // so we can save without worrying about it
    const realField = realFieldCalc[ field ];
    if ( realField ) {
        if ( realField.field )
            engineer[ realField.field ] = realField.func( value );
        else
            realField.func( value, engineer );
    }

    // we've updated draft, all we need is to trigger a refresh, so update a tick
    // draft.unsavedEngineers = [ ...newEngineers ];
    draft.editTime = new Date().getTime();

    draft.foundChanges = true;

    // this is made trickier because we have related fields (start and end time), and we
    // want to display the error on the most recently changed field. That means editing
    // one of those fields can change both fields' errors, ie setting one and clearing the
    // other.
    let error = { [ field ]: undefined };
    const oldError = rowErrors[ index ] || {};

    if ( field === engineerField.NAME && value.length === 0 )
        error = { [ field ]: __( 'Name is mandatory' ) };

    // if anything in the new error object differs from the same field in the existing
    // errors for the row, update the errors.
    const changed = Object.keys( error ).some( field => error[ field ] !== oldError[ field ]);
    if ( changed ) {

        // update errors for this row and create a new object in draft
        rowErrors[ index ] = Object.assign( rowErrors[ index ] || {}, error );
        draft.rowErrors = [ ...rowErrors ];

        // could be more efficient as we don't care about the count
        let count = 0;
        rowErrors.map( row => count += Object.keys( row ).filter( key => row[ key ]).length );
        draft.foundErrors = count > 0;
    }

};

const setEngineerReducer = ( draft, engineer ) => {

    const engineers = draft.engineers;
    if ( !engineers )
        return;
    
    let index = engineers.findIndex( n => n.userId === engineer.userId );

    if ( index >= 0 )
        engineers[ index ] = extendEngineer( engineer );
    else
        engineers.push( extendEngineer( engineer ) );

    draft.engineerMap[ engineer.userId ] = engineer;

    // update the working copy as well to avoid cloning the whole list again
    index = draft.unsavedEngineers?.findIndex( n => n.userId === engineer.userId );
    if ( index >= 0 )
        draft.unsavedEngineers[ index ] = extendEngineer( engineer );

    draft.foundChanges = false;

};

const _recordInvitation = ( draft, payload ) => {
    const { invitation: { recipient } } = payload;
    const engineer = draft.unsavedEngineers.find( e => e.userId === recipient );

    // the counts come from the backend as strings so just staying consistent
    engineer.count = String( ( Number( engineer.count ) || 0 ) + 1 );
};

/** all action creators are listed as keys here. Values are expressions which resolve to (draft, args) => {} */
const reducers = {
    [ setEngineers ]:               setEngineersReducer,
    [ setFilterString ]:            ( draft, payload ) => draft.filterString = payload,
    [ setEngineerField ]:           setEngineerFieldReducer,
    [ setEngineer ]:                setEngineerReducer,
    [ setEngineerSettings ]:        genericReducer,
    [ discardAllChanges ]:          draft => draft.foundChanges = false,
    [ recordInvitation ]:           _recordInvitation
};

/**
 * action creators and async functions are imported by the component and added to mapDispatchToProps,
 * and can then be called by the component's handlers.
 */

export {
    _asyncGetEngineersTypeahead,
    // getEngineers,
    getEngineerState,
    extendEngineer,
    postInvitation,
    patchEngineer,
    showSavedMessage,
    getPage,
    continueNavigation,
    continueUserProfileClose,
    saveAllChanges,
    saveUserSettingChanges,
    defaultContact,
    setDefaultContact
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
