/** ******************************************************************************************************************
 * @file Draws a badge if the count is greater than zero.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 20-Mar-2021
 *********************************************************************************************************************/
import React from 'react';

import PropTypes from 'prop-types';

import { badgeCircle } from '../../scss/Badge.module.scss';

export const Badge = props => typeof props.count === 'number' && props.count > 0 ? <span className={badgeCircle}>{props.count > 99 ? '99+' : props.count}</span> : null;

Badge.propTypes = {
    count: PropTypes.number
};
