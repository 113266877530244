/********************************************************************************************************************
 * @file Actions for the userDetails store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setUserDialog = createAction( 'USER_DETAIL.DIALOG/SET' );
export const setCloseUserDialog = createAction( 'USER_DETAIL.CLOSE_DIALOG/SET' );
export const setFormValidationUserDetails = createAction( 'USER_DETAIL.FORM_VALIDATION/SET' );
export const setUpdateUser = createAction( 'USER_DETAIL.UPDATE/SET' );
export const setTouchedUserDetails = createAction( 'USER_DETAIL.TOUCHED/SET' );
export const setUserDialogMode = createAction( 'USER_DETAIL.MODE/SET' );
export const setUpdateJobcardUser = createAction( 'USER_DETAIL.UPDATE_JOBCARD_USER/SET' );
export const showToken = createAction( 'USER_DETAILS.CREATE_TOKEN' );

