/**
 * @file Reducer utilities
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 12-Oct-2020
 */

import { isFunc, omit, uc, ucf, isObject, has, pick } from '@licoriceio/utils';
import produce from 'immer';
import { createAction } from 'redux-actions';

import { PATCH, POST, mergeMode } from '../constants.js';
import { store } from '../publicStore.js';
import { abstractedCreateAuthRequest } from '../services/util/baseRequests.js';

const mapPath = {
    job:                [ 'job', 'jobMap', { name: 'id' } ],
    calendarEvent:      [ 'calendarEvent', 'userMap', { name: 'userId' }, 'calendarEventMap', { name: 'id' } ]
};

const sliceMapPath = slice => mapPath[ slice ];

/**
 * @param {string} name
 * @param {string|function} actionName
 * @param {function} [setFunc]
 * @return {[ function, function ]}
 */
const actionSetter = ( name, actionName, setFunc ) => {

    if ( isFunc( actionName ) )
    {
        setFunc = actionName;
        actionName = name;
    }

    const uname = 'set' + ucf( name );

    const actionCreator = { [ uname ]: createAction( `INTEGRATION.${uc( actionName )}/SET` ) }[ uname ];
    const action = { [ '_' + uname ]: ( draft, result ) => setFunc( draft, result ) }[ '_' + uname ];

    return [ actionCreator, action ];
};

/**
 * @param {string} sliceName
 * @param {string} name
 * @param {string|function} actionName
 * @param {function} [setFunc]
 * @return {[ function, function ]}
 */
const genericActionSetter = ( sliceName, name, actionName, setFunc ) => {

    if ( isFunc( actionName ) )
    {
        setFunc = actionName;
        actionName = name;
    }

    const actionCreator = createAction( `${uc( sliceName )}.${uc( actionName )}/SET` );
    const action = ( draft, result ) => setFunc( draft, result );

    return [ actionCreator, action ];
};

const dispatchChangeAction = ( slicePackage, payload ) => {
    const { slice, localChangeThunk } = slicePackage;
    store.dispatch({
        type:       sliceChangeAction( slice ),
        payload:    { ...payload, ...slicePackage }
    });

    // we could dispatch an action here too, but we can simply handle the standard action locally (eg [ sliceChangeAction( 'slice' ) ]) 
    // as well but we'll usually be using a thunk to allow new actions to be dispatched.
    if ( localChangeThunk )
        store.dispatch( localChangeThunk({ ...payload, ...slicePackage }) );
};

const dispatchBlurThunk = ( slicePackage, payload ) => {
    store.dispatch( sliceBlurThunk({ ...payload, ...slicePackage }) );
};

const findSliceItem = ( state, slicePackage, calledFromReducer ) => {

    return sliceMapPath( slicePackage.slice ).reduce( ( acc, cur, i ) => {

        // when called from a reducer skip the first item in the path
        if ( i === 0 && calledFromReducer )
            return acc;
        return isObject( cur ) ? acc[ slicePackage[ cur.name ] ] : acc[ cur ];
    }, state );
};

// cached service creator
const serviceCache = {};
const makeSaveService = ( state, dispatch, slicePackage ) => {

    // copy item to make it changeable
    const item = { ...findSliceItem( state, slicePackage ) };

    // no change, no save
    if ( !item.changeTime ) 
        return [];

    const { slice } = slicePackage;

    // pending request, no save
    if ( item.requestTime ) {
        dispatch({
            type:       sliceChangeAction( slice ),
            payload:    { ...slicePackage, updates: { saveSkipped: true } }
        });
        return [];
    }

    const updates = { requestTime: Date.now(), changeTime: 0, saveSkipped: false };
    const deletes = [];

    // if the item was created on the frontend it must be posted
    let method = PATCH;
    let url = '/' + slice.toLowerCase();

    // ugh. job api is at '/jobs'.
    if ( /job/.test( slice ) )
        url += 's';

    const ids = [];
    let newItemFlag = item.newItemFlag;
    if ( item.newItemFlag ) {
        method = POST;

        // a record type may not have mandatory fields, but may have a list of fields at least one of which
        // be entered to create the record, otherwise the save attempt doesn't happen. It's treated like
        // an error but no error is displayed.
        const { qualifyingFields } = slicePackage;
        if ( qualifyingFields ) {
            const qualified = qualifyingFields.reduce( ( acc, cur ) => acc || !!item[ cur ], false );
            if ( !qualified )
                return [];
        }

        delete item.newItemFlag;
        deletes.push( 'newItemFlag' );
    }
    else {

        // patch URLs need the id replacement token
        url += '/:id';
        ids.push( item[ sliceId( slice ) ]);
    }

    // assume fields ending in "Id" are UUID fields and change "" to null
    Object.keys( item ).filter( field => field.endsWith( 'Id' ) && item[ field ]?.length === 0 ).forEach( field => item[ field ] = null );

    // make the local changes
    dispatch({
        type:       sliceChangeAction( slice ),
        payload:    { ...slicePackage, updates, deletes }
    });

    const service = serviceCache[ method + url ] ?? ( serviceCache[ method + url ] = abstractedCreateAuthRequest( method, url ) );

    // don't send the local fields to the backend
    return [ service, omit( item, [ 'changeTime', 'requestTime', 'saveSkipped' ]), newItemFlag, ids ];
};

const sliceBlurThunk = payload => ( dispatch, getState ) => {

    // payload is a superset of slicePackage here
    const { slice, error, localSaveThunk, preSaveTransform } = payload;

    // update fieldError for this field; we won't have a defined error if this is a repeated call
    // due to a skipped save
    if ( error !== undefined )
        dispatch({ type: sliceErrorAction( slice ), payload });

    // we want to save on blur provided there are no errors.
    // if this field has an error, we don't need to check the state, otherwise check fieldError for
    // this slice.
    if ( !error ) {
        const state = getState();
        const errors = Object.values( state[ slice ].fieldError ).join( '' );

        if ( !errors ) {

            const [ service, item, newItemFlag, ids ] = makeSaveService( state, dispatch, payload );

            // service might be undefined if there's a pending request or we didn't make any change
            if ( service ) {

                if ( preSaveTransform )
                    preSaveTransform( item );

                const auth = state.auth;

                service( item, auth, ids ).then( result => {
                    if ( !result.hasError ) {
                        dispatch({ type: sliceMergeAction( slice ), payload: { ...payload, result } });

                        if ( localSaveThunk )
                            dispatch( localSaveThunk({ item, newItemFlag, result }) );

                        // get new state and check for unsent changes
                        const mergedItem = findSliceItem( getState(), payload );

                        // recurse and save again
                        if ( mergedItem.saveSkipped )
                            dispatch( sliceBlurThunk( payload ) );
                    }
                }, error => {
                    console.warn( 'request error', error );
                });

            }

        }
    }
};

const sliceChangeAction = slice => `SLICE/${slice.toUpperCase()}/CHANGE`;
const sliceErrorAction = slice => `SLICE/${slice.toUpperCase()}/ERROR`;
const sliceMergeAction = slice => `SLICE/${slice.toUpperCase()}/MERGE`;
const sliceId = slice => slice + 'Id';

/**
 * Generic blur reducer
 * @param {object} draft - draft
 * @param {object} payload - object with id, slice name, field name and value
 */
const sliceErrorReducer = ( draft, payload ) => {
    const { field, error } = payload;
    draft.fieldError[ field ] = error;
};

/**
 * Generic change reducer for simple *Map slices
 * @param {object} draft - draft
 * @param {object} payload - object with id, slice name, field name and value
 */
const sliceMapChangeReducer = ( draft, payload ) => {
    const { updates = {}, deletes } = Array.isArray( payload ) ? payload[ 0 ] : payload;
    // const item = draft[ sliceMap( slice ) ][ id ];
    const item = findSliceItem( draft, payload, true );
    if ( !has( updates, 'changeTime' ) )
        updates.changeTime = Date.now();

    // field values from list fields may be objects with additional fields which will be used by local callbacks;
    // all we want is the "id" field.
    const simpleUpdates = Object.fromEntries( Object.entries( updates ).map( ([ key, val ]) => [ key, val?.id ?? val ]) );
    Object.assign( item, simpleUpdates );

    if ( deletes )
        deletes.forEach( field => delete item[ field ]);

};

const sliceStoredFields = {
    job: [
        'jobId',
        'billable',
        'providerJobId',
        'providerStatusId',
        'providerBoardId',
        'companyId',
        'priority',
        'description',
        'title',
        'completedDate',
        'statusId',
        'estimatedTime',
        'userId'
    ],
    calendarEvent: [
        'calendarEventId',
        'providerCalendarEventId',
        'userId',
        'title',
        'description',
        'startDate',
        'endDate',
        'isPrivate',
        'licoriceNameId'
    ],
    appointment: [
        'appointmentId',
        'providerAppointmentId',
        'userId',
        'previousUserId',
        'jobId',
        'startDate',
        'endDate',
        'state'
    ]
};

/**
 * Generic merge reducer for simple *Map slices
 * @param {object} draft - draft
 * @param {object} payload - object with id, slice name, field name and value
 */
const sliceMapMergeReducer = ( draft, response ) => {
    const { mode = mergeMode.REPLACE_NEW, result } = response;

    const payload = pick( Array.isArray( result.payload ) ? result.payload[ 0 ] : result.payload, sliceStoredFields[ response.slice ]);

    const item = findSliceItem( draft, response, true );

    // if we get an update for a record we don't have, ignore it (?)
    if ( item ) {

        // depending on the merge mode, we won't replace any keys, any keys with non-blank values, or any
        // any keys where the new value is blank
        const omitKeys = mode === mergeMode.REPLACE_NEW
            ? Object.keys( item )
            : mode === mergeMode.REPLACE_BLANK
                ? Object.keys( item ).filter( key => !!item[ key ])
                : Object.keys( payload ).filter( key => payload[ key ] == null || payload[ key ].length === 0 ); // NO_BLANKING
        Object.assign( item, omit( payload, omitKeys ) );
        delete item.requestTime;
    }
    else {

        // add a new item; don't care about merge mode

        const mapPath = sliceMapPath( response.slice );
        const lastIndex = mapPath.length - 1;

        const parent = mapPath.reduce( ( acc, cur, i ) => {

            // skip the first item in the path since we're in a reducer
            // and the last item since we want the parent
            return i === 0 || i === lastIndex ? acc : acc[ cur ];
        }, draft );

        // add this item to the parent
        parent[ response[ mapPath[ lastIndex ].name ] ] = payload;
    }
};

// generic change handling relies on knowing how each slice is structured, since we have a variety of models
const sliceChangeReducer = {
    job:            sliceMapChangeReducer,
    calendarEvent:  sliceMapChangeReducer
};

const sliceMergeReducer = {
    job:            sliceMapMergeReducer,
    calendarEvent:  sliceMapMergeReducer
};

// allow local handling of auto actions
const mergeReducers = ( reducers, type, reducer ) => {
    if ( reducers[ type ]) {
        const customReducer = reducers[ type ];
        reducers[ type ] = ( draft, payload ) => {
            reducer( draft, payload );
            customReducer( draft, payload );
        };
    }
    else
        reducers[ type ] = reducer;
};

// we can define on-key actions that need the save process to be run first eg close window
const installKeySaveActions = ( slicePackage, doSave, localOnKeyHandler ) => {
    let handler = localOnKeyHandler;
    const { keySaveAction } = slicePackage;
    if ( keySaveAction ) { 
        handler = e => {
            if ( keySaveAction[ e.key ]) {

                // some controls save automatically (switches, selects) so we don't need the save action,
                // but we use the same framework to hook the shared action handler (from slicePackage) into each control
                if ( doSave )
                    doSave();

                keySaveAction[ e.key ]();
            }
            if ( localOnKeyHandler )
                localOnKeyHandler( e );
        }; 
    }
    return handler;
};

/**
 * turn a reducer map object into a reducer function that can be given to combineReducers
 *
 * @param {object} reducers
 * @param {object} initialState
 * @return {function(state:object,action:object): object }
 */
const ezRedux = ( reducers, initialState = {}, slice ) => {

    // add automatic slice reducers; this could be expressed as a data structure but we'd still
    // have one line per action so keep it simple
    if ( slice ) {

        // change field(s) from component or other internal process
        mergeReducers( reducers, sliceChangeAction( slice ), sliceChangeReducer[ slice ]);

        // merge updated record from backend, ie database or socket
        mergeReducers( reducers, sliceMergeAction( slice ), sliceMergeReducer[ slice ]);

        // blur
        mergeReducers( reducers, sliceErrorAction( slice ), sliceErrorReducer );
    }

    return produce( ( draft, { type, payload }) => {
        if ( reducers[ type ])
            reducers[ type ]( draft, payload );
        return draft;
    }, initialState );
};

const isString = s => typeof s === 'string';
const isNumber = n => typeof n === 'number' && n === n; // eslint-disable-line no-self-compare

const jsonPrint = o => JSON.stringify( o, undefined, 2 );

const makeMapById = key => ( acc, cur ) => {
    return {
        ...acc,
        [ cur[ key ] ]: cur
    };
};

// generic actions
const getLoadingStates = ( state, ...labels ) => labels.map( label => state.loading[ label ]);

/**
 * Validate a single field against a Yup schema.
 * Returns an error message
 * @param {ObjectSchema} schema
 * @param {object} state
 */
const validateField = ( schema, name, value ) => {
    let newError = '';
    if ( schema ) {
        try {
            schema.validateSync({ [ name ]: value }, { strict: true, abortEarly: true });
        }
        catch ( error ) {
            newError = error.errors.join( ' ' );
        }
    }
    return newError;
};

/**
 * Validate a state object against a Yup schema.
 * Returns a map of fieldnames to errors.
 * @param {ObjectSchema} schema
 * @param {object} state
 */
const validateForm = ( schema, state ) => {

    try {
        schema.validateSync( state, { abortEarly: false });

        // no error thrown, clear error list
        return {};
    }
    catch ( error ) {
        return error.inner.reduce( ( acc, cur ) => {
            return {
                ...acc,
                [ cur.path ]: cur.message
            };
        }, {});
    }

};

// generic "put all fields from values object in draft" reducer
const genericReducer = ( draft, values ) => Object.assign( draft, values );

/**
 * Record the new value for the field and update the error map
 * @param {object} draft - current draft
 * @param {{ field: string, value: any }} arg - field name
 * @param {ObjectSchema} schema - validator
 */
const formValueReducer = ( draft, arg, schema ) => {
    if ( arg ) draft[ arg.field ] = arg.value;
    draft.fieldError = validateForm( schema, draft );
};

/**
 * Record when a field is first de-focussed, which is when we start displaying errors
 * @param {object} draft - current draft
 * @param {string} field - name of field exited
 */
const focusReducer = ( draft, field ) => {
    draft.fieldTouched[ field ] = true;
};

const validateOnBlurReducer = ( draft, field, schema ) => {
    draft.fieldTouched[ field ] = true;
    draft.fieldError = validateForm( schema, draft );
};

/**
 * Dispatch one or more actions, with either a default payload or a fixed payload as supplied
 * @param {function} dispatch - dispatch function
 * @param {any} action - An action, or an array of { action | [ action, payload ] }
 * @param {any} payload - default action payload
 * @param {object} headers - headers
 */
const dispatchActions = ( dispatch, action, payload, headers ) => {

    // allow requests to have no response action
    if ( !action ) return;

    if ( typeof action === "function" )
        dispatch( action( payload ) );
    else
    {
        action.map( singleAction => {
            if ( typeof singleAction === "function" )
                dispatch( singleAction( payload ) );
            else {

                // where we have defined an action/something pair, the something can an object
                // or a string.
                // If it's an object, it's used for the payload and we supply the real payload as well inside that object.
                // This is also how you access response headers in a reducer, ie you have to
                // supply a payload.  However, this can be an empty object, eg:
                // [[ action, {} ]]
                // If it's a string, it's an alternative name for the payload
                const suppliedPayload = singleAction[ 1 ];
                if ( typeof suppliedPayload === "string" )
                    dispatch( singleAction[ 0 ]({ [ suppliedPayload ]: payload }) );
                else
                    dispatch( singleAction[ 0 ]({ ...suppliedPayload, payload, headers }) );
            }
            return 1;
        });
    }

};

/**
 * Perform an authorised API call and on success dispatch an action (or several actions) with the payload
 * @param {object} data - object to be supplied in body
 * @param {any} request - created by abstractCreateAuthRequest
 * @param {any?} action - action on success
 * @param {array?} ids - field names (to be extracted from data object) or values to be inserted in the URI
 * @param {object?} params - parameter fields to be added to the URL
 */
const genericRequest = ( data, request, action = undefined, ids = [], params = undefined ) => async ( dispatch, getState ) => {

    const idValues = ids?.map( field => has( data, field ) ? data[ field ] : field );

    return request( data, getState().auth, idValues, params )
        .then( result => {
            if ( !result.hasError && action !== undefined )
                dispatchActions( dispatch, action, result.payload, result.headers );
        })
        .catch( reason => {
            console.warn( 'request failed', reason );
        });

};

export {
    ezRedux,
    isString,
    isNumber,
    jsonPrint,
    makeMapById,
    getLoadingStates,
    validateForm,
    genericReducer,
    formValueReducer,
    focusReducer,
    validateOnBlurReducer,
    genericRequest,
    actionSetter,
    dispatchChangeAction,
    dispatchBlurThunk,
    sliceErrorAction,
    sliceChangeAction,
    sliceMergeAction,
    validateField,
    installKeySaveActions,
    sliceStoredFields,
    genericActionSetter
};
