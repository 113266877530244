/*********************************************************************************************************************
 * @file Organisation reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 12-May-2021
 *********************************************************************************************************************/
import { has } from '@licoriceio/utils';

import { GET, uri } from '../../../constants.js';
import {
    setSettingValueOrganisation, setAddOrganisationBusinessHours, setRemoveOrganisationBusinessHours, discardOrganisationSettingChanges, setAddCAClientMode,
    setAddCAClient, setRemoveCAClient, discardOnlineAppointmentsSettingChanges, setLoadOrganisationSettings, setDirectURL,
    setEligibleEngineers, setAddOrganisationOpenHours, setRemoveOrganisationOpenHours, setSettingValueOrganisationOpenHours } from '../../../redux/actions/index.js';
import { ezRedux, genericRequest, jsonPrint } from '../../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../../services/util/baseRequests.js';
import { handleBusinessHourChange, patchOneConfiguration, convertWeekdayMap, unwrapBusinessHours, wrapBusinessHours, validateBusinessHours } from '../utilities.jsx';

const checkForEligibleEngineers = () => genericRequest({}, abstractedCreateAuthRequest( GET, uri.SCHEDULES ), setEligibleEngineers );
const getDirectUrl = payload => genericRequest({}, abstractedCreateAuthRequest( GET, uri.PROVIDER_DIRECT_URL ), setDirectURL, [ payload.provider ]);

/**
 * @typedef {object} BusinessHoursState
 * @property {number}       startTime
 * @property {number}       endTime
 * @property {boolean[]}    weekdays
 */

/**
 * @typedef {object} SingleOrganisationState
 * @property {boolean}              foundChanges
 * @property {BusinessHoursState[]} [businessHours]
 * @property {BusinessHoursState[]} [openHours]
 * @property {number}               [minimumTimeEntry]
 * @property {boolean}              [twoFactorAuthenticationRequired]
 * @property {boolean}              [onlineAppointmentsEnabled]
 * @property {boolean}              [useEnabledClients]
 * @property {object}               [enabledClients]
 * @property {string}               [criticalPriorityMessage]
 * @property {number}               [defaultDuration]
 * @property {number}               [minimumLeadTimeHours]
 * @property {number}               [maximumLeadTimeDays]
 * @property {number}               [prePadding]
 * @property {number}               [postPadding]
 * @property {boolean}              [emailContact]
 * @property {string}               [logoURL]
 * @property {string}               [providerJobURL]
 * @property {object}               [fieldError]
 */


/**
 * The store holds 2 copies of the settings data; one for operational use (ie running the UI) and one
 * for editing. Currently they have the same structure but this need not be the case; ops and editing may have
 * different needs.
 * Note that neither of these needs to match the database structure, ie the content of the JSON data name for settings.
 * The difference is that values are expanded for editing, eg the weekdayMask(s) in businessHours gets turned
 * into a list of booleans, hours and minutes for times get turned in minutes in day, etc.
 * We also flatten the structure to remove the organisational groupings in the database JSON data.
 * @typedef {object} OrganisationState
 * @property {string}                   [configurationId]
 * @property {boolean}                  addClientApptClient
 * @property {SingleOrganisationState}  [live]
 * @property {SingleOrganisationState}  [edit]
 */

/**
  * @type {OrganisationState}
  */
const initialState = Object.freeze({
    foundChanges:               false,
    numberEligibleEngineers:    -1,
    addClientApptClient:        false,
    fieldError:                 {}
});

// functions

/**
 * @typedef UsableBusinessHours
 * @property {number} startTime
 * @property {number} endTime
 * @property {boolean[]} weekdays
 */

/**
 * @typedef CalendarBusinessHoursBlock
 * @property {number} startTime
 * @property {number} endTime
 * @property {number} weekday
 */

/**
 * @typedef CalendarTimeData
 * @property {object} businessHourDays
 * @property {object} outOfHourDays
 * @property {number} minStartTime
 * @property {number} maxEndTime
 */

/**
 * create the data needed to render the calendar from the list of business hour objects
 * @param {UsableBusinessHours[]} convertedBusinessHours - usable business hours list
 * @returns {CalendarTimeData}
 */
const makeCalendarTimeData = convertedBusinessHours => {

    // the calendar needs a list of working hour blocks for the current week;
    // FC will transform a list one-to-one but can't expand a list, so a businessHour
    // entry that may represent multiple days must be expanded before FC receives it.
    // We still need to transform this list to make the date match the current week
    // since FC deals in actual dates, not days of the week.
    // We'll use the converted settings to get to the weekdays list rather than use the map.
    //
    // In the same loop, find earliest start and latest end for the week
    const businessHourDays = {};
    let minStartTime = 1440;
    let maxEndTime = 0;
    convertedBusinessHours.forEach( bh => {
        bh.weekdays.forEach( ( flag, i ) => {
            if ( flag ) {

                if ( !has( businessHourDays, i ) )
                    businessHourDays[ i ] = [];
                businessHourDays[ i ].push({
                    startTime:      bh.startTime,
                    endTime:        bh.endTime,
                    weekday:        i
                });

                minStartTime = Math.min( minStartTime, bh.startTime );
                maxEndTime = Math.max( maxEndTime, bh.endTime );
            }
        });
    });

    // we now have the full list of business hour chunks. We have to invert that list
    // to find the extents of the out-of-hours regions, so we can display background events
    // in those regions to make them shadowed.
    // (We used to use FC's nifty 'inverse-background' feature to do this but it doesn't play well with
    // all day events, and we want the STT shaded as well (on days with no business hours).)
    // We still need the original list to correctly format the Time Entered Today display on the pegboard.
    
    // start with all days out of hours and punch holes in those regions as required, but
    // only after we find business hours for the day, so we can tell wholly OOH days.
    const outOfHourDays = {};
    Object.entries( businessHourDays ).forEach( bhd => {
        const [ index, bhs ] = bhd;

        // default period is 0 to final minute in day
        outOfHourDays[ index ] ??= [ { startTime: 0, endTime: 24 * 60 - 1 } ];

        bhs.forEach( bh => {

            // find the OOH chunk that contains this business hour chunk
            const oohIndex = outOfHourDays[ index ].findIndex( ooh => bh.startTime >= ooh.startTime && bh.endTime <= ooh.endTime );
            if ( oohIndex === -1 ) {
                console.warn( "Couldn't find OOH chunk for BH", jsonPrint({ bh, ooh: outOfHourDays[ index ] }) );
                return;
            }
            
            // we have to split this OOH into 2, 1, or 0 pieces. Work out the new times for the 2 piece solution
            // and then see which pieces are valid, if any.
            const ooh = outOfHourDays[ index ][ oohIndex ];
            const pieces = [
                {
                    startTime:  ooh.startTime,
                    endTime:    bh.startTime
                },
                {
                    startTime:  bh.endTime,
                    endTime:    ooh.endTime
                } 
            ].filter( ({ startTime, endTime }) => startTime < endTime );

            // replace old piece with new
            outOfHourDays[ index ].splice( oohIndex, 1, ...pieces );
        });
        

    });

    return { businessHourDays, outOfHourDays, minStartTime, maxEndTime };
};

// thunk actions

const saveOrganisationSettingChanges = () => ( dispatch, getState ) => {

    const {
        organisation: { configurationId,
            edit:{ businessHours, minimumTimeEntry, twoFactorAuthenticationRequired, emailContact, logoURL }
        }
    } = getState();

    // reverse the parsing of the settings
    const payload = {
        configurationId,
        data: {
            timeEntrySettings: {
                businessHours:     wrapBusinessHours( businessHours ),
                timeEntryRounding: {
                    minimumTimeEntry
                }
            },
            securitySettings: {
                twoFactorAuthenticationRequired
            },
            emailContact,
            logoURL
        }
    };

    dispatch( patchOneConfiguration( payload, setLoadOrganisationSettings ) );
};


const saveOnlineAppointmentsSettingChanges = () => ( dispatch, getState ) => {

    const { organisation: { configurationId, 
        edit: {
            onlineAppointmentsEnabled,
            useEnabledClients,
            enabledClients,
            criticalPriorityMessage,
            defaultDuration,
            minimumLeadTimeHours,
            maximumLeadTimeDays,
            prePadding,
            postPadding,
            openHours
        }
    } } = getState();

    // reverse the parsing of the settings
    const payload = {
        configurationId,
        data: {
            clientAppointmentSettings: {
                onlineAppointmentsEnabled,
                useEnabledClients,
                enabledClients,
                criticalPriorityMessage,
                defaultDuration,
                minimumLeadTimeHours,
                maximumLeadTimeDays,
                prePadding,
                postPadding,
                openHours:          wrapBusinessHours( openHours )
            }
        }
    };

    dispatch( patchOneConfiguration( payload, setLoadOrganisationSettings ) );
};

// reducers

const loadOrganisationSettingsReducer = ( draft, payload ) => {
    const { configurationId, data: {
        timeEntrySettings: {
            businessHours,
            timeEntryRounding: { minimumTimeEntry }
        },
        securitySettings: {
            twoFactorAuthenticationRequired
        } = {
            twoFactorAuthenticationRequired: false
        },
        clientAppointmentSettings: {
            onlineAppointmentsEnabled,
            useEnabledClients,
            enabledClients,
            criticalPriorityMessage,
            defaultDuration,
            minimumLeadTimeHours,
            maximumLeadTimeDays,
            prePadding,
            postPadding,
            openHours
        },
        emailContact = false,
        logoURL = "",
        providerJobURL = draft.live?.providerJobURL     // preserve this non-editable setting
    } } = payload;
    draft.configurationId = configurationId;

    // parse backend data for editing/use
    const settings = {
        minimumTimeEntry,
        businessHours:                  unwrapBusinessHours( businessHours ),
        openHours:                      unwrapBusinessHours( openHours || businessHours ),
        twoFactorAuthenticationRequired,
        onlineAppointmentsEnabled,
        useEnabledClients,
        enabledClients,
        criticalPriorityMessage,
        defaultDuration:                Number( defaultDuration ),
        minimumLeadTimeHours:           Number( minimumLeadTimeHours ),
        maximumLeadTimeDays:            Number( maximumLeadTimeDays ),
        prePadding:                     Number( prePadding ),
        postPadding:                    Number( postPadding ),
        emailContact,
        logoURL,
        providerJobURL
    };

    // loading sets both live and edit settings
    draft.live = settings;
    draft.edit = settings;
    draft.foundChanges = false;

    // add the extended data need for the calendar
    // Object.assign( draft, makeCalendarTimeData( settings.businessHours ) );
};

const settingValueReducer = ( draft, payload, bhName = 'businessHours' ) => {
    const { name, value, blockIndex, dayIndex } = payload;

    draft.foundChanges = true;

    // changes to one of the business hours settings (businessHours or openHours) come with blockIndex defined
    if ( blockIndex !== undefined ) {
        const error = handleBusinessHourChange( draft.edit[ bhName ], name, value, blockIndex, dayIndex );
        
        // non-null may be error or empty string
        if ( error !== null ) 
            draft.fieldError[ bhName ] = error;    
    }
    else if ( /minimumLeadTimeHours|maximumLeadTimeDays|prePadding|postPadding/.test( name ) ) {
        if ( !/\D/.test( value ) )
            draft.edit[ name ] = Number( value );
    }
    else
        draft.edit[ name ] = value;
};

const addBusinessHoursReducer = ( draft, payload, bhName = 'businessHours' ) => {
    draft.edit[ bhName ].push({ startTime: 540, endTime: 1020, weekdays: convertWeekdayMap( 0 ) });
    draft.foundChanges = true;
    draft.fieldError[ bhName ] = validateBusinessHours( draft.edit[ bhName ]);
};

const removeBusinessHoursReducer = ( draft, payload, bhName = 'businessHours' ) => {
    draft.edit[ bhName ].splice( payload, 1 );
    draft.foundChanges = true;
    draft.fieldError[ bhName ] = validateBusinessHours( draft.edit[ bhName ]);
};

const discardChangeReducer = draft => {
    draft.edit = draft.live;
    draft.foundChanges = false;
};

const addCAClient = ( draft, payload ) => {
    draft.edit.enabledClients[ payload.id ] = payload.label;
    draft.foundChanges = true;
};

const removeCAClient = ( draft, payload ) => {
    delete draft.edit.enabledClients[ payload ];
    draft.foundChanges = true;
};

const _setEligibleEngineers = ( draft, payload ) => {
    draft.numberEligibleEngineers = payload.engineers.length;
};

const reducers = {
    [ setLoadOrganisationSettings ]:                loadOrganisationSettingsReducer,
    [ setSettingValueOrganisation ]:                settingValueReducer,
    [ setSettingValueOrganisationOpenHours ]:       ( draft, payload ) => settingValueReducer( draft, payload, 'openHours' ),
    [ setAddOrganisationBusinessHours ]:            addBusinessHoursReducer,
    [ setRemoveOrganisationBusinessHours ]:         removeBusinessHoursReducer,
    [ setAddOrganisationOpenHours ]:                ( draft, payload ) => addBusinessHoursReducer( draft, payload, 'openHours' ),
    [ setRemoveOrganisationOpenHours ]:             ( draft, payload ) => removeBusinessHoursReducer( draft, payload, 'openHours' ),
    [ discardOrganisationSettingChanges ]:          discardChangeReducer,
    [ discardOnlineAppointmentsSettingChanges ]:    discardChangeReducer,
    [ setAddCAClientMode ]:                         ( draft, payload ) => draft.addClientApptClient = payload,
    [ setAddCAClient ]:                             addCAClient,
    [ setRemoveCAClient ]:                          removeCAClient,
    [ setEligibleEngineers ]:                       _setEligibleEngineers,
    [ setDirectURL ]:                               ( draft, payload ) => draft.live.providerJobURL = payload.directUrl
};

// selectors

const getOrganisationSettings = state => state.organisation;

export {
    getOrganisationSettings,
    saveOrganisationSettingChanges,
    saveOnlineAppointmentsSettingChanges,
    makeCalendarTimeData,
    checkForEligibleEngineers,
    getDirectUrl
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
