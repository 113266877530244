
import { createAction } from 'redux-actions';
export const setClients = createAction( 'CLIENTS.CLIENTS/SET' );
export const setClientStatus = createAction( 'CLIENTS.CLIENT_STATUS/SET' );
export const setClientLocation = createAction( 'CLIENTS.CLIENT_LOCATION/SET' );
export const setClientUsers = createAction( 'CLIENTS.CLIENT_USERS/SET' );
export const setClientFilterString = createAction( 'CLIENTS.CLIENT_FILTER_STRING/SET' );
export const setUserFilterString = createAction( 'CLIENTS.USER_FILTER_STRING/SET' );
export const setSiteFilterString = createAction( 'CLIENTS.SITE_FILTER/SET' );
// export const updateClients = createAction( 'CLIENTS.CLIENTS/UPDATE' );
export const setUnsavedClients = createAction( 'CLIENTS.UNSAVED_CLIENT/SET' );
export const resetFoundChanges = createAction( 'CLIENTS.FOUND_CHANGES/RESET' );
export const setNewClients = createAction( 'CLIENTS.NEW_CLIENTS/SET' );
export const setUnsavedUsers = createAction( 'CLIENTS.UNSAVED_USERS/SET' );
export const setNewUsers = createAction( 'CLIENTS.NEW_USERS/SET' );
export const setTotalClientCount = createAction( 'CLIENTS.TOTAL_CLIENTS/SET' );
export const setTotalUserCount = createAction( 'CLIENTS.TOTAL_USERS/SET' );
export const setTotalSiteCount = createAction( 'CLIENTS.TOTAL_SITES/SET' );
export const setCurrentPage = createAction( 'CLIENTS.CURRENT_PAGE/SET' );
export const setCurrentUserPage = createAction( 'CLIENTS.CURRENT_USER_PAGE/SET' );
export const setCurrentSitePage = createAction( 'CLIENTS.CURRENT_SITE_PAGE/SET' );
export const setUnsavedSite = createAction( 'CLIENTS.UNSAVED_SITES/SET' );
export const setNewSite = createAction( 'CLIENTS.NEW_SITES/SET' );
export const setClientSettings = createAction( 'CLIENTS.CLIENT_SETTINGS/SET' );

