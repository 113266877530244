/**
Must have:

    * drag images
    * paste images
        * Do we allow sizing or do we set the size automatically? For images displayed at a smaller size
          than the true size, do we allow magnification?
          * answer from SG - display small thumb then open in new tab/popup
    * WYSIWYG Markdown
        * Bold, italic, underline
        * Headings
        * Code blocks
        * Quote blocks
    * paste from Office
    * @mentions
        * Entering an isolated @ shows a combined list of engineers & company contacts. This list is filtered
          by any characters following the @. If no users match, the list disappears and regular text entry
          continues.
        * Selection of a list item adds that user to the appropriate list and adds a formatted 
          inline representation of their name to the editor. This can be removed but not edited.
        * Will removal of the name remove the user from the list? Certainly should not if it was there previously.
          Probably hard to remove the user if the job card is closed and reopened and then the mention is removed.
    * #tags
        * Entering an isolated # shows a list of tags. This list is filtered by any characters following the #. If 
          no tags match, the list disappears and regular text entry continues.
        * (Where do the tags come from?)
        * Selection of a list item adds that tag to the tags for the job. 
        * (Where is the tag list shown and how else can it be modified, eg direct typing?)
        * (Does the tag appear in the worklog? Doesn't seem as natural as a username.)

Nice to have:

    * collaborative editing?
    * tables
    * inline files
 */


import React, { useState, useCallback, useEffect, useRef } from 'react';

import { has } from '@licoriceio/utils';
import PropTypes from 'prop-types';
import { createEditor, Editor, Transforms, Text } from 'slate';
import { withHistory } from 'slate-history';
import {
    Slate,
    Editable,
    withReact } from 'slate-react';

import { editorClass, code, quote, display } from '../../../scss/RichEditor.module.scss';

import EditorImageWrapper from './EditorImageWrapper.jsx';

const insertImage = ( editor, url, title ) => {
    const text = { text: '' };
    const image = { type: 'image', url, title, children: [ text ] };
    const blank = { type: 'paragraph', children: [ text ] };

    // add a blank para after the image, so we can append content after it
    Transforms.insertNodes( editor, [ image, blank ]);
};

const DefaultElement = props => {
    return <div {...props.attributes}>{props.children}</div>;
};
DefaultElement.propTypes = {
    attributes:     PropTypes.any,
    children:       PropTypes.any
};

// Define a React component renderer for our code blocks.
const CodeElement = props => {
    return (
        <pre className={code} {...props.attributes}>
            <code>{props.children}</code>
        </pre>
    );
};
CodeElement.propTypes = {
    attributes:     PropTypes.any,
    children:       PropTypes.any
};

// Define a React component to render leaves with styled text.
const Leaf = props => {
    return (
        <span
            {...props.attributes}
            style={{ 
                fontWeight:         props.leaf.strong ? 'bold' : 'normal',
                fontStyle:          props.leaf.emphasis ? 'italic' : 'normal',
                textDecoration:     `${props.leaf.underline ? 'underline ' : ''}${props.leaf.strikethrough ? 'line-through' : ''}`.trim()
            }}
        >
            {props.children}
        </span>
    );
};
Leaf.propTypes = {
    attributes:     PropTypes.any,
    children:       PropTypes.any,
    leaf:           PropTypes.object
};

// Define our own custom set of helpers.
const CustomEditor = {

    isMarkActive( editor, mark ) {
        const [ match ] = Editor.nodes( editor, {
            match:     n => n[ mark ] === true,
            universal: true
        });

        return !!match;
    },

    isBlockActive( editor, block ) {
        const [ match ] = Editor.nodes( editor, {
            match: n => n.type === block
        });
  
        return !!match;
    },

    toggleMark( editor, mark ) {
        const isActive = CustomEditor.isMarkActive( editor, mark );
        Transforms.setNodes(
            editor,
            { [ mark ]: isActive ? null : true },
            { match: n => Text.isText( n ), split: true }
        );
    },

    toggleBlock( editor, block ) {
        const isActive = CustomEditor.isBlockActive( editor, block );
        Transforms.setNodes(
            editor,
            { type: isActive ? null : block },
            { match: n => Editor.isBlock( editor, n ) }
        );
    }
};


const withImages = ( editor, addFile ) => {
    const { isVoid, insertData } = editor;
  
    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid( element );
    };

    editor.insertData = data => {
        const { files } = data;

        if ( files && files.length > 0 ) {
            for ( const file of files ) {
                const [ mime ] = file.type.split( '/' );

                if ( mime === 'image' ) {
                    addFile({ 
                        formData: {
                            file
                        },
                        addToEditor: fileKey => insertImage( editor, fileKey, file.name )
                    });
                }
            }
        } 

        // this line goes above, just preserving it while the url code is commented out
        // const text = data.getData( 'text/plain' );
        // else if ( isUrl( text ) )
        
        // TO-do: work for links to work with plain text. Currently only works when copy paste link alone in chat.
        // hence commenting below code.

        // const link = { type: 'link', title: null, url: text, children: [ { text } ] };
        // const paragraph = { type: 'paragraph', children: [ link ] };
        // Transforms.insertNodes( editor, { type: 'paragraph', children: [ paragraph ] });

        //     or
        // const link = { title: text, url: text };
        // const node = { type: "paragraph", children: { type: "link", children: [ { text } ], ...link  }  };

        else 
            insertData( data );
        
    };
  
    return editor;
};

const standardToggles = {
    'ctrl-alt-shift-C':     { method: CustomEditor.toggleBlock, label: 'code_block' },
    'ctrl-shift-Q':         { method: CustomEditor.toggleBlock, label: 'blockquote' },
    'ctrl-b':               { method: CustomEditor.toggleMark, label: 'strong' },
    'ctrl-i':               { method: CustomEditor.toggleMark, label: 'emphasis' },
    'ctrl-u':               { method: CustomEditor.toggleMark, label: 'underline' },
    'ctrl-shift-X':         { method: CustomEditor.toggleMark, label: 'strikethrough' }
};

const singleParagraph = text => `[ { "type": "paragraph", "children": [ { "text": "${text}" } ] } ]`;

const forceToJSON = value => {

    let json;
    try {
        json = JSON.parse( value );

        // some scalars convert to another scalar
        if ( !Array.isArray( json ) ) {
            try {
                json = JSON.parse( singleParagraph( value ) );
                return json;
            }
            catch ( e ) {
                return JSON.parse( singleParagraph( 'Error reading note: failed scalar conversion' ) );
            }
        }
        else 
            return json;

    } catch ( e ) {

        // this was the old attempt to convert plain-text to Slate; this should be redundant now
        // but it seems harmless to leave it in?
        console.warn( 'failed, try to convert', `-->${singleParagraph( value )}<--` );
        try {
            json = JSON.parse( singleParagraph( value ) );
            return json;
        }
        catch ( e2 ) {
            console.error( 'failed text conversion', e2 );
            return JSON.parse( singleParagraph( 'Error reading note: failed text conversion' ) );
        }
        
    }
};

const RichEditor = ({ addFile, textValue, onChange, disabled, displayOnly, placeholder, hotkey, autoFocus, dataName }) => {

    const [ editor ] = useState( () => withImages( withReact( withHistory( createEditor() ) ), addFile ) );

    const [ value, setValue ] = useState( forceToJSON( textValue || singleParagraph( '' ) ) );
    // const [ value, setValue ] = useState( JSON.parse( textValue || singleParagraph( '' ) ) );
    const [ localText, setLocalText ] = useState( textValue );

    const reset = () => {
        // loop delete all
        editor?.children?.map( item => {
            Transforms.delete( editor, { at: [ 0 ] });
        });

        if ( editor ) {
            // reset init
            editor.children = [
                {
                    type:     "paragraph",
                    children: [ { text: "" } ]
                }
            ];
        }
    };

    useEffect( () => {
        if ( textValue !== localText ) {
            if ( textValue.length ) {
                const newValue = JSON.parse( textValue );
                setValue( newValue );
                Transforms.delete( editor, {
                    at: {
                        anchor: Editor.start( editor, []),
                        focus:  Editor.end( editor, [])
                    }
                });
                // Removes empty node
                Transforms.removeNodes( editor, {
                    at: [ 0 ]
                });

                // Insert array of children nodes
                Transforms.insertNodes(
                    editor,
                    newValue
                );
            }
            else {

                // if we clear everything by the above method we get errors, I think
                // because Slate has internal state re current position, etc.
                // We could probably do the above operation via Transform operations 
                // as well if we wanted to be nicer.
                Transforms.delete( editor, {
                    at: {
                        anchor: Editor.start( editor, []),
                        focus:  Editor.end( editor, [])
                    }
                });
                reset();
            }
        }
    }, [ textValue ]);
    
    // Define a rendering function based on the element passed to `props`. 
    const renderElement = useCallback( prop => {
        switch ( prop.element.type  ) {
            case 'blockquote':
                return <blockquote className={quote} {...prop} />;
            case 'code_block':
                return <CodeElement {...prop} />;
            case 'image':
                return <EditorImageWrapper {...prop} />;
            default:
                return <DefaultElement {...prop} />;
        }
    }, []);

    // Define a leaf rendering function that is memoized with `useCallback`.
    const renderLeaf = useCallback( props => {
        return <Leaf {...props} />;
    }, []);

    return (
        <Slate 
            editor={editor} 
            value={value}
            initialValue={textValue || []}
            onChange={value => {
                if ( !displayOnly ) {

                    // the editor is empty if there's only 1 node and that node is empty
                    const empty = value.length === 1 && Editor.isEmpty( editor, value[ 0 ]);
                
                    const json = JSON.stringify( value );
                    onChange( json, empty );
                    setLocalText( json );
                    setValue( value );

                    // saving to local storage was very useful during development...
                    // 
                    // const isAstChange = editor.operations.some(
                    //     op => op.type !== 'set_selection'
                    // );
                    // if ( isAstChange ) 
                    //     localStorage.setItem( 'ikmslatecontent', JSON.stringify( value ) );
                }
              
            }}
        >
            <Editable
                placeholder={placeholder}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                readOnly={disabled || displayOnly}
                className={displayOnly ? display : editorClass}
                autoFocus={autoFocus}
                data-ux={displayOnly ? undefined : dataName}
                onKeyDown={event => {

                    // all of our shortcuts include Ctrl key, and the only other special keys we want handling for
                    // are Tab, Enter and Escape
                    if ( !event.ctrlKey && !/Enter|Tab|Escape/.test( event.key ) )
                        return;

                    const modifiers = [ ];
                    if ( event.ctrlKey ) modifiers.push( 'ctrl' );
                    if ( event.altKey ) modifiers.push( 'alt' );
                    if ( event.shiftKey ) modifiers.push( 'shift' );
                    modifiers.push( event.key );
                    const fullKey = modifiers.join( '-' );

                    // check supplied key shortcuts
                    if ( has( hotkey, fullKey ) ) {
                        event.preventDefault();
                        hotkey[ fullKey ]( event, editor );
                    }

                    // check our shortcuts
                    else if ( has( standardToggles, fullKey ) ) {
                        const { method, label } = standardToggles[ fullKey ];
                        event.preventDefault();
                        method( editor, label );
                    }

                }}
            >
                {value}
            </Editable>
        </Slate>
    );
};
RichEditor.propTypes = {
    addFile:            PropTypes.func,
    textValue:          PropTypes.string.isRequired,
    onChange:           PropTypes.func,
    disabled:           PropTypes.bool,
    displayOnly:        PropTypes.bool,
    placeholder:        PropTypes.string,
    hotkey:             PropTypes.object,
    autoFocus:          PropTypes.bool,
    dataName:           PropTypes.string
};

export default RichEditor;
export { RichEditor };
