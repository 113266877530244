import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setDNSStatus } from '../../redux/actions';
import { root, appLink } from '../../scss/Confirmation.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { H2, H3, LicoButton, LicoStaticLink, P, CheckDNS, DNSStatus } from "../common/index.js";

const ConfirmationForm = ({ finalUrl, dnsStatus, setDNSStatus }) => {

    return <div className={root}>
        <H2 >{dnsStatus < DNSStatus.SUCCESS ? __( "Congratulations! Sort of." ) : __( "Congratulations!" )}</H2>
        <br/>
        { dnsStatus !== DNSStatus.SUCCESS && <>
            {dnsStatus === DNSStatus.IN_PROGRESS
                ? <P small>{__( "You've completed the setup process! Now we have to set up your new site." )}</P>
                : <P small>{__( "There was a problem setting up your site." )}</P>}
            <br/>
            <CheckDNS
                url={finalUrl} 
                setFinalStatus={setDNSStatus} 
                fixedDelaysMsecs={5000} 
                fixedDelayLabel={ __( "Creating DNS Worker..." )}
            />
        </>}
        { dnsStatus === DNSStatus.SUCCESS && <>
            <P small>{__( "Your Licorice instance is ready to go! You're a few steps away from the end of IT tickets forever." )}</P>
            <br/>
            <H3>{__( "Your Licorice URL" )}</H3>
            <LicoStaticLink className={appLink} to={finalUrl}>{finalUrl}</LicoStaticLink>
            <br/>
            <LicoButton element="a" href={finalUrl} >{__( "Sign in" )}</LicoButton>
        </>}
    </div>;
};

ConfirmationForm.propTypes = {
    finalUrl:       PropTypes.string.isRequired,
    dnsStatus:      PropTypes.number.isRequired,
    setDNSStatus:   PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    finalUrl:   state.registration.finalUrl,
    dnsStatus:  state.registration.dnsStatus
});

const mapDispatchToProps = {
    setDNSStatus
};

export default connect( mapStateToProps, mapDispatchToProps )( ConfirmationForm );
export { ConfirmationForm };
