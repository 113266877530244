import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";


import { snackbarKey } from "../../../constants.js";
import { UX_CLIENTUSER_ADD_BUTTON } from "../../../ux-constants.js";
import { resetFoundChanges, setClientSettings } from "../../../redux/actions/index.js";
import {  saveButton, footer, leftFooter, rightFooter, addButton, iconStyle, fabStyle, errorStyle } from '../../../scss/ManageClient.module.scss';
import { filterLine } from '../../../scss/ManageEngineers.module.scss';
import { settings, screenTitle, linkHeader, clientNm, spaceAround } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import { LicoButton, LicoLiveSearch, LicoSmallButton } from "../../common/index.js";
import LicoSnackbar from "../../common/LicoSnackbar.jsx";
import LicoTable from "../../common/LicoTable.jsx";
import { ListPager } from "../../common/ListPager.jsx";
import NavigationDialog from "../../common/NavigationDialog.jsx";
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import { getClientUsers, PAGE_SIZE, saveUserChanges, setUnsavedUsers, setUserFilterString, setNewUsers, continueUserNavigation } from "./reducer.js";


const Users = ( props ) => {

    const { getClientUsers, clientUsers, foundChanges, setUnsavedUsers, saveUserChanges, setNewUsers, columns, showDuplicateErrorMessage, contactId,
        userFilterString, setUserFilterString, currentUserPage, totalUserCount, confirmNavigationDialogOpen, setClientSettings, continueUserNavigation, resetFoundChanges } = props;
    const { clientId, clientName } = useParams();
    const name = decodeURIComponent( clientName );
    const [ users, setUsers ] = useState( clientUsers );
    const history = useHistory();
    const [ newRowCount, setNewRowCount ] = useState( 1 );

    useEffect( () => {
        getClientUsers({  page: 0, userFilterString, clientId });
    }, [ userFilterString, clientId ]);


    const handleUserChange = ( id, field, value ) => {
        if ( field === 'phone' || field === 'email' ) {
            const newUsers = users.map( user => {
                // if the user contactInfo is empty then add the default contactInfo
                if ( user.userId === id ) {
                    let contactInfo = user.contactInfo.length === 0 ? [] : user.contactInfo;
                    const fieldData = user.contactInfo.find( contact => contact.type === field );
                    if ( !fieldData ) {
                        contactInfo.push({
                            type:      field,
                            value:     value
                        });
                    } else
                        contactInfo = contactInfo.map( contact => contact.type === field ? { ...contact, value } : contact );
                    

                    const updatedUser = { ...user, contactInfo };
                    // Remove contactInfo if it's empty
                    if ( contactInfo.length === 0 )
                        delete updatedUser.contactInfo;
                    return updatedUser;
                }
                return user;
            });
            setUsers( newUsers );
        }
        else
            setUsers( users.map( user => user.userId === id ? { ...user, [ field ]: value } : user ) );


        if ( id <= 0 )
            setNewUsers({ userId: id, [ field ]: value });
        else
            setUnsavedUsers({ userId: id, [ field ]: value });
    };
    const addRow = () => {
        const userId = -newRowCount;
        setUsers([
            ...users,
            {
                userId:      userId,
                defaultUser: ( users || []).length === 0,
                companyId:   clientId,
                name:        '',
                title:       '',
                phone:       '',
                email:       '',
                active:      true,
                contactInfo: [
                    {
                        type:      'phone',
                        value:     '',
                        isDefault: true
                    },
                    {
                        type:      'email',
                        value:     '',
                        isDefault: true
                    }
                ]
            }
        ]);
        if ( users.length === 0 ) {
            setNewUsers({ userId: userId, defaultUser: true, companyId: clientId });
            setClientSettings({ selectedContactId: userId });
        }

        setNewRowCount( newRowCount + 1 );
    };

    useEffect( () => {
        setUsers( clientUsers );
    }, [ clientUsers ]);

    useEffect( () => {
        if ( users ) {
            setUsers( users.map( user => {
                if ( user.userId === contactId )
                    return { ...user, defaultUser: true };

                return user;
            }) );

        // check if contactId and User Id are the same, then set defaultUser to true
        } }, [ contactId ]);

    // show validation error if the email address is empty
    const showValidationErrorMessage = foundChanges && users.some( user => {
        const email = user?.contactInfo?.find( contact => contact.type === 'email' );
        const name = user?.name;
        return !email?.value || !name;
    });
    const validEmailErrorMessage = foundChanges && users.some( user => {
        const contact = user?.contactInfo?.find( contact => contact.type === 'email' );
        const email = contact?.value;
        if ( email ) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !emailRegex.test( email );
        }
        return false;
    });

    const handleRadioChange = id  => {
        setClientSettings({ selectedContactId: id, foundChanges: true });
        handleUserChange( id, 'defaultUser', true );
        handleUserChange( id, 'companyId', clientId );
    };

    return (
        <div>
            <LicoSnackbar
                metaKey={ snackbarKey.CLIENT_DETAILS_SAVED }
                label={__( "Changes saved." )}
            />
            <NavigationDialog
                isOpen={confirmNavigationDialogOpen}
                saveAction={() => continueUserNavigation( true )}
                discardAction={() => continueUserNavigation( false )}
                cancelAction={() => setClientSettings({ confirmNavigationDialogOpen: false })}
            />
            <PreventTransitionPrompt
                when={foundChanges}
                history={history}
                saveAction={saveUserChanges}
                discardAction={resetFoundChanges}
            />
            <SettingsLayout>
                <div className={settings}>
                    <div className={screenTitle}>
                        <Link className={linkHeader} to="/settings/clients">Clients</Link>
                        <span className={spaceAround}> {'>'} </span>
                        <div className={clientNm}>{name}</div>
                    </div>

                    <div className={filterLine}>
                        <LicoLiveSearch
                            placeholder={__( "Filter Name" )}
                            value={userFilterString}
                            onChange={e => setUserFilterString( e.target.value )}
                            id="userFilter"
                        />
                    </div>
                </div>
                <LicoTable
                    columns={columns}
                    rows={users}
                    onChange={handleUserChange}
                    uniqueKey="userId"
                    selectedRadioId={contactId}
                    onRadioChange={handleRadioChange}
                />
                {showValidationErrorMessage && <div className={errorStyle}>{__( "Please fill in all required fields" )}</div>}
                {showDuplicateErrorMessage && <div className={errorStyle}>{__( "User Email Already Exists" )}</div>}
                {validEmailErrorMessage && <div className={errorStyle}>{__( "Please enter a valid email address" )}</div>}
                <div className={footer}>
                    <div className={ leftFooter}>
                        <LicoSmallButton
                            labelClass={addButton}
                            label={__( "Add User" )}
                            fabClass={fabStyle}
                            dataName={UX_CLIENTUSER_ADD_BUTTON}
                            onClick={addRow}
                            iconClass={iconStyle}
                        />
                        <LicoButton
                            className={saveButton}
                            onClick={() => saveUserChanges( clientId )}
                            disabled={!foundChanges || showValidationErrorMessage || validEmailErrorMessage}
                        >{__( "Save" )}
                        </LicoButton>
                    </div>

                    <div className={rightFooter} >
                        <ListPager
                            page={currentUserPage}
                            pageSize={PAGE_SIZE}
                            totalPages={Math.ceil( totalUserCount / PAGE_SIZE )}
                            getPage={getClientUsers}
                            filter={userFilterString}
                        />
                    </div>
                </div>
            </SettingsLayout>

        </div>
    );
};

Users.propTypes = {
    clientUsers:                 PropTypes.array,
    getClientUsers:              PropTypes.func.isRequired,
    foundChanges:                PropTypes.bool.isRequired,
    setUnsavedUsers:             PropTypes.func.isRequired,
    saveUserChanges:             PropTypes.func.isRequired,
    resetFoundChanges:           PropTypes.func.isRequired,
    setNewUsers:                 PropTypes.func.isRequired,
    userFilterString:            PropTypes.string.isRequired,
    setUserFilterString:         PropTypes.func.isRequired,
    currentUserPage:             PropTypes.number,
    totalUserCount:              PropTypes.number,
    confirmNavigationDialogOpen: PropTypes.bool.isRequired,
    setClientSettings:           PropTypes.func.isRequired,
    continueUserNavigation:      PropTypes.func.isRequired,
    columns:                     PropTypes.array.isRequired,
    showDuplicateErrorMessage:   PropTypes.bool.isRequired,
    contactId:                   PropTypes.string
};

const mapStateToProps = state => {
    return {
        clientUsers:                 state?.client?.clientUsers,
        foundChanges:                state?.client?.foundChanges,
        userFilterString:            state?.client?.userFilterString,
        currentUserPage:             state?.client?.currentUserPage,
        totalUserCount:              state?.client?.totalUserCount,
        confirmNavigationDialogOpen: state?.client?.confirmNavigationDialogOpen,
        columns:                     state?.client?.userColumns,
        showDuplicateErrorMessage:   state?.client?.showDuplicateErrorMessage,
        contactId:                   state?.client?.selectedContactId
    };
};

const mapDispatchToProps = {
    getClientUsers,
    setUnsavedUsers,
    saveUserChanges,
    setNewUsers,
    setUserFilterString,
    setClientSettings,
    continueUserNavigation,
    resetFoundChanges
};

export default connect( mapStateToProps, mapDispatchToProps )( Users );
