import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { JournalTypeLabel } from '../../../constants.js';
import { setJournalDownloadContent, setJournalPageIndex } from '../../../redux/actions/index.js';
import { journal, listPager, journalRow, journalRowType, journalRowDate, journalRowData, filterLine, downloadButton, downloadContainer } from '../../../scss/Journal.module.scss';
import { settings, screenTitle } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import GenericDialog from '../../common/GenericDialog.jsx';
import { H1, LicoLiveSearch, LicoFab, LicoIcon, LicoButton } from "../../common/index.js";
import ListPager from '../../common/ListPager.jsx';
import SettingsLayout from '../../layouts/SettingsLayout.jsx';

import { getJournals, getJournalsForDownload  } from './reducer.js';

const MAX_LINES = 15;

const NoMaxWidthTooltip = styled( ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
) )({
    [ `& .${tooltipClasses.tooltip}` ]: {
        maxWidth: 'none'
    }
});

const JournalLine = ({ line }) => {
    const data = JSON.stringify( line.data );
    const niceData = JSON.stringify( line.data, undefined, 4 );
    return <div key={line.journalId} className={journalRow}>
        <div className={journalRowType}>{JournalTypeLabel[ line.type ]}</div>
        <div className={journalRowDate}>{line.createdOn}</div>
        <NoMaxWidthTooltip title={<pre>{niceData}</pre>} placement="bottom-start">
            <div className={journalRowData}>{data}</div>
        </NoMaxWidthTooltip>
    </div>; 
};
JournalLine.propTypes = {
    line:       PropTypes.object.isRequired
};

// I'm using this function to create a temporary anchor element because creating such 
// a button manually and specifying a data URL ourselves doesn't give us a chance to clean up;
// there's no other action caused by/with the download.
function downloadJSON( json, filename ) {

    // Create a new Blob object with the JSON data and set its type
    const blob = new Blob([ json ], { type: 'application/json' });

    // Create a temporary URL for the file
    const url = URL.createObjectURL( blob );

    // Create a new link element with the download attribute set to the desired filename
    var link = document.createElement( 'a' );
    link.setAttribute( 'download', filename );

    // Set the link's href attribute to the temporary URL
    link.href = url;

    // Simulate a click on the link to trigger the download
    document.body.appendChild( link );
    link.click();

    // Clean up the temporary URL and link element
    document.body.removeChild( link );
    URL.revokeObjectURL( url );

};

const Journal = ({ page, total, lines, downloadContent, getJournals, getJournalsForDownload, setJournalPageIndex, setJournalDownloadContent }) => {

    const [ filterString, setFilterString ] = useState( '' );

    useEffect( () => {
        getJournals( '', page, MAX_LINES );
    }, [ getJournals, page  ]);

    const currentTime = dayjs();

    return <SettingsLayout>

        <GenericDialog
            isOpen={downloadContent != null}
            title={__( "Download Journal Entries" )}
            message={ __( "Press Download to save all journal entries matching the current filter." )}
            buttons={[
                {
                    label:          __( "Cancel" ),
                    action:         () => setJournalDownloadContent( null )
                }
            ]}
            extraContent={<div className={downloadContainer}><LicoButton
                className={downloadButton}
                onClick={() => {
                    downloadJSON( downloadContent, `journal-${currentTime.toISOString()}.json` );
                    setJournalDownloadContent( null );
                } }
            >
                {__( "Download" )}
            </LicoButton></div>}
        />

        <div className={`${settings} ${journal}`}>
            <H1 className={screenTitle}>{__( "Journal" )}</H1>
            <div className={filterLine}>
                <LicoLiveSearch
                    placeholder={__( "Filter" )}
                    value={filterString}
                    onChange={e => {
                        getJournals( e.target.value, 0, MAX_LINES );
                        setJournalPageIndex( 0 );
                        setFilterString( e.target.value );
                    }}
                />

                {lines.length > 0 &&
                    <LicoFab onClick={() => getJournalsForDownload( filterString )}>
                        <LicoIcon icon="download" />
                    </LicoFab>
                }

            </div>

            <div>
                {lines.length > 0 
                    ? lines.map( line => <JournalLine key={line.journalId} line={line} /> ) 
                    : <span>{__( "No entries found." )}</span>}
            </div>

            {total > 0 && <ListPager 
                className={listPager}
                page={page} 
                totalPages={Math.ceil( total / MAX_LINES )} 
                getPage={({ page: newPage }) => {
                    getJournals( filterString, newPage, MAX_LINES );
                    setJournalPageIndex( newPage );
                }} 
            />}
        </div>


    </SettingsLayout>;

};

Journal.propTypes = {
    page:                           PropTypes.number.isRequired,
    total:                          PropTypes.number.isRequired,
    lines:                          PropTypes.array.isRequired,
    downloadContent:                PropTypes.string,
    getJournals:                    PropTypes.func.isRequired,
    getJournalsForDownload:         PropTypes.func.isRequired,
    setJournalPageIndex:            PropTypes.func.isRequired,
    setJournalDownloadContent:      PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    page:                   state.journal.page,
    total:                  state.journal.total,
    lines:                  state.journal.displayedLines,
    downloadContent:        state.journal.downloadContent
});

const mapDispatchToProps = {
    getJournals,
    getJournalsForDownload,
    setJournalPageIndex,
    setJournalDownloadContent
};

export default connect( mapStateToProps, mapDispatchToProps )( Journal );
export { Journal };
