/********************************************************************************************************************
 * @file Actions for the registration store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setProgress = createAction( 'REGISTRATION.PROGRESS/SET' );
export const setBetaKey = createAction( 'REGISTRATION.BETA_KEY/SET' );
export const setFormValueRegistration = createAction( 'REGISTRATION.FORM_VALUE/SET' );
export const setTouchedRegistration = createAction( 'REGISTRATION.TOUCHED/SET' );
export const setAdvance = createAction( 'REGISTRATION.ADVANCE/SET' );
export const setErrorRegistration = createAction( 'REGISTRATION.ERROR/SET' );
export const setRegistrationDone = createAction( 'REGISTRATION.DONE/SET' );
export const showCurrentErrors = createAction( 'REGISTRATION.SHOW_ERRORS/SET' );
export const setDNSStatus = createAction( 'REGISTRATION.DNS_STATUS/SET' );
