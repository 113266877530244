/*********************************************************************************************************************
 * @file Pegboard slot component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { PureComponent } from 'react';

import { MAX_TIME_LOG_DURATION, Priority } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { disablePlayPauseDuringRequests } from '../../constants.js';
import { getJobFromState, getJobCompany, getJobAppointmentCount, selectCacheRecord } from '../../redux/selectors/index.js';
import { pegboardSlot, fabWrapper, client, closeIcon, closeFab, label, spacer, bottomRow, timerPaused } from '../../scss/PegboardSlot.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { priorityColors, priorityColorsWithBorders, requestIsLoading } from '../../utils/misc.js';
import { getDefaultAppointmentDuration } from '../../utils/user-prefs.js';
import { UX_PEGBOARD_CARD, UX_PEGBOARD_CARD_CLIENT, UX_PEGBOARD_CARD_TITLE, UX_PEGBOARD_CARD_DELETE_BUTTON } from '../../ux-constants.js';
import { setExistingJobThunk } from '../calendar/sharedThunks.js';
import { LicoFab, LicoIcon } from '../common/index.js';
import { PlayPauseButton } from '../layouts/sections/PlayPauseButton.jsx';
import { TimeMeter } from '../layouts/sections/TimeMeter.jsx';

import { pausePegboardTimer, resumePegboardTimer, removeJobFromPegboard } from './reducer.js';

// record and make available the alt key state at the last mouse down event
let _mouseDownAltKey;
const getPreviousPBMouseDownAltKey = () => _mouseDownAltKey;

class PegboardSlot extends PureComponent {

    render() {
        const {
            item,
            setExistingJobThunk,
            companyName,
            title,
            description,
            priority,
            estimatedTime,
            resumePegboardTimer,
            pausePegboardTimer,
            removeJobFromPegboard,
            appointmentCount,
            duration,
            pendingTimeLogId,
            localTimeLogId,
            currentJobTime,
            readOnlyCompany,
            playPausePendingReq
        } = this.props;

        const { timeLogId, jobId, pausedAt, start } = item;

        const timerRunning = pausedAt === null && !!start;

        const disabled = timeLogId === pendingTimeLogId || appointmentCount === 0 || timeLogId === localTimeLogId;
        const disabledTooltip = timeLogId === pendingTimeLogId
            ? __( "The Job cannot be removed until the Note has been added" )
            : timeLogId === localTimeLogId
                ? __( "The Job cannot be removed until it has been fully added" )
                : __( "You need to schedule an Appointment before you can remove this Job from the Pegboard" );

        const playDisabledTooltip = readOnlyCompany ? __( "The timer cannot be started because the company is currently read-only" ) : undefined;

        return <div data-type="">
            <div
                className={`${pegboardSlot} 
                    ${priorityColorsWithBorders[ priority || Priority.normal ]} 
                    ${timerRunning ? '' : timerPaused}
                    pegboard-full-slot`}
                data-type=""
                data-id={jobId}
                data-timelog-id={timeLogId}
                data-timer-running={timerRunning}
                data-company={companyName}
                data-title={title}
                data-description={description}
                data-time={getDefaultAppointmentDuration()}
                data-priority={priority}
                data-new-item-flag={true}
                draggable

                // record the alt key state so we can distinguish alt-drag vs drag
                onMouseDown={event => {
                    _mouseDownAltKey = event.altKey;

                    // force blur because the draggable stuff is stopping automatic loss of focus when clicking on the PB card
                    document.activeElement.blur();
                }}

                onClick={() => setExistingJobThunk({ jobId })}
            >

                <div data-ux={UX_PEGBOARD_CARD}>
                    <div className={fabWrapper}>
                        <div className={client} data-ux={UX_PEGBOARD_CARD_CLIENT}>
                            {companyName}
                        </div>
                        <LicoFab
                            color="primary"
                            licoVariant="tiny"
                            disabled={disabled}
                            onClick={e => {
                                e.stopPropagation();
                                removeJobFromPegboard( timeLogId );
                            }}
                            className={closeFab}
                            disabledTooltip={disabledTooltip}
                            data-ux={UX_PEGBOARD_CARD_DELETE_BUTTON}
                        >
                            <LicoIcon icon="close" className={closeIcon} />
                        </LicoFab>

                    </div>
                    {/* <P className={time} gutterBottom={false}>{appointmentDescription || '' }</P> */}
                    <div className={`${label} ${priorityColors[ priority || Priority.normal ]}`} data-ux={UX_PEGBOARD_CARD_TITLE}>{title}</div>

                    <div className={bottomRow}>
                        <TimeMeter 
                            timeTaken={duration || 0} 
                            totalTime={estimatedTime || 0} 
                            priority={priority} 
                            currentTime={currentJobTime} 
                            maxCurrentTime={MAX_TIME_LOG_DURATION / 1000}
                            timerRunning={timerRunning}
                        />
                        <span className={spacer} >&nbsp;</span>
                        <PlayPauseButton
                            isPlaying={timerRunning}
                            priority={priority}
                            disabled={timeLogId === pendingTimeLogId || readOnlyCompany || playPausePendingReq}
                            onPlay={() => resumePegboardTimer( timeLogId, jobId )}
                            onPause={() => pausePegboardTimer({ timeLogId, pegboard: true })}
                            tooltip={playDisabledTooltip}
                        />
                    </div>
                </div>
            </div>
        </div>;
    }

    static propTypes = {
        item:                   PropTypes.object.isRequired,
        title:                  PropTypes.string,
        description:            PropTypes.string,
        companyName:            PropTypes.string,
        pendingTimeLogId:       PropTypes.string,
        localTimeLogId:         PropTypes.string,
        priority:               PropTypes.string,
        estimatedTime:          PropTypes.number,
        setExistingJobThunk:         PropTypes.func.isRequired,
        pausePegboardTimer:     PropTypes.func.isRequired,
        removeJobFromPegboard:  PropTypes.func.isRequired,
        resumePegboardTimer:    PropTypes.func.isRequired,
        appointmentCount:       PropTypes.number.isRequired,
        duration:               PropTypes.number,
        currentJobTime:         PropTypes.number,
        readOnlyCompany:        PropTypes.bool.isRequired,
        playPausePendingReq:    PropTypes.bool.isRequired
    };
}

const mapStateToProps = ( state, props ) => {

    const { title, description, priority, estimatedTime, companyId } = getJobFromState( state, props.item.jobId ) || {};
    const { readOnly } = selectCacheRecord( state, 'company', companyId ) || {};
    const { companyName } = getJobCompany( state, props.item.jobId );
    const { jobcard: { pendingNote }, calendar: { userMap }, timelog: { jobTimeMap } } = state;

    const playPausePendingReq = requestIsLoading( state, disablePlayPauseDuringRequests );

    const appointmentCount = getJobAppointmentCount( userMap, props.item.jobId );

    return {
        title,
        description,
        companyName,
        priority,
        estimatedTime,
        appointmentCount,
        duration:           state.timelog.elapsedMeters[ props.item.jobId ],
        pendingTimeLogId:   pendingNote?.timeLogId,
        localTimeLogId:     state.timelog.localTimeLogId,
        currentJobTime:     jobTimeMap[ props.item.jobId ] || 0,
        readOnlyCompany:    readOnly || false,
        playPausePendingReq
    };
};

const mapDispatchToProps = {
    setExistingJobThunk,
    resumePegboardTimer,
    pausePegboardTimer,
    removeJobFromPegboard
};

export default connect( mapStateToProps, mapDispatchToProps )( PegboardSlot );
export { PegboardSlot, getPreviousPBMouseDownAltKey };
