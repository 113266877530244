import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectCurrentCalendarEventId, selectCurrentJobCardId, selectSearchPanelShown, selectNotificationPanelShown, selectUser } from '../../redux/selectors/index.js';
import { rootHome, homeContainer, notificationContainer, rootHomeWithFilter } from '../../scss/Home.module.scss';
import MainCalendar from '../calendar/MainCalendar.jsx';
import JobCardModal from '../jobcard/JobCardModal.jsx';
import OurStandardAuthLayout from '../layouts/OurStandardAuthLayout.jsx';
import NotificationPanel from '../notification/NotificationPanel.jsx';
import PegboardPanel from '../pegboard/PegboardPanel.jsx';
import SearchPanel from '../searchPanel/SearchPanel.jsx';

const Home = ({ user, searchPanelShown, currentJobCardId, currentCalendarEventId, notificationPanelShown }) => (
    <OurStandardAuthLayout>
        {user && <div className={`${rootHome} ${searchPanelShown ? rootHomeWithFilter : ''}`}>

            <PegboardPanel/>
            <div className={homeContainer}>
                {( currentJobCardId || currentCalendarEventId ) && <JobCardModal jobId={currentJobCardId} calendarEventId={currentCalendarEventId} />}
                <MainCalendar/>
            </div>
            <div className = {notificationContainer}>
                <NotificationPanel notificationPanelShown={notificationPanelShown}/>
                <SearchPanel searchPanelShown={searchPanelShown || false}/>
            </div>

        </div>}
    </OurStandardAuthLayout>
);

Home.propTypes = {
    user:                   PropTypes.object,
    searchPanelShown:       PropTypes.bool,
    currentJobCardId:       PropTypes.string,
    currentCalendarEventId: PropTypes.string,
    notificationPanelShown: PropTypes.bool
};

// const mapStateToProps = ( state: BaseState ) => {
const mapStateToProps = state => {
    return {
        user:                   selectUser( state ),
        searchPanelShown:       selectSearchPanelShown( state ),
        currentJobCardId:       selectCurrentJobCardId( state ),
        currentCalendarEventId: selectCurrentCalendarEventId( state ),
        notificationPanelShown: selectNotificationPanelShown( state )
    };
};

export default connect( mapStateToProps )( Home );
