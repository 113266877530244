import React from 'react';

import { faArrowCircleRight, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import {
    faTimes, faPlus, faStopwatch, faCreditCard,
    faFileImage, faFileAlt,
    faExpandAlt, faChevronDown, faChevronRight, faChevronUp, faSnooze, faGift
} from '@fortawesome/pro-light-svg-icons';
import {
    faTrashAlt,  faEyeSlash, faEye,  faPaperclip, faArrowRight, faArrowLeft, faCalendarCheck, faCalendarTimes, faSync,
    faAngleRight, faAngleLeft, faEdit, faExclamationCircle, faCircleQuestion, faXmark, faMessageLines as faMessageLinesRegular,
    faCalendarExclamation, faLessThan, faGreaterThan, faBuilding as faBuildingRegular, faEnvelope, faComputer, faCalendar
} from "@fortawesome/pro-regular-svg-icons";
import {
    faPause, faPlay, faStar, faShareAll, faMagic, faCheckCircle, faTimesCircle, faMinusCircle, faPlusCircle, faRedo,
    faBuilding, faUser, faUsers, faCog, faAngleUp, faAngleDown, faSlash, faCalendarStar, faCaretCircleRight, faInfoCircle,
    faExclamationTriangle, faCalendarTimes as faCalendarTimesSolid, faChevronLeft as faChevronLeftSolid, faChevronRight as faChevronRightSolid,
    faPersonRunning, faMoonStars, faBell, faHand, faMessageLines, faFolder, faSparkles, faCaretDown, faMagnifyingGlass, faGripDotsVertical, faMicrochip, faGear,
    faDownToBracket, faPuzzlePieceSimple, faCircleQuestion as faCircleQuestionSolid
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CalenderPlay from '../../../images/calendar-play.svg';
import context from '../../../images/context.svg';

const IconTooltip = withStyles({
    tooltip: {
        background:   "var(--black-1)",
        fontSize:     12,
        borderRadius: 4,
        fontFamily:   "gotham-book, sans-serif",
        maxWidth:     300,
        border:       "1px solid var(--white)"
    },

    arrow: {
        color: "var(--black-1)"
    }
})( Tooltip );

const useStyles = makeStyles({
    root: ({
        "&::after": {
            content: "/"
        }
    }),
    relative: {
        position: "relative"
    },
    slashedCss: {
        position: "absolute",
        left:     0,
        right:    0,
        bottom:   0,
        top:      0
    }
});

const iconMap = {
    "close":                    faTimes,
    "add":                      faPlus,
    "delete":                   faTrashAlt,
    "star":                     faStar,
    "play":                     faPlay,
    "pause":                    faPause,
    "clock":                    faStopwatch,
    "paperclip":                faPaperclip,
    "eye":                      faEye,
    "no-eye":                   faEyeSlash,
    "document":                 faFileAlt,
    "photo-document":           faFileImage,
    "calendar":                 faCalendar,
    "calendar-star":            faCalendarStar,
    "calendar-tick":            faCalendarCheck,
    "calendar-cross":           faCalendarTimes,
    "calendar-cross-solid":     faCalendarTimesSolid,
    "expand":                   faExpandAlt,
    "info":                     faInfoCircle,
    "chevron-down":             faChevronDown,
    "chevron-up":               faChevronUp,
    "chevron-right":            faChevronRight,
    "chevron-left-solid":       faChevronLeftSolid,
    "chevron-right-solid":      faChevronRightSolid,
    "submit":                   faShareAll,
    "magic":                    faMagic,
    "user":                     faUser,
    "arrow-left":               faArrowLeft,
    "arrow-right":              faArrowRight,
    "check-circle":             faCheckCircle,
    "times-circle":             faTimesCircle,
    "minus-circle":             faMinusCircle,
    "plus-circle":              faPlusCircle,
    "redo":                     faRedo,
    "sync":                     faSync,
    "building":                 faBuilding,
    "users":                    faUsers,
    "cog":                      faCog,
    "angle-left":               faAngleLeft,
    "angle-right":              faAngleRight,
    "angle-up":                 faAngleUp,
    "angle-down":               faAngleDown,
    "edit":                     faEdit,
    "credit-card":              faCreditCard,
    "slash":                    faSlash,
    "arrow-circle-right":       faArrowCircleRight,
    "caret-circle-right":       faCaretCircleRight,
    "warning":                  faExclamationTriangle,
    "warning-circle":           faExclamationCircle,
    "snooze":                   faSnooze,
    "person-running":           faPersonRunning,
    "question":                 faCircleQuestion,
    "keyboard":                 faKeyboard,
    "gift":                     faGift,
    "moon-stars":               faMoonStars,
    "bell":                     faBell,
    "xmark":                    faXmark,
    "hand":                     faHand,
    "message-lines":            faMessageLines,
    "message-lines-regular":    faMessageLinesRegular,
    "calendarExclamation":      faCalendarExclamation,
    "less-than":                faLessThan,
    "greater-than":             faGreaterThan,
    "folder":                   faFolder,
    "lightBuilding":            faBuildingRegular,
    "sparkles":                 faSparkles,
    "caretDown":                faCaretDown,
    "magnifyingGlass":          faMagnifyingGlass,
    "envelope":                 faEnvelope,
    "computer":                 faComputer,
    "gripDotsVertical":         faGripDotsVertical,
    "microchip":                faMicrochip,
    "gear":                     faGear,
    "download":                 faDownToBracket,
    "puzzlePieceSimple":        faPuzzlePieceSimple,
    "question-circle":          faCircleQuestionSolid
};

const imgMap = {
    "context":      context,
    "calenderPlay": CalenderPlay
};

export const LicoIcon = props => {
    const { icon, size = "1x", className = '', style = {}, onClick, slashed, tooltip, tooltipPlacement = "right-start", dataName, tooltipDelay, slashPositionFix = 0 } = props;
    const classes = useStyles( props );


    const control = (
        iconMap[ icon ] ? ( <span onClick={onClick}  className={classes.relative} data-ux={dataName}  >
            <FontAwesomeIcon icon={iconMap[ icon ]} size={size} className={`${classes.root} ${className}`} style={style} />
            {
                slashed && 
                    <span className={classes.slashedCss} style={{ left: slashPositionFix }} >
                        <FontAwesomeIcon icon={faSlash} size={size} className={`${classes.root} ${className}  fa-layers fa-fw`} style={style} />
                    </span>
            }
        </span> ) : imgMap[ icon ] ? (
            <imgx
                src={imgMap[ icon ]}
                alt={icon}
                className={`${classes.root} ${className}`}
                style={style}
            />
        ) : null
    );
    if ( !control ) {
        console.error( `Icon '${icon}' not found in iconMap or imgMap` );
        return null;
    }

    return tooltip
        ? <IconTooltip title={tooltip} placement={tooltipPlacement} enterNextDelay={tooltipDelay}>
            {control}
        </IconTooltip>
        : control;
};

LicoIcon.propTypes = {
    icon:               PropTypes.string.isRequired,
    size:               PropTypes.string,
    className:          PropTypes.string,
    style:              PropTypes.object,
    onClick:            PropTypes.func,
    slashed:            PropTypes.bool,
    slashPositionFix:   PropTypes.number,
    tooltip:            PropTypes.any,
    tooltipPlacement:   PropTypes.string,
    dataName:           PropTypes.string,
    tooltipDelay:       PropTypes.number
};
