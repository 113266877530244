/*********************************************************************************************************************
 * @file MFA actions, reducers, utilies, etc
 * @abstract The MFA setup process takes place at first login if organisation mandated, or when selected from user profile.
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Nov-2020
 *********************************************************************************************************************/

import { uri, POST, GET } from '../constants.js';
import { setLoginError, setQrCodeUrl, setLoginMfaTransition,
    setUserProfileMfaError, setUserQrCodeUrl, setUserMfaTransition,
    setLoginVerified, setLoginMfaVerified, setUserVerified, setUserMfaVerified } from '../redux/actions/index.js';
import { abstractedCreateAuthRequest, abstractedCreateRequest } from '../services/util/baseRequests.js';

import { INCORRECT_MFA } from "./common-types.js";

const MfaStates = {
    NONE:         'none',
    CONFIRM:      'confirm',
    EXPLAIN:      'explain',
    RECOVERY:     'recovery',
    SETUP:        'setup',
    USE:          'use'
};

const nextMfaState = {
    [ MfaStates.CONFIRM ]:      MfaStates.EXPLAIN,
    [ MfaStates.EXPLAIN ]:      MfaStates.SETUP,
    [ MfaStates.RECOVERY ]:     MfaStates.SETUP,
    [ MfaStates.SETUP ]:        MfaStates.USE
};

const _asyncQrCodeUrl = abstractedCreateAuthRequest( GET, uri.QRCODEURL );
const _asyncVerify = abstractedCreateRequest( POST, uri.VERIFY );

const verifySecret = ( userMfaSetup, secretObj ) => async ( dispatch, getState ) => {

    const [ action, setError ] = userMfaSetup
        ? secretObj.password ? [ setUserVerified, setUserProfileMfaError ] : [ setUserMfaVerified, setUserProfileMfaError ]
        : secretObj.password ? [ setLoginVerified, setLoginError ] : [ setLoginMfaVerified, setLoginError ];

    dispatch( setError( undefined ) );

    const { email } = getState().login;

    _asyncVerify({ email, ...secretObj, userMfaSetup }, null, [])
        .then( result => dispatch( result.payload.verified ? action( secretObj ) : setError( INCORRECT_MFA ) ) );

};

const verifyCreds = ( userMfaSetup, password ) => verifySecret( userMfaSetup, { password });
const verifyMfa = ( userMfaSetup, mfa ) => verifySecret( userMfaSetup, { mfa });

const getQrCodeUrl = userMfaSetup => async ( dispatch, getState ) => {

    const [ qrCodeUrlAction, setError ] = userMfaSetup ? [ setUserQrCodeUrl, setUserProfileMfaError ] : [ setQrCodeUrl, setLoginError ];

    dispatch( setError( undefined ) );

    // we're still getting email address from login state, whether we're doing login or user profile
    const { email } = getState().login;

    _asyncQrCodeUrl({}, null, [ email ])
        .then( result => dispatch( qrCodeUrlAction( result.payload ) ) );
};


const setMfaTransition = ( userMfaSetup, newState ) => async ( dispatch ) => {

    const transitionAction = userMfaSetup ? setUserMfaTransition : setLoginMfaTransition;

    dispatch( transitionAction( newState ) );
};

const setVerifiedReducer = draft => {
    draft.verified = true;

    // if we've confirmed the password at start of MFA setup, move to next screen
    if ( draft.mfaState === MfaStates.CONFIRM )
        draft.mfaState = nextMfaState[ draft.mfaState ];
};

const setMfaVerifiedReducer = ( draft, payload ) => {
    draft.mfaVerified = true;
    draft.qrcode = payload.mfa;
};

const setMfaTransitionReducer = ( draft, payload ) => {
    draft.mfaState = payload;
    if ( payload === MfaStates.CONFIRM )
        draft.preMfaChanges = draft.foundChanges;
};

const setCredsReducer = ( draft, creds ) => Object.assign( draft, creds );

const setMfaBackReducer = draft => {
    draft.error = '';

    // most states just go back to the initial login screen but SETUP goes back to RECOVERY
    // Well, it will do once recovery has real content; commented out for now.
    // if ( draft.mfaState === MfaStates.SETUP )
    //     draft.mfaState = MfaStates.RECOVERY;
    // else {

    // back to start where we could come in as a different user, so clear everything
    draft.email = '';
    draft.password = '';
    draft.qrcode = '';
    draft.verified = false;
    draft.mfaVerified = false;
    draft.mfaState = MfaStates.NONE;
    draft.settings = null;
    // }
};

export {
    MfaStates,
    nextMfaState,
    setVerifiedReducer,
    setMfaVerifiedReducer,
    getQrCodeUrl,
    setMfaTransition,
    setMfaTransitionReducer,
    verifyCreds,
    verifyMfa,
    setCredsReducer,
    setMfaBackReducer
};
