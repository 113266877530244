import produce from 'immer';
import { combineReducers } from "redux";


import calendarEvent from '../components/busycard/reducer.js';
import calendar from '../components/calendar/reducer.js';
import scheduling from '../components/clientSchedule/reducer.js';
import userDetails from '../components/common/userDetails';
import forgotPassword from '../components/ForgotPassword/reducer.js';
import invitation from '../components/invitation/reducer.js';
import jobcard from '../components/jobcard/reducer.js';
import login from '../components/login/reducer.js';
import notification from '../components/notification/reducer.js';
import timelog from '../components/pegboard/reducer.js';
import registration from '../components/registration/reducer.js';
import searchPanel from '../components/searchPanel/reducer.js';
import client from '../components/settings/clients/reducer.js';
import engineer from '../components/settings/engineer/reducer.js';
import integration from '../components/settings/integration/reducer.js';
import journal from '../components/settings/journal/reducer.js';
import organisation from '../components/settings/organisation/reducer.js';
import team from '../components/settings/team/reducer.js';
import userProfile from '../components/settings/user/reducer.js';
import { closeSocket } from '../sockets/initialize-socket.js';
import { removeAuth } from "../utils/local-storage.js";

import { setAuth, USER_LOGOUT } from './actions/index.js';
import auth from './reducers/auth.js';
import cache from './reducers/cache.js';
import company from './reducers/company.js';
import error from './reducers/error.js';
import job from './reducers/job.js';
import meta from './reducers/meta.js';
import typeAhead from './reducers/typeAhead.js';
import user from './reducers/user.js';

/**
 * @typedef {object} BaseState
 * @property {{ [string]: boolean }} loading
 * @property {any} app
 * @property {any} integration
 * @property {any} scheduling
 * @property {any} registration
 * @property {AuthState} auth
 * @property {any} login
 * @property {any} notification
 * @property {any} engineer
 * @property {UserState} user
 * @property {CompanyState} company
 * @property {ErrorState} error
 * @property {MetaState} meta
 * @property {TypeAheadState} typeAhead
 * @property {TimeLogState} timelog
 * @property {TicketState} ticket
 * @property {JobState} job
 * @property {any} jobcard
 * @property {any} calendar
 * @property {BusyCardState} busyCard
 * @property {UserDetailState} userDetails
 * @property {OrganisationState} organisation
 * @property {UserProfileState} userProfile
 * @property {PasswordResetState} forgotPassword
 * @property {AcceptInvitationState} invitation
 * @property {CacheState} cache
 * @property {TeamState} team
 */

const loadingReducer = produce( ( draft, { type, payload }) => {
    const [ , requestState ] = /^LOADING\/(.*)$/.exec( type ) || [];

    if ( !requestState ) return draft;

    draft[ payload ] = requestState === 'GET';

    return draft;
}, {});



const appReducers = combineReducers({
    auth,
    cache,
    calendar,
    calendarEvent,
    company,
    engineer,
    error,
    forgotPassword,
    integration,
    invitation,
    job,
    jobcard,
    journal,
    loading:        loadingReducer,
    login,
    meta,
    notification,
    organisation,
    registration,
    scheduling,
    team,
    searchPanel,
    timelog,
    typeAhead,
    user,
    userDetails,
    userProfile,
    client
});

const rootReducer = ( state, action ) => {
    if ( action.type === USER_LOGOUT ) {
        removeAuth();
        setAuth({ rememberSession: false, token: '', userInitState: {} });
        state = undefined;
        closeSocket();

        // window.location.reload();
    }

    return appReducers( state, action );
};

export default rootReducer;
