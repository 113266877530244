import React, { useEffect } from 'react';


import { INVALID, TokenStatus } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';

import { uri } from '../../constants.js';
import { setFormValueForgotPassword, setTouchedForgotPassword } from '../../redux/actions/index.js';
import { createSingleLineErrorSelector, selectIsFetchResetTokenLoading, selectResetToken, selectPasswordReset, selectorInitMeta } from '../../redux/selectors/index.js';
import { error, buttonPanel, subdomainText } from '../../scss/ResetPassword.module.scss';
import { __ } from '../../utils/i18n.jsx';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { P, LicoButton, Loader, LicoLink } from '../common/index.js';
import PasswordField from '../common/PasswordField.jsx';
import PreAuthLayout from '../layouts/PreAuthLayout.jsx';
import { LOGIN, FORGOT_PASSWORD } from '../navigation/routes.js';

import { validateResetToken, resetPassword } from './reducer.js';

function useQuery() {
    return new URLSearchParams( useLocation().search );
}

const validateResetTokenErrorSelector = createSingleLineErrorSelector( uri.USER_TOKEN_VALIDATION );
const resetPasswordErrorSelector = createSingleLineErrorSelector( uri.USER_RESET_PASSWORD );

const ResetPasswordPage = ({ password, strength, fieldError, fieldTouched, setFormValueForgotPassword, setTouchedForgotPassword, validateResetToken, resetPassword }) => {

    const queryParams = useQuery();
    const tokenCode = queryParams.get( "token" ) || "";
    const meta = useSelector( selectorInitMeta );
    const isCheckLoading = useSelector( selectIsFetchResetTokenLoading );
    const resetToken = useSelector( selectResetToken );
    const passwordReset = useSelector( selectPasswordReset );

    useEffect( () => {
        ( async () => {
            await validateResetToken({ token: tokenCode });
        })();
    }, [ validateResetToken, tokenCode ]);


    const validateResetTokenError = useSelector( validateResetTokenErrorSelector );
    const resetPasswordError = useSelector( resetPasswordErrorSelector );

    if ( !tokenCode ) return <Redirect to={FORGOT_PASSWORD} />;

    if ( passwordReset ) return <Redirect to={LOGIN} />;

    const statusError = {
        [ TokenStatus.disabled ]:  __( "This code has been cancelled" ),
        [ TokenStatus.used ]:      __( "This code has been used already" ),
        [ TokenStatus.expired ]:   __( "This code has expired" ),
        [ TokenStatus.obsolete ]:  __( "This code has been replaced by a more recent one" ),
        'missing':                   __( "This is not a valid Licorice reset code" ),
        'inactive':                  __( "This code is for an inactive user" )
    };

    const errorMessage = resetToken?.status === INVALID
        ? statusError[ resetToken.reason ] || __( "Unknown problem with token" )
        : validateResetTokenError || resetPasswordError || undefined;

    return <PreAuthLayout>
        <BannerLogo />
        {meta.domain && <P align="center" className={subdomainText}> {meta.domain}</P>}

        {( !resetToken || isCheckLoading )
            ? <Loader />
            : <>
                {errorMessage
                    ? <P className={error}>{errorMessage}. You can send a new code <LicoLink to={FORGOT_PASSWORD} >here</LicoLink>.</P>
                    : <div>
                        <PasswordField
                            password={password}
                            strength={strength}
                            fieldTouched={fieldTouched}
                            fieldError={fieldError}
                            setFormValue={setFormValueForgotPassword}
                            setTouched={setTouchedForgotPassword}
                        />
                        <div className={buttonPanel} >
                            <LicoButton onClick={() => {
                                if ( strength.score[ 0 ] === 4 ) 
                                    resetPassword({ token: tokenCode, password: password });
                            }}>
                                {__( "Reset Password" )}
                            </LicoButton>
                        </div>
                    </div>
                }
            </>
        }
    </PreAuthLayout>;
};
ResetPasswordPage.propTypes = {
    password:                   PropTypes.string,
    strength:                   PropTypes.object,
    setFormValueForgotPassword: PropTypes.func.isRequired,
    validateResetToken:         PropTypes.func.isRequired,
    resetPassword:              PropTypes.func.isRequired,
    fieldError:                 PropTypes.object,
    fieldTouched:               PropTypes.object,
    setTouchedForgotPassword:   PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const { password, strength, fieldError, fieldTouched } = state.forgotPassword;

    return { password, strength, fieldError, fieldTouched };
};

const mapDispatchToProps = {
    validateResetToken,
    resetPassword,
    setFormValueForgotPassword,
    setTouchedForgotPassword
};

export default connect( mapStateToProps, mapDispatchToProps )( ResetPasswordPage );
export { ResetPasswordPage };
