/********************************************************************************************************************
 * @file Actions for the userProfile store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setUserProfileMfaError = createAction( 'USER_PROFILE.ERROR/SET' );
export const setUserQrCode = createAction( 'USER_PROFILE.MFA.QRCODE/SET' );
export const setUserQrCodeUrl = createAction( 'USER_PROFILE.MFA.QRCODE.URL/SET' );
export const setUserMfaTransition = createAction( 'USER_PROFILE.MFA.TRANSITION/SET' );
export const setUserVerified = createAction( 'USER_PROFILE.PWD.VERIFIED/SET' );
export const setUserMfaVerified = createAction( 'USER_PROFILE.MFA.VERIFIED/SET' );

export const setMfaBackUserProfile = createAction( 'USER_PROFILE.MFA.BACK/SET' );
export const setUserProfileEmailError = createAction( 'USER_PROFILE.EMAIL.ERROR/SET' );
export const setUserProfileNameError = createAction( 'USER_PROFILE.NAME.ERROR/SET' );
export const setSettingValueUserProfile = createAction( 'USER_PROFILE.SETTING/SET' );
export const discardUserSettingChanges = createAction( 'USER_PROFILE.DISCARD_CHANGES/SET' );
export const setUserProfile = createAction( 'USER_PROFILE.USER/SET' );
export const setAddUserBusinessHours = createAction( 'USER_PROFILE.ADD_BUSINESS_HOURS/SET' );
export const setRemoveUserBusinessHours = createAction( 'USER_PROFILE.REMOVE_BUSINESS_HOURS/SET' );

