/** ******************************************************************************************************************
 * @file Describe what type-helpers does.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 10-Jan-2021
 *********************************************************************************************************************/

import kindOf from 'kind-of';

const rxUUID = /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}/i;
const rxAny = /^.+$/;

/**
 * @param {any} p
 * @return {boolean}
 */
const isPromise = p => kindOf( p ) === 'promise';

/**
 * @param {any} m
 * @return {boolean}
 */
const isSet    = m => kindOf( m ) === 'set';

/**
 * @param {any} m
 * @return {boolean}
 */
const isMap    = m => kindOf( m ) === 'map';

/**
 * @param {any} s
 * @return {boolean}
 */
const isString = s => kindOf( s ) === 'string';

/**
 * @param {any} s
 * @return {boolean}
 */
const isObject = s => kindOf( s ) === 'object';

/**
 * @param {any} o
 * @return {boolean}
 */
const isBasicObject = o => typeof o === 'object' && !Array.isArray( o ) && o !== null;

/**
 * @param {any} s
 * @return {boolean}
 */
const isFunc   = s => kindOf( s ) === 'function';

/**
 * @param {any} n
 * @return {boolean}
 */
const isNumber = n => kindOf( n ) === 'number';

/**
 * @param {any} r
 * @return {boolean}
 */
const isRx = r => kindOf( r ) === 'regexp';

/**
 * @param {any} n
 * @return {boolean}
 */
const isInt    = n => kindOf( n ) === 'number' && Number.isInteger( n );

const isTypedArray = o => isBasicObject( o ) && Object.getPrototypeOf( o.constructor )?.name === 'TypedArray';

/**
 * @param {[]|object|string} x
 * @param {boolean} [nullish=true]
 * @return {boolean}
 */
const isEmpty = ( x, nullish = true ) => x == null || ( Array.isArray( x ) && x.length === 0 ) || ( isObject( x ) && emptyObject( x, nullish ) ) || ( isString( x ) && x === "" );

/**
 * @param {[]|object|string} x
 * @return {boolean}
 */
const isReallyEmpty = x => isEmpty( x, false );

const emptyObject = ( o, nullish = false ) => {
    const keys = Object.keys( o );
    if ( keys.length === 0 ) return true;

    return keys.every( k => nullish ? o[ k ] == null : o[ k ] === void 0 );
};

/**
 * @param {any} o
 * @return {boolean}
 */
const isIterable = o => Symbol.iterator in Object( o );

/**
 * @param {any} o
 * @return {boolean}
 */
const isSpreadable = o => Symbol.iterator in Object( o ) || isObject( o );

/**
 * @param {object} o
 * @param {string|symbol} k
 * @return {boolean}
 */
const has      = ( o, k ) => isObject( o ) && Object.hasOwn( o, k );

const isBoolean = b => b === true || b === false;

const isUUID = s => isString( s ) && rxUUID.test( s );
const hasString = s => isString( s ) && s.length > 0;
const hasStringValue = ( o, k, type, mustHaveLength = true ) => has( o, k ) && kindOf( o[ k ]) === type && ( mustHaveLength !== true || ( o[ k ].length || o[ k ].size ) > 0 );

const isPrimitive = v => v == null || ( typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean' || typeof v === 'bigint' || typeof v === 'symbol' );

export {
    isPrimitive,
    hasString,
    hasStringValue,
    isUUID,
    isBoolean,
    has,
    isIterable,
    isEmpty,
    isReallyEmpty,
    isNumber,
    isObject,
    isSet,
    isPromise,
    isMap,
    isInt,
    isBasicObject,
    isFunc,
    isString,
    isRx,
    isSpreadable,
    isTypedArray,
    rxUUID,
    rxAny
};
