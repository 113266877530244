/** ******************************************************************************************************************
 * @file Describe what array-helpers does.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 10-Jan-2021
 *********************************************************************************************************************/
import { rand } from './number-helpers.js';
import { isPrimitive, isString } from './type-helpers.js';

/**
 * @param {array|any} a
 * @return {boolean}
 */
const hasValues = a => Array.isArray( a ) && a.length > 0;

/**
 * @param {array|any} a
 * @return {boolean}
 */
const hasAny    = a => Array.isArray( a ) && a.length > 0;

/**
 * @param {array|any} a
 * @return {boolean}
 */
const hasOne    = a => Array.isArray( a ) && a.length === 1;

/**
 * @param {array|any} a
 * @return {boolean}
 */
const hasMany   = a => Array.isArray( a ) && a.length > 1;

/**
 * @template T
 * @param {T[]|any} a
 * @return {T|undefined}
 */
const hasTruthy = a => hasAny( a ) && a[ 0 ] ? a[ 0 ] : void 0;

/**
 * @template T
 * @param {T[]} x
 * @param {function} fn
 * @return {T|undefined}
 */
const hasA      = ( x, fn ) => hasAny( x ) && fn( x[ 0 ]) ? x[ 0 ] : void 0;

/**
 * @template T
 * @param {T[]} x
 * @return {T|undefined}
 */
const first     = x => hasAny( x ) ? x[ 0 ] : void 0;

/**
 * @template T
 * @param {T[]} a
 * @param {number} n
 * @return {T|undefined}
 */
const last = ( a, n = -1 ) => Array.isArray( a ) && a.length > ~n ? a[ a.length + n ] : void 0;

/**
 * @template T
 * @param {T|T[]|undefined} x
 * @return {T|undefined}
 */
const unpack    = x => hasAny( x ) ? x[ 0 ] : Array.isArray( x ) ? void 0 : x;

/**
 * @template {any} T
 * @param {T} a
 * @return {T[]}
 */
const asArray   = a => Array.isArray( a ) ? a : a == null ? [] : [ a ];

/**
 * @template T
 * @param {T[]|T|any} a
 * @return {T}
 */
const asOne = a => hasOne( a ) ? a[ 0 ] : a;

/**
 * @param {[]|Map|Set} iter
 * @return {*}
 */
const getOne     = iter => iter == null ? void 0 : Array.isArray( iter ) ? ( iter.length > 0 ? iter[ 0 ] : void 0 ) : iter[ Symbol.iterator ]?.().next().value ?? void 0;

/**
 * @param rest
 * @return {*}
 */
const restToArray = rest => hasOne( rest ) && Array.isArray( rest[ 0 ]) ? rest[ 0 ] : rest;

/**
 * @param {any} a
 * @param {any} b
 * @return {any[]}
 */
const concat = ( a, b ) => asArray( a ).concat( asArray( b ) );

const cons = arr => {
    if ( !Array.isArray( arr ) ) return null;

    if ( arr.length === 1 ) return [ arr[ 0 ], [] ];

    return [ arr[ 0 ], arr.slice( 1 ) ];
};

const rest = arr => Array.isArray( arr ) ? arr.slice( 1 ) : null;

/**
 * This function takes in a variable number of arguments and normalizes them.
 *
 * @param {...any} args - The arguments to be normalized.
 * @returns {Array} - The normalized arguments.
 */
const normalizeArgs = ( ...args ) => args.length === 1 && Array.isArray( args[ 0 ]) ? args[ 0 ] : args;

/**
 * @param {object} obj
 * @param {string|string[]} path
 * @param {any} [def]
 * @return {*}
 */
const objectAt = ( obj, path, def ) =>
    ( Array.isArray( path ) ? path : path.replace( /\[\s*(\w+)\s*]/g, '.$1.' ).split( /\.+/ ) ).filter( Boolean ).reduce( ( o, key ) => o?.[ key ], obj ) ?? def;

const padArray = ( main, aspirant ) => aspirant.length < main.length ? main.map( ( _, i ) => i < aspirant.length ? aspirant[ i ] : null ) : aspirant;

const randomElement = ( list, seed = list.length ) => {
    let max = list.length;

    if ( max === 0 ) return;
    else if ( max === 1 ) return list[ 0 ];

    if ( isString( seed ) )
    {
        const sum = [ ...seed ].reduce( ( sum, ch ) => sum + ch.codePointAt( 0 ), 0 );
        return list[ sum % list.length ];
    }

    return list[ rand( list.length ) ];
};

// Fisher–Yates shuffle
function randomArray( arr )
{
    const a = Array.from( arr );
    // Start from the last element and swap one by one. We don't need to run for the first element, so `i > 0`.
    for ( let i = a.length - 1; i > 0; i-- )
    {
        let j = ( Math.random() * ( i + 1 ) ) | 0;
        [ a[ i ], a[ j ] ] = [ a[ j ], a[ i ] ];
    }

    return a;
}

function uniq( arr )
{
    if ( !Array.isArray( arr ) ) return arr;
    if (  arr.length < 2 ) return arr;

    if ( arr.length > 200 )
        return Array.from( new Set( arr ) );

    const result = [];
    const length = arr.length;
    let index = -1;

    while ( ++index < length )
    {
        if ( !result.includes( arr[ index ]) )
            result.push( arr[ index ]);
    }

    return result;
}

/**
 * Create lists of differences between two lists. The result will list elements only in the left
 * list, items only in the right list, and items that are in both but are different.
 *
 * You can provide a custom order function and a custom equality function. The order function is used
 * to determine the order of the elements in the lists. The equality function is used to determine
 * if two elements are equal. If no order function is provided, the elements will not be sorted as part
 * of the comparison. The presence of the order function implies element cardinality, in general, and
 * inequality when the order function returns anything other than `0`.
 *
 * The `equal` function will be used to compare two objects. It should not compare the field that implies
 * cardinality if the `order` function is provided.
 *
 * Normally, the cardinality check will be a comparison between two elements using some form of `id` property.
 * Thus, it uses such a property to determine the element order. If the order between two objects is not `0`
 * then the objects will be considered different. If the `order` funciton returns `0` then the two elements will
 * be considered the same object and they can be compared for similarity using the `equal` function.
 *
 * @param {T[]} a
 * @param {T[]} b
 * @param {( a: T, b: T ) => number} order   - -1 if a < b, 0 if a == b, 1 if a > b
 * @param {(a: T, b: T) => boolean} equal    - True if a === b
 * @return {{leftOnly: T[], rightOnly: T[], different: [ T, T ][]}}
 */
function listDiff( a, b, order = null, equal = null )
{
    const result = {
        leftOnly:  [],
        rightOnly: [],
        different: []
    };

    for ( const [ left, right ] of genListDiff( a, b, order, equal ) )
    {
        if ( left && !right )
            result.leftOnly.push( left );
        else if ( right && !left )
            result.rightOnly.push( right );
        else
            result.different.push([ left, right ]);
    }

    return result;
}

function *genListDiff( a, b, order = null, equal = null )
{
    console.assert( Array.isArray( a ), 'a is not an array' );
    console.assert( Array.isArray( b ), 'b is not an array' );

    const _order = ( a, b ) => typeof a === 'number' ? a - b : typeof a === 'string' ? a.localeCompare( b ) : 0;

    equal ??= ( a, b ) => isPrimitive( a ) ? a === b : true;

    if ( !order && ( ( a.length > 0 && isPrimitive( a[ 0 ]) ) || ( b.length > 0 && isPrimitive( b[ 0 ]) ) ) )
        order = _order;

    if ( order )
    {
        a = a.toSorted( order );
        b = b.toSorted( order );
    }
    else
        order = _order;

    let rindex = 0;
    let lindex = 0;

    while ( true )
    {
        const lhs = lindex < a.length ? a[ lindex ] : null;
        const rhs = rindex < b.length ? b[ rindex ] : null;

        if ( !lhs && !rhs ) break;

        // End of one of the two arrays, so dump the rest of the other array and quit.
        if ( !lhs )
        {
            for ( const item of b.slice( rindex ) ) yield [ , item ];
            break;
        }
        else if ( !rhs )
        {
            for ( const item of a.slice( rindex ) ) yield [ item ];
            break;
        }

        // If the cardinality of the two current items is different, we can assume that they are different.
        // If the left hand side is smaller, we treat it as deleted so emit that on the left.
        if ( order( lhs, rhs ) < 0 )
        {
            yield [ lhs ];
            ++lindex;
        }
        // Conversely, if the right hand side is smaller, we treat it as inserted so emit that on the right.
        else if ( order( lhs, rhs ) > 0 )
        {
            yield [ , rhs ];
            ++rindex;
        }
        // Here we have the same cardinality, so we can compare the two items. If they are different, we
        // emit them both.
        else
        {
            if ( !equal( lhs, rhs ) )
                yield [ lhs, rhs ];

            ++lindex;
            ++rindex;
        }
    }
}

export {
    uniq,
    padArray,
    objectAt,
    rest,
    cons,
    concat,
    getOne,
    hasOne,
    hasValues,
    hasAny,
    hasMany,
    hasTruthy,
    hasA,
    first,
    last,
    unpack,
    asArray,
    asOne,
    restToArray,
    randomElement,
    randomArray,
    normalizeArgs,
    listDiff,
    genListDiff
};

// const list = [
//     { id: 1, name: 'name1' },
//     { id: 2, name: 'name2' },
//     { id: 3, name: 'name3' },
//     { id: 4, name: 'name4' },
//     { id: 5, name: 'name5' },
//     { id: 6, name: 'name6' },
//     { id: 7, name: 'name7' }
// ];
//
// const listN = [ 1, 2, 3, 4, 5, 6, 7, 8 ];
//
// function test( msg, change, arr = list )
// {
//     let listA = arr.map( x => ({ ...x }) );
//     let listB = listA.map( x => ({ ...x }) );
//
//     [ listA, listB ] = change( listA, listB );
//
//     console.log( `${msg}:` );
//     console.log( listDiff( listA, listB, ( a, b ) => a.id - b.id, ( a, b ) => a.name === b.name ) );
// }
//
// function testNum( msg, change, arr = list )
// {
//     let listA = Array.from( arr );
//     let listB = Array.from( listA );
//
//     [ listA, listB ] = change( listA, listB );
//
//     console.log( `${msg}:` );
//     console.log( listDiff( listA, listB ) );
// }
//
// test( 'No change', ( a, b ) => [ a, b ]);
// test( 'Missing #1 in left', ( a, b ) => { a.splice( 1, 1 ); return [ a, b ]; });
// test( 'Different name on right at #1', ( a, b ) => { b[ 1 ].name = 'name8'; return [ a, b ]; });
//
// test( `empty`, ( a, b ) => [ a, b ], []);
// test( `empty left`, ( a, b ) => [ [], b ]);
// test( `empty right`, ( a, b ) => [ a, [] ]);
//
// testNum( `number same`,  ( a, b ) => [ a, b ], listN );
// testNum( `number same reordered`,  ( a, b ) => [ a.slice( 1 ).concat( a[ 0 ]), b ], listN );
// testNum( `number missing 5 left`,  ( a, b ) => [ a.filter( n => n !== 5 ), b ], listN );
// testNum( `number missing 6 right`,  ( a, b ) => [ a, b.filter( n => n !== 6 ) ], listN );
// testNum( `number missing both`,  ( a, b ) => [ a.filter( n => n !== 5 ), b.filter( n => n !== 6 ) ], listN );
//
//
// const leftItems = [
//     {
//         id:                486,
//         type:              { id: 11 },
//         value:             'julian.modified@licorice.dev',
//         defaultFlag:       false,
//         communicationType: 'Email',
//         domain:            '@licorice.dev'
//     },
//     {
//         id:                527,
//         type:              { id: 2 },
//         value:             '15551234269',
//         defaultFlag:       true,
//         communicationType: 'Phone'
//     },
//     {
//         id:                528,
//         type:              { id: 1 },
//         value:             'julian.edited5@licorice.dev',
//         defaultFlag:       true,
//         communicationType: 'Email',
//         domain:            '@licorice.dev'
//     }
// ];
//
// const rightItems =
// [
//     {
//         id:   486,
//         type: {
//             id:    11,
//             name:  'Private Email',
//             _info: {
//                 type_href: 'https://api-staging.connectwisedev.com/v4_6_release/apis/3.0//company/communicationTypes/11'
//             }
//         },
//         value:             'julian.modified@licorice.dev',
//         defaultFlag:       false,
//         domain:            '@licorice.dev',
//         communicationType: 'Email'
//     },
//     {
//         id:   527,
//         type: {
//             id:    2,
//             name:  'Direct',
//             _info: {
//                 type_href: 'https://api-staging.connectwisedev.com/v4_6_release/apis/3.0//company/communicationTypes/2'
//             }
//         },
//         value:             '15551234567',
//         defaultFlag:       true,
//         communicationType: 'Phone'
//     },
//     {
//         id:   528,
//         type: {
//             id:    1,
//             name:  'Email',
//             _info: {
//                 type_href: 'https://api-staging.connectwisedev.com/v4_6_release/apis/3.0//company/communicationTypes/1'
//             }
//         },
//         value:             'julian.edited5@licorice.dev',
//         defaultFlag:       true,
//         domain:            '@licorice.dev',
//         communicationType: 'Email'
//     }
// ];
//
// const order = ( a, b ) => a.id - b.id;
// const equal = ( a, b ) => a.type.id === b.type.id &&
//                           a.value === b.value &&
//                           a.extension === b.extension ||
//                           a.defaultFlag !== b.defaultFlag ||
//                           a.domain !== b.domain ||
//                           a.communicationType !== b.communicationType;
//
// const result = listDiff( leftItems, rightItems, order, equal );
// console.log( `result:`,  result );
