import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField } from "@mui/material";
import Popper from "@mui/material/Popper";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setSelectedAsset } from "../../../redux/actions/index.js";
import { 
    getTypeAheadControlState,
    setTypeAheadFilter,
    TypeAheadControl
} from "../../../redux/reducers/typeAhead.js";
import { selectJobAssets } from "../../../redux/selectors/index.js";
import * as scss from "../../../scss/AssetsAutoComplete.scss";
import { LicoIcon } from "../../common/index.js";
import { updateAssetToJob } from "../reducer.js";

import CreateAssetForm from "./CreateAssetForm.jsx";

const { roundInputRoot, roundInputBox } = scss;
const DEVICETYPE = 'Device';
const MICROCHIP = 'microchip';
const GEAR = 'gear';
const AssetAutoComplete =  props  => {
    const { anchorEl, allAssets, setSelectedAsset, updateAssetToJob, setTypeAheadFilter, clientId, jobId } = props;

    useEffect( () => {
        setTypeAheadFilter({
            name:      TypeAheadControl.assets,
            filter:    '',
            extraData: {
                companyId: clientId,
                jobId
            }
        }); 
    }, [ clientId, setTypeAheadFilter ]);

    const [ newAsset, setNewAsset ] = useState( false ); // this is for the add new device option

    const onAutocompleteChange = ( e, value ) => {
        updateAssetToJob( value );
    };

    const onInputChange = ( event, value ) => {
        setTypeAheadFilter({
            name:      TypeAheadControl.assets,
            filter:    value,
            extraData: {
                companyId: clientId,
                jobId
            }
        });
    };

    const ADD_ITEM_ID = "addAsset";

    const realOptions =  [
        ...( allAssets || [])
        // commenting below code for now as we are not adding new asset to the job card yet.
        // {
        //     assetId:   ADD_ITEM_ID,
        //     type:      ADD_ITEM_ID,
        //     assetName: addLabel
        // }
    ];

    const styles = makeStyles( () => ({
        optionLabel: {
            display:    'flex',
            marginLeft: 10,
            alignItems: 'left',
            color:      'var(--black-1)'
        },
        iconClass:   {
            color: 'var(--grey-3)'
        }

    }) );
    const classes = styles();

    const renderOptions = ( props, option ) => {
        return (
            <div key={option.assetId} {...props}>
                {option.type === ADD_ITEM_ID ? (
                    <LicoIcon icon={'add'} className={classes.iconClass}/>
                ) : (
                    <LicoIcon
                        icon={option.type === DEVICETYPE ? MICROCHIP : GEAR}
                        className={classes.iconClass}
                    />
                )}
                <div className={classes.optionLabel}>{option.assetName}</div>
            </div>
        );
    };
    const newAssetForm = () => {
        setNewAsset( true );
        setSelectedAsset({ mode: 'ADD', details: [], questions: [] });
    };

    return (
        <>
            <Autocomplete
                options={realOptions}
                className="autocomplete"
                openOnFocus
                disableClearable
                freeSolo
                multiple
                classes={{
                    root:      roundInputRoot,
                    inputRoot: roundInputRoot
                }}
                getOptionLabel={( option ) => option.assetName || option.label}
                onInputChange={onInputChange}
                filterOptions={( options ) => options}
                onChange={( event, value ) => {
                    const asset = value[ value?.length - 1 ];
                    asset.type !== ADD_ITEM_ID ? onAutocompleteChange( event, asset ) : newAssetForm(); }}
                renderOption={renderOptions}
                renderInput={( params ) => {
                    return  <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Filter"
                        autoFocus={true}
                        style={{ fontSize: 14, height: 40, width: 250 }} // changing to classname is not working
                        InputProps={{
                            ...params.InputProps,
                            className: `${roundInputBox} autocompleteInput`
                        }}

                    />;
                }}
                renderTags={() => []}
                PopperComponent={( props ) => (
                    <Popper {...props}  placement="bottom-start"  />
                )}
            />
            { newAsset && <CreateAssetForm  anchorEl = {anchorEl} onClose={() => { setNewAsset( false ); }  } />
            }
        </>
    );

};

AssetAutoComplete.propTypes = {
    anchorEl:           PropTypes.any,
    setSelectedAsset:   PropTypes.func.isRequired,
    updateAssetToJob:   PropTypes.func.isRequired,
    allAssets:          PropTypes.array.isRequired,
    setTypeAheadFilter: PropTypes.func.isRequired,
    clientId:           PropTypes.string.isRequired,
    jobId:              PropTypes.string.isRequired
};
const mapStateToProps = state => {
    const { data: allAssets } = getTypeAheadControlState( state, TypeAheadControl.assets )  || [];
    return {
        assetsMap:  selectJobAssets( state ),
        allAssets:  allAssets || [],
        jobId:      state.jobcard.currentJobCardId
    };
};

const mapDispatchToProps = {
    setSelectedAsset,
    updateAssetToJob,
    setTypeAheadFilter
};

export default connect( mapStateToProps, mapDispatchToProps )( AssetAutoComplete );
