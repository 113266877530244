import React from 'react';

import { TextField, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

/* https://material-ui.com/components/autocomplete/#multiple-values */

export const LicoChipSearchSelect = ({ label, placeholder, options, existingOptions = [] }) => (
    <div >
        <Autocomplete
            multiple
            options={options}
            defaultValue={existingOptions}
            freeSolo
            renderTags={
                ( value, { className, onDelete }) =>
                    value.map( ( option, index ) =>
                        <Chip
                            key={index}
                            variant="outlined"
                            data-tag-index={index}
                            tabIndex={-1}
                            label={option.label}
                            className={className}
                            onDelete={onDelete}
                        />
                    )
            }
            getOptionLabel = {option => option.label}
            renderInput={
                params =>
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        placeholder= {placeholder || label}
                        margin="normal"
                        fullWidth={true}
                    />
            }
        />
    </div>
);

LicoChipSearchSelect.propTypes = {
    label:           PropTypes.string.isRequired,
    placeholder:     PropTypes.string,
    options:         PropTypes.array,
    existingOptions: PropTypes.array
};
