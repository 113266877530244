import { connectionErrorCodes, ADD, ConfigurationTypes, integrationFieldType, integrationTypes } from "@licoriceio/constants";
import { omit } from "@licoriceio/utils";

import { uri, GET, PATCH, POST } from "../../../constants.js";
import { setError, setProvider, setProviders, setIntegrationSettings, setMappingValue, setIntegrationFieldValue, setIntegrationProgress,
    setLoadOrganisationSettings, 
    setMeta } from "../../../redux/actions/index.js";
import { getNamesThunk } from "../../../redux/reducers/names.js";
import { genericRequest } from "../../../redux/reducerUtil.js";
import { createFatalError, abstractedCreateAuthRequest } from "../../../services/util/baseRequests.js";
import { __ } from "../../../utils/i18n.jsx";
import { initUserSession } from "../../calendar/sharedThunks.js";
import { HOME } from "../../navigation/routes.js";
import { getOneConfiguration } from "../utilities.jsx";

import { makeBoardFilter } from "./integration-helpers.jsx";

const getProvider = providerName => genericRequest({}, abstractedCreateAuthRequest( GET, uri.PROVIDER_BY_NAME ), setProviderConnectionThunk, [ providerName ]);
const getProviderSettings = providerName => genericRequest({}, abstractedCreateAuthRequest( GET, uri.INTEGRATION_SETTINGS ), setIntegrationSettings, [ providerName ]);
const refreshProviderSettings = providerName => genericRequest({}, abstractedCreateAuthRequest( GET, uri.REFRESH_CONNECTION ), setIntegrationSettings, [ providerName ]);
const updateMapValue = ( providerName, args ) => genericRequest( args, abstractedCreateAuthRequest( PATCH, uri.UPDATE_PROVIDER_VALUE ), setMappingValue, [ providerName ]);
const startIntegration = providerName => genericRequest({}, abstractedCreateAuthRequest( POST, uri.PERFORM_INTEGRATION ), setIntegrationProgress, [ providerName ]);
const setIntegrationTypeReq = integrationType => genericRequest({ integrationType }, abstractedCreateAuthRequest( POST, uri.INTEGRATION_TYPE ) );

const setProvidersThunk = payload => dispatch => {

    if ( payload.length === 1 ) 
        dispatch( getProvider( payload[ 0 ].connectorName ) );   
    else 
        dispatch( setProviders( payload ) );
    
};

const providerErrorMessage = {
    [ connectionErrorCodes.USER_NOT_AUTHENTICATED ]:    __( "User not authenticated; check the Company Name" ),
    [ connectionErrorCodes.PROVIDER_NOT_FOUND ]:        __( "Provider not found; check the API URL" ),
    [ connectionErrorCodes.BAD_KEY ]:                   __( "Public/Private keys not valid" ),
    [ connectionErrorCodes.UNSPECIFIED ]:               __( "Connection could not be established. Please check the details and try again" )
};

const setProviderConnectionThunk = payload => async ( dispatch, getState )  => {

    // we come here from two requests; getProvider (which fires at login, assuming we don't have to choose a provider ie there's only one)
    // and connect. getProvider always returns a provider (may be connected or not),
    // and connect does so if connection was successful. Otherwise, connect returns error information.
    // If we have a connected provider, we load the provider settings for the other fields.
    if ( payload.errorCode ) 
    {
        const { errorCode } = payload;
        dispatch( setError({
            uri:   uri.CONNECT_TO_PROVIDER,
            error: createFatalError( providerErrorMessage[ errorCode ] || providerErrorMessage[ connectionErrorCodes.UNSPECIFIED ])
        }) ); 

        // if we were previously connected, we're no longer so, according to the database.
        // We have to make that change locally to force a reconnection as the back end will now reject anything
        // but connect requests.
        dispatch( setProvider({ connected: false }) );
    }
    else 
    {

        const { meta: { integrationType } } = getState();

        dispatch( setProvider( payload ) );

        // We want to do none, some or all of these (see details) but we want to be sure they're all done before proceeding.
        // They can happen simultaneously.
        // if connected or standalone, get org settings, specifying connector in query if connected
        // if integrated or if standalone, get mappings
        // if connected, get provider settings
        // Note to future self; this will result in an indeterminate state if there are errors in ANY
        // of the promises. Look into allSettled if that becomes an issue (look in sliced.js for an example).
        await Promise.all([
            payload.connected ? dispatch( getProviderSettings( payload.connectorName ) ) : null,

            // This must wait until after the connectorName is found so we can look up "providerJobURL" if need be.
            ( payload.connected || integrationType === integrationTypes.STANDALONE )
                ? dispatch( getOneConfiguration( ConfigurationTypes.SETTINGS, setLoadOrganisationSettings, payload.connected ? { connectorName: payload.connectorName } : null ) ) 
                : null,

            // we have to wait for integration to have occurred since if we load these directly after first connection,
            // the tables are still loading. They're not used during integration.
            // payload.integrated ? getMappings() : null
            ( payload.integrated || integrationType === integrationTypes.STANDALONE ) ? dispatch( getNamesThunk() ) : null

            // getMappings()
        ].filter( Boolean ) );

        // if we've completed the system init or we've selected standalone, we can load the user session
        if ( ( payload.connected && payload.integrated ) || integrationType === integrationTypes.STANDALONE )
            dispatch( initUserSession() );
    }

};

const updateMapValueThunk = ( licoriceNameId, option, sectionName, action, recursing ) => ( dispatch, getState ) => {
    const { integration: { provider: { connectorName }, sections, settings: { dependencies, options }, fieldValue, fieldInfo } } = getState();

    const newFieldValues = { 
        ...fieldValue, 
        [ licoriceNameId ]: option
    };

    // maps job status labels against the list of provider names for that label
    const optionValues = sections[ sectionName ].optionValues;

    let newValue;
    if ( action ) {

        newValue = action === ADD 
            ? { ...fieldValue[ licoriceNameId ], [ option.id ]: option.label }
            : { ...omit( fieldValue[ licoriceNameId ], option.label ) };
            
        // for labelChip fields, we need to map each label in the list to a list of providerNameIds;
        // we have the mapping already in optionValues. For other fields, we have the keys in the value already.
        const providerNameIds = option.mode === integrationFieldType.LABEL_CHIP
            ? [ ...Object.keys( newValue ).map( id => optionValues[ id ]).flat() ]
            : [ ...Object.keys( newValue ) ];
        
        dispatch( updateMapValue( connectorName, { licoriceNameId, providerNameId: providerNameIds }) );
    }
    else {
        newValue = option;

        // job status dropdowns send the list of ids to the backend but maintain the text name as the value in state
        dispatch( updateMapValue( connectorName, { licoriceNameId, providerNameId: optionValues?.[ option ] ?? option }) );
    }
    
    dispatch( setIntegrationFieldValue({ name: licoriceNameId, value: newValue }) );

    // dependency handling; if we've changed a field which has dependent fields, we need to reset those fields' values to the first
    // item in the valid list for the new selection. (The values in the dependent fields' lists will also change but we need to tell
    // the backend about all the field changes).
    if ( !action && dependencies[ licoriceNameId ]) {
        Object.entries( dependencies[ licoriceNameId ]).forEach( ([ dependentField, values ]) => {
            const info = fieldInfo[ dependentField ];

            // the valid list is not in the same order as the displayed options, so we have to filter the display list 
            // by the valids to find the first one, we can't just pick the first valid id.
            const first = options[ dependentField ].find( ({ providerNameId }) => values[ option ].includes( providerNameId ) );

            // dispatch this thunk recursively
            dispatch( updateMapValueThunk( info.id, first.providerNameId, info.sectionName, undefined, true ) );

            // if we're the master field, add this value to the new values
            if ( !recursing ) 
                newFieldValues[ info.id ] = first.providerNameId;

        });
    }

    // when we make a field change that affects the board list (ie location or department), we need to tell the backend,
    // similar to dependent field changes above. However, getting the new board value is much trickier, since we don't
    // have the fixed dependency lists.
    // 
    // What we do is build the board filter ourselves, in the same way that will be done to refresh the control, passing it a
    // fake fieldValue object, since getting the new one out of state is tricky/impossible.
    // We can then filter the board list and choose the first option, again as done above.
    // 
    // The final trick is when to do this; we no longer have field names, and we don't want to reset the board value
    // unless location or department is changed. (Location will trigger a change in department, but we're handling that
    // via the recursing flag; we don't do anything until we're back in the location handler with the full set of new values).
    // We assume that board dependencies will remain in the billing section; we find the licoriceNameId for all fields
    // in that section with serviceBoardInfo defined and if we're changing one of those fields, we reset the board.
    if ( !recursing ) {
        const serviceBoardFields = sections.billing.dropdowns.filter( field => field.serviceBoardInfo ).map( field => field.licoriceNameId );
        if ( serviceBoardFields.includes( licoriceNameId ) ) {

            const boardFilter = makeBoardFilter( options.board, sections.billing.dropdowns, newFieldValues );

            // get the first id in the filtered board list (not the first id in the filter list)
            const first = options.board.find( ({ providerNameId }) => boardFilter.includes( providerNameId ) );

            // assuming we found a board, dispatch this thunk recursively; again, we're using the section control list to find the licoriceNameId
            // for the board field. If we didn't find a board, they'll need to make a further change and we'll update it then.
            if ( first ) 
                dispatch( updateMapValueThunk( sections.board.dropdowns[ 0 ].licoriceNameId, first.providerNameId, 'board', undefined, true ) );

        }
    }

};

const initStandalone = history => async ( dispatch, getState ) => {   

    // we could use setMeta as the response action to the request, but that would set all the fields
    // in dbState into state.meta
    dispatch( setIntegrationTypeReq( integrationTypes.STANDALONE ) );
    dispatch( setMeta({ integrationType: integrationTypes.STANDALONE }) );

    // don't get names until the licoriceName init is done
    abstractedCreateAuthRequest( POST, uri.ADD_RAW_LICORICE_NAMES )({}, getState().auth )
        .then( async () => await dispatch( getNamesThunk() ) );

    dispatch( initUserSession() );

    history.push( HOME );
};

export {
    setProvidersThunk,
    setProviderConnectionThunk,
    getProviderSettings,
    refreshProviderSettings,
    updateMapValueThunk,
    startIntegration,
    initStandalone
};
