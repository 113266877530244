/********************************************************************************************************************
 * @file Actions for the appointment store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setAddAppointment = createAction( 'APPOINTMENT.ADD/SET' );
export const setAppointmentImported = createAction( 'APPOINTMENT.IMPORTED/SET' );
