import React from 'react';

import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { form,
    row,
    formFields
} from '../../../scss/AssetForm.module.scss';
import { __ } from "../../../utils/i18n.jsx";

import AssetFormViewFields from "./AssetFormViewFields.jsx";
import FooterSection from "./FooterSection.jsx";
import HeaderSection from "./HeaderSection.jsx";

const assetDetailsQuestionList = [
    "type",
    "license",
    "name",
    "status",
    "sla",
    "install date",
    "installed by",
    "purchase date",
    "location",
    "business unit",
    "expiration date",
    "vendor",
    "manufacturer",
    "mfg part #",
    "model number",
    "serial number",
    "tag Number",
    "bill customer",
    "needs renewal",
    "notes"
];
export const removeAssetDetailsQuestionList = [
    "location",
    "department",
    "company",
    "activeflag",
    "vendornotes"
];


const AssetForm = (
    { anchorEl,
        onClose,
        selectedAsset,
        filterString = ''
    }
) => {
    const isOpen = Boolean( anchorEl );
    const containsFilterString = ( text ) => {
        return text.toLowerCase().includes( filterString.toLowerCase() );
    };

    const separateQuestions =  asset  => {
        // this is done because the details questions will have more fields than what we need to show on the form.
        // the rest of the fields have to be shown in the questions section.

        const { details, questions } = asset;
        const detailsList = [];
        const questionsList = [];
        const processItem = ( item, addToDetailsList ) => {
            const lowercaseQuestion = item.question.toLowerCase();

            if ( removeAssetDetailsQuestionList.includes( lowercaseQuestion ) ) return;

            if ( addToDetailsList && assetDetailsQuestionList.includes( lowercaseQuestion ) )
                detailsList.push( item );
            else 
                questionsList.push( item );
        };
        const containsFilter = ( item, filterString ) => {
            const lowercaseAnswer = ( typeof item.answer === 'string' ? item.answer : item?.answer?.name || '' ).toLowerCase();
            const lowercaseQuestion = item.question.toLowerCase();
            return containsFilterString( lowercaseQuestion, filterString ) || containsFilterString( lowercaseAnswer, filterString );
        };
        const filterItems = ( items, filterString ) => {
            return filterString.trim() === '' ? items : items.filter( item => containsFilter( item, filterString ) );
        };

        details.forEach( detail => processItem( detail, true ) );
        questions.forEach( question => processItem( question, true ) );

        const filteredDetailsList = filterItems( detailsList, filterString );
        const filteredQuestionsList = filterItems( questionsList, filterString );

        return { details: filteredDetailsList, questions: filteredQuestionsList };
    };

    const viewFormField = asset => {
        const { details, questions } = separateQuestions( asset );
        return  <div className={row}>
            { <AssetFormViewFields sectionName={__( 'Details' )} fieldList={details}  ></AssetFormViewFields>}
            &nbsp;&nbsp;
            { <AssetFormViewFields sectionName={__( 'Technical' )} fieldList={questions} ></AssetFormViewFields> }
        </div>;
    };

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical:   'center',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical:   'center',
                horizontal: 'center'
            }}
        >
            <div className={form}>
                <HeaderSection onClose={onClose}/>
                <div className={formFields}>
                    { viewFormField( selectedAsset ) }
                </div>
                <FooterSection onClose={onClose}></FooterSection>
            </div>
        </Popover>
    );
};

AssetForm.propTypes = {
    anchorEl:             PropTypes.func,
    onClose:              PropTypes.func.isRequired,
    selectedAsset:        PropTypes.object.isRequired,
    filterString:         PropTypes.string.isRequired
};
const mapStateToProps = state => ({
    selectedAsset: state.jobcard.selectedAsset,
    filterString:  state.jobcard.selectedAsset?.filterString || ''
});

export default connect( mapStateToProps )( AssetForm );
