/**
 * @param {BaseState} state
 * @param {string} userId
 * @return {ExtendedEngineer&Database.User|undefined}
 */
const selectCacheUser = ( state, userId ) => userId && state.cache.type?.users?.data[ userId ]?.record;

/**
 * @param {BaseState} state
 * @param {string} type
 * @param {string} id
 * @return {object}
 */
const selectCacheRecord = ( state, type, id ) => id && state.cache.type?.[ type ]?.data[ id ]?.record;

/**
 * @param {BaseState} state
 * @param {string} type
 * @return {object}
 */
const selectCacheData = ( state, type ) => state.cache.type?.[ type ]?.data;

export {
    selectCacheUser,
    selectCacheRecord,
    selectCacheData
};
