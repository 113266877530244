/********************************************************************************************************************
 * @file Actions for the journal store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 29-05-2024
 * ***************************************************************************/

import { createAction } from 'redux-actions';

export const setJournalPageIndex = createAction( 'JOURNAL.JOURNAL_PAGE_INDEX/SET' );
export const setJournalPageRows = createAction( 'JOURNAL.JOURNAL_PAGE_ROWS/SET' );
export const setJournalDownloadContent = createAction( 'JOURNAL.JOURNAL_DOWNLOAD/SET' );
