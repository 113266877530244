import {
    UX_SETTINGS_PROFILE,
    UX_SETTINGS_ENGINEERS,
    UX_SETTINGS_ORGANISATION,
    UX_SETTINGS_CLIENT_SCHEDULING,
    UX_SETTINGS_INTEGRATIONS,
    UX_SETTINGS_TEAMS,
    UX_SETTINGS_CLIENTS, 
    UX_SETTINGS_CLIENTS_LOCATIONS, 
    UX_SETTINGS_CLIENTS_USERS,
    UX_SETTINGS_JOURNAL
} from '../../ux-constants.js';
import { __ } from '../../utils/i18n.jsx';

export const HOME = "/";
export const LOGIN = "/login";
export const SETTINGS = "/settings";
export const ACCEPT_INVITE = "/register";
export const CLIENT_SCHEDULE = "/schedule/:tokenId?";
export const REGISTRATION = "/registration";

export const SETTINGS_PROFILE = SETTINGS + "/profile";
export const SETTINGS_ORGANISATION = SETTINGS + "/organisation";
export const SETTINGS_CLIENTS = SETTINGS + "/clients";
export const SETTINGS_CLIENTS_LOCATIONS = SETTINGS + "/clients/:clientId/locations/:clientName";
export const SETTINGS_CLIENTS_USERS = SETTINGS + "/clients/:clientId/users/:clientName";
export const SETTINGS_ENGINEER = SETTINGS + "/engineers";
export const SETTINGS_CLIENT_APPOINTMENTS = SETTINGS + "/clientAppointments";
export const SETTINGS_INTEGRATION = SETTINGS + "/integrations";
export const SETTINGS_TEAM = SETTINGS + "/teams";
export const SETTINGS_JOURNAL = SETTINGS + "/journal";
// export const SETTINGS_BILLING = SETTINGS + "/billing";


export const settingsRoutes = [
    {
        label:      __( "Your Profile" ),
        to:         SETTINGS_PROFILE,
        adminOnly:  false,
        testTag:    UX_SETTINGS_PROFILE
    },
    {
        label:      __( "Organisation" ),
        to:         SETTINGS_ORGANISATION,
        adminOnly:  true,
        testTag:    UX_SETTINGS_ORGANISATION
    },
    {
        label:      'Clients',
        to:         SETTINGS_CLIENTS,
        adminOnly:  true,
        testTag:    UX_SETTINGS_CLIENTS
    },
    {
        label:      __( "Engineers" ),
        to:         SETTINGS_ENGINEER,
        adminOnly:  false,
        testTag:    UX_SETTINGS_ENGINEERS
    },
    {
        label:      __( "Teams" ),
        to:         SETTINGS_TEAM,
        adminOnly:  true,
        testTag:    UX_SETTINGS_TEAMS
    },
    {
        label:      __( "Client Scheduling" ),
        to:         SETTINGS_CLIENT_APPOINTMENTS,
        adminOnly:  true,
        testTag:    UX_SETTINGS_CLIENT_SCHEDULING
    },
    {
        label:      __( "Integrations" ),
        to:         SETTINGS_INTEGRATION,
        adminOnly:  true,
        testTag:    UX_SETTINGS_INTEGRATIONS
    },
    {
        label:      __( "Journal" ),
        to:         SETTINGS_JOURNAL,
        adminOnly:  true,
        testTag:    UX_SETTINGS_JOURNAL
    }
    // {
    //     label: "Billing",
    //     to: SETTINGS_BILLING,
    //     adminOnly: true,
    // },
];


export const FORGOT_PASSWORD = "/forgotpassword";
export const RESET_PASSWORD = "/reset-password";
