export const inputStyle = {
    '& .MuiOutlinedInput-root': {
        padding: 0,

        '& fieldset':             {
            display: 'none'
        },
        '&:hover fieldset':       {
            borderColor: 'var(--grey-4)'
        },
        '&.Mui-focused':          {
            backgroundColor: '#f1f2f4',
            borderRadius:    0,
            border:          'none'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--grey-4)'
        }
    }
};
export const tableCellStyle = {
    padding:           0,
    fontSize:          14,
    color:             'var(--black-1)',
    border:            '1px solid var(--grey-4)',
    '&:first-of-type': {
        borderLeft: 'none'
    },
    '&:last-of-type':  {
        borderRight: 'none'
    }
};
export const tableCellLabelStyle = {
    paddingLeft: "14px",
    paddingTop:  "19px"
};
export const headerCellStyle = {
    border:            '1px solid  var(--grey-4)',
    fontSize:          14,
    fontWeight:        'bold',
    padding:           '20px 14px',
    color:             'var(--black-1)',
    '&:first-of-type': {
        borderLeft: 'none'
    },
    '&:last-of-type':  {
        borderRight: 'none'
    }
};
