import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridGap: 10,
        margin:  20
    }
});

export const LicoButtonGroup = ({ children, className = '', ...rest }) => {
    const classes = useStyles( rest );

    return (
        <div className={`${classes.root} ${className}`}>
            {children}
        </div>
    );
};

LicoButtonGroup.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node
};
