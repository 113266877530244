/*********************************************************************************************************************
 * @file Job reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 31-Dec-2020
 *********************************************************************************************************************/
import { ResourceUploadStages, DELETE } from '@licoriceio/constants';
import { unpack, has, omit } from '@licoriceio/utils';

import { setUpdateAppointmentStaticsThunk, setExistingJobThunk } from '../../components/calendar/sharedThunks.js';
import { GET, uri } from '../../constants.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { setDeleteJob, setSplitJobs, setUpdateJob, setDeleteTimeLog,
    setAddJobs, setJobChange, setUpdateCompany, setFileUploadStart, setFileUploadProgress } from '../actions/index.js';
import { ezRedux, genericRequest, makeMapById, sliceMergeAction } from '../reducerUtil.js';
import { jobOnPegboard } from '../selectors/index.js';

import { requestCacheRecord, cacheType } from './cache.js';
/**
 * @typedef {object} JobState
 * @property {object} jobMap
 */

/**
 * @type {JobState}
 */
const initialState = Object.freeze({
    jobMap:             {},
    clientMap:          {},
    fieldError:         {},
    changeFlag:         false,
    fileMap:            {}
});

/** requests */

const deleteJob = jobId => genericRequest({}, abstractedCreateAuthRequest( DELETE, uri.SINGLE_JOB ), [ [ setDeleteJob, { jobId } ] ], [ jobId ]);
const getCompanyName = companyId => genericRequest({}, abstractedCreateAuthRequest( GET, uri.SINGLE_COMPANY ), 
    [ 
        [ setUpdateCompany, { companyId } ], 
        [ setUpdateAppointmentStaticsThunk, {} ],
        [ requestCacheRecord, { type: cacheType.COMPANY, id: companyId } ]
    ], [ companyId ]);

/** thunk actions */

const deleteJobThunk = payload => async ( dispatch, getState ) => {
    const { jobcard: { currentJobCardId } } = getState();

    // socket deletes just need local actions
    if ( !payload.localOnly )
        dispatch( deleteJob( payload.jobId ) );

    dispatch( setDeleteJob( payload ) );

    const timeLogId = jobOnPegboard( payload.jobId );
    if ( timeLogId )
        dispatch( setDeleteTimeLog({ timeLogId }) );

    if ( payload.jobId === currentJobCardId )
        dispatch( setExistingJobThunk({ jobId: null }) );
    dispatch( setUpdateAppointmentStaticsThunk( ) );
};

const confirmClientThunk = payload => async ( dispatch, getState ) => {
    const { job: { clientMap } } = getState();

    if ( payload && !has( clientMap, payload ) ) 
        dispatch( getCompanyName( payload ) );
};

/** reducers */

const splitJobReducer = ( draft, payload ) => {
    payload.payload
        .map( r => ({ ...r.job, jobId: r.realJobId, userId: r.jobUserId, companyName: r.company.companyName }) )
        .forEach( job => {
            draft.jobMap[ job.jobId ] = omit( job, [ 'companyName' ]);
            draft.clientMap[ job.companyId ] = { companyName: job.companyName };
        });
};

const updateJobReducer = ( draft, payload ) => {
    const job =  unpack( payload );
    draft.jobMap[ job.jobId ] = { ...draft.jobMap[ job.jobId ], ...job };
};

const _setJobChange = ( draft, { job, action }) => {
    if ( action === 'updated' ) {
        if ( has( draft.jobMap, job.jobId ) )
            Object.assign( draft.jobMap[ job.jobId ], job );
        else
            draft.jobMap[ job.jobId ] = job;
    }
    else if ( action === 'added' )
        draft.jobMap[ job.jobId ] = job;
    else if ( action === 'removed' && has( draft.jobMap, job ) )
        delete draft.jobMap[ job ];
};

const mergeCompletedDate = ( draft, { result: { payload } }) => {

    // special case reducer to cope with completedDate going from set to null, which is ignored
    // by the standard reducer. 
    const updatedJob = unpack( payload );
    const job = draft.jobMap[ updatedJob.jobId ];

    // if the job hasn't been loaded yet (eg after an import), we don't have to worry about this change
    if ( job )
    {
        job.completedDate = updatedJob.completedDate;
        job.statusId = updatedJob.statusId;
    }
};

const _setFileUploadProgress = ( draft, payload ) => {

    // for large files we can get progress reports before the initial request returns the new fileKey,
    // so a progress report on an unknown fileKey is valid (this would also happen if the upload was still in progress
    // when the user logged in). Progress notifications only go to the originating user so we don't need to worry
    // about filtering them.

    // we can't simply replace the current record because "progress" (ie "In-progress") messages can arrive after "complete",
    // and these need to be ignored.
    const { fileMap } = draft;
    const resource = fileMap[ payload.fileKey ];
    if ( resource ) {
        if ( resource.progress === ResourceUploadStages.started || resource.progress === ResourceUploadStages.loading ) 
            fileMap[ payload.fileKey ] = payload;
    }
    else 
        fileMap[ payload.fileKey ] = payload;
};

const reducers = {
    [ setAddJobs ]:                     ( draft, payload ) => draft.jobMap = payload.reduce( makeMapById( 'jobId' ), draft.jobMap ),
    [ setUpdateJob ]:                   updateJobReducer,
    [ setDeleteJob ]:                   ( draft, payload ) => delete draft.jobMap[ payload.jobId ],
    [ setSplitJobs ]:                   splitJobReducer,
    [ setJobChange ]:                   _setJobChange,
    [ setUpdateCompany ]:               ( draft, payload ) => draft.clientMap[ payload.companyId ] = { companyName: payload.companyName || payload.payload },
    [ sliceMergeAction( 'job' ) ]:      mergeCompletedDate,
    [ setFileUploadStart ]:             ( draft, payload ) => draft.fileMap[ payload.fileKey ] = payload,
    [ setFileUploadProgress ]:          _setFileUploadProgress
};

/** selectors */

export {
    deleteJob,
    deleteJobThunk,
    confirmClientThunk
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState, 'job' );
