import React, { useState, useRef } from 'react';

import { Chip, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

import { roundIcon, greyIcon, chipBox, chip, chipLabel, selector } from '../../../scss/ChipBox.module.scss';
import DynamicSelect from '../../common/DynamicSelect.jsx';
import { LicoIcon } from "../../common/images/LicoIcon.jsx";

const ChipBoxChip = ({ chipId, chipName, deleteAction }) => (
    <Chip
        deleteIcon={<LicoIcon icon="close" />}
        label={chipName}
        classes={{
            root:   chip,
            label:  chipLabel
        }}
        onDelete={deleteAction ? () => deleteAction( chipId ) : undefined}
    />
);

ChipBoxChip.propTypes = {
    chipId:         PropTypes.string.isRequired,
    chipName:       PropTypes.string.isRequired,
    deleteAction:   PropTypes.func
};

const RoundIcon = ({ icon, size, className, onClick }) => (
    <div className={`${roundIcon} ${className}`} style={{ height: size, width: size }} onClick={onClick}>
        <LicoIcon icon={icon} />
    </div>
);

RoundIcon.propTypes = {
    icon:      PropTypes.string,
    size:      PropTypes.number.isRequired,
    className: PropTypes.string,
    onClick:   PropTypes.func
};

export const ChipBox = ({
    name,
    existingChips,
    addChipAction,
    removeChipAction,
    fullChipList,
    typeAheadId,
    disabled,
    typeAheadExclusions,
    allowEmptyList = false
}) => {

    const [ addChipMode, setAddChipMode ] = useState( false );
    const boxRef = useRef( null );

    return <div className={chipBox} ref={boxRef}>
        {Object.entries( existingChips ).sort( ( a, b ) => a[ 1 ].localeCompare( b[ 1 ]) ).map( ([ chipId, chipName ], i ) => <ChipBoxChip
            key={chipId}
            chipId={chipId}
            chipName={chipName}
            deleteAction={disabled || ( i == 0 && !allowEmptyList ) ? undefined : removeChipAction}
        /> )}
        {!disabled && !addChipMode &&
            <RoundIcon icon="add" size={30} className={greyIcon} onClick={event => setAddChipMode( event.currentTarget )}/>}
        {!disabled && addChipMode && <div className={selector}>

            {typeAheadId &&
                <DynamicSelect
                    name={typeAheadId}
                    autoFocus
                    onChange={( event, value ) => {
                        addChipAction( value );
                        setAddChipMode( null );
                    }}
                    onBlur={() => setAddChipMode( false )}
                    excludedIds={typeAheadExclusions}
                />}

            {fullChipList && 
                <Menu
                    name={`${name}Menu`}
                    open={true}
                    anchorEl={boxRef.current}
                    onClose={() => setAddChipMode( false )}
                >
                    {fullChipList.filter( item => !existingChips[ item.id ]).map( ( v, i ) => <MenuItem value={v.id} key={i} onClick={() => {
                        addChipAction( v );
                        setAddChipMode( false );
                    }}>{v.label}</MenuItem> )}
                </Menu>
        
            }
        </div>}
    </div>;
};


ChipBox.propTypes = {
    name:                       PropTypes.string,
    existingChips:              PropTypes.object.isRequired,
    addChipAction:              PropTypes.func.isRequired,
    removeChipAction:           PropTypes.func.isRequired,
    fullChipList:               PropTypes.array,
    typeAheadId:                PropTypes.string,
    disabled:                   PropTypes.bool,
    typeAheadExclusions:        PropTypes.array,
    allowEmptyList:             PropTypes.bool
};
