import { NULL } from "@licoriceio/constants";
import { has } from "@licoriceio/utils";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';

import { GET, uri, JOB_QUERY_FIELDS } from "../../constants";
import {
    setUpdateAppointmentStatics, setSplitJobs, setDeleteJob, setDeleteLocalTimeLog, setCurrentJobCardId,
    setCurrentCalendarEventId, setCalendarEventDetails, setDeleteCalendarEvent, setCommentBillable, setJobCardAppointments,
    setAppointmentHistory, setChatTabIndex, setNotifications, setTimeLogs, setElapsedMeter, setMeta,
    setUpdatePegboardTimers, setIncrementTimeEntered, setAddUserCalendar, setCalendarViewDates, setAddAppointments,
    setCalendarEvents, setOtherUserTimeLogs, setSearchPanel, setTypeAheadAssets, setAddTimeLog, setAddLocalTimeLog,
    startLoading, setSkipClientEmail
} from "../../redux/actions/index.js";
import { cacheType, requestCacheRecords, requestCacheRecord } from "../../redux/reducers/cache.js";
import { TypeAheadControl } from "../../redux/reducers/typeAhead.js";
import { genericRequest } from "../../redux/reducerUtil.js";
import { getJobFromState, selectSystemReady } from '../../redux/selectors';
import { abstractedCreateAuthRequest } from "../../services/util/baseRequests.js";
import { getUserSession, addCalendarToSession, clearUserSession } from "../../utils/local-storage.js";
import { getJobNotes, getJobCardUsers, getJobCardEngineers, getJobCardChecklist, getJobCardAssets } from '../jobcard/requests.js';
import { getJobsForTab, windowHeightChanged } from "../searchPanel/reducer.js";

const getJobCardAppointments = ( jobId ) => genericRequest({}, 
    abstractedCreateAuthRequest( GET, uri.JOB_APPOINTMENTS, a => a, false ), setJobCardAppointmentsThunks, [ jobId ], { pageSize: 1000 });

const getJobAppointmentHistory = jobId => genericRequest(
    {}, 
    abstractedCreateAuthRequest( GET, uri.HISTORY_BY_PARENT ), 
    setJobAppointmentHistoryThunk, 
    [ jobId ], 
    { table: 'appointment', action: 'reschedule' }
);

const getNotificationsRequest = ( ) => genericRequest({}, 
    abstractedCreateAuthRequest( GET, uri.NOTIFICATIONS ), 
    [ setNotifications, setUpdateAppointmentStaticsThunk, [ requestRecordLinks, { type: 'user' } ] ], undefined, { pageSize: 1000 });

const getTimeLogsRequest = userId => genericRequest(
    {}, abstractedCreateAuthRequest( GET, uri.TIME_LOG ), [ [ setTimeLogs ], [ commonJobActionsThunk ], [ updateMeters ] ], undefined, {
        finish:             NULL,
        "timeLog.userId":   userId,
        related:            [ 'timeLog->job->?company' ],
        fields:             [ 'timeLog.*', ...JOB_QUERY_FIELDS ],
        splitRelated:       true
    }
);

// when fetching other user's timelog items, we only care about the pegboard items, since we don't want to change
// the play/pause status, etc
const getOtherUserTimeLogsRequest = userId => genericRequest(
    {}, abstractedCreateAuthRequest( GET, uri.TIME_LOG ), [ [ commonJobActionsThunk ], [ setOtherUserTimeLogs, { userId } ] ], undefined, {
        finish:             NULL,
        "timeLog.userId":   userId,
        pegboard:           true,
        related:            [ 'timeLog->job->?company' ],
        fields:             [ 'timeLog.*', ...JOB_QUERY_FIELDS ],
        splitRelated:       true
    }
);

const getElapsedMeter = jobId => genericRequest({}, abstractedCreateAuthRequest( GET, uri.TIME_LOG_SUM ), setElapsedMeter, [ jobId ]);

const getTimeEnteredToday = ( day = dayjs().startOf( 'day' ) ) => genericRequest(
    undefined, 
    abstractedCreateAuthRequest( GET, uri.TIME_LOG_SUM_USER ), 
    [ [ setIncrementTimeEntered, { start: day.toISOString() } ] ],
    undefined,
    { 
        from:   day.toISOString(),
        to:     day.add( 1, 'day' ).toISOString()
    }
);

const getAppointmentsRequest = ( userId, from, to ) => genericRequest(
    {}, abstractedCreateAuthRequest( GET, uri.APPOINTMENTS ), [ [ commonJobActionsThunk, {} ], [ setAddAppointmentsThunk, { userId } ] ],  [  ], {
        userId,
        from,
        to,
        related:                [ 'appointment->job->?company' ],
        fields:                 [ 'appointment.*', ...JOB_QUERY_FIELDS ],
        'appointment.state':   [ 'active', 'done' ],
        splitRelated:           true,
        pageSize:               100
    }
);

const getCalendarEventsRequest = ( userId, from, to ) => genericRequest(
    {}, abstractedCreateAuthRequest( GET, uri.CALENDAR_EVENTS ), [ [ setCalendarEvents, { userId } ] ], undefined, {
        userId,
        from,
        to
    }
);

const getCalendarUserRequest = calendar => genericRequest(
    {}, abstractedCreateAuthRequest( GET, uri.SINGLE_USER ), [ [ addCalendarThunk, { viewStart: calendar.viewStart } ] ], [ calendar.user.userId ]);

const setAddAppointmentsThunk = payload => ( dispatch ) => {
    dispatch( setAddAppointments( payload ) );
    dispatch( setUpdateAppointmentStaticsThunk( payload.userId ) );
};

const updateMeter = ({ jobId }) => dispatch => dispatch( getElapsedMeter( jobId ) );

/**
 * dispatch a timer update action if there are any running timers
 */
const dispatchTimerUpdate = () => ( dispatch, getState ) => {
    const timerRunning = Object.values( getState().timelog.timeLogMap || {}).some( item => item.pegboard && item.start && ( item.pausedAt === null ) );
    if ( timerRunning )
        dispatch( setUpdatePegboardTimers() );
};

const updateMeters = () => ( dispatch, getState ) => {
    const { timelog: { timeLogMap } } = getState();
    if ( timeLogMap == null )
        return;
    
    Object.values( timeLogMap )
        .filter( tl => tl.pegboard )
        .map( tl => tl.jobId )
        .forEach( jobId => jobId && dispatch( getElapsedMeter( jobId ) ) );
};

const getTimeLogs = () => ( dispatch, getState ) => {

    const { timelog: { timeLogMap }, user } = getState();
    if ( timeLogMap == null )
    {
        dispatch( setTimeLogs({ payload: [] }) );
        dispatch( getTimeLogsRequest( user.userId ) );
    }
};

const getNotifications = () => ( dispatch, getState ) => {
    if ( getState().notification.notifications == null )
        dispatch( getNotificationsRequest() );
};

// we want to cache all appointment users so we have to intercept the original data with a thunk
const setJobCardAppointmentsThunks = payload => dispatch => {
    dispatch( setJobCardAppointments( payload ) );
    payload.forEach( appt => dispatch( requestCacheRecord({ type: cacheType.USER, id: appt.userId }) ) );
};

const setJobAppointmentHistoryThunk = payload => dispatch => {
    dispatch( setAppointmentHistory( payload ) );
    payload.forEach( appt => dispatch( requestCacheRecord({ type: cacheType.USER, id: appt.data.userId ?? appt.userId }) ) );
};

// convenience thunk to request a set of associated cache records given a list and a type,
// eg select all users for a list of notifications. This is intended to be called as an action
// following a backend request so expects the double payload structure.
const requestRecordLinks = payload => dispatch => {
    dispatch( requestCacheRecords({ type: payload.type, ids: payload.payload }) );
};

/**
 * Rebuild the appointment statics for one or all users. Pulls in all other state components needed
 * for the rebuild, ie things outside calendar eg jobMap, notifications
 * @param {string} [userId]
 * @returns void
 */
const setUpdateAppointmentStaticsThunk = payload => ( dispatch, getState ) => {
    const { job: { jobMap, clientMap }, notification: { pendingAppointmentMap } } = getState();

    // the userId expression is necessary because this action can be dispatched from the genericRequest action list,
    // which automatically adds the response payload, which we don't care about.
    dispatch( setUpdateAppointmentStatics({ jobMap, clientMap, pendingAppointmentMap, userId: typeof payload === 'string' ? payload : undefined }) );
};

const commonJobActionsThunk = payload => ( dispatch, getState ) => {

    dispatch( setSplitJobs( payload ) );
    if ( selectSystemReady( getState() ) ) 
    {
        dispatch( requestCacheRecords({ type: cacheType.COMPANY, ids: payload.payload.filter( a => a.job.companyId ).map( a => a.job.companyId ) }) );

        const session = getUserSession();
        if ( session?.currentJobCardId ) 
        {
            const record = payload.payload.find( a => a.realJobId === session.currentJobCardId );
            if ( record ) 
                dispatch( setExistingJobThunk({ jobId: record.realJobId, chatTabIndex: session.chatTabIndex, force: true }) );
        }
    }
};

/**
 * if we close a job card that hasn't triggered a save, remove the local job, appointment and pegboard items
 * @param {string} jobId
 * @returns void
 */
const deleteUnsavedJobThunk = jobId => ( dispatch, getState ) => {
    const state = getState();
    const { job: { jobMap }, jobcard: { newJobDetails } } = state;

    if ( jobMap[ jobId ]?.newItemFlag ) 
    {
        dispatch( setDeleteJob({ jobId }) );
        dispatch( setUpdateAppointmentStaticsThunk( newJobDetails.userId ) );
        if ( newJobDetails.jobAddedFromPegboard )
            dispatch( setDeleteLocalTimeLog() );
    }

};

const setExistingBusyCardThunk = payload => dispatch => {

    // immediately clear previous state and open (or close) the busyCard card
    dispatch( setCurrentCalendarEventId( payload?.calendarEventId ) );

    // we need the user id as well
    dispatch( setCalendarEventDetails({ busyCardDetails: { userId: payload?.userId } }) );
};

const closeCalendarEventThunk = () => ( dispatch, getState ) => {
    const { calendarEvent: { currentCalendarEventId, busyCardDetails, userMap } } = getState();

    dispatch( setExistingBusyCardThunk( null ) );

    // if this is an unsaved new event, delete it
    if ( busyCardDetails.userId && userMap[ busyCardDetails.userId ].calendarEventMap[ currentCalendarEventId ].newItemFlag )
        dispatch( setDeleteCalendarEvent({ calendarEventId: currentCalendarEventId, userId: busyCardDetails.userId }) );

};

const setExistingJobThunk = payload => ( dispatch, getState ) => {

    // is this job already open? Catch repeated clicks on the PB item
    const state = getState();
    const { user: { userId }, jobcard: { currentJobCardId, skipClientEmail } } = state;

    // ignore repeated click
    if ( payload?.jobId === currentJobCardId && !payload?.force ) 
        return;

    dispatch( deleteUnsavedJobThunk( currentJobCardId ) );

    // immediately clear previous state and open (or close) the job card
    dispatch( setCurrentJobCardId({ jobId: payload?.jobId, userId }) );

    // we might have had a CE open
    dispatch( closeCalendarEventThunk() );

    // typeahead data is separate from jobcard data
    dispatch( setTypeAheadAssets({ name:      TypeAheadControl.assets, payload: [] }) );

    // retrieve job info
    if ( payload?.jobId ) 
    {

        // default state of comment billable icon comes from job
        const { billable } = getJobFromState( state, payload.jobId );
        dispatch( setCommentBillable( billable ) );

        // reset skip email flag if it was set previously
        if ( skipClientEmail )
            dispatch( setSkipClientEmail() );

        dispatch( getJobNotes( payload.jobId ) );
        dispatch( getJobCardAppointments( payload.jobId ) );
        dispatch( getJobCardUsers( payload.jobId ) );
        dispatch( getJobCardEngineers( payload.jobId ) );
        dispatch( getJobCardChecklist( payload.jobId ) );
        dispatch( getJobAppointmentHistory( payload.jobId ) );
        dispatch( setChatTabIndex( payload.chatTabIndex || 0 ) );
        dispatch( getJobCardAssets( payload.jobId ) );
    }

};

// load the user-specific data
const initUserSession = () => ( dispatch, getState ) => {

    const { user, login } = getState();

    dispatch( getNotifications() );
    dispatch( getTimeLogs() );

    let session;
    if ( login.ignoreSession )
        clearUserSession();
    else
        session = getUserSession( );
    let calendarsAdded = false;

    if ( session ) 
    {

        // add calendars, making sure we get current user data for the other calendars
        session.calendars.forEach( calendar => {
            if ( !calendar.user ) 
            {
                // we're seeing empty calendars after a crash somehow
                console.warn( `No user in calendar`, calendar );
            }
            else 
            { 
                calendarsAdded = true;
                dispatch( calendar.user.userId === user.userId 
                    ? addCalendarThunk({ user, viewStart: calendar.viewStart }) 
                    : getCalendarUserRequest( calendar ) ); 
            } 
        });

        if ( session.searchPanelShown )
            dispatch( setMeta({ searchPanelShown: true }) );

        if ( session.searchPanel )
        {
            dispatch( setSearchPanel( session.searchPanel ) );
            const newHeight = window.innerHeight - 73;
            dispatch( windowHeightChanged( newHeight ) );
            session.searchPanel?.tabs?.forEach( tab => dispatch( getJobsForTab( tab.id ) ) );

        }
    }

    if ( !calendarsAdded ) 
        dispatch( addCalendarThunk({ user }) );

    dispatch( getTimeEnteredToday() );

};


const addCalendarThunk = payload => ( dispatch, getState ) => {

    const state = getState();
    const { viewStart } = payload;
    const user = payload.payload ?? payload.user;

    if ( !has( state.calendar.userMap, user.userId ) ) 
    {

        if ( !viewStart )
            addCalendarToSession( user );

        // The extra day is because we start viewing on monday, not sunday,
        // and dayjs.startOf returns a sunday.
        const startWeek = viewStart ? dayjs( viewStart ) : dayjs().startOf( 'week' ).add( 1, 'day' );

        const minLoaded = startWeek.subtract( 1, 'week' ).toISOString();
        const maxLoaded = startWeek.add( 2, 'weeks' ).toISOString();

        dispatch( setAddUserCalendar({ 
            user, 
            minLoaded, 
            maxLoaded, 
            startWeek:                  startWeek.toISOString()
        }) );

        // initialise view settings; we start with the current 7 day week displayed.
        // Do this before we retrieve appointments, etc, so we can correctly determine
        // if appts exist out of hours.
        dispatch( setCalendarViewDates({
            userId: user.userId,
            start:  startWeek,
            end:    startWeek.add( 1, 'week' )
        }) );

        dispatch( getAppointmentsRequest( user.userId, minLoaded, maxLoaded ) );
        dispatch( getCalendarEventsRequest( user.userId, minLoaded, maxLoaded ) );

        // load the pegboard items for other users
        if ( user.userId !== state.user.userId )
            dispatch( getOtherUserTimeLogsRequest( user.userId ) );

    }
};

const addLocalTimeLogThunk = jobId => async dispatch => {
    // add a placeholder pegboard card; we don't pass a timeLogId when we create the real
    // card, so we can't have this timelog updated, we'll have to remove it when the real one
    // arrives. Just supply the minimum info to display the card in a stopped state; it's too hard
    // to combine a running timer and a fake card and I don't think it's justifiable anyway; it'll
    // start when the job is added, and before then we don't know what was happening.
    const dummyTimeLogId = uuidv4();
    dispatch( setAddTimeLog({
        jobId,
        timeLogId:  dummyTimeLogId,
        pegboard:   true,
        start:      new Date().toISOString(),
        pausedAt:   null
    }) );
    dispatch( setAddLocalTimeLog( dummyTimeLogId ) );
};

export {
    getJobCardAppointments,
    getJobAppointmentHistory,
    setUpdateAppointmentStaticsThunk,
    commonJobActionsThunk,
    deleteUnsavedJobThunk,
    setExistingJobThunk,
    setExistingBusyCardThunk,
    closeCalendarEventThunk,
    initUserSession,
    requestRecordLinks,
    updateMeter,
    addCalendarThunk,
    getTimeEnteredToday,
    getAppointmentsRequest,
    getCalendarEventsRequest,
    getOtherUserTimeLogsRequest,
    updateMeters,
    dispatchTimerUpdate,
    addLocalTimeLogThunk
};
