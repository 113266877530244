import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_REGISTRATION_ENGINEER_NAME, UX_REGISTRATION_PASSWORD } from '../../ux-constants.js';
import { setAdvance, setFormValueRegistration, setTouchedRegistration } from '../../redux/actions/index.js';
import { registrationRoot } from '../../scss/NamePassword.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { onEnter } from '../../utils/misc.js';
import { LicoTextField } from "../common/index.js";
import PasswordField from '../common/PasswordField.jsx';

const NamePasswordForm = ({ engineerName, password, setFormValueRegistration, emailAddress, fieldTouched, fieldError, setTouchedRegistration, setAdvance, strength }) => (
    <form className={registrationRoot}>

        <LicoTextField
            name="engineerName"
            label={__( "Name" )}
            data-ux={UX_REGISTRATION_ENGINEER_NAME}
            autoFocus
            value={engineerName}

            helperText={fieldTouched.engineerName ? fieldError.engineerName : ''}
            error={!!fieldError.engineerName && fieldTouched.engineerName}

            onChange={e => setFormValueRegistration({ field: e.target.name, value: e.target.value })}
            onBlur={e => setTouchedRegistration( e.target.name )}
            onKeyPress={onEnter( setAdvance )}
            autoComplete="on"
        />

        <LicoTextField name="emailAddress" type="email" label={__( "Work email" )} value={emailAddress} disabled={true} />

        <PasswordField
            data-ux={UX_REGISTRATION_PASSWORD}
            password={password}
            strength={strength}
            fieldTouched={fieldTouched}
            fieldError={fieldError}
            setFormValue={setFormValueRegistration}
            setTouched={setTouchedRegistration}
            onKeyPress={onEnter( setAdvance )}
            autoComplete="on"
        />

    </form>
);

NamePasswordForm.propTypes = {
    engineerName:             PropTypes.string.isRequired,
    password:                 PropTypes.string.isRequired,
    emailAddress:             PropTypes.string.isRequired,
    setFormValueRegistration: PropTypes.func.isRequired,
    fieldTouched:             PropTypes.object.isRequired,
    fieldError:               PropTypes.object.isRequired,
    setTouchedRegistration:   PropTypes.func.isRequired,
    setAdvance:               PropTypes.func.isRequired,
    strength:                 PropTypes.object
};

const mapStateToProps = state => ({
    engineerName:   state.registration.engineerName,
    password:       state.registration.password,
    emailAddress:   state.registration.emailAddress,
    fieldTouched:   state.registration.fieldTouched,
    fieldError:     state.registration.fieldError,
    strength:       state.registration.strength
});

const mapDispatchToProps = { setFormValueRegistration, setTouchedRegistration, setAdvance };

export default connect( mapStateToProps, mapDispatchToProps )( NamePasswordForm );
export { NamePasswordForm };
