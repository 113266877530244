import React, { useEffect, useState } from 'react';

import OpenInNewIcon from "@mui/icons-material/OpenInNew.js";
import { FormControl, IconButton, InputAdornment, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Radio } from '@mui/material';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { TableFieldType } from "../../constants.js";
import { adornment, tabCell, tableButton, tableContainer, tableLink } from '../../scss/LicoTable.module.scss';

import LicoButton from "./atomic/form/LicoButton.jsx";
import { headerCellStyle, inputStyle, tableCellLabelStyle, tableCellStyle } from "./LicoTableStyles.jsx";

const EditableCell = ({ id, value, onChange, onNavigate }) => {
    // Handle local state for the editable cell
    const [ cellValue, setCellValue ] = useState( value );

    useEffect( () => {
        setCellValue( value );
    }, [ value ]);

    const handleCellChange = ( event ) => {
        setCellValue( event.target.value );
        onChange( event.target.value );
    };

    return (
        <TextField
            variant="outlined"
            value={cellValue}
            onChange={handleCellChange}
            fullWidth
            size="small"
            sx={inputStyle}
            InputProps={{
                endAdornment: (
                    onNavigate ? (
                        <InputAdornment className={id ? adornment : '${adornment} ${hidden}'}>
                            <IconButton onClick={onNavigate}>
                                <OpenInNewIcon/>
                            </IconButton>
                        </InputAdornment>
                    ) : null
                )
            }}
        />
    );
};

EditableCell.propTypes = {
    id:         PropTypes.string,
    value:      PropTypes.string,
    onChange:   PropTypes.func,
    onNavigate: PropTypes.func
};

const LicoTable = ({ columns, rows, onChange, uniqueKey = 'id', selectedRadioId, onRadioChange }) => {
    return (
        <TableContainer className={tableContainer}>
            <Table aria-label="clients table">
                <TableHead sx={{ 'th, td': headerCellStyle, borderCollapse: 'collapse' }}>
                    <TableRow>
                        {
                            columns?.map( ( column, index ) => (
                                <TableCell key={index} sx={{ ...tableCellLabelStyle, maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>{column.headerName}</TableCell>
                            ) )
                        }
                    </TableRow>
                </TableHead>
                <TableBody sx={{ 'th, td': tableCellStyle, borderCollapse: 'collapse' }}>
                    {rows?.map( ( row, rowIndex ) => {
                        const rowElement = row[ uniqueKey ];
                        return (
                            <TableRow key={rowIndex}>
                                {
                                    columns?.map( ( column, colIndex ) => {
                                        if ( column.type === TableFieldType.SINGLE_SELECT ) {
                                            return (
                                                <TableCell key={colIndex} sx={{ ...tableCellStyle, maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>
                                                    <FormControl fullWidth variant="outlined" size="small" sx={inputStyle}>
                                                        <Select
                                                            displayEmpty
                                                            value={row[ column.field ] !== undefined ? String( row[ column.field ]) : ''}
                                                            onChange={( e ) => {
                                                                const selectedValue = e.target.value;
                                                                let parsedValue;

                                                                if ( selectedValue === 'true' || selectedValue === 'false' )
                                                                    parsedValue = selectedValue === 'true';
                                                                else
                                                                    parsedValue = selectedValue;

                                                                onChange?.( rowElement, column.field, parsedValue );
                                                            }}
                                                            label="Status"
                                                            sx={{ "& .MuiInputLabel-root": { display: "none" }, '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}
                                                            MenuProps={{
                                                                sx: {
                                                                    '& .MuiPaper-root': {
                                                                        borderColor:  'var(--grey-4)',
                                                                        borderWidth:  '1px',
                                                                        borderRadius: '4px',
                                                                        marginTop:    '8px'
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {column.valueOptions.map( ( valueOption ) => (
                                                                <MenuItem value={valueOption.id} key={valueOption.id}>{valueOption?.value}</MenuItem>
                                                            ) )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            );
                                        }
                                        else if ( column.type === TableFieldType.BUTTON ) {
                                            return (
                                                <TableCell key={colIndex} sx={{ maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>
                                                    <Typography variant="body2" className={tabCell}>
                                                        <LicoButton className={tableButton} onClick={() => column.onClick( row[ uniqueKey ], row )}>{row[ column.field ]}</LicoButton>
                                                    </Typography>
                                                </TableCell>
                                            );
                                        } else if ( column.type === TableFieldType.LINK ) {
                                            return (
                                                <TableCell key={colIndex} sx={{ maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>
                                                    <Typography variant="body2">
                                                        <Link to="#" className={tableLink} onClick={() => column.onClick( row[ uniqueKey ], row )}>
                                                            {row[ column.field ]}
                                                        </Link>
                                                    </Typography>
                                                </TableCell>
                                            );
                                        } else if ( column.type === TableFieldType.RADIO ) {
                                            return (
                                                <TableCell key={colIndex} sx={{ maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>
                                                    <Radio
                                                        checked={selectedRadioId === rowElement}
                                                        onChange={() => onRadioChange( rowElement )}
                                                        value={rowElement}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': rowElement }}
                                                    />
                                                </TableCell>
                                            );
                                        } else if ( column.editable ) {
                                            const value = column.renderField ? column.renderField( row ) : row[ column.field ];

                                            return (
                                                <TableCell key={colIndex} sx={{ maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>
                                                    <EditableCell
                                                        id={row[ uniqueKey ]}
                                                        value={value}
                                                        onNavigate={column?.onNavigate ? () => column?.onNavigate( row[ uniqueKey ]) : null}
                                                        onChange={( text ) => onChange?.( row[ uniqueKey ], column.field, text )}
                                                    />
                                                </TableCell>
                                            );
                                        } else {
                                            return (
                                                <TableCell key={colIndex} sx={{ maxWidth: column?.flex === 1 ? "100%" : ( column.width || 200 ) }}>{row[ column.field ]}</TableCell>
                                            );
                                        }
                                    })
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

LicoTable.propTypes = {
    columns:         PropTypes.array,
    rows:            PropTypes.array,
    onChange:        PropTypes.func,
    uniqueKey:       PropTypes.string,
    selectedRadioId: PropTypes.string,
    onRadioChange:   PropTypes.func
};

export default LicoTable;
