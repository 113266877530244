/********************************************************************************************************************
 * @file Actions for the team store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Jul-2023
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setTeams = createAction( 'TEAM.TEAMS/SET' );
export const showNewTeam = createAction( 'TEAM.SHOW_NEW_TEAM/SET' );
export const addTeam = createAction( 'TEAM.ADD_TEAM/SET' );
export const editTeam = createAction( 'TEAM.EDIT_TEAM/SET' );
export const changeTeam = createAction( 'TEAM.CHANGE_TEAM/SET' );
export const removeTeam = createAction( 'TEAM.REMOVE_TEAM/SET' );
export const addTeamMember = createAction( 'TEAM.ADD_TEAM_MEMBER/SET' );
export const removeTeamMember = createAction( 'TEAM.DELETE_TEAM_MEMBER/SET' );
export const setTeamError = createAction( 'TEAM.TEAM_ERROR/SET' );
export const setTeamEngineers = createAction( 'TEAM.ENGINEERS/SET' );

