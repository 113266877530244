import { ResourceUploadStages } from '@licoriceio/constants';
import { v4 as uuidv4 } from 'uuid';

import { uri } from "../../constants";
import { setCurrentJobCardId, setJobCardDetails, setJobCardEngineers, setJobCardUsers, setFileUploadStart } from "../../redux/actions/index.js";
import { BACKEND_URL } from "../../services/util/baseRequests.js";
import { deleteUnsavedJobThunk, closeCalendarEventThunk } from '../calendar/sharedThunks.js';

const setNewJobThunk = payload => async ( dispatch, getState ) => {

    const { user, user: { userId }, jobcard: { currentJobCardId } } = getState();

    dispatch( deleteUnsavedJobThunk( currentJobCardId ) );

    // we might have had a CE open
    dispatch( closeCalendarEventThunk() );

    // immediately clear previous state and open (or close) the job card
    dispatch( setCurrentJobCardId({ jobId: payload.jobId, userId }) );

    // remember how the job card was opened and pass thru the default billable state set when the new job was created.
    // Also, stop waiting for a draft message, which won't exist for a new job.
    dispatch( setJobCardDetails({ newJobDetails: payload, billable: payload.billable, draftMessageLoading: false }) );

    // set the engineer automatically; can't be deleted until the job is created
    dispatch( setJobCardEngineers([ user ]) );

    // clear users
    dispatch( setJobCardUsers([]) );
};

const addFile = ({ formData, addToEditor }) => ( dispatch, getState ) => {
    const { auth, jobcard: { currentJobCardId } } = getState();

    const fd  = new FormData();

    // any data fields must be added before the file
    fd.append( 'jobId', currentJobCardId );
    const fileKey = uuidv4();
    fd.append( 'fileKey', fileKey );
    fd.append( 'fileSize', formData.file.size );
        
    for ( const [ name, value ] of Object.entries( formData ) )
        fd.append( name, value );

    const options = {
        method:     "POST",
        body:       fd,
        headers:    {
            Authorization: `Bearer ${auth.token}`
        }
    };

    // we can't wait for the request to return because for large files 
    // we get the progress messages before that happens.
    dispatch( setFileUploadStart({ fileKey, jobId: currentJobCardId, progress: ResourceUploadStages.started, loaded: 0 }) );

    fetch( `${BACKEND_URL}${uri.FILE}`, options )
        .then( async r => {
            if ( r.ok ) 
                addToEditor( fileKey );
            else
                console.error( 'request failed', r.statusText );
        })
        .catch( error => {
            console.error( 'request threw error', error );
        });

};


export {
    setNewJobThunk,
    addFile
};
