import React from 'react';

import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_SETTINGS_ORGANISATION_CLIENT_CHAT } from '../../../ux-constants.js';
import { 
    setSettingValueOrganisation, setAddOrganisationBusinessHours, setRemoveOrganisationBusinessHours, discardOrganisationSettingChanges 
} from '../../../redux/actions/index.js';
import { settings, screenTitle, boxLabel, infoIcon, flexColumn, centredFlex, urlClass, logo } from '../../../scss/Settings.module.scss';
import { __ } from "../../../utils/i18n.jsx";
import BusinessHoursList from '../../common/BusinessHoursList.jsx';
import { H1, LicoSwitch, LicoIcon } from "../../common/index.js";
import NumberDropdown from '../../common/NumberDropdown.jsx';
import PreventTransitionPrompt from "../../common/PreventTransitionPrompt.jsx";
import SettingsLayout from '../../layouts/SettingsLayout.jsx';
import { SettingsSection, SettingsBox, SaveButton } from '../utilities.jsx';

import { saveOrganisationSettingChanges } from './reducer.js';


const OrganisationSettings = ({
    businessHours,
    minimumTimeEntry,
    twoFactorAuthenticationRequired,
    emailContact,
    logoURL,
    foundChanges,
    setSettingValueOrganisation,
    setAddOrganisationBusinessHours,
    setRemoveOrganisationBusinessHours,
    saveOrganisationSettingChanges,
    discardOrganisationSettingChanges,
    history,
    fieldError,
    errorCount
}) => <SettingsLayout>

    <PreventTransitionPrompt
        when={foundChanges}
        history={history}
        saveAction={saveOrganisationSettingChanges}
        saveDisabled={errorCount > 0}
        discardAction={discardOrganisationSettingChanges}
    />

    <div className={settings}>
        <H1 className={screenTitle}>{__( "Organisation" )}</H1>
        <SettingsSection label={ __( "Client Chat" ) }>
            <LicoSwitch
                name="emailContact"
                dataName={UX_SETTINGS_ORGANISATION_CLIENT_CHAT}
                checked={emailContact}
                onChange={( e, checked ) => setSettingValueOrganisation({
                    name:      e.target.name,
                    value:     checked
                })}
            />
            {__( "Configure Integration(s) to email Client Chat" )}
            <LicoIcon className={infoIcon} icon="info" tooltip={__( "If enabled, Client Chat messages from the Integration platform will be emailed to Licorice users" )}/>

        </SettingsSection>
        <SettingsSection label={ __( "Time Entry" ) }>
            {businessHours &&
                <SettingsBox label={__( "Work days" )} topLabel>
                    <BusinessHoursList
                        businessHours={businessHours}
                        setValue={setSettingValueOrganisation}
                        addItem={setAddOrganisationBusinessHours}
                        removeItem={setRemoveOrganisationBusinessHours}
                        error={fieldError.businessHours}
                    />
                </SettingsBox>
            }
            {minimumTimeEntry &&
                <NumberDropdown
                    value={{ minimumTimeEntry }}
                    label={__( "Time entry rounding" )}
                    options={[ 1, 6, 10, 15, 30 ].map( value => ({ id: value, label: value === 1 ? __( 'None' ) : __( '{value} minutes', { value }) }) )}
                    labelClassName={boxLabel}
                    inputClassName='slim'
                    setValue={setSettingValueOrganisation}
                />
            }
        </SettingsSection>
        <SettingsSection label={ __( "Security" ) }>
            {twoFactorAuthenticationRequired !== undefined && (
                <>
                    <LicoSwitch
                        name="twoFactorAuthenticationRequired"
                        checked={twoFactorAuthenticationRequired}
                        onChange={( e, checked ) => setSettingValueOrganisation({
                            name:      e.target.name,
                            value:     checked
                        })}
                    />
                    {__( "Require 2-factor authentication for engineers" )}
                </>
            )}
        </SettingsSection>
        <SettingsSection label={ __( "Organisation" ) }>
            <SettingsBox label={__( "Logo URL" )}>
                <div className={flexColumn}>
                    <div className={centredFlex}>
                        <TextField
                            name="logoURL"
                            value={logoURL}
                            className={urlClass}
                            onChange={( e ) => setSettingValueOrganisation({
                                name:      e.target.name,
                                value:     e.target.value
                            })}
                            variant="outlined"
                            size="small"
                        />
                        <LicoIcon className={infoIcon} icon="info" tooltip={__( "The image referenced by this URL will be used as the organisation logo." )} />
                    </div>

                    {/* Without the span, the image stretches to its max extents instead of just being limited by them */}
                    <span>{logoURL && <img className={logo} src={logoURL} alt={__( "Logo URL not valid" )}/>}</span>
                </div>
            </SettingsBox>
        </SettingsSection>
        <SaveButton handler={saveOrganisationSettingChanges} disabled={!foundChanges || errorCount > 0} />
    </div>
</SettingsLayout>;

OrganisationSettings.propTypes = {
    businessHours:                      PropTypes.array,
    minimumTimeEntry:                   PropTypes.number,
    foundChanges:                       PropTypes.bool,
    emailContact:                       PropTypes.bool,
    logoURL:                            PropTypes.string,
    setSettingValueOrganisation:        PropTypes.func.isRequired,
    setAddOrganisationBusinessHours:    PropTypes.func.isRequired,
    setRemoveOrganisationBusinessHours: PropTypes.func.isRequired,
    saveOrganisationSettingChanges:     PropTypes.func.isRequired,
    discardOrganisationSettingChanges:  PropTypes.func.isRequired,
    history:                            PropTypes.object.isRequired,
    twoFactorAuthenticationRequired:    PropTypes.bool,
    fieldError:                         PropTypes.object.isRequired,
    errorCount:                         PropTypes.number.isRequired
};

const mapStateToProps = state => {

    const { organisation: { foundChanges, fieldError, edit } } = state || {};
    const errorCount = Object.values( fieldError ).filter( a => a ).length;
    if ( !edit )
        return { fieldError, errorCount };

    const { businessHours, minimumTimeEntry, twoFactorAuthenticationRequired, emailContact, logoURL } = edit;

    return {
        foundChanges,
        fieldError,
        errorCount,
        businessHours,
        minimumTimeEntry,
        twoFactorAuthenticationRequired,
        emailContact,
        logoURL
    };
};

const mapDispatchToProps = {
    setSettingValueOrganisation,
    setAddOrganisationBusinessHours,
    setRemoveOrganisationBusinessHours,
    saveOrganisationSettingChanges,
    discardOrganisationSettingChanges
};

export default connect( mapStateToProps, mapDispatchToProps )( OrganisationSettings );
export { OrganisationSettings };
