import { applyMiddleware, createStore, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import { publishStore } from './publicStore.js';
import rootReducer from './redux/reducers.js';

const persistConfig = {
    key:       'root',
    storage,
    whitelist:  []
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer( persistConfig, rootReducer );

const middlewares = [ thunk ];
const middlewareEnhancer = applyMiddleware( ...middlewares );
const enhancers = [ middlewareEnhancer ];
// Enable these two lines to get the ability to trace in DevTools Redux
// const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
// const composedEnhancers = composeEnhancers( ...enhancers );
// And comment out the one below
// const composedEnhancers = composeWithDevTools( ...enhancers );   // Removed to avoid using the redux-devtools-extension in production
const composedEnhancers = composeEnhancers( ...enhancers );

export const setupStore = preloadedState => {
    return publishStore( createStore( persistedReducer, preloadedState, composedEnhancers ) );
};

// export const store = setupStore();
// export const persistor = persistStore( store );
