
import kindOf from 'kind-of';
import { restToArray } from './array-helpers.js';
import { identity } from './func-helpers.js';
import { isFunc, isObject, isTypedArray } from './type-helpers.js';

/**
 * Creates a new object with the specified keys
 * and their corresponding values from the original object.
 *
 * @param {Object} obj - The original object.
 * @param {Array} keys - The keys to pick from the original object.
 * @return {Object} - A new object containing the picked keys and values.
 */
function pick( obj, keys )
{
    const lng    = keys.length;
    const result = {};
    let index    = -1;

    while ( ++index < lng )
    {
        const key     = keys[ index ];
        result[ key ] = obj[ key ];
    }

    return result;
}

/**
 * @param {{ [ s: string ]: string}} pickMap
 * @param {object} [obj]
 * @param {object} [dest]
 * @return {{}|(function({}, {}=): {})}
 */
function copyProps( pickMap, obj, dest = {})
{
    for ( const [ destKey, srcKey ] of Object.entries( pickMap ) )
        dest[ destKey ] = obj[ srcKey ];

    return dest;
}

const _addProp = ( acc, src, prop ) => {
    if ( Object.hasOwn( src, prop ) ) acc[ prop ] = src[ prop ];
    return acc;
};

/**
 * @param {object} obj
 * @param {string[]} keys
 * @return {object}
 */
const pickDefd = ( obj, ...keys ) => keys.flat().reduce( ( res, key ) => _addProp( res, obj, key ), {});


/**
 * @param {object} obj
 * @param {string[]} keys
 * @return {object}
 */
function omit( obj, ...keys )
{
    const badKeys = new Set( restToArray( keys ) );

    return pickDefd( obj, Object.keys( obj ).filter( key => !badKeys.has( key ) ) );
}

/**
 * @param {object} obj
 * @param {string|string[]} path
 * @param {any} [def]
 * @return {*}
 */
const getAtPath = ( obj, path, def ) => ( Array.isArray( path ) ? path : path.replace( /\[\s*(\d+)\s*]/g, '.$1.' ).split( /\.+/ ) )
    .filter( Boolean )
    .reduce( ( o, key ) => o?.[ /^\d+$/.test( key ) ? Number( key ) : key ], obj ) ?? def;

/**
 * @param {object} o
 * @param {string} [watchKey]
 * @return {null|string}
 */
function oneKey( o, watchKey )
{
    if ( !isObject( o ) ) return null;

    const keys = Object.keys( o );

    if ( keys.length !== 1 ) return null;

    return watchKey == null || ( watchKey && keys[ 0 ] === watchKey ) ? keys[ 0 ] : void 0;
}

/**
 * @param {string[]|object} keys
 * @param {function} val
 * @return {object}
 */
const keysToObject = ( keys, val = identity ) => ( Array.isArray( keys ) ? keys : Object.keys( keys ) )
    .reduce( ( obj, key ) => {
        const v = val( key );

        return { ...obj, ...( v !== void 0 ? { [ key ]: v } : {}) };
    }, {});

/**
 * @param {object} dest
 * @param {object} src
 * @return {object}
 */
const orderedBlend = ( dest, src ) => Object.entries( src ).reduce( ( d, [ key, value ]) => {
    d[ key ] = value;
    return d;
}, dest );

const isMergeable = val => isFunc( val ) || isObject( val );
const isValidKey  = key => key !== '__proto__' && key !== 'constructor' && key !== 'prototype';

const mixinDeep = ( target, ...rest ) => {
    for ( const obj of rest )
    {
        if ( !isMergeable( obj ) ) continue;

        const props = Object.getOwnPropertyDescriptors( obj );

        for ( const [ key, desc ] of Object.entries( props ) )
        {
            if ( isValidKey( key ) )
                mixin( target, desc, key );
        }
    }

    return target;
};

/**
 * Mixin function to add properties from one object to another.
 * It can either shallowly merge the properties or deeply merge if both objects are
 * nested objects.
 *
 * @param {object} target - The target object to receive the properties.
 * @param {object} desc - The descriptor object containing the properties to be added.
 * @param {string} key - The key of the property being added to the target object.
 *
 * @return {void}
 */
function mixin( target, desc, key )
{
    let obj = target[ key ];

    if ( isObject( desc.value ) && isObject( obj ) )
        mixinDeep( obj, desc.value );
    else
        Object.defineProperty( target, key, desc );
}

/**
 * @param {object} o
 * @param {...string} emptyKeys
 * @return {object}
 */
function trimEmpty( o, ...emptyKeys )
{
    if ( !isObject( o ) ) return o;

    const t = {};

    for ( const [ name, value ] of Object.entries( o ) )
    {
        if ( value == null || emptyKeys.includes( name ) ) continue;
        if ( isObject( value ) )
            t[ name ] = trimEmpty( value );
        else
            t[ name ] = value;
    }

    return t;
}

/** @private */
const recurseInto = [ 'function', 'object', 'array', 'map', 'set' ];

/**
 * @param {object} top
 * @param {{ enter: function=, exit: function= }} opts
 */
function deepWalk( top, opts )
{
    const seen = new Set();
    const {
        enter,
        exit,
        ignoreSymbols = false
    }    = opts;

    function _walker( main, parent, path )
    {
        const selfType = kindOf( main );
        if ( !recurseInto.includes( selfType ) ) return;
        if ( seen.has( main ) ) return;

        enter?.( main, parent, path, selfType );

        if ( selfType === 'array' )
            main.forEach( ( el, i ) => _walker( el, main, path.concat( i ) ) );
        else if ( selfType === 'map' )
        {
            for ( const [ key, value ] of main )
                _walker( value, main, path.concat( key ) );
        }
        else if ( selfType === 'set' )
        {
            for ( const value of main )
                _walker( value, main, path.concat( undefined ) );
        }
        else if ( selfType === 'object' || selfType === 'function' )
        {
            let keys = Object.getOwnPropertyNames( main );
            if ( !ignoreSymbols )
                keys = [ ...keys, ...Object.getOwnPropertySymbols( main ) ];

            for ( const key of keys )
                _walker( main[ key ], main, path.concat( key ) );
        }

        exit?.( main, parent, path, selfType );
    }

    _walker( top, null, []);
}

/**
 * @param {object} o
 * @param {string|string[]} path
 * @return {*}
 */
const deepRead  = ( o, path ) => ( Array.isArray( path ) ? path : path.split( '.' ) ).reduce( ( cur, key ) => cur?.[ key ], o );

/**
 * Writes a value to a nested property in an object based on a given path.
 *
 * @param {Object} o - The object to write to.
 * @param {string|string[]} path - The path to the property to write to. Can be either a dot-separated string or an
 *     array of keys.
 * @param {*} value - The value to write to the property.
 * @return {*} - The value that was written to the property.
 */
function deepWrite( o, path, value )
{
    const p   = Array.isArray( path ) ? [ ...path ] : path.split( '.' );
    const key = p.pop();

    p.reduce( ( cur, key ) => cur?.[ key ] || ( cur[ key ] = {}), o )[ key ] = value;

    return value;
}

/**
 * The _compareEntries function takes functions as arguments to get the entries, keys, individual entry values, check
 * if an entry exists, and get the length of an object. It returns a comparison function.
 *
 * For objects, it gets the entries of the first value as a key-value array. It iterates over the entries, checking if
 * each key exists in the second value and the values are deeply equal. It tracks checked keys in a Set.
 *
 * It then iterates the keys of the second value and removes them from the checked set. If the checked set is empty
 * after, all keys were matched and values equal, so the objects are equal.
 *
 * @param {Function} getEntries - A function that takes an object and returns its entries as a key-value array.
 * @param {Function} getKeys - A function that takes an object and returns its keys as an array.
 * @param {Function} getEntry - A function that takes an object and a key and returns the corresponding entry value.
 * @param {Function} hasEntry - A function that takes an object and a key and checks if the entry exists.
 * @param {Function} getLength - A function that takes an object and returns the number of entries.
 * @return {(function(*, *): boolean)|*} - A comparison function that takes two objects and returns true if they are
 *     equal, false otherwise.
 * @private
 */
function _compareEntries( getEntries, getKeys, getEntry, hasEntry, getLength )
{
    return ( a, b ) => {
        const akv     = getEntries( a );
        const checked = new Set();

        if ( akv.length !== getLength( b ) ) return false;

        for ( const [ key, value ] of akv )
        {
            if ( !hasEntry( b, key ) || !compareDeep( value, getEntry( b, key ) ) ) return false;
            checked.add( key );
        }

        for ( const bkey of getKeys( b ) )
            checked.delete( bkey );

        return checked.size === 0;
    };
}

/**
 * `cmpObj` and `cmpMap` call `_compareEntries` to get comparison functions specialized for objects and maps.
 *
 * @type {(function(*, *): boolean)|*}
 */
const cmpObj = _compareEntries( Object.entries, Object.keys, ( o, k ) => o[ k ], ( o, k ) => Object.hasOwn( o, k ), o => Object.keys( o ).length );
const cmpMap = _compareEntries( x => [ ...x ], x => [ ...x.keys() ], ( o, k ) => o.get( k ), ( o, k ) => o.has( k ), o => o.size );

/**
 * A collection of comparison functions for various data types.
 * @type {object}
 * @property {function} string   - Returns true if both values are of type string and have the same value.
 * @property {function} number   - Returns true if both values are of type number and have the same value, or both are
 *     NaN.
 * @property {function} boolean  - Returns true if both values are of type boolean and have the same value.
 * @property {function} null     - Returns true if the second value is null.
 * @property {function} undefined - Returns true if the second value is undefined.
 * @property {function} bigint   - Returns true if both values are of type bigint and have the same value.
 * @property {function} symbol   - Returns true if the second value is of type symbol and has the same symbol key.
 * @property {function} date     - Returns true if the second value is an instance of Date and has the same time.
 * @property {function} set      - Returns true if the second value is an instance of Set, has the same size, and
 *     contains the same elements.
 * @property {function} map      - Returns true if the second value is an instance of Map, has the same size, and
 *     contains the same key-value pairs.
 * @property {function} weakset  - Returns true if the second value is an instance of WeakSet.
 * @property {function} weakmap  - Returns true if the second value is an instance of WeakMap.
 * @property {function} array    - Returns true if the second value is an array, has the same length, and each element
 *     is deeply equal to the corresponding element in the first value.
 * @property {function} typedarray - Returns true if the second value is a TypedArray, has the same length, and each
 *     element is deeply equal to the corresponding element in the first value.
 * @property {function} object   - Returns true if the second value is an object, and each property in the first value
 *     is deeply equal to the corresponding property in the second value
 *.
 * @type {ICmps}
 */
const cmps = {
    /**
     * Checks if two values are equal and both are of type string.
     *
     * @param {string} a - The first value to compare.
     * @param {any} b - The second value to compare.
     * @returns {b is string} - Returns true if both values are equal and of type string, otherwise false.
     */
    string:     ( a, b ) => kindOf( b ) === 'string' && a === b,
    /**
     * Checks if two numbers are equal, handling NaN values correctly.
     *
     * @param {number} a - The first number to compare.
     * @param {any} b - The second number to compare.
     * @returns {b is number} - Returns true if the numbers are equal or both NaN, otherwise false.
     */
    number:     ( a, b ) => ( Number.isNaN( a ) && Number.isNaN( b ) ) || a === b,
    /**
     * Determines if two values are equal.
     *
     * @param {boolean} a - The first value to compare.
     * @param {any} b - The second value to compare.
     * @returns {b is boolean} - True if the values are equal, False otherwise.
     */
    boolean:    ( a, b ) => a === b,
    /**
     * Checks if a given value is null
     * @param {null} a - The value to be checked
     * @param {any} b - The value to compare with null
     * @returns {b is null} - True if the given value is null, false otherwise
     */
    null:       ( a, b ) => b === null,
    /**
     * Determines if the provided value is undefined.
     *
     * @param {undefined} a - The value to compare.
     * @param {any} b - The value to check for undefined.
     * @returns {b is undefined} - True if the value is undefined, false otherwise.
     */
    undefined:  ( a, b ) => b === undefined,
    /**
     * Determines if the given value is a bigint and equal to another value.
     *
     * @param {bigint} a - The first value to compare.
     * @param {any} b - The second value to compare.
     * @returns {b is bigint} - Returns true if the values are bigints that are equal, otherwise returns false.
     */
    bigint:     ( a, b ) => typeof b === 'bigint' && a === b,
    /**
     * Checks if two symbols are equal.
     *
     * @param {symbol} a - The first symbol to compare.
     * @param {any} b - The second symbol to compare.
     * @returns {b is symbol} - Returns true if the symbols are equal, otherwise false.
     */
    symbol:     ( a, b ) => kindOf( b ) === 'symbol' && Symbol.keyFor( a ) === Symbol.keyFor( b ),
    /**
     * Checks if two objects are equal dates.
     *
     * @param {object} a - The first date object to compare.
     * @param {any} b - The second date object to compare.
     * @returns {b is Date} - True if the two date objects are equal, false otherwise.
     */
    date:       ( a, b ) => b instanceof Date && a.getTime() === b.getTime(),
    /**
     * Checks if two sets are equal by comparing their size and symmetric difference.
     *
     * @param {Set<T>} a - The first set.
     * @param {any} b - The second set being compared against the first set.
     * @returns {b is Set<T>} - True if the sets are equal, false otherwise.
     */
    set:        ( a, b ) => b instanceof Set && a.size === b.size && a.symmetricDifference( b ).size === 0,
    /**
     * Compares two Map objects for equality.
     *
     * @param {Map<K, V>} a - The first Map object.
     * @param {any} b - The second Map object.
     * @returns {b is Map<K, V>} - Whether the two Map objects are equal.
     */
    map:        ( a, b ) => b instanceof Map && a.size === b.size && cmpMap( a, b ),
    /**
     * Determines if the provided value is an instance of WeakSet.
     *
     * @param {WeakSet<any>} a - The value to be checked.
     * @param {any} b - The value to be checked.
     * @returns {b is WeakSet<any>} - True if the value is an instance of WeakSet, false otherwise.
     */
    weakset:    ( a, b ) => b instanceof WeakSet,
    /**
     * Checks if the given object is an instance of WeakMap.
     *
     * @param {WeakMap<any, any>} a - The object to be checked.
     * @param {any} b - The object to be checked against WeakMap instance.
     * @returns {b is WeakMap<any, any>} - Returns true if the object is an instance of WeakMap, otherwise false.
     */
    weakmap:    ( a, b ) => b instanceof WeakMap,
    /**
     * Checks if two arrays are deeply equal.
     *
     * @param {Array<T>} a - The first array to compare.
     * @param {any} b - The second array to compare.
     * @returns {b is Array<T>} - True if the arrays are deeply equal, false otherwise.
     */
    array:      ( a, b ) => Array.isArray( b ) && a.length === b.length && a.every( ( x, i ) => compareDeep( x, b[ i ]) ),
    /**
     * Checks if two arrays are equal, element by element, and both are TypedArrays.
     *
     * @param {TypedArray<T>} a - The first TypedArray to compare.
     * @param {any} b - The second TypedArray to compare.
     * @returns {b is TypedArray<T>} - Returns true if the two arrays are equal and both are TypedArrays, otherwise
     *     false.
     */
    typedarray: ( a, b ) => isTypedArray( b ) && a.length === b.length && a.every( ( x, i ) => compareDeep( x, b[ i ]) ),
    /**
     * Checks if the given `b` value is an object and if it is of the same kind as `a`.
     *
     * @param {object} a - The first value to compare.
     * @param {any} b - The second value to compare.
     * @returns {b is object} - `true` if `b` is an object and of the same kind as `a`, `false` otherwise.
     */
    object:     ( a, b ) => kindOf( b ) === 'object' && cmpObj( a, b )
};

/**
 * This code is implementing a deep comparison function to recursively compare two JavaScript values and determine if
 * they are equal.
 *
 * The `compareDeep` function gets the type of the first value, checks for typed arrays, and calls the corresponding
 * comparison function in `cmps`, recursively comparing values until a mismatch is found or types are fully compared.
 *
 * This allows deeply comparing any two JavaScript values while handling all the built-in types and common
 * constructors.
 *
 * @param {any} a
 * @param {any} b
 * @return {boolean}
 */
function compareDeep( a, b )
{
    let type = kindOf( a );

    if ( typeof a === 'object' && isTypedArray( a ) )
        type = 'typedarray';

    return cmps[ type ]( a, b );
}

function stripKeysDeep( obj, keys )
{
    if ( kindOf( obj ) === 'array' ) return obj.map( v => stripKeysDeep( v, keys ) );

    if ( kindOf( obj ) !== 'object' ) return obj;

    const target = {};

    for ( const [ key, value ] of Object.entries( obj ) )
    {
        if ( Array.isArray( keys ) ? keys.includes( key ) : keys === key ) continue;

        if ( kindOf( obj ) === 'array' )
            target[ key ] = value.map( v => stripKeysDeep( v, keys ) );
        else if ( kindOf( obj ) === 'object' )
        {
            if ( key.endsWith( 'Reference' ) && kindOf( value ) === 'object' && Object.hasOwn( value, 'id' ) )
                target[ key ] = { id: value.id };
            else
                target[ key ] = stripKeysDeep( value, keys );
        }
        else
            target[ key ] = value;
    }

    return target;
}

export {
    stripKeysDeep,
    deepWrite,
    deepRead,
    deepWalk,
    trimEmpty,
    mixinDeep,
    mixin,
    pick,
    keysToObject,
    orderedBlend,
    oneKey,
    copyProps,
    pickDefd,
    omit,
    getAtPath
};
