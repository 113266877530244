import React, { useEffect } from 'react';

import { NotificationTypes } from "@licoriceio/constants";
import { LinearProgress, Grid, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setMeta } from '../../redux/actions/index.js';
import { getJobFromState, getJobCompany } from '../../redux/selectors/index.js';
import * as scss from '../../scss/Notification.module.scss';
import { getAvatarItemFromName } from '../../utils/common-types.js';
import { __, _$ } from "../../utils/i18n.jsx";
import { getJobActionThunk, setPatchAppointmentThunk } from '../calendar/thunks.js';
import { LicoButton, LicoFab, LicoIcon } from '../common/index.js';
import LicoAvatar from '../common/LicoAvatar.jsx';
import TimeDisplay from '../common/notifications/TimeDisplay.jsx';
import { SETTINGS, SETTINGS_ENGINEER } from '../navigation/routes.js';

import { notificationViewed, avatarType } from './reducer.js';


dayjs.extend( localizedFormat );

const {
    root,
    message,
    avatarRow,
    avatarImg,
    buttons,
    closeFab,
    closeIcon,
    dangerButton,
    time,
    link,
    lightBackground,
    progressGrid,
    progressBar,
    inProgress
} = scss;

/**
 * @typedef {object} NotificationParentProps
 * @property {ExtendedNotification} notification
 * @property {string} userId
 * @property {string} [avatar]
 * @property {any} key
 * @property {NotificationResponse} response
 */

/** */

const JobLink = ({ notification, jobTitle, companyName, jobDescription, getJobActionThunk }) => {

    return <span className={link}
        onClick={() => getJobActionThunk({ jobId: notification.data.jobId, openJobCard: true })}>
        {jobTitle || companyName || jobDescription || notification.data?.jobTitle || __( 'Job' )}
    </span>;
};

JobLink.propTypes = {
    notification:       PropTypes.object.isRequired,
    jobTitle:           PropTypes.string,
    companyName:        PropTypes.string,
    jobDescription:     PropTypes.string,
    getJobActionThunk:  PropTypes.func.isRequired
};

const SettingsLink = () => <Link className={link} to={SETTINGS}>{__( "Go to settings" )}</Link>;
const SettingsEngineerLink = filter => <Link className={link} to={SETTINGS_ENGINEER + `?filter=${filter}`}>{__( "Go to Engineer settings" )}</Link>;

const EngineerAdded = ({ notification, providerName }) => <span>{_$( `Engineer {name} has been added on {providerName}. {settings}`, {
    ...notification.data,
    settings: SettingsEngineerLink( notification.data.name ),
    providerName
})}</span>;

EngineerAdded.propTypes = {
    notification:   PropTypes.object.isRequired,
    providerName:   PropTypes.string.isRequired
};

const EngineerReactivated = ({ notification, providerName }) => <span>{_$( `Engineer {name} has been reactivated on {providerName}. {settings}`, {
    ...notification.data,
    settings: SettingsEngineerLink( notification.data.name ),
    providerName
})}</span>;

EngineerReactivated.propTypes = {
    notification:   PropTypes.object.isRequired,
    providerName:   PropTypes.string.isRequired
};

const EngineerDeactivated = ({ notification, providerName }) => <span>{_$( `Engineer {name} has been deactivated on {providerName}. {settings}`, {
    ...notification.data,
    settings: SettingsEngineerLink( notification.data.name ),
    providerName
})}</span>;

EngineerDeactivated.propTypes = {
    notification:   PropTypes.object.isRequired,
    providerName:   PropTypes.string.isRequired
};


const AppointmentAssignedContent = props => <span>{_$( `{assigner} assigned {linky} to you.`, {
    ...props.notification.data,
    linky: JobLink( props )
})}</span>;

AppointmentAssignedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const AppointmentChangedContent = props => {

    const tokens = {
        ...props.notification.data,
        linky: JobLink( props )
    };
    const actionMessage = {
        create:     _$( '{userName} added an appointment for {linky}.', tokens ),
        change:     _$( '{userName} altered your appointment for {linky}.', tokens ),
        cancel:     _$( '{userName} cancelled your appointment for {linky}.', tokens ),
        reschedule: _$( '{userName} rescheduled your appointment for {linky}.', tokens )
    };
    const message = actionMessage[ props.notification.data.action ];

    return <span>{message}</span>;
};

AppointmentChangedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const AppointmentDeletedContent = props => {

    const startDate = dayjs( props.notification.data.startDate );

    const tokens = {
        ...props.notification.data,
        startDateStr:   startDate.format( 'ddd, MMM D' ),
        startTimeStr:   startDate.format( 'LT' ),
        linky:          JobLink({ ...props, jobTitle: props.notification.data.jobTitle })
    };

    const message = _$( 'Your appointment for {linky} at {startTimeStr} on {startDateStr} was deleted on the provider.', tokens );

    return <span>{message}</span>;
};

AppointmentDeletedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const AppointmentRejectedContent = props => <span>{_$( `{userName} rejected job {linky}.`, {
    ...props.notification.data,
    linky: JobLink( props )
})}</span>;

AppointmentRejectedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const highlightTypes = new Set([
    NotificationTypes.APPOINTMENT_CHANGED,
    NotificationTypes.APPOINTMENT_ASSIGNED,
    NotificationTypes.APPOINTMENT_REJECTED
]);

const IntegrationCompleteContent = props => <span>{_$( "Done! Licorice is ready to use with {provider}.", { provider: props.providerName })}</span>;

IntegrationCompleteContent.propTypes = {
    providerName: PropTypes.string.isRequired
};

const IntegrationFailedContent = props => <span>{_$( "Integration with {provider} failed.", { provider: props.notification.data.provider })}</span>;

IntegrationFailedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const JobContent = props => {
    const {
        type,
        data
    } = props.notification;

    return <span>{type === NotificationTypes.JOB_CREATED
        ? _$( "{name} created job {linky} for {companyName}", { ...data, linky: JobLink( props ), ...props })
        : _$( "{name} changed status to {status} for {linky} for {clientName}", { ...data, linky: JobLink( props ) })}</span>;
};

JobContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const JobDeletedContent = props => {

    const tokens = {
        ...props.notification.data
    };

    const message = tokens.deleterName
        ? _$( 'Job {title} was deleted by {deleterName}.', tokens )
        : _$( 'Job {title} was deleted on the provider.', tokens );

    return <span>{message}</span>;
};

JobDeletedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const ReopenedJobContent = props => {
    const params = {
        ...props.notification.data,
        linky:  JobLink( props ),
        client: props.notification.data.clientName
    };

    return <span>{_$( `{linky} for {client} was reopened.`, params )}</span>;
};

ReopenedJobContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const ResolvedJobContent = props => {
    const params = {
        ...props.notification.data,
        linky:  JobLink( props ),
        client: props.notification.data.clientName
    };

    return <span>{_$( `{linky} for {client} was resolved.`, params )}</span>;
};

ResolvedJobContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const JobEngineerChangedContent = props => {
    const { action, names } = props.notification.data;

    const tokens = {
        names:  names.join( ', ' ),
        name:   names[ 0 ],
        linky:  JobLink( props )
    };
    const actionMessage = {
        add:        names.length === 1 
            ? _$( '{name} was assigned to {linky}.', tokens )
            : _$( 'These Engineers were assigned to {linky}: {names}.', tokens ),
        delete:     names.length === 1 
            ? _$( '{name} was removed from {linky}.', tokens )
            : _$( 'These Engineers were removed from {linky}: {names}.', tokens )
    };
    const message = actionMessage[ action ];

    return <span>{message}</span>;
};

JobEngineerChangedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const JobNoteAddedContent = props => {
    const params = {
        ...props.notification.data,
        linky:  JobLink( props )
    };

    return <span>{_$( `{name} added a note for {linky}.`, params )}</span>; };

JobNoteAddedContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const UnknownTypeContent = ({ notification }) => <span>{notification.type}</span>;

UnknownTypeContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const ErrorTypeContent = ({ notification }) => <span>{_$( "Provider error: {message}", { message: notification.data.message })}</span>;

ErrorTypeContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const MissingJobStatusContent = props => {
    const params = {
        ...props.notification.data,
        linky:  JobLink( props )
    };

    return <span>{_$( `Provider status for '{statusName}' for {linky} not on service board.`, params )}</span>; 
};

MissingJobStatusContent.propTypes = {
    notification: PropTypes.object.isRequired
};


const UnselectedProviderStatusContent = props => {
    const params = {
        ...props.notification.data,
        linky:  JobLink( props )
    };

    return <span>{_$( `{linky} was assigned an unselected provider status '{statusName}'.`, params )}</span>; 
};

UnselectedProviderStatusContent.propTypes = {
    notification: PropTypes.object.isRequired
};

const SyncProgress = withStyles( ( ) => ({
    root: {
        height:       9,
        borderRadius: 50
    },
    colorPrimary: {
        backgroundColor: 'var(--lightgrey-3)'
    },
    bar: {
        borderRadius:    0,
        backgroundColor: 'var(--teal)'
    }
}) )( LinearProgress );

const SyncProgressContent = props => {
    const { syncProgress, syncComplete } = props;
    const names = Object.keys( syncProgress );

    const label = name => {
        const { count, total, label } = syncProgress[ name ] || {};
        return <Grid key={`label-${name}`} item xs={6}>{syncComplete 
            ? <span>{label}</span>
            : <Tooltip title={`Received ${count}/${total}`}><span>{label}</span></Tooltip>
        }</Grid>;
    };

    const item = name => {
        const { count, total } = syncProgress[ name ] || {};
        return <Grid key={`item-${name}`} item xs={6}>{syncComplete 
            ? <span>{total}</span> 
            : <Tooltip title={`Received ${count}/${total}`}>
                <SyncProgress className={progressBar} variant='determinate' value={total ? 100 * count / total : 0} />
            </Tooltip>
        }</Grid>;
    };

    return <div>
        <span>{syncComplete ? __( 'Historical sync is complete.' ) : __( 'Historical sync is in progress.' )}</span>
 
        <Grid container spacing={0} className={`${progressGrid} ${syncComplete ? '' : inProgress}`} alignItems="center">
            {names.map( name => [ 0, 1 ].map( c => c == 0 ? label( name ) : item( name ) ) )}
        </Grid>
    </div>; 
};

SyncProgressContent.propTypes = {
    syncProgress: PropTypes.object.isRequired,
    syncComplete: PropTypes.bool.isRequired
};

const typeContent = {
    [ NotificationTypes.APPOINTMENT_ASSIGNED ]:         AppointmentAssignedContent,
    [ NotificationTypes.APPOINTMENT_CHANGED ]:          AppointmentChangedContent,
    [ NotificationTypes.APPOINTMENT_DELETED ]:          AppointmentDeletedContent,
    [ NotificationTypes.INTEGRATION_COMPLETE ]:         IntegrationCompleteContent,
    [ NotificationTypes.INTEGRATION_FAILED ]:           IntegrationFailedContent,
    [ NotificationTypes.JOB_CREATED ]:                  JobContent,
    [ NotificationTypes.JOB_STATUS_CHANGED ]:           JobContent,
    [ NotificationTypes.JOB_DELETED ]:                  JobDeletedContent,
    [ NotificationTypes.REOPENED_JOB ]:                 ReopenedJobContent,
    [ NotificationTypes.RESOLVED_JOB ]:                 ResolvedJobContent,
    [ NotificationTypes.APPOINTMENT_REJECTED ]:         AppointmentRejectedContent,
    [ NotificationTypes.ENGINEER_ADDED ]:               EngineerAdded,
    [ NotificationTypes.JOB_ENGINEER_ADDED ]:           JobEngineerChangedContent,
    [ NotificationTypes.JOB_ENGINEER_REMOVED ]:         JobEngineerChangedContent,
    [ NotificationTypes.JOB_NOTE_ADDED ]:               JobNoteAddedContent,
    [ NotificationTypes.ENGINEER_ACTIVATED ]:           EngineerReactivated,
    [ NotificationTypes.ENGINEER_DEACTIVATED ]:         EngineerDeactivated,
    [ NotificationTypes.PROVIDER_ERROR ]:               ErrorTypeContent,
    [ NotificationTypes.MAPPED_STATUS_NOT_ON_BOARD ]:   MissingJobStatusContent,
    [ NotificationTypes.PROVIDER_STATUS_NOT_SELECTED ]: UnselectedProviderStatusContent,
    [ NotificationTypes.SYNC_PROGRESS ]:                SyncProgressContent
};

const Notification = props => {
    const {
        notification,
        notificationViewed,
        key,
        response,
        userId,
        setPatchAppointmentThunk,
        getJobActionThunk
    } = props;

    // these notifications can arrive when the job concerned is not on the user's calendar,
    // so load the job details for display in the notification. The jobId needs to be in the
    // notification data.
    const jobNotifications = new Set([
        NotificationTypes.JOB_CREATED,
        NotificationTypes.JOB_ENGINEER_ADDED,
        NotificationTypes.JOB_ENGINEER_REMOVED,
        NotificationTypes.JOB_NOTE_ADDED
    ]);

    const systemNotifications = new Set([ NotificationTypes.SYNC_PROGRESS ]);

    useEffect( () => {
        if ( jobNotifications.has( notification.type ) ) 
            getJobActionThunk({ jobId: notification.data.jobId });
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Not displaying status notifications for now
    if ( notification.type === NotificationTypes.JOB_STATUS_CHANGED )
        return null;

    const component = ( typeContent[ notification.type ] || UnknownTypeContent )( props );
    if ( !component ) return null;
    const showClose = notification.dismissable && notification.responses.length < 2;

    const highlightOnHover = notification.data?.appointmentId && highlightTypes.has( notification.type );
    const setHighlight = flag => setPatchAppointmentThunk({ appointment: { appointmentId: notification.data.appointmentId, userId: userId, data: { highlight: flag } } });

    const makeButton = ( key, txt, action ) => (
        <LicoButton key={key} size="small" className={action < 0 ? dangerButton : ''} onClick={() => {
            notificationViewed({ notification, action });
            if ( highlightOnHover )
                setHighlight( false );
        }}>{txt}</LicoButton>
    );

    return (
        <div
            className={`${root} ${response || systemNotifications.has( notification.type ) ? '' : lightBackground}`}
            key={key}
            onMouseEnter={highlightOnHover ? () => setHighlight( true ) : undefined}
            onMouseLeave={highlightOnHover ? () => setHighlight( false ) : undefined}
        >
            <div className={avatarRow}>
                {notification.data.warning 
                    ? <span className={`${avatarImg} fa-duotone`}><LicoIcon 
                        style={{
                            color:                  'var( --watermelon)'
                        }}
                        icon="warning" 
                        size="2x"/></span>
                    : <LicoAvatar
                        avatar={avatarType.has( notification.type )
                            ? getAvatarItemFromName( notification.data.assigner ?? notification.data.userName )
                            : undefined}
                        userId={notification.userId}
                        className={avatarImg}
                        size="small"/>
                }
                <div className={message}>
                    {component}
                </div>
                {
                    showClose
                        ? <LicoFab
                            color="inherit"
                            licoVariant="tiny"
                            onClick={() => {
                                notificationViewed({ notification });
                                if ( highlightOnHover )
                                    setHighlight( false );
                            }}
                            className={`${closeFab} notification-close-button grey-background`}
                        >
                            <LicoIcon icon="close" className={closeIcon} />
                        </LicoFab>
                        : null
                }
            </div>
            {notification.dismissable && <div className={time}><TimeDisplay time={new Date( notification.createdOnTime )}/></div>}
            {
                response
                    ? ( <div className={buttons}>{notification.responses.map( ( r, i ) => makeButton( 'resp' + i, r.label, r.affirm ) )}</div> )
                    : null
            }
        </div>
    );
};

Notification.propTypes = {
    notification:               PropTypes.any.isRequired,
    userId:                     PropTypes.string.isRequired,
    notificationViewed:         PropTypes.func.isRequired,
    setPatchAppointmentThunk:   PropTypes.func.isRequired,
    getJobActionThunk:          PropTypes.func.isRequired,
    key:                        PropTypes.object,
    response:                   PropTypes.object
};

const mapStateToProps = ( state, props ) => {

    const jobId = props.notification.data?.jobId;
    const job = getJobFromState( state, jobId );
    const jobCompany = getJobCompany( state, jobId );

    return {
        userId:         state.user?.userId,
        jobTitle:       job?.title || '',
        jobDescription: job?.description || '',
        companyName:    jobCompany.companyName,
        providerName:   state.integration?.provider?.displayName || __( 'Provider' ),
        syncProgress:   state.notification.syncProgress,
        syncComplete:   state.notification.syncComplete
    };
};

const mapDispatchToProps = {
    getJobActionThunk,
    notificationViewed,
    setMeta,
    setPatchAppointmentThunk
};

export default connect( mapStateToProps, mapDispatchToProps )( Notification );
export { Notification };
