/*********************************************************************************************************************
 * @file Pegboard empty slot component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_PEGBOARD_EMPTY_BUTTON } from '../../ux-constants.js';
import { emptyPegboardSlot, button } from '../../scss/EmptyPegboardSlot.module.scss';
import { addJobThunk } from '../calendar/thunks.js';

class EmptyPegboardSlot extends PureComponent {

    render() {
        const { addJobThunk } = this.props;

        return <div data-type="EmptySlot" className={emptyPegboardSlot}>
            <div
                className={button}
                data-type="EmptySlotButton"
                data-ux={UX_PEGBOARD_EMPTY_BUTTON}

                color="primary"
                onClick={() => addJobThunk({ jobAddedFromPegboard: true })}
            >
                {' '}
            </div>
        </div>;
    }
}

EmptyPegboardSlot.propTypes = {
    addJobThunk:   PropTypes.func.isRequired
};

const mapDispatchToProps = { addJobThunk };

export default connect( null, mapDispatchToProps )( EmptyPegboardSlot );
export { EmptyPegboardSlot };
