/** ******************************************************************************************************************
 * @file Describe what errorDefs does.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 28-10-2021
 *********************************************************************************************************************/

const ERROR_DEF = Symbol( 'error-definition' );
const BASE_ERROR_CODE = 1000;

let errCode = BASE_ERROR_CODE;

/**
 * @param {number} statusCode
 * @param {string} message
 * @return {ErrorDefinition}
 */
const makeError = ({ statusCode, message, errorCode }) => ({
    message,
    statusCode,
    errorCode:     errorCode ?? errCode++,
    [ ERROR_DEF ]: true
});

const BAD_CREDS = 10001;
const BAD_MFA = 10002;
const MAXIMUM_ATTEMPTS = 10003;

/** @type {{ [ errorName: string ]: ErrorDefinition }} */
const ErrorDefs = {
    DATABASE_ERROR: makeError({
        statusCode: 500,
        message:    ''
    }),
    UNKNOWN_ERROR: makeError({
        statusCode: 500,
        message:    'Unknown error'
    }),
    INTERNAL_ERROR: makeError({
        statusCode: 500,
        message:    'Internal error'
    }),
    FAIL_APPOINTMENT: makeError({
        statusCode: 500,
        message:    'Fail to create an appointment, no provider updating'
    }),
    NO_JOB:           {
        statusCode: 500,
        message:    'Unable to find valid jobId, no provider updating'
    },
    NO_PROVIDER_ID: makeError({
        statusCode: 500,
        message:    'Timeout waiting for provider API response to get ticket reference'
    }),
    PROVIDER_ERROR: makeError({
        statusCode: 500,
        message:    'Error communicating with provider service API'
    }),
    COMPANY_INTEGRATION: makeError({
        statusCode: 404,
        message:    'Error adding integration for company %s'
    }),
    PROVIDER_AUTHENTICATION: makeError({
        statusCode: 403,
        message:    'Failed authentication in connection check %s'
    }),
    INTEGRATION_ERROR: makeError({
        statusCode: 403,
        message:    'Integration error: %s'
    }),
    APPOINTMENT_CREATE: makeError({
        statusCode: 500,
        message:    'Failed to create appointment, error: '
    }),
    MISSING_USER:       makeError({
        statusCode: 400,
        message:    'Unable to find user'
    }),
    USER_EXISTS:       makeError({
        statusCode: 400,
        message:    'Email already exists: %s'
    }),
    NAMESPACE_EXISTS:  makeError({
        statusCode: 400,
        message:    'Namespace already exists: %s'
    }),
    MISSING_ID:       makeError({
        statusCode: 400,
        message:    'Missing ID'
    }),
    MISSING_ID_BODY:       makeError({
        statusCode: 400,
        message:    'Missing ID or request body'
    }),
    CREATE_ERROR: makeError({
        statusCode: 500,
        message:    'Failed to create on database'
    }),
    CUSTOM_ERROR: makeError({
        statusCode: 500,
        message:    null
    }),
    NOT_FOUND: makeError({
        statusCode: 404,
        message:    'Not found'
    }),
    NO_INTEGRATION: makeError({
        statusCode: 404,
        message:    'No integration data found'
    }),
    MAXIMUM_ATTEMPTS: makeError({
        statusCode: 403,
        message:    'Maximum login attempts reached',
        errorCode:  MAXIMUM_ATTEMPTS
    }),
    BAD_CREDS: makeError({
        statusCode: 401,
        message:    'Incorrect login credentials',
        errorCode:  BAD_CREDS
    }),
    BAD_MFA: makeError({
        statusCode: 401,
        message:    'Incorrect MFA credentials',
        errorCode:  BAD_MFA
    }),
    VALIDATION: makeError({
        statusCode: 400,
        message:    'Request %s'
    }),
    CONN_BAD_FN: makeError({
        statusCode: 500,
        message:    'Not a function: %s'
    }),
    BAD_HTTP: makeError({
        statusCode: 500,
        message:    'Malformed HTTP message'
    }),
    CONNECTOR_REJECT: makeError({
        statusCode: 500,
        message:    'Error response from remote: %s'
    }),
    MAX_REACHED: makeError({
        statusCode: 500,
        message:    'System component is at capacity: %s'
    }),
    NOT_IMPLEMENTED: makeError({
        statusCode: 500,
        message:    'System component not implemented: %s'
    }),
    AUTH_BAD_FORMAT: makeError({
        statusCode: 403,
        message:    'Format is Authorization: Bearer [token]'
    }),
    AUTH_MISSING: makeError({
        statusCode: 403,
        message:    'No authorization token was found'
    }),
    AUTH_INVALID_TOKEN: makeError({
        statusCode: 403,
        message:    'Invalid token'
    }),
    PWD_RESET_MISSING_TOKEN: makeError({
        statusCode: 404,
        message:    'Unable to find valid reset code'
    }),
    PWD_RESET_INVALID_TOKEN: makeError({
        statusCode: 409,
        message:    'Invalid token'
    }),
    INTEGRATION_BAD_CONNECT: makeError({
        statusCode: 406,
        message:    'Unable to connect to provider'
    }),
    INTEGRATION_BAD_IDS: makeError({
        statusCode: 400,
        message:    'One or more required integration indices are invalid'
    }),
    MISSING_DATE: makeError({
        statusCode: 400,
        message:    'Missing date query parameter: %s'
    }),
    NO_ADMIN_RIGHTS: makeError({
        statusCode: 403,
        message:    'User does not have admin rights'
    }),
    MISSING_RECIPIENTS: makeError({
        statusCode: 422,
        message:    'No recipients provided'
    })
};

export { ErrorDefs, BAD_CREDS, BAD_MFA, MAXIMUM_ATTEMPTS, ERROR_DEF };
