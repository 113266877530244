import React     from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { root, spacer, company, logo } from '../../scss/TopRow.module.scss';
import { __ } from '../../utils/i18n.jsx';

const TopRow = ({ job, logoURL }) => (
    <div className={root}>
        <span className={company}>
            {job.companyName}
        </span>
        <span className={spacer} />
        {logoURL && <img className={logo} src={logoURL} alt={__( "Logo URL not valid" )} />}
    </div>
);

TopRow.propTypes = {
    job:        PropTypes.object.isRequired,
    logoURL:    PropTypes.string
};

const mapStateToProps = state => ({
    logoURL: state.scheduling.logoURL
});

export default connect( mapStateToProps )( TopRow );
export { TopRow };
