import React, { useCallback, useState, useEffect } from 'react';

import { nop } from '@licoriceio/utils';
import { TextField, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoFormItemWrapper } from './LicoFormItemWrapper.jsx';


const TextFieldTooltip = withStyles({
    tooltip: {
        background:   "var(--black-1)",
        fontSize:     12,
        borderRadius: 4,
        fontFamily:   "gotham-book, sans-serif",
        maxWidth:     200,
        border:       "1px solid white"
    },
    arrow: {
        color: "var(--black-1)"
    }
})( Tooltip );

export const LicoSearchableSelect = props => {
    const {
        label,
        options,
        placeholder,
        onChange = nop,
        onInputChange,
        onBlur,
        name = label,
        initialSelected,
        disabled = false,
        tooltip,
        disabledTooltip,
        error,
        helperText,
        autoFocus,
        formPackage
    } = props;

    const [ textValue, setTextValue ] = useState( '' );

    useEffect( () => {
        setTextValue( initialSelected ? initialSelected.label : '' );
    }, [ initialSelected ]);

    const handleSelect = useCallback( ( event, value ) => {

        if ( value )
            onChange( event, value );

        setTextValue( value ? value.label : '' );
    }, [ onChange ]);

    const handleTextChange = useCallback( event => void setTextValue( event.target.value ), [ setTextValue ]);

    const showTooltip = ( !!disabled && disabledTooltip !== undefined && disabledTooltip.length > 0 ) ||
        ( !disabled && tooltip !== undefined && tooltip.length > 0 );

    return (
        <LicoFormItemWrapper
            label={label}>
            <Autocomplete
                options={options}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={( option, value ) => value.label === option.label}
                onChange={ formPackage
                    ? ( event, value ) => formPackage.onChange({ id: formPackage.id, field: name, value: value?.id })
                    : handleSelect }
                onInputChange={( event, value, reason ) => {

                    // edits to the text field only get recorded if they are blanking the field, otherwise we're just changing the filter
                    if ( formPackage ) {
                        if ( value === '' ) formPackage.onChange({ id: formPackage.id, field: name, value });
                    }
                    else if ( onInputChange )
                        onInputChange( event, value, reason );
                }}

                disabled = {disabled}
                noOptionsText="Not found"
                renderInput={params => {

                    const textField = <TextField
                        {...params}
                        variant="outlined"
                        fullWidth={true}
                        name={name}
                        placeholder={placeholder}

                        onBlur={formPackage
                            ? event => formPackage.onBlur( event.target.name || 'anon searchableSelect' )
                            : onBlur }

                        helperText={formPackage
                            ? formPackage.fieldTouched[ name ] && formPackage.fieldError[ name ]
                            : helperText}

                        error={formPackage
                            ? !!formPackage.fieldError[ name ] && formPackage.fieldTouched[ name ]
                            : error}

                        inputProps={{
                            ...params.inputProps,
                            onChange: e => {
                                handleTextChange( e );
                                params.inputProps.onChange( e );
                            },
                            value:           textValue,
                            autoFocus:       autoFocus,
                            "data-lpignore": true
                        }} />;

                    const textFieldWithTooltip = <TextFieldTooltip
                        title={disabled ? disabledTooltip || '' : tooltip || ''}
                        arrow
                        placement="left"
                        disableHoverListener={!showTooltip}
                        disableTouchListener={true}
                        disableFocusListener={true}
                    >{textField}</TextFieldTooltip>;

                    return <>
                        { showTooltip ? textFieldWithTooltip : textField }
                    </>;
                }}
            />
        </LicoFormItemWrapper>
    );
};

LicoSearchableSelect.propTypes = {
    label:           PropTypes.string.isRequired,
    options:         PropTypes.array.isRequired,
    placeholder:     PropTypes.string,
    onChange:        PropTypes.func,
    name:            PropTypes.string,
    onBlur:          PropTypes.func,
    onInputChange:   PropTypes.func,
    initialSelected: PropTypes.object,
    disabled:        PropTypes.bool,
    tooltip:         PropTypes.string,
    disabledTooltip: PropTypes.string,
    autoFocus:       PropTypes.bool,
    inputProps:      PropTypes.object,
    helperText:      PropTypes.node,
    error:           PropTypes.bool,
    formPackage:     PropTypes.object
};
