import React, { useState, useCallback } from 'react';

import IconClose from "@mui/icons-material/Close";
import { Popper, ClickAwayListener, MenuList, Paper, Grow, Fab, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoFab } from '../atomic/form/index.js';

const useStyles = makeStyles({
    root: {},
    fab:  {
        boxShadow: "none",
        height:    "unset"
    },
    menuLabel: {
        display:        "flex",
        flexFlow:       "row nowrap",
        justifyContent: "space-between",
        fontWeight:     "bold",
        paddingRight:   60
    },
    menuLabelClose: {
        marginLeft: "auto",
        position:   "absolute",
        top:        0,
        right:      0,
        fontSize:   12
    },
    iconClose: {
        fontSize: 14
    }
});

export const NavMenuTrigger = props => {
    const {
        menuId,
        buttonProps,
        buttonChildren,
        children,
        isFab = false,
        menuListLabel,
        dataName
    } = props;
    const classes = useStyles( props );
    const [ menuOpen, setMenuOpen ] = useState( false );
    const [ buttonRef, setButtonRef ] = useState();
    const updateButtonRef = useCallback( node => setButtonRef( node ), []);
    const handleMenuClick = useCallback( () => setMenuOpen( true ), []);
    const handleMenuClose = useCallback( () => setMenuOpen( false ), []);
    const arrayChildren = ( Array.isArray( children ) ) ? children : [ children ];

    return ( <>
        {
            isFab
                ? (
                    <Fab
                        classes={{ root: classes.fab }}
                        aria-owns={menuId}
                        aria-haspopup="true"
                        data-ux={dataName}
                        onClick={handleMenuClick}
                        ref={updateButtonRef}
                        {...buttonProps}
                    >
                        {buttonChildren}
                    </Fab>
                )
                : (
                    <Button
                        style = {{ height: "unset" }}
                        aria-owns={menuId}
                        aria-haspopup="true"
                        data-ux={dataName}
                        onClick={handleMenuClick}
                        {...buttonProps}
                        ref={updateButtonRef}
                    >
                        {buttonChildren}
                    </Button>
                )
        }
        <Popper open={menuOpen} anchorEl={buttonRef} transition style={{ zIndex: 9999 }}>
            {
                ({ TransitionProps, placement }) => (
                    <Grow  {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleMenuClose}>
                                <MenuList id={menuId}>
                                    {
                                        menuListLabel && <MenuItem
                                            className = {classes.menuLabel}
                                            button={false}
                                            component="div">
                                            {menuListLabel}
                                            <LicoFab onClick={handleMenuClose} licoVariant = "small" className ={classes.menuLabelClose}><IconClose className ={classes.iconClose}/>
                                            </LicoFab>
                                        </MenuItem>
                                    }
                                    {
                                        arrayChildren.map( ( child, i ) => {
                                        // @ts-ignore
                                            return React.cloneElement( child, {
                                                ...child.props,
                                                ...{
                                                    onClick: e => {
                                                        handleMenuClose();
                                                        child.props.onClick( e );
                                                    },
                                                    key: `${menuId}${i}`
                                                }
                                            });
                                        })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )
            }
        </Popper>
    </> );
};

NavMenuTrigger.propTypes = {
    menuId:         PropTypes.string,
    buttonProps:    PropTypes.object.isRequired,
    buttonChildren: PropTypes.node.isRequired,
    children:       PropTypes.any.isRequired,
    isFab:          PropTypes.bool,
    menuListLabel:  PropTypes.string,
    dataName:       PropTypes.string
};
