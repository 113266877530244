import React from 'react';

import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: ({
        display:        "flex",
        margin:         "200px auto",
        flexFlow:       "column nowrap",
        justifyContent: "space-around",
        padding:        40,
        position:       "relative"
    })
});

export const Panel = props => (
    <Card className={`${useStyles( props ).root} ${props.className || ''}`}>
        {props.children}
    </Card>
);


Panel.propTypes = {
    className: PropTypes.string,
    children:   PropTypes.node
};
