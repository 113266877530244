/*********************************************************************************************************************
 * @file Team reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Jul-2023
 *********************************************************************************************************************/
import { TagType, UserRole, DELETE } from '@licoriceio/constants';
import { has } from '@licoriceio/utils';
import { v4 as uuidv4 } from 'uuid';

import { uri, POST, GET, PATCH } from '../../../constants.js';
import {
    setTeams, showNewTeam, addTeam, changeTeam, editTeam, removeTeam, addTeamMember, removeTeamMember, setTeamError, setTeamEngineers, setUserFromSettings
} from '../../../redux/actions/index.js';
import { ezRedux, genericRequest } from '../../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../../services/util/baseRequests.js';
import { __ } from '../../../utils/i18n.jsx';

/**
 * @typedef {object} TeamState
 * @property {array} teams
 */


/**
  * @type {EngineerState}
  */
const initialState = Object.freeze({
    teams:                          [],
    addingTeam:                     false,
    workingTeamName:                '',
    fieldError:                     '',
    editingTeamId:                  null,
    engineers:                      []
});

const getTeamState = state => state.team;
const getUnteamedEngineers = state => {
    const { team: { teams, engineers } } = state;

    const teamEngineers = teams.flatMap( team => team.members.map( member => member.user.userId ) );
    return engineers.filter( e => e.active && !teamEngineers.includes( e.userId ) ).map( e => e.name );
};

// requests

const getTeams = () => genericRequest({}, abstractedCreateAuthRequest( GET, uri.TAGS_WITH_MEMBERS ), setTeams, [ ], 
    { 
        tagType:            TagType.team, 
        related:            'tag->?tagUser->?user',
        splitRelated:       true,
        ignoreActive:       true,
        fields:             [ 'tag.tagId', 'tag.tagType', 'tag.tagName', 'tag.active as tagActive', 'tagUser.tagUserId', 'user.*' ]
    }
);
const postTeam = team => genericRequest( team, abstractedCreateAuthRequest( POST, uri.TAGS ), addTeam );
const postTeamMember = payload => genericRequest({
    tagUserId:      uuidv4(),
    tagId:          payload.tagId,
    userId:         payload.userId
}, abstractedCreateAuthRequest( POST, uri.TAG_USERS ), [ [ addTeamMember, { user: payload.user } ] ]);
const deleteTeamMember = payload => genericRequest({}, abstractedCreateAuthRequest( DELETE, uri.SINGLE_TAG_USER ), [ [ removeTeamMember, payload ] ], [ payload.tagUserId ]);
const patchTeam = team => genericRequest( team, abstractedCreateAuthRequest( PATCH, uri.SINGLE_TAG ), [], [ team.tagId ]);
const deleteTeam = tagId => genericRequest({}, abstractedCreateAuthRequest( DELETE, uri.SINGLE_TAG ), [ [ removeTeam, { tagId } ] ], [ tagId ]);

const getEngineers = () => genericRequest({}, abstractedCreateAuthRequest( GET, uri.USER ), setTeamEngineers, [ ], 
    { 
        role:               UserRole.engineer,
        ignoreActive:       true,
        fields:             [ 'user.userId', 'user.name', 'user.active' ],
        pageSize:           99
    }
);

// thunks

const saveTeam = payload => ( dispatch, getState ) => {

    if ( has( payload, 'tagName' ) && payload.tagName.length === 0 ) 
        return;

    // check we don't clash with an existing team
    const { team: { teams, addingTeam, editingTeamId } } = getState();
    const clash = teams.find( team => ( addingTeam || team.tagId !== editingTeamId ) && team.tagName === payload.tagName );
    if ( clash )
        dispatch( setTeamError( __( "There is an existing Team with this name" ) ) );
    else {
        
        if ( addingTeam ) {
            const team = {
                tagId:      uuidv4(),
                tagType:    TagType.team,
                tagName:    payload.tagName
            };
            dispatch( showNewTeam( false ) );
            dispatch( postTeam( team ) );
            dispatch( setTeamError( '' ) );
        }
        else {
            dispatch( editTeam( null ) );
            dispatch( patchTeam( payload ) );
        }
    }
};

// reducers

const _changeTeam = ( draft, payload ) => {
    if ( draft.addingTeam )
        draft.workingTeamName = payload.tagName;
    else if ( draft.editingTeamId ) {
        const team = draft.teams.find( team => team.tagId === draft.editingTeamId );
        if ( team )
            Object.assign( team, payload );
    }
    else {
        // immediate change to a team ie enabled switch
        const team = draft.teams.find( team => team.tagId === payload.tagId );
        if ( team ) 
            Object.assign( team, payload );
    }
};

const _addTeamMember = ( draft, payload ) => {
    const { user, payload: tagUser } = payload;
    const team = draft.teams.find( team => team.tagId === tagUser.tagId );
    team?.members.push({
        tagUserId:  tagUser.tagUserId,
        user
    });
};

const _removeTeamMember = ( draft, payload ) => {
    const { tagId, tagUserId } = payload;
    const team = draft.teams.find( team => team.tagId === tagId );
    if ( team ) {
        const index = team.members.findIndex( m => m.tagUserId === tagUserId );
        if ( index >= 0 )
            team.members.splice( index, 1 );
    }
};

const _editTeam = ( draft, tagId ) => {

    // these look really similar but I'm not sure it can be refactored to make it better
    draft.fieldError = '';
    
    if ( tagId ) {

        // this is start of edit and save of original name
        const team = draft.teams.find( team => team.tagId === tagId );
        if ( team ) {
            draft.workingTeamName = team.tagName;
            draft.editingTeamId = tagId;
        }
    }
    else {
        // finish edit mode
        draft.editingTeamId = null;
        draft.workingTeamName = '';
    }
};

const _removeTeam = ( draft, payload ) => {
    const index = draft.teams.findIndex( team => team.tagId === payload.tagId );
    if ( index >= 0 )
        draft.teams.splice( index, 1 );

};

const updateEngineerList = ( engineers, userId, active ) => {
    const index = engineers.findIndex( user => user.userId === userId );
    if ( index >= 0 && engineers[ index ].active !== active ) 
        engineers[ index ].active = active;
};

const updateMemberList = ( members, userId, active ) => {
    const index = members.findIndex( member => member.user.userId === userId );
    if ( index >= 0 && members[ index ].user.active !== active ) 
        members[ index ].user.active = active;
};

const _updateEngineer = ( draft, payload ) => {

    // check if an engineer's active state was changed, as this changes the unteamed list 
    // and any teams they're in (visually anyway; they're still in the team).
    updateEngineerList( draft.engineers, payload.userId, payload.active );
    draft.teams.map( team => updateMemberList( team.members, payload.userId, payload.active ) );  
};

const reducers = {
    [ setTeams ]:               ( draft, payload ) => draft.teams = payload,
    [ showNewTeam ]:            ( draft, payload ) => { draft.addingTeam = payload; draft.workingTeamName = ""; },
    [ changeTeam ]:               _changeTeam,
    [ addTeam ]:                ( draft, payload ) => draft.teams = [ ...draft.teams, { members: [], ...payload } ],
    [ setTeamError ]:           ( draft, payload ) => draft.fieldError = payload,
    [ addTeamMember ]:          _addTeamMember,
    [ removeTeamMember ]:       _removeTeamMember,
    [ editTeam ]:               _editTeam,
    [ removeTeam ]:             _removeTeam,
    [ setTeamEngineers ]:       ( draft, payload ) => draft.engineers = payload,
    [ setUserFromSettings ]:    _updateEngineer
};

export {
    getTeamState,
    getTeams,
    saveTeam,
    patchTeam,
    deleteTeam,
    postTeamMember,
    deleteTeamMember,
    getEngineers,
    getUnteamedEngineers
};

export default ezRedux( reducers, initialState );
