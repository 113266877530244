/*********************************************************************************************************************
 * @file Pegboard panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { PureComponent } from 'react';

import { MINUTE } from '@licoriceio/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { dragSource, PEGBOARD_SLOTS } from '../../constants.js';
import { UX_PEGBOARD_PANEL } from '../../ux-constants.js';
import { pegboardPanel } from '../../scss/PegboardPanel.module.scss';
import { getDefaultAppointmentDuration } from '../../utils/user-prefs.js';
import { Draggable } from '../FullCalendar.jsx';

import EmptyPegboardSlot from './EmptyPegboardSlot.jsx';
import PegboardSlot from './PegboardSlot.jsx';
import PegboardTopTimer from './PegboardTopTimer.jsx';
import { selectPegboardItems } from './reducer.js';

class PegboardPanel extends PureComponent {

    componentDidMount() {

        let containerEl = document.getElementById( 'pegboard-slot-container' );

        if ( containerEl ) {
            // eslint-disable-next-line no-new
            new Draggable( containerEl, {
                itemSelector: '.pegboard-full-slot',
                eventData:    function( eventEl ) {
                    const jobId = eventEl.getAttribute( 'data-id' );
                    if ( jobId ) {
                        let timeMinutes = Number( eventEl.getAttribute( 'data-time' ) ) || getDefaultAppointmentDuration();
                        if ( timeMinutes % 15 ) timeMinutes += ( 15 - ( timeMinutes % 15 ) );

                        return {
                            title:                  eventEl.getAttribute( 'data-title' ),
                            clientName:             eventEl.getAttribute( 'data-company' ),
                            description:            eventEl.getAttribute( 'data-description' ),
                            jobId:                  jobId,
                            timeLogId:              eventEl.getAttribute( 'data-timelog-id' ),
                            timerRunning:           eventEl.getAttribute( 'data-timer-running' ) === 'true',
                            externalSource:         dragSource.PEGBOARD,
                            duration:               timeMinutes * MINUTE,
                            priority:               eventEl.getAttribute( 'data-priority' ),
                            newItemFlag:            eventEl.getAttribute( 'data-new-item-flag' )
                        };
                    }
                    else
                        throw new Error( "no job id in drag" );
                }
            });
        }
    }

    render() {
        const { items } = this.props;

        return <div id="pegboard-panel" className={pegboardPanel} data-ux={UX_PEGBOARD_PANEL} >
            <PegboardTopTimer />
            <div id="pegboard-slot-container">
                {new Array( PEGBOARD_SLOTS )
                    .fill( true )
                    .map( ( v, i ) => i < items.length
                        ? <PegboardSlot key={i} item={items[ i ]} />
                        : <EmptyPegboardSlot key={i} />
                    )
                }
            </div>
        </div>;
    }

    static propTypes = {
        items:          PropTypes.array
    };
}

const mapStateToProps = state => ({
    items:          selectPegboardItems( state ) || []
});

export default connect( mapStateToProps )( PegboardPanel );
export { PegboardPanel };
