import React from 'react';

import { Fab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LicoIcon } from '../../images/LicoIcon.jsx';

const useStyles = makeStyles({
    root: ({
        display:   "flex",
        "&:hover": {
            cursor: "pointer"
        }
    }),
    fab: {
        boxShadow:       "none",
        height:          20,
        width:           20,
        minHeight:       20,
        minWidth:        20,
        fontSize:        1,
        backgroundColor: "var(--grey-2)",
        display:         "flex",
        flexFlow:        "column nowrap",
        justifyContent:  "center",
        alignItems:      "center",
        marginRight:     8
    },
    icon: {
        top:        5,
        height:     12,
        width:      12,
        fontSize:   50,
        color:      "var(--white)",
        "&:hover": {
            color:  "var(--black-1)"
        }
    },
    label: {
        fontSize: 13
    }
});

export const LicoSmallButton = ({ onClick, label = '', fabClass, labelClass, iconClass, ...rest }) => {
    const classes = useStyles( rest );
    return <label className={`${classes.root} tiny`} style={{ marginRight: rest.marginRight }} >
        <Fab variant="circular" size="small" className={[ classes.fab, fabClass ].join( ' ' )} onClick={onClick} data-ux={rest.dataName}>
            <LicoIcon icon="add" className={`${classes.icon} ${iconClass}`} />
        </Fab>
        <Typography variant = "caption" component ="span"className={`${classes.label} ${labelClass}`}>{label}</Typography>
    </label>;
};

LicoSmallButton.propTypes = {
    onClick:        PropTypes.func,
    label:          PropTypes.any.isRequired,
    marginRight:    PropTypes.number,
    fabClass:       PropTypes.string,
    labelClass:     PropTypes.string,
    iconClass:      PropTypes.string
};
