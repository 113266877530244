/** ******************************************************************************************************************
 * @file Description of this file goes here.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 19-Apr-2021
 *********************************************************************************************************************/
import worldCountries from './world-countries.js';
import { currencies } from './currencies.js';
import { lc } from '../helpers/index.js';
import normalizeState from './states.js';

worldCountries.forEach( c => c.currency = c.currency?.map( code => currencies.get( code ) ) );

const alpha2 = new Map( worldCountries.map( c => [ c.cca2, c ]) );
const alpha3 = new Map( worldCountries.map( c => [ c.cca3, c ]) );
const countryName = new Map( worldCountries.map( c => [ lc( c.name?.common ), c ]) );

/**
 * @typedef {object} FullCurrency
 * @property {string} name
 * @property {string} namePlural
 * @property {string} symbol
 * @property {string} symbolNative
 * @property {number} decimalDigits
 * @property {number} rounding
 * @property {string} code
 */

/**
 * @param {string} code
 * @return {FullCurrency}
 */
const getCurrency = code => currencies.get( code );

const getCountry = code => code.length === 2 ? alpha2.get( code ) : alpha3.get( code );

const getCountryByName = name => countryName.get( lc( name ) );

/**
 *
 * @param {string} code
 * @return {FullCurrency[]}
 */
const getCurrencyByCountry = code => getCountry( code )?.currency;

function _findInRecord( name )
{
    return record => lc( record.name.common ) === name || lc( record.name.official ) === name ||
                     lc( record.cca2 ) === name || lc( record.cca3 ) === name || lc( record.cioc ) === name ||
                     ( !record.altSpellings || record.altSpellings.some( a => lc( a ) === name ) );
}
function normalizedFind( name )
{
    const find = _findInRecord( lc( name ) );
    return worldCountries.find( find );
}

function findInMap( name, map )
{
    const record = normalizedFind( name );

    if ( !record ) return;

    const oneOfThese = [ [ 'name', 'common', 'official' ], [ null, 'cca2', 'cca3', 'cioc' ], [ null, 'altSpellings' ] ];

    let found = null;

    const isInMap = name => map.has( name ) ? name : null;

    for ( const [ key, ...rest ] of oneOfThese )
    {
        const base = key ? record[ key ] : record;

        for ( const subKey of rest )
        {
            if ( Array.isArray( base[ subKey ]) )
                found = base[ subKey ].some( a => isInMap( lc( a ) ) );
            else
                found = isInMap( lc( base[ subKey ]) );

            if ( found ) break;
        }

        if ( found ) break;
    }

    return found ? map.get( found ) : null;
}


export {
    getCurrency,
    getCountry,
    getCountryByName,
    getCurrencyByCountry,
    normalizedFind,
    findInMap,
    normalizeState
};

// const testMap = new Map([
//     [ 'united states', { data: 'about', the: 'US' } ],
//     [ 'canada', { data: 'about', the: 'Canadia' } ]
// ]);
//
// console.log( `findInMap( 'USA', testMap ):`, findInMap( 'USA', testMap ) );
// console.log( `findInMap( 'CAN', testMap ):`, findInMap( 'CAN', testMap ) );
