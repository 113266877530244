import React, { useCallback } from 'react';

import { Priority } from '@licoriceio/constants';
import { nop } from '@licoriceio/utils';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { root, playIcon, disabledColors } from '../../../scss/PlayPauseButton.module.scss';
import { priorityColorsWithBorders } from '../../../utils/misc.js';
import { LicoIcon } from '../../common/index.js';


export const PlayPauseButton = ({ isPlaying, onPlay, onPause, priority, disabled, tooltip = null }) => {

    const handlePlay = useCallback( event => { event.stopPropagation(); onPlay?.(); }, [ onPlay ]);
    const handlePause = useCallback( event => { event.stopPropagation(); onPause?.(); }, [ onPause ]);

    const control = <div
        className={`${root} ${disabled ? disabledColors : isPlaying ? '' : priorityColorsWithBorders[ priority || Priority.normal ]}`}
        onClick={disabled ? nop : isPlaying ? handlePause : handlePlay}>
        {isPlaying ? <LicoIcon icon="pause"/> : <LicoIcon icon="play" className={playIcon}/>}
    </div>;

    return tooltip 
        ? <Tooltip title={tooltip}>
            {control}
        </Tooltip>
        : control;
};

PlayPauseButton.propTypes = {
    isPlaying:  PropTypes.bool,
    onPlay:     PropTypes.func,
    onPause:    PropTypes.func,
    priority:   PropTypes.string,
    disabled:   PropTypes.bool,
    tooltip:    PropTypes.string
};
