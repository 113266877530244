import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { POST, uri } from '../../constants.js';
import { UX_REGISTRATION_NEXT } from "../../ux-constants.js";
import step2Image from "../../images/step2img.png";
import step3Image from "../../images/step3img.png";
import step4Image from "../../images/step4img-cropped.png";
import { setAdvance, setProgress } from '../../redux/actions/index.js';
import { getLoadingStates } from '../../redux/reducerUtil.js';
import { root, isLoading, subHeading, back, spacer, heading, errorStyle, finalImage, imageStyle } from '../../scss/Registration.module.scss';
import { __ } from "../../utils/i18n.jsx";
import LicoButton from '../common/atomic/form/LicoButton.jsx';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { Image, LicoIcon } from "../common/index.js";
import PreAuthLayout from '../layouts/PreAuthLayout.jsx';

import BetaKey from './BetaKey.jsx';
import CompanyForm from './Company.jsx';
import Confirmation from './Confirmation.jsx';
import EmailForm from './Email.jsx';
import NamePasswordForm from './NamePassword.jsx';
import { progressStage, performRegistration } from './reducer.js';


const stageImage = [
    null,
    null,
    [ step2Image, __( "A cartoon image of a dashboard." ) ],
    [ step3Image, __( "A cartoon image physical calendar, with an atlas in the background." ) ],
    [ step4Image, __( "People celebrating" ) ]
];

const componentStages = {
    [ progressStage.BETA_KEY ]:    () => <BetaKey />,           // Beta key
    [ progressStage.EMAIL ]:       () => <EmailForm />,         // Signup - 0
    [ progressStage.IDENTITY ]:    () => <NamePasswordForm />,  // Signup - 2
    [ progressStage.COMPANY ]:     () => <CompanyForm />,       // Signup - 3
    [ progressStage.SUCCESS ]:     () => <Confirmation />       // Signup - 5
};

const Registration = ({ progress, error, setProgress, setAdvance, performRegistration, loadingCheck, loadingRegister }) => {
    const component = useMemo( () => componentStages[ progress ](), [ progress ]);

    return <PreAuthLayout className={`${root} ${loadingCheck || loadingRegister ? isLoading : ''}`}>

        <BannerLogo />

        {
            progress > progressStage.BETA_KEY && progress < progressStage.SUCCESS
                ? <>
                    <div className={subHeading}>
                        {
                            progress > progressStage.EMAIL  &&
                            <span className={back} onClick={() => setProgress( progress - 1 )}>
                                <LicoIcon icon="angle-left" />
                                &nbsp;
                                {__( "Back" )}
                            </span>
                        }
                        <span className={spacer}>&nbsp;</span>
                        <span>{__( "Step" )}&nbsp;{progress}/3</span>
                    </div>
                    <div className={heading}>{__( "Create your account" )}</div>
                </>
                : null
        }

        {component}

        <div className={errorStyle}>{error}</div>

        {
            progress > progressStage.BETA_KEY && progress <= progressStage.COMPANY
                ? <LicoButton 
                    data-ux={UX_REGISTRATION_NEXT} 
                    onClick={() => progress === progressStage.COMPANY ? performRegistration() : setAdvance()}
                    disableDuring={progress === progressStage.COMPANY ? [ POST + uri.REGISTRATION_REGISTER ] : undefined}
                >
                    {progress === progressStage.COMPANY ? __( "Sign Up" ) : __( "Next" )}
                </LicoButton>
                : null
        }
        {
            stageImage[ progress ]
                ? <Image
                    src={stageImage[ progress ][ 0 ]} alt={stageImage[ progress ][ 1 ]}
                    className={progress === progressStage.SUCCESS ? finalImage : imageStyle}
                />
                : null
        }

    </PreAuthLayout>;
};

Registration.propTypes = {
    progress:            PropTypes.number.isRequired,
    setProgress:         PropTypes.func.isRequired,
    setAdvance:          PropTypes.func.isRequired,
    error:               PropTypes.string,
    loadingCheck:        PropTypes.any,
    loadingRegister:     PropTypes.any,
    performRegistration: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const [ loadingCheck, loadingRegister ] = getLoadingStates( state, POST + uri.REGISTRATION_CHECK, POST + uri.REGISTRATION_REGISTER );

    return {
        progress:           state.registration.progress,
        error:              state.registration.error,
        loadingCheck,
        loadingRegister
    };
};

const mapDispatchToProps = {
    setProgress,
    setAdvance,
    performRegistration
};

export default connect( mapStateToProps, mapDispatchToProps )( Registration );
export { Registration };
