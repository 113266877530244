import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UX_TIMED_ACTION_PANEL_UNDO, UX_TIMED_ACTION_PANEL_CLOSE } from '../../ux-constants.js';
import { messageBox, button, message, closeButton, closeIcon, shown } from '../../scss/TimedActionPanel.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { LicoIcon, LicoButton, LicoFab } from '../common/index.js';
import { sendPendingNote, setUndoPendingNote } from '../jobcard/reducer.js';


class TimedActionPanel extends PureComponent
{
    render() {
        const {
            pendingNote,
            sendPendingNote,
            setUndoPendingNote
        } = this.props;

        return <div className={`${messageBox} ${pendingNote ? shown : ''}`}>
            <span className="flexGrow" />
            <LicoButton className={button} size="small" onClick={setUndoPendingNote} data-ux={UX_TIMED_ACTION_PANEL_UNDO}>{__( "Undo" )}</LicoButton>
            <span className={message}>{__( "Click to undo sending the last message" )}</span>
            <span className="flexGrow" />
            <LicoFab onClick={sendPendingNote} className={closeButton} data-ux={UX_TIMED_ACTION_PANEL_CLOSE}>
                <LicoIcon icon="close" className={closeIcon} />
            </LicoFab>
        </div>;
    }

    static propTypes = {
        pendingNote:            PropTypes.object,
        sendPendingNote:        PropTypes.func.isRequired,
        setUndoPendingNote:     PropTypes.func.isRequired
    };
}

const mapStateToProps = state => {

    const { jobcard: { pendingNote } } = state;

    return {
        pendingNote
    };
};
const mapDispatchToProps = {
    sendPendingNote,
    setUndoPendingNote
};

export default connect( mapStateToProps, mapDispatchToProps )( TimedActionPanel );
export { TimedActionPanel };
