import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { H2 } from '../typography/index.js';

const useStyles = makeStyles({
    root:  {},
    label: {
        marginBottom:   9,
        marginTop:      '0 !important'
    }
});

export const LicoFormItemWrapper = ({ label, children, ...rest }) => {
    const { root, label: labelCss } = useStyles( rest );
    return (
        <label className={root}>
            <H2 className={labelCss}>{label}</H2>
            {children}
        </label>
    );
};


LicoFormItemWrapper.propTypes = {
    label:    PropTypes.string.isRequired,
    error:    PropTypes.string,
    children: PropTypes.node
};
