/*********************************************************************************************************************
 * @file Invitation reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Dec-2020
 *********************************************************************************************************************/
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';

import { uri, GET, POST } from '../../constants.js';
import { setTouchedInvitation, setFormValueInvitation, setInvitationData, setInvitationAccepted } from '../../redux/actions/index.js';
import { ezRedux, genericRequest, formValueReducer, focusReducer } from '../../redux/reducerUtil.js';
import { abstractedCreateRequest } from '../../services/util/baseRequests.js';
import { THIS_FIELD_IS_REQUIRED } from "../common/index.js";
import { formatStrength, validateStrength } from '../registration/reducer.js';

/**
 * @typedef {object} AcceptInvitationState
 * @property {string}   emailAddress
 * @property {string}   engineerName
 * @property {object}   fieldTouched
 * @property {object}   fieldError
 * @property {string}   password
 * @property {object}   [strength]
 * @property {boolean}  [invitationAccepted]
 * @property {string}   [invitationToken]
 * @property {object}   [invitationData]
 */

/**
  * @type {Readonly<AcceptInvitationState>}
  */
const initialState = Object.freeze({
    emailAddress:           '',
    engineerName:           '',
    fieldTouched:           {},
    fieldError:             {},
    password:               '',
    invitationAccepted:     false
});


const formSchema = Yup.object().shape({
    engineerName: Yup.string().required( THIS_FIELD_IS_REQUIRED ),
    password:     Yup.string().required( THIS_FIELD_IS_REQUIRED )
});

// services

const _asyncGetSingleInvitation = abstractedCreateRequest( GET, uri.SINGLE_INVITATION );
const _asyncPostAcceptInvite = abstractedCreateRequest( POST, uri.ACCEPT_INVITATION );

const getInvitationStatus = code => genericRequest({}, _asyncGetSingleInvitation, setInvitationData, [ code ]);
const acceptInvitation = payload => genericRequest( payload, _asyncPostAcceptInvite, setInvitationAccepted );


/** reducers */

const updateForm = ( draft, arg ) => {
    formValueReducer( draft, arg, formSchema );
    draft.fieldTouched[ arg?.field ] = false;
    if ( arg?.field === 'password' ) {
        if ( draft.password ) {
            const inputs = [];
            if ( draft.emailAddress?.length > 0 ) inputs.push( ...draft.emailAddress.split( /[.@_-]/ ) );
            draft.strength = formatStrength( zxcvbn( draft.password, inputs ) );
            draft.fieldError.password = validateStrength( draft.strength );
        }
        else
            draft.strength = null;
    }
};

const invitationDataReducer = ( draft, payload ) => {
    draft.invitationData = payload;

    if ( payload.user ) {
        const { user: { name, loginEmail } } = payload;
        draft.emailAddress = loginEmail;
        draft.engineerName = name;
    }
};

const reducers = {
    [ setFormValueInvitation ]: updateForm,
    [ setTouchedInvitation ]:   focusReducer,
    [ setInvitationData ]:      invitationDataReducer,
    [ setInvitationAccepted ]:  draft => draft.inviteAccepted = true
};

export {
    acceptInvitation,
    getInvitationStatus
};

export default ezRedux( reducers, initialState );
