import React from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

const styles = ( ) => createStyles({
    root: ({
        display: 'flex'
    }),
    item: {
        paddingRight:   10,
        paddingBottom:  10,
        display:        'flex'
    }
});

const RawLicoTableRow = ({ classes, widths, children }) => (
    <div className={classes.root}>
        {
            React.Children.map( children, ( child, i ) =>
                <div style={{ width: widths[ i ] }} key={i} className={classes.item}>
                    {child}
                </div> )
        }
    </div>
);

RawLicoTableRow.propTypes = {
    widths:   PropTypes.arrayOf( PropTypes.string ).isRequired,
    classes:  PropTypes.object,
    children: PropTypes.any
};

export const LicoTableRow = withStyles( styles )( RawLicoTableRow );
