import React from 'react';

import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
// import { getFilters } from '../functions/colorMaker.js';

const useStyles = makeStyles({
    root:  {
        // filter:    props => props.color ? ( props.isWhite ? 'invert(100%)' : 'brightness(0%)' ) +    getFilters( props.color ).filter : '',
        objectFit: "scale-down",
        alignSelf: "center",
        margin:    "1.5rem 2rem 1.5rem",
        width:     155
    }
});

export const Image = props => {
    const { src, className = '', alt, style } = props;
    const classes = useStyles( props );
    return <img className={`${classes.root} ${className}`} title = {alt} alt = {alt} src = {src} style = {style}/>;
};

Image.propTypes = {
    src:       PropTypes.string,
    alt:       PropTypes.string,
    className: PropTypes.string,
    style:     PropTypes.object
};
