import React from 'react';

import PropTypes from 'prop-types';

import { LicoSelect } from "../common/atomic/form/LicoSelect.jsx";

const NumberDropdown = ({ value, setValue, ...rest }) => <LicoSelect
    name={Object.keys( value )[ 0 ]}
    externalValue={Object.values( value )[ 0 ]}
    onSelect={( e, option ) => setValue({
        name:      e.target.name,
        value:      option.id
    })}
    inlineLabel
    noMargins
    {...rest}
/>;

NumberDropdown.propTypes = {
    value:          PropTypes.object.isRequired,
    options:        PropTypes.array.isRequired,
    label:          PropTypes.string,
    labelClassName: PropTypes.string,
    setValue:       PropTypes.func,
    inputClassName: PropTypes.string,
    disabled:       PropTypes.bool,
    minimumwidth:   PropTypes.number,
    slicePackage:   PropTypes.object
};

export default NumberDropdown;
export { NumberDropdown };
