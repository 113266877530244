/**
 * @file Frontend constants
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 12-Oct-2020
 */

import { SECOND, JournalTypes } from '@licoriceio/constants';

import { __ } from './utils/i18n';

export const engineerField = {
    NAME:                   'name',
    EMAIL_ADDRESS:          'loginEmail',
    ROLE:                   'role',
    PHONE:                  'phone',
    TITLE:                  'title',
    ADMIN:                  'admin',
    WORK_DAY_START_TIME:    'work_day_start_time',
    WORK_DAY_END_TIME:      'work_day_end_time',
    ACTIVE:                 'active',
    ELIGIBLE:               'eligible',
    LOGIN_EMAIL:            'loginEmail',
    EMAIL:                  'email'
};

// these paths are used twice, for the service definition and also to create specific
// loading selectors.
export const uri = {
    APPOINTMENTS:           '/appointments',

    ASSET:                  '/asset/:id',
    ASSETS_TYPEAHEAD:       '/asset/typeahead/:id',
    JOB_ASSETS:             '/asset/job/:id',
    ASSET_TO_JOB:           '/asset/job/:jobId',  // POST body must have assetId
    DELETE_ASSET:           '/asset/job/:jobId',
    SINGLE_APPOINTMENT:     '/appointments/:id',
    JOB_APPOINTMENTS:       '/appointments?jobId=:id',
    COPY_APPOINTMENTS:      '/appointments/copy/:id',
    CONVERT_TICKET:         '/appointments/import/:id',
    SCHEDULE_APPOINTMENT:   '/appointments/schedule/user',

    CALENDAR_EVENTS:        '/calendarevent',
    SINGLE_CALENDAR_EVENT:  '/calendarevent/:id',

    JOB_CHECKLIST:          '/checklist/job/:id',
    SINGLE_CHECKLIST:       '/checklist/:id',

    SINGLE_COMPANY:         '/companies/:id',
    ENGINEERS:              '/companies/engineers',
    COMPANY_USERS:          '/companies/customers/:id',
    COMPANY_TYPEAHEAD:      '/companies/typeahead/:id',
    COMPANY_META:           '/companies/meta/:id',
    CONNECT_INTEGRATION:    '/companies/integrations',
    COMPANY_STATUSES:       '/companies/statuses',
    COMPANY_LIST:           '/companies/list',
    COMPANY_BULK_UPDATE:    '/companies/update',
    COMPANY_CREATE:         '/companies/create',

    SINGLE_CONFIGURATION:   '/configuration/:id',

    HISTORY:                '/history',
    SINGLE_HISTORY:         '/history/:historyId',
    HISTORY_BY_PARENT:      '/history/parent/:parentId',

    REGISTRATION_CHECK:     '/integration/betakey/:betaKey',
    PROVIDER_BY_NAME:       '/integration/provider/:providerName',  // GET integration/:providerName
    CONNECT_TO_PROVIDER:    '/integration/connect/:providerName',   // POST body must have all connection fields
    DIAGNOSE_CONNECTION:    '/integration/diagnose/:providerName',  // GET test integration connection
    INTEGRATION_SETTINGS:   '/integration/settings/:providerName',  // GET the integration settings in a UI relevant format
    REFRESH_CONNECTION:     '/integration/refresh/:providerName',   // GET Re-read the provider names and update all mappings
    UPDATE_PROVIDER_VALUE:  '/integration/value/:providerName',     // PATCH update single mapping; body has licorice id and provider id(s)
    PERFORM_INTEGRATION:    '/integration/start/:providerName',     // POST start integration process
    INTEGRATION_PROGRESS:   '/integration/progress/:providerName',  // GET integration progress
    INTEGRATION_TYPE:       '/integration/type',                    // POST set the integration type, presumably to integrated or standalone

    JOBS:                   '/jobs',
    SINGLE_JOB:             '/jobs/:id',
    UPDATE_JOB:             '/jobs/:id?action=UPDATE',
    TICKETS:                '/jobs/provider',
    JOB_ENGINEERS:          '/jobs/engineers/:id',
    JOB_USERS:              '/jobs/users/:id',
    JOBS_BY:                '/jobs/filter',

    JOURNAL:                '/journal/:string',
    JOURNAL_COUNT:          '/journal/total',
    JOURNAL_FILTER:         '/journal/filter/:string',

    NAMES_BY_TYPE:          '/mapping/:id',
    ADD_MAPPING:            '/mapping',
    ADD_RAW_LICORICE_NAMES: '/mapping/addraw',    
    
    JOB_NOTES:              '/note/:id',
    TICKET_NOTES:           '/note/import/:id/:id',

    NOTIFICATIONS:          '/notifications/unviewed',
    SINGLE_NOTIFICATION:    '/notifications/viewed',

    SINGLE_PEGBOARD:        '/pegboard/:id',

    GET_PROVIDERS:          '/provider',                            // GET all providers
    TEST_CONNECTION:        '/provider/test/:id',
    PROVIDERS:              '/provider/providers',
    PROVIDER_DIRECT_URL:    '/provider/directurl/:providerName',

    FILE:                   '/resource',
    SINGLE_FILE:            '/resource/:id',

    DNS_QUERY:              '/system/dns/:type/:url',
    FRONTEND_ERROR:         '/system/frontenderror',
    SYSTEM_MUTEX:           '/system/lock/:id',
    VERSION_BASIC:          '/system/version/live/basic',
    VERSION_EXTENDED:       '/system/version/live/extended',

    COMPANY_SITES:          '/site/company/:id',
    UPDATE_SITE:            '/site/:id',
    CREATE_SITE:            '/site/create',
    CLIENT_USERS:           '/users/company/:id',

    TAGS:                   '/tag',
    TAGS_WITH_MEMBERS:      '/tag/withmembers',
    SINGLE_TAG:             '/tag/:tagId',
    TAG_USERS:              '/tag/users',
    SINGLE_TAG_USER:        '/tag/users/:tagUserId',

    IMPORT_INITIAL_TICKETS: '/tickets/integration?source=connectwise',

    TIME_LOG:                       '/timelog',
    TIME_LOG_ADD_ON_PB:             '/timelog/add/:jobId',
    TIME_LOG_ADD_ON_PB_PAUSED:      '/timelog/addpaused/:jobId',
    TIME_LOG_CREATE:                '/timelog/create/:jobId',
    TIME_LOG_PAUSE:                 '/timelog/pause/:timeLogId',
    TIME_LOG_PLAY:                  '/timelog/play/:timeLogId',
    TIME_LOG_LOG:                   '/timelog/log/:timeLogId',
    TIME_LOG_EDIT:                  '/timelog/edit/:timeLogId',
    TIME_LOG_REMOVE:                '/timelog/remove/:timeLogId',
    TIME_LOG_SUM:                   '/timelog/sum/job/:jobId',
    TIME_LOG_SUM_USER:              '/timelog/sum/user',

    LOGIN:                          '/users/login',
    LOGIN_MFA:                      '/users/mfa',
    UPDATE_TERMS:                   '/users/agreements/:id',
    USER:                           '/users',
    CREATE_USER:                    '/users/create/:companyId',
    USER_SETTINGS:                  '/users/settings',
    SINGLE_USER:                    '/users/:id',
    ADD_ENGINEER:                   '/users/engineer',
    ACCEPT_INVITATION:              '/users/invitations/accept',
    CLIENT_USER_ADD:                '/users/create/:companyId',
    INVITATIONS:                    '/users/invitations',
    QRCODEURL:                      '/users/mfa/qrcodeurl/:loginEmail',
    REGISTRATION_REGISTER:          '/users/registration',
    USER_REQUEST_PASSWORD_TOKEN:    '/users/reset',
    SCHEDULES:                      '/users/schedules',
    USER_RESET_PASSWORD:            '/users/set/pwd',
    GET_TOKEN:                      '/users/tokens/decode/:id',
    CREATE_TOKEN:                   '/users/tokens/create',
    SET_TOKEN_STATUS:               '/users/tokens/status/:status/:id',
    USER_TOKEN_VALIDATION:          '/users/validate/:purpose/:token',
    SINGLE_INVITATION:              '/users/validate/invitation/:id',
    PEOPLE_TYPEAHEAD:               '/users/typeahead/:id',
    VERIFY:                         '/users/verify'
};

export const snackbarKey = {
    INVITE_CREATED:         'inviteCreated',
    ENGINEER_ADDED:         'engineerAdded',
    ENGINEERS_SAVED:        'engineersSaved',
    LOGGED_OUT_INACTIVE:    'loggedOutInactive',
    LOGGED_OUT_GOT_ADMIN:   'loggedOutGotAdmin',
    LOGGED_OUT_LOST_ADMIN:  'loggedOutLostAdmin',
    CLIENT_DETAILS_SAVED:    'clientDetailsSaved'
};

export const jobState = {
    INIT:       'INIT',
    UPDATED:    'UPDATED',
    DELETED:    'DELETED',
    INSYNC:     'INSYNC',
    SYNCED:     'SYNCED',
    SYNCERROR:  'SYNCERROR'
};

export const searchPanelChipType = {
    COMPANY:    'company',
    STRING:    'string',
    TEAM:       'team',
    STATUS:     'status',
    ICON:       'icon',
    USER:       'user'
};

export const GET = 'GET', POST = 'POST', PATCH = 'PATCH', PUT = "PUT";

export const PEGBOARD_SLOTS = 4;

// these are job columns retrieved by both pegboard and appointment queries, so we can
// assume a constant set of fields in the job store, which is built up from the union of
// the jobs from these queries.
export const JOB_QUERY_FIELDS = [
    'job.jobId as realJobId',
    'job.title',
    'job.description',
    'job.companyId',
    'job.providerBoardId',
    'job.providerJobId',
    'job.providerStatusId',
    'job.previousProviderStatusId',
    'job.priority',
    'job.completedDate',
    'job.statusId',
    'job.estimatedTime',
    'job.billable',
    'job.userId as jobUserId',
    'job.contactId',
    'company.companyName'
];

// sources from where we can drag objects to the calendar
export const dragSource = {
    PEGBOARD:       'pegboard',
    SEARCH_PANEL:   'searchPanel'
};

// how long after a field is changed do we autosave?
export const SAVE_FIELD_BOUNCE_DELAY = SECOND;

// errors requiring more than a simple string need a type
export const errorType = {
    NO_CONNECTION:      'NO_CONNECTION'
};

// how changes from the backend are merged into store
export const mergeMode = {
    REPLACE_NEW:        'replace_new',
    REPLACE_BLANK:      'replace_blank',
    NO_BLANKING:        'no_blanking'       // overwrite existing unless new value is blank
};

// time entered today progress color
export const timeEnteredProgress = {
    BADLY_BEHIND:   'badly_behind',
    BEHIND:         'behind',
    ON_TARGET:      'on_target'
};

// default appointment duration, used to init profile editing and in calendar if setting
// hasn't made it to saved preferences yet
export const DEFAULT_APPOINTMENT_DURATION = 30;

// mode values double as icon names, hence "eye"
export const UserProfileMode = {
    EDIT:   "edit",
    VIEW:   "eye",
    ADD:    "add"
};

// these are the requests that disable the play/pause buttons on PB & JC
export const disablePlayPauseDuringRequests = [
    POST + uri.TIME_LOG_ADD_ON_PB,
    POST + uri.TIME_LOG_ADD_ON_PB_PAUSED,
    POST + uri.TIME_LOG_CREATE,
    PATCH + uri.TIME_LOG_PLAY,
    PATCH + uri.TIME_LOG_PAUSE,
    PATCH + uri.TIME_LOG_EDIT,
    PATCH + uri.TIME_LOG_LOG,
    PATCH + uri.TIME_LOG_REMOVE
];

// item ids used by DynamicSelect for user items
export const ADD_ITEM_ID = "addItem";
export const CC_EMAIL_ID = "emailCCItem";

export const userDialogMode = {
    VIEW:   "view",
    EDIT:   "edit",
    ADD:    "add"
};

export const AssetDialogMode = {
    VIEW: "view",
    EDIT: "edit",
    ADD:  "add"
};

export const TableFieldType = {
    SINGLE_SELECT:   "singleSelect",
    LINK:            "link",
    BUTTON:          "button",
    RADIO:           "radio"
};

// licoriceName data is transformed into a number of formats in the store to avoid doing this multiple times.
// These are the format names, for use with selectNameData
export const nameDataFormat = {
    OBJECTS:        'objects',
    ID_TO_NAME:     'idToName',
    NAME_TO_ID:     'nameToId',
    OPTIONS:        'options'
};

export const JournalTypeLabel = {
    [ JournalTypes.INTEGRATION ]:   __( "Integration" ),
    [ JournalTypes.SYNC ]:          __( "Sync" ),
    [ JournalTypes.MAPPING ]:       __( "Mapping" ),
    [ JournalTypes.UNKNOWN ]:       __( "Unknown" )
};
