/*********************************************************************************************************************
 * @file Meta reducer
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 24-Dec-2020
 *********************************************************************************************************************/

import { has, isObject } from '@licoriceio/utils';

import { GET, uri } from '../../constants.js';
import { setCacheRecord, setExistingCacheRecordRequested, setCacheMaxLength, deleteCacheRecord } from '../../redux/actions/index.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { ezRedux, genericRequest } from '../reducerUtil.js';

/**
 * Maintain a set of simple caches for records we need to pull in at random but can't be bothered adding to the
 * API that caused the need. Founding example is user records needed to display avatars for appointments; we don't
 * want to get the full user record with every appointment.
 * 
 * This is not intended to serve high record counts or data sizes.
 * 
 * Includes simple LRU mechanisms to keep a low memory footprint.
 * No TTL mechanism since we can update the cache with changes via socket notifications.
 *  
 * If the data will be used in a component, use requestCacheRecord and selectCacheRecord.
 * If the data is needed synchronously, use getCacheRecord which returns a Promise.
 */

// there's no technical need to specify a type here in order to use the cache, as long
// as the URL accessor is predictable from the type name (eg users) but these types are known 
// to work and avoid hard-coded strings.
const cacheType = {
    USER:       'users',
    COMPANY:    'company'
};

const specialAPIs = {
    company:  uri.COMPANY_META
};

const accessors = {};

/**
 * @typedef {object} CacheState
 * @property {object} data
 */

/**
  * @type {CacheState}
  */
const initialState = Object.freeze({
    type:           {},
    maxLength:      100
});

// make sure we know how to request the record. If  GET /<type>/<id> won't get the record, it needs to be handled via specialAPIs.
const confirmTypeAccessor = ( cache, type ) => {
    if ( !has( cache, type ) ) {
        accessors[ type ] = {
            request: id => genericRequest(
                {}, 
                abstractedCreateAuthRequest( GET, specialAPIs[ type ] ?? `/${type}/:id` ), 
                [ [ setCacheRecord, { type, id } ] ], 
                [ id ]
            )
        };
    }
};


// convenience method to get a list of ids, having eliminated duplicates
const requestCacheRecords = payload => dispatch => {
    const { type } = payload;

    // we can pass in a list of records instead of ids and we'll map to id via the type name
    const ids = isObject( payload.ids[ 0 ]) ? payload.ids.map( r => r[ type + 'Id' ]).filter( Boolean ) : payload.ids;
    const typeTransform = {
        user:   'users'
    };
    for ( const id of [ ...new Set( ids ) ])
        dispatch( requestCacheRecord({ type: typeTransform[ type ] ?? type, id }) );
};

// remnove and re-fetch a cache record
const refreshCacheRecord = payload => dispatch => {
    dispatch( deleteCacheRecord( payload ) );
    dispatch( requestCacheRecord( payload ) );
};

const requestCacheRecord = payload => ( dispatch, getState ) => {
    const { cache } = getState();

    if ( !payload.id ) {
        console.trace( 'no id in cache request' );
        return;
    }

    // bear in mind we're not sending back the data here; the selector does that. We just send a request if the record is not present in the cache

    // if the record is in the cache, the selector will find it and this is a no-op
    if ( cache.type[ payload.type ]?.data[ payload.id ])
        return dispatch( setExistingCacheRecordRequested( payload ) );

    confirmTypeAccessor( cache, payload.type );

    // request the record
    return dispatch( accessors[ payload.type ].request( payload.id ) );
};

const _setCacheRecord = ( draft, payload ) => {

    const { type, id, payload: record } = payload;
    draft.type[ type ] ??= {
        ids:        [],
        data:       {}
    };

    const { ids, data } = draft.type[ type ];

    if ( has( data, id ) ) {

        // if we set an existing record, extend/update the existing data record but don't change id list, requests, etc
        if ( isObject( record ) )
            Object.assign( data[ id ].record, record );
        else
            data[ id ].record = record;
    }
    else {
        data[ id ] = {
            record,
            requests:   0
        };
        ids.push( id );

        if ( ids.length > draft.maxLength ) {
            const oldestId = ids.shift();
            delete data[ oldestId ];
        }
    }

};

const _setCacheMaxLength = ( draft, payload ) => {
    draft.maxLength = payload;
    Object.values( draft.type ).forEach( ({ data, ids }) => {
        if ( ids.length > draft.maxLength ) {
            const oldest = ids.splice( 0, ids.length - draft.maxLength );
            oldest.forEach( id => delete data[ id ]);
        }
    });
};

const _setExistingCacheRecordRequested = ( draft, payload ) => {
    const { data, ids } = draft.type[ payload.type ];
    data[ payload.id ].requests += 1;
    const index = ids.findIndex( id => id === payload.id );
    if ( index < 0 ) 
        throw new Error( `cache id ${payload.id} not found in ids` );
    ids.splice( index, 1 );
    ids.push( payload.id );
};

const _deleteCacheRecord = ( draft, payload ) => {
    const { data, ids } = draft.type[ payload.type ];
    delete data[ payload.id ];
    const index = ids.findIndex( id => id === payload.id );
    if ( index < 0 ) 
        throw new Error( `cache id ${payload.id} not found in ids` );
    ids.splice( index, 1 );
};

const reducers = {
    [ setCacheRecord ]:                         _setCacheRecord,
    [ setExistingCacheRecordRequested ]:        _setExistingCacheRecordRequested,
    [ setCacheMaxLength ]:                      _setCacheMaxLength,
    [ deleteCacheRecord ]:                      _deleteCacheRecord
};

export {
    requestCacheRecord,
    requestCacheRecords,
    refreshCacheRecord,
    cacheType
};

export default ezRedux( reducers, initialState );
