/*********************************************************************************************************************
 * @file Notification reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 1-Dec-2020
 *********************************************************************************************************************/

import { NotificationTypes } from '@licoriceio/constants';
import { isString, has } from '@licoriceio/utils';

import { ucf } from '../../../../utils/src/helpers/string-helpers.js';
import { uri, POST } from '../../constants.js';
import { setNotifications, setNotification, setScrollTop, removeNotification } from '../../redux/actions/index.js';
import { ezRedux, genericRequest } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { __ } from '../../utils/i18n.jsx';
import { setUpdateAppointmentStaticsThunk } from '../calendar/sharedThunks.js';

/**
 * @typedef {object} NotificationState
 * @property {number} scrollTop
 * @property {boolean} closeButtonShown
 * @property {array} notifications
 */

/**
  * @type {NotificationState}
  */
const initialState = Object.freeze({
    scrollTop:              0,
    closeButtonShown:       false,
    notifications:          null,
    pendingAppointmentMap:  {},
    syncProgress:           {},
    syncRunIndex:           -1,
    syncComplete:           false
});

const syncNames = {
    jobs:               __( "Tickets" )
};

// services
const _asyncViewedNotification = abstractedCreateAuthRequest( POST, uri.SINGLE_NOTIFICATION );
const _asyncPostNotification = abstractedCreateAuthRequest( POST, uri.NOTIFICATIONS );

// async handlers

/**
 * Patch a single notification
 */
const notificationViewed = ({ notification, action }) => genericRequest(
    { ...notification, action }, _asyncViewedNotification, [ () => removeNotification( notification ), setUpdateAppointmentStaticsThunk ]);


/**
 * Add a single notification
 */
const postNotification = notification => genericRequest( notification, _asyncPostNotification, [ setNotification, setUpdateAppointmentStaticsThunk ]);

// show a browser notification
const showDesktopNotification = () => {

    const desktopNotification = new Notification( 'New Notification', {
        body: "Licorice has sent a notification to you.",
        icon: "/logo192.png"
    });

    desktopNotification.addEventListener( 'click', () => {
        // Open the corresponding web page
        window.focus();
    });
};

// if we already have permission, show the notification, otherwise check now and show if allowed
const checkDesktopNotification = () => {

    if ( Notification.permission === 'granted' ) 
        showDesktopNotification(  );
    else if ( Notification.permission !== 'denied' ) 
    {
        Notification.requestPermission().then( ( permission ) => {
            if ( permission === 'granted' ) 
                showDesktopNotification( );
        });
    }
};


const avatarType = new Set([
    NotificationTypes.APPOINTMENT_ASSIGNED,
    NotificationTypes.APPOINTMENT_CHANGED,
    NotificationTypes.APPOINTMENT_REJECTED,
    NotificationTypes.JOB_CREATED,
    NotificationTypes.JOB_STATUS_CHANGED
]);

const extendNotification = n12 => {
    const { dismissable, responses, createdOn, updatedOn, hideUntil, data } = n12;

    return {
        ...n12,
        dismissable:    dismissable && responses.length < 2,
        createdOnTime:  new Date( createdOn ).getTime(),
        updatedOnTime:  new Date( updatedOn ).getTime(),
        hideUntilTime:  hideUntil ? new Date( hideUntil ).getTime() : undefined,
        data:           isString( data ) ? JSON.parse( data ) : data
    };
};

const updatePendingAppointments = ( draft, notifications ) => draft.pendingAppointmentMap = notifications
    .filter( n => n.type === NotificationTypes.APPOINTMENT_ASSIGNED && n.responses.length === 2 )
    .reduce( ( acc, cur ) => {
        acc[ cur.data.appointmentId ] = true;
        return acc;
    }, {});

const setNotificationsReducer = ( draft, notifications ) => {
    draft.notifications = ( notifications || []).map( n => extendNotification( n ) );
    updatePendingAppointments( draft, draft.notifications );
};

const setNotificationReducer = ( draft, notification ) => {
    let { notifications, syncProgress, syncRunIndex } = draft;

    if ( !notifications ) notifications = draft.notifications = [];

    const index = ( notifications || []).findIndex( n => n.notificationId === notification.notificationId );

    if ( index >= 0 )
        notifications.splice( index, 1, extendNotification( notification ) );
    else
        notifications.push( extendNotification( notification ) );

    updatePendingAppointments( draft, notifications );

    // we get progress stats individually for each stage so need to accumulate them to
    // display the progress bars together.
    if ( notification.type === NotificationTypes.SYNC_PROGRESS ) 
    {
        const { name, total, count, runIndex } = notification.data;
        if ( name === 'complete' ) 

            // the complete flag stops any further progress notifications from updating the notification,
            // until a new run starts.
            draft.syncComplete = true;
        else
        {
            if ( runIndex !== syncRunIndex )
            {
                draft.syncRunIndex = runIndex;
                draft.syncComplete = false;
                draft.syncProgress = {
                    [ name ]: { count, total, label: syncNames[ name ] ?? ucf( name ) }
                };
            }
            else 
            {
                if ( has( syncProgress, name ) ) 
                {
                    syncProgress[ name ].count = count;
                    syncProgress[ name ].total = total;
                }
                else
                    syncProgress[ name ] = { count, total, label: syncNames[ name ] ?? ucf( name ) };   
            }
        }
        
    }
    else {

        // show a desktop notification for any Licorice notification that comes in while we're not active
        if ( document.hidden )
            checkDesktopNotification();

    }
};

const localRemoveNotification = ( draft, notification ) => {
    draft.notifications = ( draft.notifications || []).filter( n => n.notificationId !== notification.notificationId );
    updatePendingAppointments( draft, draft.notifications );
};

const reducers = {
    [ setNotifications ]:       setNotificationsReducer,
    [ setNotification ]:        setNotificationReducer,
    [ setScrollTop ]:           ( draft, arg ) => draft.scrollTop = arg,
    [ removeNotification ]:     localRemoveNotification
};

/**
 * action creators and async functions are imported by the component and added to mapDispatchToProps,
 * and can then be called by the component's handlers.
 */
export {
    notificationViewed,
    postNotification,
    avatarType
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
