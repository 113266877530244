/** ******************************************************************************************************************
 * @file Describe what iterableBase does.
 * @author julian <jjensen@licorice.io>
 * @since 1.0.0
 * @date 15-01-2024
 *********************************************************************************************************************/

class IterableEntryBase
{
    *_getIterator() {}

    /**
     * @param {...any} args
     * @return {IterableIterator<[K, V]>}
     */
    *[ Symbol.iterator ]( ...args )
    {
        yield *this._getIterator( ...args );
    }

    /**
     * @return {IterableIterator<[K, V | undefined]>}
     */
    *entries()
    {
        for ( const item of this ) yield item;
    }

    /**
     * @return {: IterableIterator<K>}
     */
    *keys()
    {
        for ( const item of this ) yield item[ 0 ];
    }

    /**
     * @return {IterableIterator<V>}
     */
    *values()
    {
        for ( const item of this ) yield item[ 1 ];
    }

    /**
     * @param {EntryCallback<K, V, boolean>} predicate
     * @param {any} thisArg
     * @return {boolean}
     */
    every( predicate, thisArg )
    {
        let index = 0;

        for ( const item of this )
            if ( !predicate.call( thisArg, item[ 1 ], item[ 0 ], index++, this ) ) return false;

        return true;
    }

    /**
     * @param {EntryCallback<K, V, boolean>} predicate
     * @param {any} thisArg
     * @return {boolean}
     */
    some( predicate, thisArg )
    {
        let index = 0;

        for ( const item of this )
            if ( predicate.call( thisArg, item[ 1 ], item[ 0 ], index++, this ) ) return true;

        return false;
    }

    /**
     * @param {EntryCallback<K, V, void>} callbackfn
     * @param {any} thisArg
     */
    forEach( callbackfn, thisArg )
    {
        let index = 0;

        for ( const item of this )
        {
            const [ key, value ] = item;
            callbackfn.call( thisArg, value, key, index++, this );
        }
    }

    /**
     * @param {EntryCallback<K, V, [K, V]>} callbackfn
     * @param {any} thisArg
     * @returns {[K, V] | undefined}
     */
    find( callbackfn, thisArg )
    {
        let index = 0;

        for ( const item of this )
        {
            const [ key, value ] = item;
            if ( callbackfn.call( thisArg, value, key, index++, this ) ) return item;
        }
    }

    /**
     * @param {K} key
     * @returns {boolean}
     */
    has( key )
    {
        for ( const item of this )
        {
            const [ itemKey ] = item;
            if ( itemKey === key ) return true;
        }

        return false;
    }

    /**
     * @param {V} value
     * @returns {boolean}
     */
    hasValue( value )
    {
        for ( const [ , elementValue ] of this )
            if ( elementValue === value ) return true;

        return false;
    }

    /**
     * @param {K} key
     * @return {V|undefined}
     */
    get( key )
    {
        for ( const item of this )
        {
            const [ itemKey, value ] = item;
            if ( itemKey === key ) return value;
        }
    }

    /**
     * @param {ReduceEntryCallback<K, V, U>} callbackfn
     * @param {U} initialValue
     * @return {U}
     */
    reduce( callbackfn, initialValue )
    {
        let accumulator = initialValue;
        let index       = 0;

        for ( const item of this )
        {
            const [ key, value ] = item;
            accumulator          = callbackfn( accumulator, value, key, index++, this );
        }

        return accumulator;
    }
}

/**
 * @class {IterableElementBase<E>}
 */
class IterableElementBase
{
    *_getIterator() {}

    /**
     * @param {any[]} args
     * @return {IterableIterator<E>}
     */
    *[ Symbol.iterator ]( ...args )
    {
        yield *this._getIterator( ...args );
    }

    /**
     * @return {IterableIterator<E>}
     */
    *values()
    {
        for ( const item of this ) yield item;
    }

    /**
     * @param {ElementCallback<E, boolean>} predicate
     * @param {any} thisArg
     */
    every( predicate, thisArg )
    {
        let index = 0;

        for ( const item of this )
            if ( !predicate.call( thisArg, item, index++, this ) ) return false;

        return true;
    }

    /**
     * @param {ElementCallback<E, boolean>} predicate
     * @param {any} thisArg
     * @return {boolean}
     */
    some( predicate, thisArg )
    {
        let index = 0;

        for ( const item of this )
            if ( predicate.call( thisArg, item, index++, this ) ) return true;

        return false;
    }

    /**
     * @param {ElementCallback<E, void>} callbackfn
     * @param {any} thisArg
     */
    forEach( callbackfn, thisArg )
    {
        let index = 0;

        for ( const item of this ) callbackfn.call( thisArg, item, index++, this );
    }

    /**
     * Time Complexity: O(n)
     * Space Complexity: O(1)
     */

    /**
     * @param {ElementCallback<E, E>} callbackfn
     * @param {any} thisArg
     * @return {E|undefined}
     */
    find( callbackfn, thisArg )
    {
        let index = 0;

        for ( const item of this )
            if ( callbackfn.call( thisArg, item, index++, this ) ) return item;
    }

    /**
     * @param {E} element
     * @return {boolean}
     */
    has( element )
    {
        for ( const ele of this )
            if ( ele === element ) return true;

        return false;
    }

    /**
     * @property {number} size
     */

    /**
     * @param {ReduceElementCallback<E, U>} callbackfn
     * @param {U} initialValue
     * @return {U}
     */
    reduce( callbackfn, initialValue )
    {
        if ( this.size === 0 && initialValue === undefined )
            throw new Error( `TypeError: Reduce of empty linked list with no initial value` );

        let accumulator = initialValue;
        let index       = 0;

        for ( const item of this )
            accumulator = callbackfn( accumulator, item, index++, this );

        return accumulator;
    }
}

export { IterableElementBase, IterableEntryBase };
