/** ******************************************************************************************************************
 * @file Multi-factor login with OTP.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 12-May-2021
 *********************************************************************************************************************/

import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars

import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { POST, uri } from '../../constants.js';
import { getLoadingStates } from '../../redux/reducerUtil.js';
import {
    mfaSection,
    textSection,
    centered,
    spacer,
    topRow,
    reddishText,
    columns,
    heavy,
    codeEntry,
    QRCode,
    qrInput,
    gridItem,
    gridCenter,
    letterSpace,
    active,
    spacelet,
    offScreenTouchDevice
} from '../../scss/Mfa.module.scss';
import { __, _$ } from "../../utils/i18n.jsx";
import { MfaStates, nextMfaState, getQrCodeUrl, setMfaTransition, verifyCreds, verifyMfa } from '../../utils/mfa.js';
import { BUTTON_LOGIN, LicoButton, LicoTextField, LicoStaticLink } from '../common/index.js';

import NamePassword from './NamePassword.jsx';

const fakeCodes = [ ...' '.repeat( 16 ) ].map( ( _, i ) => 'abcd-12' + `${i}`.padStart( 2, '0' ) );
const numbers = {
    Digit0:     '0',
    Digit1:     '1',
    Digit2:     '2',
    Digit3:     '3',
    Digit4:     '4',
    Digit5:     '5',
    Digit6:     '6',
    Digit7:     '7',
    Digit8:     '8',
    Digit9:     '9',
    Numpad0:    '0',
    Numpad1:    '1',
    Numpad2:    '2',
    Numpad3:    '3',
    Numpad4:    '4',
    Numpad5:    '5',
    Numpad6:    '6',
    Numpad7:    '7',
    Numpad8:    '8',
    Numpad9:    '9',
    '0':        '0',
    '1':        '1',
    '2':        '2',
    '3':        '3',
    '4':        '4',
    '5':        '5',
    '6':        '6',
    '7':        '7',
    '8':        '8',
    '9':        '9'
};

const SPACE = '\u00A0';


let _hasTouchScreen = null;
const hasTouchScreen = () => {
    if ( _hasTouchScreen == null ) {
        if ( "maxTouchPoints" in navigator ) 
            _hasTouchScreen = navigator.maxTouchPoints > 0;
        else if ( "msMaxTouchPoints" in navigator ) 
            _hasTouchScreen = navigator.msMaxTouchPoints > 0;
        else {
            const mQ = matchMedia?.( "(pointer:coarse)" );
            if ( mQ?.media === "(pointer:coarse)" ) 
                _hasTouchScreen = !!mQ.matches;
            else if ( "orientation" in window ) 
                _hasTouchScreen = true; // deprecated, but good fallback
            else {
                // Only as a last resort, fall back to user agent sniffing
                const UA = navigator.userAgent;
                _hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test( UA ) ||
                     /\b(Android|Windows Phone|iPad|iPod)\b/i.test( UA );
            }
        }
    }
    return _hasTouchScreen;
};

const QRCodeInput = ({ onChange, onSubmit }) => {
    const [ code, setCode ] = useState( SPACE.repeat( 6 ) );
    const [ activeIndex, setActiveIndex ] = useState( 0 );

    const setCodeAt = e => {
        const newCode = [ ...code ].map( ( c, i ) => i === activeIndex ? numbers[ e.code ] : c );
        // noinspection JSCheckFunctionSignatures
        setCode( newCode );
        onChange( newCode );

        // autosubmit first time thru
        const firstSpace = code.indexOf( SPACE );
        if ( firstSpace === 5 && activeIndex === 5 )
            onSubmit( newCode );

    };

    const clearCodeAt = index => {
        const newCode = [ ...code ].map( ( c, i ) => i === index ? SPACE : c );
        setCode( newCode );
        onChange( newCode );
    };

    const onKeyPress = e => {
        if ( numbers[ e.code ])
        {
            setCodeAt( e );

            if ( activeIndex < 5 )
                setActiveIndex( activeIndex + 1 );
        }
        else if ( e.code === 'Backspace' && activeIndex > 0 ) {
            clearCodeAt( activeIndex - 1 );
            setActiveIndex( activeIndex - 1 );
        }
        else if ( e.code === 'Delete' )
            clearCodeAt( activeIndex );
        else if ( e.code === 'ArrowLeft' && activeIndex > 0 )
            setActiveIndex( activeIndex - 1 );
        else if ( e.code === 'ArrowRight' && activeIndex < 5 )
            setActiveIndex( activeIndex + 1 );
        else if ( e.code === 'Home' )
            setActiveIndex( 0 );
        else if ( e.code === 'Enter' )
            onSubmit( code );
    };

    const onClick = i => () => setActiveIndex( i );

    useEffect( () => {
        document.querySelector( 'body' ).addEventListener( 'keydown', onKeyPress );
        return () => document.querySelector( 'body' ).removeEventListener( 'keydown', onKeyPress );
    });

    const touch = hasTouchScreen();

    const cls = i => `${gridItem} ${i === activeIndex ? active : ''}`;
    return (
        <>  
            <div className={qrInput}>

                <div onClick={onClick( 0 )} className={cls( 0 )} key="digit0">{code[ 0 ]}</div>
                <div onClick={onClick( 1 )} className={cls( 1 )} key="digit1">{code[ 1 ]}</div>
                <div onClick={onClick( 2 )} className={cls( 2 )} key="digit2">{code[ 2 ]}</div>
                <div className={`${gridItem} ${gridCenter}`} key="digit-center">-</div>
                <div onClick={onClick( 3 )} className={cls( 3 )} key="digit3">{code[ 3 ]}</div>
                <div onClick={onClick( 4 )} className={cls( 4 )} key="digit4">{code[ 4 ]}</div>
                <div onClick={onClick( 5 )} className={cls( 5 )} key="digit5">{code[ 5 ]}</div>
            </div>
            {touch && <TextField 
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                autoFocus 
                className={offScreenTouchDevice}
                onKeyDown={ e => onKeyPress({ code: e.key }) }
            />}
        </>
    );
};

QRCodeInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

const Mfa = props => {
    const components = {
        [ MfaStates.CONFIRM ]:  () => <MfaConfirm {...props } />,
        [ MfaStates.EXPLAIN ]:  () => <MfaExplain {...props } />,
        [ MfaStates.RECOVERY ]: () => <MfaRecovery {...props } />,
        [ MfaStates.SETUP ]:    () => <MfaSetup {...props } />,
        [ MfaStates.USE ]:      () => <MfaUse {...props } />,
        [ MfaStates.NONE ]:     () => <NamePassword />
    };

    return components[ props.mfaState ]();
};

Mfa.propTypes = {
    mfaState:           PropTypes.string.isRequired,
    verified:           PropTypes.bool,
    mfaVerified:        PropTypes.bool,
    verifyCreds:        PropTypes.func.isRequired,
    verifyMfa:          PropTypes.func.isRequired,
    setMfaTransition:   PropTypes.func.isRequired,
    loadingLogin:       PropTypes.bool,
    qrcode:             PropTypes.string,
    getQrCodeUrl:       PropTypes.func.isRequired,
    redirectTo:         PropTypes.string,
    qrCodeUrl:          PropTypes.string,
    userMfaSetup:       PropTypes.bool,
    organisationMfa:    PropTypes.bool
};

const MfaConfirm = ({ verifyCreds, userMfaSetup, organisationMfa }) => {
    const [ password, setPassword ] = useState( '' );

    const onChange = e => setPassword( e.target.value );
    const onKeyPress = e => e.key === 'Enter' && password.length > 0 && verifyCreds( userMfaSetup, password );

    return (
        <div className={mfaSection}>
            {organisationMfa && <div className={textSection}>
                {__( "Your organisation requires two-factor authentication on all accounts, please follow the prompts to set up 2FA now." )}
            </div>}
            <div className={textSection}>
                {__( "To enable two-factor authentication please confirm your current password." )}
            </div>
            <div>

                {/* I took out the error checking/display on the password as it was distracting when Back was pressed
                   * and sometimes ate the click on Back. The Confirm button is only enabled once entry has begun so it's still safe.
                   */}
                <LicoTextField
                    type="password"
                    name="password"
                    value={password}
                    allowLastPass={true}
                    autoFocus
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />
            </div>
            <div className={spacer} />
            <div className={centered}>
                <LicoButton type="button" disabled={password.length === 0} onClick={() => verifyCreds( userMfaSetup, password )}> {__( "Confirm Password" )} </LicoButton>
            </div>
        </div>
    );
};

MfaConfirm.propTypes = {
    verifyCreds:        PropTypes.func.isRequired,
    userMfaSetup:       PropTypes.bool,
    organisationMfa:    PropTypes.bool
};

const MfaExplain = ({ userMfaSetup, setMfaTransition }) => {

    const Link = ( label, to ) => <LicoStaticLink target="_blank" to={to} className={textSection}>{label}</LicoStaticLink>;

    return (
        <div className={mfaSection}>
            <div className={textSection}>
                {__( "Two-factor authentication adds an additional layer of security to your account." ) }
            </div>
            <div className={textSection}>
                {__( "In addition to your username and password, you'll need to enter a code that Licorice sends to you via an app." )}
            </div>
            <div className={textSection}>
                {_$( "We recommend using {authy}, {googleAuth}, {onePassword}, or {lastpass}.", {
                    authy:          Link( __( "Authy" ),                "https://www.authy.com" ),
                    googleAuth:     Link( __( "Google Authenticator" ), "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" ),
                    onePassword:    Link( __( "1Password" ),            "https://1password.com" ),
                    lastpass:       Link( __( "Lastpass" ),             "https://www.lastpass.com" )
                })}
            </div>
            <div className={centered}>
                <LicoButton type="button" onClick={() => setMfaTransition( userMfaSetup, nextMfaState[ MfaStates.EXPLAIN ])}> {__( "Set up using an app" )} </LicoButton>
            </div>
        </div>
    );
};

MfaExplain.propTypes = {
    setMfaTransition: PropTypes.func.isRequired,
    userMfaSetup:       PropTypes.bool
};

const MfaRecovery = ({ userMfaSetup, setMfaTransition }) => (
    <div className={mfaSection}>
        <div className={topRow}>
            <span>Recovery codes</span>
            <span>Step 1/2</span>
        </div>
        <div className={spacer} />
        <div className={textSection}>
            {__( "Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes." )}
        </div>
        <div className={`${textSection} ${reddishText}`}>
            {__( "Download, print, or copy your recovery codes before continuing two-factor authentication setup below." )}
        </div>
        <div className={columns}>
            {
                fakeCodes.map( c => <span key={c} className={codeEntry}>{c}</span> )
            }
        </div>
        <div className={spacer} />
        <div className={centered}>
            <LicoButton type="button" onClick={() => setMfaTransition( userMfaSetup, nextMfaState[ MfaStates.RECOVERY ])}> {__( "I've saved these somewhere safe" )} </LicoButton>
        </div>
    </div>
);

MfaRecovery.propTypes = {
    setMfaTransition:   PropTypes.func.isRequired,
    userMfaSetup:       PropTypes.bool
};

const MfaSetup = ({ getQrCodeUrl, qrCodeUrl, verifyMfa, userMfaSetup }) => {
    const [ qrc, setQrc ] = useState( '' );
    useEffect( () => void getQrCodeUrl( userMfaSetup ), [ getQrCodeUrl, userMfaSetup ]);

    const submit = chars => verifyMfa( userMfaSetup, chars.join( '' ) );

    const match = ( qrCodeUrl ?? '' ).match( /secret=([A-Z0-9]{32})/ );
    const secret = match ? match[ 1 ] : '';

    return (
        <div className={mfaSection}>
            <div className={topRow}>
                <span>{__( 'Scan this barcode with your app' )}</span>
                {/* <span>Step 2/2</span> */}
            </div>
            <div className={spacer} />
            <div className={textSection}>
                {__( "Scan this image with the two-factor authentication app. If you can't use a barcode, enter this code manually instead:" )}
                <span className={`${heavy} ${letterSpace}`}>{secret}</span>
            </div>
            {
                qrCodeUrl && <div className={QRCode}>
                    <img src={qrCodeUrl} alt={__( "QR Code to scan" )}/>
                </div>
            }
            <div className={spacer} />
            <div className={textSection}>
                <div className={heavy}>{__( "Enter the six-digit code from the application" )}</div>
                <div className={spacer} />
                <div>{__( "After scanning the barcode image, the app will display a six-digit code that you can enter below:" )}</div>
            </div>
            <QRCodeInput onChange={v => setQrc( v )} onSubmit={submit}/>
            <div className={spacer} />
            <div className={centered}>
                <LicoButton type="button" disabled={qrc.length !== 6} onClick={() => submit( qrc )}> {__( "Enable" )} </LicoButton>
            </div>
        </div>
    );
};

MfaSetup.propTypes = {
    verifyMfa:          PropTypes.func.isRequired,
    getQrCodeUrl:       PropTypes.func.isRequired,
    qrCodeUrl:          PropTypes.string,
    userMfaSetup:       PropTypes.bool
};

const MfaUse = ({ onDone }) => {
    const [ qrc, setQrc ] = useState( '' );

    const submit = chars => onDone( chars.join( '' ) );

    return (
        <div className={mfaSection}>
            <div className={textSection}>
                {__( "Please enter the verification code from your 2FA app below to login:" )}
            </div>
            <div className={spacelet} />
            <QRCodeInput onChange={v => setQrc( v )} onSubmit={submit} />
            <div className={spacer} />
            <div className={centered}>
                <LicoButton type="button" disabled={qrc.length !== 6} onClick={() => submit( qrc )} > {BUTTON_LOGIN} </LicoButton>
            </div>
        </div>
    );
};

MfaUse.propTypes = {
    onDone: PropTypes.func.isRequired
};

const mapStateToProps = ( state, props ) => {
    const [ loadingLogin, loadingLogin2 ] = getLoadingStates( state, POST + uri.LOGIN, POST + uri.LOGIN_MFA );

    const { verified, mfaVerified, mfaState, qrcode, qrCodeUrl } = props.userMfaSetup ? state.userProfile : state.login;

    return {
        verified,
        mfaVerified,
        mfaState,
        qrcode,
        qrCodeUrl,
        loadingLogin: !!loadingLogin || !!loadingLogin2
    };
};

const mapDispatchToProps = {
    verifyCreds,
    verifyMfa,
    setMfaTransition,
    getQrCodeUrl
};

export default connect( mapStateToProps, mapDispatchToProps )( Mfa );
export { MfaUse };
