import React from 'react';

import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        display: "block", 
        margin:  "4px auto"
    }
});

export const Loader = props => <CircularProgress className = {useStyles( props ).root}/>;
