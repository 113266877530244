import { isProduction, Priority } from '@licoriceio/constants';
import { nop } from '@licoriceio/utils';

import { buildEnv } from '../build-env.js';
import { critical, high, normal, noColor, normalWithBorder, highWithBorder, criticalWithBorder, normalBorder, highBorder, criticalBorder } from '../scss/misc.module.scss';

const debugNames = buildEnv.DEBUG_NAMES;
const debugFlags = new Set( ( debugNames ?? '' ).split( ',' ) );

/**
 * Generic text filter
 * @param {object} record - object to be filtered
 * @param {Set} fields - set of field names in the object to be filtered
 * @param {string} keyword - filter string (lower case)
 */
const filterWithKeyword = ( record, fields, keyword ) => {
    const line = Object.keys( record ).filter( key => fields.has( key ) ).map( key => record[ key ]).join( ':' );
    return ( keyword === undefined || keyword.length === 0 ) ? true : line.toLowerCase().includes( keyword );
};

const pick = ( obj, ...keys ) => Object.fromEntries( Object.entries( obj ).filter( ([ key ]) => keys.includes( key ) ) );

const onKey = ( key, fn ) => e => e.key === key && fn();
const onEnter = fn => onKey( 'Enter', fn );
const onEsc = fn => onKey( 'Escape', fn );

const curry = ( f, ...i ) => ( s => s( s )( i ) )( s => p => ( ...c ) => c.length + p.length >= f.length ? f( ...p.concat( c ) ) : s( s )( p.concat( c ) ) );
const log = ( area, ...args ) => {
    if ( args.length === 0 ) return debugFlags[ area ] && !isProduction ? ( ...args ) => console.log( ...args ) : nop;
    debugFlags[ area ] && !isProduction && console.log( ...args );
};

const SelectStyle = {
    standard:  'standard',
    chipAdder: 'chipAdder',
    round:     'round'
};

const priorityColors = {
    [ Priority.normal ]:   normal,
    [ Priority.high ]:     high,
    [ Priority.critical ]: critical

};

const priorityColorsWithBorders = {
    [ Priority.normal ]:   normalWithBorder,
    [ Priority.high ]:     highWithBorder,
    [ Priority.critical ]: criticalWithBorder

};

const priorityBorderColors = {
    [ Priority.normal ]:    normalBorder,
    [ Priority.high ]:      highBorder,
    [ Priority.critical ]:  criticalBorder,
    noColor:                noColor
};

let _loginUserId;

const getLoginUserId = () => _loginUserId;
const setLoginUserId = id => _loginUserId = id;

const deleteOnUserId = ( list, userId ) => list.filter( item => item.user.userId !== userId );

const requestIsLoading = ( state, requestLabels ) => requestLabels.some( label => state.loading[ label ]);

export {
    getLoginUserId,
    setLoginUserId,
    curry,
    log,
    onEnter,
    onEsc,
    onKey,
    priorityColors,
    priorityColorsWithBorders,
    priorityBorderColors,
    SelectStyle,
    filterWithKeyword,
    pick,
    deleteOnUserId,
    requestIsLoading
};
