import React from 'react';

import { Snackbar, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { LicoCircularProgress } from './LicoCircularProgress.jsx';

export const LicoProgressSnackBar = props => {
    const {
        message,
        progress,
        open
    } = props;
    return <div>

        <Snackbar
            anchorOrigin={{
                vertical:   'bottom',
                horizontal: 'right'
            }}
            open={open}
            message={
                <>            <CircularProgress size = {15}/> {message} <LicoCircularProgress progress={progress} />
                </>
            }
        />
    </div>;
};

LicoProgressSnackBar.propTypes = {
    message:  PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    open:     PropTypes.bool.isRequired
};
