/** ******************************************************************************************************************
 * @file Programming helpers.
 * @author Julian Jensen <jjensen@licorice.io>
 * @since 1.0.0
 * @date 10-Jan-2021
 *********************************************************************************************************************/

import { isString, isFunc, lc, asArray, inRange, curry } from '../helpers/index.js';

/**
 * @param {string[]} enumList
 * @param {string} s
 * @return {string|null}
 */
const _isIn = ( enumList, s ) => isString( s ) && enumList.includes( lc( s ) ) ? lc( s ) : null;

/**
 * @param {function|any|null|undefined} value
 * @param {...*}args
 * @return {any}
 */
const dynamic = ( value, ...args ) => isFunc( value ) ? value( ...args ) : value;

/**
 * @param {object} obj
 * @param {string} key
 * @param {any} value
 * @return {any}
 */
const define  = ( obj, key, value ) => Object.defineProperty( obj, key, value );

/**
 * @param {object} obj
 * @param {object} defs
 * @return {any}
 */
const defines = ( obj, defs ) => Object.defineProperties( obj, defs );


const { getOwnPropertyNames: names, keys, getOwnPropertyDescriptors: ownProps, getOwnPropertySymbols: ownSyms } = Object;

const props     = o => keys( ownProps( o ) ).concat( ownSyms( o ) );

const functionType = fn => {
    const m = props( fn );
    const type = !m.includes( 'prototype' ) ? 'arrowFunction' : m.includes( 'arguments' ) ? 'function' : 'class';
    if ( type !== 'class' ) return type;
    const protoNames = names( fn.prototype );

    if ( protoNames.length > 1 )
        return 'class';

    return 'function';
};

function iterN( start, end, step = 1, predicate )
{
    if ( isFunc( end ) ) [ start, end, step, predicate ] = [ 0, start, 1, end ];
    else if ( isFunc( step ) ) [ step, predicate ] = [ 1, step ];

    if ( end === void 0 ) [ start, end ] = [ 0, start ];

    for ( let i = start; i < end; i += step )
        predicate( i );
}

/** ******************************************************************************************************************
 * COUNTER OF MANY NUMBERS
 *********************************************************************************************************************/

// With callbacks.
function forN( counts, cb )
{
    let index = 0;

    for ( const res of forN.iter( counts ) )
        cb([ ...res ], index++ );
}

// As an iterator.
forN.iter = function *iter( counts ) {
    const bases = new Array( counts.length ).fill( 0 );
    let last    = counts.length - 1, carry = 0, i = 0, n;

    while ( carry === 0 || i > -1 )
    {
        yield [ ...bases ];

        for ( i = last, carry = 1, n = bases[ i ] + carry; carry === 1 && i > -1; --i, n = bases[ i ] + carry )
            carry = +( ( bases[ i ] = n >= counts[ i ] ? 0 : n ) === 0 );
    }
};

const _getNames = name => name.split( /[\s.,-]/ ).map( s => s.trim() ).filter( Boolean );

const _getNamesInitials = name => _getNames( name ).map( s => s[ 0 ]).join( '' );

const _getFirstLast = name => {
    const [ first, ...rest ] = _getNames( name );
    return [ first, rest.length > 0 ? rest.pop() : '' ];
};

const _getFirstLastInitials = name => {
    const inits = _getNamesInitials( name );

    if ( inits.length > 1 )
        return inits[ 0 ] + inits[ inits.length - 1 ];
    const ch = inits[ 0 ] || '';

    return ch + ch;
};

const getNames = ( name, initialsOnly = false ) => initialsOnly ? _getNamesInitials( name ) : _getNames( name );

const getFirstLast = ( name, initialsOnly = false ) => initialsOnly ? _getFirstLastInitials( name ) : _getFirstLast( name );

/**
 * @param {string} s
 * @param {() => any} [onError]
 * @return {any|null}
 */
const safeParse = ( s, onError = () => null ) => {
    try
    {
        return JSON.parse( s );
    }
    catch ( e )
    {
        return onError?.( e, s );
    }
};

const isIn = curry( _isIn );

const
    { isArray: array } = Array,
    simple = a => !array( a ) || !a.some( array ),
    prepend = ( head, tail ) => tail.length ? tail.map( t => head.concat( t ) ) : [ head ],
    _permutations = ( arr, tail = asArray( arr ).slice( 1 ), tmp ) =>
        simple( arr ) ? asArray( arr ) : permutations( arr[ 0 ]).map( asArray ).reduce( ( _, h ) => [ ..._, ...prepend( h, tmp || ( tmp = permutations( tail ) ) ) ], []),
    permutations = arr => _permutations( arr );

export {
    permutations,
    safeParse,
    getNames,
    getFirstLast,
    functionType,
    define,
    defines,
    dynamic,
    inRange,
    isIn,
    forN,
    iterN
};
